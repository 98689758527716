import React, { useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useDebounceValue } from 'lib/hooks';
import { useLockTree } from 'components/trees/utils/useLocationTree';
import { InfiniteTree as Tree } from 'components/trees';
import cx from './lock-tree.module.scss';

const LockTree = ({ objectId, resourceId, resourceType, objectRoleId }) => {
    const { tree } = useLockTree(objectId, resourceId, resourceType, objectRoleId);
    const treeRef = useRef();

    const [query, setQuery] = useState('');
    const [debouncedValue] = useDebounceValue(query.trim().replace(/\s{2,}/g, " "), 300);

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(debouncedValue.trim());
        }
    }, [debouncedValue, treeRef]);

    return (
        <div className={cx.lockTree}>
            <div className={cx.search}>
                <input
                    type="text"
                    placeholder="Поиск по дереву"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                />
            </div>
            <div className={cx.sizerContainer}>
                <AutoSizer key={tree}>
                    {({ width, height }) => {
                        return (
                            <Tree
                                ref={treeRef}
                                width={width}
                                height={height}
                                data={tree}
                                multipleChoice={false}
                                autoOpen={true}
                            />
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};

export default LockTree;
