import ApiClient from './api-axios';

export default class ChatApi extends ApiClient {
    getUsers = async (page = 0, size = 20, sort = '') => {
        return await this.get('user-chat-messages/users', { page, size, sort });
    };

    getAuthorities = async () => {
        return await this.get('users/authorities');
    };

    getChatGroups = async () => {
        return await this.get('user-chat-messages/groups');
    };

    createChatGroup = async (title, description, avatarType, avatar, users, groups) => {
        return await this.post('chat-groups', {
            name: title,
            description: description,
            avatarContentType: avatarType,
            avatar: avatar,
            users: users,
            userGroups: groups
        });
    };

    updateChatGroup = async (id, title, description, avatarType, avatar, users, groups) => {
        return await this.put('chat-groups', {
            id: id,
            name: title,
            description: description,
            avatarContentType: avatarType,
            avatar: avatar,
            users: users,
            userGroups: groups
        });
    };

    deleteChatGroup = async (id) => {
        return await this.deleteRequest('chat-groups/' + id);
    };

    createMessage = async (fromUserId, toUserId, chatGroupId, message) => {
        const data = {
            fromUserId: fromUserId,
            message: message,
            createdDateTime: new Date().toISOString(),
            seen: true
        };

        if (toUserId) {
            data['toUserId'] = toUserId;
        } else if (chatGroupId) {
            data['chatGroupId'] = chatGroupId;
        }

        return await this.post('chat-messages', data);
    };

    deleteChatMessage = async (messageId) => {
        return await this.deleteRequest('chat-messages/' + messageId);
    };

    deleteDialogMessage = async (messageId) => {
        return await this.deleteRequest('chat-messages/' + messageId);
    };

    updateChatMessage = async (message) => {
        message.original.message = message.message;
        return await this.put('chat-messages', message.original);
    };

    getChatGroupMessages = async (chatGroupId, page = 0, size = 20, sort = '') => {
        return await this.get('chat-messages/chat-groups/' + chatGroupId, { page, size, sort });
    };

    seeChatGroupMessages = async (chatGroupId) => {
        return await this.post('chat-messages/chat-groups/' + chatGroupId + '/seen');
    };

    getChatDialogMessages = async (dialogId, page = 0, size = 20, sort = '') => {
        return await this.get('chat-messages/users/' + dialogId, { page, size, sort });
    };

    seeChatDialogMessages = async (dialogId) => {
        return await this.post('chat-messages/users/' + dialogId + '/seen');
    };

    deleteDialog = async (id) => {
        return await this.deleteRequest('chat-messages/users/' + id);
    };

    getLogo = (id) => {
        return this.prefix + 'chat-groups/logo?uuid=' + id;
    };
}