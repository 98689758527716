import React, { useEffect } from 'react';
import { useGlobalContext, useMessage, usePermittedActions } from 'lib/hooks';
import { perm, RESOURCE_TYPE } from 'model/resource';
import hot from 'containers/hot-container';
import { TreePropContext } from 'containers/side-tree-wrapper';
import { projectFormPage } from 'components/project-form';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import api from 'api';

const EditProjectPage = ({ project, loading, setLoading, navigate }) => {

    const { platform, setFormDataChanged } = useGlobalContext();
    const { tree, setScrollToNode } = React.useContext(TreePropContext);

    const { addError, addSuccess } = useMessage();
    const { checkPermission, ready } = usePermittedActions(RESOURCE_TYPE.PROJECT, project.id);

    const editProject = async () => {
        if (!project.validateFields()) {
            return;
        }

        setLoading(true);

        try {
            setFormDataChanged(false);
            const formData = project.getFormData();
            const newProject = await api.project.editProject(formData);

            project.logoUuid = newProject.logoUuid;
            project.applyTreeChanges(tree);

            addSuccess('Проект изменён');
            navigate(`/projects/${project.id}/articles`);
        } catch (error) {
            setFormDataChanged(true);
            addError('Не удалось изменить проект');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setScrollToNode(project.getParentsIds());
    }, [project, setScrollToNode]);

    useEffect(() => {
        if (ready && !checkPermission(perm.document.PROJECT_EDIT)) {
            navigate('/401', { replace: true });
        }
    }, [ready, checkPermission, navigate]);

    return (
        <>
            {loading &&
            <Loading withOverlay={false} withRelativeOverlay={true}/>}
            <Button onClick={editProject} label={'Сохранить'} disabled={loading} color={'green'}
                    fullWidth={platform === 'mobile'}/>
        </>
    );
};

export default hot(projectFormPage(EditProjectPage, 'Редактировать проект', true));
