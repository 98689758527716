import React from 'react';
import cs from './Tag.module.scss';
import classNames from 'classnames';

export const Tag = ({ name, selectable, selected, onClick }) => {

    const handleClick = () => {
        if (onClick) {
            onClick(!selected);
        }
    }

    return (
        <span
            className={classNames(cs.tag, {
                [cs.selectable]: selectable,
                [cs.selected]: selected,
            })}
            onClick={handleClick}
        >
            {name}
        </span>
    )
}
