export function getTableNodeTypes(schema) {
    if (schema.cached.tableNodeTypes) {
        return schema.cached.tableNodeTypes;
    }

    const roles = {};

    Object.keys(schema.nodes).forEach(type => {
        const nodeType = schema.nodes[type];

        if (nodeType.spec.tableRole) {
            roles[nodeType.spec.tableRole] = nodeType;
        }
    });

    schema.cached.tableNodeTypes = roles;

    return roles;
}

export function tableNodeTypes(schema) {
    let result = schema.cached.tableNodeTypes;

    if (!result) {
        result = schema.cached.tableNodeTypes = {};

        for (const name in schema.nodes) {
            const type = schema.nodes[name],
                role = type.spec.tableRole;
            if (role) result[role] = type;
        }
    }

    return result;
}
