import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import cx from './empty.module.scss';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';

const Empty = ({ title, icon, description, buttons, className }) => {
    return (
        <div className={classNames(cx.empty, [className])}>
            <Icon type={icon || Icons.FOLDER} width={56} height={50}/>
            <h3 data-testid="emtyPageTitle">{title}</h3>
            <p data-testid="emtyPageDescription">{description}</p>
            {buttons && buttons.length > 0 &&
            <div className={cx.buttons}>
                {buttons.map((button) => {
                    return (
                        <AdaptiveLink key={button.title} link={button.link}>
                            <button onClick={button.onClick} data-testid="emptyPageBtns">
                                <Icon type={button.icon} width={20} height={20}/>
                                <span>{button.title}</span>
                            </button>
                        </AdaptiveLink>
                    );
                })}
            </div>}
        </div>
    );
};

Empty.defaultProps = {
    title: '',
    description: '',
    buttons: []
};

Empty.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    buttons: PropTypes.array
};

export default Empty;