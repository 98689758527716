import React, { useEffect } from 'react';
import { useNavigate } from '@reach/router';
import PropTypes from 'prop-types';
import { useArticleFormModel } from 'lib/hooks';
import ContentManagementProjectFormPage from './content-management-form';
import cs from './content-management-form-page.module.scss';

export const contentManagementArticleFormPage = (Component, pageTitle) => {
    const FormPage = (props) => {

        const navigate = useNavigate();
        const { uuid } = props;

        const model = useArticleFormModel(uuid);
        const { article, loading, permissionsOk } = model;

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401', { replace: true });
            }
        }, [navigate, permissionsOk]);

        return (
            <div className={cs.content}>
                <div id={'contentManagementProjectFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                <ContentManagementProjectFormPage resource={article} loading={loading}>
                    <Component {...props} {...model}/>
                </ContentManagementProjectFormPage>
            </div>
        );
    };

    FormPage.propTypes = {
        uuid: PropTypes.string
    };

    return FormPage;
};
