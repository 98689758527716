import React, { useRef } from 'react';
import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';
import { EmojiPicker } from 'uikit/emoji-picker/emoji-picker';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';

export const MenuButtonEmoji = ({ mode, currentMenu, setCurrentMenu, isFullScreen, isTooltip }) => {
    const editor = useEditorContext();
    const onClick = emoji => {
        editor.chain().focus().insertContent(emoji.e).run();
    };

    const menuRef = useRef(null);
    return (
        <Menu
            ref={menuRef}
            mode={mode}
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            label={<MenuButton icon={Icons.EditorIconEmoji} tooltip={isTooltip === false ? null : 'Смайлик'} label="Смайлик"></MenuButton>}
            menuButtonStyles={mode === 'DESKTOP' && isFullScreen ? null : { width: '100%' }}
        >
            <MenuItem disableHover label={<EmojiPicker onClick={onClick} />} />
        </Menu>
    );
};
