export default function CollectArticleParentsIds(id, parents) {
    if (parents) {
        let arr = [];

        arr.push(parents.projectId);
        arr = [...arr, ...parents.sections.map(sec => sec.uuid).reverse()];

        if (id) {
            arr.push(id);
        }

        return arr;
    } else {
        return [];
    }
}