import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import Icon, { Icons } from 'uikit/icon';
import Checkbox from 'uikit/checkbox/checkbox';
import Button from 'uikit/button';
import cx from './training-test-end.module.scss';
import api from 'api/index';

export default function TrainingTestEnd({ id }) {
    const navigate = useNavigate();

    const [test, setTest] = useState(null);
    const [testStorage, setTestStorage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const storage = JSON.parse(localStorage.getItem('test'));
            localStorage.removeItem('test');

            if (!storage) {
                navigate('/training');
            }

            const response = await api.training.getTest(id);
            response['time'] = storage['finish'] - storage['start'];

            // Calculate min mark;
            response['minMark'] = -1;

            if (response['gradingType'] !== 0) {
                const gradingRange = JSON.parse(response['gradingRange']);

                for (let i = 0; i < gradingRange.length; i++) {
                    if (!gradingRange[i]['isPassed']) {
                        continue;
                    }

                    if (response['minMark'] === -1 || gradingRange[i]['from'] < response['minMark']) {
                        response['minMark'] = gradingRange[i]['from'];
                    }
                }
            }

            // Calculate max mark;
            response['maxMark'] = 0;

            for (let i = 0; i < response['questions'].length; i++) {
                response['maxMark'] += response['questions'][i]['mark'];
            }

            // Calculate current mark;
            response['currentMark'] = 0;

            for (let i = 0; i < response['questions'].length; i++) {
                const storageQuestion = storage['questions'].find(p => p['id'] === response['questions'][i]['id']);
                response['questions'][i]['isCorrect'] = false;

                if ((response['questions'][i]['type'] === 0 || response['questions'][i]['type'] === 1)
                    && storageQuestion['answer'].filter(p => p['isChecked'] && p['isRight']).length
                    === response['questions'][i]['answers'].filter(p => p['isRight']).length) {
                    response['currentMark'] += response['questions'][i]['mark'];
                    response['questions'][i]['isCorrect'] = true;
                } else if (response['questions'][i]['type'] === 2) {
                    response['currentMark'] += response['questions'][i]['mark'];
                    response['questions'][i]['isCorrect'] = true;
                } else if (response['questions'][i]['type'] === 3
                    && storageQuestion['answers'].findIndex(p => p['value1'] === storageQuestion['answer']) !== -1) {
                    response['currentMark'] += response['questions'][i]['mark'];
                    response['questions'][i]['isCorrect'] = true;
                } else if (response['questions'][i]['type'] === 4) {
                    for (let j = 0; j < storageQuestion['answer'].length; j++) {
                        if (storageQuestion['answer'][j]['value2'] !== storageQuestion['answer'][j]['answer']['label']) {
                            response['questions'][i]['isCorrect'] = false;
                            break;
                        }

                        response['questions'][i]['isCorrect'] = true;
                    }

                    if (response['questions'][i]['isCorrect']) {
                        response['currentMark'] += response['questions'][i]['mark'];
                    }
                } else if (response['questions'][i]['type'] === 5) {
                    for (let j = 0; j < response['questions'][i]['answers'].length; j++) {
                        if (storageQuestion['answer'][j]['value1'] !== response['questions'][i]['answers'][j]['value1']) {
                            response['questions'][i]['isCorrect'] = false;
                            break;
                        }

                        response['questions'][i]['isCorrect'] = true;
                    }

                    if (response['questions'][i]['isCorrect']) {
                        response['currentMark'] += response['questions'][i]['mark'];
                    }
                }
            }

            // Sort questions by storage;
            response['questions'] = response['questions'].sort(
                (a, b) => storage['questions'].findIndex(p => p['id'] === a['id']) > storage['questions'].findIndex(p => p['id'] === b['id']) ? 1 : -1);

            setTest(response);
            setTestStorage(storage);

            await api.training.postTestResult(storage['id'], storage['start'], storage['finish'], storage['questions'].map(question => {
                const answers = question['type'] === 2
                    ? [{ id: 0, answer: question['answer'] }]
                    : (question['type'] === 5 ? question['answers'].sort((a, b) => a['id'] > b['id'] ? 1 : -1) : question['answers']).map((answer, i) => {
                        let responseAnswer = '';

                        if (question['type'] === 0 || question['type'] === 1) {
                            const questionAnswer = question['answer'][question['answer'].findIndex(p => p['id'] === answer['id'])];
                            responseAnswer = questionAnswer['isChecked'] ? 'true' : 'false';
                        } else if (question['type'] === 3) {
                            responseAnswer = question['answer'];
                        } else if (question['type'] === 4) {
                            const questionAnswer = question['answer'][question['answer'].findIndex(p => p['id'] === answer['id'])];
                            responseAnswer = questionAnswer['answer']['label'];
                        } else if (question['type'] === 5) {
                            responseAnswer = question['answer'][i]['value1'];
                        }

                        return {
                            id: answer['id'],
                            answer: responseAnswer
                        };
                    });

                return {
                    id: question['id'],
                    start: question['start'],
                    finish: question['finish'],
                    answers: answers
                };
            }));
        };
        fetchData();
    }, [navigate, id]);

    if (!test || !testStorage) {
        return null;
    }

    return (
        <div className={cx.trainingTestEnd}>
            <div className={cx.trainingTestEndContainer}>
                {test['gradingType'] === 0 && <h3>Тест пройден</h3>}
                {test['gradingType'] === 1 &&
                <h3>Тест{test['currentMark'] < test['minMark'] ? ' не ' : ' '}пройден ({test['currentMark']} / { test['maxMark'] })</h3>}
                <ul className={cx.trainingTestEndContainerInfo}>
                    <li>
                        <span>Потраченное время:</span>
                        <b>{new Date(test['time']).toISOString().substr(11, 8)}</b>
                    </li>
                    {/*<li>*/}
                    {/*    <span>Попыток осталось:</span>*/}
                    {/*    <b>{test['tryCount'] ? test['tryCount'] : 0}</b>*/}
                    {/*</li>*/}
                </ul>
                <p className={cx.trainingTestEndContainerDescription}>
                    {test['gradingType'] === 0
                        ? test['entTestMessage']
                        : test['currentMark'] < test['minMark']
                            ? test['endTestFailureMessage']
                            : test['endTestSuccessMessage']}
                </p>
                <div className={cx.trainingTestEndContainerDelimiter}/>
                <div className={cx.trainingTestEndContainerButtons}>
                    <Button onClick={() => navigate('/training')}>
                        <Icon type={Icons.ARROW_LEFT} width={20} height={20}/>
                        <span>Выйти из теста</span>
                    </Button>
                    <Button color="green" onClick={() => navigate('/training/test/' + id + '/process')}>
                        <Icon type={Icons.PLAY_FILL} width={18} height={18}/>
                        <span>Начать заново</span>
                    </Button>
                </div>
                <div className={cx.trainingTestEndContainerMarks}>
                    {test['questions'].map((item, index) => {
                        return (
                            <span className={item['isCorrect']
                                ? cx.trainingTestEndContainerMarksTrue
                                : cx.trainingTestEndContainerMarksFalse}>{index + 1}</span>
                        );
                    })}
                </div>
                <div className={cx.trainingTestEndContainerQuestions}>
                    {test['questions'].map((question) => {
                        const storageQuestion = testStorage['questions'].find(p => p['id'] === question['id']);

                        return (
                            <div className={cx.trainingTestEndContainerQuestionsItem}>
                                <h4>{question['title']}</h4>
                                <div className={cx.trainingTestEndContainerQuestionsItemDelimiter}/>
                                {question['type'] === 0 &&
                                <div className={cx.trainingTestEndContainerQuestionsItemMany}>
                                    <p className={cx.trainingTestEndContainerQuestionsItemDescription}>
                                        Выберите несколько правильных ответов:
                                    </p>
                                    <ul>
                                        {storageQuestion['answer'].map((answer) => {
                                            return (
                                                <li>
                                                    <Checkbox checked={answer['isChecked']}/>
                                                    <span>{answer['value1']}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>}
                                {question['type'] === 1 &&
                                <div className={cx.trainingTestEndContainerQuestionsItemOne}>
                                    <p className={cx.trainingTestEndContainerQuestionsItemDescription}>
                                        Выберите один правильный ответ:
                                    </p>
                                    <ul>
                                        {storageQuestion['answer'].map((answer) => {
                                            return (
                                                <li>
                                                    <Checkbox checked={answer['isChecked']}/>
                                                    <span>{answer['value1']}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>}
                                {question['type'] === 2 &&
                                <div className={cx.trainingTestEndContainerQuestionsItemOpen}>
                                    <p className={cx.trainingTestEndContainerQuestionsItemDescription}>
                                        Введите правильный ответ:
                                    </p>
                                    <p className={cx.trainingTestEndContainerQuestionsItemOpenAnswer}>
                                        {storageQuestion['answer']}
                                    </p>
                                </div>}
                                {question['type'] === 3 &&
                                <div className={cx.trainingTestEndContainerQuestionsItemNext}>
                                    <p className={cx.trainingTestEndContainerQuestionsItemDescription}>
                                        Впишите правильный ответ:
                                    </p>
                                    <div>
                                        <b>{question['title']}</b>
                                        <span>{storageQuestion['answer']}</span>
                                    </div>
                                </div>}
                                {question['type'] === 4 &&
                                <div className={cx.trainingTestEndContainerQuestionsItemMatch}>
                                    <p className={cx.trainingTestEndContainerQuestionsItemDescription}>
                                        Сопоставьте варианты:
                                    </p>
                                    <ul>
                                        {storageQuestion['answer'].map((answer) => {
                                            return (
                                                <li>
                                                    <b>{answer['value1']}</b>
                                                    <span>{answer['answer']['label']}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>}
                                {question['type'] === 5 &&
                                <div className={cx.trainingTestEndContainerQuestionsItemSort}>
                                    <p className={cx.trainingTestEndContainerQuestionsItemDescription}>
                                        Расположите ответы в правильном порядке:
                                    </p>
                                    <ul>
                                        {storageQuestion['answer'].map((answer) => {
                                            return (
                                                <li>{answer['value1']}</li>
                                            );
                                        })}
                                    </ul>
                                </div>}
                                {question.isCorrect !== 0 &&
                                <p className={question['isCorrect']
                                    ? cx.trainingTestEndContainerQuestionsItemCorrect
                                    : cx.trainingTestEndContainerQuestionsItemIncorrect}>
                                    Ваш ответ {!question['isCorrect'] ? 'не' : ' '}верный
                                </p>}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};