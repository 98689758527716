import React from 'react';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';

export const MenuButtonPageCode = ({ toggleIsCodeViewMode, isTooltip }) => {
    return (
        <MenuButton
            icon={Icons.EditorIconCodeView}
            tooltip={isTooltip === false ? null : 'Код страницы'}
            label="Код страницы"
            onClick={toggleIsCodeViewMode}
        ></MenuButton>
    );
};
