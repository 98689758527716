import { mergeAttributes, Node } from '@tiptap/core';

const getElementWithAttributes = (name, attrs, events) => {
    const el = document.createElement(name);

    if (!el) throw new Error(`Element with name ${name} can't be created.`);

    if (attrs) {
        Object.entries(attrs).forEach(([key, val]) => el.setAttribute(key, val));
    }

    if (events) {
        Object.entries(events).forEach(([key, val]) => el.addEventListener(key, val));
    }

    return el;
};

export const TableRow = Node.create({
    name: 'tableRow',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    content: '(tableCell | tableHeader)*',

    tableRole: 'row',

    parseHTML() {
        return [{ tag: 'tr' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['tr', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addNodeView() {
        return ({ HTMLAttributes }) => {
            const tableRow = getElementWithAttributes('tr', { ...HTMLAttributes });

            return {
                dom: tableRow,
                contentDOM: tableRow,
            };
        };
    },
});
