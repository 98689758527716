import React from 'react';
import api from 'api/index';
import cx from './training-test-base.module.scss';

export default function TrainingTestBase({ author, date, gradingType, usersCount, avatar, description, questions }) {
    return (
        <div className={cx.trainingTestViewContent}>
            <ul>
                <li>
                    <img src={author['image'] ? api.upload.getImage(author['image'], false, 128) : '/img/avatar.png'}
                         alt={author['firstName'] + ' ' + author['lastName']}/>
                    <div>
                        <span>Автор</span>
                        <p>{author['firstName'] + ' ' + author['lastName']}</p>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Дата создания:</span>
                        <p>{new Date(date).toLocaleString()}</p>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Оценивание:</span>
                        <p>{gradingType}</p>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Содержание:</span>
                        <p>{questions.length} вопросов</p>
                    </div>
                </li>
                <li>
                    <div>
                        <span>Назначен:</span>
                        <p>{usersCount} участников</p>
                    </div>
                </li>
            </ul>
            <img className={cx.trainingTestViewContentImage} src={avatar ? avatar : '/img/article-card-cover.jpg'} alt=""/>
            <p className={cx.trainingTestViewContentDescription}>{description}</p>
            <div className={cx.trainingTestViewContentQuestions}>
                {questions.map((item, index) => (
                    <div key={index} className={cx.trainingTestViewContentQuestion}>
                        {item.avatar && <img src={item.avatar} alt=""/>}
                        <h3>{item.title}</h3>
                        <div className={cx.trainingTestViewContentQuestionDelimiter}/>
                        <p>
                            <span>{item.type.label}</span>
                            <span>{item.answers.length} ответа (-ов)</span>
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}