import React, { useEffect, useMemo, useState } from 'react';
import { makeMoveable, Scalable } from 'react-moveable';

import { useClickOutside } from './use-click-outside';

const Moveable = makeMoveable([Scalable]);

const ResizableImageComponent = props => {
    const { updateAttributes, node, extension, editor } = props;
    const imageRef = useClickOutside(() => setFocused(false));

    const [focused, setFocused] = useState(false);
    // const [_scaleStartSize, setScaleStartSize] = useState({ width: null, height: null });

    const attrs = node.attrs;
    const options = extension.options;
    const disabled = !editor.isEditable;

    const { width, height } = attrs;
    const keepRatio = attrs['data-keep-ratio'];

    const scaleDirection = useMemo(() => {
        if (attrs['data-align'] === 'center') {
            return [0, -1];
        } else if (attrs['data-align'] === 'right') {
            return [1, -1];
        } else {
            return [-1, -1];
        }
    }, [attrs]);

    const style = !keepRatio && {
        width,
        height,
    };
    const sharedImageProps = {
        ...attrs,
        style: {
            ...(style || {}),
            cursor: !focused || attrs.link ? 'pointer' : 'default',
            width: width,
            // maxWidth: 600,
        },
    };

    const onScale = event => {
        event.target.style.transform = event.drag.transform;
    };

    const onScaleEnd = event => {
        const rect = event.target.getBoundingClientRect();
        // if (rect.width <= scaleStartSize.width) {
        //     updateAttributes({
        //         width: rect.width,
        //         height: rect.height,
        //     });
        // } else {
        // const width = rect.width > 600 ? 600 : rect.width;
        // const _height = keepRatio ? width * (rect.height / width) : rect.height;
        updateAttributes({
            width: rect.width,
            height: 'auto',
        });
        // }
        event.target.style.transform = '';
    };

    const onImageClick = () => {
        if (focused && attrs.link) {
            window.open(attrs.link, '_blank');
        }
        setFocused(true);
        editor.commands.setNodeSelection(props.getPos());
    };

    useEffect(() => {
        document.addEventListener('keydown', event => {
            if (event.ctrlKey && (event.key === 'a' || event.key === 'A')) {
                setFocused(true);
            }
        });
    }, []);

    if (disabled) {
        return <img alt={attrs.alt || ''} {...sharedImageProps} />;
    }

    return (
        <>
            <img
                alt={attrs.alt || ''}
                {...sharedImageProps}
                ref={imageRef}
                onClick={onImageClick}
                onDrag={() => setFocused(true)}
                onContextMenu={event => {
                    setFocused(true);
                    options.onContextMenu?.(event, {
                        setFocused,
                        ...props,
                    });
                }}
            />

            <Moveable
                target={focused ? imageRef : null}
                scalable={true}
                keepRatio={keepRatio}
                origin={false}
                throttleScale={0}
                renderDirections={['se', 'nw', 'ne', 'sw']}
                snappable={true}
                onScale={onScale}
                onScaleEnd={onScaleEnd}
                onScaleStart={e => {
                    // const rect = e.target.getBoundingClientRect();
                    // setScaleStartSize({ width: rect.width, height: rect.height });

                    e.setFixedDirection(scaleDirection);
                }}
                onBeforeScale={e => {
                    e.setFixedDirection(scaleDirection);
                }}
                {...options.moveableProps}
            />
        </>
    );
};

export default ResizableImageComponent;
