import { createSlice } from '@reduxjs/toolkit';
import NotificationConnect from 'api/notification-wss';
import { ChatConnect } from 'api/chat-wss';
import api from 'api/index';
import ApiAxios from 'api/api-axios';
import { EDITOR_CKEDITOR } from '../editors/constants';

let isInitialized = false;
const initialState = {
    session: null,
    sessionLoading: false,
    sessionHasBeenFetched: false,
    roles: null,
    user: null,
    users: null,
    globalPermActions: [],
    globalRootPermActions: [],
    menuButtons: null,
    config: {
        features: {
            aiServices: {
                enabled: false,
                url: null,
            },
            scripting: {
                enabled: true
            },
            education: {
                enabled: true
            },
        },
        common: {
            editor: {
                type: EDITOR_CKEDITOR, // 'CKEDITOR'|'TIPTAP'
                experimental: {
                    switchEditors: false
                }
            },
            search: {
                voice: true
            },
        }
    },
};

/*
    {
      "host": "kms-dev.ext.grancall.ru",
      "features": {
        "aiServices": {
          "enabled": true,
          "botServiceUrl": "https://chatbot.gran-soft.ru",
          "processingServiceUrl": "https://chatbot-processing.int.grancall.ru"
        },
        "scripting": {
          "enabled": false
        },
        "education": {
          "enabled": false
        }
      },
      "common": {
        "editor": {
          "type": "CKEDITOR"
        },
        "search": {
          "voice": false
        }
      }
    }
*/

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSession: (state, action) => {
            state.session = action.payload;
        },
        setSessionLoading: (state, action) => {
            state.sessionLoading = action.payload;
        },
        setSessionHasBeenFetched: (state, action) => {
            if (!action.payload) {
                isInitialized = false;
            }

            state.sessionHasBeenFetched = action.payload;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUsers: (state, action) => {
            state.users = action.payload;
        },
        setLogoutUrl: (state, action) => {
            state.logoutUrl = action.payload;
        },
        setGlobalPermActions: (state, action) => {
            state.globalPermActions = action.payload;
        },
        setGlobalRootPermActions: (state, action) => {
            state.globalRootPermActions = action.payload;
        },
        setMenuButtons: (state, action) => {
            state.menuButtons = action.payload;
        },
        invalidateState: () => initialState,
        decrementUnreadCounter: (state, action) => {
            state.menuButtons[action.payload] = Math.max(0, state.menuButtons[action.payload] - 1);
        },
        incrementUnreadCounter: (state, action) => {
            state.menuButtons[action.payload] = state.menuButtons[action.payload] + 1;
        },
        setConfig: (state, action) => {
            state.config = action.payload;
        },
    },
});

export const {
    setSession,
    setSessionLoading,
    setSessionHasBeenFetched,
    setRoles,
    setUser,
    setUsers,
    invalidateState,
    setGlobalPermActions,
    setGlobalRootPermActions,
    setMenuButtons,
    decrementUnreadCounter,
    incrementUnreadCounter,
    setLogoutUrl,
    setConfig,
} = authSlice.actions;

export const selectSession = state => state.auth.session;
export const selectSessionLoading = state => state.auth.sessionLoading;
export const selectSessionHasBeenFetched = state => state.auth.sessionHasBeenFetched;
export const selectRoles = state => state.auth.roles;
export const selectUser = state => state.auth.user;
export const selectUsers = state => state.auth.users;
export const selectGlobalPermActions = state => state.auth.globalPermActions;
export const selectGlobalRootPermActions = state => state.auth.globalRootPermActions;
export const selectMenuButtons = state => state.auth.menuButtons;
export const selectLogoutUrl = state => state.auth.logoutUrl;
export const selectChatAiEnabled = state => state.auth.config.features?.aiServices?.enabled;
export const selectScriptingEnabled = state => state.auth.config.features?.scripting?.enabled;
export const selectEducationEnabled = state => state.auth.config.features?.education?.enabled;
export const selectVoiceSearchEnabled = state => state.auth.config.common?.search?.voice;
export const selectEditorType = state => {
    const editorType = localStorage.getItem('editorType');
    return editorType ? editorType : state.auth.config.common?.editor?.type;
};
export const selectEditorExperimentalSwitch = state => state.auth.config.common?.editor?.experimental?.switchEditors;

export const getSession = () => async dispatch => {
    try {
        if (isInitialized) {
            return;
        }

        isInitialized = true;

        dispatch(setSessionLoading(true));
        const res = await api.auth.getAccount();

        NotificationConnect(res.data.login);
        ChatConnect();

        const data = await Promise.all([
            api.role.getUserRolesInfo(),
            api.user.getUserByLogin(res.data.login),
            api.user.getUsersInitList(),
            api.user.info(),
            api.globalMenu.getMenuButtons(),
            api.resource.getGlobalPermittedActions(),
            api.resource.getGlobalRootPermittedActions(),
            api.config.getConfig(),
        ]);

        dispatch(setRoles(data[0]));
        dispatch(setUser(data[1]));
        dispatch(setUsers({ content: data[2] }));

        ApiAxios.logoutUrl = data[3];
        dispatch(setLogoutUrl(data[3]));
        dispatch(setMenuButtons(data[4]));
        dispatch(setGlobalPermActions(data[5]));
        dispatch(setGlobalRootPermActions(data[6]));
        dispatch(setConfig(data[7]));

        dispatch(setSessionHasBeenFetched(true));
        dispatch(setSession(res.data));

        setInterval(async () => {
            api.globalMenu.getMenuButtons().then(result => dispatch(setMenuButtons(result)));
            api.resource.getGlobalPermittedActions().then(result => dispatch(setGlobalPermActions(result)));
            api.resource.getGlobalRootPermittedActions().then(result => dispatch(setGlobalRootPermActions(result)));
        }, 60000);
    } catch (error) {
        if (!error.response) {
            console.log('Auth Slice Redirect', error, ApiAxios.logoutUrl);

            if (window.location.pathname !== '/login') {
                if (process.env.NODE_ENV === 'development') {
                    document.location.replace('/login');
                } else {
                    if (ApiAxios.logoutUrl) {
                        document.location.replace(ApiAxios.logoutUrl);
                    } else {
                        document.location.reload();
                    }
                }
            }
        }

        dispatch(setSessionHasBeenFetched(true));
    } finally {
        dispatch(setSessionLoading(false));
    }
};

export const clearSession = () => async dispatch => {
    try {
        await api.auth.logout();
        dispatch(invalidateState());
        document.location.replace('/');
    } catch (error) {
        console.log(error);
    }
};

export const fetchMenuButtons = () => async dispatch => {
    dispatch(setMenuButtons(await api.globalMenu.getMenuButtons()));
};

export default authSlice.reducer;
