import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import Button from 'uikit/button';
import Checkbox from 'uikit/checkbox';
import api from 'api';
import cx from './chat-group-add.module.scss';
import IconButton from 'uikit/icon-button';

export default function ChatGroupAdd({ isOpen, isAdmins, groups, users, onApprove, onClose }) {
    const [search, setSearch] = useState('');

    const [groupsData, setGroupsData] = useState([]);
    const [usersData, setUsersData] = useState([]);

    const onGroupSelect = (groupId) => {
        const groups = Object.assign([], groupsData).map((group) => {
            if (group['id'] === groupId) {
                group.isSelect = !group.isSelect;
            }

            return group;
        });

        setGroupsData(groups);
    };
    const onUserSelect = (userId) => {
        const users = Object.assign([], usersData).map((user) => {
            if (user['id'] === userId) {
                if (isAdmins) {
                    user.isAdmin = !user.isAdmin;
                } else {
                    user.isSelect = !user.isSelect;
                }
            }

            return user;
        });

        setUsersData(users);
    };
    const onApproveClick = () => {
        const approveGroups = groups.map((group) => {
            const approveGroup = groupsData.find((item) => group.id === item.id);
            return approveGroup ? approveGroup : group;
        });

        const approveUsers = users.map((user) => {
            const approveUser = usersData.find((item) => user.id === item.id);
            return approveUser ? approveUser : user;
        });

        onApprove(approveGroups, approveUsers);
    };

    const showGroups = groupsData.filter((group) => {
        return group.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });
    const showUsers = usersData.filter((user) => {
        return user.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    useEffect(() => {
        const fetchData = async () => {
            setGroupsData(JSON.parse(JSON.stringify(groups)));
            setUsersData(JSON.parse(JSON.stringify(users)));
        };

        fetchData();
    }, [groups, users]);

    return (
        <Modal isOpen={isOpen} className={cx.chatGroupAdd} overlayClassName={cx.chatGroupAddOverlay}>
            <div className={cx.chatGroupAddHeader}>
                <div className={cx.chatGroupAddHeaderTop}>
                    <h3>Добавить в чат</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={onClose}/>
                </div>
                <div className={cx.chatGroupAddHeaderBottom}>
                    <div className={cx.chatGroupAddHeaderBottomSearch}>
                        <Input placeholder="Поиск по имени ..." value={search} onChange={(value) => setSearch(value)}/>
                        <Icon type={Icons.SEARCH} width={13} height={13}/>
                    </div>
                </div>
            </div>
            <div className={cx.chatGroupAddBody}>
                {showGroups.length === 0 && showUsers.length === 0 &&
                <div className={cx.chatGroupAddBodyEmpty}>
                    <Icon type={Icons.SEARCH} width={50} height={50}/>
                    <p>Ничего не найдено</p>
                </div>}
                {showGroups.length !== 0 &&
                <div className={cx.chatGroupAddBodyGroups}>
                    <h4>Группы</h4>
                    <div className={cx.chatGroupAddBodyGroupsItems}>
                        {showGroups.map((group) => {
                            return (
                                <div key={group.id} className={cx.chatGroupAddBodyGroupsItemsItem}
                                     onClick={() => onGroupSelect(group.id)}>
                                    <Checkbox checked={group.isSelect} onClick={() => onGroupSelect(group.id)}/>
                                    <div className={cx.chatGroupAddBodyGroupsItemsItemAvatar}>
                                        {group.avatar && <img src={group.avatar} alt="" />}
                                    </div>
                                    <p>{group.title}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>}
                {showUsers.length !== 0 &&
                <div className={cx.chatGroupAddBodyUsers}>
                    <h4>{isAdmins ? 'Администраторы' : 'Участники'}</h4>
                    <div className={cx.chatGroupAddBodyUsersItems}>
                        {showUsers.map((user) => {
                            return (
                                <div key={user.id} className={cx.chatGroupAddBodyUsersItemsItem}
                                     onClick={() => onUserSelect(user.id)}>
                                    <Checkbox checked={isAdmins ? user.isAdmin : user.isSelect}
                                              onClick={() => onUserSelect(user.id)}/>
                                    <div className={cx.chatGroupAddBodyUsersItemsItemAvatar}>
                                        {user.avatar &&
                                        <img src={api.upload.getImage(user.avatar, false, 128)} alt="" />}
                                    </div>
                                    <p>{user.title}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>}
            </div>
            <div className={cx.chatGroupAddFooter}>
                <Button label="Отмена" onClick={onClose}/>
                <Button color="green" label="Подтвердить" onClick={onApproveClick}/>
            </div>
        </Modal>
    );
};
