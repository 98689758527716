import ApiClient from './api-axios';

const INF = 10000;

export default class TagApi extends ApiClient {

    getCategories = async () => {
        return await this.get('document-tag-categories');
    }

    getCategory = async (id) => {
        return await this.get(`document-tag-categories/${id}`);
    }

    deleteCategory = async (id) => {
        return await this.deleteRequest(`document-tag-categories/${id}`);
    }

    saveCategory = async (name) => {
        return await this.post('document-tag-categories', { name });
    }

    patchCategory = async (id, name) => {
        return await this.put(`document-tag-categories/${id}`, { id, name });
    }

    addTag = async (categoryId, tagName) => {
        return await this.post('document-tags', { documentTagCategory: { id: categoryId}, name: tagName });
    }

    deleteTag = async (tagId) => {
        return await this.deleteRequest(`document-tags/${tagId}`);
    }

    getTags = async () => {
        return await this.get(`document-tags?size=${INF}`);
    }
}
