import { useCallback, useEffect, useRef } from 'react';

export const useRequestAnimationFrame = callback => {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = useCallback(
    time => {
      if (previousTimeRef.current) callback(time - previousTimeRef.current);
      previousTimeRef.current = time;
      requestRef.current = requestAnimationFrame(animate);
    },
    [callback]
  );

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    if (requestRef.current) {
      return () => cancelAnimationFrame(requestRef.current ?? 0);
    }
  }, [animate]);
};
