import React from 'react';
import { Icons } from 'uikit/icon';
import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';

export const MenuButtonCalloutInfo = props => {
    const editor = useEditorContext();

    const addInfo = () => {
        props.setCurrentMenu(undefined);
        editor?.chain().focus().addCallout({ type: 'yellow' }).run();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconInfo}
            onClick={addInfo}
            tooltip={props.isTooltip === false ? null : 'Информационный блок'}
            label="Информационный блок"
            selected={editor?.isActive('callout', { type: 'info' }) ?? undefined}
        ></MenuButton>
    );
};
