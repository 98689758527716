import React from 'react';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';

import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { desktopStyles } from 'lib/util/modalStyles';
import { MenuButton } from './MenuButton';
import api from 'api/index';
import cx from '../../editor.module.scss';
import Icon from 'uikit/icon/icon';
import { ModalHeader } from 'components/modal/modal-components';
import { ModalFooter } from 'components/modal/modal-components';
import { ModalBody } from 'components/modal/modal-components';
import Loading from 'uikit/loading/loading';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';
import Button from 'uikit/button';

export const MenuButtonVideo = ({ mode, initId, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const [show, setShow] = React.useState(false);

    const toggleModal = () => {
        setCurrentMenu(undefined);
        setShow(show => !show);
    };

    const handleSubmit = res => {
        editor.chain().focus().setVideo(res.url).run();
        setShow(false);
    };

    return (
        <>
            <MenuButton
                icon={Icons.EditorIconVideo}
                onClick={toggleModal}
                tooltip={isTooltip === false ? null : 'Видео'}
                label="Видео"
            ></MenuButton>
            {show && <AddVideoModal mode={mode} initId={initId} show={show} onClose={toggleModal} onSubmit={handleSubmit} />}
        </>
    );
};

const AddVideoModal = ({ mode, show, initId, onClose, onSubmit }) => {
    const [files, setFiles] = React.useState(null);
    const [fileInfo, setFileInfo] = React.useState(null);
    const [url, setUrl] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const handleAndInsertNewFiles = async files => {
        if (files.length === 0) {
            return;
        }

        const file = files[0];

        if (!file) {
            return;
        }

        setLoading(true);
        const formData = new FormData();

        const re = new RegExp('XSRF-TOKEN=([^;]+)');
        const value = re.exec(document.cookie);

        formData.append('init', initId);
        formData.append('upload', file);
        formData.append('ckCsrfToken', value !== null ? decodeURI(value[1]) : null);

        const res = await api.file.uploadEditorFile(formData);

        if (url) {
            setUrl('');
        }

        setFiles(res);
        setFileInfo(file);
        setLoading(false);
    };

    const removeFile = () => {
        setFiles(null);
        setFileInfo(null);
    };

    const handleSubmit = () => {
        onSubmit(url ? { url } : files);
    };

    return (
        <Modal
            isOpen={show}
            preventScroll
            contentLabel={'Добавление видео'}
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 568, width: 'calc(100% - 20px)' } }}
        >
            <ModalHeader title="Добавление видео" onClose={onClose} />
            <ModalBody>
                {!loading && !fileInfo && (
                    <Dropzone onDrop={handleAndInsertNewFiles}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: mode === 'DESKTOP' ? cx.dropZone : cx.dropZoneButton })}>
                                <input {...getInputProps()} />
                                {mode === 'DESKTOP' && (
                                    <>
                                        <Icon type={Icons.DOWNLOAD} width={24} height={24} color="blue" />
                                        <p>Перетащите файл в эту область или нажмите для выбора файла с компьютера</p>
                                    </>
                                )}
                                {mode !== 'DESKTOP' && (
                                    <Button color="blue">
                                        <Icon type={Icons.DOWNLOAD} width={18} height={18} />
                                        <span>Загрузить видео</span>
                                    </Button>
                                )}
                            </div>
                        )}
                    </Dropzone>
                )}
                {loading && (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Loading size={120} withOverlay={false} withRelativeOverlay={false} />
                    </div>
                )}
                {!loading && fileInfo && (
                    <div className={cx.fileWrapper}>
                        <div className={cx.file}>
                            <Icon
                                className={cx.fileIcon}
                                width={24}
                                height={24}
                                type={FILES_TYPE_ICONS[fileInfo?.type] || Icons.NONE_FILE}
                            />
                            <div>
                                <div className={cx.name}>
                                    <div className={cx.text}>{fileInfo.name}</div>
                                    <div className={cx.buttons}>
                                        <Icon
                                            id={'attachmentsDeleteIcon'}
                                            className={cx.icon}
                                            width={20}
                                            height={20}
                                            type={Icons.TRASH}
                                            tooltip={'Удалить'}
                                            onClick={removeFile}
                                        />
                                    </div>
                                </div>
                                <div className={cx.size}>
                                    {FILES_TYPE_SHORT_NAME[fileInfo?.type]}
                                    &nbsp;—&nbsp;
                                    {humanFileSize(fileInfo?.size, true)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: onClose },
                    { label: 'Сохранить', color: 'blue', onClick: handleSubmit, disabled: loading || !fileInfo },
                ]}
            />
        </Modal>
    );
};
