import { useEffect, useState } from 'react';
import api from 'api';

export const useSection = (sectionId) => {
    const [section, setSection] = useState();

    useEffect(() => {
        if (sectionId) {
            (async function() {
                try {
                    const response = await api.section.getSection(sectionId);
                    response.section.projectId = response.parents.projectId;
                    setSection(response.section);
                  } catch(_) {
                  setSection(1);
                    // do nothing
                }
            })();
        }

        return () => {
            setSection(null);
        }
    }, [sectionId]);

    return section;
}
