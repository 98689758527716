import { tree } from 'components/trees/statisctics-tree/statistics-tree-data'

export const reportTitle = (title) => {
    for (let i = 0; i < tree.length; i++) {
        for (let j = 0; j < tree[i].children.length; j++) {
            if (tree[i].children[j].id === title.toUpperCase()) {
                return tree[i].children[j].name;
            }
        }
    }
}
