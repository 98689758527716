import DOMPurify from 'dompurify';

export const formatMessage = message => {
    let messageTemp = DOMPurify.sanitize(message, {
        ADD_TAGS: [
            'iframe'
        ],
        ADD_ATTR: [
            'allow',
            'allowfullscreen',
            'frameborder',
            'scrolling',
            'target'
        ]
    });
    messageTemp = messageTemp.replace(
        // eslint-disable-next-line no-useless-escape
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
        match => `<a href="${match}" target="_blank" rel="noreferrer nofollor">` + match + '</a>'
    );

    return messageTemp;
};
