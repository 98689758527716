import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonBlockqoute = ({ setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const toggleBlockquote = () => {
        setCurrentMenu(undefined);
        editor?.chain().focus().toggleBlockquote().run();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconQuote}
            onClick={toggleBlockquote}
            tooltip={isTooltip === false ? null : 'Цитата'}
            label="Цитата"
        ></MenuButton>
    );
};
