import { mergeAttributes, Node, nodeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { Plugin, PluginKey } from '@tiptap/pm/state';

import ResizableImageNodeView from './ResizableImageNodeView';

const inputRegex = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const ResizableImage = Node.create({
    name: 'resizableImage',
    group: 'inline',
    inline: true,
    selectable: true,

    addOptions() {
        return {
            HTMLAttributes: {},
            defaultWidth: '600',
            maxWidth: '100%',
            allowBase64: true,
            onUpload: null,
        };
    },

    addStorage() {
        return {
            initId: null,
        };
    },

    addAttributes() {
        return {
            src: {
                default: '',
            },
            alt: {
                default: '',
            },
            title: {
                default: '',
            },
            width: {
                default: this.options.defaultWidth,
                parseHTML: el => {
                    const width = el.getAttribute('width');
                    const height = el.getAttribute('height');

                    if (!height && !width) {
                        return this.options.defaultWidth;
                    } else if (height && !width) {
                        return 'auto';
                    } else if (width > 0 && width < 600) {
                        return width;
                    } else if ((!height && width) || (height && width)) {
                        return width;
                    } else {
                        return 600;
                    }
                },
                renderHTML: attrs => {
                    if (!attrs.width) {
                        return {};
                    }

                    return {
                        width: attrs.width,
                        style: `width: ${attrs.width}px`,
                    };
                },
            },
            height: {
                default: 'auto',
            },
            loading: {
                default: 'lazy',
            },
            'data-keep-ratio': {
                parseHTML: element => {
                    return element.getAttribute('data-keep-ratio') !== 'false';
                },
                renderHTML(attributes) {
                    if (!attributes['data-keep-ratio']) {
                        return {};
                    }
                    return {
                        style: [`max-width: ${attributes.width}px`].join(';'),
                        'data-keep-ratio': 'true',
                    };
                },
                default: true,
            },
            'data-align': {
                default: 'center',
                parseHTML: el => el.getAttribute('data-align'),
                renderHTML: attrs => {
                    if (!attrs['data-align']) {
                        return {};
                    }

                    return {
                        'data-align': attrs['data-align'],
                    };
                },
            },
            link: {
                default: null,
            },
            className: {
                parseHTML: element => {
                    return element.getAttribute('class');
                },
                renderHTML: attrs => {
                    return {
                        class: attrs.className,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'img[src]',
                getAttrs: el => {
                    return !el.src.includes('smiley') && null;
                },
            },
        ];
    },

    renderHTML({ _node, HTMLAttributes }) {
        if (HTMLAttributes.link) {
            return [
                'span',
                {
                    class: 'node-imageComponent',
                },
                [
                    'a',
                    { href: HTMLAttributes.link, target: '_blank', rel: 'nofollow' },
                    ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { loading: 'lazy' })],
                ],
            ];
        } else {
            return [
                'span',
                {
                    class: 'node-imageComponent',
                },
                ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { loading: 'lazy' })],
            ];
        }
    },

    addCommands() {
        return {
            setResizableImage:
                attrs =>
                ({ commands }) => {
                    return commands.insertContent({
                        type: 'resizableImage',
                        attrs,
                    });
                },
        };
    },

    addInputRules() {
        return [
            nodeInputRule({
                find: inputRegex,
                type: this.type,
                getAttributes: match => {
                    const [, , alt, src, title] = match;

                    return { src, alt, title };
                },
            }),
        ];
    },
    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey(this.name),
                props: {
                    handleDrop: (_, dragEvent) => {
                        dragEvent.preventDefault();
                        const files = dragEvent.dataTransfer?.files;

                        const isDroppedFromFileSystem = !dragEvent.dataTransfer?.getData('text/html');

                        if (!files || files.length === 0 || !this.options.onUpload || !isDroppedFromFileSystem) {
                            return false;
                        }

                        const position = this.editor.state.selection.head;
                        for (const file of files) {
                            this.options.onUpload(file, this.editor.storage.resizableImage.initId).then(attrs => {
                                this.editor.chain().focus().setResizableImage(attrs, position, { updateSelection: false }).run();
                            });
                        }
                    },
                },
            }),
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ResizableImageNodeView, {
            contentDOMElementTag: 'span',
            as: 'span',
        });
    },
});
