function plural(x, text) {
    const n = Math.abs(x);
    const modulo100 = n % 100;

    if (modulo100 >= 11 && modulo100 <= 14) {
        return text[0];
    }

    const modulo10 = n % 10;

    if (modulo10 === 1) {
        return text[1];
    }

    if (modulo10 >= 2 && modulo10 <= 4) {
        return text[2];
    }

    return text[0];
}

const GrammarUtils = { plural };
export default GrammarUtils;