import React from 'react';
import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';
import { AllSelection } from '@tiptap/pm/state';

export const MenuButtonSelectAll = ({ isCodeViewMode, cmSelectAll, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const selectAll = () => {
        setCurrentMenu(undefined);

        if (isCodeViewMode) {
            cmSelectAll();
        } else {
            const { tr, doc } = editor.state;
            const { dispatch } = editor.view;

            dispatch(tr.setSelection(new AllSelection(doc)));
        }
    };

    return (
        <MenuButton
            icon={Icons.EditorIconSelectAll}
            tooltip={isTooltip === false ? null : 'Выделить всё'}
            label="Выделить всё"
            onClick={selectAll}
        ></MenuButton>
    );
};
