
export const requestActionTypes = {
  ACTIONS_IN_PROJECTS: 'project',
  ACTIONS_IN_SECTIONS: 'section',
  ACTIONS_IN_ARTICLES: 'article',
  ACTIONS_IN_NEWS: 'news',
};

export const articleSearchBy = [
  { value: 'ALL_ARTICLES', label: 'Вся система' },
  { value: 'SECTION_ARTICLES', label: 'Проекты и разделы' },
  { value: 'CUSTOM_ARTICLES', label: 'Выборочные документы' },
];
export const objectTypes = [
  { value: 'ARTICLE', label: 'Статьи' },
  { value: 'NEWS', label: 'Новости' },
];

export const newsSearchBy = [
  { value: 'PROJECT_NEWS', label: 'Проектные новости' },
  { value: 'ALL_NEWS', label: 'Все новости' },
  { value: 'GLOBAL_NEWS', label: 'Общие новости' },
];

export const dateOptions = [
  { value: 0, label: '1 день' },
  { value: 1, label: '3 дня' },
  { value: 3, label: '1 неделя' },
  { value: 4, label: '2 неделя' },
  { value: 5, label: '1 месяц' },
  { value: 6, label: '2 месяца' },
  { value: 7, label: '6 месяца' },
  { value: 8, label: '1 год' },
];

export const sortTypesOptions = [
  { label: 'По убыванию', value: 'desc' },
  { label: 'По возрастанию', value: 'asc' },
];

export const popularityTypes = [
  { label: 'Лайк', value: 'LIKE' },
  { label: 'Дизлайк', value: 'DISLIKE' },
];

export const documentStatusOptions = [
  { label: 'Любой', value: '' },
  { label: 'Архивные', value: 'ARCHIVED' },
  { label: 'Активные', value: 'ACTIVE' },
  { label: 'Удаленные', value: 'DELETED' },
];

export const newsPriorityStatusOptions = [
  { label: 'Любой', value: '' },
  { label: 'Срочные новости', value: 'HIGH' },
  { label: 'Обычные новости', value: 'MEDIUM' },
];

export const newsTickerOptions = [
  { label: 'Любой', value: null },
  { label: 'Да', value: true },
  { label: 'Нет', value: false },
];

export const priority = {
  HIGH: 'Срочная',
  MEDIUM: 'Обычная',
};