import React, { useEffect, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import cx from './mobile-tree.module.scss';
import InfiniteTree from 'react-infinite-tree';
import { useCombinedRefs } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import MobileStatisticsTreeNode from 'components/trees/mobile-tree/mobile-statistics-tree-node';
import { tree } from '../statisctics-tree/statistics-tree-data';

const MobileStatisticsTree = ({ forwardedRef, onSelect, search = false, onLoadTree = null }) => {
	const treeRef = useCombinedRefs(forwardedRef);

	const [query, setQuery] = useState(null);

	useEffect(() => {
		if (treeRef.current) {
			treeRef.current.tree.loadData(tree);
			if (onLoadTree) {
				onLoadTree(treeRef.current.tree, treeRef);
			}
		}
	}, [treeRef, onLoadTree]);

	useEffect(() => {
		if (treeRef.current) {
			treeRef.current.tree.filter(query);
		}
	}, [treeRef, query])

	const onClick = (node) => {
		treeRef.current.tree.selectNode(node);
		onSelect(node);
	}

	return (
		<div className={cx.container}>
			{search && <div className={cx.search}>
				<Icon className={cx.searchIcon} type={Icons.SEARCH} width={13} height={13}/>
				<input
                    type="text"
                    placeholder="Поиск по дереву..."
                    value={query}
                    onChange={e => setQuery(e.target.value)}/>
			</div>}
			<div className={cx.treeContainer}>
				<AutoSizer>
					{({ height }) => {
						return (
							<InfiniteTree ref={treeRef} tabIndex={0} autoOpen={false} width='100%' height={height} rowHeight={48} data={tree}>
								{({ node, tree }) => {
									const hasChildren = node.hasChildren();
									let toggleState = '';

									if ((!hasChildren && node.loadOnDemand) || (hasChildren && !node.state.open)) {
										toggleState = 'closed';
									}

									if (hasChildren && node.state.open) {
										toggleState = 'opened';
									}
									return <MobileStatisticsTreeNode node={node} tree={tree} toggleState={toggleState} onClick={onClick}/>
								}}
							</InfiniteTree>
						);
					}}
				</AutoSizer>
			</div>
		</div>
	)
}

export default React.forwardRef((props, ref) => <MobileStatisticsTree {...props} forwardedRef={ref}/>);
