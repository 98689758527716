import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import Icon, { Icons } from 'uikit/icon';
import { FileViewDialog } from 'components/file/file-view-dialog';
import FileUsageList from 'components/file/file-usage-list';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';

import api from 'api';

import cx from '../pages/search-result-page.module.scss';
import style from 'components/file/file-view-dialog.module.scss';
import Loading from 'uikit/loading';
import ButtonMenu from 'uikit/button-menu';

function FilesList({ files, typeSort, orderSort, resourceTypeCount, sortTypeChange, orderTypeChange, onLoadFiles }) {
    const [fileViewData, setFileViewData] = useState(null);
    const [fileInfo, setFileInfo] = useState(null);
    const [downloading, setDownloading] = useState(false);

    const [isViewFileModal, toggleViewFileModal] = useState(false);
    const [isUsageModalOpen, toggleIsUsageModalOpen] = useState(false);

    const patch = (item) => {
        if (!item) {
            return;
        }

        let path = item.map((item) => item.title);
        return path.join(' / ');
    };

    const title = (patch, item) => {
        let title;

        if (item.highlights.TITLE) {
            title = item.highlights.TITLE;
        } else {
            title = item.title;
        }

        if (patch) {
            return patch + ' / ' + title;
        } else {
            return title;
        }
    };

    const fileType = (title) => {
        let value = title.split('.')[title.split('.').length - 1].toUpperCase();

        if (value === 'JPEG') {
            return Object.keys(FILES_TYPE_SHORT_NAME).find((key) => FILES_TYPE_SHORT_NAME[key] === 'JPG');
        } else if (value === 'DOCX') {
            return Object.keys(FILES_TYPE_SHORT_NAME).find((key) => FILES_TYPE_SHORT_NAME[key] === 'DOC');
        } else {
            return Object.keys(FILES_TYPE_SHORT_NAME).find((key) => FILES_TYPE_SHORT_NAME[key] === value);
        }
    };

    const fileView = async (id) => {
        try {
            const res = await api.file.getFileByUuid(id);
            setFileViewData(res);
            toggleViewFileModal(true);
        } catch (e) {
            console.log(e);
        }
    };

    const onFileViewClose = () => {
        toggleViewFileModal(false);
    };

    const openUsage = async (id) => {
        try {
            const response = await api.file.getFileByUuid(id);

            setFileInfo(response['fileId']);
            toggleIsUsageModalOpen(true);
        } catch (e) {
            console.log(e);
        }
    };

    const downloadFile = async (item) => {
        try {
            setDownloading(true);
            const res = await api.file.download(item.id);
            const blob = new Blob([res], { type: fileType(item.title) });
            saveAs(blob, item.title);
        } catch (e) {
            console.log(e);
        } finally {
            setDownloading(false);
        }
    };

    return (
        <>
            <div className={cx.searchResultContent}>
                <Loading active={downloading} withOverlay />
                <div className={cx.searchResultContentHeader}>
                    <h4>Файлы</h4>
                    {files.length !== 0 && (
                        <div className={cx.searchResultContentHeaderSorting}>
                            <span>Сортировать:</span>
                            <ButtonMenu className={cx.navigationMenu} toggler={(showMenu) => (
                                <button className={cx.navigationMenuTitle} onClick={showMenu}>{typeSort}</button>
                            )} items={[
                                {
                                    title: 'По умолчанию',
                                    onClick: () => sortTypeChange('По умолчанию')
                                },
                                {
                                    title: 'По дате добавления',
                                    onClick: () => sortTypeChange('По дате добавления')
                                },
                                {
                                    title: 'По дате изменения',
                                    onClick: () => sortTypeChange('По дате изменения')
                                },
                                {
                                    title: 'По автору',
                                    onClick: () => sortTypeChange('По автору')
                                },
                                {
                                    title: 'По популярности',
                                    onClick: () => sortTypeChange('По популярности')
                                }
                            ]} />
                            <span className={cx.order}>
                                <p onClick={() => orderTypeChange(!orderSort)}>
                                    {orderSort && (
                                        <span>
                                            По возрастанию{' '}
                                            <span className={cx.union}>
                                                <Icon type={Icons.UNION} height={8} width={8} />
                                            </span>
                                        </span>
                                    )}
                                    {!orderSort && (
                                        <span>
                                            По убыванию{' '}
                                            <span className={cx.union}>
                                                <Icon type={Icons.UNION} height={8} width={8} />
                                            </span>
                                        </span>
                                    )}
                                </p>
                            </span>
                        </div>
                    )}
                </div>
                <div className={cx.searchResultContentBody}>
                    {files.length === 0 && (
                        <div className={cx.searchResultContentBodyEmpty}>
                            <Icon type={Icons.FILES} width={56} height={56} />
                            <div>
                                <p>Файлы не найдены</p>
                                <span>По данному запросу не найдено ни одного файла</span>
                            </div>
                        </div>
                    )}
                    {files.length !== 0 &&
                        files.map((item) => {
                            return (
                                <div key={item['id']} className={cx.fileItem}>
                                    <div className={cx.generalInfoContainer} onClick={() => fileView(item.id)}>
                                        <div className={cx.logoContainer}>
                                            <Icon width={24} height={24} type={FILES_TYPE_ICONS[fileType(item.title)] || Icons.NONE_FILE} />
                                        </div>
                                        <div className={cx.descriptionContainer}>
                                            <p className={cx.title}>{ReactHtmlParser(title(patch(item.breadcrumbs), item))}</p>
                                            <p className={cx.coincidences}>
                                                {FILES_TYPE_SHORT_NAME[fileType(item.title)] + ' — ' + humanFileSize(item.size, true)}
                                            </p>
                                        </div>
                                    </div>
                                    <div className={cx.additionalInfoContainer}>
                                        <Icon type={Icons.INFO} width={20} height={20} onClick={() => openUsage(item.id)} />
                                        <Icon type={Icons.DOWNLOAD} width={20} height={20} onClick={() => downloadFile(item)} />
                                    </div>
                                </div>
                            );
                        })}
                    {files.length !== 0 && resourceTypeCount !== files.length && (
                        <div className={cx.searchResultContentBodyMore} onClick={() => onLoadFiles(true)}>
                            <Icon type={Icons.PLAY_FILL} width={14} height={14} />
                            <span>Показать все файлы&nbsp; (+{resourceTypeCount - files.length})</span>
                        </div>
                    )}
                </div>
            </div>

            {isViewFileModal &&
            <FileViewDialog file={fileViewData} isNoActions={true} handleClose={onFileViewClose} onChange={onLoadFiles}/>}

            <Modal
                isOpen={isUsageModalOpen}
                className={style.usage}
                onRequestClose={() => toggleIsUsageModalOpen(false)}
                overlayClassName={style.usageOverlay}
            >
                <FileUsageList uuid={fileInfo} handleClose={() => toggleIsUsageModalOpen(false)} />
            </Modal>
        </>
    );
}

FilesList.defaultProps = {
    files: [],
    typeSort: 'По умолчанию',
    orderSort: false,
    resourceTypeCount: 0,
    sortTypeChange: () => {},
    orderTypeChange: () => {},
    onLoadFiles: () => {},
};

FilesList.propTypes = {
    files: PropTypes.arrayOf(PropTypes.object),
    typeSort: PropTypes.string,
    orderSort: PropTypes.bool,
    resourceTypeCount: PropTypes.number,
    sortTypeChange: PropTypes.func,
    orderTypeChange: PropTypes.func,
    onLoadFiles: PropTypes.func,
};

export default FilesList;
