import React from 'react';
import { Link, useLocation } from '@reach/router';
import { useAuthorized } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import cx from './training-navigation.module.scss';

export default function TrainingNavigation() {
    const location = useLocation();
    const { isAuthorizedAction } = useAuthorized();

    return (
        <>
            <ul className={cx.trainingNavigation}>
                <li>
                    <Link to="/training">Открытые тесты</Link>
                </li>
                {isAuthorizedAction([GLOBAL_ACTIONS.TRAINING]) &&
                <li>
                    <Link to="/training/all">Все тесты</Link>
                </li>}
                {isAuthorizedAction([GLOBAL_ACTIONS.TRAINING]) &&
                <li>
                    <Link to="/training/catalog"
                          className={location.pathname.indexOf('/catalog') !== -1 ? cx.trainingNavigationActive : null}>
                        Каталог вопросов
                    </Link>
                </li>}
            </ul>
        </>
    );
};
