import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { Link, useNavigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { setUsers as setUsersReducer } from 'slice/authSlice';
import PrivatePage from 'containers/private-page-wrapper';
import { TableComponent } from 'components/data-components';
import Confirmation from 'components/confirmation';
import { EditRowToolbar } from 'uikit/table';
import { Icons } from 'uikit/icon';
import api from 'api';
import cx from './blocked-users-page.module.scss';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import { EmptyCell } from 'components/data-components/table-component';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import Loading from 'uikit/loading';
import { Empty } from 'components/projects';

const BlockedUsersPage = ({ hasAnyAuthorities, id, project }) => {
    const tableRef = useRef(null);

    const { width } = useWindowSize();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { platform } = useGlobalContext();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [total, setTotal] = useState(0);
    const [localUsers, setLocalUsers] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const onPaginate = useCallback(async (offset, count, sort, search, filters) => {
        try {
            setIsLoading(true);

            let usersData;

            if (!id || id === 'all') {
                usersData = await api.user.getBlockedUsers(project,offset / count, count,
                    sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '', search, filters);
            } else {
                usersData = await api.user.getBlockedUsers(project, offset / count, count,
                    sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '', search, filters);
            }

            const usersTmp = usersData.data.content.map(item => {
                return {
                    ...item,
                    position: item['position'] ? item['position'] : <EmptyCell/>,
                    office: item['office'] ? item['office'] : <EmptyCell/>,
                    supervisor: item['superVisor'] ? item['superVisor'] : <EmptyCell/>,
                    lastModifiedDate: item['lastModifiedDate']
                        ? new Date(item['lastModifiedDate']).toLocaleString().slice(0, -3)
                        : new Date(item['createdDate']).toLocaleString().slice(0, -3)
                };
            });

            setLocalUsers(usersTmp);
            setTotal(parseInt(usersData.data['totalElements']));

            setIsEmpty(parseInt(usersData.data['totalElements']) === 0 && !search && Object.keys(filters).length === 0);
        } catch {

        } finally {
            setIsLoading(false);
            setIsReLoading(false);
        }
    }, [id, project]);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, []);

    const removeUser = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.user.deleteUser(data.login);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.user.deleteUser(checkedCheckboxRef.current[i].login);
                }
            }

            const users = await api.user.getUsers(0, 999999);
            dispatch(setUsersReducer(users.data));

            tableRef?.current?.reload();
            addSuccess(data.id ? 'Участник удален' : 'Выбранные участники удалены');
        } catch (error) {
            addError('При удалении произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, closeDialog, addSuccess, addError]);
    const onRemoveUser = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/edit-user/${item.original.login}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.firstName} {item.original.lastName}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите удалить участника&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/edit-user/${data.login}`)}>
                        {data?.firstName} {data.lastName}
                    </span>?
                </span>
            );
        }

        openDialog({
            title: 'Удаление',
            subTitle: data?.length ? 'Вы действительно хотите удалить нижеперечисленных участников? Этот процесс нельзя будет отменить' : null,
            text,
            color: 'red',
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (users) => checkboxHandler(users),
            onSubmit: () => removeUser(data),
            onClose: closeDialog
        });
    }, [closeDialog, openDialog, removeUser, navigate, checkboxHandler]);

    const unblockUser = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.user.unBlockUser(data.login);
            }  else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.user.unBlockUser(checkedCheckboxRef.current[i].login);
                }
            }

            const users = await api.user.getUsers(0, 999999);
            dispatch(setUsersReducer(users.data));

            tableRef?.current?.reload();
            addSuccess(data.id ? 'Участник разблокирован' : 'Выбранные участники разблокированы');
        } catch (error) {
            addError('При восстановлении произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, closeDialog, addSuccess, addError]);
    const onUnblockUser = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/edit-user/${item.original.login}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.firstName} {item.original.lastName}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы подтверждаете разблокирование участника&nbsp;
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/edit-user/${data.login}`)}>
                        {data?.firstName} {data.lastName}
                    </span>?
                </span>
            );
        }

        openDialog({
            title: 'Разблокирование',
            subTitle: data?.length ? 'Вы действительно хотите разблокировать нижеперечисленных участников?' : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onChange: (users) => checkboxHandler(users),
            onSubmit: () => unblockUser(data),
            onClose: closeDialog
        });
    }, [closeDialog, openDialog, unblockUser, navigate, checkboxHandler]);

    const columns = useMemo(() => [
        {
            Header: 'ИМЯ',
            accessor: 'firstName',
            width: 100,
            Cell: (data) => {
                return (
                    <Link to={`/edit-user/${data.row.original['login']}`} className={classNames(cx.nameCell, {[cx.mobileName]: data.isMobile})}>
                        <img src={data.row.original['avatar'] == null || data.row.original['avatar'] === '' ? '/img/avatar.png' : api.upload.getImage(data.row.original['avatar'], false, 128)}
                             alt=""/>
                        <MultiClumpTooltip label={data.row.original['firstName'] + ' ' + data.row.original['lastName']} />
                    </Link>
                )
            }
        },
        {
            Header: 'ДОЛЖНОСТЬ',
            accessor: 'position',
            width: 100,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Должность</div>}
                        {data.row.original['position']}
                    </>
                )
            }
        },
        {
            Header: 'ОФИС',
            accessor: 'office',
            width: 100,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Офис</div>}
                        {data.row.original['office']}
                    </>
                )
            }
        },
        {
            Header: 'РУКОВОДИТЕЛЬ',
            accessor: 'superVisor',
            width: 100,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Руководитель</div>}
                        {data.row.original['supervisor']}
                    </>
                )
            }
        },
        {
            Header: 'ДАТА ИЗМЕНЕНИЯ',
            accessor: 'lastModifiedDate',
            width: 130,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {data.row.original['lastModifiedDate']}
                    </>
                )
            }
        },
        {
            id: 5,
            width: 65,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function(data) {
                const tools = [];

                if (data.row.original.permissions?.canBlock) {
                    tools.push({
                        icon: Icons.UNBLOCK,
                        tooltip: 'Разблокировать',
                        onClick: async (data) => onUnblockUser(data)
                    });
                }

                if (data.row.original.permissions?.canDelete) {
                    tools.push({
                        icon: Icons.TRASH,
                        tooltip: 'Удалить',
                        onClick: (data) => onRemoveUser(data)
                    })
                }

                return EditRowToolbar(tools)(data);
            }
        }
    ], [onRemoveUser, onUnblockUser, platform]);
    const userActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.UNBLOCK,
            label: 'Разблокировать',
            onClick: (data) => onUnblockUser(data)
        });

        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: (data) => onRemoveUser(data)
        });

        return actions;
    }, [onRemoveUser, onUnblockUser]);

    const loadGroupsOptions = useCallback(async (search) => {
        let options = [];

        if (search) {
            const groupsResponse = await api.group.searchByName(search);

            options = groupsResponse.map((group) => ({ label: group.name, value: group.id }));
        }

        return { options };
    }, []);

    const loadRolesOptions = useCallback(async () => {
        let options = [];
        const rolesResponse = await api.role.getRolesList();

        options = rolesResponse.map((role) => ({ label: role.title, value: role.name }));

        return {
            options: options,
        };
    }, []);

    const loadOfficesOptions = useCallback(async (search, loadedOptions, { page }) => {
        let options = [];
        const officesResponse = await api.office.getOffices(page, 20, '', search);

        options = officesResponse.content.map((office) => ({ label: office.name, value: office.id }));

        return {
            options: options,
            hasMore: page < officesResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    }, []);

    const loadPositionsOptions = useCallback(async (search, loadedOptions, { page }) => {
        let options = [];
        const positionsResponse = await api.userPosition.getUserPosition(page, 20, '', search);

        options = positionsResponse.content.map((position) => ({ label: position.title, value: position.title }));

        return {
            options: options,
            hasMore: page < positionsResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    }, []);

    const loadSupervisorsOptions = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const supervisorsResponse = await api.office.getSupervisors(page, 20, '', search);

        options = supervisorsResponse.content.map((value) => ({ label: value.user.firstName + ' ' + value.user.lastName, value: value.user.login }));

        return {
            options: options,
            hasMore: page < supervisorsResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    }, []);

    const usersFilters = [
        {
            'label': 'Должность',
            'fields': [
                {
                    'name': 'positionTitle.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadPositionsOptions
                }
            ]
        },
        {
            'label': 'Руководитель',
            'fields': [
                {
                    'name': 'supervisorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadSupervisorsOptions
                }
            ]
        },
        {
            'label': 'Офис',
            'fields': [
                {
                    'name': 'officeName.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadOfficesOptions
                }
            ]
        },
        {
            'label': 'Группа',
            'fields': [
                {
                    'name': 'group.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadGroupsOptions
                }
            ]
        },
        {
            'label': 'Роль',
            'fields': [
                {
                    'name': 'roleName.in',
                    'type': 'select',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadRolesOptions
                }
            ]
        }
    ];

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [project]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && (
                <Empty title="Пользователи не найдены" />
            )}
            {!isEmpty && (
                <div className={cx.userContainer}>
                    {!isReLoading && <h2>Заблокированные участники</h2>}
                    <TableComponent
                        innerRef={tableRef}
                        searchTitle="ФИО, логин, email, телефон"
                        addTitle="Добавить участника"
                        columns={columns}
                        isLoading={isLoading}
                        total={total}
                        data={localUsers}
                        filters={usersFilters}
                        onPaginate={onPaginate}
                        actions={userActions}
                        isMobile={platform === 'mobile' || width <= 1024}
                        name="blockedUsers"
                    />
                </div>
            )}
        </PrivatePage>
    );
};

export default BlockedUsersPage;
