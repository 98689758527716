import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { useUpload } from 'components/background-upload/upload-provider';
import PrivatePage from 'containers/private-page-wrapper';
import cx from './add-file-page.module.scss';
import Icon, { Icons } from 'uikit/icon';

const AddFilePage = ({ hasAnyAuthorities }) => {
    const { upload } = useUpload();

    const onChange = (files) => {
        for (const f of files) {
            upload({ file: f }).start({ inBackground: false })
        }
    };

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={cx.base}>
                <div className={cx.uploadTitle}>Загрузка файлов</div>
                <Dropzone onDrop={onChange}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: cx.dropZone })}>
                            <input {...getInputProps()} />
                            <div className={cx.mobileView}>
                                <Icon type={Icons.UPLOAD} width={54} height={54} />
                                <p>Нажмите здесь, чтобы начать загрузку файлов с вашего устройства</p>
                            </div>
                            <div className={cx.desktopView}>
                                <p>Перетащите файл в эту область или нажмите для выбора файла с компьютера</p>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
        </PrivatePage>
    );
};

AddFilePage.defaultProps = {
    hasAnyAuthorities: []
};

AddFilePage.propTypes = {
    hasAnyAuthorities: PropTypes.arrayOf(PropTypes.string)
};

export default AddFilePage;
