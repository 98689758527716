import React from 'react';
import classNames from 'classnames';
import { useEditorContext } from '../../context';
import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import cx from '../../editor.module.scss';
import { v4 } from 'uuid';
import Tippy from '@tippyjs/react';

export const MenuHeading = ({ mode, currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();

    const icons = [
        { icon: Icons.EditorIconHeader, label: 'Обычный текст', value: null },
        { icon: Icons.EditorIconHeadingOne, label: 'Заголовок 1', value: 1 },
        { icon: Icons.EditorIconHeadingTwo, label: 'Заголовок 2', value: 2 },
        { icon: Icons.EditorIconHeadingThree, label: 'Заголовок 3', value: 3 },
        { icon: Icons.EditorIconHeadingFour, label: 'Заголовок 4', value: 4 },
        { icon: Icons.EditorIconHeadingFive, label: 'Заголовок 5', value: 5 },
        { icon: Icons.EditorIconHeadingSix, label: 'Заголовок 6', value: 6 },
    ];

    const setHeading = (level, markName) => {
        if (!isNaN(level) && level > 0) {
            editor
                ?.chain()
                .focus()
                .setHeading({ level: level, 'data-uniq-id': v4(), 'data-mark': markName })
                .unsetAllMarks()
                .run();

            editor?.emit('update');
        } else {
            editor
                ?.chain()
                .focus()
                .toggleHeading({ level: editor.getAttributes('customHeading').level })
                .run();
        }
    };

    return (
        <Menu
            mode={mode}
            name="heading"
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            menuContentStyles={{ overflow: 'initial' }}
            label={
                <Tippy content="Заголовок" disabled={mode !== 'DESKTOP'}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center', padding: '0 6px' }}>
                        <div className={classNames(cx.headingButton, cx.editorMenuButton)} style={{ minWidth: 18 }}>
                            <Icon type={Icons.EditorIconHeader} width={18} height={18} />
                        </div>
                        <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                    </div>
                </Tippy>
            }
        >
            {icons.map((v, idx) => {
                if (idx === 0) {
                    return (
                        <MenuItem
                            key={v.label}
                            onClick={() => setHeading()}
                            selected={!editor?.getAttributes('customHeading').level}
                            label={
                                <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                    <Icon type={v.icon} width={18} height={18} /> <span>{v.label}</span>
                                </div>
                            }
                        />
                    );
                }

                return (
                    <Menu
                        mode={mode}
                        name="heading"
                        currentMenu={currentMenu}
                        setCurrentMenu={setCurrentMenu}
                        key={v.label}
                        onClick={() => (mode === 'DESKTOP' ? setHeading(idx) : null)}
                        menuStyles={{ minWidth: 236 }}
                        selected={v.value === Number(editor?.getAttributes('customHeading').level)}
                        label={
                            <div
                                className={idx > 0 ? `h${idx}` : null}
                                style={{ display: 'flex', alignItems: 'center', gap: 6, margin: 0 }}
                            >
                                <Icon type={v.icon} width={18} height={18} /> <span>{v.label}</span>
                            </div>
                        }
                    >
                        <MenuItem
                            selected={
                                v.value === editor?.getAttributes('customHeading').level &&
                                editor?.getAttributes('customHeading')?.['data-mark'] === 'italic'
                            }
                            label={<em>Курсивный</em>}
                            styles={{ minWidth: 220 }}
                            onClick={() => setHeading(idx, 'italic')}
                        />
                        <MenuItem
                            selected={
                                v.value === editor?.getAttributes('customHeading').level &&
                                editor?.getAttributes('customHeading')?.['data-mark'] === 'bold'
                            }
                            label={<b style={{ fontWeight: 600 }}>Полужирный</b>}
                            onClick={() => setHeading(idx, 'bold')}
                        />
                        <MenuItem
                            selected={
                                v.value === editor?.getAttributes('customHeading').level &&
                                editor?.getAttributes('customHeading')?.['data-mark'] === 'underline'
                            }
                            label={<u>Подчеркнутый</u>}
                            onClick={() => setHeading(idx, 'underline')}
                        />
                    </Menu>
                );
            })}
        </Menu>
    );
};
