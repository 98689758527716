import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { useEventListener, useGlobalContext } from 'lib/hooks';
import { ActivityTypes, Months, Days } from 'uikit/activity';
import Loading from 'uikit/loading';
import Filter from 'uikit/filter/filter';
import api from 'api/index';
import cx from './tab-actions.module.scss';

const eventTypesOptions = [
    { label: 'Создание', value: 'CREATE' },
    { label: 'Изменение', value: 'EDIT' },
    { label: 'Удаление', value: 'DELETE' },
    { label: 'Аутентификация', value: 'AUTHENTICATION' }
];

const TabActions = ({ uuid }) => {
    const { platform } = useGlobalContext();

    const [isFetched, setIsFetched] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const [logs, setLogs] = useState([]);
    const [logsData, setLogsData] = useState([]);
    const [page, setPage] = useState(0);

    const [filtersData, setFiltersData] = useState({});

    const loadUsers = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const usersResponse = await api.resource.findUsersByResourceIdPaginate(uuid, page, 20, '', search);

        options = usersResponse.data.content.map((i) => ({
            label: i['user']['firstName'] + ' ' + i['user']['lastName'],
            value: i['user']['login']
        }));

        return {
          options,
          hasMore: page < usersResponse.data.totalPages - 1,
          additional: {
              page: page + 1,
          },
      };
    }, [uuid]);

    const filters = [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Активности',
            'fields': [
                {
                    'name': 'eventTypes',
                    'type': 'select',
                    'default': null,
                    'isMulti': true,
                    'options': eventTypesOptions
                }
            ]
        },
        {
            'label': 'Участники',
            'fields': [
                {
                    'name': 'users',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadUsers
                }
            ]
        }
    ];

    const onChangeFilters = (data) => {
        setPage(0);
        setLogs([]);
        setFiltersData(data);
    };
    const onScroll = (e) => {
        if (e.target.scrollTop === e.target.scrollHeight - e.target.clientHeight) {
            setPage(page + 1);
        }
    };

    useEventListener('scroll', onScroll, document.getElementById('sidebarContent'));

    useEffect(() => {
        async function fetchData() {
            setIsFetching(true);

            const response = await api.logging.getLogs({
                size: 20,
                page: page,
                filters: filtersData,
                objectUuid: uuid
            });

            setLogs((logs) => logs.concat(response));

            setIsFetching(false);
            setIsFetched(true);
        }

        fetchData();
    }, [uuid, page, filtersData]);

    useEffect(() => {
        const fetchData = async () => {
            // setIsFetched(false);
            const items = [];
            const current = new Date();

            const currentYear = current.getFullYear();
            const currentMonth = current.getMonth();
            const currentDay = current.getDate();

            logs.map((item) => {
                const itemDate = new Date(item['timestamp']);

                const itemYear = itemDate.getFullYear();
                const itemMonth = itemDate.getMonth();
                const itemDay = itemDate.getDate();

                const timestamp = new Date(itemYear, itemMonth, itemDay).getTime() / 1000;
                let key = items.findIndex((item) => item.timestamp === timestamp);

                if (key === -1) {
                    if (itemYear === currentYear && itemMonth === currentMonth && itemDay === currentDay) {
                        items.push({
                            timestamp: timestamp,
                            title: 'Сегодня',
                            users: []
                        });
                    } else {
                        items.push({
                            timestamp: timestamp,
                            title: Days[itemDate.getDay()] + ', ' + itemDay + ' ' + Months[itemMonth],
                            users: []
                        });
                    }

                    key = items.length - 1;
                }

                // Check user;
                let userIndex = items[key]['users'].findIndex((user) => user['id'] === item['userInfo']['id']);

                if (userIndex === -1) {
                    items[key]['users'].push({
                        id: item['userInfo']['id'],
                        login: item['userInfo']['login'],
                        firstName: item['userInfo']['firstName'] ?? 'System',
                        lastName: item['userInfo']['lastName'] ?? 'System',
                        smallAvatarUuid: item['userInfo']['smallAvatarUuid'],
                        avatarUuid: item['userInfo']['avatarUuid'],
                        items: []
                    });
                    userIndex = items[key]['users'].length - 1;
                }

                // Add log to user;
                let hours = itemDate.getHours();
                item['hours'] = hours.toString().length === 1 ? '0' + hours : hours;

                let minutes = itemDate.getMinutes();
                item['minutes'] = minutes.toString().length === 1 ? '0' + minutes : minutes;

                items[key]['users'][userIndex]['items'].push(item);
                return item;
            });
            setLogsData(items);
            // setIsFetched(true);
        };
        fetchData();
    }, [logs]);

    return (
        <div id={'tabActionsContainer'} className={cx.tabActions}>
            {isFetching &&
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>}
            {isFetched && logsData.length !== 0 &&
            <>
                <div className={cx.filter}>
                    <Filter id={'actionsFilters'} filters={filters} filtersData={filtersData}
                            onFilters={(data) => onChangeFilters(data)} isMobile={platform === 'mobile'}/>
                </div>
                <div className={cx.items}>
                    {logsData.map((item, itemIndex) => {
                        return (
                            <div key={itemIndex} className={cx.item}>
                                <h3>{item.title}</h3>
                                <div className={cx.users}>
                                    {item.users.map((user, userIndex) => {
                                        return (
                                            <div key={userIndex} className={cx.user}>
                                                <div className={cx.head}>
                                                    <div className={cx.avatar}>
                                                        {user.avatarUuid &&
                                                        <img src={api.upload.getImage(user.avatarUuid, false, 128)}
                                                             alt="" />}
                                                    </div>
                                                    <Link
                                                        to={'/edit-user/' + user.login}>{user.firstName} {user.lastName}</Link>
                                                </div>
                                                <div className={cx.records}>
                                                    {user.items.map((record, recordIndex) => {
                                                        return (
                                                            <div key={recordIndex} className={cx.record}>
                                                                <span>{record.hours}:{record.minutes}</span>
                                                                {ActivityTypes[record.resourceType + '.' + record.type]
                                                                    ? ActivityTypes[record.resourceType + '.' + record.type](record)
                                                                    : record.resourceType + '.' + record.type}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </>}
        </div>
    );
};

TabActions.defaultProps = {
    uuid: ''
};

TabActions.propTypes = {
    uuid: PropTypes.string
};

export default TabActions;
