import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Router } from '@reach/router';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import { ContentWrapper } from 'containers/content-wrapper';
import PrivatePage from 'containers/private-page-wrapper';
import StatisticsTree from 'components/trees/statisctics-tree/statistics-tree';
import StatisticDashboardsWrapper from './statistic-dashboards/statistic-dashboards-wrapper';
import StatisticReportsPage from './statistic-reports/statistic-reports-page';
import { MobileStatisticsTree, NODE_TYPES } from 'components/trees';
import TreeUtils from 'lib/util/tree.util';
import cx from './statistic-dashboards/statistic-total-stats-page.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { useCustomMobileMenu } from 'lib/hooks/useCustomMobileMenu';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import Icon, { Icons } from 'uikit/icon';

const StatisticWrapperPage = ({ navigate, hasAnyAuthorities, ...props }) => {
    const treeRef = useRef(null);

    const { width } = useWindowSize();
    const [showTree, setShowTree] = useState(false);

    const { platform } = useGlobalContext();
    const [isInit, setIsInit] = useState(false);
    const [node, setNode] = useState(null);
    const newsPicker = useSelector(selectNewsPicker);
    const [scrollToNode, setScrollToNode] = useState(null);

    const onNodeClick = useCallback(
        (treeNode) => {
            setNode(treeNode);

            if (treeNode.nodeType === NODE_TYPES.PROJECT) {
                navigate('/statistic/dashboard/' + treeNode.id.toLowerCase().replace(/_/g, '-'));
            } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
                navigate('/statistic/report/' + treeNode.id.toLowerCase().replace(/_/g, '-'));
            }
        },
        [navigate]
    );

    const getNodeLink = useCallback((treeNode) => {
        if (treeNode.nodeType === NODE_TYPES.PROJECT) {
            return '/statistic/dashboard/' + treeNode.id.toLowerCase().replace(/_/g, '-');
        } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
            return '/statistic/report/' + treeNode.id.toLowerCase().replace(/_/g, '-');
        }
    }, []);

    useCustomMobileMenu(
        useCallback(
            (onClose) => (
                <MobileStatisticsTree
                    onSelect={(node) => {
                        onClose();
                        onNodeClick(node);
                    }}
                    ref={treeRef}
                />
            ),
            [treeRef, onNodeClick]
        )
    );

    useEffect(() => {
        if (treeRef?.current?.tree && !isInit) {
            const { tree } = treeRef.current;
            let selectedNode = null;
            let projectId, sectionId;
            const path = props['*'];

            if (path.indexOf('/') === -1) {
                projectId = path;
            } else {
                projectId = path.slice(0, path.indexOf('/'));
                sectionId = path.slice(path.indexOf('/') + 1);
            }

            if (projectId && !sectionId) {
                selectedNode = tree.getNodeById(projectId.toUpperCase().replace(/-/g, '_'));
                tree.selectNode(selectedNode);
            } else if (projectId && sectionId) {
                selectedNode = tree.getNodeById(sectionId.toUpperCase().replace(/-/g, '_'));
            } else {
                navigate('/statistic/general');
            }
            if (selectedNode) {
                setNode(selectedNode);
            }

            setIsInit(true);
        }
    }, [navigate, props, isInit, treeRef]);

    useEffect(() => {
        if (node) {
            const pathIds = TreeUtils.getPathIdsTo(node);
            setScrollToNode(pathIds);
        }
    }, [node]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <ContentWrapper>
                <div className={cx.toggleTreeButton} onClick={() => setShowTree(!showTree)}>
                    <Icon type={Icons.SANDWICH} width={20} height={14} />
                </div>
                <div
                    className={classNames(cx.container, {
                        [cx.withNewsTicker]: newsPicker.count > 0,
                        [cx.bgWhite]: node?.nodeType === 'SECTION',
                        [cx.mobile]: platform === 'mobile',
                    })}
                >
                    {platform === 'desktop' && (
                        <div className={classNames(cx.treeContainer, {
                            [cx.treeContainerHidden]: width >= 768 && width <= 1024 && !showTree
                        })} onClick={() => setShowTree(!showTree)}>
                            <div
                                className={classNames(cx.tree, {
                                    [cx.withNewsTicker]: newsPicker.count > 0,
                                })}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <StatisticsTree
                                    width={258}
                                    onNavigate={onNodeClick}
                                    ref={treeRef}
                                    scrollToNode={scrollToNode}
                                    getNodeLink={getNodeLink}
                                />
                            </div>
                        </div>
                    )}
                    <div
                        className={classNames(cx.content, {
                            [cx.maxWidth]: node?.nodeType === 'SECTION',
                        })}
                    >
                        <Router>
                            <StatisticDashboardsWrapper path="dashboard/*" />
                            <StatisticReportsPage path="report/*" title={node?.name} />
                        </Router>
                    </div>
                </div>
            </ContentWrapper>
        </PrivatePage>
    );
};

export default StatisticWrapperPage;
