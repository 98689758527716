import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDialog, useMessage, usePermittedActions, useGlobalContext } from 'lib/hooks';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import { perm, RESOURCE_TYPE } from 'model/resource';
import hot from 'containers/hot-container';
import { newsFormPage } from 'components/news-form';
import Preview from 'components/news-preview';
import Button from 'uikit/button/button';
import Loading from 'uikit/loading';
import Divider from 'uikit/divider';
import api from 'api/index';
import cs from './edit-news-page.module.scss';
import { useUpload } from 'components/background-upload/upload-provider';
import Confirmation from 'components/confirmation';
import Switch from 'uikit/switch/switch';
import Checkbox from 'uikit/checkbox/checkbox';
import { incrementUnreadCounter } from 'slice/authSlice';
import classNames from 'classnames';
import UploadEditorImages from 'lib/helpers/uploadEditorImages';

const EditNewsPage = ({ news, type = 'news', loading, setLoading, navigate }) => {
    const dispatch = useDispatch();
    const { platform, setFormDataChanged } = useGlobalContext();

    const { addError, addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [previewShow, setPreviewShow] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const { checkPermission, ready } = usePermittedActions(type === 'news' ? RESOURCE_TYPE.NEWS : null, type === 'news' ? news.id : null);

    const { showBackgroundModal, isUploading, addGroupCallback, removeGroupCallback } = useUpload(news.id);
    const [queuedAction, setQueuedAction] = useState(null);

    const onOk = async () => {
        showBackgroundModal();

        const formData = news.getFormData();
        formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

        const oldTicker = news.ticker;
        let method, url;
        switch (queuedAction) {
            case 'editNews':
                method = api.news.editNews;
                url = `/projects/news/${news.id}`;
                break;
            case 'editNewsDraft':
                method = api.news.editNewsDraft;
                url = '/user/drafts/news';
                break;
            case 'postNewsDraft':
                method = api.news.postNewsDraft;
                url = '/user/drafts/news';
                break;
            case 'publishNewsDraft':
                method = api.news.publishNewsDraft;
                url = '/news';
                break;
            default:
                method = () => console.log("won't happen, but warning");
        }
        const callback = (uploadedFiles) => {
            const files = [...formData.files, ...uploadedFiles.filter((f) => f.status === 'done').map((f) => f.fileId)];
            // uniques only
            formData.files = files.filter((v, i, a) => a.indexOf(v) === i);
            method(formData);
            if (oldTicker !== formData.tickerRequest) {
                dispatch(updateNewsTicker(true));
            }
            addSuccess('Новость изменена.');
            removeGroupCallback(callback);
        };
        addGroupCallback(callback);
        closeDialog();

        addSuccess('Действие будет выполнено после загрузки файлов. Не закрывайте вкладку.');
        navigate(url);
    };

    const onBackgroundUpload = () => {
        openDialog({
            title: 'Фоновый режим',
            text: 'Не все файлы были загружены. Загрузить их в фоновом режиме?',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, загрузить',
            onSubmit: onOk,
            onClose: closeDialog,
        });
    };

    const editNews = async () => {
        news.commit();
        news.submit();
        setIsSubmit(true);

        if (type === 'news-draft' && !news.validateFields(['title', 'text'])) {
            return;
        }

        if (type !== 'news-draft' && !news.validateFields()) {
            return;
        }

        if (isUploading) {
            if (type === 'news') {
                setQueuedAction('editNews');
            } else if (type === 'news-draft') {
                setQueuedAction('editNewsDraft');
            } else if (type === 'delayed-news') {
                setQueuedAction('editDelayedNews');
            }
            onBackgroundUpload();
            return;
        }

        setFormDataChanged(false);
        setLoading(true);

        try {
            const formData = news.getFormData();
            formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

            if (type === 'news' && !formData.publicationTime) {
                await api.news.editNews(formData);
                addSuccess('Новость изменена');

                if (!formData.insignificantChanges) {
                    dispatch(incrementUnreadCounter('unreadNews'));
                }

                navigate(`/projects/news/${news.id}`, 'no-check-form-data-changed');

                if (news.ticker !== formData.tickerRequest) {
                    dispatch(updateNewsTicker(true));
                }
            } else if (type === 'news-draft') {
                await api.news.editNewsDraft(formData);
                addSuccess('Черновик новости изменен');

                navigate('/user/drafts/news', 'no-check-form-data-changed');
            } else if (formData.publicationTime || type === 'delayed-news') {
                await api.news.editDelayedNews(formData);
                addSuccess('Отложенная новость изменена');

                navigate(`/user/delayed-publications/news`);
            }
        } catch (e) {
            addError('Не удалось изменить новость');
        } finally {
            setLoading(false);
        }
    };

    const addNewsDraft = async () => {
        news.submit();

        setIsSubmit(true);

        if (!news.validateFields()) {
            return;
        }

        if (isUploading) {
            setQueuedAction('postNewsDraft');
            onBackgroundUpload();
            return;
        }

        setFormDataChanged(false);
        setLoading(true);
        try {
            const formData = news.getFormData();
            formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

            await api.news.postNewsDraft(formData);

            if (formData.tickerRequest) {
                dispatch(updateNewsTicker(true));
            }

            addSuccess('Черновик новости создан');

            navigate('/user/drafts/news');
        } catch (e) {
            addError('Не удалось создать черновик новости');
        } finally {
            setLoading(false);
        }
    };

    const publishNewsDraft = async () => {
        news.commit();
        news.submit();

        setIsSubmit(true);

        if (!news.validateFields()) {
            return;
        }

        if (isUploading) {
            setQueuedAction('publishNewsDraft');
            onBackgroundUpload();
            return;
        }

        setFormDataChanged(false);
        setLoading(true);

        try {
            const formData = news.getFormData();
            formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

            const res = await api.news.publishNewsDraft(formData);

            if (formData.tickerRequest) {
                dispatch(updateNewsTicker(true));
            }

            addSuccess('Новость опубликована');

            if (formData.publicationTime) {
                navigate(`/user/delayed-publications/news`);
            } else {
                navigate(`/projects/news/${res.id}`, 'no-check-form-data-changed');
            }
        } catch (e) {
            addError('Не удалось изменить новость');
        } finally {
            setLoading(false);
        }
    };

    const saveAndPublishDelayedNews = async () => {
        news.commit();
        news.submit();

        setIsSubmit(true);

        if (!news.validateFields()) {
            return;
        }

        if (isUploading) {
            setQueuedAction('saveAndPublishDelayedNews');
            onBackgroundUpload();
            return;
        }

        setFormDataChanged(false);
        setLoading(true);

        try {
            const formData = news.getFormData();
            formData.text = await UploadEditorImages(news.id ?? news.initId, formData.text);

            await api.news.editDelayedNews(formData);
            await api.news.publishDelayedNews(formData.id);

            if (formData.tickerRequest) {
                dispatch(updateNewsTicker(true));
            }

            addSuccess('Новость опубликована');
            if (news.parentProjectId) {
                navigate(`/projects/${news.parentProjectId}/news`);
            } else {
                navigate('/news');
            }
        } catch (e) {
            addError('Не удалось изменить новость');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (ready && !checkPermission(perm.news.NEWS_EDIT)) {
            navigate('/401', { replace: true });
        }
    }, [ready, checkPermission, navigate]);

    useEffect(() => {
        if (isSubmit && Object.values(news.errors).some((v) => v)) {
            news.scrollToError();
            setIsSubmit(false);
        }
    }, [isSubmit, news, news.errors]);

    const _onPreview = () => {
        news.commit();
        news.submit();

        setPreviewShow(true);
    };

    const handleCancelButton = () => {
        setFormDataChanged(false);
        window.history.back();
    };

    return (
        <>
            <Confirmation {...dialogState} />
            {loading && <Loading withOverlay={false} withRelativeOverlay={true} />}
            {news.publishedType !== 'draft' && (
                <>
                    <Divider />
                    <div className={cs.blockStatus}>
                        <Switch
                            label="Принудительно уведомить участников об изменениях"
                            checked={news.notifySystem}
                            onClick={() => {
                                const state = !news.notifySystem;
                                news.notifySystem = state;
                                news.notifyEmail = state;
                                news.commit();
                                setFormDataChanged(true);
                            }}
                        />
                        <p style={{ marginBottom: '16px' }}>
                            Принудительно отправить оповещение об изменениях всем участникам, имеющим доступ к данной статье. Участники
                            должны будут подтвердить просмотр изменений
                        </p>
                        <Checkbox label="Отправить системное оповещение" checked={news.notifySystem} disabled={true} />
                        <Checkbox
                            label="Отправить оповещение участникам на указанный в профиле Email"
                            checked={news.notifyEmail}
                            onClick={() => {
                                setFormDataChanged(true);
                                return news.notifySystem
                                    ? () => {
                                          news.notifyEmail = !news.notifyEmail;
                                          news.commit();
                                      }
                                    : null
                            }}
                        />
                    </div>
                    <Divider style={{ marginTop: '1.25rem' }} />
                    <div className={cs.blockStatus}>
                        <Switch
                            checked={news.insignificantChanges}
                            label="Не сбрасывать статус Прочитано после этого изменения"
                            onClick={() => {
                                news.insignificantChanges = !news.insignificantChanges;
                                news.commit();
                                setFormDataChanged(true);
                            }}
                        />
                        <p>
                            Применяется, если изменения в данной версии не влияют на содержание документа, например, исправление
                            орфографической ошибки, незначительное изменение форматирования и пр.
                        </p>
                    </div>
                </>
            )}
            <Divider className={cs.toolDivider} />
            <div className={classNames(cs.toolbar, { [cs.mobile]: platform === 'mobile' })}>
                <Button
                    label="Отмена"
                    disabled={loading}
                    onClick={handleCancelButton}
                    fullWidth={platform === 'mobile'}
                />
                {!news && type !== 'news-draft' && (
                    <Button onClick={addNewsDraft} label="В черновик" disabled={loading} />
                )}
                <Button
                    onClick={_onPreview}
                    label="Предпросмотр"
                    disabled={loading}
                    fullWidth={platform === 'mobile'}
                />
                {type === 'news-draft' && (
                    <Button
                        className={cs.desktop}
                        onClick={editNews}
                        label="Сохранить и оставить в черновик"
                        disabled={loading}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'news-draft' && (
                    <Button
                        className={cs.noDesktop}
                        onClick={editNews}
                        label="Сохранить"
                        disabled={loading}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {(type === 'news' || type === 'delayed-news') && (
                    <Button
                        onClick={editNews}
                        label="Сохранить"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'news-draft' && (
                    <Button
                        className={cs.desktop}
                        onClick={publishNewsDraft}
                        label="Сохранить и опубликовать"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'news-draft' && (
                    <Button
                        className={cs.noDesktop}
                        onClick={publishNewsDraft}
                        label="Опубликовать"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'delayed-news' && (
                    <Button
                        className={cs.desktop}
                        onClick={saveAndPublishDelayedNews}
                        label="Сохранить и опубликовать сейчас"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
                {type === 'delayed-news' && (
                    <Button
                        className={cs.noDesktop}
                        onClick={saveAndPublishDelayedNews}
                        label="Опубликовать"
                        disabled={loading}
                        color={'green'}
                        fullWidth={platform === 'mobile'}
                    />
                )}
            </div>
            <Preview
                show={previewShow}
                onDismiss={() => setPreviewShow(false)}
                onDraft={addNewsDraft}
                onEditDraft={type === 'news-draft' && editNews}
                onPublish={editNews}
                news={news}
                type={type}
            />
        </>
    );
};

export default hot(newsFormPage(EditNewsPage, 'Редактировать новость', true));
