import React, { useMemo, useRef, useState } from 'react';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';
import { useEditorContext } from '../../context';
import DropdownList from 'uikit/dropdown-list/dropdown-list';
import { MenuButtonColorPicker } from './MenuButtonColorPicker';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import classNames from 'classnames';
import Icon from 'uikit/icon/icon';

import cx from '../../editor.module.scss';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { selectedRect } from '../../extensions/Table/commands';
import { findParentNode } from 'editors/tiptap-editor/utils/findParentNode';

export const MenuListTableButton = () => {
    const editor = useEditorContext();
    const cellBgColorBtnRef = useRef(null);
    const borderColorBtnRef = useRef(null);
    const isTableActive = editor?.isActive('table') && editor?.view?.dragging === null;
    const tableAttrs = editor?.getAttributes('table');

    const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 1)');
    const [borderColor, setBorderColor] = useState('rgba(0, 0, 0, 1)');

    const responsive = useMemo(() => {
        return typeof tableAttrs?.['data-responsive'] === 'string'
            ? tableAttrs?.['data-responsive'] === 'true'
            : tableAttrs?.['data-responsive'];
    }, [tableAttrs]);

    const isAddColumnBlock = useMemo(() => {
        if (!isTableActive) {
            return false;
        }

        const rect = selectedRect(editor.state);

        if (!rect) {
            return false;
        }
        const tableHeader = findParentNode(editor.state, 'tableHeader');

        return tableHeader && rect.left === 0 ? true : false;
    }, [isTableActive, editor?.state]);

    const isAddRowBlock = useMemo(() => {
        if (!isTableActive) {
            return false;
        }

        const rect = selectedRect(editor.state);

        if (!rect) {
            return false;
        }

        const tableHeader = findParentNode(editor.state, 'tableHeader');

        return tableHeader && rect.top === 0 ? true : false;
    }, [isTableActive, editor?.state]);

    const addRowAfter = () => editor?.chain().focus().addRowAfter().run();
    const addRowCopyAfter = () => editor?.chain().focus().addRowCopyAfter().run();
    const addRowBefore = () => editor?.chain().focus().addRowBefore().run();
    const deleteRow = () => editor?.chain().focus().deleteRow().run();
    const addColumnAfter = () => {
        editor?.chain().focus().addColumnAfter().run();
    };
    const addColumnBefore = () => {
        editor?.chain().focus().addColumnBefore().run();

        if (responsive) {
            return;
        }

        const tr = editor.state.tr;
        const rect = selectedRect(editor.state);

        rect.map.map.forEach((pos, idx) => {
            const curCell = rect.table.nodeAt(pos);

            if (curCell.attrs.colwidth !== null) {
                return;
            }

            if (rect.table.attrs.width?.includes('%')) {
                let step = 1;
                let leftCell;
                // return;
                while (!leftCell || step < rect.width) {
                    const cell = rect.table.nodeAt(rect.map.map[idx - step]);

                    if (+cell?.attrs.colwidth?.[0] > 90) {
                        leftCell = cell;
                    } else {
                        step++;
                    }
                }

                if (leftCell.attrs.colwidth?.length) {
                    tr.setNodeMarkup(rect.tableStart + rect.map.map[idx - step], null, {
                        ...leftCell.attrs,
                        colwidth: [leftCell.attrs.colwidth?.[0] - 45],
                    });
                }
            }

            tr.setNodeMarkup(rect.tableStart + pos, null, {
                ...curCell.attrs,
                colwidth: [45],
            });
        });

        editor.view.dispatch(tr);
    };
    const addColumnCopyAfter = () => editor?.chain().focus().addColumnCopyAfter().run();
    const deleteColumn = () => editor?.chain().deleteColumn().run();
    const mergeCells = () => editor?.chain().focus().mergeCells().run();
    const splitCell = () => editor?.chain().focus().splitCell().run();
    const toggleHeaderRow = () => editor?.chain().focus().toggleHeaderRow().run();
    const toggleHeaderColumn = () => editor?.chain().focus().toggleHeaderColumn().run();
    const deleteTable = () => editor?.chain().focus().deleteTable().run();
    const setCellBackgroundColor = color => {
        editor.chain().setCellAttribute('bgcolor', color).run();
        setBgColor(color);
    };
    const onBorderColorChange = color => {
        editor.chain().setBorderColor(color).run();
        setBorderColor(color);
    };
    const setTableResponsiveStatus = status => {
        editor.chain().focus().updateAttributes('table', { 'data-responsive': status }).run();

        const { $from } = editor.state.selection;
        const tr = editor.state.tr;

        const tablePos = findParentNodeClosestToPos($from, node => node.type.name === 'table');

        tablePos.node.descendants((node, pos) => {
            if (node.type.name !== 'tableCell' && node.type.name !== 'tableHeader') {
                return;
            }

            const cellPos = tablePos.pos + pos + 1;
            const cellDom = editor.view.nodeDOM(cellPos);

            if (!status && cellDom.getAttribute('colwidth') !== null) {
                return;
            }

            tr.setNodeMarkup(cellPos, null, {
                ...node.attrs,
                colwidth: status ? null : [cellDom.offsetWidth],
            });
        });

        editor.view.dispatch(tr);
    };

    if (!isTableActive) {
        return null;
    }

    return (
        <div
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
            }}
        >
            <Menu
                menuContentStyles={{ overflow: 'initial' }}
                label={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                        }}
                    >
                        <div style={{ width: 82 }} className={classNames('editor-button', cx.fontFamily, cx.editorMenuButton)}>
                            {responsive ? 'Адаптивная' : 'С фиксированной шириной'}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    </div>
                }
            >
                <MenuItem selected={responsive} label="Адаптивная" onClick={() => setTableResponsiveStatus(true)} />
                <MenuItem selected={!responsive} label="С фиксированной шириной" onClick={() => setTableResponsiveStatus(false)} />
            </Menu>
            <MenuButton tooltip="Добавить строку ниже" icon={Icons.EditorIconTableAddRowBottom} onClick={addRowAfter} />
            <MenuButton
                disabled={isAddRowBlock}
                tooltip={isAddRowBlock ? "Снимите закрепление чтобы добавить строку" : "Добавить строку выше"}
                icon={Icons.EditorIconTableAddRowTop}
                onClick={addRowBefore}
            />
            <MenuButton tooltip="Удалить строку" icon={Icons.EditorIconTableRemoveRow} onClick={deleteRow} />
            <MenuButton tooltip="Копировать строку" icon={Icons.EditorIconTableCopyRow} onClick={addRowCopyAfter} />
            <div
                style={{
                    height: 17,
                    borderRight: '1px solid rgba(39, 155, 217, 0.15)',
                }}
            ></div>
            <MenuButton
                disabled={isAddColumnBlock}
                tooltip={isAddColumnBlock ? "Снимите закрепление чтобы добавить столбец" : "Добавить столбец слева"}
                icon={Icons.EditorIconTableAddColumnLeft}
                onClick={addColumnBefore}
            />
            <MenuButton tooltip="Добавить столбец справа" icon={Icons.EditorIconTableAddColumnRight} onClick={addColumnAfter} />
            <MenuButton tooltip="Удалить столбец" icon={Icons.EditorIconTableRemoveColumn} onClick={deleteColumn} />
            <MenuButton tooltip="Копировать столбец" icon={Icons.EditorIconTableCopyColumn} onClick={addColumnCopyAfter} />
            <div
                style={{
                    height: 17,
                    borderRight: '1px solid rgba(39, 155, 217, 0.15)',
                }}
            ></div>
            <MenuButton tooltip="Объединить ячейки" icon={Icons.EditorIconTableJoinCell} onClick={mergeCells} />
            <MenuButton tooltip="Разделить объединенные ячейки" icon={Icons.EditorIconTableSeparateCell} onClick={splitCell} />
            <MenuButton tooltip="Выделенный столбец" icon={Icons.EditorIconTableToggleColumnCell} onClick={toggleHeaderColumn} />
            <MenuButton tooltip="Выделенная строка" icon={Icons.EditorIconTableToggleHeaderRow} onClick={toggleHeaderRow} />
            <div
                style={{
                    height: 17,
                    borderRight: '1px solid rgba(39, 155, 217, 0.15)',
                }}
            ></div>
            <DropdownList
                innerRef={borderColorBtnRef}
                position="left"
                toggler={showMenu => <MenuButton tooltip="Цвет границ" icon={Icons.EditorIconTableTextColor} onClick={e => showMenu(e)} />}
            >
                <MenuButtonColorPicker
                    onChange={onBorderColorChange}
                    onClose={() => borderColorBtnRef.current.hidemenu()}
                    value={borderColor}
                />
            </DropdownList>
            <DropdownList
                innerRef={cellBgColorBtnRef}
                position="left"
                toggler={showMenu => <MenuButton tooltip="Цвет ячейки" icon={Icons.EditorIconTableCellColor} onClick={e => showMenu(e)} />}
            >
                <MenuButtonColorPicker
                    onChange={setCellBackgroundColor}
                    onClose={() => cellBgColorBtnRef.current.hidemenu()}
                    value={bgColor}
                />
            </DropdownList>
            <div
                style={{
                    height: 17,
                    borderRight: '1px solid rgba(39, 155, 217, 0.15)',
                }}
            ></div>
            <MenuButton tooltip="Удалить таблицу" icon={Icons.EditorIconTableRemoveTable} onClick={deleteTable} />
        </div>
    );
};
