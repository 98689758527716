import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useCombinedRefs } from 'lib/hooks';
import { idFromString } from 'lib/helpers';
import Icon from 'uikit/icon';
import cx from './input.module.scss';

const Input = ({
    id,
    className,
    wrapperStyle,
    type,
    label,
    placeholder,
    icon,
    description,
    error,
    forwardedRef,
    required,
    onChange,
    isError,
    value,
    maxLength,
    ...attrs
}) => {
    const key = id || idFromString(label) || idFromString(attrs.placeholder);
    const _isError = isError === null ? !!error : isError;
    const clsName = classNames(cx.base, className, { [cx.error]: _isError });

    const innerRef = useCombinedRefs(forwardedRef);
    const renderDescription = () => {
        if (typeof description === 'function') {
            return description();
        }

        return description;
    };

    return (
        <div className={clsName} style={wrapperStyle}>
            {label && (
                <label htmlFor={key} className={classNames(cx.label, { [cx.required]: required })}>
                    {label}
                </label>
            )}
            <div className={cx.inputWrapper}>
                {icon && <Icon className={cx.icon} type={icon} width={14} height={14} />}
                <input
                    id={key}
                    ref={innerRef}
                    className={!!icon ? cx.withIcon : null}
                    autoComplete={attrs.type === 'password' ? 'on' : 'off'}
                    onChange={(e) => onChange(e.target.value)}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    {...attrs}
                />
            </div>
            <div className={cx.inputInfo}>
                <div className={cx.left}>
                    {!!description && !error && <span className={cx.description}>{renderDescription()}</span>}
                    {!!error && <span className={cx.errorMessage}>{error}</span>}
                </div>
                {maxLength && (
                    <span className={cx.maxLength}>
                        <span className={classNames({ [cx.lengthError]: value.length > maxLength })}>{value.length}</span>/{maxLength}
                    </span>
                )}
            </div>
        </div>
    );
};

Input.defaultProps = {
    id: null,
    className: '',
    type: 'text',
    label: '',
    placeholder: '',
    icon: null,
    description: '',
    error: '',
    forwardedRef: null,
    required: false,
    onChange: () => {},
    isError: null,
};

Input.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password', 'date']),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    icon: PropTypes.node,
    description: PropTypes.string,
    error: PropTypes.string,
    forwardedRef: PropTypes.object,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    isError: PropTypes.bool,
};

export default React.forwardRef((props, ref) => <Input {...props} forwardedRef={ref} />);
