import { Node, mergeAttributes } from '@tiptap/core';
import { getHeadings } from './utils';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { TOCView } from './TOCView';

const handleUpdate = editor => {
    return getHeadings(editor);
};

export const TableOfContents = Node.create({
    name: 'tableOfContents',

    atom: true,
    group: 'block',
    content: '(block|list|listItem|paragraph)*',

    selectable: true,
    // draggable: false,

    addAttributes() {
        return {
            'data-type': {
                default: null,
                parseHTML: el => el.getAttribute('data-type'),
                renderHTML: attrs => ({ 'data-type': attrs['data-type'] }),
            },
        };
    },

    addStorage() {
        return {
            headings: [],
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div.table-of-contents',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        this.storage.headings = handleUpdate(this.editor);

        const headings = this.storage.headings.map(i => {
            return ['li', { 'data-type': 'toc-item', ...i.attrs }, i.content[0]?.content[0]?.text];
        });

        return [
            'div',
            mergeAttributes(HTMLAttributes, { class: 'table-of-contents', 'data-type': 'toc' }),
            ['ul', { 'data-type': 'toc-list' }, ...headings],
        ];
    },

    addCommands() {
        return {
            insertTableOfContents:
                () =>
                ({ commands, editor }) => {
                    const content = getHeadings(editor);
                    if (!content.length) return;

                    const headings = {
                        type: 'tableOfContents',
                        attrs: { 'data-type': 'toc' },
                        content: [{ type: 'tableOfContentsList', content }],
                    };

                    const { $from } = editor.view.state.selection;
                    const resolvedPos = editor.$pos($from.pos);

                    let node = resolvedPos;

                    while (node.parent.name !== 'doc') {
                        node = node.parent;
                    }

                    commands.insertContentAt(node.from - 1, headings);
                    commands.setNodeSelection(node.from + 1);
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(TOCView);
    },
});
