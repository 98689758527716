import { Node, mergeAttributes } from '@tiptap/core';
import { NodeSelection, Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { AnchorView } from './AnchorView';
import { findParentNodeOfType } from 'prosemirror-utils';
import { translate } from 'lib/helpers';

export const Anchor = Node.create({
    name: 'anchor',

    group: 'inline',
    inline: true,
    content: 'inline*',
    priority: 900,
    draggable: false,
    defining: true,

    addAttributes() {
        return {
            id: {
                default: null,
                parseHTML: element => element.getAttribute('id'),
                renderHTML: attributes => {
                    if (!attributes.id) {
                        return {};
                    }

                    return {
                        id: attributes.id,
                    };
                },
            },
            name: {
                default: null,
            },
            title: {
                default: null,
            },
            'data-type': {
                default: 'anchor',
                parseHTML: el => el.getAttribute('data-type'),
                renderHTML: attrs => ({ 'data-type': attrs['data-type'] }),
            },
            'data-tooltip': {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'span[data-type="anchor"]',
            },
            {
                tag: 'a',
                getAttrs: el => {
                    const href = el.getAttribute('href');
                    const id = el.getAttribute('id');
                    const name = el.getAttribute('name');
                    const title = el.getAttribute('title');

                    if (href) {
                        return false;
                    }

                    return {
                        id,
                        name,
                        title,
                        'data-tooltip': title || id || name,
                    };
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['a', mergeAttributes({ 'data-type': 'anchor' }, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            selectAnchor:
                position =>
                ({ editor, tr }) => {
                    const { pos } = editor.state.doc.resolve(position);
                    const node = editor.state.doc.nodeAt(pos);

                    if (node?.type?.name === 'anchor') tr.setSelection(NodeSelection.create(editor.state.doc, pos));
                    return editor.view.dispatch(tr);
                },
            setAnchor:
                name =>
                ({ dispatch, state, tr }) => {
                    const id = translate(name.replace(' ', '_').toLowerCase());

                    tr.replaceWith(
                        state.selection.from,
                        state.selection.to,
                        state.schema.nodes.anchor.create(
                            { id, name: id, title: name, 'data-tooltip': name, 'data-type': 'anchor' },
                            state.selection.content()?.content?.firstChild?.content
                        )
                    );

                    if (dispatch) {
                        return dispatch(tr);
                    }
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(AnchorView);
    },

    addKeyboardShortcuts() {
        return {
            Enter: ({ editor }) => {
                const anchor = findParentNodeOfType(editor.state.schema.nodes.anchor)(editor.state.selection);

                if (!anchor) return;

                const tr = editor.state.tr;

                const newPos = tr.doc.resolve(editor.state.selection.$to.after());

                tr.setSelection(TextSelection.near(newPos));
                tr.scrollIntoView();

                if (editor.view.dispatch) {
                    editor.view.dispatch(tr.scrollIntoView());
                }
            },
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('linkKeyDown'),
                props: {
                    handleTextInput: (view, _from, _to, text) => {
                        const anchor = findParentNodeOfType(view.state.schema.nodes.anchor)(view.state.selection);

                        if (!anchor) return false;

                        const endPos = view.state.selection.$to.after();
                        const { from, to } = view.state.selection;

                        if (from !== to || to !== endPos - 1) return false;

                        const textNode = view.state.schema.text(text);
                        const tr = view.state.tr.insert(endPos, textNode);
                        const newPos = tr.doc.resolve(endPos + 1);

                        tr.setSelection(TextSelection.near(newPos));

                        if (view.dispatch) {
                            view.dispatch(tr.scrollIntoView());
                        }

                        return true;
                    },
                },
            }),
        ];
    },
});
