import { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { useMessage } from 'lib/hooks/useMessage';
import * as validators from 'lib/util/validators.util';
import { ArticleFormModel } from 'model/article';
// import { selectParent } from 'slice/treeSlice';
import api from 'api';
import decodeHtmlEntities from 'lib/helpers/decodeHtmlEntities';

export function useArticleFormModel(articleUuid, articleType = 'article') {
    const { addError } = useMessage();

    const [loading, setLoading] = useState(false);
    const [permissionsOk, setPermissionsOk] = useState(true);

    const [editor, setEditor] = useState(null);
    const [article, setArticle] = useState(new ArticleFormModel());

    useEffect(() => {
        async function getArticle() {
            if (articleUuid) {
                let logo, data;

                setLoading(true);

                try {
                    if (articleType === 'article') {
                        data = await api.article.getArticle(articleUuid);
                    } else if (articleType === 'article-draft') {
                        data = await api.article.getArticleDraft(articleUuid);
                    } else if (articleType === 'delayed-article') {
                        data = await api.article.getDelayedArticle(articleUuid);
                    }
                } catch (e) {
                    if (e?.errorData?.title === 'error.article.has-no-permission.read') {
                        setPermissionsOk(false);
                    } else {
                        throw e;
                    }
                }

                try {
                    if (articleType === 'article' && data?.article?.logoUuid) {
                        logo = await api.upload.getImage(data.article.logoUuid, true, 0)()
                    } else if (articleType === 'article-draft' && data?.draft?.logoUuid) {
                        logo = await api.upload.getImage(data.draft.logoUuid, true, 0)()
                    } else if (articleType === 'delayed-article' && data?.logoUuid) {
                        logo = await api.upload.getImage(data.logoUuid, true, 0)()
                    }
                    logo = logo ? 'data:image/png;base64,' + Buffer.from(logo, 'binary').toString('base64') : '';
                } catch (error) {
                    console.log(error);
                }

                let article;
                if (articleType === 'article') {
                    article = new ArticleFormModel({ ...data, article: { ...data.article, logo } }, true);
                } else if (articleType === 'article-draft') {
                    article = new ArticleFormModel({ article: { ...data.draft, logo }, publishedType: 'draft', ...data }, true);
                } else if (articleType === 'delayed-article') {
                    article = new ArticleFormModel({ article: { ...data, logo}, parents: data.parents, publishedType: 'delayed' }, true);
                }
                setArticle(article);

                setLoading(false);
            } else {
              try {
                  const initRes = await api.article.initArticle();

                  article.initId = initRes?.initializeId;
                  article.commit();
              } catch (e) {
                  console.log(e);
                  addError('Сервис недоступен. Пожалуйста попробуйте позже.');
              }
            }
        }

        getArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addError, articleUuid, articleType]);

    function commit() {
        const copy = this.copy();
        copy.commit = commit;

        setArticle(copy);
    }

    article.commit = commit;

    function validateFields(fields) {
        if (!fields || fields.length === 0) {
            fields = ['title', 'description', 'text', 'location', 'to', 'searchQueries'];
        }

        let errors = {};

        if (fields.includes('title')) {
            errors['title'] = validators.textValidator(article.title);
        }

        if (fields.includes('description')) {
            errors['description'] = validators.textValidator(article.description, 254, false);
        }

        if (fields.includes('text')) {
            errors['text'] = validators.htmlValidator(article.text);
        }

        if (fields.includes('location')) {
            errors['location'] = article.parentSectionId ? null : 'Нужно указать родительский раздел для статьи';
        }

        if (fields.includes('to')) {
            errors['to'] = article.originalLifeTime > article.lifeTime ? 'Нельзя выбрать дату в прошлом' : null;
        }

        article.errors = {
            ...article.errors,
            ...errors
        };

        article.commit();
        return Object.values(errors).every(v => v === null);
    }

    article.validateFields = validateFields;

    function scrollToError() {
        const { title, titleExists, description, text } = article.errors;

        if (title || titleExists) {
            document.getElementById('articleFormTitleInput').scrollIntoView(false);
        } else if (description) {
            document.getElementById('articleFormDescriptionField').scrollIntoView(false);
        } else if (text) {
            document.querySelector('[id^="cke_editor"], [id^="editor"]')?.scrollIntoView(false);
        }
    }

    article.scrollToError = scrollToError;

    async function validateTitleExists() {
        try {
            if (!article.title || !article.parentSectionId
                || (article.title === article.oldTitle && article.parentSectionId === article.oldParentSectionId)) {
                article.errors = {
                    ...article.errors,
                    titleExists: null
                }
                article.commit();
                return;
            }

            const err = (await api.article.validateTitle(article.parentSectionId, article.title))
                ? null : 'Статья с таким названием уже существует';

            article.errors = {
                ...article.errors,
                titleExists: err
            };

            article.commit();
            return !!err;
        } catch {
            return true;
        }
    }

    article.validateTitleExists = validateTitleExists;

    function validateExternalText(text) {
        const result = validators.htmlValidator(text);
        article.errors = {
            ...article.errors,
            text: result
        }
        article.commit();
        return !!result;
    }

    article.validateExternalText = validateExternalText;

    function clearArticle() {
        const newArticle = new ArticleFormModel();
        newArticle.commit = commit;

        setArticle(newArticle);
    }

    function setEditorInstance(editor) {
      setEditor(editor)
      article.editor = editor;
    }

    article.setEditorInstance = setEditorInstance;

    function submit() {
        // getData() - CKEditor, getHTML() - TiptapEditor
        const data = decodeHtmlEntities(editor?.getData?.() || editor?.getHTML?.()).replaceAll('open=""', '');

        article.text = data;
        article.description = article.description?.trim()?.replace(/[\s]+/ig, ' ');

        article.commit();
        article.validateExternalText(data);
    }

    article.submit = submit;

    return { editor, article, setArticle, clearArticle, loading, setLoading, permissionsOk };
}
