import { clearSession } from 'slice/authSlice';
import buttons from 'model/global-menu/buttons';
import { submenuWrapper } from 'containers/sub-menu-wrapper';

const menuConfig = [
    {
        to: '/user/profile',
        name: 'Профиль'
    },
    {
        to: '/user/drafts/articles',
        name: 'Черновики',
        auth: buttons.DRAFT,
        links: ['/user/drafts', '/user/article-draft', '/user/news-draft']
    },
    {
        to: '/user/favorites/projects',
        name: 'Избранное',
        links: ['/user/favorites']
    },
    {
        to: '/user/delayed-publications/articles',
        name: 'Отложенные публикации',
        auth: buttons.DELAYED,
        links: ['/user/delayed-publications', '/user/delayed-article', '/user/delayed-news']
    },
    {
        action: async (dispatch) => {
            dispatch(clearSession());
        },
        name: 'Выход'
    }
];

const UserProfilePage = ({ children }) => {
    return children;
};

export default submenuWrapper(menuConfig, UserProfilePage, 'Участник');
