import React, { useState } from 'react';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import ButtonMenu from 'uikit/button-menu';
import DropdownMenu from 'uikit/dropdown-menu';
import IconButton, { IconButtonStatus } from 'uikit/icon-button/icon-button';
import cx from './table.module.scss';
import Loading from 'uikit/loading';

const EditRowToolbar = (toolbar = [], renderer, canRowSelect = true, showDesktop = false, showMobile = false) =>
    function _(cellProps) {
        const { cell: { value }, row, isMobile } = cellProps;
        const isMobileView = isMobile && !showDesktop;

        const [btnLoaderIdx, setBtnLoaderIdx] = useState(null);
        const mobileItems = toolbar.map((item) => ({
            icon: item.icon,
            title: item.tooltip,
            onClick: async () => {
                if (item.onClick) {
                    await item.onClick(row.original);
                }
            },
            link: item.link
        }));

        if (isMobileView && canRowSelect) {
            mobileItems.push({
                icon: Icons.CHECK_VOID,
                title: row.isSelected ? 'Убрать отметку' : 'Отметить',
                onClick: () => row.toggleRowSelected()
            });
        }

        return (
            <div className={classNames(cx.toolbarContainer, { [cx.mobileToolbar]: isMobile, [cx.mobileToolbarShow]: showMobile })}>
                <div className={cx.toolbarContent}>{renderer ? renderer(cellProps) : <>{value}</>}</div>
                {isMobileView && <ButtonMenu items={mobileItems}/>}
                {!isMobileView && (
                    <div className={cx.toolbar}>
                        {toolbar.map((item, index) => {
                            if (item.isHidden && item.isHidden(row.original)) {
                                return null;
                            }

                            if (item.covered) {
                                return (
                                    <div>
                                        <div style={{width: 16, height: 16}}></div>
                                    </div>
                                );
                            }

                            return (
                                <div style={{ position: 'relative' }} key={index}>
                                    {!item.dropdown && btnLoaderIdx !== index ? (
                                        <IconButton
                                            id={item.id}
                                            status={IconButtonStatus.NONE}
                                            icon={
                                                <Icon
                                                    className={classNames({ [cx.iconActive]: item.iconActive })}
                                                    type={item.icon}
                                                    tooltip={item.tooltip}
                                                    width={16}
                                                    height={16}
                                                />
                                            }
                                            hoverColor={item.iconHoverColor}
                                            onClick={async (e) => {
                                                if (item.loader) {
                                                    setBtnLoaderIdx(index);
                                                }

                                                if (item.onClick) {
                                                    await item.onClick(row.original);
                                                }

                                                if (item.loader) {
                                                    setBtnLoaderIdx(null);
                                                }
                                                e.stopPropagation();
                                            }}
                                            link={item.link}
                                        />
                                    ) : btnLoaderIdx === index ? (
                                        <div>
                                          <Loading size={16} withOverlay={false} withRelativeOverlay={false} />
                                        </div>
                                    ) : null}

                                    {item.dropdown && (
                                        <DropdownMenu
                                            icon={item.icon ?? null}
                                            tooltip={item.tooltip ?? null}
                                            width={item.width ?? null}
                                            height={item.height ?? null}
                                        >
                                            {item.dropdown.map((dropdownItem) => {
                                                if (dropdownItem.isHidden && dropdownItem.isHidden(row.original)) {
                                                    return null;
                                                }
                                                return (
                                                    <DropdownMenu.Item
                                                        key={dropdownItem.text}
                                                        iconType={dropdownItem.icon}
                                                        text={dropdownItem.text}
                                                        link={dropdownItem.link}
                                                        onClick={() => dropdownItem.onClick(row.original)}
                                                    />
                                                );
                                            })}
                                        </DropdownMenu>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    };
export default EditRowToolbar;
