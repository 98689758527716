import ApiClient from './api-axios';

export default class ContentConverterApi extends ApiClient {
    articleToPdf = async (uuid) => {
        return await this.get(`/article/${uuid}/pdf`);
    };

    newsToPdf = async (uuid) => {
        return await this.get(`/news/${uuid}/pdf`);
    };

    articleToFormat = async (uuid, format) => {
        return await this.get(`/article/${uuid}/${format}`, {}, false, 'blob');
    };

    newsToFormat = async (uuid, format) => {
        return await this.get(`/news/${uuid}/${format}`, {}, false, 'blob');
    };
}
