export class Role {
    id = undefined;
    name = undefined;
    title = undefined;
    default = undefined;

    static create(obj) {
        return Object.assign(Object.create(Role.prototype), obj);
    }

    static get defaultRole() {
        const r = new Role();
        r.id = 6;
        r.name = 'system-operator';
        r.title = 'Системный оператор';
        r.default = true;
        return r;
    }

    dropdownOption() {
        return { label: this.title, value: this.id }
    }
}