import React, { useState } from 'react';
import classNames from 'classnames';
import Cropper from 'cropperjs';
import Modal from 'react-modal';
import IconButton from 'uikit/icon-button/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button/button';
import cx from './avatar-editor.module.scss';
import { useGlobalContext } from 'lib/hooks';
import Loading from 'uikit/loading';

const AvatarEditor = ({ open, title, onDismiss, onSubmit, img, type = 'circle' }) => {
    const MAX_HEIGHT = 1080;

    const { platform } = useGlobalContext();
    const [cropper, setCropper] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const afterOpenModal = () => {
        setIsLoaded(false);
        const image = document.getElementById('image');

        if (image != null) {
            image.setAttribute('src', img);

            const cropperTmp = new Cropper(image, {
                aspectRatio: type === 'rectangle' ? 16 / 5 : 1,
                viewMode: 1,
            });

            setCropper(cropperTmp);
        }

        setTimeout(() => {
            setIsLoaded(true);
        }, 700);
    };

    const getScaledRoundedCanvas = (sourceCanvas) => {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');

        let height = sourceCanvas.height;
        let width = sourceCanvas.width;

        if (height > MAX_HEIGHT) {
            height = MAX_HEIGHT;
            width = sourceCanvas.width !== sourceCanvas.height ?
                Math.round(sourceCanvas.width * (height / sourceCanvas.height)) : MAX_HEIGHT;
        }

        canvas.width = width;
        canvas.height = height;
        context.imageSmoothingEnabled = true;

        context.fillStyle = 'white';
        context.fillRect(0, 0, width, height);

        context.beginPath();

        if (type === 'circle') {
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI);
            context.closePath();
            context.clip();
        }

        context.drawImage(sourceCanvas, 0, 0, width, height);
        return canvas;
    };

    const submit = () => {
        let croppedCanvas;
        let roundedCanvas;

        // Crop
        croppedCanvas = cropper.getCroppedCanvas();

        // Scale and round
        roundedCanvas = getScaledRoundedCanvas(croppedCanvas);

        if (onSubmit) {
            onSubmit(roundedCanvas.toDataURL("image/jpeg", 0.7));
        }
    };
    return (
        <Modal className={`modal ${cx.modal}`} overlayClassName="overlay" isOpen={open} contentLabel={title} onAfterOpen={afterOpenModal}>
            <div className={classNames(cx.content, {[cx.mobile]: platform === 'mobile'})}>
                <div className={cx.title}>
                    <div>{title}</div>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS} />} onClick={onDismiss} />
                </div>
                <div className={cx.container}>
                    {!isLoaded && <Loading withOverlay={false} withRelativeOverlay={true} />}
                    <div style={{ opacity: isLoaded ? 1 : 0 }}>
                        <img alt="" height={480} id="image" src="/img/login.png" />
                    </div>
                </div>
                <div className={cx.buttons}>
                    <Button label="Отмена" onClick={onDismiss} fullWidth={platform === 'mobile'} />
                    <Button color="green" label="Отправить" onClick={submit} fullWidth={platform === 'mobile'} />
                </div>
            </div>
        </Modal>
    );
};

export default AvatarEditor;
