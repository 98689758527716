import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import className from 'classnames';
import _ from 'lodash';
import JSZip from 'jszip';
import { useNavigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { selectUsers } from 'slice/authSlice';
import { saveAs } from 'file-saver';
import Modal from 'react-modal';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';
import TreeUtils from 'lib/util/tree.util';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { useAuthorized } from 'lib/hooks/useAuthorized';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { TableComponent } from 'components/data-components';
import { FileViewDialog } from 'components/file/file-view-dialog';
import { FileEditDialog } from 'components/file/file-edit-dialog';
import { Empty } from 'components/projects';
import Confirmation from 'components/confirmation';
import { EditRowToolbar } from 'uikit/table';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import style from './files-page-tabs-content.module.scss';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import cx from 'app/scripting/pages/scripting-page/scripting-page.module.scss';
import Loading from 'uikit/loading';
import getFileTypeOptions from 'lib/helpers/getFileTypeOptions';

export const FilesPageOwnFilesTab = ({ projectUuid, sectionUuid, treeRef }) => {
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const selector = useSelector(state => state);
    const navigate = useNavigate();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { isAuthorizedAction } = useAuthorized();
    const { addSuccess, addError } = useMessage();

    const [users, setUsers] = useState([]);

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const [filesOffset, setFilesOffset] = useState(null);
    const [filesCount, setFilesCount] = useState(null);
    const [filesSort, setFilesSort] = useState(null);
    const [filesFilters, setFilesFilters] = useState(null);
    const [filesTotal, setFilesTotal] = useState(0);
    const [fileSizeRange, setFileSizeRange] = useState([0, 1]);

    const [, setIsInit] = useState(false);
    const [isTreeInit, setIsTreeInit] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const [isViewFileModal, toggleViewFileModal] = useState(false);
    const [isEditFileModal, toggleEditFileModal] = useState(false);

    const checkboxHandler = useCallback(node => {
        setCheckedCheckbox(prevValue => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);
    const fetchFiles = useCallback(
        async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
            setIsLoading(true);

            try {
                let res;

                if (projectUuid && sectionUuid) {
                    res = await api.file.getFileListBySectionNew(
                        projectUuid,
                        sectionUuid,
                        offset / count,
                        count,
                        sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                        search,
                        filters
                    );
                } else if (projectUuid && !sectionUuid) {
                    res = await api.file.getFileListByProjectNew(
                        projectUuid,
                        offset / count,
                        count,
                        sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                        search,
                        filters
                    );
                } else {
                    res = await api.file.getAttachedFileList({
                        page: offset / count,
                        size: count,
                        sort: sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                        search,
                        filters,
                    });
                }

                setIsEmpty(res.totalElements === 0 && _.isEmpty(filters) && !search);

                setFilesOffset(offset);
                setFilesCount(count);
                setFilesSort(sort);
                setFilesFilters(filters);
                setFilesTotal(res.totalElements);
                setFiles(res.content);

                setFileSizeRange([Math.floor(res.minFileSize / 1000), Math.ceil(res.maxFileSize / 1000)]);

                setIsReLoading(false);
                setIsLoading(false);

                return res;
            } catch (e) {
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                console.log(e);
            }
        },
        [addError, projectUuid, sectionUuid]
    );

    const deleteFiles = useCallback(
        async data => {
            try {
                setIsLoading(true);
                closeDialog();

                if (data?.fileId) {
                    await api.file.removeFile(data.fileId);
                } else if (checkedCheckboxRef.current.length) {
                    for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                        await api.file.removeFile(checkedCheckboxRef.current[i].fileId);
                    }
                }

                tableRef?.current?.reload();
                addSuccess(data?.fileId ? 'Файл удален' : 'Выбранные файлы удалены');
            } catch (error) {
                addError('При удалении произошла ошибка');
            } finally {
                setIsLoading(false);
            }
        },
        [closeDialog, addSuccess, addError]
    );
    const onFilesDelete = useCallback(
        async data => {
            if (data?.length) {
                setCheckedCheckbox(
                    data.map(d => {
                        d.original.id = d.original.fileId;
                        return d.original;
                    })
                );
            }

            let text = '';

            if (data?.length) {
                text = data.map(item => {
                    return {
                        ...item.original,
                        text: (
                            <>
                                <span onClick={() => fileSelectHandler(item.original)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                    {item?.original?.name}
                                </span>
                            </>
                        ),
                    };
                });
            } else {
                text = (
                    <span>
                        Вы действительно хотите удалить файл&nbsp;
                        <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => fileSelectHandler(data)}>
                            {data?.name}
                        </span>
                        &nbsp;? Этот процесс нельзя будет отменить
                    </span>
                );
            }

            openDialog({
                title: 'Удаление',
                subTitle: data?.length
                    ? 'Вы действительно хотите удалить нижеперечисленные объекты? Этот процесс нельзя будет отменить'
                    : null,
                text,
                contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
                color: 'red',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Да, удалить',
                onChange: files => checkboxHandler(files),
                onSubmit: () => deleteFiles(data?.length ? checkedCheckboxRef.current : data),
                onClose: closeDialog,
            });
        },
        [checkboxHandler, deleteFiles, openDialog, closeDialog]
    );

    const columns = useMemo(() => {
        return [
            {
                Header: 'Название',
                accessor: 'name',
                width: 400,
                Cell: data => {
                    return (
                        <div
                            className={className(style.nameCell, { [style.mobileNameCell]: data.isMobile })}
                            onClick={() => fileSelectHandler(data.row.original)}
                        >
                            <div className={style.iconWrapper}>
                                <Icon type={FILES_TYPE_ICONS[data.row.original.type] || Icons.NONE_FILE} width={24} height={24} />
                            </div>
                            <div>
                                <MultiClumpTooltip className={style.fileName} label={data.row.original.name} />
                                <div className={style.fileDescription}>
                                    <span className={style.fileType}>{FILES_TYPE_SHORT_NAME[data.row.original.type]}</span> —{' '}
                                    {humanFileSize(data.row.original.size, true)}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Дата создания',
                accessor: 'createTime',
                width: 120,
                Cell: function (data) {
                    const date = new Date(data.row.original.createTime).toLocaleString();

                    return (
                        <div>
                            {data.isMobile && <div className={style.subheader}>Дата создания</div>}
                            {date.substr(0, date.length - 3)}
                        </div>
                    );
                },
            },
            {
                id: 4,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                width: 100,
                Cell: function (data) {
                    const permissions = data.cell.row.original.permissions;
                    let toolbar = [];
                    if (permissions?.rename || permissions?.replace) {
                        toolbar.push({
                            icon: Icons.EDIT_PEN,
                            tooltip: 'Редактировать',
                            onClick: data => {
                                setSelectedFile(data);
                                toggleEditFileModal(true);
                            },
                        });
                    }
                    if (permissions?.delete) {
                        toolbar.push({
                            icon: Icons.TRASH,
                            tooltip: 'Удалить',
                            onClick: async data => onFilesDelete(data),
                        });
                    }
                    if (permissions?.download) {
                        toolbar.push({
                            icon: Icons.DOWNLOAD,
                            tooltip: 'Скачать',
                            loader: true,
                            onClick: async data => {
                                const res = await api.file.download(data.fileId);
                                const blob = new Blob([res], { type: data.type });
                                saveAs(blob, data.name);
                            },
                        });
                    }

                    return EditRowToolbar(toolbar)(data);
                },
            },
        ];
    }, [onFilesDelete, platform]);
    const filesActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.DOWNLOAD,
            label: 'Скачать',
            onClick: async data => {
                const zip = new JSZip();

                for (let i = 0; i < data.length; i++) {
                    const res = await api.file.download(data[i].original.fileId);
                    const blob = new Blob([res], { type: data[i].original.type });

                    zip.file(data[i].original.name, blob);
                }
                const zipData = await zip.generateAsync({ type: 'blob' });

                saveAs(zipData, 'files.zip');
            },
        });
        if (isAuthorizedAction([GLOBAL_ACTIONS.FILE_DELETE])) {
            actions.push({
                icon: Icons.TRASH,
                label: 'Удалить',
                onClick: data => onFilesDelete(data),
            });
        }

        return actions;
    }, [isAuthorizedAction, onFilesDelete]);

    const loadUsers = async search => {
        let options = [];

        if (search) {
            options = users.filter(user => {
                return user.label.toString().toLowerCase().includes(search.toLowerCase());
            });
        }

        return { options };
    };

    const _filesFilters = [
        {
            label: 'Дата загрузки',
            fields: [
                {
                    name: 'createTime',
                    type: 'date-select',
                    default: {
                        type: {
                            label: 'Все время',
                            value: 0,
                        },
                        from: null,
                        to: null,
                    },
                },
            ],
        },
        {
            label: 'Формат файла',
            fields: [
                {
                    name: 'type.in',
                    type: 'select',
                    default: null,
                    isMulti: true,
                    options: getFileTypeOptions(),
                },
            ],
        },
        {
            label: 'Автор файла',
            fields: [
                {
                    name: 'userLogin.in',
                    type: 'search',
                    default: null,
                    isMulti: true,
                    loadOptions: loadUsers,
                },
            ],
        },
        {
            label: 'Размер файла',
            fields: [
                {
                    name: 'fileSize',
                    type: 'range',
                    default: {
                        min: fileSizeRange[0],
                        max: fileSizeRange[1],
                        from: fileSizeRange[0],
                        to: fileSizeRange[1],
                        type: {
                            label: 'Кб',
                            value: 0,
                        },
                        range: fileSizeRange,
                    },
                    options: [
                        {
                            label: 'Кб',
                            value: 0,
                        },
                        {
                            label: 'Мб',
                            value: 1,
                        },
                        {
                            label: 'Гб',
                            value: 2,
                        },
                    ],
                },
            ],
        },
    ];

    const fileSelectHandler = values => {
        setSelectedFile(values);
        toggleViewFileModal(true);
    };
    const onFileViewClose = () => {
        toggleViewFileModal(false);
        // const params = new URLSearchParams(window.location.search);
        // const _sort = {
        //     by: params.get('filesSortBy') || '',
        //     desc: params.get('filesSortAsc') === 'false'
        // }
        // fetchFiles(undefined, undefined, _sort);
    };

    const onFileEdit = fileName => {
        setFiles(prevState => {
            return prevState.map(file => {
                if (file.fileId === selectedFile.fileId) {
                    return {
                        ...file,
                        name: fileName,
                    };
                }

                return file;
            });
        });
    };
    const onTreeUpdate = useCallback(
        async node => {
            document.getElementById('content').scrollTo(0, 0);

            if (!node) {
                return;
            }

            const projectUuid = TreeUtils.getTopParentNode(node);
            const sectionUuid = node.nodeType === 'SECTION' ? node.id : null;

            await navigate('/files/all/' + projectUuid.id + (sectionUuid ? '/' + sectionUuid : ''));
        },
        [navigate]
    );

    const onFilesChange = () => fetchFiles(filesOffset, filesCount, filesSort, filesFilters);

    useEffect(() => {
        const fetchData = async () => {
            const usersResponse = await selectUsers(selector);
            setUsers(
                usersResponse.content.map(user => {
                    return { label: user.firstName + ' ' + user.lastName, value: user.login };
                })
            );
        };

        fetchData();
    }, [selector]);
    useEffect(() => {
        const fetch = () => {
            if (!sectionUuid || !projectUuid) {
                return;
            }

            const tree = treeRef?.current?.tree;

            if (tree && tree.nodes.length !== 0) {
                let node = tree.getNodeById(sectionUuid || projectUuid);
                let nodes = [];

                while (true) {
                    if (!node.id) {
                        break;
                    }

                    nodes.push(node);
                    node = node.parent;
                }

                nodes.reverse().forEach(node => {
                    tree.openNode(node, { silent: true });
                    tree.selectNode(node, { silent: true });
                });
            } else {
                setTimeout(fetch, 300);
            }
        };
        fetch();
    }, [treeRef, projectUuid, sectionUuid]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setFilesTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [projectUuid, sectionUuid]);

    useEffect(() => {
        const fetch = () => {
            if (!projectUuid || projectUuid === 'all') {
                return;
            }

            const tree = treeRef?.current?.tree;

            if (tree && tree.nodes.length !== 0) {
                let node = tree.getNodeById(sectionUuid || projectUuid);
                let nodes = [];

                while (true) {
                    if (!node.id) {
                        break;
                    }
                    nodes.push(node);
                    node = node.parent;
                }

                nodes.reverse().forEach(node => {
                    tree.openNode(node, { silent: true });
                    tree.selectNode(node, { silent: true });
                });
            } else {
                setTimeout(fetch, 300);
            }
        };
        fetch();
    }, [treeRef, projectUuid, sectionUuid]);

    if (!isTreeInit && treeRef?.current?.tree) {
        treeRef.current.tree.on('selectNode', onTreeUpdate);
        setIsTreeInit(true);
    }

    return (
        <>
            <Confirmation {...dialogState} />
            <Modal
                isOpen={isEditFileModal}
                className={style.editModal}
                onRequestClose={() => toggleEditFileModal(false)}
                overlayClassName={style.editModalOverlay}
            >
                <FileEditDialog file={selectedFile} handleClose={() => toggleEditFileModal(false)} onFileChange={onFileEdit} />
            </Modal>
            {isViewFileModal && (
                <FileViewDialog file={selectedFile} onChange={onFilesChange} onRemove={onFilesChange} handleClose={onFileViewClose} />
            )}
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {!isReLoading && !isEmpty && <h1 className={style.title}>Общие файлы</h1>}
            {isEmpty && !isLoading && <Empty title="Файлы не найдены" />}
            {!isEmpty && (
                <TableComponent
                    innerRef={tableRef}
                    isLoading={isLoading}
                    searchTitle="Поиск по имени"
                    columns={columns}
                    data={files}
                    actions={filesActions}
                    total={filesTotal}
                    filters={_filesFilters}
                    onPaginate={fetchFiles}
                    isMobile={platform === 'mobile'}
                    name="files"
                />
            )}
        </>
    );
};
