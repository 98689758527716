import { createSlice } from '@reduxjs/toolkit';

export const newsPickerSlice = createSlice({
    name: 'newsPicker',
    initialState: {
        isNewsTickerUpdate: false,
        news: {
            count: 0,
            list: []
        },
        newsBeingRemovedId: null
    },
    reducers: {
        setNewsPicker: (state, action) => {
            state.news = action.payload;
        },
        updateNewsTicker: (state, action) => {
            state.isNewsTickerUpdate = action.payload;
        },
        setNewsBeingRemoved: (state, action) => {
            state.newsBeingRemovedId = action.payload;
        }
    }
});

export const { updateNewsTicker, setNewsPicker, setNewsBeingRemoved } = newsPickerSlice.actions;

export const selectNewsPicker = state => state.newsPicker.news;
export const selectNewsPickerUpdateStatus = state => state.newsPicker.isNewsTickerUpdate;
export const selectNewsBeingRemoved = state => state.newsPicker.newsBeingRemovedId;

export default newsPickerSlice.reducer;
