import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cs from './divider.module.scss';

const Divider = ({ className, style }) => {
    return (
        <div className={cn(cs.divider, className)} style={style}/>
    );
};

Divider.defaultProps = {
    className: '',
    style: {}
};

Divider.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
};

export default Divider;