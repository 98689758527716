import { useContext, useEffect } from 'react';
import { useGlobalContext } from 'lib/hooks/index';
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';

export const useCustomMobileMenu = (renderFunction, menuTitle = null) => {
	const { platform } = useGlobalContext();
	const { setRenderMenu, setMenuContentType, setMenuTitle } = useContext(MobileMenuContext);

	useEffect(() => {
		if (platform === 'mobile' && setMenuContentType && setRenderMenu) {
			setMenuContentType(MENU_CONTENT_TYPE.CUSTOM);
			setRenderMenu(renderFunction);

			if (menuTitle) {
				setMenuTitle(menuTitle);
			}
		}

		return () => {
            if (platform === 'mobile') {
                setMenuContentType(MENU_CONTENT_TYPE.MAIN_MENU);

                if (menuTitle) {
                    setMenuTitle(null);
                }
            }
		}
	}, [renderFunction, menuTitle, platform, setRenderMenu, setMenuContentType, setMenuTitle]);
};
