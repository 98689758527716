import React, { useCallback, useEffect, useState } from 'react';
import Icon, { Icons } from 'uikit/icon';
import FileCommentsListItem from 'components/file/file-comments-list-item';
import style from 'components/file/file-comments-list.module.scss';
import api from 'api/index';

const FileCommentsList = ({ isOpen, uuid, addComments }) => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');

    const fetchComments = useCallback(async () => {
        try {
            const res = await api.fileComments.getFileComments(uuid);
            setComments(res.sort((a, b) => a.createTime - b.createTime).reverse());
        } catch (e) {
            console.log(e);
        }
    }, [uuid]);

    const handleAddComment = async (e) => {
        e.preventDefault();

        try {
            await api.fileComments.postFileComment({ attachmentId: uuid, text: comment });
            setComment('');
            await fetchComments();
        } catch (e) {
            console.log(e);
        }
    };

    const removeComment = async (uuid) => {
        try {
            await api.fileComments.deleteFileComment(uuid);
            fetchComments();
        } catch (e) {
            console.log(e);
        }
    };

    const likeComment = async (uuid) => {
        try {
            await api.like.addFileCommentLike(uuid);
            fetchComments();
        } catch (e) {
            console.log(e);
        }
    };

    const dislikeComment = async (uuid) => {
        try {
            await api.dislike.addFileDislike(uuid);
            fetchComments();
        } catch (e) {
            console.log(e);
        }
    };

    const onCommentEditHandler = () => {
        fetchComments();
    };

    useEffect(() => {
        fetchComments();
    }, [isOpen, fetchComments]);

    return (
        <>
            <div className={style.commentsWrapper}>
                <div className={style.comments}>
                    {comments.map(comment => {
                        return (
                            <FileCommentsListItem key={comment.id} comment={comment} onRemove={removeComment}
                                                  onLike={likeComment} onDislike={dislikeComment}
                                                  onEdit={onCommentEditHandler}/>
                        );
                    })}
                </div>
                {addComments &&
                    <form onSubmit={handleAddComment} className={style.commentFooter}>
                        <input value={comment} placeholder={'Ваш коментарий'} onChange={(e) => setComment(e.target.value)}/>
                        <button type="submit" className={style.commentSubmitButton}>
                            <Icon type={Icons.SEND} width={24} height={24} color={'blue'}/>
                        </button>
                    </form>
                }
            </div>
        </>
    );
};

export default FileCommentsList;
