export function fillEmpty(headings) {
    for (let i = 0; i < headings.length; i += 1) {
        let j = headings[i - 1]?.level || 0;
        if (headings[i].level - j > 1) {
            while (j < headings[i].level) {
                headings.splice(i, 0, { attrs: { level: j + 1 }, content: [{ type: 'text', text: '' }] });
                j += 1;
            }
        }
    }

    return headings;
}

const exitHeadingBranch = (heading, targetLevel) => {
    let currentHeading = heading;

    while (currentHeading.attrs.level > targetLevel) {
        currentHeading = currentHeading.parent;
    }

    //   currentHeading.content = currentHeading.content.map(({ parent, ...rest }) => ({ rest }));

    return currentHeading;
};

export function toTree(headings) {
    fillEmpty(headings);

    const tree = [];
    let currentHeading;
    for (let i = 0; i < headings.length; i += 1) {
        const heading = headings[i];
        if (heading.attrs.level === 1) {
            const h = { ...heading };
            tree.push(h);
            currentHeading = h;
        } else if (heading.attrs.level > currentHeading.attrs.level) {
            const h = { ...heading, parent: currentHeading };
            currentHeading.content.push({ type: 'tableOfContentsList', content: [h] });
            currentHeading = h;
        } else if (heading.attrs.level <= currentHeading.attrs.level) {
            currentHeading = exitHeadingBranch(currentHeading, heading.attrs.level - 1);

            const h = { ...heading, parent: currentHeading };
            (currentHeading?.content || headings).push(h);
            currentHeading = h;
        }
    }

    return tree;
}

export function getHeadings(editor) {
    const headings = [];

    editor.state.doc.descendants(node => {
        if (node.type.name !== 'customHeading') {
            return;
        }

        if (!node.textContent?.trim()) {
            return;
        }

        headings.push({
            type: 'tableOfContentsItem',
            attrs: {
                class: 'h' + node.attrs.level,
                'data-level': node.attrs.level,
                'data-mark': node.attrs['data-mark'],
                'data-uniqid': node.attrs['data-uniq-id'],
            },
            content: [{ type: 'paragraph', content: [{ type: 'text', text: node.textContent }] }],
        });
    });

    return headings;
}
