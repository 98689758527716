import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import ButtonMenu from 'uikit/button-menu';
import IconButton, { IconButtonStatus, IconButtonType } from 'uikit/icon-button';
import cs from './posting-search.module.scss';

const PostingSearch = ({
    onCancel,
    onPrevious,
    onNext,
    currentPosition,
    allSearchResult,
    value,
    onChange,
    onKeyPress,
    className,
    placeholder,
    children,
    isMobile,
    actions,
    ...attr
}) => {
    const toolbarActions = actions.filter(a => a.visible && a.display && a.display.includes(isMobile ? 'mobile_toolbar' : 'desktop_toolbar'));
    const dropdownActions = actions.filter(a => a.visible && (!a.display || (isMobile && !a.display.includes('mobile_toolbar'))));

    return (
        <div id="postingSearchContainer" className={classNames(cs.search, {[cs.mobile]: isMobile}, className)} {...attr}>
            <div className={cs.inputBlock}>
                <div id="postingSearchInputBlock" className={cs.input}>
                    <div className={cs.searchInputContainer}>
                        <Icon width={16} height={16} type={Icons.SEARCH} />
                        <input
                            onKeyPress={onKeyPress}
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder={placeholder}
                        />
                    </div>
                    {allSearchResult > 0 &&
                    <div className={cs.searchInputInfo}>
                        <span>{currentPosition} / {allSearchResult}</span>
                        <div className={cs.iconContainer}>
                            <Icon onClick={onPrevious} width={12} height={12} type={Icons.CHEVRON_UP}/>
                            <Icon onClick={onNext} width={12} height={12} type={Icons.ARROW_DOWN}/>
                        </div>
                        <div className={cs.cancelContainer}>
                            <Icon onClick={onCancel} width={10} height={10} type={Icons.CROSS} />
                        </div>
                    </div>}
                </div>
            </div>
            <div className={cs.buttons}>
                {toolbarActions.map((item, i) => (
                    <IconButton
                        key={i}
                        id={item.id}
                        onClick={item.onClick}
                        icon={<Icon type={item.icon} tooltip={item.title} width={15} height={15} />}
                        type={IconButtonType.NORMAL}
                        status={IconButtonStatus.NONE}
                        className={classNames(cs.toolbarItem, item.className)}
                        link={item.link}
                    />
                ))}
                {dropdownActions.length > 0 && (
                    <ButtonMenu
                        id="postingSearchDropdown"
                        className={cs.dropdown}
                        tooltip="Действия"
                        position="left"
                        items={dropdownActions}
                        submenuPosition="left"
                        menuStyle={{ width: '270px', left: '-245px' }}
                    />
                )}
                {children}
            </div>
        </div>
    );
};

PostingSearch.defaultProps = {
    value: '',
    onChange: () => {

    },
    placeholder: '',
    isMobile: false,
    actions: []
};

PostingSearch.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    isMobile: PropTypes.bool,
    actions: PropTypes.array
};

export default PostingSearch;
