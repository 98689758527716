import React from 'react';
import Modal from 'react-modal';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import cx from './chat-info.module.scss';

export default function ChatInfo({ chat, onOpenChat, onUpdateChatGroup, onRemoveChatGroup, onClose }) {
    return (
        <Modal isOpen={true} className={cx.chatInfo} overlayClassName={cx.chatInfoOverlay}>
            <div className={cx.chatInfoHeader}>
                <div className={cx.chatInfoHeaderTitle}>
                    <h3>Информация</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={onClose}/>
                </div>
                <div className={cx.chatInfoHeaderInfo}>
                    <div className={cx.chatInfoHeaderInfoAvatar}>
                        {chat.image && <img src={chat.image} alt="" />}
                    </div>
                    <div className={cx.chatInfoHeaderInfoData}>
                        <h4>{chat.title}</h4>
                        {/*{chat.type === 0 && <p>Онлайн</p>}*/}
                        {chat.type === 1 &&
                        <p>{chat.users.length + chat.groups.map(group => group['members'].length)} участника(-ов)</p>}
                    </div>
                </div>
                {(chat.type === 0 || chat.type === 1) && (onUpdateChatGroup || onRemoveChatGroup) &&
                <ul className={cx.chatInfoHeaderMenu}>
                    {chat.type === 0 &&
                    <li onClick={onClose}><Icon type={Icons.CHAT}/>Написать сообщение</li>}
                    {chat.type === 0 &&
                    <li onClick={() => window.open('/edit-user/' + chat.login)}>
                        <Icon type={Icons.EXIT}/>Открыть профиль в новой вкладке
                    </li>}
                    {chat.type === 1 && onUpdateChatGroup &&
                    <li onClick={() => onUpdateChatGroup(chat.id)}>
                        <Icon type={Icons.USER}/>Добавить участника
                    </li>}
                    {chat.type === 1 && onUpdateChatGroup &&
                    <li onClick={() => onUpdateChatGroup(chat.id)}>
                        <Icon type={Icons.EDIT_PEN}/>Редактировать чат
                    </li>}
                    {chat.type === 1 && onRemoveChatGroup &&
                    <li className={cx.chatInfoHeaderMenuRed} onClick={() => onRemoveChatGroup(chat.id)}>
                        <Icon type={Icons.TRASH}/>Удалить чат
                    </li>}
                </ul>}
                {chat.type === 0 && chat.groups && chat.groups.length !== 0 &&
                <div className={cx.chatInfoBody}>
                    <h4>Общие чаты</h4>
                    <div className={cx.chatInfoBodyItems}>
                        {chat['groups'].map((group) => {
                            return (
                                <div key={group.id} className={cx.chatInfoBodyItem}
                                     onClick={() => onOpenChat(group.id, 1)}>
                                    <div className={cx.chatInfoBodyItemContainer}>
                                        <div className={cx.chatInfoBodyItemContainerAvatar}>
                                            {group.image && <img src={group.image} alt="" />}
                                        </div>
                                        <div className={cx.chatInfoBodyItemContainerData}>
                                            <p>{group.title}</p>
                                            <span>{group.users.length} участника(-ов)</span>
                                        </div>
                                    </div>
                                    {onRemoveChatGroup && <Icon type={Icons.CHAT} width={15} height={13}/>}
                                </div>
                            );
                        })}
                    </div>
                </div>}
                {chat.type === 1 && chat.users && chat.users.length !== 0 &&
                <div className={cx.chatInfoBody}>
                    <h4>Участники беседы</h4>
                    <div className={cx.chatInfoBodyItems}>
                        {chat['users'].sort((a, b) => a.isAdmin ? -1 : b.isAdmin ? 1 : 0).map((user) => {
                            return (
                                <div key={user.id} className={cx.chatInfoBodyItem}
                                     onClick={() => onOpenChat(user.id, 0)}>
                                    <div className={cx.chatInfoBodyItemContainer}>
                                        <div className={cx.chatInfoBodyItemContainerAvatar}>
                                            {user.image && <img src={user.image} alt="" />}
                                            {user.isAdmin && <Icon className={cx.crown} type={Icons.CROWN}/>}
                                        </div>
                                        <div className={cx.chatInfoBodyItemContainerData}>
                                            <p>{user.firstName} {user.lastName}</p>
                                            {/*<span>{user.isOnline ? 'Онлайн' : 'Оффлайн'}</span>*/}
                                        </div>
                                    </div>
                                    {onRemoveChatGroup && <Icon type={Icons.CHAT} width={15} height={13}/>}
                                </div>
                            );
                        })}
                        {chat['groups'].map((group) => {
                            return (
                                <div key={group.id} className={cx.chatInfoBodyGroup}>
                                    <div key={group.id} className={cx.chatInfoBodyItem}>
                                        <div className={cx.chatInfoBodyItemContainer}>
                                            <div className={cx.chatInfoBodyItemContainerAvatar}>
                                                <Icon type={Icons.GROUP} width={48}/>
                                            </div>
                                            <div className={cx.chatInfoBodyItemContainerData}>
                                                <p>{group.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div key={group.id} className={cx.chatInfoBodyGroupItems}>
                                        {group['members'].map(member => {
                                            if (chat['users'].find(p => p['id'] === member['id'])) {
                                                return null;
                                            }

                                            return (
                                                <div key={member.id} className={cx.chatInfoBodyItem}
                                                     onClick={() => onOpenChat(member.id, 0)}>
                                                    <div className={cx.chatInfoBodyItemContainer}>
                                                        <div className={cx.chatInfoBodyItemContainerAvatar}>
                                                            {member.image && <img src={member.image} alt="" />}
                                                        </div>
                                                        <div className={cx.chatInfoBodyItemContainerData}>
                                                            <p>{member.firstName} {member.lastName}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>}
            </div>
        </Modal>
    );
};
