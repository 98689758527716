const Buttons = {
    ADD: 'add',
    ARCHIVE: 'archive',
    STAT: 'statistic',
    ADD_PROJECT: 'addProject',
    ADD_SECTION: 'addSection',
    ADD_ARTICLE: 'addArticle',
    ADD_NEWS: 'addNews',
    ADD_USER: 'addUser',
    ADD_FILE: 'addFile',
    ADD_GROUP: 'addGroup',
    ADD_SCRIPT: 'addScript',
    SETTINGS: 'globalSettings',
    SETTINGS_COMPANY: 'globalSettingsCompany',
    SETTINGS_SUPERVISORS: 'globalSettingsSupervisors',
    SETTINGS_POSITIONS: 'globalSettingsPositions',
    SETTINGS_ROLES: 'globalSettingsRoles',
    SETTINGS_LICENSE: 'license',
    SETTINGS_GLOSSARY: 'globalSettingsGlossary',
    SETTINGS_INTEGRATION: 'globalSettingsIntegration',
    SETTINGS_ABOUT: 'globalSettingsAbout',
    DRAFT: 'draft',
    ACTIVITY: 'activity',
    USERS: 'users',
    DELAYED: 'userMenuDelayed',
};
export default Buttons;
