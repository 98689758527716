import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import { useAuthorized, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { Empty } from 'components/projects/index';
import { TableComponent } from 'components/data-components';
import { Icons } from 'uikit/icon';
import api from 'api/index';
import cx from './tab-users.module.scss';
import Loading from 'uikit/loading';
import _ from 'lodash';


const TabUsers = ({ projectId, sectionId }) => {
    const tableRef = useRef(null);

    const navigate = useNavigate();
    const { platform } = useGlobalContext();

    const selector = useSelector(state => state);
    const { isAuthorizedAction } = useAuthorized();

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [roles, setRoles] = useState([]);

    const [data, setData] = useState([]);
    const [dataTotal, setDataTotal] = useState(0);

    const requestUsers = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        setIsLoading(true);

        let _sortBy;
        if (sort.by === 'role') {
            _sortBy = 'roleTitle';
        } else {
            _sortBy = 'user.' + sort.by;
        }

        const usersResponse = await api.resource.findUsersByResourceIdPaginate(
            sectionId ? sectionId : projectId,
            offset / count, count,
            sort.by ? (_sortBy + ',' + (sort.desc ? 'desc' : 'asc')) : 'createTime,desc', search, filters
        );

        setDataTotal(usersResponse.data.totalElements);
        setData(usersResponse.data.content);

        setIsEmpty(usersResponse.totalElements === 0 && _.isEmpty(filters) && !search);

        setIsLoading(false);
        setIsReLoading(false);
    }, [projectId, sectionId]);

    const usersColumns = useMemo(() => [
        {
            Header: 'Имя',
            accessor: 'firstName',
            width: 125,
            Cell: (data) => {
                return (
                    <>
                        <span className={cx.title}
                              onClick={() => navigate('/edit-user/' + data.row.original['user']['login'])}>
                            {data.row.original['user']['firstName']}
                            {data.isMobile && (
                                <>&nbsp;{data.row.original['user']['lastName']}</>
                            )}
                        </span>
                    </>
                );
            }
        },
        {
            Header: 'Фамилия',
            accessor: 'lastName',
            width: 125,
            isVisible: platform !== 'mobile',
            Cell: (data) => {
                return (
                    <span className={cx.title} onClick={() => navigate('/edit-user/' + data.row.original['user']['login'])}>
                        {data.row.original['user']['lastName']}
                    </span>
                )
            }
        },
        {
            Header: 'Email',
            accessor: 'email',
            width: 150,
            maxWidth: 500,
            Cell: (data) => {
                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Email</div>}
                        <span className={cx.title} onClick={() => navigate('/edit-user/' + data.row.original['user']['login'])}>
                            {data.row.original['user']['email'] ?? '-'}
                        </span>
                    </>
                );
            }
        },
        {
            Header: 'Роль',
            accessor: 'role',
            width: 125,
            Cell: (data) => {
                const role = roles.find((role) => role.value === data.row.original['role']['name']);

                return (
                  <>
                      {data.isMobile && <div className={cx.subheader}>Роль</div>}
                      {role ? role.label : data.row.original['role']['title']}
                  </>
                )
            }
        },
        {
            Header: 'Дата изменения',
            accessor: 'lastModifiedDate',
            width: 100,
            Cell: (data) => {
                const date = data.row.original['user']['lastModifiedDate']
                    ? new Date(data.row.original['user']['lastModifiedDate']).toLocaleString()
                    : new Date(data.row.original['user']['createdDate']).toLocaleString();

                return (
                    <>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {date.substr(0, date.length - 3)}
                    </>
                )
            }
        },
        // {
        //     id: 4,
        //     width: 64,
        //     maxWidth: 64,
        //     Cell: (data) => {
        //         let toolbar = [];
        //
        //         toolbar.push({
        //             icon: Icons.EDIT_PEN,
        //             tooltip: 'Редактировать',
        //             link: `/edit-user/${data.row.original['user']['login']}/edit`,
        //             isHidden: original => !(original?.user?.permittedActions ?? []).includes(GLOBAL_ACTIONS.USER_EDIT)
        //         });
        //
        //         return EditRowToolbar(toolbar)(data);
        //     }
        // }
    ], [navigate, roles, platform]);

    const loadProjectOptions = async (search) => {
        let options = [];

        if (search) {
            const projectsResponse = await api.project.searchByTitle(search);

            options = projectsResponse.map((project) => ({ label: project.title, value: project.id }));
        }

        return { options };
    };

    const loadGroupsOptions = useCallback(async (search) => {
        let options = [];

        if (search) {
            const groupsResponse = await api.group.searchByName(search);

            options = groupsResponse.map((group) => ({ label: group.name, value: group.id }));
        }

        return { options };
    }, []);

    const loadRolesOptions = useCallback(async () => {
        let options = [];
        const rolesResponse = await api.role.getRolesList();

        options = rolesResponse.map((role) => ({ label: role.title, value: role.name }));

        return {
          options: options,
      };
    }, []);

    const loadOfficesOptions = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const officesResponse = await api.office.getOffices(page, 20, '', search);

        options = officesResponse.content.map((office) => ({ label: office.name, value: office.name }));

        return {
          options: options,
          hasMore: page < officesResponse.totalPages - 1,

          additional: {
              page: page + 1,
          },
      };
    }, []);

    const loadPositionsOptions = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const positionsResponse = await api.userPosition.getUserPosition(page, 20, '', search);

        options = positionsResponse.content.map((position) => ({ label: position.title, value: position.title }));

        return {
          options: options,
          hasMore: page < positionsResponse.totalPages - 1,

          additional: {
              page: page + 1,
          },
      };
    }, []);

    const loadSupervisorsOptions = useCallback(async (search, _loadedOptions, { page }) => {
      let options = [];
      const supervisorsResponse = await api.office.getSupervisors(page, 20, '', search);

      options = supervisorsResponse.content.map((value) => ({ label: value.user.firstName + ' ' + value.user.lastName, value: value.user.login }));

      return {
        options: options,
        hasMore: page < supervisorsResponse.totalPages - 1,

        additional: {
            page: page + 1,
        },
    };
  }, []);

    const usersFilters = [
        {
            'label': 'Должность',
            'fields': [
                {
                    'name': 'positionTitle.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadPositionsOptions
                }
            ]
        },
        {
            'label': 'Руководитель',
            'fields': [
                {
                    'name': 'supervisorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadSupervisorsOptions
                }
            ]
        },
        {
            'label': 'Офис',
            'fields': [
                {
                    'name': 'officeName.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadOfficesOptions
                }
            ]
        },
        {
            'label': 'Группа',
            'fields': [
                {
                    'name': 'group.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadGroupsOptions
                }
            ]
        },
        {
            'label': 'Проект',
            'fields': [
                {
                    'name': 'project.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadProjectOptions
                }
            ]
        },
        {
            'label': 'Роль',
            'fields': [
                {
                    'name': 'roleName.in',
                    'type': 'select',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadRolesOptions
                }
            ]
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            let response = await api.role.getRolesList();
            setRoles(response.map((item) => {
                return { id: item['id'], label: item['title'], value: item['name'] };
            }));
        };

        fetchData();
    }, [selector]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setDataTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [projectId, sectionId]);

    return (
        <div className={cx.tabUsers}>
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && (
                <Empty
                    title="Участники не найдены"
                    description="Воспользуйтесь кнопкой ниже для добавления"
                    buttons={[{
                        icon: Icons.USER,
                        title: 'Добавить участника',
                        link: '/add/user'
                    }]}
                />
            )}
            {!isEmpty && (
                <TableComponent
                    innerRef={tableRef}
                    isLoading={isLoading}
                    title="Участники"
                    searchTitle="Поиск"
                    name="users"
                    columns={usersColumns}
                    total={dataTotal}
                    data={data}
                    onPaginate={requestUsers}
                    filters={usersFilters}
                    isMobile={platform === 'mobile'}
                    addLink={isAuthorizedAction([GLOBAL_ACTIONS.USER_CREATE]) ? '/add/user' : ''}
                    defaultSort={{ by: 'multiName', desc: true }}
                    sortOptions={[
                        { Header: 'По имени (по алфавиту по фамилии) (по умолчанию)', accessor: 'multiName' },
                        { Header: 'По дате создания', accessor: 'createdDate' },
                        { Header: 'По дате изменения', accessor: 'lastModifiedDate' },
                        { Header: 'По должности', accessor: 'position' },
                        { Header: 'По офису', accessor: 'office' },
                        { Header: 'По руководителю', accessor: 'supervisor' },
                    ]}
                />
            )}
        </div>
    );
};

TabUsers.defaultProps = {
    uuid: ''
};

TabUsers.propTypes = {
    uuid: PropTypes.string
};

export default TabUsers;
