import React from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';
import { GlobalContext } from 'lib/context';
import FormDataSaveWrapper from 'containers/form-data-save-wrapper';
import { UploadProvider } from 'components/background-upload/upload-provider';
import CustomToast from 'uikit/toast/custom-toast';
import store from '../store';

const App = ({ children }) => {
    const [userRolesInfo, setUserRolesInfo] = React.useState();
    const [sessionHasBeenFetched, setSessionHasBeenFetched] = React.useState(false);

    const [platform, setPlatform] = React.useState('desktop');

    const [visitedUrls, setVisitedUrls] = React.useState([]);

    const [formDataChanged, setFormDataChanged] = React.useState(false);
    const [formDataChangedSubmitHandler, setFormDataChangedSubmitHandler] = React.useState(null);

    const [locationUrl, setLocationUrl] = React.useState('');
    const [prevLocationUrl, setPrevLocationUrl] = React.useState('');

    const [gotScriptProgress, setGotScriptProgress] = React.useState(false);
    const [avatar, setAvatar] = React.useState('');

    const globalStateProvided = React.useMemo(() => ({
        userRolesInfo,
        setUserRolesInfo,
        sessionHasBeenFetched,
        setSessionHasBeenFetched,
        avatar,
        setAvatar,
        platform,
        visitedUrls,
        setVisitedUrls,
        formDataChanged,
        setFormDataChanged,
        locationUrl,
        setLocationUrl,
        prevLocationUrl,
        setPrevLocationUrl,
        gotScriptProgress,
        setGotScriptProgress,
        formDataChangedSubmitHandler,
        setFormDataChangedSubmitHandler,
    }), [
        userRolesInfo,
        sessionHasBeenFetched,
        avatar,
        platform,
        visitedUrls,
        formDataChanged,
        locationUrl,
        prevLocationUrl,
        gotScriptProgress,
        formDataChangedSubmitHandler
    ]);

    React.useEffect(() => {
        const width = window.innerWidth;
        if (width && width >= 768) {
            setPlatform('desktop');
        } else {
            setPlatform('mobile');
        }
    }, [])

    return (
        <Provider store={store}>
            <ToastProvider components={{ Toast: CustomToast }} placement={'bottom-right'}>
                <GlobalContext.Provider value={globalStateProvided}>
                    <UploadProvider>
                        <FormDataSaveWrapper>
                            {children}
                        </FormDataSaveWrapper>
                    </UploadProvider>
                </GlobalContext.Provider>
            </ToastProvider>
        </Provider>
    );
};

export default App;
