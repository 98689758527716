import React, { useEffect, useRef, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CKEditor from 'ckeditor4-react';
import { useLocation, useNavigate } from '@reach/router';
import { useGlobalContext } from 'lib/hooks';
import Confirmation from 'components/confirmation';
import './ck-styles.css';
import cs from './ck-editor.module.scss';
import { debounce } from 'lodash';

export const TextEditor = forwardRef(({ value, saveEditorInstance, onMinimize, onChange, ...props }) => {
    const { platform } = useGlobalContext();

    const location = useLocation();
    const navigate = useNavigate();

    const ckBox = React.useRef();
    const stopEffectRef = useRef(false);

    const [toolbar, setToolbar] = useState([]);
    const [editor, setEditor] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [pasteData, setPasteData] = useState();
    const [initId, setInitId] = useState(null);

    const handleOnChange = debounce(() => {
        onChange?.(editor.getData());
    }, 300);

    const execPaste = (removeStyle = false) => {
        const resetStyles = element => {
            const replaceStyle = (name, value) => {
                // eslint-disable-next-line no-useless-escape
                const regexpName = name + ':s?(.+?)(;|$)';
                const r = new RegExp(regexpName);

                if (element.attributes.style && r.test(element.attributes.style)) {
                    if (['none', 'inherit', 'initial'].includes(element.attributes.style.match(r)[1])) {
                        return;
                    }

                    if (value) {
                        element.attributes.style = element.attributes.style.replace(r, `${name}:${value};`);
                    } else {
                        element.attributes.style = element.attributes.style.replace(r, '');
                    }
                }
            };

            delete element.attributes.style;

            if (element.name === 'a') {
                element.name = 'span';
                delete element.attributes.href;
            }

            if (element.name === 'table') {
                if (!element.attributes.width && !element.attributes.style?.includes('width')) {
                    element.attributes.style = 'width: 100%';
                }
            }

            ['i', 'b', 'u', 'strong', 'font'].forEach(v => {
                if (element.name === v) {
                    element.name = 'span';
                }
            });

            delete element.attributes.color;
            delete element.attributes.bgcolor;
            delete element.attributes.lang;

            ['border', 'border-bottom', 'border-top', 'border-left', 'border-right'].forEach(v => replaceStyle(v, '1px solid black'));

            ['border-color', 'border-bottom-color', 'border-top-color', 'border-left-color', 'border-right-color'].forEach(v =>
                replaceStyle(v, 'black')
            );

            ['border-width', 'border-bottom-width', 'border-top-width', 'border-left-width', 'border-right-width'].forEach(v =>
                replaceStyle(v, '1px')
            );

            ['border-style', 'border-bottom-style', 'border-top-style', 'border-left-style', 'border-right-style'].forEach(v =>
                replaceStyle(v, 'solid')
            );

            [
                'margin',
                'margin-bottom',
                'margin-top',
                'margin-left',
                'margin-right',
                'padding',
                'padding-bottom',
                'padding-top',
                'padding-right',
                'padding-left',
            ].forEach(v => replaceStyle(v, '4px'));

            [
                'background',
                'background-color',
                'color',
                'width',
                'height',
                'font',
                'font-family',
                'font-size',
                'font-style',
                'font-weight',
                'text-decoration',
            ].forEach(v => replaceStyle(v));
        };
        const formatStyles = element => {
            delete element.attributes.lang;

            if (element.name === 'table') {
                if (!element.attributes.width && !element.attributes.style?.includes('width')) {
                    element.attributes.style = 'width: 100%';
                }
            }

            if (element.name === 'h1') {
                element.attributes.class = 'editorTitle1';
            }

            if (element.name === 'h2') {
                element.attributes.class = 'editorTitle2';
            }

            if (element.name === 'h3') {
                element.attributes.class = 'editorTitle3';
            }

            if (element.name === 'b') {
                const child = element.children[0];
                element.name = 'strong';

                if (child?.name) {
                    element.name = 'span';
                    element.attributes.style = child.attributes.style;

                    child.name = 'strong';
                    delete child.attributes.style;

                    if (element.attributes.style && element.attributes.style.includes('pt;')) {
                        element.attributes.style = element.attributes.style.replace('pt;', 'px;');
                    }
                }
            }

            if (element.attributes.style && element.attributes.style.includes('text-indent:-')) {
                element.attributes.style = element.attributes.style.replace('text-indent:-', 'text-indent:');
            }
        };

        const fragment = window.CKEDITOR.htmlParser.fragment.fromHtml(pasteData);
        const writer = new window.CKEDITOR.htmlWriter();

        const filter = new window.CKEDITOR.htmlParser.filter({
            elements: {
                '^': removeStyle ? resetStyles : formatStyles,
            },
        });

        filter.applyTo(fragment);
        fragment.writeHtml(writer);

        editor.insertHtml(
            writer.getHtml().replaceAll('<![if>', '').replaceAll('</![if>', '').replaceAll('<![endif]>', '').replaceAll('</![endif]>', '')
        );
    };

    useEffect(() => {
        if (props.initId) {
            setInitId(props.initId);
        }
    }, [props.initId]);

    useEffect(() => {
        if (!editor) {
            return;
        }

        if (stopEffectRef.current) {
            stopEffectRef.current = false;
            return;
        }

        const params = new URLSearchParams(location.search);

        if (
            (params.get('maximize') === 'true' && editor.getCommand('maximize').state === 2) ||
            (params.get('maximize') !== 'true' && editor.getCommand('maximize').state === 1)
        ) {
            editor.execCommand('maximize');
        }
    }, [editor, location.search]);

    useEffect(() => {
        if (platform === 'desktop') {
            setToolbar([
                { name: 'block-1', items: ['Undo', 'Redo', 'Format', 'Bold', 'Italic', 'Underline', 'Strike', 'image2'] },
                { name: 'block-2', items: ['Font', 'FontSize', 'TextColor', 'BGColor', 'pre', 'lineheight'] },
                { name: 'block-3', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'Outdent', 'Indent'] },
                { name: 'block-4', items: ['NumberedList', 'BulletedList'] },
                {
                    name: 'block-5',
                    items: [
                        'Link',
                        'Unlink',
                        'Anchor',
                        'attachment',
                        'Image',
                        'Html5video',
                        'Table',
                        'HorizontalRule',
                        'Smiley',
                        'SpecialChar',
                        'PageBreak',
                        'Subscript',
                        'Superscript',
                    ],
                },
                {
                    name: 'ckwebspeech',
                    items: [
                        'redblock',
                        'aquablock',
                        'Chart',
                        'Blockquote',
                        'CodeSnippet',
                        'RemoveFormat',
                        'Find',
                        'Replace',
                        'SelectAll',
                        'CopyFormatting',
                        'Maximize',
                        'Source',
                        'webSpeechEnabled',
                        'webSpeechSettings',
                        'btgrid',
                    ],
                },
            ]);
        } else {
            setToolbar([
                {
                    name: 'block',
                    items: [
                        'Undo',
                        'Redo',
                        'Format',
                        'Bold',
                        'Italic',
                        'Underline',
                        'Strike',
                        'image2',
                        'Font',
                        'FontSize',
                        'lineheight',
                        'TextColor',
                        'BGColor',
                        'pre',
                        'JustifyLeft',
                        'JustifyCenter',
                        'JustifyRight',
                        'JustifyBlock',
                        'Outdent',
                        'Indent',
                        'NumberedList',
                        'BulletedList',
                        'Link',
                        'Unlink',
                        'Anchor',
                        'attachment',
                        'Image',
                        'Html5video',
                        'Table',
                        'HorizontalRule',
                        'Smiley',
                        'SpecialChar',
                        'PageBreak',
                        'Subscript',
                        'Superscript',
                        'redblock',
                        'aquablock',
                        'Chart',
                        'Blockquote',
                        'RemoveFormat',
                        'Find',
                        'Replace',
                        'SelectAll',
                        'CopyFormatting',
                        'Maximize',
                        'Source',
                        'webSpeechEnabled',
                        'webSpeechSettings',
                        'btgrid',
                    ],
                },
            ]);
        }
    }, [platform]);

    return (
        <>
            <Confirmation
                isOpen={modalOpen}
                color={'base'}
                closeBtnText={'Нет, удалить'}
                submitBtnText={'Да, сохранить'}
                title={'Настройки форматирования'}
                text={'Сохранить форматирование текста при вставке?'}
                onRequestClose={() => {
                    setModalOpen(false);
                    execPaste(true);
                }}
                onRequestSubmit={() => {
                    setModalOpen(false);
                    execPaste(false);
                }}
                contentType={'TEXT'}
            />
            <div
                data-ck-editor="5"
                style={{ color: '#161616' }}
                ref={ckBox}
                className={classNames(cs.editor, { [cs.error]: props.error, 'field-invalid': props.error })}
            >
                {initId && (
                    <CKEditor
                        data={value}
                        config={{
                            autoParagraph: false,
                            allowedContent: true,
                            defaultLanguage: 'ru',
                            language: 'ru',
                            toolbarCanCollapse: true,
                            height: 600,
                            fileBrowserInitId: initId,
                            // imageUploadUrl : '/services/upload/api/v1/editor/image?',
                            filebrowserUploadUrl: '/services/upload/api/v1/editor/image?',
                            format_h1: { element: 'h1', attributes: { class: 'editorTitle1' } },
                            format_h2: { element: 'h2', attributes: { class: 'editorTitle2' } },
                            format_h3: { element: 'h3', attributes: { class: 'editorTitle3' } },
                            format_p: { element: 'p', attributes: { class: '' } },
                            disableNativeSpellChecker: false,
                            toolbar: toolbar,
                            ckwebspeech: {
                                culture: 'ru-RU',
                                commandvoice: 'okay', // trigger command listener
                                commands: [
                                    // action list
                                    // {'[action]': '['text to trigger action']'}
                                    { newline: 'new line' },
                                    { newparagraph: 'new paragraph' },
                                    { undo: 'undo' },
                                    { redo: 'redo' },
                                ],
                            },
                            removePlugins: 'elementspath, scayt, wordcount',
                        }}
                        onInstanceReady={event => {
                            setEditor(event.editor);
                            saveEditorInstance(event.editor);
                        }}
                        onPaste={event => {
                            let data = event.data.dataValue;

                            if (!data) {
                                data = event.data.dataTransfer.getData('text/html');
                            }

                            setPasteData(data);
                            setModalOpen(true);

                            event.stop();
                        }}
                        onMaximize={event => {
                            stopEffectRef.current = true;

                            if (event.data === 1) {
                                navigate(location.origin + location.pathname + '?maximize=true', 'no-check-form-data-changed');
                            } else {
                                if (onMinimize) {
                                    onMinimize();
                                }
                                navigate(location.origin + location.pathname, 'no-check-form-data-changed');
                            }
                        }}
                        onChange={handleOnChange}
                    />
                )}
            </div>
            {props.error && <div className={cs.errorText}>{props.error}</div>}
        </>
    );
});

TextEditor.defaultProps = {};
TextEditor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    initId: PropTypes.string,
    onMinimize: PropTypes.func,
};

export default TextEditor;
