import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';
import api from 'api';
import cs from './like.module.scss';

const Like = ({ id, isLiked, isDisliked, sessionLogin, getDislikesFunction, getLikesFunction, addDislikeFunction, addLikeFunction, lastLikeList: _lastLikeList, likeCount: _likeCount, isArchive = false }) => {
    const [loading, setLoading] = React.useState(false);
    const [ready, setReady] = React.useState(false);

    const [liked, setLiked] = React.useState(isLiked);
    const [disliked, setDisliked] = React.useState(isDisliked);

    const [likeCount, setLikeCount] = React.useState(_likeCount);
    const [dislikeCount, setDislikeCount] = React.useState(0);

    const [lastLikeList, setLastLikeList] = React.useState(_lastLikeList || []);

    const computeLastLikeList = (like) => {
        if (like && lastLikeList.length < 3) {
            setLastLikeList([...lastLikeList, { user: like.user.user }]);
        } else if (!like) {
            setLastLikeList([...(lastLikeList.filter(l => l.user.login !== sessionLogin))]);
        }
    };

    const doClick = (e) => {
        switch (e.currentTarget.id) {
            case 'likeComponentLikeButton':
                if (liked) {
                    api.like.removeLike(liked).then(() => {
                        setLiked(false);
                    }).finally(() => {
                        setLoading(false);
                        setLikeCount(likeCount - 1);
                        computeLastLikeList();
                    });
                } else if (disliked) {
                    Promise.all([api.dislike.removeDislike(disliked), addLikeFunction(id)]).then(res => {
                        setLiked(res[1].uuid);
                        setDisliked(false);
                        computeLastLikeList(res[1]);
                    }).finally(() => {
                        setLoading(false);
                        setLikeCount(likeCount + 1);
                        setDislikeCount(dislikeCount - 1);
                    });
                } else {
                    addLikeFunction(id).then(res => {
                        setLiked(res.uuid);
                        computeLastLikeList(res);
                    }).finally(() => {
                        setLoading(false);
                        setLikeCount(likeCount + 1);
                    });
                }
                break;
            case 'likeComponentDislikeButton':
                if (liked) {
                    Promise.all([api.like.removeLike(liked), addDislikeFunction(id)]).then(res => {
                        setDisliked(res[1].uuid);
                        setLiked(false);
                    }).finally(() => {
                        setLoading(false);
                        setLikeCount(likeCount - 1);
                        setDislikeCount(dislikeCount + 1);
                        computeLastLikeList();
                    });
                } else if (disliked) {
                    api.dislike.removeDislike(disliked).then(() => {
                        setDisliked(false);
                    }).finally(() => {
                        setDislikeCount(dislikeCount - 1);
                        setLoading(false);
                    });
                } else {
                    addDislikeFunction(id).then(res => {
                        setDisliked(res.uuid);
                    }).finally(() => {
                        setDislikeCount(dislikeCount + 1);
                        setLoading(false);
                    });
                }
                break;
            default:
                throw new Error(`Unknown case option e.currentTarget.id = ${e.currentTarget.id}. Expecting {"likeComponentLikeButton", "likeComponentDislikeButton"}`);
        }
    };

    useEffect(() => {
        if (!id || !sessionLogin) {
            return;
        }

        setLoading(true);

        Promise.all([getLikesFunction(id), getDislikesFunction(id)]).then(values => {
            const likesRes = values[0];
            const myLike = likesRes.content.find(item => item.username === sessionLogin);

            if (myLike) {
                setLiked(myLike.uuid);
            } else {
                setLiked(false);
            }

            setLikeCount(values[0].numberOfElements);

            const dislikesRes = values[1];
            const myDis = dislikesRes.content.find(item => item.username === sessionLogin);

            if (myDis) {
                setDisliked(myDis.uuid);
            } else {
                setDisliked(false);
            }

            setDislikeCount(values[1].numberOfElements);
        }).finally(() => {
            setLoading(false);
            setReady(true);
        });
    }, [id, sessionLogin, getDislikesFunction, getLikesFunction]);

    return (
        <div className={cs.like}>
            <div id={'likeComponentLikeButton'} onClick={!isArchive ? doClick : null}
                 className={classNames(cs.button, cs.green, { [cs.progress]: !ready || loading, [cs.liked]: liked })}>
                {(!ready || loading)
                    ? <Loading small withOverlay={false}/>
                    : <Icon type={Icons.LIKE} width={16} height={16}/>}
                {liked
                    ? <span>Убрать "нравится"</span>
                    : <span>Нравится</span>}
                {likeCount > 0 && <span className={cs.likes}> - {likeCount}</span>}
            </div>
            <div id={'likeComponentDislikeButton'} className={classNames(cs.button, cs.red, {
                [cs.progress]: !ready || loading,
                [cs.disliked]: disliked
            })} onClick={!isArchive ? doClick : null}>
                {(!ready || loading)
                    ? <Loading small withOverlay={false}/>
                    : <Icon type={Icons.DISLIKE} width={16} height={16}/>}
                {disliked
                    ? <span>Убрать "не нравится"</span>
                    : <span>Не нравится</span>}
                {dislikeCount > 0 && <span className={cs.likes}> - {dislikeCount}</span>}
            </div>
        </div>
    );
};

Like.propTypes = {
    id: PropTypes.string,
    isLiked: PropTypes.bool,
    isDisliked: PropTypes.bool,
    lastLikeList: PropTypes.arrayOf(PropTypes.object),
    likeCount: PropTypes.number,
    sessionLogin: PropTypes.string,
    getDislikesFunction: PropTypes.func.isRequired,
    getLikesFunction: PropTypes.func.isRequired,
    addDislikeFunction: PropTypes.func.isRequired,
    addLikeFunction: PropTypes.func.isRequired
};

export default Like;
