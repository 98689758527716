import { createSlice } from '@reduxjs/toolkit';

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        type: '',
        filtersData: {
            'create-time': {},
            'document-type-select': {},
            'articles-group': {},
            'news-group': {},
            authors: [],
            news: [],
            sortBy: null,
            newsType: 'PROJECT_NEWS',
          },
        dynamicColumns: [],
    },
    reducers: {
        setFiltersData: (state, action) => {
            state.filtersData = { ...state.filtersData, ...action.payload };
        },
        setReportType: (state, action) => {
            state.type = action.payload;
        },
        setAuthors: (state, action) => {
            state.filtersData.authors = action.payload;
        },
        setNews: (state, action) => {
            state.filtersData.news = action.payload;
        },
        setDynamicColumns: (state, action) => {
            state.dynamicColumns = action.payload;
        },
        cleanDynamicColumns: (state) => {
            state.dynamicColumns = [];
        },
        cleanFiltersData: (state) => {
            state.filtersData = {
                authors: [],
                sortBy: [],
            };
        },
    },
});

export const {
    setReportType,
    setAuthors,
    setNews,
    setDynamicColumns,
    cleanDynamicColumns,
    cleanFiltersData,
    setFiltersData,
} = reportsSlice.actions;

export const selectReportsFiltersData = (state) => state.reports.filtersData;
export const selectReportsType = (state) => state.reports.type;
export const selectReportsDynamicColumns = (state) => state.reports.dynamicColumns;

export default reportsSlice.reducer;
