import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useMessage } from 'lib/hooks';
import Filter from 'uikit/filter/filter';
import Table, { EditRowToolbar } from 'uikit/table';
import { Icons } from 'uikit/icon';
import RolesPageEditRole from 'app/global-settings/roles-page/roles-page-edit-role';
import api from 'api';
import cx from './roles-page.module.scss';

const rolesNames = {
    'manager': 'Менеджер',
    'admin': 'Администратор',
    'super-user': 'Суперпользователь',
    'operator': 'Оператор'
};
const tmpRole = {
    name: 'Создание роли',
    permissions: [
        {
            length: 4,
            permName: 'Группы',
            values: [false, false, false, false]
        },
        {
            length: 4,
            permName: 'Файлы',
            values: [false, false, false, false]
        },
        {
            length: 4,
            permName: 'Статьи',
            values: [false, false, false, false]
        },
        {
            length: 4,
            permName: 'Участники',
            values: [false, false, false, false]
        },
        {
            length: 4,
            permName: 'Новости',
            values: [false, false, false, false]
        },
        {
            length: 1,
            permName: 'Журнал действий',
            values: [false]
        },
        {
            length: 1,
            permName: 'Статистика',
            values: [false]
        }
    ],
    roleName: '',
    roleType: 'Системная'
};

const RolesPage = () => {
    const { addError } = useMessage();

    const [roles, setRoles] = useState([]);
    const [rolesData, setRolesData] = useState([]);

    const [rolesTableSort, setRolesTableSort] = useState({ by: '', desc: false });
    const [selectedRole, setSelectedRole] = useState(null);

    const [isEditRoleModal, setEditRoleModal] = useState(false);
    const [isCreateRoleModal, setCreateRoleModal] = useState(false);

    const removeRole = useCallback((roleId) => {
        setRoles(_roles => _roles.filter(role => role.id !== roleId));
    }, []);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Наименование роли',
                accessor: 'roleName',
                align: 'center'
            },
            {
                Header: 'Тип роли',
                accessor: 'roleType',
                align: 'center'
            },
            {
                id: 4,
                Header: '',
                align: 'center',
                Cell: (data) => {
                    let toolbar = [];
                    if (data.row.original.isEdit) {
                        toolbar.push({
                            icon: Icons.EDIT_PEN,
                            tooltip: 'Редактировать',
                            onClick: (data) => {
                                setSelectedRole(data);
                                setEditRoleModal(true);
                            }
                        });
                        toolbar.push({
                            icon: Icons.TRASH,
                            tooltip: 'Удалить',
                            onClick: (data) => {
                                removeRole(data.id);
                            }
                        });
                    } else {
                        toolbar.push({
                            icon: Icons.EYE,
                            tooltip: 'Открыть роль',
                            onClick: (data) => {
                                setSelectedRole(data);
                                setEditRoleModal(true);
                            }
                        });
                    }

                    return EditRowToolbar(toolbar, null, true, false, true)(data);
                }
            }
        ];
    }, [removeRole]);

    const onRolesTableSort = async (column) => {
        const sort = Object.assign({}, rolesTableSort);

        if (sort.by === column.id) {
            sort.desc = !sort.desc;
        } else {
            sort.by = column.id;
            sort.desc = false;
        }

        setRolesTableSort(sort);
    };
    const fetchRoles = useCallback(async () => {
        const response = await api.role.getRoles();
        const roles = response.roles.map(role => {
            const permissions = role.permissions.map(perm => {
                const values = [perm.read, perm.create, perm.update, perm.delete];
                const length = values.filter(i => i !== null).length;

                return { permName: perm.scope, values, length };
            }).sort((permA, permB) => permB.length - permA.length);

            return {
                ...role,
                roleName: rolesNames[role.name],
                roleType: 'Системная',
                permissions
            };
        });

        try {
            setRoles(roles);
            setRolesData(roles);
        } catch (e) {
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            console.log(e);
        }
    }, [addError]);

    useEffect(() => { fetchRoles() }, [fetchRoles]);
    useEffect(() => {
        if (rolesTableSort.by) {
            const rolesDataNext = Object.assign([], roles);

            rolesDataNext.sort((a, b) => {
                if (a[rolesTableSort.by] > b[rolesTableSort.by]) {
                    return rolesTableSort.desc ? 1 : -1;
                } else if (a[rolesTableSort.by] < b[rolesTableSort.by]) {
                    return rolesTableSort.desc ? -1 : 1;
                }

                return 0;
            });

            setRolesData(rolesDataNext);
        }
    }, [rolesTableSort, roles]);

    return (
        !isCreateRoleModal ?
            (!isEditRoleModal
                ? (
                    <div className={cx.contentWrapper}>
                        <div className={cx.title}>Роли</div>
                        <div className={cx.table}>
                            <div className={cx.filter}>
                                <Filter id={'rolesPageFilter'} add={'Создать роль'} canAdd={true} onAdd={() => setCreateRoleModal(true)}/>
                            </div>
                            <Table columns={columns} data={rolesData} sort={rolesTableSort} onSort={onRolesTableSort}/>
                        </div>
                    </div>
                )
                : (<RolesPageEditRole role={selectedRole} isReadOnly={selectedRole && !selectedRole.isEdit}
                                      onCloseHandler={setEditRoleModal}/>))
            : (<RolesPageEditRole role={tmpRole} isReadOnly={false} onCloseHandler={setCreateRoleModal}/>)
    );
};

export default RolesPage;
