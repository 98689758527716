import { createContext, useContext } from 'react';

export const EditorContext = createContext(undefined);

export function useEditorContext() {
    const editor = useContext(EditorContext);

    if (editor === undefined) {
        throw new Error('Tiptap editor not found in component context.');
    }

    return editor;
}

export const EditorNotificationContext = createContext(undefined);

export function useEditorNotificationContext() {
    const notification = useContext(EditorNotificationContext);

    if (notification === undefined) {
        throw new Error('Editor notification not found in component context');
    }

    return notification;
}
