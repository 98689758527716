import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Modal from 'react-modal';
import Select, { components } from 'react-select';
import { selectUsers } from 'slice/authSlice';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Checkbox from 'uikit/checkbox';
import Input from 'uikit/input';
import { DatePicker, TimePicker } from 'uikit/datetime';
import Button from 'uikit/button';
import api from 'api';
import cx from './training-access-modal.module.scss';

const ValueContainer = (props) => {
    const { label } = props.selectProps;

    return (
        <components.ValueContainer {...props}>
            <label className={cx.trainingAccessModalBodyFiltersLabel}>{label}</label>
            <span className={cx.trainingAccessModalBodyFiltersSpan}>{props.selectProps.value.length}</span>
        </components.ValueContainer>
    );
};

export default function TrainingAccessModal({ id, accesses, onClose }) {
    const selector = useSelector((state) => state);
    const [isNext, setIsNext] = useState(false);

    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');

    const [showUsers, setShowUsers] = useState([]);
    const [checkedUsers, setCheckedUsers] = useState([]);

    const [projects, setProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);

    const [offices, setOffices] = useState([]);
    const [selectedOffices, setSelectedOffices] = useState([]);

    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [positions, setPositions] = useState([]);
    const [selectedPositions, setSelectedPositions] = useState([]);

    const [openDate, setOpenDate] = useState(new Date());
    const [openTime, setOpenTime] = useState('10:00');

    const [closeDate, setCloseDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
    const [closeTime, setCloseTime] = useState('10:00');

    const [isTryCount, setIsTryCount] = useState(false);
    const [tryCount, setTryCount] = useState('0');

    const [isForcibly, setIsForcibly] = useState(false);

    const [isPostpone, setIsPostpone] = useState(false);
    const [postponeCount, setPostponeCount] = useState('0');

    const [isNotification, setIsNotification] = useState(false);

    const onCheckUser = (user) => {
        const checkedUsersTemp = Object.assign([], checkedUsers);

        if (checkedUsersTemp.indexOf(user) === -1) {
            checkedUsersTemp.push(user);
        } else {
            checkedUsersTemp.splice(checkedUsersTemp.indexOf(user), 1);
        }

        setCheckedUsers(checkedUsersTemp);
    };
    const onAddUsers = async () => {
        const users = checkedUsers.map(p => p['id']);

        const open = new Date(openDate);
        open.setHours(openTime.split(':')[0], openTime.split(':')[1], 0);

        const close = new Date(closeDate);
        close.setHours(closeTime.split(':')[0], closeTime.split(':')[1], 0);

        await api.training.postTestUsers(id, users, open.toISOString(), close.toISOString(),
            +tryCount <= 0 ? 1 : +tryCount, +postponeCount < 0 ? 0 : +postponeCount, isForcibly,
            isNotification);

        onClose();
    };

    useEffect(() => {
        const fetchData = async () => {
            const projectsData = await Promise.all(selectedProjects.map(async (project) => {
                return await api.role.getResourceUsers(project.value, 'PROJECT');
            }));
            const groupsData = await Promise.all(selectedGroups.map(async (group) => {
                return await api.groups.getGroupById(group.value);
            }));

            setShowUsers(users.filter((user) => {
                if ((user.firstName === null || user.firstName.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.lastName === null || user.lastName.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.middleName === null || user.middleName.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.login === null || user.login.toLowerCase().indexOf(search.toLowerCase()) === -1)
                    && (user.firstName === null || user.lastName === null
                        || (user.firstName + ' ' + user.lastName).toLowerCase().indexOf(search.toLowerCase()) === -1)) {
                    return false;
                }

                if (selectedProjects.length !== 0) {
                    if (projectsData.findIndex((item) => item.findIndex((item2) => item2.object.login === user.login) !== -1) === -1) {
                        return false;
                    }
                }

                if (selectedOffices.length !== 0) {
                    if (user.office === null) {
                        return false;
                    }

                    if (selectedOffices.findIndex((office) => office.label === user.office) === -1) {
                        return false;
                    }
                }

                if (selectedGroups.length !== 0) {
                    if (groupsData.findIndex((group) => group.members.findIndex((member) => member.login === user.login) !== -1) === -1) {
                        return false;
                    }
                }

                if (selectedPositions.length !== 0) {
                    if (user.position === '') {
                        return false;
                    }

                    if (selectedPositions.findIndex((position) => position.label === user.position) === -1) {
                        return false;
                    }
                }

                return true;
            }));
        };
        fetchData();
    }, [users, search, selectedProjects, selectedOffices, selectedGroups, selectedPositions]);

    useEffect(() => {
        const fetchData = async () => {
            const users = await selectUsers(selector);
            const usersData = users['content'].filter(user => {
                return accesses.findIndex(access => access['user']['login'] === user['login']) === -1;
            });

            setUsers(usersData);
            setShowUsers(usersData);
        };
        fetchData();
    }, [selector, accesses]);
    useEffect(() => {
        const fetchData = async () => {
            const projectsResponse = await api.project.getProjects(0, 2000);
            setProjects(projectsResponse.content.map((project) => {
                return { label: project.title, value: project.id };
            }));

            const officesResponse = await api.office.getOffices(0, 2000);
            setOffices(officesResponse.content.map((office) => {
                return { label: office.name, value: office.id };
            }));

            const groupsResponse = await api.groups.getGroups(0, 2000);
            setGroups(groupsResponse.data.content.map((group) => {
                return { label: group.name, value: group.id };
            }));

            const positionsResponse = await api.userPosition.getUserPosition(0, 2000);
            setPositions(positionsResponse.content.map((position) => {
                return { label: position.title, value: position.id };
            }));
        };
        fetchData();
    }, []);

    return (
        <Modal isOpen={true} className={cx.trainingAccessModal} overlayClassName={cx.trainingAccessModalOverlay}
               onRequestClose={() => onClose()}>
            <div className={classNames(cx.trainingAccessModalHeader, !isNext ? cx.trainingAccessModalHeaderDelimiter : null)}>
                <div className={cx.trainingAccessModalHeaderTop}>
                    <h2>Доступ к тесту</h2>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>} onClick={onClose}/>
                </div>
                {!isNext &&
                <div className={cx.trainingAccessModalHeaderBottom}>
                    <Icon type={Icons.SEARCH} width={13} height={13}/>
                    <input type="text" placeholder="Поиск участника..." value={search}
                           onChange={e => setSearch(e.target.value)}/>
                </div>}
            </div>
            <div className={cx.trainingAccessModalBody}>
                {!isNext &&
                <div className={cx.trainingAccessModalBodyFilters}>
                    <Select isMulti={true} label="Проекты" options={projects} value={selectedProjects}
                            components={{ ValueContainer }} onChange={(item) => setSelectedProjects(item)}/>
                    <Select isMulti={true} label="Офисы" options={offices} value={selectedOffices}
                            components={{ ValueContainer }} onChange={(item) => setSelectedOffices(item)}/>
                    <Select isMulti={true} label="Группы" options={groups} value={selectedGroups}
                            components={{ ValueContainer }} onChange={(item) => setSelectedGroups(item)}/>
                    <Select isMulti={true} label="Должности" options={positions} value={selectedPositions}
                            components={{ ValueContainer }} onChange={(item) => setSelectedPositions(item)}/>
                </div>}
                <div className={cx.trainingAccessModalBodySelect}>
                    <span>Выбрано: {checkedUsers.length} участника (-ов)</span>
                    {!isNext && checkedUsers.length === showUsers.length &&
                    <p onClick={() => setCheckedUsers([])}>Убрать всех</p>}
                    {!isNext && checkedUsers.length !== showUsers.length &&
                    <p onClick={() => setCheckedUsers(showUsers)}>Выбрать всех</p>}
                </div>
                {!isNext &&
                <div className={cx.trainingAccessModalBodyUsers}>
                    {showUsers.map((user, index) => (
                        <div key={index} className={cx.trainingAccessModalBodyUser}>
                            <Checkbox checked={checkedUsers.indexOf(user) !== -1} onClick={() => onCheckUser(user)}/>
                            <img src={user['avatar'] ? api.upload.getImage(user['avatar'], false, 128) : '/img/avatar.png'}
                                 alt="" />
                            <span>{user['firstName'] + ' ' + user['lastName']}</span>
                        </div>
                    ))}
                </div>}
                {isNext &&
                <>
                    <div className={cx.trainingAccessModalBodyDates}>
                        <DatePicker label="Дата открытия теста" value={openDate} onChange={(value) => setOpenDate(value)}/>
                        <TimePicker label="Время открытия" value={openTime} onChange={(value) => setOpenTime(value)}/>
                    </div>
                    <div className={cx.trainingAccessModalBodyDates}>
                        <DatePicker label="Дата закрытия теста" value={closeDate} onChange={(value) => setCloseDate(value)}/>
                        <TimePicker label="Время закрытия" value={closeTime} onChange={(value) => setCloseTime(value)}/>
                    </div>
                    <div className={cx.trainingAccessModalBodyDelimiter}/>
                    <div className={cx.trainingAccessModalBodyCheck}>
                        <Checkbox label="Ограничить количество попыток" checked={isTryCount}
                                  onClick={() => setIsTryCount(!isTryCount)}/>
                        {isTryCount &&
                        <div className={cx.trainingAccessModalBodyCheckContainer}>
                            <Input type="number" value={tryCount} onChange={(number) => setTryCount(number)}/>
                            <span>раз</span>
                        </div>}
                    </div>
                    <div className={cx.trainingAccessModalBodyCheck}>
                        <Checkbox label="Принудительное прохождение теста" checked={isForcibly}
                                  onClick={() => setIsForcibly(!isForcibly)}/>
                        <p>При входе в систему участник сразу будет направлен на страницу теста</p>
                    </div>
                    <div className={cx.trainingAccessModalBodyCheck}>
                        <Checkbox label="Разрешить отложить тестирование на 15 минут" checked={isPostpone}
                                  onClick={() => setIsPostpone(!isPostpone)}/>
                        {isPostpone &&
                        <div className={cx.trainingAccessModalBodyCheckContainer}>
                            <Input type="number" value={postponeCount} onChange={(number) => setPostponeCount(number)}/>
                            <span>раз (-a)</span>
                        </div>}
                    </div>
                    <div className={cx.trainingAccessModalBodyDelimiter}/>
                    <div className={cx.trainingAccessModalBodyCheck}>
                        <Checkbox label="Прислать уведомление участникам теста" checked={isNotification}
                                  onClick={() => setIsNotification(!isNotification)}/>
                        <p>Участники получат уведомления о назначенном им тесте, а также получат напоминание о
                            приближающейся дате открытия теста</p>
                    </div>
                </>}
            </div>
            <div className={cx.trainingAccessModalFooter}>
                {!isNext && <Button label="Отмена" onClick={onClose}/>}
                {!isNext &&
                <Button label="Далее" color="green" disabled={checkedUsers.length === 0}
                        onClick={() => setIsNext(true)}/>}
                {isNext && <Button label="Назад" onClick={() => setIsNext(false)}/>}
                {isNext && <Button label="Подтвердить" color="green" onClick={onAddUsers}/>}
            </div>
        </Modal>
    );
};
