import React from 'react';
import { Router } from '@reach/router';
import classNames from 'classnames';
import { useGlobalContext } from 'lib/hooks';

import StatisticDashboardsActivities from './statistic-dashboards-activities';
import StatisticDashboardsDocuments from './statistic-dashboards-documents';
import StatisticDashboardsGeneral from './statistic-dashboards-general';
import StatisticDashboardsSearch from './statistic-dashboards-search';
import StatisticDashboardsUsers from './statistic-dashboards-users';

import cx from './statistic-total-stats-page.module.scss';

export default function StatisticDashboardsWrapper() {
    const { platform } = useGlobalContext();

    return (
        <div className={classNames(cx.body, { [cx.mobile]: platform === 'mobile' })}>
            <Router>
                <StatisticDashboardsGeneral path="general" />
                <StatisticDashboardsDocuments path="documents" />
                <StatisticDashboardsUsers path="users" />
                <StatisticDashboardsActivities path="activities" />
                <StatisticDashboardsSearch path="search" />
            </Router>
        </div>
    );
}
