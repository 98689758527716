import React, { useContext, useEffect } from 'react';
import { Router } from '@reach/router';
import { ContentWrapper } from 'containers/content-wrapper';
import { TrainingTabOpen, TrainingTabAll, TrainingTabCatalog, TrainingTheme, TrainingQuestion, TrainingTest } from 'components/training';
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';

export default function TrainingPage() {
    const { setMenuContentType } = useContext(MobileMenuContext);
    useEffect(() => {
        if (!setMenuContentType) {
            return;
        }

        setMenuContentType(MENU_CONTENT_TYPE.MAIN_MENU);
    }, [setMenuContentType]);

    return (
        <ContentWrapper>
            <Router>
                <TrainingTabOpen default/>
                <TrainingTabAll path="/all"/>
                <TrainingTabCatalog path="/catalog">
                    <TrainingTheme path="/theme"/>
                    <TrainingTheme path="/theme/:id"/>
                    <TrainingTheme path="/theme/:id/edit" isEdit={true}/>
                    <TrainingQuestion path="/question"/>
                    <TrainingQuestion path="/question/:id"/>
                    <TrainingQuestion path="/question/:id/edit" isEdit={true}/>
                </TrainingTabCatalog>
                <TrainingTest path="/test"/>
                <TrainingTest path="/test/:id"/>
                <TrainingTest path="/test/:id/users"/>
                <TrainingTest path="/test/:id/reports"/>
                <TrainingTest path="/test/:id/edit" isEdit={true}/>
            </Router>
        </ContentWrapper>
    );
};
