import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { textValidator } from 'lib/util/validators.util';
import { selectRoles, updateRole } from 'slice/customRolesSlice';
import Input from 'uikit/input';
import Checkbox from 'uikit/checkbox';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import BackButton from 'components/back-button';
import PrivatePage from 'containers/private-page-wrapper';
import CONFIG from './custom-roles-config';
import api from 'api';
import cs from './custom-role-form.module.scss';
import Card from 'components/card';
import { useGlobalContext, useMessage } from 'lib/hooks';

const CustomRoleForm = ({ hasAnyAuthorities, navigate, id }) => {
    const { platform } = useGlobalContext();

    const dispatch = useDispatch();
    const roles = useSelector(selectRoles);

    const [model, setModel] = React.useState([]);
    const [roleTitle, setRoleTitle] = React.useState('');

    const [defaultRole, setDefaultRole] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const title = id ? 'Редактирование роли' : 'Создание роли';
    const [titleErr, setTitleErr] = React.useState(null);

    const { addSuccess } = useMessage();

    React.useEffect(() => {
        if (roles && id) {
            const role = roles.find(r => r.id.toString() === id);

            if (role) {
                setModel(role.authorities.map(a => a.name));
                setRoleTitle(role.title);
                setDefaultRole(role.defaultRole);
            }
        }
    }, [roles, id]);

    const _goBack = () => {
        navigate('/global-settings/custom-roles');
    };

    const onToggle = e => {
        if (e.target.checked) {
            setModel([...model, e.target.name]);
        } else {
            setModel(model.filter(m => m !== e.target.name));
        }
    };

    const onSubmit = async () => {
        if (roleTitle.trim().length === 0) {
            setTitleErr(textValidator(roleTitle));
            return;
        }

        if (titleErr) {
            return;
        }

        const data = {
            name: roleTitle,
            title: roleTitle,
            permissions: model
        };

        if (id) {
            data.id = Number.parseInt(id);
        }

        setLoading(true);
        const submitF = id ? api.customRoles.editRole : api.customRoles.addRole;

        try {
            const updated = await submitF(data);

            dispatch(updateRole(updated));
            _goBack();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            if (id) {
                addSuccess('Роль изменена');
            } else {
                addSuccess('Роль создана');
            }
        }
    };

    const roleTitleChanged = str => {
        setRoleTitle(str);
        setTitleErr(textValidator(str));
    };

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={classNames(cs.content, {[cs.mobile]: platform === 'mobile'})}>
                <Loading active={loading}/>
                <div className={cs.header}>
                    <BackButton onClick={_goBack}/>
                    <h1>{title}</h1>
                </div>
                <Card className={cs.form}>
                    <Input label={'Название роли'} value={roleTitle} onChange={roleTitleChanged} disabled={defaultRole}
                           error={titleErr}/>
                    <div className={classNames(cs.mt15, cs.line)}/>
                    <div className={classNames(cs.mt15, cs.mb05, cs.blockName)}>Уровень доступа</div>
                    <div className={cs.hint}>
                        Настройте права доступа для каждого компонента системы, используя флажки
                    </div>
                    <div className={cs.mt15}>
                        {CONFIG.map((inf, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <div className={cs.section}>
                                        <div className={cs.sectionName}>
                                            {inf.name}
                                        </div>
                                        <div className={cs.rights}>
                                            {inf.roles.map((role, j) => {
                                                return (
                                                    <Checkbox key={j} label={role.name}
                                                              checked={model.includes(role.value)} onClick={onToggle}
                                                              name={role.value} disabled={defaultRole}/>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    {i !== CONFIG.length - 1 && <div className={classNames(cs.mt1, cs.mb1, cs.line)}/>}
                                </React.Fragment>
                            );
                        })}
                    </div>
                    <div className={cs.toolbar}>
                        <Button label={'Отмена'} onClick={_goBack} fullWidth={platform === 'mobile'}/>
                        <Button color={'green'} label={'Подтвердить'} onClick={onSubmit}
                                disabled={defaultRole || loading || titleErr} fullWidth={platform === 'mobile'}/>
                    </div>
                </Card>
            </div>
        </PrivatePage>
    );
};

export default CustomRoleForm;
