import { Extension } from '@tiptap/core';
import { extendCodemirror } from './extendCodemirror';

export const DEFAULT_CODEMIRROR_OPTIONS = {
    lineNumbers: true,
    lineWrapping: true,
    tabSize: 2,
    tabMode: 'indent',
    mode: 'text/html',
};

const CodeView = Extension.create({
    name: 'codeView',

    addOptions() {
        return {
            codemirror: null,
            codemirrorOptions: {
                ...DEFAULT_CODEMIRROR_OPTIONS,
            },
        };
    },

    addCommands() {
        return {
            cmSelectAll: () => () => {
                const cm = this.options.codemirror();

                cm.execCommand('selectAll');
            },
        };
    },

    onBeforeCreate() {
        if (!this.options.codemirror) {
            return;
        }

        extendCodemirror(this.options.codemirror);
        this.options.codemirrorOptions = {
            ...DEFAULT_CODEMIRROR_OPTIONS,
            ...this.options.codemirrorOptions,
        };
    },
});

export default CodeView;
