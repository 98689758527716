import { mergeAttributes } from '@tiptap/core';
import Details from '@tiptap-pro/extension-details';

const ExtensionCustomDetails = Details.extend({
    addNodeView() {
        return ({ HTMLAttributes }) => {
            const toggleDetailsContent = () => {
                container.classList.toggle(this.options.openClassName);

                const event = new Event("toggleDetailsContent");
                const element = content.querySelector(':scope > div[data-type="detailsContent"]');

                if (element) {
                    element.dispatchEvent(event);
                }
            };

            const container = document.createElement("div");
            const attributes = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
                "data-type": this.name
            });

            Object.entries(attributes).forEach((([t, e]) => container.setAttribute(t, e)));
            const button = document.createElement("button");

            button.type = "button";
            button.addEventListener('click', toggleDetailsContent);

            container.append(button);

            const content = document.createElement("div");
            container.append(content);

            return {
                dom: container,
                contentDOM: content,
                ignoreMutation: t => "selection" !== t.type && (!container.contains(t.target) || container === t.target),
                update: t => t.type === this.type
            };
        }
    },
});

export default ExtensionCustomDetails;
