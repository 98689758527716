export const buildNode = ({ type, attrs, content }) => (content ? { type, attrs, content } : { type, attrs });

export const buildParagraph = ({ content }) => buildNode({ type: 'paragraph', attrs: {}, content });

export const buildListItem = ({ type = 'listItem', attrs, content }) => buildNode({ type, attrs, content: content || [buildParagraph({})] });

export const buildMultiListItem = ({attrs, content}) => buildListItem({type: 'multiListItem', attrs: {'data-type': 'multi-list-item', ...attrs}, content});

export const buildOrderedList = ({ attrs, content }) => buildNode({ type: 'orderedList', attrs, content });

export const buildBulletList = ({ attrs, content }) => buildNode({ type: 'bulletList', attrs, content });

export const buildColumn = ({ content }) => buildNode({ type: 'column', content });

export const buildColumnBlock = ({ content }) => buildNode({ type: 'columnBlock', content });

export const buildNColumns = (n) => {
  const content = [buildParagraph({})];
  const fn = () => buildColumn({ content });

  return Array.from({ length: n }, (_) => fn());
};
