import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import formatDateTime from 'lib/helpers/formatDateTime';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import Loading from 'uikit/loading';
import cx from './chat-menu.module.scss';

export default function ChatMenu({ chats, currentChat, onOpenChat, onCreateDialog, onCreateGroup, isMobile }) {
    const [chatMenuTab, setChatMenuTab] = useState(0);

    const [chatsData, setChatsData] = useState([]);
    const [search, setSearch] = useState('');

    const chatDialogsCount = chatsData.filter((chat) => chat.type === 0).length;
    const chatGroupsCount = chatsData.filter((chat) => chat.type === 1).length;

    useEffect(() => {
        const fetchData = async () => {
            if (!chats) {
                return;
            }
            setChatsData(chats.filter((chat) => {
                return chat.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }));
        };
        fetchData();
    }, [chats, search]);

    return (
        <div className={classNames(cx.chatMenu, {[cx.mobile]: isMobile})}>
            <div className={cx.chatSearch}>
                <Input className={cx.chatSearchInput} placeholder="Поиск по чатам ..." value={search}
                       onChange={(value) => setSearch(value)}/>
                <Icon type={Icons.SEARCH} width={13} height={13}/>
            </div>
            <ul className={cx.chatTabs}>
                <li className={chatMenuTab === 0 ? cx.active : ''} onClick={() => setChatMenuTab(0)}>
                    <p>Все</p>
                    <span>{chatsData.length}</span>
                </li>
                <li className={chatMenuTab === 1 ? cx.active : ''} onClick={() => setChatMenuTab(1)}>
                    <p>Диалоги</p>
                    <span>{chatDialogsCount}</span>
                </li>
                <li className={chatMenuTab === 2 ? cx.active : ''} onClick={() => setChatMenuTab(2)}>
                    <p>Группы</p>
                    <span>{chatGroupsCount}</span>
                </li>
            </ul>
            <div className={cx.chatMenuContent}>
                {!chats &&
                <div className={cx.chatMenuContentLoading}>
                    <Loading withOverlay={false}/>
                </div>}
                {(chats && (chats.length === 0 || (chats.length !== 0
                    && ((chatMenuTab === 0 && chatDialogsCount === 0 && chatGroupsCount === 0)
                        || (chatMenuTab === 1 && chatDialogsCount === 0)
                        || (chatMenuTab === 2 && chatGroupsCount === 0))))) &&
                <div className={cx.chatMenuContentEmpty}>
                    <Icon type={Icons.CHAT} width={40} height={40}/>
                    <p>Чаты не найдены</p>
                </div>}
                {chatsData.filter((item) => {
                    if (chatMenuTab === 0) {
                        return true;
                    } else if (chatMenuTab === 1 && item.type === 0) {
                        return true;
                    } else if (chatMenuTab === 2 && item.type === 1) {
                        return true;
                    }
                    return false;
                }).map((item) => {
                    return (
                        <div key={item.id} className={classNames(
                            cx.chatMenuContentItem,
                            currentChat && currentChat.id === item.id ? cx.active : ''
                        )} onClick={() => onOpenChat(item.id, item.type)}>
                            {item.isRead !== 0 &&
                            <div className={cx.chatMenuContentItemRead}>{item.isRead}</div>}
                            <div className={cx.chatMenuContentItemDate}>
                                {item.isPrimary && <i/>}
                                <span>
                                    {item.lastChatMessage.date ? formatDateTime(item.lastChatMessage.date, false, '', true) : ''}
                                </span>
                            </div>
                            <div className={cx.chatMenuContentItemImage}
                                 style={{ backgroundImage: `url(${item.image})` }}/>
                            <div className={cx.chatMenuContentItemContainer}>
                                <div className={cx.chatMenuContentItemTitle}>
                                    {item.title}
                                </div>
                                {item.type === 1 &&
                                <div className={cx.chatMenuContentItemAuthor}>
                                    {item.lastChatMessage
                                        ? item.lastChatMessage.firstName + ' ' + item.lastChatMessage.lastName
                                        : ''}
                                </div>}
                                <div className={cx.chatMenuContentItemMessage}>
                                    {item.lastChatMessage
                                        ? item.lastChatMessage.message.length > 64
                                            ? item.lastChatMessage.message.substr(0, 64) + '...'
                                            : item.lastChatMessage.message
                                        : ''}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {(onCreateDialog || onCreateGroup) &&
            <div className={cx.chatButtons}>
                {onCreateDialog &&
                <button onClick={onCreateDialog}>
                    <Icon type={Icons.USER_ADD} width={20} height={20}/>
                    <p>Создать диалог</p>
                </button>}
                {onCreateGroup &&
                <button onClick={onCreateGroup}>
                    <Icon type={Icons.GROUP_ADD} width={20} height={20}/>
                    <p>Создать группу</p>
                </button>}
            </div>}
        </div>
    );
};