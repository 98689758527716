import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { selectUser } from 'slice/authSlice';
import Button from 'uikit/button';
import Icon, { Icons } from 'uikit/icon';
import HtmlViewer from 'editors/html-viewer';
import User from 'components/user';
import Attachments from 'components/attachments';
import api from 'api';
import cs from './article-preview.module.scss';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import { useGlobalContext } from 'lib/hooks';
import ButtonGroup from 'uikit/button-group';
import MultiClamp from 'react-multi-clamp';

export const ArticlePreview = ({ show, onDismiss, onEditDraft, onPublish, article, type, onDraft }) => {
    const [isExpand, setIsExpand] = useState(false);

    const { platform } = useGlobalContext();
    const user = useSelector(selectUser);

    const onPublicationClick = useCallback(() => {
        onDismiss();

        if (onEditDraft) {
            onEditDraft();
        } else {
            onPublish();
        }
    }, [onDismiss, onEditDraft, onPublish]);

    return (
        <>
            {show &&
            <Modal id={'articlePreviewModal'} isOpen={show} onRequestClose={onDismiss} contentLabel={''}
                   style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={classNames(cs.preview, {
                  [cs.expand]: isExpand
                })}>
                    <div className={cs.menu}>
                        <ButtonGroup type="lastToEnd">
                            <Button color={'base'} className={cs.buttonIcon} onClick={onDismiss}>
                                <Icon className={cs.backIcon} width={20} height={20} type={Icons.ARROW_LEFT}/>
                                {' '}
                                {platform === 'mobile' && <span>Выйти</span>}
                                {platform !== 'mobile' && <span>Выйти из предпросмотра</span>}
                            </Button>
                            <Button className={cs.expandButton} type="icon" onClick={() => setIsExpand(!isExpand)}>
                                <Icon width={32} height={32} type={Icons.EXPAND}/>
                            </Button>
                            {type === 'article' && !article && <Button onClick={onDraft}>В черновик</Button>}
                            <Button color="green" onClick={onPublicationClick}>
                                <span className={cs.desktop}>
                                    {onEditDraft ? 'Сохранить черновик' : 'Опубликовать'}
                                </span>
                                <span className={cs.mobile}>
                                    {onEditDraft ? 'Сохранить' : 'Опубликовать'}
                                </span>
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className={cs.header}>
                        <div id={'articlePreviewTitle'} className={cs.title}>
                            <MultiClamp clamp={2} ellipsis={'...'}>
                            {article.title}
                            </MultiClamp>
                        </div>
                        <User id={'articlePreviewAuthor'}
                              image={user?.avatar && 'data:image/jpeg;base64,' + user.avatar}
                              name={user && (user.firstName + ' ' + user.lastName)}/>
                    </div>
                    <div className={classNames(cs.content, 'js-scroll-wrapper')}>
                        {(article.logoUuid || article.logo) &&
                        <img id={'articlePreviewCover'} alt="" className={cs.cover}
                             src={article.logo ? article.logo : api.upload.getImage(article.logoUuid, false, window.innerWidth)}/>}
                        <div className={cs.mobileTitle}>{article.title}</div>
                        {article?.description?.length > 0 &&
                        <div id={'articlePreviewDescription'} className={cs.desc}>{article.description}</div>}
                        <HtmlViewer body={article.text} id="articlePreviewText"/>
                        {article.getFiles().filter(file => file?.status !== 'deleted').length > 0 &&
                        <div className={cs.attachments}>
                            <div/>
                            <div>Прикреплённые файлы</div>
                        </div>}
                        <Attachments
                            files={article.getFiles()}
                            className={cs.attachmentsBlock}
                            noAdd
                            noDelete
                            onChange={files => {
                                article.setFiles(files);
                                article.commit();
                            }}
                        />
                    </div>
                </div>
            </Modal>}
        </>
    );
};

ArticlePreview.defaultProps = {
    show: false
};

ArticlePreview.propTypes = {
    show: PropTypes.bool,
    onDismiss: PropTypes.func.isRequired,
    onDraft: PropTypes.func.isRequired,
    onPublish: PropTypes.func.isRequired,
    article: PropTypes.shape({
        text: PropTypes.string,
        title: PropTypes.string
    }),
    type: PropTypes.string
};
