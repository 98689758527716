import React, { useState } from 'react';
import classNames from 'classnames';
import { textValidator } from 'lib/util/validators.util';
import Button from 'uikit/button';
import Input from 'uikit/input';
import Icon, { Icons } from 'uikit/icon';
import cx from './positions-page-add-position.module.scss';
import IconButton from 'uikit/icon-button';
import { useGlobalContext } from 'lib/hooks';

const PositionsPageAddPosition = ({ position: _position, onCloseHandler, onSubmitHandler, data }) => {
    const { platform } = useGlobalContext();

    const [position, setPosition] = useState(_position);
    const [err, setErr] = useState(null);

    const setPositionDoubleErr = (position) => {
        if (data.some(( item ) => position === item.title)) {
            setErr('Такая должность уже создана');
        }
    };

    const onPositionNameChange = (value) => {
        setErr(textValidator(value, 64));
        setPositionDoubleErr(value.toLowerCase());
        setPosition({ ...position, title: value });
    };

    const onClose = () => {
        onCloseHandler(false);
    };

    const onSubmit = () => {
        setErr(textValidator(position.title, 64));
        if (position.title) {
          setPositionDoubleErr(position.title.toLowerCase());
        }

        if (!err && position.title) {
            onSubmitHandler(position);
            onCloseHandler();
        }
    };

    return (
        <>
            <div className={cx.modalHeader}>
                <h3>{!_position.title ? 'Новая должность' : 'Редактирование должности'}</h3>
                <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                            onClick={onClose}/>
            </div>
            <div className={cx.modalBody}>
                <Input value={position.title} label="Наименование должности" onChange={onPositionNameChange}
                       error={err} id={'positionFormTitle'} maxLength={64} required />
            </div>
            <div className={classNames(cx.modalFooter, {[cx.mobileFooter]: platform === 'mobile'})}>
                <Button label="Отменить" onClick={onClose} fullWidth={platform === 'mobile'}/>
                <Button color="green" label="Подтвердить" onClick={onSubmit} fullWidth={platform === 'mobile'} disabled={(!!err && position?.title) || !position?.title} />
            </div>
        </>
    );
};

export default PositionsPageAddPosition;