import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useMessage } from 'lib/hooks';
import DraftsPageArticlesTab from 'app/user-profile/drafts-page/drafts-page-articles-tab';
import DraftsPageNewsTab from 'app/user-profile/drafts-page/drafts-page-news-tab';
import cx from './drafts-page.module.scss';
import api from 'api';
import DraftsPageScriptsTab from './drafts-page-scripts-tab';
import { selectScriptingEnabled } from 'slice/authSlice';

const errorMessage = 'Сервис недоступен. Пожалуйста попробуйте позже.';

const DraftsPage = ({ navigate, tab }) => {
    const { addError } = useMessage();
    const isScriptingEnabled = useSelector(selectScriptingEnabled);

    const [articleCount, setArticleCount] = useState(null);
    const [newsCount, setNewsCount] = useState(null);
    const [scriptsCount, setScriptsCount] = useState(null);

    const onPageChange = (tab) => {
        navigate('/user/drafts' + (tab ? '/' + tab : ''));
    };

    const getArticlesCount = useCallback(async () => {
        try {
            const articles = await api.article.getArticlesDraft({ size: 1 });
            setArticleCount(articles.totalElements);
        } catch (e) {
            console.log(e);
            addError(errorMessage);
        }
    }, [addError]);

    const getNewsCount = useCallback(async () => {
        try {
            const news = await api.news.getDraftNews({ size: 1 });
            setNewsCount(news.totalElements);
        } catch (e) {
            console.log(e);
            addError(errorMessage);
        }
    }, [addError]);

    const getScriptsCount = useCallback(async () => {
        try {
            const news = await api.scriptingDraft.getAllScripts(0, 1);
            setScriptsCount(news.totalElements);
        } catch (e) {
            console.log(e);
            addError(errorMessage);
        }
    }, [addError]);

    useEffect(() => {
        getArticlesCount();
        getNewsCount();
        getScriptsCount();
    }, [getArticlesCount, getNewsCount, getScriptsCount]);

    return (
        <div className={cx.content}>
            <div className={cx.title}>Черновики</div>
            <ul>
                <li className={tab === 'articles' ? cx.active : ''} onClick={() => onPageChange('articles')}>
                    Статьи
                    <div className={cx.count}>{articleCount}</div>
                </li>
                <li className={tab === 'news' ? cx.active : ''} onClick={() => onPageChange('news')}>
                    Новости
                    <div className={cx.count}>{newsCount}</div>
                </li>
                {isScriptingEnabled && (
                    <li
                        id={cx.scriptsTab}
                        className={tab === 'scripts' ? cx.active : ''}
                        onClick={() => onPageChange('scripts')}
                    >
                        Скрипты
                        <div className={cx.count}>{scriptsCount}</div>
                    </li>
                )}
            </ul>
            <div className={cx.tableWrapper}>
                {tab === 'articles' && <DraftsPageArticlesTab onCountChange={getArticlesCount} />}
                {tab === 'news' && <DraftsPageNewsTab onCountChange={getNewsCount} />}
                {tab === 'scripts' && isScriptingEnabled && <DraftsPageScriptsTab onCountChange={getScriptsCount} />}
            </div>
        </div>
    );
};

export default DraftsPage;
