import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { TableComponent } from 'components/data-components';
import cxTable from 'uikit/table/table.module.scss';
import Confirmation from 'components/confirmation';
import { Empty } from 'components/projects';
import { EditRowToolbar } from 'uikit/table';
import Icon, { Icons } from 'uikit/icon';
import api from 'api';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import cx from './drafts-page-news-tab.module.scss';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import TableTitleCell from 'components/table-title-cell';

export default function DraftsPageScriptsTab({ onCountChange }) {
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const { width } = useWindowSize();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const {addSuccess, addError} = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [scriptsTotal, setScriptsTotal] = useState(0);
    const [scripts, setScripts] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const fetchScripts = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        setIsLoading(true);

        try {
            setIsLoading(true);

            const scriptsResponse = await api.scriptingDraft.getAllScripts(
                offset / count,
                count,
                sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                search);

            setScriptsTotal(scriptsResponse.totalElements);
            setScripts(scriptsResponse.content.map((script) => ({ ...script, read: undefined })));

            setIsSearch(!!search);
            setIsEmpty(scriptsResponse.totalElements === 0 && _.isEmpty(filters));

            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    }, []);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
          if (prevValue.some(c => c.id === node.id)) {
              return prevValue.filter(c => c.id !== node.id);
          } else {
              return [...prevValue, node]
          }
        })
    }, [])

    const deleteScripts = useCallback(async (data) => {
        setIsLoading(true);
        closeDialog();

        try {
            const count = checkedCheckboxRef.current.length;

            if (data.id) {
                await api.scriptingDraft.removeScript(data.id);
            } else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.scriptingDraft.removeScript(checkedCheckboxRef.current[i].id);
                }
            } else {
                setIsLoading(false);
                return
            }

            tableRef?.current?.reload();
            onCountChange();

            addSuccess(data.id ? 'Черновик скрипта удален' : 'Выбранные (' + count + ') черновики удалены')
        } catch (error) {
            addError('При удалении произошла ошибка')
        } finally {
            setIsLoading(false);
        }
    }, [closeDialog, onCountChange, addSuccess, addError]);

    const onScriptsDelete = useCallback(async (data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        openDialog({
            color: 'red',
            title: 'Удаление',
            text: 'Вы подтверждаете удаление?',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (articles) => checkboxHandler(articles),
            onSubmit: () => deleteScripts(data),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    }, [openDialog, closeDialog, checkboxHandler, deleteScripts]);

    const getEditUrl = (id) => `/scripting/edit/${id}/draft`;

    const columns = useMemo(() => {
        return [
            {
                Header: 'Содержание',
                accessor: 'title',
                isResizing: true,
                Cell: (data) => (
                    <TableTitleCell
                        title={data.row.original.title}
                        link={getEditUrl(data.row.original.id)}
                        description={data.row.original.description}
                    />
                ),
            },
            {
                Header: 'Дата изменения',
                accessor: 'modifyTime',
                width: 182,
                Cell: (data) => {
                    const createTime = new Date(data.row.original['createTime']).toLocaleString();
                    const modifyTime = new Date(data.row.original['modifyTime']).toLocaleString();
                    const isModifyTime = data.row.original['modifyTime'] !== null;

                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                            {isModifyTime
                                ? modifyTime.substr(0, modifyTime.length - 3)
                                : createTime.substr(0, createTime.length - 3)}
                        </div>
                    );
                },
                className: cxTable.td__grow_0
            },
            {
                id: 4,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                width: 82,
                Cell: (data) => {
                    let toolbar = [];
                    const {id} = data.row.original;

                    if (width > 1024) {
                        toolbar.push({
                            icon: Icons.EDIT_PEN,
                            tooltip: 'Редактировать',
                            link: `/scripting/edit/${id}/draft`
                        });
                    }

                    toolbar.push({
                        icon: Icons.TRASH,
                        tooltip: 'Удалить',
                        onClick: (data) => onScriptsDelete(data),
                    });

                    return (
                        <>
                            {EditRowToolbar(toolbar)(data)}
                            <Icon
                                className={cx.mobileDelete}
                                type={Icons.TRASH}
                                width={15}
                                height={15}
                                onClick={() => onScriptsDelete(data.row.original)}
                            />
                        </>
                    );
                },
                className: cxTable.td__grow_0
            },
        ];
    }, [platform, onScriptsDelete, width]);

    const scriptsActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: (data) => {
                onScriptsDelete(data);
            }
        });

        return actions;
    }, [onScriptsDelete]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <>
            <Confirmation {...dialogState} />

            {isEmpty && !isSearch && <Empty title="Скрипты не найдены" />}

            {(!isEmpty || isSearch) && (
                <TableComponent
                    innerRef={tableRef}
                    isLoading={isLoading}
                    searchTitle="Поиск по автору и названию"
                    columns={columns}
                    total={scriptsTotal}
                    data={scripts}
                    onPaginate={fetchScripts}
                    defaultSort={{ by: 'modifyTime', desc: true }}
                    isMobile={platform === 'mobile'}
                    actions={scriptsActions}
                />
            )}
        </>
    );
}
