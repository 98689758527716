import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import Button from 'uikit/button';
import PrivatePage from 'containers/private-page-wrapper';
import { LocationTree } from 'components/trees';
import BackButton from 'components/back-button';
import cx from './integrations-1cbitrix-page.module.scss';
import { useGlobalContext } from 'lib/hooks';

const Integrations1CBitrixPage = ({ hasAnyAuthorities, navigate }) => {
    const { platform } = useGlobalContext();
    const [locationDialog, setLocationDialog] = useState(false);

    const [host, setHost] = useState('');
    const [port, setPort] = useState('');

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const [project, setProject] = useState(null);
    const [components, setComponents] = useState([1]);

    const onDestinationChange = (node) => {
        setProject({ 'id': node.id, 'title': node.name });
        setLocationDialog(false);
    };
    const onSetComponent = (component) => {
        const data = Object.assign([], components);

        if (components.indexOf(component) === -1) {
            data.push(component);
        } else {
            data.splice(components.indexOf(component), 1);
        }

        setComponents(data);
    };
    const isValidate = () => {
        return host.length !== 0 && port.length !== 0 && login.length !== 0 && password.length !== 0 && project
            && components.length !== 0;
    };
    const onApprove = () => {
        localStorage.setItem('integration1CBitrix', JSON.stringify({
            'host': host,
            'port': port,
            'login': login,
            'password': password,
            'project': project,
            'components': components
        }));
        navigate('/global-settings/integrations');
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('integration1CBitrix'));

        if (!data) {
            return;
        }

        setHost(data['host']);
        setPort(data['port']);

        setLogin(data['login']);
        setPassword(data['password']);

        setProject(data['project']);
        setComponents(data['components']);
    }, []);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={classNames(cx.contentWrapper, {[cx.mobile]: platform === 'mobile'})}>
                <LocationTree open={locationDialog} onDismiss={() => setLocationDialog(false)}
                              onSubmit={onDestinationChange} withSections={false}/>
                <div className={cx.integrationsTitle}>
                    <BackButton className={cx.integrationsTitleIcon} link={'/global-settings/integrations'}/>
                    <h1>Интеграция 1С Битрикс</h1>
                </div>
                <div className={cx.integrationsWrapper}>
                    <div className={cx.integrationsWrapperBlock}>
                        <h2>Настройки</h2>
                        <div className={cx.integrationsWrapperBlockRow}>
                            <Input className={cx.host} label="Хост" description="Укажите доменное имя или IP адрес"
                                   value={host} onChange={(value) => setHost(value)}/>
                            <Input className={cx.port} label="Порт" value={port} onChange={(value) => setPort(value)}/>
                        </div>
                        <div className={cx.integrationsWrapperBlockRow}>
                            <Input className={cx.login} label="Логин" value={login}
                                   onChange={(value) => setLogin(value)}/>
                            <Input className={cx.password} label="Пароль" value={password}
                                   onChange={(value) => setPassword(value)}/>
                        </div>
                    </div>
                    <div className={cx.integrationsWrapperBlock}>
                        <h2>Выберите проект для интеграции</h2>
                        <div className={cx.integrationsLocation} onClick={() => setLocationDialog(true)}>
                            <div>{project ? project.title : ''}</div>
                            <Icon type={Icons.MORE}/>
                        </div>
                    </div>
                    <div className={cx.integrationsWrapperBlock}>
                        <h2>Выберите компоненты для интеграции</h2>
                        <ul className={cx.integrationsComponents}>
                            <li className={components.indexOf(1) !== -1 ? cx.active : ''}
                                onClick={() => onSetComponent(1)}>
                                <Icon type={Icons.FOLDER} width={26} height={24}/>
                                <span>Статьи</span>
                            </li>
                            <li className={components.indexOf(2) !== -1 ? cx.active : ''}
                                onClick={() => onSetComponent(2)}>
                                <Icon type={Icons.FOLDER} width={26} height={24}/>
                                <span>Новости</span>
                            </li>
                        </ul>
                    </div>
                    <div className={cx.integrationsWrapperButtons}>
                        <Button label="Отмена" onClick={() => navigate('/global-settings/integrations')}
                                fullWidth={platform === 'mobile'}/>
                        <Button color="green" label="Подтвердить" disabled={!isValidate()} onClick={onApprove}
                                fullWidth={platform === 'mobile'}/>
                    </div>
                </div>
            </div>
        </PrivatePage>
    );
};

export default Integrations1CBitrixPage;