import React from 'react';
import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';

export const MenuButtonTemplate = ({ mode, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const addTemplate = () => {
        setCurrentMenu(undefined);
        editor
            .chain()
            .focus()
            .setColumns({ cols: 3, widths: [25, 50, 25] })
            .run();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconTemplate}
            tooltip={isTooltip === false ? null : 'Макеты'}
            label={mode !== 'TABLET' ? 'Макеты' : null}
            onClick={addTemplate}
        ></MenuButton>
    );
};
