import ApiClient from './api-axios';

export default class SectionApi extends ApiClient {
    getRootSections = async (projectUuid) => {
        return await this.get(`root/${projectUuid}`);
    };

    getSectionsContents = async (projectUuid, sectionUuid) => {
        return await this.get(`${projectUuid}/${sectionUuid}/content`);
    };

    postSection = async (section) => {
        return await this.post('', section);
    };

    deleteSection = async id => {
        return await this.post(`delete/${id}`, {});
    };

    getSection = async id => {
        return await this.get(`${id}`);
    };

    editSection = async section => {
        return await this.post('edit', section);
    };

    validateTitle = async (parentProjectId, parentSectionId, sectionId, title) => {
        return await this.get(`validate/title?parentProjectId=${parentProjectId}${parentSectionId ? '&parentSectionId=' + parentSectionId : ''}&sectionId=${sectionId}&title=${encodeURIComponent(title)}`);
    };
}