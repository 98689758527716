import React from 'react';
import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';

export const MenuButtonIndex = ({ mode, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const addTOC = () => {
        setCurrentMenu(undefined);
        editor?.commands.insertTableOfContents();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconIndex}
            tooltip={isTooltip === false ? null : 'Оглавление'}
            label={mode !== 'TABLET' ? 'Оглавление' : null}
            onClick={addTOC}
        ></MenuButton>
    );
};
