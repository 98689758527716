import React, { useCallback, useEffect } from 'react';
import { useNavigate } from '@reach/router';
import UrlUtils from 'lib/util/url.util';
import TreeUtils from 'lib/util/tree.util';
import { NODE_TYPES } from 'components/trees';

export const useDefaultTreeProps = ({ onTreeUpdate, setTree, getNodeLink, archive, script }, treeRef) => {
    const navigate = useNavigate();
    const onNavigate = React.useCallback(
        async (node) => {
            if (archive) {
                if (!node) {
                    navigate('/archive');
                } else if (node.nodeType === NODE_TYPES.PROJECT) {
                    navigate(`/archive/${node.id}/articles`);
                } else if (node.nodeType === NODE_TYPES.ARTICLE) {
                    navigate(`/archive/article/${node.id}`);
                } else if (node.nodeType === NODE_TYPES.SECTION) {
                    navigate(UrlUtils.getArchiveSectionUrl(TreeUtils.getTopParentNode(node).id, node.id));
                }
            } else if (script) {
                if (!node) {
                    navigate('/scripting');
                } else if (node.nodeType === NODE_TYPES.PROJECT) {
                    navigate(`/scripting/${node.id}`);
                } else if (node.nodeType === NODE_TYPES.SECTION) {
                    navigate(`/scripting/${node.id}`);
                } else if (node.nodeType === NODE_TYPES.SCRIPTS) {
                    navigate(`/scripting/script/${node.id}`);
                }
            } else {
                if (!node) {
                    navigate('/projects');
                } else if (node.nodeType === NODE_TYPES.PROJECT) {
                    navigate(`/projects/${node.id}/articles`, { replace: true });
                } else if (node.nodeType === NODE_TYPES.ARTICLE) {
                    navigate(`/projects/article/${node.id}`);
                } else if (node.nodeType === NODE_TYPES.SECTION) {
                    navigate(UrlUtils.getSectionUrl(TreeUtils.getTopParentNode(node).id, node.id));
                } else if (node.nodeType === NODE_TYPES.SCRIPTS) {
                    navigate(`/scripting/script/${node.id}`);
                }
            }
        },
        [navigate, archive, script]
    );

    const _getNodeLink = useCallback(
        (node) => {
            if (archive) {
                if (!node) {
                    return '/archive';
                } else if (node.nodeType === NODE_TYPES.PROJECT) {
                    return `/archive/${node.id}/articles`;
                } else if (node.nodeType === NODE_TYPES.ARTICLE) {
                    return `/archive/article/${node.id}`;
                } else if (node.nodeType === NODE_TYPES.SECTION) {
                    return UrlUtils.getArchiveSectionUrl(TreeUtils.getTopParentNode(node).id, node.id);
                }
            } else if (script) {
                if (!node) {
                    return '/scripting';
                } else if (node.nodeType === NODE_TYPES.PROJECT) {
                    return `/scripting/project/${node.id}`;
                } else if (node.nodeType === NODE_TYPES.SECTION) {
                    return `/scripting/section/${node.id}`;
                } else if (node.nodeType === NODE_TYPES.SCRIPTS) {
                    return `/scripting/script/${node.id}`;
                }
            } else {
                if (!node) {
                    return '/projects';
                } else if (node.nodeType === NODE_TYPES.PROJECT) {
                    return `/projects/${node.id}/articles`;
                } else if (node.nodeType === NODE_TYPES.ARTICLE) {
                    return `/projects/article/${node.id}`;
                } else if (node.nodeType === NODE_TYPES.SECTION) {
                    return UrlUtils.getSectionUrl(TreeUtils.getTopParentNode(node).id, node.id);
                } else if (node.nodeType === NODE_TYPES.SCRIPTS) {
                    return `/scripting/script/${node.id}`;
                }
            }
        },
        [archive, script]
    );

    useEffect(() => {
        let handler;

        function tryTree() {
            const tree = treeRef.current?.tree;

            if (tree) {
                setTree(tree);
            } else {
                handler = setTimeout(tryTree, 300);
            }
        }

        tryTree();

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [treeRef, setTree]);

    return {
        onTreeUpdate: onTreeUpdate || onNavigate,
        getNodeLink: getNodeLink || _getNodeLink,
    };
};
