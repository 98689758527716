import React from 'react';

import { formatMessage } from '../utils';

import cx from './chat-message.module.scss';
import classNames from 'classnames';

export const ChatMessage = ({ message }) => {
    return (
        <div key={message.id} className={cx.chatWindowBodyItem}>
            <div
                id={message.id}
                className={classNames(cx.chatWindowBodyItemMessage, {
                    [cx.chatWindowBodyItemMessageSelf]: message.selfMessage,
                })}
            >
                {message.avatar && !message.selfMessage && (
                    <div className={cx.chatWindowBodyItemMessageAvatar}>{<img src={message.avatar} alt="" />}</div>
                )}
                <div className={cx.chatWindowBodyItemMessageContent}>
                    <p dangerouslySetInnerHTML={{ __html: formatMessage(message.message) }} />
                </div>
                <span>{message.messageTime}</span>
            </div>
        </div>
    );
};
