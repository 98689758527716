import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import Icon, { Icons } from 'uikit/icon';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import { NotificationTypes, setNotifications } from 'slice/notificationsSlice';
import api from 'api/index';
import cx from './notification-window.module.scss';
import Button from 'uikit/button';

const NotificationWindow = ({ notificationsCount, notifications, onClick, isMobile, onClose }) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const isTicker = useSelector(selectNewsPicker).count > 0;
    const [height, setHeight] = useState(0);

    const onRead = (notification) => {
        const newNotifications = JSON.parse(JSON.stringify(notifications));
        const notificationIndex = newNotifications.findIndex((item) => item['id'] === notification['id']);

        newNotifications[notificationIndex]['readByUser'] = true;
        dispatch(setNotifications(newNotifications));

        api.notification.read(notification['id']);
    };
    const getHeight = (elIndex) => {
        let _height = 0;

        for (let i = 0; i < elIndex + 1; i++) {
            if (i > 4 && ref.current && ref.current.children.length > i) {
                _height += ref.current.children[i].clientHeight;
            }
        }

        return _height
    }

    const onScroll = () => {
        let el = document.getElementById('notifications').children;
        let payload = JSON.parse(JSON.stringify(notifications));

        let isChanged = false;

        for (let i = 0; i < el.length; i++) {
            if ((notifications[i]['objectType'] === 'ARTICLE' || notifications[i]['objectType'] === 'NEWS') && notifications[i]['eventType'] === 'EDIT') {
                continue;
            }

            if (ref.current.scrollTop < getHeight(i) || notifications[i]['readByUser']) {
                continue;
            }

            payload[i]['readByUser'] = true;
            isChanged = true;

            api.notification.read(payload[i]['id']);
        }

        if (isChanged) {
            dispatch(setNotifications(payload));
        }
    }

    useEffect(() => {
        let _height = 0;

        for (let i = 0; i < 5; i++) {
            if (ref.current && ref.current.children.length > i) {
                _height += ref.current.children[i].clientHeight;
            }
        }

        setHeight(_height);
    }, [ref]);

    return (
        <>
            {isMobile && <div className={cx.mobileBack}/>}
            <div id="notificationsWindow" onClick={onClick} className={classNames(cx.container, {[cx.mobile]: isMobile}, {[cx.newLine]: isTicker})}>
                <p className={cx.head}>
                    <b>Непросмотренные</b>
                    &nbsp;—&nbsp;
                    {notificationsCount}
                </p>
                {(notifications == null || notifications.length <= 0) &&
                <div className={cx.empty}>
                    <Icon type={Icons.BELL} width={24} height={24}/>
                    <span>Нет новых уведомлений</span>
                </div>}
                {notifications != null && notifications.length > 0 &&
                <div id="notifications" ref={ref} className={cx.notifications} style={{ height: height }} onScroll={onScroll}>
                    {notifications.map((item, i) => {
                        return (
                            <div key={i} className={classNames(cx.notification, item['readByUser'] ? cx.notificationRead : null)}>
                                <div className={cx.notificationContent}>
                                    {item['author'] &&
                                    <img src={item['author']['avatarUuid'] ?
                                        api.upload.getImage(item['author']['avatarUuid'], false, 128)
                                        : '/img/avatar.png'} alt=""/>}
                                    <p>{NotificationTypes[item['objectType'] + '.' + item['eventType']]
                                        ? NotificationTypes[item['objectType'] + '.' + item['eventType']](onClose, item, true)
                                        : item['objectType'] + '.' + item['eventType']}</p>
                                </div>
                                {(item['objectType'] === 'ARTICLE_COMMENT' || item['objectType'] === 'NEWS_COMMENT') &&
                                <p className={cx.notificationComment}>{item['data']['text']}</p>}
                                {(item['objectType'] === 'ARTICLE' || item['objectType'] === 'NEWS') && item['eventType'] === 'EDIT' &&
                                <Button color='green' disabled={item['readByUser']} onClick={() => onRead(item)}>
                                    {item['readByUser'] ? 'Прочитано' : 'Прочитал (-а) уведомление'}
                                </Button>}
                                <p className={cx.notificationTime}>
                                    {new Date(item.timestamp).toLocaleString().slice(0, -3)}
                                </p>
                            </div>
                        );
                    })}
                </div>}
                <Link to={'/action-log/notifications'} className={cx.bottom} onClick={onClose}>
                    Показать все уведомления
                </Link>
            </div>
        </>
    );
}

export default NotificationWindow;
