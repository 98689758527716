import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from '@reach/router';
import { useArticleFormModel } from 'lib/hooks';
import { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';
import Card from 'components/card';
import { Empty } from 'components/projects';
import ArticleForm from './article-form';
import api from 'api';
import cs from './article-form-page.module.scss';

export const articleFormPage = (Component, pageTitle, edit = false) => {
    const FormPage = (props) => {
        const navigate = useNavigate();
        const { uuid, type } = props;

        const model = useArticleFormModel(uuid, type);
        const { editor, article, loading, permissionsOk } = model;

        const [isEmpty, setIsEmpty] = useState(false);
        const [isFetching, setIsFetching] = useState(true);
        const [isEditorInstanceReady, setIsEditorInstanceReady] = useState(false);

        useEffect(() => {
            const fetchSections = async () => {
                try {
                    setIsFetching(true);
                    const response = await api.globalMenu.hasAccess();

                    setIsEmpty(!response || !response.hasAccessToSections);
                    setIsFetching(false);
                } catch {
                    setIsEmpty(true);
                    setIsFetching(false);
                }
            };
            fetchSections();
        }, []);
        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401', { replace: true });
            }
        }, [navigate, permissionsOk]);

        useEffect(() => {
          if (editor?.instanceReady || editor?.isEditable) {
            setIsEditorInstanceReady(true);
          }
        }, [editor])

        return !isFetching ? (
            <div className={cs.content}>
                <div id={'articleFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                {!isEmpty &&
                <ArticleForm article={article} loading={loading || !isEditorInstanceReady} edit={edit}>
                    <Component {...props} {...model}/>
                </ArticleForm>}
                {isEmpty &&
                <Card className={cs.emptyCard}>
                    <Empty className={cs.empty} title="Нет разделов для расположения статьи"
                           description="Для создания статьи, сперва необходимо создать разделы в системе"
                           buttons={[{
                               icon: Icons.ADD,
                               title: 'Создать раздел',
                               onClick: () => {
                                   navigate('/add/section');
                               }
                           }]}/>
                </Card>}
            </div>
        ) : <Loading withOverlay={false} withRelativeOverlay={true}/>;
    };

    FormPage.propTypes = {
        uuid: PropTypes.string,
        type: PropTypes.string
    };

    return FormPage;
};
