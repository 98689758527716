import React from 'react';
import cx from './filterIndicator.module.scss';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';

let FilterIndicator = ({label, text, onRemove, click}) => {
    return (
        <div className={cx.indicator}>
            <span className={cx.text} onClick={click}>{label}: {text}</span>
            <IconButton className={cx.cross} onClick={() => onRemove()} icon={<Icon type={Icons.ROUND_CROSS} />} />
        </div>
    );
}

export default FilterIndicator;
