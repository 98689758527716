import React, { useState, useEffect } from 'react';
import cx from './TagGroupModal.module.scss';
import Button from 'uikit/button';
import Modal from 'react-modal';
import { desktopStyles } from 'lib/util/modalStyles';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import classNames from 'classnames';
import TagsInput from 'react-tagsinput';
import api from 'api';
import { useMessage } from 'lib/hooks';

export const TagGroupModal = ({ isOpen, onClose, onAfterSubmit, category, onlyAdd }) => {
    const { id, name, tags: initialTags = [] } = category ?? {};

    const [loading, setLoading] = useState(false);
    const [groupName, setGroupName] = useState(name);
    const [tags, setTags] = useState([]);
    const { addError } = useMessage();

    useEffect(() => {
        setGroupName(category?.name);
        setTags((category?.tags ?? []).map(tag => tag.name));
    }, [category]);

    const handleSubmit = async () => {
        setLoading(true);
        let categoryId = id;
        try {
            if (categoryId) {
                if (groupName !== name) {
                    await api.tags.patchCategory(categoryId, groupName);
                }
            } else {
                const { id } = await api.tags.saveCategory(groupName);
                categoryId = id;
            }

            await Promise.all(tags
                .filter(test => !initialTags.map(t => t.name).includes(test))
                .map(tag => {
                    return api.tags.addTag(categoryId, tag);
                })
            );

            await Promise.all(initialTags
                .filter(test => !tags.includes(test.name))
                .map(tag => {
                    return api.tags.deleteTag(tag.id);
                })
            );

            setLoading(false);
            onAfterSubmit();
            onClose();
        } catch(e) {
            console.log(e);
            addError("Ошибка сервера.");
        }
    }

    const handleTagsChange = (tags) => {
        setTags(tags);
    }

    const title = onlyAdd ? 'Добавление тегов в категорию' : (id ? 'Редактирование категории' : 'Создание категории');

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            contentLabel={'Content Label'}
            style={desktopStyles}
        >
            <div className={cx.modalContent}>
                <div className={cx.title}>
                    <div>{title}</div>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>} onClick={onClose}/>
                </div>

                <div className={cx.body}>
                    <Input
                        type='text'
                        value={groupName}
                        label='Название категории'
                        disabled={onlyAdd}
                        name='name'
                        required
                        onChange={str => {
                            setGroupName(str);
                        }}
                    />

                    <label className={cx.label}> Теги категории
                        <TagsInput
                            className={classNames('react-tagsinput', cx.reactTagsInput)}
                            inputProps={{ id: 'tags', className: 'react-tagsinput-input', placeholder: '' }}
                            value={tags}
                            onChange={handleTagsChange}
                            renderTag={(data) => {
                                return (
                                   <div key={data['key']} className={cx.reactTagsInputTag}>
                                       <span>{data['tag']}</span>
                                       <div onClick={() => data['onRemove'](data['key'])}>
                                           <Icon type={Icons.CROSS} width={6} height={6}/>
                                       </div>
                                   </div>
                                );
                            }}

                        />
                    </label>

                    <div className={cx.hint}>Вы можете ввести несколько вариантов написания через запятую</div>
                </div>

                <div className={cx.footer}>
                    <Button
                        onClick={onClose}
                        disabled={loading}
                        label='Отмена'
                    />
                    <Button
                        color='green'
                        onClick={handleSubmit}
                        label='Сохранить'
                        disabled={loading}
                    />
                </div>
            </div>
        </Modal>
    )
}
