export default function ApiError(data, code = 500) {
    this.responseCode = code;
    this.errorData = data;
    this.name = 'ApiError';

    if (data === undefined) {
        // document.location = '/';
    } else {
        this.stack = (new Error()).stack;
    }
}

ApiError.prototype = Object.create(Error.prototype);
ApiError.prototype.constructor = ApiError;
