import { PluginKey } from '@tiptap/pm/state';
import { TableMap } from './tableMap';

import { isTableSection } from './extension-table/utilities/isTableSection';
import { tableNodeTypes } from './extension-table/utilities/getTableNodeTypes';

export const tableEditingKey = new PluginKey('selectingCells');

export function cellAround($pos) {
    for (let d = $pos.depth - 1; d > 0; d--)
        if ($pos.node(d).type.spec.tableRole === 'row') return $pos.node(0).resolve($pos.before(d + 1));
    return null;
}

export function cellWrapping($pos) {
    for (let d = $pos.depth; d > 0; d--) {
        const role = $pos.node(d).type.spec.tableRole;
        if (role === 'cell' || role === 'header_cell') return $pos.node(d);
    }
    return null;
}

export function selectionCell(state) {
    const sel = state.selection;
    if ('$anchorCell' in sel && sel.$anchorCell) {
        return sel.$anchorCell.pos > sel.$headCell.pos ? sel.$anchorCell : sel.$headCell;
    } else if ('node' in sel && sel.node && sel.node.type.spec.tableRole === 'cell') {
        return sel.$anchor;
    }
    const $cell = cellAround(sel.$head) || cellNear(sel.$head);
    if ($cell) {
        return $cell;
    }

    return null;
}

function cellNear($pos) {
    for (let after = $pos.nodeAfter, pos = $pos.pos; after; after = after.firstChild, pos++) {
        const role = after.type.spec.tableRole;
        if (role === 'cell' || role === 'header_cell') return $pos.doc.resolve(pos);
    }
    for (let before = $pos.nodeBefore, pos = $pos.pos; before; before = before.lastChild, pos--) {
        const role = before.type.spec.tableRole;
        if (role === 'cell' || role === 'header_cell') return $pos.doc.resolve(pos - before.nodeSize);
    }
}

export function pointsAtCell($pos) {
    return $pos.parent.type.spec.tableRole === 'row' && !!$pos.nodeAfter;
}

export function moveCellForward($pos) {
    return $pos.node(0).resolve($pos.pos + $pos.nodeAfter.nodeSize);
}

export function inSameTable($cellA, $cellB) {
    return $cellA.depth === $cellB.depth && $cellA.pos >= $cellB.start(-2) && $cellA.pos <= $cellB.end(-2);
}

export function findCell($pos) {
    return TableMap.get($pos.node(-2)).findCell($pos.pos - $pos.start(-2));
}

export function colCount($pos) {
    return TableMap.get($pos.node(-2)).colCount($pos.pos - $pos.start(-2));
}

export function nextCell($pos, axis, dir) {
    const table = $pos.node(-2);
    const map = TableMap.get(table);
    const tableStart = $pos.start(-2);

    const moved = map.nextCell($pos.pos - tableStart, axis, dir);
    return moved == null ? null : $pos.node(0).resolve(tableStart + moved);
}

export function removeColSpan(attrs, pos, n = 1) {
    const result = { ...attrs, colspan: attrs.colspan - n };

    if (result.colwidth) {
        result.colwidth = result.colwidth.slice();
        const width = result.colwidth[pos];
        result.colwidth.splice(pos, n);
        if (!result.colwidth.some(w => w > 0)) result.colwidth = null;
        result.colwidth[0] = Number(result.cowidth[0]) + Number(width);
    }
    return result;
}

export function addColSpan(attrs, pos, n = 1) {
    const result = { ...attrs, colspan: attrs.colspan + n };
    if (result.colwidth) {
        result.colwidth = result.colwidth.slice();
        for (let i = 0; i < n; i++) result.colwidth.splice(pos, 0, 0);
    }
    return result;
}

export function columnIsHeader(map, table, col) {
    const headerCell = tableNodeTypes(table.type.schema).header_cell;
    for (let row = 0; row < map.height; row++) if (table.nodeAt(map.map[col + row * map.width]).type !== headerCell) return false;
    return true;
}

export function rowsCount(table) {
    let count = 0;
    for (let c = 0; c < table.childCount; c++) {
        const section = table.child(c);
        if (isTableSection(section)) count += section.childCount;
    }
    return count;
}

export function getRow(table, row) {
    let rPos = 0;

    let prevSectionsRows = 0;
    let sectionIndex = -1;

    for (let tc = 0; tc < table.childCount; tc++) {
        const section = table.child(tc);

        if (isTableSection(section)) {
            sectionIndex++;
            const sectionRows = section.childCount;

            if (sectionRows > 0) {
                if (prevSectionsRows + sectionRows <= row) {
                    if (tc === table.childCount - 1) {
                        return {
                            node: null,
                            pos: rPos + section.nodeSize - 1,
                            section: sectionIndex,
                        };
                    }

                    rPos += section.nodeSize;
                    prevSectionsRows += sectionRows;
                } else {
                    rPos++;
                    let r = 0;

                    while (r < sectionRows) {
                        if (prevSectionsRows + r === row) {
                            break;
                        }

                        rPos += section.child(r).nodeSize;
                        r++;
                    }

                    if (r === sectionRows) {
                        rPos++;
                    }

                    return {
                        node: r >= sectionRows ? null : section.child(r),
                        pos: rPos,
                        section: sectionIndex,
                    };
                }
            }
        } else {
            rPos += section.nodeSize;
        }
    }

    return { node: null, pos: rPos, section: sectionIndex };
}

export function rowPos(table, row) {
    return getRow(table, row).pos;
}

export function rowAtPos(table, pos) {
    let rpos = 0;
    let row = 0;
    for (let c = 0; c < table.childCount; c++) {
        const section = table.child(c);
        if (isTableSection(section)) {
            rpos++;
            for (let r = 0; r < section.childCount; r++) {
                rpos += section.child(r).nodeSize;
                if (pos < rpos) return row;
                row++;
            }
            rpos++;
        } else {
            rpos += section.nodeSize;
        }
    }
    return row;
}

export function tableHasCaption(table) {
    if (table && table.type.spec.tableRole === 'table') {
        return table.child(0).type.spec.tableRole === 'table_title';
    }
    return false;
}

export function tableHasHead(table) {
    if (table && table.type.spec.tableRole === 'table') {
        for (let i = 0; i < table.childCount; i++) if (table.child(i).type.spec.tableRole === 'table_head') return true;
    }
    return false;
}

export function tableHasFoot(table) {
    if (table && table.type.spec.tableRole === 'table') {
        for (let i = table.childCount - 1; i > 0; i--) if (table.child(i).type.spec.tableRole === 'foot') return true;
    }
    return false;
}

export function tableBodiesCount(table) {
    let count = 0;
    if (table && table.type.spec.tableRole === 'table') {
        for (let i = 0; i < table.childCount; i++) if (table.child(i).type.spec.tableRole === 'table_body') count++;
    }
    return count;
}

export function tableSectionsCount(table) {
    let count = 0;
    if (table && table.type.spec.tableRole === 'table') {
        for (let i = 0; i < table.childCount; i++) if (isTableSection(table.child(i))) count++;
    }
    return count;
}

export function isRowLastInSection(table, row) {
    const { height, sectionRows } = TableMap.get(table);
    if (row >= height || row < 0) return false;
    let rowsMinusOne = -1;
    for (let i = 0; i < sectionRows.length; i++) {
        rowsMinusOne += sectionRows[i];
        if (row === rowsMinusOne) return true;
        if (row < rowsMinusOne) return false;
    }
    return false;
}

export function isInTable(state) {
    const $head = state.selection.$head;
    for (let d = $head.depth; d > 0; d--) if ($head.node(d).type.spec.tableRole === 'row') return true;
    return false;
}

export function tableDepth($pos) {
    for (let d = $pos.depth; d >= 0; d--) if ($pos.node(d).type.spec.tableRole === 'table') return d;
    return -1;
}

export function innerTableInSelection(selection) {
    const depth = tableDepth(selection.$head);
    if (depth >= 0) {
        return selection.$head.node(depth);
    }
}

export function toPrecision(value) {
    const multiplier = Math.pow(10, 2);
    const number = typeof value === 'number' ? value : parseFloat(value);

    return Math.round(number * multiplier) / multiplier;
}
