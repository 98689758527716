import TreeUtils from 'lib/util/tree.util';
import { collectArticleParentsIds, visualPathFromParentNodes } from 'lib/helpers';

export const SECTION_TITLE_EXISTS = 'Раздел с таким названием уже существует';

export class SectionFormModel {
    id = null;
    uuid = null;
    sectionTitle = '';
    parentProjectId = null;
    parentSectionId = null;
    errors = {
        sectionTitle: null,
        sectionTitleExists: null,
        location: null
    };
    meta = {
        visualPath: [],
        parentsIds: []
    };
    touched = {};

    constructor(section) {
        const _sec = section && section.section;

        if (_sec) {
            this.id = _sec.uuid;
            this.uuid = _sec.uuid;
            this.sectionTitle = _sec.title;
        }

        const parents = section?.parents;

        if (parents) {
            this.parentProjectId = parents.projectId;
            const sections = parents.sections.reverse();

            if (sections && sections.length > 0) {
                const parentSection = sections[sections.length - 1];
                this.parentSectionId = parentSection.uuid;
            }

            this.meta.parentsIds = collectArticleParentsIds(this.id, parents);
            this.meta.visualPath = visualPathFromParentNodes(parents);
        }
    }

    copy() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    applyTreeChanges(tree) {
        if (tree) {
            const thisNode = tree.getNodeById(this.id);
            tree.updateNode(thisNode, { name: this.sectionTitle });
        }
    }

    setTargetNodeId(id) {
        this.meta.targetNodeId = id;
    }

    makeMovement(tree) {
        if (tree && this.meta.targetNodeId) {
            const thisNode = tree.getNodeById(this.id);
            const targetNode = tree.getNodeById(this.meta.targetNodeId);

            thisNode.state.open = false;
            tree.moveNodeTo(thisNode, targetNode, 0);
        }
    }

    getParentsIds(excludeSelf) {
        const parentsIds = this.meta.parentsIds;

        if (excludeSelf && parentsIds.length > 0) {
            return parentsIds.slice(0, parentsIds.length - 1);
        } else {
            return parentsIds;
        }
    }

    setParentsIds(node) {
        function traverseNodes(path, node) {
            if (!node) {
                return path;
            }
            else {
                return traverseNodes([...path, node.id], node.parent);
            }
        }

        this.meta.parentsIds = traverseNodes([this.id], node).reverse();
    }

    getVisualPath() {
        return this.meta.visualPath;
    }

    setVisualPath(node) {
        this.meta.visualPath = TreeUtils.getVisualPathTo(node);
    }

    clearPath() {
        this.parentProjectId = null;
        this.parentSectionId = null;
        this.meta.parentsIds = [];
        this.meta.visualPath = [];
        this.meta.targetNodeId = null;
    }

    getFormData() {
        const data = {};

        if (this.id) {
            data.id = this.id;
        }

        data.sectionTitle = this.sectionTitle.trim();
        data.parentProjectId = this.parentProjectId;

        if (this.parentSectionId) {
            data.parentSectionId = this.parentSectionId;
        }

        return data;
    }
}
