import React from 'react';
import { components } from 'react-select';

const SingleValueWithImg = (props) => {
    return (
        <components.SingleValue {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props['data']['img'] &&
                    <img src={props['data']['img']} width={16} height={16} alt={props['data']['label']}/>}
                {props['data']['img'] && <>&nbsp;</>}
                {props['data']['label']}
            </div>
        </components.SingleValue>
    );
};

const OptionWithImg = (props) => {
    return (
        <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {props['data']['img'] &&
                <img src={props['data']['img']} width={16} height={16} alt={props['data']['label']}/>}
                {props['data']['img'] && <>&nbsp;</>}
                {props['data']['label']}
            </div>
        </components.Option>
    );
};

export { SingleValueWithImg, OptionWithImg };
