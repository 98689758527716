import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from '@reach/router';
import Modal from 'react-modal';
import axios from 'axios';
import classNames from 'classnames';
import { humanFileSize } from 'lib/helpers';
import { FILES_TYPE_ICONS } from 'lib/config/constant';
import Icon, { Icons } from 'uikit/icon';
import ProgressBar from 'uikit/progress-bar';
import Button from 'uikit/button/button';
import { FileViewDialog } from 'components/file/file-view-dialog';
import api from 'api';
import cx from './upload-provider.module.scss';
import IconButton from 'uikit/icon-button';

const UploadContext = React.createContext({ });
const { Provider } = UploadContext;

export const UploadProvider = ({ children }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isViewFileModal, setViewFileModal] = useState(false);

    const [uploadFile, setUploadFile] = useState(null);
    const [uploadingFiles, setFiles] = useState([]);

    const uploadingFilesRef = useRef(uploadingFiles);
    const uploadedFilesCount = uploadingFiles.filter(f => f.status !== 'loading').length;

    const [isUploadModal, toggleUploadModal] = useState(false);
    const [isBackUploadModal, toggleBackUploadModal] = useState(false);

    const [isBackUploadExpand, toggleBackUploadExpand] = useState(false);
    const isUploading = uploadingFiles.some(f => f.status === 'loading');

    const [isCloseConfirmModal, toggleCloseConfirmModal] = useState(false);

    const [groupCallbacks, setGroupCallbacks] = useState({});
    const groupCallbacksRef = useRef(groupCallbacks);

    const _updateFile = (id, file) => {
        let f = uploadingFilesRef.current.find(_f => _f.id === id);
        const i = uploadingFilesRef.current.findIndex(_f => _f.id === id);

        const updFile = {...f, ...file};
        const newFiles = [
            ...uploadingFilesRef.current.slice(0, i),
            updFile,
            ...uploadingFilesRef.current.slice(i + 1)
        ];

        setFiles(newFiles);
        setUploadFile(updFile);
    }
    const showBackgroundModal = () => {
        toggleBackUploadModal(true);
    }

    const getInstance = (identifier) => {
        return {
            uploadingFiles: uploadingFiles.filter(f => f.identifier === identifier),
            upload: ({ file, onUpdate, onFinished }) => upload({ file, identifier, onUpdate, onFinished }),
            addGroupCallback: (callback) => addCallback(identifier, callback),
            removeGroupCallback: (callback) => removeCallback(identifier, callback),
            showBackgroundModal: showBackgroundModal,
            isUploading: uploadingFiles.filter(f => f.identifier === identifier)
                .some(f => f.status === 'loading')
        }
    }

    const addCallback = (identifier, callback) => {
        const prevCallbacks = Object.keys(groupCallbacks).includes(identifier) ? groupCallbacks[identifier] : [];
        setGroupCallbacks(Object.assign(groupCallbacks, {
            [identifier]: [...prevCallbacks, callback]
        }));
    }
    const removeCallback = (identifier, callback) => {
        const prevCallbacks = Object.keys(groupCallbacks).includes(identifier) ? groupCallbacks[identifier] : [];
        setGroupCallbacks(Object.assign(groupCallbacks, {
            [identifier]: prevCallbacks.filter(c => c !== callback)
        }));
    }

    const upload = ({ file, identifier = null, onUpdate = null, onFinished = null, type = 'new', fileId = null }) => {
        let block = false;
        const newFileId = uploadingFiles.length;
        const cancelToken = axios.CancelToken.source();
        let cancelled = false;
        let useCallbacks = true;
        const _file = {
            id: newFileId,
            fileId: null,
            status: 'loading',
            file: file,
            uploaded: 0,
            uploadedPercent: 0,
            size: file.size,
            identifier: identifier,
            start: async ({ inBackground = true } = { inBackground: true }) => {
                const progressEventHandler = async (progressEvent) => {
                    if (block) {
                        return;
                    }
                    block = true;
                    const fileUpd = {
                        uploaded: progressEvent.loaded,
                        total: progressEvent.total,
                        uploadedPercent: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10),
                    };
                    _updateFile(newFileId, fileUpd);
                    if (useCallbacks && onUpdate) {
                        await onUpdate({..._file, ...fileUpd});
                    }
                    setTimeout(() => block = false, 1000);
                };

                if (!inBackground) {
                    toggleUploadModal(true);
                }

                const formData = new FormData();
                formData.append('file', _file.file);

                let method;
                switch (type) {
                    case 'new':
                        method = async (data, progressHandler, token) => await api.file.uploadFile(file.name, data, progressHandler, token);
                        break;
                    case 'replace':
                        method = async (data, progressHandler, token) => await api.file.replaceFile(fileId, data, progressHandler, token);
                        break;
                    default:
                        method = () => console.log('won\'t happen, but warning');
                }

                return await method(formData,
                    async (progressEvent) => await progressEventHandler(progressEvent), cancelToken.token)
                    .then(res => {
                        if (cancelled) {
                            throw new Error('cancelled')
                        } else {
                            return res;
                        }
                    })
                    .then(async res => {
                        const upd = {...uploadingFiles.find(f => f.id === newFileId), status: 'done', fileId: res.fileId, serverData: res};
                        if (useCallbacks && onUpdate) {
                            await onUpdate(upd);
                        }
                        if (useCallbacks && onFinished) {
                            await onFinished(upd);
                        }
                        _updateFile(newFileId, { status: 'done', fileId: res.fileId, serverData: res })
                        return {...uploadingFiles.find(f => f.id === newFileId), status: 'done', fileId: res.fileId, serverData: res}
                    }).then(res => {
                        if (uploadingFiles.filter(f => f.identifier === identifier && f.id !== newFileId).every(f => f.status !== 'loading')
                            && Object.keys(groupCallbacksRef.current).includes(identifier)) {
                            const i = uploadingFiles.findIndex(_f => _f.id === newFileId);
                            const files = [
                                ...uploadingFiles.slice(0, i),
                                res,
                                ...uploadingFiles.slice(i+1)
                            ]

                            for (const c of groupCallbacksRef.current[identifier]) {
                                c(files);
                            }
                        }
                        return res;
                    }).catch(error => {
                        if (error.message !== 'cancelled') {
                            throw error;
                        }
                    });
            }, cancel: () => {
                cancelled = true;
                cancelToken.cancel();
                _updateFile(newFileId, { status: 'cancel' });
            }, removeCallbacks: () => {
                useCallbacks = false;
            }
        }

        uploadingFiles.push(_file);
        return _file;
    };
    const onOpenFileHandler = (file) => {
        setSelectedFile(file);
        setViewFileModal(true);
    }

    const cancelAllUpload = () => {
        for (const f of uploadingFiles) {
            f.cancel();
        }
    };
    const backUploadExpandHandler = () => {
        toggleBackUploadExpand(!isBackUploadExpand);
    };

    const onUploadModalClose = () => {
        if (!isUploading) {
            toggleUploadModal(false);
            setFiles([]);
        } else {
            toggleUploadModal(false);
            toggleBackUploadModal(true);
        }
    };
    const onFileRemove = (fileId) => {
        const filteredFiles = uploadingFiles.filter(f => f.fileId !== fileId);
        setFiles(filteredFiles);

        if (filteredFiles.length === 0) {
            onUploadModalClose();
        }
    }

    const onFileChange = (changedFile) => {
        const _files = [ ...uploadingFiles ];
        const file = _files.find(f => f.fileId === changedFile.fileId);

        file.file = {...file.file, ...changedFile};
        setFiles(_files);
    }
    const onUploadModalExpand = () => {
        toggleBackUploadModal(false);
        toggleUploadModal(true);
    };

    const onCloseConfirm = () => {
        toggleBackUploadModal(false);

        cancelAllUpload();
        setFiles([]);

        toggleCloseConfirmModal(false);
    };
    const BackUploadCloseHandler = () => {
        if (!isUploading) {
            onCloseConfirm();
        } else {
            toggleCloseConfirmModal(true);
        }
    };

    useEffect(() => { groupCallbacksRef.current = groupCallbacks }, [groupCallbacks]);
    useEffect(() => { uploadingFilesRef.current = uploadingFiles }, [uploadingFiles]);

    return (
        <Provider value={{ upload, uploadingFiles, showBackgroundModal, getInstance, uploadFile }}>
            { children }

            <Modal isOpen={isUploadModal} className={cx.modal} onRequestClose={onUploadModalClose} overlayClassName={cx.modalOverlay}>
                <div className={cx.modalHeader}>
                    <h3>Загрузка неопубликованного файла</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={onUploadModalClose}/>
                </div>
                <div className={cx.modalBody}>
                    {uploadingFiles.map((file) => {
                        return <UploadFileElement key={file.id} file={file} onOpenFile={onOpenFileHandler} />
                    })}
                    {/*{uploadFile &&*/}
                    {/*<UploadFileElement key={uploadFile.id} file={uploadFile} onOpenFile={onOpenFileHandler} />}*/}
                </div>
                {isUploading &&
                <div className={cx.modalFooter}>
                    <Button label="Отменить все" onClick={cancelAllUpload} />
                    <button className={cx.iconButton} onClick={onUploadModalClose}>
                        <Icon type={Icons.HOURGLASS}/> <span>Загружать на фоне</span>
                    </button>
                </div>}
                {!isUploading &&
                <div className={cx.modalFooter}>
                    <Button label="Закрыть" onClick={onUploadModalClose} />
                    <Link to={'/files/unpublished'} onClick={onUploadModalClose}>
                        <Button label="Перейти к файлам" />
                    </Link>
                </div>}
            </Modal>

            {isBackUploadModal &&
            <div className={cx.backUploadModal}>
                <div className={cx.backUploadHeader}>
                    <span>Загрузка файлов ({uploadedFilesCount}/{uploadingFiles.length})</span>
                    <div className={cx.backUploadIcons}>
                        <Icon type={Icons.EXPAND} width={16} height={16} onClick={onUploadModalExpand} />
                        {isBackUploadExpand
                            ? <Icon type={Icons.CHEVRON_UP} width={14} height={14} onClick={backUploadExpandHandler}/>
                            : <Icon type={Icons.ARROW_DOWN} width={14} height={14} onClick={backUploadExpandHandler} />
                        }
                        <Icon type={Icons.CROSS} width={10} height={10} onClick={BackUploadCloseHandler}/>
                    </div>
                </div>
                <div className={classNames(cx.backUploadBody, {
                    [cx.hidden]: isBackUploadExpand,
                })}>
                    <div className={cx.backUploadCancelAllBtn} onClick={cancelAllUpload}>Отменить всё</div>
                    {uploadingFiles.map((file) => {
                        return <UploadFileElement key={file.id}
                                                  file={file}
                                                  onOpenFile={onOpenFileHandler} />
                    })}
                </div>
            </div>}
            <Modal isOpen={isCloseConfirmModal} className={cx.modal} onRequestClose={onUploadModalClose}
                   overlayClassName={cx.modalOverlay}>
                <div className={cx.modalHeader}>
                    <h3>Отмена</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={() => toggleCloseConfirmModal(false)}/>
                </div>
                <div className={cx.modalBody}>
                    Вы действительно хотите прервать текущую загрузку файлов? Все активные загрузки будут удалены.
                </div>
                <div className={cx.modalFooter}>
                    <Button label="Нет, закрыть" onClick={() => toggleCloseConfirmModal(false)}/>
                    <Button color="red" label="Да, отменить" onClick={onCloseConfirm}/>
                </div>
            </Modal>

            {isViewFileModal &&
            <FileViewDialog file={selectedFile}
                            onChange={onFileChange}
                            onRemove={onFileRemove}
                            handleClose={() => setViewFileModal((isOpen) => !isOpen)} />}
        </Provider>
    );
};

const UploadFileElement = ({ file, onOpenFile }) => {
    const { name, type } = file.file;

    return (
        <div className={cx.uploadFiles}>
            <div className={cx.iconWrapper}>
                <Icon type={FILES_TYPE_ICONS[type] || Icons.NONE_FILE} width={32} height={32}/>
            </div>
            <div className={cx.uploadInfo}>
                <div className={cx.fileName}>
                    <span>{name}</span>
                </div>
                {file.status !== 'done' && (
                    <div className={cx.progressWrapper}>
                        <ProgressBar now={file.uploadedPercent} />
                    </div>
                )}
                <div className={cx.loadDescription}>
                    {file.status === 'done' && humanFileSize(file.size, true) + ' - успешно загружено'}
                    {file.status === 'loading' && file.uploaded
                        ? humanFileSize(file.uploaded, true) + ' / ' + humanFileSize(file.size, true)
                        : file.status !== 'cancel' && file.status !== 'done' ? 'Ожидает загрузки' : null}
                    {file.status === 'cancel' && 'Загрузка прервана'}
                </div>
            </div>
            {file.status === 'loading' && (
                <div className={cx.cancelIcon} onClick={() => file.cancel()}>
                    <Icon type={Icons.CROSS} width={6} height={6} />
                </div>
            )}
            {file.status === 'done' && file.fileId && (
                <div>
                    <div className={cx.uploadedIconWrapper}>
                        <div className={cx.uploadedIcon}>
                            <Icon type={Icons.CHECK} width={6} height={6} />
                        </div>
                    </div>
                    <div className={cx.openFileButton} onClick={() => onOpenFile(file)}>Просмотреть</div>
                </div>
            )}
        </div>
    );
};

export const useUpload = (identifier = null) => {
    const ctx = useContext(UploadContext);

    if (!ctx) {
        throw Error('The `useUpload` hook must be called from a descendent of the `UploadProvider`.');
    }

    if (identifier) {
        return ctx.getInstance(identifier);
    }

    return {
        upload: ctx.upload,
        uploadingFiles: ctx.uploadingFiles,
        getInstance: ctx.getInstance,
        showBackgroundModal: ctx.showBackgroundModal
    }
}
