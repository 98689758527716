import ApiClient from './api-axios';

export default class GlossaryApi extends ApiClient {
    list = async (page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('term.contains', search);
            params.append('definition.contains', search);
        }

        if (filters['projectId.equals'] && filters['projectId.equals'].value !== '') {
            params.append('projectId.equals', filters['projectId.equals'].value);
        }

        return await this.get('', params, true);
    };
    create = async (projectId, title, description) => {
        return await this.post('', {
            projectId: projectId,
            termTags: title.map((item) => { return { termTag: item } }),
            termDefinition: description
        }, true);
    };
    update = async (id, projectId, title, description) => {
        return await this.put('/', {
            id: id,
            projectId: projectId,
            termTags: title.map((item) => { return { termTag: item } }),
            termDefinition: description
        }, true);
    };
    remove = async (id) => {
        return await this.deleteRequest('/' + id, {}, true);
    };
    validate = async (projectId, tag) => {
        return await this.get('/validate', { projectId, tag }, true);
    };
    validateExceptTerm = async (projectId, tag, termId) => {
        return await this.get('/validateExceptTerm', { projectId, tag, termId }, true);
    };
    template = () => {
        return this.prefix + '/template';
    };
    export = () => {
        return this.prefix + '/export';
    };
    import = async (formData, onUploadProgress, cancelToken) => {
        return this.upload('/import', formData, onUploadProgress, cancelToken);
    };
}