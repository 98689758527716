import DetailsContent from '@tiptap-pro/extension-details-content';

const ExtensionCustomDetailsContent = DetailsContent.extend({
    content: "block+",

    parseHTML() {
        return [{
            tag: `div[data-type="${this.name}"]`
        }, {
            tag: `div[class="pre-content"]`
        }];
    },
});

export default ExtensionCustomDetailsContent;
