import ApiClient from './api-axios';

export default class ScriptingDraftApi extends ApiClient {
    postScript = async (script) => {
        console.log(script);
        return await this.post('script-draft/', script);
    };

    patchScript = async (script) => {
        return await this.post('script-draft/edit', script);
    };

    removeScript = async (uuid) => {
        // uuid - string[]
        return await this.post('script-draft/delete/', { list: [uuid] });
    };

    publishScript = async (uuid) => {
        return await this.post('script-draft/publish/' + uuid);
    };

    saveAndPublishScript = async (script) => {
        return await this.post('script-draft/save-and-publish', script);
    };

    getAllScripts = async (page = 0, size = 20, sort = '', _search = '') => {
        const params = new URLSearchParams({ page, size, sort });

        if (_search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', _search);
            params.append('authorLogin.contains', _search);
            params.append('authorLastName.contains', _search);
            params.append('authorFirstName.contains', _search);
            params.append('authorMiddleName.contains', _search);
        }

        return await this.get('script-draft?' + params.toString(), '');
    };

    getScriptById = async (uuid) => {
        return await this.get(`script-draft/${uuid}`);
    };
}
