import api from 'api/index';

const processImage = async (id, element) => {
    return new Promise((resolve, _reject) => {
        const img = new Image();

        img.crossOrigin = 'Anonymous';
        img.src = element.src;

        img.onload = function () {
            try {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.height = this.naturalHeight;
                canvas.width = this.naturalWidth;

                ctx.drawImage(this, 0, 0);
                canvas.toBlob(async (blob) => {
                    try {
                        const formData = new FormData();

                        const re = new RegExp('XSRF-TOKEN=([^;]+)');
                        const value = re.exec(document.cookie);

                        formData.append('init', id);
                        formData.append('upload', blob);
                        formData.append('ckCsrfToken', value !== null ? decodeURI(value[1]) : null);

                        const res = await api.file.uploadEditorFile(formData);
                        resolve(res.url);
                    } catch {
                        resolve(undefined);
                    }
                });
            } catch (ex) {
                resolve(undefined);
            }
        };

        img.onerror = function () {
            resolve(undefined);
        };
    });
};

export default async function UploadEditorImages(id, html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const images = Array.from(doc.querySelectorAll('img'))
        .filter(p => p.src.indexOf('https://') !== -1)
        .filter(p => p.src.indexOf(window.location.origin) === -1);

    for (let image of images) {
        const src = await processImage(id, image);
        console.log(src);

        if (src === undefined) {
            continue;
        }

        image.src = src.toString();

        image.setAttribute('data-cke-saved-src', '');
        image.setAttribute('data-cke-widget-data', '');
    }

    return doc.documentElement.innerHTML;
}
