import React, { useState, useMemo, useEffect } from 'react';
import { ReportsComponent } from 'components/data-components/reports-component';
import api from 'api';
import { sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';

const StatisticReportsSearchRequests = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);

    const getSearchRequestReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getSearchRequests({ page, size, filters, cancelToken: ReportService.cancelToken.token });

            setReports(
                res.searches.content.map((i) => {
                    return {
                        ...i,
                        numOfSearches: i.numberOfQueries,
                        avgNumOfProposedArticles: i.avgNumberOfProposedArticles || 0,
                        avgNumOfProposedNews: i.avgNumberOfProposedNews || 0,
                        avgNumOfProposedFiles: i.avgNumberOfProposedFiles || 0,
                    };
                })
            );
            setReportsTotal(res.searches.totalElements);

            if (!res.searches.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('search/queries', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Запрос',
                accessor: 'query',
            },
            {
                Header: 'Кол-во запросов',
                accessor: 'numOfSearches',
            },
            {
                Header: 'Статей в выдаче',
                accessor: 'avgNumOfProposedArticles',
            },
            {
                Header: 'Новостей в выдаче',
                accessor: 'avgNumOfProposedNews',
            },
            {
                Header: 'Файлов в выдаче',
                accessor: 'avgNumOfProposedFiles',
            },
        ];

        setSortByOptions(
            data
                .filter((col) => col.accessor && col.isVisible !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }))
        );

        return data;
    }, []);

    const filtersTmp = useMemo(() => {
        return [
            {
                fields: [
                    {
                        labels: ['Диапазон времени', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: 'Учитывать поиск в рамках проектов',
                fields: [
                    {
                        name: 'projectPaths',
                        type: 'project',
                        withSections: false,
                        withArticles: false,
                        default: null,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: sortByOptions ? sortByOptions[0] : null,
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions]);

    useEffect(() => {
        ReportService.init('SEARCH_REQUESTS');

        return () => {
            ReportService.destroy();
        };
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getSearchRequestReports}
        />
    );
};

export default StatisticReportsSearchRequests;
