import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFlexLayout, useResizeColumns, useRowSelect, useTable } from 'react-table';
import Checkbox from 'uikit/checkbox';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import cx from './table.module.scss';
import { LongPressWrapper } from 'containers/long-press-wrapper';
import { EVENT_TYPE } from 'containers/long-press-wrapper/long-press-wrapper';
import { TagList } from 'components/tag-list';

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox type="checkbox" ref={resolvedRef} {...rest} />
            </>
        );
    }
);

const withErrorBoundary = (renderFn) => (data) => {
    try {
        return renderFn(data);
    } catch (error) {
        console.error("Error rendering cell:", error);
        return '-';
    }
};

const applyErrorBoundaryToColumns = (columns) => {
    return columns.map((column) => ({
        ...column,
        Cell: withErrorBoundary(column.Cell || ((data) => data.value)),
    }));
};

export const Table = ({
    archive,
    columns,
    data,
    onRowClick,
    onRowSelection,
    sort,
    onSort,
    actions,
    isMobile,
    isGlobalCheckbox = true,
    canRowSelect = true,
    rowsToTilesOnMobile = false,
    search,
    id = "",
}) => {
    const columnsWithErrorBoundary = useMemo(() => applyErrorBoundaryToColumns(columns), [columns]);
    const defaultColumn = {
        minWidth: 30,
        width: 150,
        maxWidth: 200
    };

    const headerProps = (props, { column }) => getStyles(props, column.justify);
    const cellProps = (props, { cell }) => {
        return getStyles(props, cell.column.align)
    };

    const getStyles = (props, align = 'left') => {
        const _style = {
            justifyContent: align === 'right' ? 'flex-end' : align === 'center' ? 'center' : 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
        };
        if (isMobile) {
            _style.width = 'auto';
            _style.maxWidth = 'none';
        }
        return [props, { style: _style }];
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleAllRowsSelected } = useTable(
        {
            columns: columnsWithErrorBoundary,
            data,
            defaultColumn,
            initialState: {
                hiddenColumns: columns.reduce((acc, column) => {
                    if (column.isVisible === false) {
                        acc.push(column.accessor || column.id);
                    }

                    return acc;
                }, []),
            },
        },
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => {
                const _columns = [...columns];

                if (!isMobile && actions.length > 0) {
                    _columns.unshift({
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <div>
                                {isGlobalCheckbox ? <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} /> : null}
                            </div>
                        ),
                        Cell: ({ row }) => {
                            const checkboxProps = row.getToggleRowSelectedProps();

                            if (row.original.rowCanChecked === false) {
                                delete checkboxProps['onChange'];
                                delete checkboxProps['checked'];
                            }

                            return (
                                <div>
                                    <IndeterminateCheckbox onClick={() => onRowSelection(row.original, selectedRows)}
                                                           disabled={row.original.rowCanChecked === false
                                                           || (!row.isSelected && !canRowSelect)} {...checkboxProps}/>
                                </div>
                            )
                        },
                        width: 0,
                        maxWidth: 0
                    });
                }
                return _columns;
            });
        }
    );

    const selectedRows = rows.filter(row => row.isSelected && row.original.rowCanChecked !== false);
    const isMobileSelectionMode = isMobile && rows.some(r => r.isSelected);

    return (
        <>
            <div {...getTableProps()} className={classNames(cx.table, {[cx.tableMobile]: isMobile})}>
                <>
                    {actions.length > 0 && (!isMobile || selectedRows.length > 0) &&
                    <div className={cx.actions}>
                        <div className={cx.actionsWrapper}>
                            <p data-testid={id + "SelectedRows"}>Выбрано: {selectedRows.length}</p>
                            {selectedRows.length !== 0 && actions &&
                            <div className={cx.buttonsWrapper}>
                                {actions.map((action) => {
                                    return (
                                        <IconButton key={action.label} icon={<Icon type={action.icon}/>}
                                                    type={IconButtonType.TABLE} label={action.label}
                                                    className={action.className}
                                                    onClick={() => {
                                                        selectedRows.map((row) => row.toggleRowSelected());
                                                        action.onClick(selectedRows);
                                                    }} link={action.link} id={id + "MultipleActions"}/>
                                    );
                                })}
                            </div>}
                        </div>
                        {isMobile && <div className={cx.clearSelection} onClick={_ => toggleAllRowsSelected(false)}>
                            Отменить выделение
                        </div>}
                    </div>}
                    {!isMobile && <div className={cx.thead} data-testid={id + "Head"}>
                        {headerGroups.map((headerGroup, headerGroupIndex) => {
                            return (
                                <div key={`headerGroup__${headerGroupIndex}`} {...headerGroup.getHeaderGroupProps({ style: { paddingRight: '0px' } })} className={cx.tr}>
                                    {headerGroup.headers.map((column, index) => {
                                        return (
                                            <div key={`${column.id}__${index}`} {...column.getHeaderProps(headerProps)} className={classNames(
                                                cx.th, (column.id === 'selection' || (typeof column.Header === 'function' && !column.accessor)) && cx.hidden, !!column.className && column.className
                                            )} onClick={() => column.id !== 'selection' && sort && column.accessor && onSort(column)}>
                                                {column.render('Header')}
                                                {sort && column.accessor &&
                                                <span className={sort.by === column.id ? sort.desc ?
                                                    cx.sortDesc : cx.sortAsc : cx.sort}>
                                                    <Icon type={Icons.ARROW} width={6} height={4}/>
                                                    <Icon type={Icons.ARROW} width={6} height={4}/>
                                                </span>}
                                                {column.canResize &&
                                                <div {...column.getResizerProps()} className={classNames([cx.resizer],
                                                    { [cx.isResizing]: column.isResizing })}/>}
                                            </div>
                                        );
                                    })}
                                </div>
                            )
                        })}
                    </div>}
                </>
                <div {...getTableBodyProps()} className={classNames(cx.tbody, { [cx['tbody--mobile-tiles']]: rowsToTilesOnMobile && isMobile })} id={'tableBody'} data-testid={id + "Body"}>
                    {search && rows.length === 0 && (
                        <div className={cx.empty}>
                            <Icon type={Icons.SEARCH} width={54} height={54} />
                            <p>К сожалению, по вашему запросу ничего не найдено</p>
                        </div>
                    )}
                    {rows.map((row, i) => {
                        prepareRow(row);

                        const _onRowClick = () => onRowClick(row.original, row.values);
                        const _onRowSelection = () => onRowSelection(row.original, selectedRows);
                        const _toggleRowSelection = () => {
                            row.toggleRowSelected();
                            _onRowSelection();
                        }

                        return (
                            <Fragment key={`row__${i}`}>
                                <LongPressWrapper
                                    key={i}
                                    {...row.getRowProps()}
                                    eventType={EVENT_TYPE.CAPTURE}
                                    className={classNames(cx.tr,
                                        {
                                            [cx['tr--mobile-tile']]: rowsToTilesOnMobile && isMobile,
                                            [cx.pointer]: canRowSelect ? onRowSelection : null,
                                            [cx.unread]: !archive
                                                && (row.original.read === false || row.original.unread)
                                                && !row.original.documentTags?.length,
                                            [cx.rowLine]: !row.original.documentTags?.length,
                                        }
                                    )}
                                    onLongPress={() => {
                                        if (isMobile && canRowSelect) {
                                            _toggleRowSelection();
                                        }
                                    }}
                                    onClick={(e) => {
                                        if (isMobileSelectionMode) {
                                            _toggleRowSelection();
                                            e.stopPropagation();
                                            e.preventDefault();
                                        } else if (isMobile) {
                                            _onRowClick();
                                        }
                                    }}
                                >
                                    {isMobileSelectionMode && (
                                        <div className={classNames(cx.mobileCheckbox, {[cx.active]: row.isSelected})}>
                                            {row.isSelected && <Icon type={Icons.CHECK} width={14} height={20}/>}
                                        </div>
                                    )}
                                    {row.cells.map((cell, i) => {
                                        const settings = cell.column.settings ? cell.column.settings : [];
                                        return (
                                            <div {...cell.getCellProps(cellProps)}
                                                 className={classNames({
                                                     [cx.td]: !settings.includes('no_td_wrap'),
                                                     [cx.marginLeft]: isMobileSelectionMode && i === 0,
                                                     [cx.marginRight]: i === 0,
                                                     [cx.hidden]: cell.column.id === 'selection' || (typeof cell.column.Header === 'function' && !cell.column.accessor),
                                                     [cell.column.className]: !!cell.column.className
                                                 })}
                                                 onClick={(e) => {
                                                     if (cell.column.id === 'selection' || !cell.column.accessor) {
                                                         e.persist();
                                                         // _onRowSelection();
                                                     } else if (!isMobile) {
                                                         _onRowClick();
                                                     }
                                                 }}
                                            >
                                                {cell.render('Cell', { isMobile })}
                                            </div>
                                        );
                                    })}
                                </LongPressWrapper>

                                { row.original.documentTags?.length > 0 && (
                                    <div>
                                        <TagList tags={row.original.documentTags} className={cx.tags} maxTags={10}/>
                                    </div>
                                )}

                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

Table.defaultProps = {
    onRowSelection: () => {

    },
    onRowClick: () => {

    },
    actions: []
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRowClick: PropTypes.func,
    onRowSelection: PropTypes.func,
    header: PropTypes.string,
    sort: PropTypes.shape({
        by: PropTypes.string,
        desc: PropTypes.bool
    }),
    onSort: PropTypes.func,
    actions: PropTypes.array,
    search: PropTypes.string,
};
