import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMessage, useDialog, useCancelBehavior, useGlobalContext } from 'lib/hooks';
import hot from 'containers/hot-container';
import PrivatePage from 'containers/private-page-wrapper';
import { articleFormPage } from 'components/aritcle-form';
import Preview from 'components/article-preview';
import Loading from 'uikit/loading';
import Button from 'uikit/button';
import cs from './add-article-page.module.scss';
import api from 'api';
import { useUpload } from 'components/background-upload/upload-provider';
import Confirmation from 'components/confirmation';
import { incrementUnreadCounter } from 'slice/authSlice';
import { useDispatch } from 'react-redux';
import UploadEditorImages from 'lib/helpers/uploadEditorImages';

const AddArticlePage = ({ article, loading, setLoading, navigate, hasAnyAuthorities }) => {
    const { platform, setFormDataChanged } = useGlobalContext();

    const { addError, addSuccess } = useMessage();
    const { cancel } = useCancelBehavior();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [previewShow, setPreviewShow] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const { showBackgroundModal, isUploading, addGroupCallback, removeGroupCallback } = useUpload(article.timeId);
    const [queuedAction, setQueuedAction] = useState(null);
    const dispatch = useDispatch();

    const onOk = async () => {
        showBackgroundModal();

        const formData = article.getFormData();
        formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

        let method;
        switch (queuedAction) {
            case 'addArticle':
                method = api.article.postArticle;
                break;
            case 'addArticleDraft':
                method = api.article.postArticleDraft;
                break;
            default:
                method = () => console.log('won\'t happen, but warning');
        }
        const callback = uploadedFiles => {
            const files = [...formData.files, ...uploadedFiles.filter(f => f.status === 'done').map(f => f.fileId)];
            // uniques only
            formData.files = files.filter((v, i, a) => a.indexOf(v) === i);
            method(formData);
            addSuccess('Статья добавлена.');
            removeGroupCallback(callback);
        };
        addGroupCallback(callback);
        closeDialog();

        addSuccess('Действие будет выполнено после загрузки файлов. Не закрывайте вкладку.');
    };

    const onBackgroundUpload = () => {
        openDialog({
            title: 'Фоновый режим',
            text: 'Не все файлы были загружены. Загрузить их в фоновом режиме?',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, загрузить',
            onSubmit: onOk,
            onClose: closeDialog,
        })
    }

    const addArticle = async () => {
        article.commit();
        article.submit();

        setIsSubmit(true);

        if (!article.validateFields()) {
            return;
        }

        const titleExists = await article.validateTitleExists();
        if (titleExists) {
            return;
        }

        if (isUploading) {
            setQueuedAction('addArticle');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setLoading(true);

        try {
            setFormDataChanged(false);

            const formData = article.getFormData();
            formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

            if (formData.publicationTime) {
                await api.article.postDelayedArticle(formData);
                addSuccess('Отложенная статья добавлена');
                navigate('/user/delayed-publications/articles');
            } else {
                const res = await api.article.postArticle(formData);
                addSuccess('Статья опубликована');
                dispatch(incrementUnreadCounter('unreadArticles'));
                navigate(`/projects/article/${res.id}`);
            }
        } catch (error) {
            addError('Не удалось опубликовать статью');
        } finally {
            setLoading(false);
        }
    };

    const addArticleDraft = async () => {
        article.commit();
        article.submit();

        setIsSubmit(true);

        if (!article.validateFields(['title', 'text'])) {
            return;
        }

        if (isUploading) {
            setQueuedAction('addArticleDraft');
            onBackgroundUpload();
            return;
        }

        setPreviewShow(false);
        setLoading(true);

        try {
            setFormDataChanged(false);

            const formData = article.getFormData();
            formData.articleBody = await UploadEditorImages(article.id ?? article.initId, formData.articleBody);

            await api.article.postArticleDraft(formData);

            addSuccess('Черновик статьи создан');
            navigate('/user/drafts/articles');
        } catch (error) {
            addError('Не удалось создать черновик статьи');
        } finally {
            setLoading(false);
        }
    };

    const _onPreview = () => {
        setFormDataChanged(false);
        article.commit();
        article.submit();

        setPreviewShow(true);
    };

    useEffect(() => {
        if (isSubmit && Object.values(article.errors).some(v => v)) {
            article.scrollToError();
            setIsSubmit(false);
        }

    }, [isSubmit, article.errors, article]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />

            {loading &&
            <Loading withOverlay={false} withRelativeOverlay={true}/>}

            <div className={classNames(cs.tool, {[cs.mobile]: platform === 'mobile'})}>
                <Button
                    label={'Отмена'}
                    disabled={loading}
                    onClick={cancel}
                    fullWidth={platform === 'mobile'}
                />
                <Button
                    label={'В черновик'}
                    disabled={loading}
                    onClick={addArticleDraft}
                    fullWidth={platform === 'mobile'}
                />
                <Button
                    label={'Предпросмотр'}
                    disabled={loading}
                    onClick={_onPreview}
                    fullWidth={platform === 'mobile'}
                />
                <Button
                    label={'Опубликовать'}
                    color={'green'}
                    disabled={loading}
                    onClick={addArticle}
                    fullWidth={platform === 'mobile'}
                />
            </div>

            <Preview show={previewShow} onDismiss={() => setPreviewShow(false)} onDraft={addArticleDraft}
                     onPublish={addArticle} article={article} type={'article'}/>
        </PrivatePage>
    );
};

AddArticlePage.defaultProps = {
    hasAnyAuthorities: []
};

AddArticlePage.propTypes = {
    article: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
    clearArticle: PropTypes.func.isRequired,
    hasAnyAuthorities: PropTypes.arrayOf(PropTypes.string)
};

export default hot(articleFormPage(AddArticlePage, 'Добавление статьи'));
