import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from '@reach/router';
import classNames from 'classnames';
import BackButton from 'components/back-button';
import { ChatInfo, ChatResendModal } from 'components/chat';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Input from 'uikit/input';
import Button from 'uikit/button';
import { DatePicker } from 'uikit/datetime';
import ButtonMenu from 'uikit/button-menu';
import api from 'api';
import cx from './chat-window.module.scss';
import { useEventListener } from 'lib/hooks';
import DOMPurify from 'dompurify';

const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
const emodjies = [
    '😀',
    '😃',
    '😄',
    '😁',
    '😆',
    '😅',
    '🤣',
    '😂',
    '🙂',
    '🙃',
    '😉',
    '😊',
    '😇',
    '🥰',
    '😍',
    '🤩',
    '😘',
    '😗',
    '😚',
    '😙',
    '😋',
    '😛',
    '😜',
    '🤪',
    '😝',
    '🤑',
    '🤗',
    '🤭',
    '🤫',
    '🤔',
    '🤐',
    '🤨',
    '😐',
    '😑',
    '😶',
    '😏',
    '😒',
    '🙄',
    '😬',
    '🤥',
    '😌',
    '😔',
    '😪',
    '🤤',
    '😴',
    '😷',
    '🤒',
    '🤕',
    '🤢',
    '🤮',
    '🤧',
    '🥵',
    '🥶',
    '😵',
    '🤯',
    '🤠',
    '🥳',
    '😎',
    '🤓',
    '🧐',
    '😕',
    '😟',
    '🙁',
    '😮',
    '😯',
    '😲',
    '😳',
    '🥺',
    '😦',
    '😧',
    '😨',
    '😰',
    '😥',
    '😢',
    '😭',
    '😱',
    '😖',
    '😣',
    '😞',
    '😓',
    '😩',
    '😫',
    '🥱',
    '😤',
    '😡',
    '😠',
    '🤬',
    '😈',
    '👿',
];

export default function ChatWindow({
    session,
    currentChat,
    users,
    chats,
    onMessage,
    onOpenChat,
    onUpdateChatGroup,
    onRemoveChatGroup,
    onRemoveDialog,
    isMobile,
}) {
    const navigate = useNavigate();

    const [isChatInfo, setIsChatInfo] = useState(false);
    const [message, setMessage] = useState('');

    const [isSearch, setIsSearch] = useState(false);
    const [search, setSearch] = useState('');

    const [searchItems, setSearchItems] = useState([]);
    const [searchItem, setSearchItem] = useState(null);

    const [searchMinDate, setSearchMinDate] = useState(null);
    const [searchDate, setSearchDate] = useState(null);

    const [selectedMessages, setSelectedMessages] = useState([]);

    const [editMessage, setEditMessage] = useState(null);
    const [replyMessage, setReplyMessage] = useState(null);

    const [isResendModal, setIsResendModal] = useState(false);
    const [isScrollTopButton, setIsScrollTopButton] = useState(false);

    const dialogMenu = [
        {
            title: 'Информация',
            icon: Icons.INFO,
            onClick: () => setIsChatInfo(true),
        },
        {
            title: 'Удалить чат',
            icon: Icons.TRASH,
            isShow: !!onRemoveDialog,
            onClick: () => onRemoveDialog(currentChat.id),
        },
    ];
    const chatMenu = [
        {
            title: 'Редактировать чат',
            icon: Icons.EDIT_PEN,
            isShow: !!onUpdateChatGroup,
            onClick: () => onUpdateChatGroup(currentChat.id),
        },
        {
            title: 'Информация',
            icon: Icons.INFO,
            onClick: () => setIsChatInfo(true),
        },
        {
            title: 'Удалить чат',
            icon: Icons.TRASH,
            isShow: !!onRemoveChatGroup,
            onClick: () => onRemoveChatGroup(currentChat.id),
        },
    ];

    const formatMessage = message => {
        let messageTemp = DOMPurify.sanitize(message);
        messageTemp = messageTemp.replace(
            // eslint-disable-next-line no-useless-escape
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
            match => `<a href="${match}" target="_blank" rel="noreferrer nofollor">` + match + '</a>'
        );
        emodjies.map(emodji => {
            messageTemp = messageTemp.replaceAll(emodji, '<i>' + emodji + '</i>');
            return emodji;
        });

        return messageTemp;
    };
    const onEmodji = emodji => {
        setMessage(message + emodji);
    };
    const onMessageSend = e => {
        if (e.key !== 'Enter') {
            return;
        }

        let sendMessage = DOMPurify.sanitize(message);

        if (replyMessage) {
            sendMessage += '{[<>JSON<>]}' + JSON.stringify(replyMessage);
        }

        onMessage(currentChat.id, sendMessage);
        onMessageReplyClose();

        setMessage('');
    };

    const scrollToMessage = (message, isDate = false) => {
        if (message === null) {
            return;
        }

        const element = document.getElementById((isDate ? 'date-' : '') + message.id);

        if (element == null) {
            return;
        }

        element.style = 'background: rgba(18, 128, 206, 0.15); border-radius: 5px;';
        setTimeout(() => {
            element.style = null;
        }, 3000);

        document.getElementById('chat-window').scrollTop = element.offsetTop - 200;
        setSearchItem(message);
    };
    const scrollByStep = direction => {
        if (searchItems.length < 2) {
            return;
        }

        const index = searchItems.indexOf(searchItem);

        if (direction && searchItems.length > index + 1) {
            scrollToMessage(searchItems[index + 1]);
        } else if (!direction && index !== 0) {
            scrollToMessage(searchItems[index - 1]);
        }
    };

    const onSearchClick = () => {
        setIsSearch(isSearch => !isSearch);

        if (!isSearch) {
            setTimeout(() => document.getElementById('chatWindowSearchInput').focus(), 1);
        }
    };
    const onSearch = e => {
        if (e === null) {
            setSearch('');
            setSearchItems([]);

            return;
        }

        if (e.keyCode !== 13) {
            return;
        }

        const searchData = currentChat.messages
            .filter(item => {
                if (!search || item.message.indexOf(search) === -1) {
                    return null;
                }

                return item;
            })
            .reverse();

        if (searchItems.length === searchData.length) {
            scrollByStep(true);
            return;
        }

        setSearchItems(searchData);
        scrollToMessage(searchData.length > 0 ? searchData[0] : null);
    };
    const onSearchClose = useCallback(() => {
        scrollToMessage(null);

        setIsSearch(false);
        setSearch('');

        setSearchItems([]);
        setSearchItem(null);

        setSearchMinDate(null);
        setSearchDate(null);
    }, []);
    const onSearchDate = date => {
        const messages = Object.assign([], currentChat.messages);
        const dateIndex = messages.findIndex(message => date.toDateString() === new Date(message.date).toDateString());

        if (dateIndex === -1) {
            return;
        }

        scrollToMessage(messages[dateIndex], true);
        setSearchDate(date);
    };

    const onMessageSelect = (message, isSingle = false) => {
        if (isSingle && selectedMessages.length === 0) {
            return;
        }

        const selected = Object.assign([], selectedMessages);
        const messageIndex = selected.indexOf(message);

        if (messageIndex === -1) {
            selected.push(message);
        } else {
            selected.splice(messageIndex, 1);
        }

        setSelectedMessages(selected);
    };
    const onMessageRemove = () => {
        for (let i = 0; i < selectedMessages.length; i++) {
            if (currentChat.type === 1) {
                api.chat.deleteChatMessage(selectedMessages[i].id);
            } else {
                api.chat.deleteDialogMessage(selectedMessages[i].id);
            }

            currentChat.messages.splice(currentChat.messages.indexOf(selectedMessages[i]), 1);
        }

        setSelectedMessages([]);
    };

    const onMessageEdit = () => {
        setEditMessage(selectedMessages[0]);
        setMessage(selectedMessages[0].message);

        setSelectedMessages([]);
    };
    const onMessageEditClose = () => {
        setEditMessage(null);
        setMessage('');
    };
    const onMessageUpdate = e => {
        if (e.key !== 'Enter') {
            return;
        }

        const messageIndex = currentChat.messages.indexOf(editMessage);

        currentChat.messages[messageIndex].message = DOMPurify.sanitize(message);
        currentChat.messages[messageIndex].isUpdate = true;

        api.chat.updateChatMessage(currentChat.messages[messageIndex]);

        setEditMessage(null);
        setMessage('');
    };

    const onMessageReply = () => {
        setReplyMessage(selectedMessages[0]);
        setSelectedMessages([]);
    };
    const onMessageReplyClose = () => {
        setReplyMessage(null);
    };

    const onMessageResend = () => {
        setIsResendModal(true);
    };
    const onMessageResendClose = () => {
        setSelectedMessages([]);
        setIsResendModal(false);
    };
    const onMessageResendAccept = chat => {
        for (let i = 0; i < selectedMessages.length; i++) {
            setTimeout(() => onMessage(chat.id, '{[<>JSON<>]}' + JSON.stringify(selectedMessages[i])), 1000 * i);
        }

        onMessageResendClose();
    };

    useEventListener(
        'scroll',
        e => {
            setIsScrollTopButton(e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight);
        },
        document.getElementById('chat-window')
    );
    useEffect(() => {
        onSearchClose();
        setSearchMinDate(currentChat.messages.length !== 0 ? currentChat.messages[0].date : new Date());
    }, [currentChat, onSearchClose]);

    return (
        <div className={cx.chatWindow}>
            {isResendModal && (
                <ChatResendModal chats={chats} users={users} onClose={onMessageResendClose} onAccept={onMessageResendAccept} />
            )}
            {isChatInfo && (
                <ChatInfo
                    chat={currentChat}
                    onClose={() => setIsChatInfo(false)}
                    onOpenChat={(id, type) => {
                        setIsChatInfo(false);
                        onOpenChat(id, type);
                    }}
                    onUpdateChatGroup={
                        onUpdateChatGroup
                            ? chatId => {
                                  setIsChatInfo(false);
                                  onUpdateChatGroup(chatId);
                              }
                            : null
                    }
                    onDeleteChatGroup={
                        onRemoveChatGroup
                            ? id => {
                                  setIsChatInfo(false);
                                  onRemoveChatGroup(id);
                              }
                            : null
                    }
                />
            )}
            <div className={cx.chatWindowHeader}>
                {isMobile && (
                    <div className={cx.chatWindowHeaderBackBtn}>
                        <BackButton onClick={() => navigate('/chat')} />
                    </div>
                )}
                <div className={cx.chatWindowHeaderTarget} onClick={() => setIsChatInfo(true)}>
                    <div className={cx.chatWindowHeaderTargetAvatar}>
                        {currentChat.image && <img src={currentChat.image} alt="" />}
                        {/*{currentChat.isOnline && <div className={cx.chatWindowHeaderTargetStatusOnline}/>}*/}
                        {/*{!currentChat.isOnline && <div className={cx.chatWindowHeaderTargetStatusOffline}/>}*/}
                    </div>
                    <div className={cx.chatWindowHeaderTargetContent}>
                        <p>{currentChat.title}</p>
                        {/*{currentChat.isOnline &&*/}
                        {/*<span className={cx.chatWindowHeaderTargetContentStatusOnline}>Онлайн</span>}*/}
                        {/*{!currentChat.isOnline &&*/}
                        {/*<span className={cx.chatWindowHeaderTargetContentStatusOffline}>Оффлайн</span>}*/}
                    </div>
                </div>
                <div className={cx.chatWindowHeaderMenu}>
                    <IconButton icon={<Icon type={Icons.SEARCH} />} onClick={onSearchClick} />
                    <div className={cx.chatWindowHeaderMenuCalendar}>
                        <IconButton
                            icon={<Icon type={Icons.CALENDAR} />}
                            onClick={() => document.getElementsByClassName('react-date-picker__button')[0].click()}
                        />
                        <DatePicker value={searchDate} onChange={onSearchDate} minDate={searchMinDate} />
                    </div>
                    <ButtonMenu
                        icon={<Icon type={Icons.MORE} />}
                        position="left"
                        width={15}
                        height={15}
                        items={currentChat.type === 1 ? chatMenu : dialogMenu}
                    />
                </div>
            </div>
            {isSearch && (
                <div className={cx.chatWindowSearch}>
                    <div className={cx.chatWindowSearchInput}>
                        <Icon
                            className={cx.chatWindowSearchInputSearch}
                            type={Icons.SEARCH}
                            width={16}
                            height={16}
                            onClick={() => document.getElementById('chatWindowSearchInput').focus()}
                        />
                        <Input
                            id="chatWindowSearchInput"
                            type="text"
                            value={search}
                            onChange={value => setSearch(value)}
                            onKeyDown={onSearch}
                        />
                        <div className={cx.chatWindowSearchInputDelimiter} />
                        <p>
                            {searchItems.indexOf(searchItem) + 1}/{searchItems.length}
                        </p>
                        <Icon
                            className={cx.chatWindowSearchInputDown}
                            type={Icons.ARROW_DOWN}
                            width={12}
                            height={12}
                            onClick={() => scrollByStep(false)}
                        />
                        <Icon
                            className={cx.chatWindowSearchInputUp}
                            type={Icons.ARROW_DOWN}
                            width={12}
                            height={12}
                            onClick={() => scrollByStep(true)}
                        />
                        <div className={cx.chatWindowSearchInputDelimiter} />
                        <Icon
                            className={cx.chatWindowSearchInputClose}
                            type={Icons.CLOSE_REMOVE_X}
                            width={20}
                            height={20}
                            onClick={() => onSearch(null)}
                        />
                    </div>
                    <Button type="normal" color="base" onClick={onSearchClose}>
                        Отмена
                    </Button>
                </div>
            )}
            {selectedMessages.length !== 0 && (
                <div className={cx.chatWindowSelected}>
                    <div className={cx.chatWindowSelectedContainer}>
                        {selectedMessages.length === 1 && (
                            <Button type="normal" color="base" onClick={onMessageReply}>
                                <Icon type={Icons.PLAIN} />
                                <span>Ответить</span>
                            </Button>
                        )}
                        <Button type="normal" color="base" onClick={onMessageResend}>
                            <Icon type={Icons.SHARE_ARROW} />
                            <span>Переслать</span>
                            <p>{selectedMessages.length}</p>
                        </Button>
                        {selectedMessages.length === 1 && (
                            <Button type="normal" color="base" onClick={onMessageEdit}>
                                <Icon type={Icons.EDIT_PEN} />
                                <span>Редактировать</span>
                            </Button>
                        )}
                        <Button type="normal" color="base" onClick={onMessageRemove}>
                            <Icon type={Icons.TRASH} />
                            <span>Удалить</span>
                            <p>{selectedMessages.length}</p>
                        </Button>
                    </div>
                    <Button type="normal" color="base" onClick={() => setSelectedMessages([])}>
                        Отмена
                    </Button>
                </div>
            )}
            <div id="chat-window" className={cx.chatWindowBody}>
                {currentChat.messages.length === 0 && (
                    <div className={cx.chatWindowBodyEmpty}>
                        <img src="/img/handwave.png" alt="Empty" />
                        <p>В этом чате еще нет сообщений</p>
                        <span>Напишите первое сообщение</span>
                    </div>
                )}
                {currentChat.messages &&
                    currentChat.messages.map((item, index) => {
                        let date = item.date.getDate();
                        date = date.toString().length === 1 ? '0' + date : date;

                        const month = item.date.getMonth();
                        const year = item.date.getFullYear();

                        let hours = item.date.getHours();
                        hours = hours.toString().length === 1 ? '0' + hours : hours;

                        let minutes = item.date.getMinutes();
                        minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;

                        return (
                            <div key={item.id} className={cx.chatWindowBodyItem}>
                                {item.isRead === false && (index === 0 || currentChat.messages[index - 1].isRead !== false) && (
                                    <p className={cx.chatWindowBodyItemRead}>Непрочитанные сообщения</p>
                                )}
                                {(index === 0 || item.date.toDateString() !== currentChat.messages[index - 1].date.toDateString()) && (
                                    <p id={'date-' + item.id} className={cx.chatWindowBodyItemDate}>
                                        {date} {months[month]}, {year}
                                    </p>
                                )}
                                <div
                                    id={item.id}
                                    onDoubleClick={() => onMessageSelect(item)}
                                    onClick={() => onMessageSelect(item, true)}
                                    className={classNames(
                                        cx.chatWindowBodyItemMessage,
                                        session.id === item.sender ? cx.chatWindowBodyItemMessageSelf : '',
                                        selectedMessages.indexOf(item) !== -1 ? cx.chatWindowBodyItemMessageSelected : ''
                                    )}
                                >
                                    <div className={cx.chatWindowBodyItemMessageSelector} onClick={() => onMessageSelect(item)}>
                                        {selectedMessages.indexOf(item) !== -1 && <Icon type={Icons.CHECK} />}
                                    </div>
                                    {currentChat.type === 1 && session.id !== item.sender && (
                                        <div className={cx.chatWindowBodyItemMessageAvatar}>
                                            {item.avatar && <img src={item.avatar} alt="" />}
                                        </div>
                                    )}
                                    <div className={cx.chatWindowBodyItemMessageContent}>
                                        {currentChat.type === 1 && session.id !== item.sender && (
                                            <h5>{item.firstName + ' ' + item.lastName}</h5>
                                        )}
                                        {item.replyMessage && (
                                            <div className={cx.chatWindowBodyItemMessageContentReply}>
                                                <h5>{item.replyMessage.firstName + ' ' + item.replyMessage.lastName}</h5>
                                                <p dangerouslySetInnerHTML={{ __html: formatMessage(item.replyMessage.message) }} />
                                            </div>
                                        )}
                                        <p dangerouslySetInnerHTML={{ __html: formatMessage(item.message) }} />
                                        {item.isUpdate && <span>изменено</span>}
                                    </div>
                                    <span>
                                        {hours}:{minutes}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
            </div>
            {onMessage && (
                <div className={cx.chatWindowFooter} onKeyDown={editMessage ? onMessageUpdate : onMessageSend}>
                    {(editMessage || replyMessage) && (
                        <div className={cx.chatWindowFooterMessage}>
                            <div
                                className={cx.chatWindowFooterMessageCross}
                                onClick={editMessage ? onMessageEditClose : onMessageReplyClose}
                            >
                                <Icon type={Icons.CROSS} width={9} height={9} />
                            </div>
                            <div className={cx.chatWindowFooterMessageEdit}>
                                <label>{editMessage ? 'Редактирование' : replyMessage['firstName'] + ' ' + replyMessage['lastName']}</label>
                                <p>{editMessage ? editMessage.message : replyMessage.message}</p>
                            </div>
                        </div>
                    )}
                    <div className={cx.chatWindowFooterSend}>
                        <IconButton icon={<Icon type={Icons.SMILE} width={20} height={20} />} />
                        <div className={cx.chatWindowFooterEmodji}>
                            <div className={cx.chatWindowFooterEmodjiItems}>
                                {emodjies.map(emodji => {
                                    return (
                                        <div key={emodji} className={cx.chatWindowFooterEmodjiItem} onClick={() => onEmodji(emodji)}>
                                            {emodji}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Input
                            className={cx.chatWindowFooterInput}
                            placeholder="Введите сообщение"
                            value={message}
                            onChange={message => setMessage(message)}
                        />
                        <IconButton
                            icon={<Icon type={Icons.PLAIN} />}
                            onClick={() => (editMessage ? onMessageUpdate({ key: 'Enter' }) : onMessageSend({ key: 'Enter' }))}
                        />
                    </div>
                </div>
            )}
            {isScrollTopButton && (
                <div className={cx.scrollTopBtn} onClick={() => (document.getElementById('content').scrollTop = 0)}>
                    <IconButton
                        icon={<Icon type={Icons.ARROW_FULL_DOWN} width={15} height={15} />}
                        onClick={() => scrollToMessage(currentChat.messages[currentChat.messages.length - 1])}
                    />
                </div>
            )}
        </div>
    );
}
