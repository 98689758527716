import {CustomBulletList} from './extension-bullet-list';
import {CustomOrderedList} from './extension-ordered-list';
import {MultiList} from './extension-multilist';
import {MultiListItem} from './extension-multilist-item';

export const ListExtensions = [
    MultiList,
    MultiListItem,
    CustomBulletList,
    CustomOrderedList,
];
