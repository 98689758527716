import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import { humanFileSize } from 'lib/helpers';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import Icon, { Icons } from 'uikit/icon';
import UploadModal from 'components/attachments/uploadModal';
import { FileViewDialog } from 'components/file/file-view-dialog';
import api from 'api';
import cs from './attachments.module.scss';
import { useUpload } from 'components/background-upload/upload-provider';
import Loading from 'uikit/loading';

const Attachments = ({ uploadsId, noAdd, noDelete, noDownload, onChange, className, files }) => {
    const onChangeRef = useRef(onChange);
    const filesRef = useRef(files);

    const { upload } = useUpload(uploadsId);

    const [dialog, setDialog] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [isViewFileModal, toggleViewFileModal] = useState(false);
    const [loadingFileId, setLoadingFileId] = useState(null);

    useEffect(() => {
        filesRef.current = files;
    }, [files]);

    useEffect(() => {
        onChangeRef.current = onChange;
    }, [onChange]);

    useEffect(() => {
        return () => {
            for (const f of filesRef.current.filter((f) => f.status === 'loading')) {
                f.removeCallbacks();
            }
        };
    }, []);

    const throwAway = (e, file) => {
        e.stopPropagation();
        const _files = [...files];
        _files.find((f) => f.fileId === file.fileId).status = 'deleted';
        onChange(_files);
    };

    const toggleUploadModal = () => {
        setDialog((isOpen) => !isOpen);
    };

    const _onChange = (e) => {
        const _onUpdate = async (file) => {
            const i = filesRef.current.findIndex((f) => f.id === file.id);
            onChangeRef.current([...filesRef.current.slice(0, i), file, ...filesRef.current.slice(i + 1)]);
        };

        toggleUploadModal();
        const newFiles = [];
        for (const f of e.target.files) {
            const u = upload({ file: f, onUpdate: _onUpdate });
            newFiles.push(u);
            u.start();
        }
        onChange([...files, ...newFiles]);
    };

    const attachFiles = (attachedFiles) => {
        onChange(
            attachedFiles.map((f) => ({
                status: 'done',
                fileId: f.id || f.fileId,
                serverData: f,
            }))
        );
    };

    const download = async (e, fileDesc) => {
        e.stopPropagation();
        setLoadingFileId(fileDesc.fileId);
        const resp = await api.file.download(fileDesc.fileId);
        const blob = new Blob([resp], { type: fileDesc.serverData.type });
        setLoadingFileId(null);

        saveAs(blob, fileDesc.serverData.name);
    };

    const fileSelectHandler = (e, values) => {
        e.stopPropagation();

        setSelectedFile(values);
        toggleViewFileModal(true);

        const articlePreviewModal = document.getElementById('articlePreviewModal');
        const newsPreviewModal = document.getElementById('newsPreviewModal');

        if (articlePreviewModal) {
            articlePreviewModal.style.overflow = 'hidden';
        }

        if (newsPreviewModal) {
            newsPreviewModal.style.overflow = 'hidden';
        }
    };
    const onFileViewModalClose = () => {
        toggleViewFileModal(false);

        const articlePreviewModal = document.getElementById('articlePreviewModal');
        const newsPreviewModal = document.getElementById('newsPreviewModal');

        if (articlePreviewModal) {
            articlePreviewModal.style.overflow = 'auto';
        }

        if (newsPreviewModal) {
            newsPreviewModal.style.overflow = 'auto';
        }
    };

    return (
        <>
            <div id={'attachmentsContainer'} className={classNames(cs.attachments, className)}>
                {!noAdd && (
                    <div id={'attachmentsAddFileButton'} className={cs.uploadLabel} onClick={toggleUploadModal}>
                        <Icon type={Icons.CLIP} />
                        <span>Прикрепить файл</span>
                    </div>
                )}
                <div className={cs.files}>
                    {files
                        .filter((file) => file?.status !== 'deleted')
                        .map((file, i) => {
                            return (
                                <div 
                                    key={i}
                                    className={classNames(cs.file, file.status === 'loading' && cs.fileDisabled)} 
                                    onClick={(e) => fileSelectHandler(e, file)}
                                >
                                    <Icon
                                        className={cs.fileIcon}
                                        width={24}
                                        height={24}
                                        type={FILES_TYPE_ICONS[file?.serverData?.type] || Icons.NONE_FILE}
                                    />
                                    <div>
                                        <div className={cs.name}>
                                            <div className={cs.text}>
                                                {file.status === 'loading' && (
                                                    <img src={'/icons/loading.svg'} width={16} height={16} alt="" />
                                                )}
                                                {file.name || file.file?.name || (file?.serverData?.name ?? '')}
                                            </div>
                                            {file.status === 'done' && (
                                                <div className={cs.buttons}>
                                                    {!noDelete && (
                                                        <Icon
                                                            id={'attachmentsDeleteIcon' + i}
                                                            className={cs.icon}
                                                            width={20}
                                                            height={20}
                                                            type={Icons.TRASH}
                                                            tooltip={'Удалить'}
                                                            onClick={(e) => throwAway(e, file)}
                                                        />
                                                    )}
                                                    {!noDownload && (
                                                        <div style={{ position: 'relative' }}>
                                                            {loadingFileId === file.fileId ? (
                                                                <Loading size={20} withOverlay={false} withRelativeOverlay={false} />
                                                            ) : (
                                                                <Icon
                                                                    id={'attachmentsDownloadIcon' + i}
                                                                    className={cs.icon}
                                                                    width={20}
                                                                    height={20}
                                                                    type={Icons.DOWNLOAD}
                                                                    tooltip={'Скачать'}
                                                                    onClick={(e) => download(e, file)}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className={cs.size}>
                                            {file.status === 'done' && (
                                                <>
                                                    {FILES_TYPE_SHORT_NAME[file?.serverData?.type]}
                                                    &nbsp;—&nbsp;
                                                    {humanFileSize(file?.serverData?.size, true)}
                                                </>
                                            )}
                                            {file.status === 'loading' && <span>{file?.uploadedPercent} %</span>}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            {dialog === true && (
                <UploadModal
                    selectedFiles={files}
                    onCloseHandler={toggleUploadModal}
                    onAttachHandler={attachFiles}
                    onSubmitHandler={_onChange}
                />
            )}
            {isViewFileModal && (
                <FileViewDialog
                    file={selectedFile}
                    isNoActions={true}
                    handleClose={onFileViewModalClose}
                />
            )}
        </>
    );
};

Attachments.defaultProps = {
    onChange: () => {},
    noAdd: false,
    noDelete: false,
    noDownload: false,
    className: '',
    files: [],
    uploadsId: 'attachment',
};

Attachments.propTypes = {
    onChange: PropTypes.func,
    noAdd: PropTypes.bool,
    noDelete: PropTypes.bool,
    noDownload: PropTypes.bool,
    className: PropTypes.any,
    files: PropTypes.array,
    uploadsId: PropTypes.string,
};

export default Attachments;
