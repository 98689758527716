import { NodeViewWrapper } from '@tiptap/react';

import ResizableImageComponent from './ResizableImageComponent';
import classNames from 'classnames';

const ResizableImageNodeView = props => {
    return (
        <NodeViewWrapper
            as="span"
            className={classNames('js-image-node-wrapper media-node-view not-prose', {
                [`align-${props.node.attrs['data-align']}`]: props.node.attrs['data-align'],
            })}
        >
            <ResizableImageComponent {...props} />
        </NodeViewWrapper>
    );
};

export default ResizableImageNodeView;
