import { Extension } from '@tiptap/core';

export const LineHeight = Extension.create({
    name: 'lineHeight',

    addCommands() {
        return {
            setLineHeight:
                lineHeight =>
                ({ commands }) => {
                    return commands.updateAttributes('paragraph', {
                        lineHeight,
                    });
                },

            unsetLineHeight:
                () =>
                ({ commands }) => {
                    return commands.updateAttributes('paragraph', {
                        lineHeight: null,
                    });
                },
        };
    },
});
