import { createSlice } from '@reduxjs/toolkit';

export const defaultParentObject = {
    parentProjectId: null,
    parentSectionId: null,
    visualPath: null,
    treeInvalid: undefined,
};

export const treeSlice = createSlice({
    name: 'tree',
    initialState: {
        invalidProjects: false,
        parent: {
            ...defaultParentObject
        }
    },
    reducers: {

        setParent: (state, action) => {
            state.parent = action.payload;
        },

        clearParent: (state) => {
            state.parent = { ...defaultParentObject };
        },

        setTreeInvalid: (state, action) => {
            state.treeInvalid = action.payload;
        },

        invalidateProjects: (state, action) => {
            if (action.payload === undefined) {
                state.invalidProjects = true;
            } else {
                state.invalidProjects = action.payload;
            }

        }
    }
});

export const { setParent, clearParent, setTreeInvalid, invalidateProjects } = treeSlice.actions;

export const selectParent = state => state.tree.parent;
export const selectTreeInvalid = state => state.tree.treeInvalid;
export const selectInvalidProjects = state => state.tree.invalidProjects;
export default treeSlice.reducer;
