import React, { useState, useMemo, useEffect } from 'react';
import { ReportsComponent } from 'components/data-components/reports-component';
import api from 'api';
import { popularityTypes, sortTypesOptions } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';
import { DateTime } from 'luxon';
import { months } from 'lib/helpers/formatDateTime';

const StatisticReportsDocumentsEvaluation = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [news, setNews] = useState([]);
    const [charts, setCharts] = useState([]);
    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);

    const getArticlesInProjectsReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getDocumentEvaluation({ page, size, filters, cancelToken: ReportService.cancelToken.token });

            setCharts([
                {
                    chartTypes: ['bar'],
                    labels: res.charts.map((chart) => {
                        const searchDate = new Date(chart.date);
                        const month = searchDate.getMonth();

                        return DateTime.fromISO(searchDate.toISOString())
                            .setLocale('ru')
                            .toFormat('dd .LL., yyyy, hh:MM')
                            .replace(/\.\d\d\./, months[month]);
                    }),
                    datasets: [
                        { label: 'Лайки', data: res.charts.map((chart) => chart.like) },
                        { label: 'Дизлайки', data: res.charts.map((chart) => chart.dislike) },
                    ],
                },
            ]);
            setReports(
                res.resources.content.map((i) => {
                    const timestamp = new Date(i.reactionDate);
                    const month = timestamp.getMonth();

                    return {
                        ...i,
                        reactionDate: DateTime.fromISO(new Date(i.reactionDate).toISOString())
                            .setLocale('ru')
                            .toFormat('dd .LL., yyyy, hh:MM')
                            .replace(/\.\d\d\./, months[month]),
                    };
                })
            );
            setReportsTotal(res.resources.totalElements);

            if (!res.resources.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('articles', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Участник',
                accessor: 'fullName',
            },
            {
                Header: 'Логин',
                accessor: 'login',
            },
            {
                Header: 'Оценка',
                accessor: 'rating',
                Cell: (data) => {
                    return data.row.original.rating === 'LIKE' ? 'Лайк' : 'Дизлайк';
                },
            },
            {
                Header: 'Дата реакции',
                accessor: 'reactionDate',
                Cell: (data) => ReportService.defaultDateColumn('reactionDate')(data),
            },
        ];

        setSortByOptions(
            data
                .filter((col) => col.accessor && col.isVisible !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }))
        );

        return data;
    }, []);

    const filtersTmp = useMemo(() => {
        return [
            {
                label: 'На момент даты',
                fields: [
                    {
                        name: 'timestamp',
                        type: 'date',
                        default: new Date().toISOString(),
                    },
                ],
                width: '100%',
            },
            {
                label: 'Выберите документы',
                fields: [
                    {
                        name: 'resourcesUuids',
                        type: 'project',
                        withSections: true,
                        withArticles: true,
                        checkArticleExists: true,
                        default: null,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Новости',
                fields: [
                    {
                        name: 'newsUuids',
                        type: 'select',
                        isMulti: false,
                        options: news,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Участник',
                fields: [
                    {
                        name: 'users',
                        type: 'user-select',
                        default: [],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Тип оценки',
                fields: [
                    {
                        name: 'createTime.greaterOrEqualThan',
                        type: 'select',
                        options: popularityTypes,
                        default: popularityTypes && popularityTypes[0],
                    },
                ],
                width: '50%',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: sortByOptions && sortByOptions[0],
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions, news]);

    useEffect(() => {
        ReportService.init('ARTICLES_IN_PROJECTS');

        return () => {
            ReportService.destroy();
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const res = await ReportService.getNews();
            setNews(res);
        };

        fetchData();
    }, []);
    
    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            charts={charts}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getArticlesInProjectsReports}
        />
    );
};

export default StatisticReportsDocumentsEvaluation;
