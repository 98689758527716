import React from 'react';
import { ChatMessage } from './chat-message';

import cx from './chat-message-list.module.scss';

export const ChatMessageList = ({ messages }) => {
    return (
        <div id="chat-window" className={cx.chatWindowBody}>
            {messages.map((message, idx) => {
                const isNewDay = idx === 0 || message.date.toDateString() !== messages[idx - 1].date.toDateString();

                return (
                    <React.Fragment key={message.id}>
                        {isNewDay && (
                            <p id={'date-' + message.id} className={cx.chatWindowBodyDate}>
                                {message.dayDate}
                            </p>
                        )}

                        <ChatMessage message={message} />
                    </React.Fragment>
                );
            })}
        </div>
    );
};
