import React, { useState } from 'react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';
import { BubbleMenu } from '@tiptap/react';

import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import { DiagramModal } from '../Menu/MenuButtonDiagram';
import cx from '../../editor.module.scss';

export const BubbleMenuDiagram = ({ setCurrentMenu }) => {
    const editor = useEditorContext();
    const [isEdit, setIsEdit] = useState(false);
    const [diagramData, setDiagramData] = useState({ url: '', width: '500', height: '500' });

    const toggleEdit = () => {
        if (isEdit) {
            setIsEdit(false);
        } else {
            const { src: url, width, height, layout } = editor?.getAttributes('remote-diagram');
            setDiagramData({ url, width, height, layout });
            setIsEdit(true);
        }
    };

    const handleSubmit = (url, width, height) => {
        editor.chain().focus().deleteSelection().run();
        editor?.chain().focus().setIframe({ src: url, width, height }).run();
        editor?.chain().focus().changeLayout({ align: diagramData?.layout }).run();
        setIsEdit(false);
    };

    return (
        <BubbleMenu
            tippyOptions={{
                placement: 'bottom',
                arrow: false,
                getReferenceClientRect: () => {
                    const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                        return node.type.name === 'remote-diagram';
                    });

                    if (nearestParent) {
                        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                        if (wrapperDomNode) {
                            return wrapperDomNode.getBoundingClientRect();
                        }
                    }
                    const { ranges } = editor?.state.selection;
                    const from = Math.min(...ranges.map(range => range.$from.pos));
                    const to = Math.max(...ranges.map(range => range.$to.pos));
                    return posToDOMRect(editor?.view, from, to);
                },
            }}
            editor={editor}
            shouldShow={({ editor }) => editor.isActive('remote-diagram')}
        >
            <div className={cx.bubbleMenu}>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconBubbleAlignLeft}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeLayout({ align: 'left' }).run();
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconBubbleAlignCenter}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeLayout({ align: 'center' }).run();
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconBubbleAlignRight}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeLayout({ align: 'right' }).run();
                    }}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconEdit}
                    onClick={e => {
                        setCurrentMenu(undefined);
                        toggleEdit(e);
                    }}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconTrash}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor.chain().focus().deleteSelection().run();
                    }}
                />
                {isEdit && <DiagramModal data={diagramData} show={isEdit} onClose={toggleEdit} onSubmit={handleSubmit} />}
            </div>
        </BubbleMenu>
    );
};
