import ApiClient from './api-axios';

export default class GroupsApi extends ApiClient {
    getGroups = async (page = 0, size = 20, sort = '', search = '') => {
        return await this.get('', { page, size, sort, ...search }, true);
    };

    createGroup = async (params) => {
        return await this.post('', { ...params });
    };

    getGroupById = async (id) => {
        return await this.get('/' + id);
    };

    editGroup = async (params) => {
        return await this.post('/edit', { ...params });
    };

    deleteGroup = async (id) => {
        return await this.deleteRequest('/' + id);
    };
}