import React, { useRef } from 'react';
import Tippy from '@tippyjs/react';
import { Scrollbar } from 'react-scrollbars-custom';

import { useEditorContext } from '../../context';
import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import cx from '../../editor.module.scss';

const DEFAULT_FONT_SIZE_SELECT_OPTIONS = [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 60, 72, 96];

export const MenuFontSize = ({ mode, currentMenu, setCurrentMenu }) => {
    const ref = useRef(null);
    const editor = useEditorContext();

    const getFontSize = () => {
        return parseInt(editor?.getAttributes('textStyle')?.fontSize) || 12;
    };
    const setFontSize = size => {
        editor
            ?.chain()
            .focus()
            .setFontSize(size + 'pt')
            .run();
    };

    const decreaseSize = () => {
        const fontSizeIndex = DEFAULT_FONT_SIZE_SELECT_OPTIONS.findIndex(i => i === getFontSize());
        const prevFontSize = DEFAULT_FONT_SIZE_SELECT_OPTIONS[fontSizeIndex - 1] || getFontSize();

        if (prevFontSize === 12) {
            editor?.chain().focus().unsetFontSize().run();
        } else {
            editor
                ?.chain()
                .focus()
                .setFontSize(prevFontSize + 'pt')
                .run();
        }
    };
    const increaseSize = () => {
        const fontSizeIndex = DEFAULT_FONT_SIZE_SELECT_OPTIONS.findIndex(i => i === getFontSize());
        const nextFontSize = DEFAULT_FONT_SIZE_SELECT_OPTIONS[fontSizeIndex + 1] || getFontSize();

        if (nextFontSize === 12) {
            editor?.chain().focus().unsetFontSize().run();
        } else {
            editor
                ?.chain()
                .focus()
                .setFontSize(nextFontSize + 'pt')
                .run();
        }
    };

    return (
        <Tippy content="Размер шрифта" disabled={mode !== 'DESKTOP'}>
            <div className={cx.fontSize}>
                <Icon type={Icons.MINUS} width={13} height={13} color="blue" onClick={decreaseSize} />
                <Menu
                    mode={mode}
                    name="font-size"
                    ref={ref}
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    placement="bottom"
                    label={getFontSize() || 12}
                    menuContentStyles={{ overflow: 'initial', padding: 0, maxHeight: 'initial' }}
                >
                    <Scrollbar noScrollX style={{ width: 58, height: 423, display: 'flex', justifyContent: 'center' }}>
                        {DEFAULT_FONT_SIZE_SELECT_OPTIONS.map(size => {
                            return (
                                <MenuItem
                                    key={size}
                                    selected={size === getFontSize()}
                                    styles={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: 40,
                                        height: 33,
                                        marginLeft: 9,
                                        marginRight: 9,
                                        padding: 0,
                                    }}
                                    label={onClose => (
                                        <span
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                            onClick={onClose}
                                        >
                                            {size}
                                        </span>
                                    )}
                                    onClick={() => setFontSize(size)}
                                />
                            );
                        })}
                    </Scrollbar>
                </Menu>
                <Icon type={Icons.PLUS_ADD} width={13} height={13} color="blue" onClick={increaseSize} />
            </div>
        </Tippy>
    );
};
