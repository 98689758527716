import axios from 'axios';
import Cookies from 'js-cookie';
import { navigate } from '@reach/router';
import { ApiError } from 'lib/errors';

export default class ApiAxios {
    logoutUrl = undefined;

    constructor({ prefix } = {}) {
        this.prefix = prefix;
    }

    axios() {
        return axios;
    }

    get(url, query = {}, allResponse = false, responseType = '', headers, cancelToken) {
        return this.request({ method: 'get', url, query, responseType, headers, cancelToken }, allResponse);
    }

    put(url, data = {}) {
        return this.request({ method: 'put', url, data });
    }

    patch(url, data = {}) {
        return this.request({ method: 'patch', url, data });
    }

    post(url, data = {}, allResponse = false, responseType = '', headers, cancelToken) {
        return this.request({ method: 'post', url, data, responseType, headers, cancelToken }, allResponse);
    }

    deleteRequest(url) {
        return this.request({ method: 'delete', url });
    }

    upload(url, formData, onUploadProgress, cancelToken) {
        return this.request({
            method: 'post', url, data: formData, onUploadProgress, cancelToken, headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    getHeaders() {
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Timezone': (new Date()).getTimezoneOffset()
        };

        const xsrfToken = Cookies.get('XSRF-TOKEN');

        if (xsrfToken) {
            headers['X-XSRF-TOKEN'] = xsrfToken;
        }

        return headers;
    }

    request({ url, method, query = {}, data = {}, responseType = '', headers = {}, onUploadProgress, cancelToken }, allResponse = false) {
        const config = {
            baseURL: this.prefix,
            url,
            method,
            headers: { ...this.getHeaders(), ...headers },
            data,
            params: query,
            responseType,
            validateStatus: function(status) {
                return status <= 500;
            },
            onUploadProgress,
            cancelToken
        };

        return axios(config)
            .then(res => {
                if (res.status >= 400) {
                    throw new ApiError(res.data, res.status);
                }

                // Check response null values array function;
                const proceedArray = (array) => {
                    array.map(p => {
                        if (Object.prototype.toString.call(p) === '[object Array]') {
                            proceedArray(p);
                        } else if (Object.prototype.toString.call(p) === '[object Object]') {
                            proceedObject(p);
                        }

                        return p;
                    });
                };

                // Check response null values object function;
                const proceedObject = (object) => {
                    Object.keys(object).map(p => {
                        if (Object.prototype.toString.call(object[p]) === '[object Array]') {
                            proceedArray(object[p]);
                        } else if (Object.prototype.toString.call(object[p]) === '[object Object]') {
                            proceedObject([p]);
                        } else if (object[p] === null && ['author', 'user'].includes(p)) {
                            object[p] = {};
                        }

                        return p;
                    });
                };

                // Check response null values;
                if (Object.prototype.toString.call(res.data) === '[object Array]') {
                    proceedArray(res.data);
                } else if (Object.prototype.toString.call(res.data) === '[object Object]') {
                    proceedObject(res.data);
                }

                if (allResponse) {
                    return res;
                } else {
                    return res.data;
                }
            }).catch(e => {
                if (axios.isCancel(e)) {
                    return;
                }

                let error;

                try {
                    error = e.name === 'ApiError' ? e : new ApiError(e.response.data, e.response.status);
                } catch (e) {
                    error = new ApiError();
                }

                if (e.name === 'Error' || (e.responseCode && e.responseCode === 401 && config.url !== 'roles/info')) {
                    console.log('401 Redirect', ApiAxios.logoutUrl);

                    if (window.location.pathname !== '/login') {
                        if (process.env.NODE_ENV === 'development') {
                            document.location.replace('/login');
                        } else {
                            if (ApiAxios.logoutUrl) {
                                document.location.replace(ApiAxios.logoutUrl);
                            } else {
                                document.location.reload();
                            }
                        }
                    }
                }

                if (e.responseCode && e.responseCode === 423) {
                    console.log('423 Redirect');

                    if (e.errorData.error === 'NO_LICENSE') {
                        navigate('/login-license');
                    } else {
                        navigate('/multiply');
                    }
                }

                throw error;
            });
    }
}
