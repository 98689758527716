import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { TRAINING_RATING_SCALES } from 'lib/config/constant';
import Button from 'uikit/button';
import Icon, { Icons } from 'uikit/icon';
import api from 'api';
import cx from './training-test-start.module.scss';

export default function TrainingTestStart({ id }) {
    const navigate = useNavigate();
    const [test, setTest] = useState(null);

    const onStartTest = () => {
        let savedTest = { id: test['id'], start: Date.now(), end: null, question: 1, questions: [] };
        let questions = test['questions'];

        if (test['isMixQuestions']) {
            questions = test['questions']
                .map(question => ({ question, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ question }) => question);
        }

        if (test['isMixAnswers']) {
            for (let i = 0; i < questions.length; i++) {
                if (questions[i]['type'] === 2 || questions[i]['type'] === 3) {
                    continue;
                }

                questions[i]['answers'] = questions[i]['answers']
                    .map(answer => ({ answer, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ answer }) => answer);
            }
        }

        for (let i = 0; i < questions.length; i++) {
            savedTest['questions'][i] = {
                id: questions[i]['id'],
                type: questions[i]['type'],
                start: null,
                answers: questions[i]['answers'],
                answer: null
            };
        }

        savedTest['questions'][0]['start'] = Date.now();
        localStorage.setItem('test', JSON.stringify(savedTest));

        navigate('/training/test/' + id + '/process/1');
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await api.training.getTest(id);
            setTest(response);

            const savedTest = JSON.parse(localStorage.getItem('test'));

            if (!savedTest) {
                return;
            }

            if (response['testTime'] === 0 || savedTest['start'] + response['testTime'] * 60000 > Date.now()) {
                navigate('/training/test/' + savedTest['id'] + '/process/' + savedTest['question']);
            } else {
                localStorage.removeItem('test');
            }
        };
        fetchData();
    }, [navigate, id]);

    if (!test) {
        return null;
    }

    return (
        <div className={cx.trainingTestStart}>
            <img src={test['image'] ? test['image'] : '/img/article-card-cover.jpg'} alt={test['title']}/>
            <h3>{test['title']}</h3>
            <ul>
                <li>
                    <span>Вопросы:</span>
                    <b>{test['questions'].length}</b>
                </li>
                {test['testTime'] > 0 &&
                <li>
                    <span>Время теста:</span>
                    <b>{test['testTime']} мин.</b>
                </li>}
                {/*<li>*/}
                {/*    <span>Попыток осталось:</span>*/}
                {/*    <b>{test['tryCount'] ? test['tryCount'] : 0}</b>*/}
                {/*</li>*/}
                <li>
                    <span>Оценивание:</span>
                    <b>{TRAINING_RATING_SCALES.find(p => p.value === test['gradingType'])['label']}</b>
                </li>
            </ul>
            <div className={cx.trainingTestStartDelimiter}/>
            <p>{test['description']}</p>
            <div className={cx.trainingTestStartButtons}>
                <Button color="red" onClick={() => navigate('/training')}>Выйти из теста</Button>
                <Button color="green" onClick={() => onStartTest()}>
                    <Icon type={Icons.PLAY_FILL} width={18} height={18}/>
                    <span>Начать тест</span>
                </Button>
            </div>
        </div>
    );
};