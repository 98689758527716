import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

function createRect(rect, pos) {
    if (rect == null) {
        return null;
    }

    let newRect = {
        left: rect.left,
        top: rect.top,
        width: rect.width,
        height: rect.height,
        bottom: 0,
        right: 0,
    };

    if (pos === 'absolute') {
        const editorContent = document.getElementById('editor');
        const editorContentRect = editorContent.getBoundingClientRect();

        newRect.left = rect.left - editorContentRect.left;
        newRect.top = rect.top - editorContentRect.top + editorContent.scrollTop;
    }

    newRect.bottom = newRect.top + newRect.height;
    newRect.right = newRect.left + newRect.width;

    return newRect;
}

function absoluteRect(node) {
    return createRect(node.getBoundingClientRect(), 'absolute');
}

function nodeDOMAtCoords(coords) {
    return document.elementsFromPoint(coords.x, coords.y).find(elem => {
        return elem.getAttribute('data-tooltip');
    });
}

function TooltipHandle() {
    let tooltipElement = null;
    let contentWrapper = null;

    const handleMouseMove = event => {
        const node = nodeDOMAtCoords({
            x: event.clientX,
            y: event.clientY,
        });
        if (!(node instanceof Element)) {
            hideTooltipHandle();
            return;
        }

        const rect = absoluteRect(node);

        if (!tooltipElement) {
            return;
        }

        const tooltipLeft = tooltipElement.offsetWidth + rect.left - 4 > contentWrapper.offsetWidth;

        if (!tooltipLeft) {
            tooltipElement.classList.remove('right');
            tooltipElement.classList.add('left');
            tooltipElement.style.left = `${rect.left - 4}px`;
        } else {
            tooltipElement.classList.remove('left');
            tooltipElement.classList.add('right');
            tooltipElement.style.left = `${rect.left - tooltipElement.offsetWidth + rect.width + 4}px`;
        }

        tooltipElement.style.top = `${rect.top - 10 - tooltipElement?.offsetHeight}px`;
        tooltipElement.innerText = node.getAttribute('data-tooltip');

        showTooltipHandle();
    };

    function hideTooltipHandle() {
        if (tooltipElement) {
            tooltipElement.classList.add('hidden');
        }
    }

    function showTooltipHandle() {
        if (tooltipElement) {
            tooltipElement.classList.remove('hidden');
        }
    }

    return new Plugin({
        view: () => {
            tooltipElement = document.createElement('div');
            tooltipElement.classList.add('editor-tooltip');

            contentWrapper = document?.getElementById('editor');
            contentWrapper?.appendChild(tooltipElement);

            contentWrapper.addEventListener('mousemove', handleMouseMove);
            hideTooltipHandle();

            return {
                destroy: () => {
                    contentWrapper.removeEventListener('mousemove', handleMouseMove);

                    tooltipElement?.remove?.();
                    tooltipElement = null;
                },
            };
        },
    });
}

const Tooltip = Extension.create({
    name: 'tooltip',

    addProseMirrorPlugins() {
        return [TooltipHandle()];
    },
});

export default Tooltip;
