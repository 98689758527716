import React, { useState, useCallback, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { v4 } from 'uuid';
import Icon, { Icons } from 'uikit/icon';
import { Select } from 'uikit/select';
import { ContentWrapper } from 'containers/content-wrapper';
import { ChatHeader } from 'components/chat/ChatWindow/chat-header';
import { ChatMessageList } from 'components/chat/ChatWindow/chat-message-list';
import { ChatInputMessage } from 'components/chat/ChatWindow/chat-input-message';
import { useMessage } from 'lib/hooks';
import api from 'api/index';
import cx from './chat-bot.module.scss';
import Option from 'uikit/select/components/Option';

export const ChatBot = () => {
    const { addError } = useMessage();

    const chatRef = useRef(null);

    const [messages, setMessages] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const onMessage = async message => {
        setSubmitting(true);
        try {
            let dt = DateTime.fromJSDate(new Date()).setLocale('ru');
            const dayDate = dt.toFormat('dd MMMM, yyyy');
            const messageTime = dt.toFormat('HH:mm');

            setMessages(prev => {
                return [
                    ...prev,
                    {
                        id: v4(),
                        message,
                        selfMessage: true,
                        date: new Date(),
                        dayDate,
                        messageTime,
                    },
                ];
            });

            if (message && selectedProject?.value) {
                const chatResponse = await api.chatBot.message({ message, projectId: selectedProject.value });

                setSubmitting(false);
                chatResponse?.result &&
                    setMessages(prev => [
                        ...prev,
                        { id: v4(), message: String(chatResponse.result).trim(), date: new Date(), dayDate, messageTime },
                    ]);
            }
        } catch (error) {
            addError('Произошла ошибка');
            throw new Error(error);
        } finally {
            setTimeout(() => {
                setSubmitting(false);
            }, 1000);
        }
    };
    const loadProjects = useCallback(async (search, _loadedOptions, { page }) => {
        let options = [];
        const projectResponse = await api.project.getProjects(page, 20, '', search);

        options = projectResponse.content.map(project => ({ label: project.title, value: project.id }));

        return {
            options: options,
            hasMore: page < projectResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    }, []);

    const renderProjectSelect = () => {
        return (
            <div className={cx.projectListWrapper}>
                <Select
                    defaultOptions
                    loadOptions={loadProjects}
                    value={selectedProject}
                    onChange={setSelectedProject}
                    additional={{ page: 0 }}
                    isSearchable={true}
                    components={{ Option }}
                    paginate
                />
            </div>
        );
    };
    const renderEmpty = () => {
        return (
            <div className={cx.emptyMessage}>
                <Icon type={Icons.CHAT_BOT_COLORED} width={56} height={56} />
                <div className={cx.title}>Чем я могу помочь?</div>
            </div>
        );
    };

    useEffect(() => {
        const loadDefaultProject = async () => {
            try {
                const projectResponse = await api.project.getProjects(0, 1);
                const project = projectResponse.content.find(project => project.id && project.title);

                if (project) {
                    setSelectedProject({ label: project.title, value: project.id });
                }
            } catch (error) {}
        };

        loadDefaultProject();
    }, []);

    useEffect(() => {
        if (messages.length) {
            chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
        }
    }, [messages]);

    return (
        <ContentWrapper>
            <div className={cx.chatWrapper}>
                <ChatHeader
                    chatTitle="AI-помощник"
                    logoIcon={Icons.CHAT_LOGO}
                    logoBgColor={cx.logoWrapper}
                    renderRightSlot={renderProjectSelect}
                />

                <div ref={chatRef} className={cx.content}>
                    {messages.length ? <ChatMessageList messages={messages} /> : null}
                    {!messages.length ? <div className={cx.emptyMessage}>{renderEmpty()}</div> : null}

                    {submitting ? (
                        <div className={cx.pending}>
                            <Icon type={Icons.LOADING_DOTS} width={100} height={15} />
                        </div>
                    ) : null}
                </div>
                <ChatInputMessage submitting={submitting} onSubmit={onMessage} />
            </div>
        </ContentWrapper>
    );
};
