import { isTableSection } from './isTableSection';

export function getRow(table, row) {
    let rPos = 0;
    let prevSectionsRows = 0;
    let sectionIndex = -1;
    for (let tc = 0; tc < table.childCount; tc++) {
        const section = table.child(tc);
        if (isTableSection(section)) {
            sectionIndex++;
            const sectionRows = section.childCount;
            if (sectionRows > 0) {
                if (prevSectionsRows + sectionRows <= row) {
                    if (tc === table.childCount - 1) {
                        return {
                            node: null,
                            pos: rPos + section.nodeSize - 1,
                            section: sectionIndex,
                        };
                    }
                    rPos += section.nodeSize;
                    prevSectionsRows += sectionRows;
                } else {
                    rPos++;
                    let r = 0;
                    while (r < sectionRows) {
                        if (prevSectionsRows + r === row) break;
                        rPos += section.child(r).nodeSize;
                        r++;
                    }
                    if (r === sectionRows) rPos++;
                    return {
                        node: r >= sectionRows ? null : section.child(r),
                        pos: rPos,
                        section: sectionIndex,
                    };
                }
            }
        } else {
            rPos += section.nodeSize;
        }
    }
    return { node: null, pos: rPos, section: sectionIndex };
}
