import ApiClient from './api-axios';

export default class StatsApi extends ApiClient {
    getUsersPerDay = async (cancelToken) => {
        return await this.get('kms/api/v1/stats/users-per-day', {}, false, '', {}, cancelToken);
    };

    getUsersPerHourOnline = async (cancelToken) => {
        return await this.get('logging/api/stats/users-per-hour/online', {}, false, '', {}, cancelToken);
    };

    getRolesCount = async (cancelToken) => {
        return await this.get('kms/api/v1/stats/roles', {}, false, '', {}, cancelToken);
    };

    getRolesCountOnline = async (cancelToken) => {
        return await this.get('logging/api/stats/roles/online', {}, false, '', {}, cancelToken);
    };

    getUsersCountOfEachOffice = async (cancelToken) => {
        return await this.get('kms/api/v1/stats/office/users', {}, false, '', {}, cancelToken);
    };

    getEventsCounts = async (cancelToken) => {
        return await this.get('logging/api/stats/events', {}, false, '', {}, cancelToken);
    };

    getEventsPerOffice = async (cancelToken) => {
        return await this.get('logging/api/stats/events-per-office', {}, false, '', {}, cancelToken);
    };

    getEventsPerProject = async (cancelToken) => {
        return await this.get('logging/api/stats/events-per-project', {}, false, '', {}, cancelToken);
    };

    getEventsToday = async (cancelToken) => {
        return await this.get('logging/api/stats/events/today', {}, false, '', {}, cancelToken);
    };

    getTopActiveUsers = async (cancelToken) => {
        return await this.get('logging/api/stats/events/users?top=10', {}, false, '', {}, cancelToken);
    };

    getTopSearchedRequests = async (cancelToken) => {
        return await this.get('logging/api/stats/searches?top=10', {}, false, '', {}, cancelToken);
    };

    getTopArticles = async (status, cancelToken) => {
        return await this.get(`logging/api/stats/articles?top=10&status=${status}`, {}, false, '', {}, cancelToken);
    };

    getGeneralOfficesActivities = async (period, cancelToken) => {
        return await this.get(`offices?period=${period}`, {}, false, '', {}, cancelToken);
    };

    getTopProjectByArticles = async (cancelToken) => {
        return await this.get('logging/api/stats/projects-by-articles', {}, false, '', {}, cancelToken);
    };

    getTopProjectByNews = async (cancelToken) => {
        return await this.get('logging/api/stats/projects-by-news', {}, false, '', {}, cancelToken);
    };

    getNewsPerDay = async (cancelToken) => {
        return await this.get('kms/api/v1/stats/news-per-day', {}, false, '', {}, cancelToken);
    };

    getArticlesPerDay = async (cancelToken) => {
        return await this.get('kms/api/v1/stats/articles-per-day', {}, false, '', {}, cancelToken);
    };

    getTopSearchedRequestsToday = async (cancelToken) => {
        return await this.get('logging/api/stats/searches/today?top=10', {}, false, '', {}, cancelToken);
    };

    getSearchesRequestsPerDay = async (cancelToken) => {
        return await this.get('logging/api/stats/searches-per-day', {}, false, '', {}, cancelToken);
    };

    getSearchesRequestsPerOffice = async (cancelToken) => {
        return await this.get('logging/api/stats/searches-per-office', {}, false, '', {}, cancelToken);
    };

    getSearchesRequestsResults = async (cancelToken) => {
        return await this.get('logging/api/stats/searches/results', {}, false, '', {}, cancelToken);
    };
}