import React from 'react';
import hot from 'containers/hot-container';
import { contentManagementArticleFormPage } from 'components/content-management-project-form';
import Button from 'uikit/button';
import { useGlobalContext, useMessage } from 'lib/hooks';
import api from 'api/index';

const ContentManagementArticlePage = ({ article, loading, setLoading, navigate }) => {
    const { platform } = useGlobalContext();
    const { addSuccess } = useMessage();

    const save = async () => {
        setLoading(true);

        if (article.users?.length > 0) {
            await api.errorReportResource.addErrorReportManagers(article.users, article.id, 'ARTICLE');
        }

        if (article.managersRemove?.length > 0) {
            await api.errorReportResource.deleteErrorReportManagers(article.managersRemove, article.id, 'ARTICLE');
        }

        setLoading(false);
        navigate(`/projects/article/${article.id}`);

        addSuccess('Контент-менеджеры обновлены');
    }

    return (
        <>
            <Button label={'Сохранить'} disabled={loading} color={'green'} fullWidth={platform === 'mobile'}
                    onClick={save}/>
        </>
    );
};

export default hot(contentManagementArticleFormPage(ContentManagementArticlePage, 'Контент-менеджмент'));