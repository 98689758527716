import { useCallback, useEffect, useState } from 'react';

export const useSidebarContentInfiniteScroll = (isMore) => {
    const sidebarContent = document.getElementById('sidebarContent');
    const [isFetching, setIsFetching] = useState(false);
    const [onFetch, setOnFetch] = useState(() => () => null);

    const _setOnFetch = useCallback((f) => {
        setOnFetch(() => f);
    }, [])

    const handleScroll = useCallback(() => {
        if (!isMore) {
            return;
        }
        if (isFetching) {
            return;
        }
        if (sidebarContent.clientHeight + sidebarContent.scrollTop !== sidebarContent.scrollHeight) {
            return;
        }
        setIsFetching(true);
        if (onFetch) {
            onFetch();
        }
    }, [sidebarContent, isFetching, onFetch, isMore]);

    const finishFetching = useCallback(() => {
        setIsFetching(false);
    }, []);

    useEffect(() => {
        if (sidebarContent === null) {
            return;
        }
        sidebarContent.addEventListener('scroll', handleScroll);
        return () => sidebarContent.removeEventListener('scroll', handleScroll);
    }, [sidebarContent, handleScroll]);

    return { isFetching, finishFetching, setOnFetch: _setOnFetch };
}