import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { TRAINING_QUESTION_TYPES } from 'lib/config/constant';
import {
    TrainingQuestionAnswerMany,
    TrainingQuestionAnswerMatch,
    TrainingQuestionAnswerNext,
    TrainingQuestionAnswerOne,
    TrainingQuestionAnswerOpen,
    TrainingQuestionAnswerSort,
    TrainingQuestionAvatar,
    TrainingQuestionPreview,
    TrainingQuestionModal
} from 'components/training';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Input from 'uikit/input/input';
import { Select } from 'uikit/select';
import Button from 'uikit/button';
import api from 'api';
import cx from './training-question-add-button.module.scss';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);
    return result;
};

export default function TrainingQuestionAddButton({ isViewMode, questions, onQuestions }) {
    const [isQuestion, setIsQuestion] = useState(false);
    const [isQuestionModal, setIsQuestionModal] = useState(false);
    const [isQuestionPreview, setIsQuestionPreview] = useState(false);

    const [questionsList, setQuestionsList] = useState([]);

    const [questionIndex, setQuestionIndex] = useState(null);
    const [questionImage, setQuestionImage] = useState(null);
    const [questionTitle, setQuestionTitle] = useState('');
    const [questionMark, setQuestionMark] = useState(0);
    const [questionType, setQuestionType] = useState(TRAINING_QUESTION_TYPES[0]);
    const [questionAnswers, setQuestionAnswers] = useState([{ value1: '', value2: '', isRight: false }]);

    const onQuestionPreview = (index) => {
        setQuestionIndex(index);
        setQuestionImage(questions[index]['image']);

        setQuestionTitle(questions[index]['title']);
        setQuestionMark(questions[index]['mark']);

        setQuestionType(questions[index]['type']);
        setQuestionAnswers(questions[index]['answers']);

        setIsQuestionPreview(true);
    };
    const onQuestionEdit = (index) => {
        onQuestionCopy(index);
        setQuestionIndex(index);
    };
    const onQuestionCopy = (index) => {
        setQuestionIndex(null);

        setQuestionImage(questions[index]['image']);
        setQuestionTitle(questions[index]['title']);
        setQuestionMark(questions[index]['mark']);

        setQuestionType(questions[index]['type']);
        setQuestionAnswers(questions[index]['answers']);

        setIsQuestionPreview(false);
        setIsQuestion(true);
    };
    const onQuestionRemove = (index) => {
        const temp = Object.assign([], questions);
        temp.splice(index, 1);

        onQuestions(temp);
        setIsQuestionPreview(false);
    };

    const onQuestionSave = () => {
        const temp = Object.assign([], questions);

        if (questionIndex !== null) {
            temp[questionIndex]['image'] = questionImage;
            temp[questionIndex]['title'] = questionTitle;
            temp[questionIndex]['mark'] = questionMark;
            temp[questionIndex]['type'] = questionType;
            temp[questionIndex]['answers'] = questionAnswers;
        }
        else {
            temp.push({
                'image': questionImage,
                'title': questionTitle,
                'mark': questionMark,
                'type': questionType,
                'answers': questionAnswers
            });
        }

        onQuestions(temp);
        setIsQuestion(false);

        setQuestionIndex(null);
        setQuestionImage(null);
        setQuestionTitle('');
        setQuestionMark(0);
        setQuestionType(TRAINING_QUESTION_TYPES[0]);
        setQuestionAnswers([{ value1: '', value2: '', isRight: false }]);
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        onQuestions(reorder(questions, result.source.index, result.destination.index));
    };

    const onQuestionsSelect = async (data) => {
        const temp = Object.assign([], questions);
        let newQuestionsList = Object.assign([], questionsList);

        if (newQuestionsList.length === 0) {
            newQuestionsList = await api.training.getQuestions();
            setQuestionsList(newQuestionsList);
        }

        for (let i = 0; i < data.length; i++) {
            const question = newQuestionsList.find(p => p['id'] === data[i]['id']);

            if (question === undefined) {
                continue;
            }

            if (questions.find(p => p['id'] === data[i]['id']) !== undefined) {
                continue;
            }

            temp.push(question);
        }

        onQuestions(temp);
        setIsQuestionModal(false);
    };

    return (
        <div className={cx.trainingQuestionAdd}>
            {isQuestionModal &&
            <TrainingQuestionModal onQuestions={onQuestionsSelect} onClose={() => setIsQuestionModal(false)}/>}
            <TrainingQuestionPreview isOpen={isQuestionPreview} index={questionIndex} avatar={questionImage}
                                     title={questionTitle} mark={questionMark} type={questionType}
                                     answers={questionAnswers} onRemove={onQuestionRemove} onCopy={onQuestionCopy}
                                     onEdit={onQuestionEdit} onClose={() => setIsQuestionPreview(false)}/>
            {questions && questions.length !== 0 &&
            <div className={cx.trainingQuestionAddQuestions}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {questions.map((item, i) => (
                                    <Draggable key={i} draggableId={'draggable-' + i} index={i}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                 style={{ marginBottom: '16px', ...provided.draggableProps.style }}>
                                                <div className={cx.trainingQuestionAddQuestion}>
                                                    {item.avatar && <img src={item.avatar} alt="" />}
                                                    <div className={cx.trainingQuestionAddQuestionTitle}>
                                                        <div className={cx.trainingQuestionAddQuestionTitleLeft}>
                                                            {!isViewMode &&
                                                            <IconButton icon={<Icon type={Icons.DRAG} width={14} height={15}/>}/>}
                                                            <h3>{item.title}</h3>
                                                        </div>
                                                        <ul className={cx.trainingQuestionAddQuestionTitleRight}>
                                                            <li>
                                                                <IconButton icon={<Icon type={Icons.EYE} width={15}/>}
                                                                            onClick={() => onQuestionPreview(i)}/>
                                                            </li>
                                                            {!isViewMode &&
                                                            <li>
                                                                <IconButton icon={<Icon type={Icons.EDIT_PEN} width={15}/>}
                                                                            onClick={() => onQuestionEdit(i)}/>
                                                            </li>}
                                                            {!isViewMode &&
                                                            <li>
                                                                <IconButton icon={<Icon type={Icons.COPY} width={15}/>}
                                                                            onClick={() => onQuestionCopy(i)}/>
                                                            </li>}
                                                            {!isViewMode &&
                                                            <li>
                                                                <IconButton icon={<Icon type={Icons.TRASH} width={15}/>}
                                                                            onClick={() => onQuestionRemove(i)}/>
                                                            </li>}
                                                        </ul>
                                                    </div>
                                                    <div className={cx.trainingQuestionAddDelimiter}/>
                                                    <p>
                                                        <span>{item['type']['label']}</span>
                                                        {item['answers'].length > 0 &&
                                                        <span>{item['answers'].length} ответа (-ов)</span>}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>}
            {!isViewMode && !isQuestion &&
            <div className={cx.trainingQuestionAddButton}>
                <div className={cx.trainingQuestionAddButtonIcon}>
                    <Icon type={Icons.ADD} width={12} height={12}/>
                </div>
                <p>
                    <span onClick={() => setIsQuestion(true)}>Создать новый вопрос</span>
                    &nbsp;или&nbsp;
                    <span onClick={() => setIsQuestionModal(true)}>добавить существующий</span>
                </p>
            </div>}
            {isQuestion &&
            <div className={cx.trainingQuestionAddForm}>
                <div className={cx.trainingQuestionAddFormTop}>
                    <h3>{questionIndex ? 'Редактирование вопроса' : 'Новый вопрос'}</h3>
                    <TrainingQuestionAvatar avatar={questionImage} onAvatarSave={p => setQuestionImage(p)}/>
                    <Input id="trainingQuestionAddFormTitle" name="questionTitle" type="text"
                           className={cx.trainingQuestionAddFormField} label="Заголовок" value={questionTitle}
                           required onChange={(str) => setQuestionTitle(str)}/>
                    <Input id="trainingQuestionAddFormMark" name="questionMark" type="number"
                           className={cx.trainingQuestionAddFormField} label="Количество баллов"
                           value={questionMark} required onChange={(str) => setQuestionMark(str)}/>
                    <div className={cx.trainingQuestionAddDelimiter}/>
                    <h3>Ответы</h3>
                    <Select label="Тип ответов" options={TRAINING_QUESTION_TYPES} value={questionType}
                            onChange={(value) => {
                                setQuestionType(value);
                                setQuestionAnswers(value.value === 2
                                    ? [] : [{ value1: '', value2: '', isRight: false }]);
                            }}/>
                    {questionType.value === 0 &&
                    <TrainingQuestionAnswerMany answers={questionAnswers}
                                                onChange={(answers) => setQuestionAnswers(answers)}/>}
                    {questionType.value === 1 &&
                    <TrainingQuestionAnswerOne answers={questionAnswers}
                                               onChange={(answers) => setQuestionAnswers(answers)}/>}
                    {questionType.value === 2 && <TrainingQuestionAnswerOpen/>}
                    {questionType.value === 3 &&
                    <TrainingQuestionAnswerNext answers={questionAnswers}
                                                onChange={(answers) => setQuestionAnswers(answers)}/>}
                    {questionType.value === 4 &&
                    <TrainingQuestionAnswerMatch answers={questionAnswers}
                                                 onChange={(answers) => setQuestionAnswers(answers)}/>}
                    {questionType.value === 5 &&
                    <TrainingQuestionAnswerSort answers={questionAnswers}
                                                onChange={(answers) => setQuestionAnswers(answers)}/>}
                </div>
                <div className={cx.trainingQuestionAddFormBottom}>
                    <Button label="Отмена" onClick={() => setIsQuestion(false)}/>
                    <Button label="Предпросмотр" onClick={() => setIsQuestionPreview(true)}/>
                    <Button label="Сохранить" color="green" onClick={() => onQuestionSave()}/>
                </div>
            </div>}
        </div>
    );
};