export default function VisualPathFromParentNodes(parents) {
    const _path = [];
    _path.push(parents.projectTitle);

    if (parents.sections.length === 0) {
        _path.push('Корень проекта');
    } else {
        for (let i = parents.sections.length - 1; i >= 0; i--) {
            _path.push(parents.sections[i].title);
        }
    }

    return _path;
}