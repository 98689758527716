import ApiClient from './api-axios';

export default class ResourceApi extends ApiClient {
    findUsersByResourceId = async resourceId => {
        return await this.get(`${resourceId}/users`);
    };

    findGroupsByResourceId = async resourceId => {
        return await this.get(`${resourceId}/groups`);
    };

    findUsersByResourceIdPaginate = async (resourceId, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', true);
            params.append('login.contains', search);
            params.append('multiName.contains', search);
            params.append('email.contains', search);
        }

        if (filters['positionTitle.in']) {
            params.append('positionTitle.in', filters['positionTitle.in'].map((filter) => filter.value));
        }

        if (filters['supervisorLogin.in']) {
            params.append('supervisorLogin.in', filters['supervisorLogin.in'].map((filter) => filter.value));
        }

        if (filters['officeName.in']) {
            params.append('officeName.in', filters['officeName.in'].map((filter) => filter.value));
        }

        if (filters['group.in']) {
            params.append('groupId.in', filters['group.in'].map((filter) => filter.value));
        }

        if (filters['project.in']) {
            params.append('project.in', filters['project.in'].map((filter) => filter.value));
        }

        if (filters['roleName.in']) {
            params.append('roleName.in', filters['roleName.in'].map((filter) => filter.value));
        }

        if (filters['projectRoleName.in']) {
            params.append('projectRoleName.in', filters['projectRoleName.in'].map((filter) => filter.value));
        }

        return await this.get(`${resourceId}/users?` + params.toString(), {}, true);
    };

    getPermittedActions = async (resourceType, uuid) => {
        return await this.get(`actions/${uuid}/${resourceType}`);
    };

    getUserTree = async (login) => {
        return await this.get(`/tree/${login}`);
    };

    async getGlobalPermittedActions() {
        return await this.get('actions');
    }

    async getGlobalRootPermittedActions() {
        return await this.get('root-actions');
    }
}