export const tree = [
    {
        id: 'GENERAL',
        name: 'Общая статистика',
        nodeType: 'PROJECT',
        children: [],
    },
    {
        id: 'DOCUMENTS',
        name: 'Документы',
        nodeType: 'PROJECT',
        children: [
            // {
            //     id: 'PROJECT_INFO',
            //     name: 'Информация по проекту',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'SECTION_INFO',
            //     name: 'Информация по разделам',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'DOCUMENTS_INFO',
            //     name: 'Информация по документам',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ARTICLES_IN_PROJECTS',
            //     name: 'Статьи в проекте',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'NEWS_IN_PROJECTS',
            //     name: 'Новости в проекте',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ARTICLES_IN_SECTIONS',
            //     name: 'Статьи в разделе',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'GENERAL_NEWS',
            //     name: 'Общие новости',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            {
                id: 'CONTENT_REPORT',
                name: 'Отчет по контенту',
                nodeType: 'SECTION',
                children: null,
            },
            // {
            //     id: 'FILES_IN_PROJECTS',
            //     name: 'Файлы в проектах',
            //     nodeType: 'SECTION',
            //     children: [],
            // },
            // {
            //     id: 'FILES_IN_SECTIONS',
            //     name: 'Файлы в разделах',
            //     nodeType: 'SECTION',
            //     children: [],
            // },
            // {
            //     id: 'FILES_IN_DOCUMENTS',
            //     name: 'Файлы в документах',
            //     nodeType: 'SECTION',
            //     children: [],
            // },
            {
                id: 'FILES_REPORTS',
                name: 'Отчет по файлам',
                nodeType: 'SECTION',
                children: null,
            },
            {
                id: 'DOCUMENTS_POPULARITY_BY_RATING',
                name: 'Популярность документов по оценкам',
                nodeType: 'SECTION',
                children: null,
            },
            // {
            //     id: 'DOCUMENTS_EVALUATION',
            //     name: 'Оценивание документа',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'COMMENTS_IN_DOCUMENTS',
            //     name: 'Комментарии в документе',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ARTICLE_RATING_BY_COMMENTS',
            //     name: 'Популярность статей по комментариям',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ERROR_NOTIFICATIONS',
            //     name: 'Уведомления о ошибках',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'EDIT_NOTIFICATIONS',
            //     name: 'Уведомления о изменениях',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'REFERENCES_TO_ARTICLES',
            //     name: 'Обращение к статьям',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
        ],
    },
    // {
    //     id: 'USERS',
    //     name: 'Участники',
    //     nodeType: 'PROJECT',
    //     children: [
    //         {
    //             id: 'USERS_INFO',
    //             name: 'Информация о участниках',
    //             nodeType: 'SECTION',
    //             children: null,
    //         },
    //         {
    //             id: 'USER_IN_PROJECTS',
    //             name: 'Участники в проектах',
    //             nodeType: 'SECTION',
    //             children: null,
    //         },
    //         {
    //             id: 'USER_IN_SECTIONS',
    //             name: 'Участники в разделах',
    //             nodeType: 'SECTION',
    //             children: null,
    //         },
    //         {
    //             id: 'USER_IN_DOCUMENTS',
    //             name: 'Участники в документах',
    //             nodeType: 'SECTION',
    //             children: null,
    //         },
    //         {
    //             id: 'USERS_REPORT',
    //             name: 'Отчет по участникам',
    //             nodeType: 'SECTION',
    //             children: null,
    //         },
    //     ],
    // },
    {
        id: 'ACTIVITIES',
        name: 'Активность',
        nodeType: 'PROJECT',
        children: [
            // {
            //     id: 'ACTIONS_IN_PROJECTS',
            //     name: 'События в проектах',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ACTIONS_IN_SECTIONS',
            //     name: 'События в разделах',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ACTIONS_IN_ARTICLES',
            //     name: 'События в статьях',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ACTIONS_IN_NEWS',
            //     name: 'События в новостях',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            {
                id: 'ACTIONS_REPORT',
                name: 'Отчет по активностям',
                nodeType: 'SECTION',
                children: null,
            },
            {
                id: 'READS',
                name: 'Прочтение документов',
                nodeType: 'SECTION',
                children: null,
            },
        ],
    },
    {
        id: 'SEARCH',
        name: 'Поиск',
        nodeType: 'PROJECT',
        children: [
            {
                id: 'SEARCH_REQUESTS',
                name: 'Поисковые запросы',
                nodeType: 'SECTION',
                children: null,
            },
            {
                id: 'DETAILED_SEARCH_REQUEST',
                name: 'Детализация запроса',
                nodeType: 'SECTION',
                children: null,
            },
            // {
            //     id: 'ARTICLES_OPTIMIZE',
            //     name: 'Оптимизация поиска',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'TOP_RESULTS',
            //     name: 'Топ выдачи',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
            // {
            //     id: 'ANTI_TOP_ARTICLES',
            //     name: 'Антитоп выдачи',
            //     nodeType: 'SECTION',
            //     children: null,
            // },
        ],
    },
];
