import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from '@reach/router';

const AdaptiveLinkComponent = ({ children, link, onClick, Component, replace, useNavigateWithLeftClicks, onMouseDown, onMouseUp, ...props }) => {
    const navigate = useNavigate();
    const [_link, setLink] = useState('');
    const [_replace, setReplace] = useState(!!replace);

    const openAsLink = useRef(false);

    const _onMouseDown = (e) => {
        openAsLink.current = e.button !== 0 || e.ctrlKey || e.shiftKey || e.metaKey;

        if (onMouseDown) {
            onMouseDown(e);
        }
    };
    const _onMouseUp = (e) => {
        if (!openAsLink.current) {
            if (_link !== '#') {
                navigate(_link, { replace: _replace });
            }

            if (onClick) {
                onClick(e);
            }
        } else {
            // window.open(_link, '_blank').focus();
        }

        if (onMouseUp) {
            onMouseUp(e);
        }
    };

    useEffect(() => {
        if (typeof link === 'function') {
            const r = link();
            if (typeof r === 'string') {
                setLink(r);
            } else if (typeof r === 'object') {
                setLink(r.link);
                setReplace(!!r.replace);
            }
        } else if (typeof link === 'string') {
            setLink(link);
        }
    }, [link]);

    const eventProps = {
        ...(useNavigateWithLeftClicks && {onMouseDown: _onMouseDown}),
        ...(useNavigateWithLeftClicks && {onMouseUp: _onMouseUp}),
        ...(!useNavigateWithLeftClicks && {onClick: onClick}),
    }

    if (_link) {
        return (
            <div {...props} {...eventProps}>{children}</div>
        );
    } else {
        return <Component onClick={onClick} {...props}>{children}</Component>;
    }
}

AdaptiveLinkComponent.propTypes = {
    link: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.node]),
    onClick: PropTypes.func,
    useNavigateWithLeftClicks: PropTypes.bool
}

AdaptiveLinkComponent.defaultProps = {
    Component: ({ children, ...props }) => <div {...props}>{children}</div>,
    useNavigateWithLeftClicks: true
}

export const AdaptiveLink = React.forwardRef((props, _ref) => <AdaptiveLinkComponent {...props} />);
