import React, { useCallback, useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';
import classNames from 'classnames';
import { SelectOption } from 'uikit/select/select';
import Icon, { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';
import cx from './charts.module.scss';

const _chartTypes = [
    { value: 'doughnut', label: 'Круговая диаграмма' },
    { value: 'horizontalBar', label: 'Линейная диаграмма' },
    { value: 'bar', label: 'Гистограмма' },
    { value: 'line', label: 'График' },
];

const colors = [
    '#416EDB',
    '#1FBCA0',
    '#2AB7CA',
    '#E4572E',
    '#F7CB15',
    '#3D3B8E',
    '#BC7C9C',
    '#CF9893',
    '#DE6B48',
    '#FED766',
    '#1B998B',
    '#1A535C',
    '#3B3B58',
    '#75DBCD',
    '#2E282A',
];

const Empty = ({ type }) => {
    return (
        <div className={cx.emptyChart}>
            <div
                className={classNames({
                    [cx.emptyChartDoughnut]: type === 'doughnut',
                    [cx.emptyChartBar]: type === 'horizontalBar' || type === 'bar',
                    [cx.emptyChartLine]: type === 'line',
                })}
            />
            <div className={cx.emptyChartText}>
                <Icon type={Icons.LOCK} width={48} height={48} />
                {type === 'line' ? (
                    <div>Недостаточно данных для отображения графика</div>
                ) : (
                    <div>Недостаточно данных для отображения диаграммы</div>
                )}
            </div>
        </div>
    );
};

const MultiChart = ({ title, labels = [], data = null, chartType = 'doughnut', chartTypes = [], isTypeSelect, options }) => {
    const chartRef = useRef();
    const [chart, setChart] = useState(null);

    const [types, setTypes] = useState([]);
    const [type, setType] = useState({ value: 'doughnut', label: 'Круговая диаграмма' });

    const [isEmpty, setIsEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const initDatasets = useCallback(() => {
        if (typeof data[0] === 'number') {
            return [
                {
                    backgroundColor: chartType === 'line' ? 'transparent' : colors.slice(0, labels.length),
                    borderColor: chartType === 'line' ? '#416EDB' : 'transparent',
                    borderWidth: 2,
                    weight: 2,
                    data,
                },
            ];
        } else if (typeof data === 'object') {
            return data.map((item, idx) => {
                return {
                    backgroundColor: colors[idx],
                    borderWidth: 2,
                    weight: 2,
                    ...item,
                };
            });
        }
    }, [data, labels, chartType]);

    const initializeTypes = useCallback(() => {
        setTypes(
            _chartTypes.filter((type) => {
                return chartTypes.some((i) => i === type.value);
            })
        );
    }, [chartTypes, setTypes]);

    const checkDataEmpty = useCallback(() => {
        return data.length === 0 || data.every((i) => !i || i?.data?.length === 0);
    }, [data]);

    const drawCanvas = useCallback(async () => {
        setIsEmpty(checkDataEmpty());

        if (!chartRef.current) {
            return;
        }

        const ctx = chartRef.current.getContext('2d');

        setChart((prevChart) => {
            if (prevChart && prevChart.destroy) {
                prevChart.destroy();
            }

            const _chart = new Chart(ctx, {
                plugins: [
                    {
                        beforeInit: function (chart) {
                            chart.legend.afterFit = function () {
                                this.height = this.height + 10;
                            };
                        },
                    },
                ],
                type: type?.value || 'bar',
                data: {
                    labels,
                    datasets: initDatasets(),
                },
                options: {
                    legend: {
                        display: false,
                    },
                    scales: type &&
                        type.value === 'horizontalBar' && {
                            xAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                },
                            ],
                        },
                    ...options,
                },
            });
            setIsLoading(false);
            return _chart;
        });
    }, [type, initDatasets, options, labels, checkDataEmpty]);

    const changeType = (value) => {
      setType(value)
      setIsLoading(true);
    }

    useEffect(() => {
        initializeTypes();
    }, [initializeTypes]);

    useEffect(() => {
        setType(types.find((i) => i.value === chartType));
    }, [chartType, setType, types]);

    useEffect(() => {
        if (!data) {
            setIsLoading(true);
        } else if (data.length && isLoading) {
            drawCanvas();
        } else if (!data.length) {
            setIsEmpty(true);
            setIsLoading(false);
        }
    }, [drawCanvas, isLoading, data]);

    console.log(chart);

    return (
        <div className={cx.chart}>
            <div className={cx.chartHeader}>
                <div className={cx.chartHeaderTitle}>{title}</div>
                {isTypeSelect && (
                    <div>
                        <SelectOption
                            value={type}
                            defaultValue={types.find((i) => i.value === chartType)}
                            style={{
                                dropdownIndicator: (_state) => ({
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                }),
                                control: (_state) => ({
                                    width: 200,
                                    minHeight: 20,
                                    backgroundColor: 'transparent',
                                    border: 0,
                                    boxShadow: 'none',
                                    cursor: 'pointer',

                                    ':hover': {
                                        backgroundColor: 'transparent',
                                        border: '0 !important',
                                    },
                                }),
                            }}
                            isSearchable={false}
                            options={types}
                            onChange={changeType}
                        />
                    </div>
                )}
            </div>
            {data?.length && type && type.value === 'line' ? <div className={cx.lineCounter}>{data[data.length - 1]}</div> : null}
            <div className={cx.chartWrapper}>
                {isEmpty && <Empty type={type?.value} />}
                {isLoading && <div className={cx.loaderWrapper}><Loading withOverlay={false} /></div>}
                <canvas ref={chartRef} />
            </div>
        </div>
    );
};

export default MultiChart;
