import { NODE_TYPES } from 'components/trees/infinite-tree/node-type';

const DEFAULT_CHECKED = { [NODE_TYPES.PROJECT]: [], [NODE_TYPES.SECTION]: [], [NODE_TYPES.ARTICLE]: [] }

export function createCheckedBucket() {
    let state;

    return {

        change: (tree) => {
            const bucket = JSON.parse(JSON.stringify(DEFAULT_CHECKED));

            function add(node) {
                bucket[node.nodeType].push(node);
            }

            function traverse(nodes) {
                for (let node of nodes) {
                    if (node.state.checked && !node.state.indeterminate && !node.indeterminate) {
                        add(node);
                    } else {
                        traverse(node.children);
                    }
                }
            }

            traverse(tree.nodes.filter(n => n.nodeType === NODE_TYPES.PROJECT));
            state = bucket;
        },

        getState: () => {
            return state;
        },

        hasChecked: () => {
            return !!(state && (state[NODE_TYPES.PROJECT].length > 0 || state[NODE_TYPES.SECTION].length > 0 || state[NODE_TYPES.ARTICLE].length > 0));
        }
    }
}
