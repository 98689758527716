import OrderedList from '@tiptap/extension-ordered-list';
import { MULTI_LIST_OPTIONS } from '../../constants';

import { findKey } from 'lodash';

export const CustomOrderedList = OrderedList.extend({
    content: '(listItem|multiListItem?)*',

    addAttributes() {
        return {
            ...this.parent?.(),
            'data-type': {
                default: 'ordered-list',
            },
            'data-list-type': {
                default: 'list-decimal',
                parseHTML: el => {
                    return el.getAttribute('data-list-type') || findKey(MULTI_LIST_OPTIONS, o => o === el.style.listStyleType);
                },
                renderHTML: attrs => {
                    if (!attrs['data-list-type']) {
                        return {};
                    }

                    return {
                        style: `list-style-type: ${MULTI_LIST_OPTIONS[attrs['data-list-type']]} !important`,
                    };
                },
            },
            'data-level': {
                default: null,
            },
            'data-padding': {
                default: 1,
                parseHTML: el => el.getAttribute('data-padding') || parseFloat(el.style.paddingLeft) / 16,
                renderHTML: attrs => {
                    if (!attrs['data-padding']) {
                        return {};
                    }

                    return {
                        style: `padding-left: ${attrs['data-padding'] * 16}px;`,
                    };
                },
            },
            style: {
                default: null,
            },
        };
    },

    addInputRules() {
        return [];
    },
});
