import React from 'react';

import cx from './emoji-picker.module.scss';
import { CATEGORY_NAMES, CATEGORY_TRANSLATE } from './constants';
import { emojies } from './emojies';

const getEmojiCategoryMap = () => {
    const emojiCategoryMap = CATEGORY_NAMES.reduce((acc, category) => {
        return { ...acc, [category]: [] };
    }, {});
    emojies.forEach(emoji => {
        if (emojiCategoryMap[emoji.c]) {
            emojiCategoryMap[emoji.c].push(emoji);
        }
    });

    return emojiCategoryMap;
};

export const EmojiPicker = ({ onClick }) => {
    const emojiCategories = React.useMemo(() => {
        return getEmojiCategoryMap();
    }, []);

    return (
        <div className={cx.emojiWrapper}>
            <div className={cx.emojiPickerContainer}>
                {CATEGORY_NAMES.map(category => {
                    if (!emojiCategories[category].length) {
                        return null;
                    }
                    return (
                        <div key={category} className={cx.categoryWrapper}>
                            <div className={cx.categoryName}>{CATEGORY_TRANSLATE[category]}</div>
                            <div className={cx.emojiesList}>
                                {emojiCategories[category].map(emoji => (
                                    <span title={emoji.d} name={emoji.n} className={cx.emoji} key={emoji.n} onClick={() => onClick(emoji)}>
                                        {emoji.e}
                                    </span>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
