import React, { useCallback, useRef, useState } from 'react';
import propTypes from 'prop-types';

export const EVENT_TYPE = {
		CAPTURE: 0,
		BUBBLE: 1
};

export const LongPressWrapper = ({ onLongPress, onClick, children, shouldPreventDefault, delay, eventType, ...rest }) => {
		const [longPressTriggered, setLongPressTriggered] = useState(false);
		const timeout = useRef();
		const target = useRef();

		const start = useCallback(
				event => {
						if (shouldPreventDefault && event.target) {
								event.target.addEventListener('touchend', preventDefault, {
										passive: false
								});
								target.current = event.target;
						}
						timeout.current = setTimeout(() => {
								if (!LongPressWrapper.swiping) {
										onLongPress();
										setLongPressTriggered(true);
								}
						}, delay);
				},
				[onLongPress, delay, shouldPreventDefault]
		);

		const clear = useCallback(
				(event, shouldTriggerClick = true) => {
						timeout.current && clearTimeout(timeout.current);
						if (!LongPressWrapper.swiping) {
								if (shouldTriggerClick && !longPressTriggered) {
										onClick(event);
								}
								setLongPressTriggered(false);
								if (shouldPreventDefault && target.current) {
										target.current.removeEventListener('touchend', preventDefault);
								}
						}
						LongPressWrapper.swiping = false;
				},
				[shouldPreventDefault, onClick, longPressTriggered]
		);

		return (
				<div onTouchStartCapture={eventType === EVENT_TYPE.CAPTURE ? e => start(e) : () => {}}
						 onTouchEndCapture={eventType === EVENT_TYPE.CAPTURE ? e => clear(e) : () => {}}
						 onTouchStart={eventType === EVENT_TYPE.BUBBLE ? e => start(e) : () => {}}
						 onTouchEnd={eventType === EVENT_TYPE.BUBBLE ? e => clear(e) : () => {}}
						 onTouchMove={_ => LongPressWrapper.swiping = true}
						 {...rest}>
						{children}
				</div>
		)
}

const isTouchEvent = event => {
		return 'touches' in event;
};

const preventDefault = event => {
		if (!isTouchEvent(event)) return;

		if (event.touches.length < 2 && event.preventDefault) {
				event.preventDefault();
				event.stopPropagation();
		}
};

LongPressWrapper.swiping = false;

LongPressWrapper.propTypes = {
		id: propTypes.string,
		className: propTypes.string,
		onLongPress: propTypes.func,
		onClick: propTypes.func,
		shouldPreventDefault: propTypes.bool,
		delay: propTypes.number,
		eventType: propTypes.number
}

LongPressWrapper.defaultProps = {
		onLongPress: () => {},
		onClick: () => {},
		shouldPreventDefault: false,
		delay: 400,
		eventType: 1
}