import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { Link, useNavigate } from '@reach/router';
import { download } from 'lib/util/download';
import formatDateTime from 'lib/helpers/formatDateTime';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import ButtonMenu from 'uikit/button-menu';
import classNames from 'classnames';
import Crumbs from 'components/crumbs';
import cx from '../pages/search-result-page.module.scss';
import api from 'api';
import { TagList } from 'components/tag-list';
import { selectFilters, setTags } from 'slice/globalSearchSlice';
import { useDispatch, useSelector } from 'react-redux';

function DocumentsList({ documents = [], typeSort, orderSort, onTypeSortChange, onOrderSortChange, onNavigateToDocument }) {
    const navigate = useNavigate();
    const [downloading, setDownloading] = useState(false);
    const store = useSelector(selectFilters);
    const dispatch = useDispatch();

    const _onNavigateToDocument = (item) => {
        onNavigateToDocument(item);

        // const clearHighlights = item['clearHighlights'] ? `?highlights=${JSON.stringify(item['clearHighlights'])}` : '';
        const clearHighlights = `?highlights=${JSON.stringify(item['clearHighlights'])}`;
        const isGotBreadcrumbs = item['breadcrumbs'].length;

        if (item.status !== 'ARCHIVED') {
            if (item['type'] === 'ARTICLE') {
                return `/projects/article/${item['id']}${clearHighlights}`;
            } else if (item['type'] === 'PROJECT') {
                return `/projects/${item['id']}/articles`;
            } else if (item['type'] === 'NEWS' && isGotBreadcrumbs) {
                return `/projects/news/${item['id']}${clearHighlights}`;
            } else if (item['type'] === 'NEWS' && !isGotBreadcrumbs) {
                return `/news/${item['id']}${clearHighlights}`;
            }
        } else {
            if (item['type'] === 'ARTICLE') {
                return `/archive/article/${item['id']}${clearHighlights}`;
            } else if (item['type'] === 'PROJECT') {
                return '/archive/' + item['id'] + '/articles';
            } else if (item['type'] === 'NEWS') {
                return `/archive/news/${item['id']}${clearHighlights}`;
            }
        }

        return '/';
    };
    const articleDownload = async (id, title) => {
        try {
            setDownloading(true);
            const res = await api.contentConverter.articleToFormat(id, 'docx');
            download(res, title + '.docx', 'docx');
        } catch (error) {
            console.log('ERROR', error);
        } finally {
            setDownloading(false);
        }
    };
    const newsDownload = async (id, title) => {
        try {
            setDownloading(true);
            const res = await api.contentConverter.newsToFormat(id, 'docx');
            download(res, title + '.docx', 'docx');
        } catch (error) {
            console.log('ERROR', error);
        } finally {
            setDownloading(false);
        }
    };

    const patch = (item) => {
        if (!item) {
            return;
        }

        let path = item.map((item) => item.title);
        return path.join(' / ');
    };

    const title = (patch, item) => {
        let title;

        if (item.highlights?.TITLE && !item.exactAnswer) {
            title = item.highlights.TITLE;
        } else {
            title = item.title;
        }

        if (patch) {
            return patch + ' / ' + title;
        } else {
            return title;
        }
    };
    const getLogo = (id, type) => {
        if (type === 'NEWS' && id) {
            return api.upload.getImage(id, false, 256);
        } else if (type === 'ARTICLE') {
            return api.upload.getImage(id, false, 256);
        }
    };

    // Сортировка по наличию и размеру точных ответов
    if (documents?.length !== 0) {
        documents.sort((item_1, item_2) => {
            if (item_1.exactAnswer && !item_2.exactAnswer) {
                return -1;
            }
            if (!item_1.exactAnswer && item_2.exactAnswer) {
                return 1;
            }
            return 0;
        })
    }

    const collectCrumbs = (documentsItem) => {
        const { breadcrumbs, id } = documentsItem;
        // {resourceRefId: '673314cf-a118-4181-8084-56a0f58f8c49', resourceType: 'PROJECT', title: 'Отложенная'}
        // V
        // {id: '673314cf-a118-4181-8084-56a0f58f8c49', title: 'Отложенная', state: 'ACTIVE', type: 'PROJECT'} ...

        return breadcrumbs.map((item) => {
            return {
                name: item.title,
                // state: item.state,
                state: 'ACTIVE',
                linkTo: id === item.resourceRefId ? '' : item.state === 'ARCHIVED'
                    ? item.resourceType === 'PROJECT'
                        ? `/archive/${item.resourceRefId}/articles`
                        : `/archive/${breadcrumbs[0].resourceRefId}/${item.resourceRefId}/section/articles`
                    : item.resourceType === 'PROJECT'
                        ? `/projects/${item.resourceRefId}/articles`
                        : `/projects/${breadcrumbs[0].resourceRefId}/${item.resourceRefId}/section/articles`,
            };
        });
    };

    const handleTagClick = (tag, selected) => {
        let tags;
        if (selected) {
            tags = [...store.tags, tag];
        } else {
            tags = store.tags.filter(t => t.id !== tag.id)
        }

        dispatch(setTags(tags));
    }

    return (
        <div className={cx.searchResultContent}>
            <Loading active={downloading} withOverlay />
            <div className={cx.searchResultContentHeader}>
                <h4>Документы</h4>
                {documents?.length !== 0 && (
                    <div className={cx.searchResultContentHeaderSorting}>
                        <span>Сортировать:</span>
                        <ButtonMenu className={cx.navigationMenu} toggler={(showMenu) => (
                            <button className={cx.navigationMenuTitle} onClick={showMenu}>{typeSort}</button>
                        )} items={[
                            {
                                title: 'По умолчанию',
                                onClick: () => onTypeSortChange('По умолчанию')
                            },
                            {
                                title: 'По дате добавления',
                                onClick: () => onTypeSortChange('По дате добавления')
                            },
                            {
                                title: 'По дате изменения',
                                onClick: () => onTypeSortChange('По дате изменения')
                            },
                            {
                                title: 'По автору',
                                onClick: () => onTypeSortChange('По автору')
                            },
                            {
                                title: 'По популярности',
                                onClick: () => onTypeSortChange('По популярности')
                            },
                            {
                                title: 'По количеству лайков',
                                onClick: () => onTypeSortChange('По количеству лайков')
                            },
                            {
                                title: 'По количеству дизлайков',
                                onClick: () => onTypeSortChange('По количеству дизлайков')
                            },
                            {
                                title: 'По количеству прочтений',
                                onClick: () => onTypeSortChange('По количеству прочтений')
                            }
                        ]} />
                        <span className={cx.order}>
                            <p onClick={() => onOrderSortChange(!orderSort)}>
                                {orderSort && (
                                    <span>
                                        По возрастанию{' '}
                                        <span className={cx.union}>
                                            <Icon type={Icons.UNION} height={8} width={8} />
                                        </span>
                                    </span>
                                )}
                                {!orderSort && (
                                    <span>
                                        По убыванию{' '}
                                        <span className={cx.union}>
                                            <Icon type={Icons.UNION} height={8} width={8} />
                                        </span>
                                    </span>
                                )}
                            </p>
                        </span>
                    </div>
                )}
            </div>
            <div className={cx.searchResultContentBody}>
                {documents?.length === 0 && (
                    <div className={cx.searchResultContentBodyEmpty}>
                        <Icon type={Icons.ARTICLE} width={56} height={56} />
                        <div>
                            <p>Документы не найдены</p>
                            <span>По данному запросу не найдено ни одного документа</span>
                        </div>
                    </div>
                )}
                {documents?.length !== 0 &&
                    documents?.sort(({exactAnswer}) => {
                        if (exactAnswer !== null) {
                            return -1;
                        } else {
                            return 1;
                        }
                    }).map((item, index) => {
                        const isRecommended = item.exactAnswer !== null;
                        return (
                            <div key={item.id + index} className={classNames(cx.resultItemWrap, isRecommended ? cx.resultItemWrapRecommended : '')}>
                                <div className={cx.resultItem}>
                                    <div className={cx.generalInfoContainer}>
                                        <Link to={_onNavigateToDocument(item)}>
                                            <div className={cx.logoContainer}>
                                                {item.logoUuid !== null &&(
                                                <img style={{ objectFit: 'cover' }} alt="logo" src={getLogo(item.logoUuid, item.type)}/>
                                                )}
                                                {item.logoUuid === null && item.type === 'NEWS' && (
                                                <img style={{ objectFit: 'cover' }} alt="logo" src="/img/news-card-cover.jpg"/>
                                                )}
                                                {item.logoUuid === null && item.type !== 'NEWS' && (
                                                <img style={{ objectFit: 'cover' }} alt="logo" src="/img/article-card-cover.jpg"/>
                                            )}</div>
                                        </Link>
                                        <div className={cx.descriptionContainer}>{
                                                isRecommended ? (
                                                    <div className={cx.resultItemRecommendedTag}>
                                                        Рекомендованный ответ
                                            </div>
                                                ) : null
                                            }
                                            <div>
                                                {isRecommended ?
                                                  (
                                                    <Crumbs data={[
                                                        ...collectCrumbs(item),
                                                        {
                                                            name: item.title,
                                                            state: 'ACTIVE',
                                                            linkTo: _onNavigateToDocument(item)
                                                        },
                                                        {}
                                                    ]} />
                                                  )
                                                : (
                                                <Link className={cx.titleContainer} to={_onNavigateToDocument(item)}>
                                                    {item.status === 'ARCHIVED' && <div className={cx.titleArchived}>Архив</div>}
                                                    <p className={cx.title}>
                                                        {ReactHtmlParser(title(patch(item.breadcrumbs), item))}
                                                    </p>
                                                </Link>
                                                )}<div className={cx.coincidences}>
                                                    <div className={cx.type}>
                                                        <div className={cx.img}>
                                                            {item.type === 'NEWS' &&
                                                            <Icon type={Icons.SPEAKER} height={15} width={13}/>}
                                                            {item.type !== 'NEWS' &&
                                                            <Icon type={Icons.ARTICLE} height={15} width={11}/>}
                                                        </div>
                                                        <span className={cx.titleType}>
                                                            {item.type === 'NEWS' ? 'Новость' : 'Статья'}
                                                        </span>
                                                    </div>
                                                    {!isRecommended && (<p>Кол-во совпадений: {item.matchCount}</p>)}
                                                </div>
                                                {(item.exactAnswer || item['description'] || (item.highlights && item.highlights?.['DESCRIPTION'])) && (
                                                    <Link to={_onNavigateToDocument(item)}>
                                                        <p className={cx.desc}>
                                                            {isRecommended ? item.exactAnswer:item.highlights?.['DESCRIPTION']
                                                                ? ReactHtmlParser(item.highlights?.['DESCRIPTION'][0])
                                                                : item['description']}
                                                        </p>
                                                    </Link>
                                                )}
                                                <Link to={_onNavigateToDocument(item)}>
                                                    <p className={cx.desc}>
                                                        {item.highlights?.['TEXT'] && ReactHtmlParser(item.highlights['TEXT'][0])}
                                                    </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx.additionalInfoContainer}>
                                        {item['author'] &&(
                                        <div className={cx.infoContainer}>
                                            <p className={cx.infoTitle}>Автор:</p>
                                            <p className={cx.infoValue}>{item['author'].status && item['author'].status === 'DELETED' &&
                                                    <span style={{color: '#262626'}} className={cx.author}>
                                                        {item['author']?.login
                                                            ? item['author'].firstName + ' ' + item['author'].lastName
                                                            : item['author']}
                                                    </span>}
                                                    {item['author'].status && item['author'].status !== 'DELETED' &&
                                                <Link to={`/edit-user/${item['author'].login}`}>
                                                <span className={cx.author}>
                                                    {item['author']?.login
                                                        ? item['author'].firstName + ' ' + item['author'].lastName
                                                        : item['author']}
                                                </span>
                                                </Link>}
                                            </p>
                                        </div>
                                        )}<div className={cx.infoContainer}>
                                            <p className={cx.infoTitle}>Дата изменения:</p>
                                            <p className={cx.infoValue}>
                                                {item['modifyTime'] ? formatDateTime(item['modifyTime']) : ''}
                                            </p>
                                        </div>
                                        <div className={cx.menuContainer}>
                                            <ButtonMenu tooltip="Действия" position="left" submenuPosition="left"
                                                        items={[
                                                            {
                                                                icon: Icons.EYE,
                                                                title: 'Перейти к документу',
                                                                onClick: () => navigate(_onNavigateToDocument(item)),
                                                            },
                                                            {
                                                                icon: Icons.SHARE,
                                                                title: 'Скачать документ',
                                                                onClick: () => {
                                                                    item.type !== 'NEWS'
                                                                        ? articleDownload(item.id, item.title)
                                                                        : newsDownload(item.id, item.title);},
                                                                },
                                                            ]}
                                                        />
                                        </div>
                                    </div>
                                </div>

                                {item.documentTags?.length > 0 && (
                                    <>
                                        <TagList tags={item.documentTags} selectedTags={store.tags} onClick={handleTagClick} />
                                    </>
                                )}
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

DocumentsList.defaultProps = {
    documents: [],
    typeSort: 'По умолчанию',
    orderSort: false,
    onTypeSortChange: () => {},
    onOrderSortChange: () => {},
    onNavigateToDocument: () => {},
};
DocumentsList.propTypes = {
    documents: PropTypes.arrayOf(PropTypes.object),
    typeSort: PropTypes.string,
    orderSort: PropTypes.bool,
    onTypeSortChange: PropTypes.func,
    onOrderSortChange: PropTypes.func,
    onNavigateToDocument: PropTypes.func,
};

export default DocumentsList;
