import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MultiClamp from 'react-multi-clamp';
import Tippy from '@tippyjs/react';
import IconGroup from 'uikit/icon-group/icon-group';
import BackButton from 'components/back-button';
import cs from './user-header.module.scss';

const UserHeader = ({ idPrefix, userName, userPosition, userBlocked, avatarSrc, actionIcons, onGoBackHandler }) => {
    return (
        <div className={cs.headerWrapper}>
            <div className={cs.header}>
                {onGoBackHandler && <BackButton onClick={onGoBackHandler} />}

                <img id={idPrefix + 'UserAvatar'} className={cs.logo} src={avatarSrc || '/img/avatar.png'} width={64} height={64} alt="" />

                <div id={idPrefix + 'UserInfoBlock'} className={cs.userInfoBlock}>
                    {userBlocked && (
                        <div className={classNames(cs.blockedUser, { [cs.blockedUserCenter]: !userPosition })}>ЗАБЛОКИРОВАН</div>
                    )}
                    <Tippy content={userName}>
                        <div id={idPrefix + 'UserInfo'} className={cs.userInfo}>
                            <MultiClamp clamp={1} ellipsis={'...'}>
                                {userName}
                            </MultiClamp>
                        </div>
                    </Tippy>
                    {userPosition && (
                        <div id={idPrefix + 'UserPosition'} className={cs.userPosition}>
                            {userPosition}
                        </div>
                    )}
                </div>
            </div>

            {actionIcons && (
                <div className={cs.headerActions}>
                    <IconGroup id={idPrefix + 'Actions'} position="right">
                        {actionIcons}
                    </IconGroup>
                </div>
            )}
        </div>
    );
};

UserHeader.defaultProps = {
    idPrefix: '',
    userName: '',
    userPosition: '',
    userBlocked: false,
    avatarSrc: '',
};

UserHeader.propTypes = {
    idPrefix: PropTypes.string,
    userName: PropTypes.string,
    userPosition: PropTypes.string,
    userBlocked: PropTypes.bool,
    avatarSrc: PropTypes.string,
    actionIcons: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    onGoBackHandler: PropTypes.node,
};

export default UserHeader;
