import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import MultiChart from 'components/statistic/resources-chart';
import { barChartOptions, doughnutChartOptions } from 'components/statistic/charts-options';
import api from 'api/index';
import cx from './statistic-total-stats-page.module.scss';

const today = {
    numberOfProjects: {
        id: 1,
        title: 'Создано проектов',
        count: 0,
    },
    numberOfArticles: {
        id: 2,
        title: 'Создано статей',
        count: 0,
    },
    numberOfNews: {
        id: 3,
        title: 'Создано новостей',
        count: 0,
    },
    numberOfUploads: {
        id: 4,
        title: 'Загружено файлов',
        count: 0,
    },
    numberOfComments: {
        id: 5,
        title: 'Оставлено комментариев',
        count: 0,
    },
    numberOfUsers: {
        id: 6,
        title: 'Новых участников',
        count: 0,
    },
    numberOfLikes: {
        id: 7,
        title: 'Лайков',
        count: 0,
    },
    numberOfDislikes: {
        id: 8,
        title: 'Дизлайков',
        count: 0,
    },
};

const StatisticDashboardsActivities = () => {
    const [eventsCounts, setEventsCounts] = useState(null);
    const [eventsPerProject, setEventsPerProject] = useState(null);

    const [eventsPerOffice, setEventsPerOffice] = useState(null);
    const [eventsToday, setEventsToday] = useState(today);

    const [topActiveUsers, setTopActiveUsers] = useState(null);

    const fetchEventsCounts = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getEventsCounts(cancelToken.token);

            setEventsCounts({
                labels: ['Создание', 'Редактирование', 'Удаление', 'Коментирование', 'Прочтение'],
                datasets: [res.numberOfCreates, res.numberOfEdits, res.numberOfDeletes, res.numberOfComments, res.numberOfReads],
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchEventsPerProject = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getEventsPerProject(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((project) => {
                data.labels.push(project.title);
                data.datasets.push(project.numberOfEvents);
            });

            setEventsPerProject(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchEventsPerOffice = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getEventsPerOffice(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((office) => {
                data.labels.push(office.officeName || 'Без филиала');
                data.datasets.push(office.numberOfEvents);
            });

            setEventsPerOffice(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchEventsToday = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getEventsToday(cancelToken.token);

            setEventsToday((prevState) => {
                return Object.keys(res).map((key) => {
                    return { ...prevState[key], count: res[key] ? res[key] : 0 };
                });
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopActiveUsers = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopActiveUsers(cancelToken.token);
            const data = {
                labels: res.map((user) => `${user.user.firstName} ${user.user.lastName}`),
                datasets: [
                    { label: 'Создание', data: res.map((user) => user.numberOfCreates) },
                    { label: 'Редактирование', data: res.map((user) => user.numberOfEdites) },
                    { label: 'Удаление', data: res.map((user) => user.numberOfDeletes) },
                ],
            };

            setTopActiveUsers(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source(); 

        fetchEventsCounts(cancelToken);
        fetchEventsPerProject(cancelToken);
        fetchEventsPerOffice(cancelToken);
        fetchEventsToday(cancelToken);
        fetchTopActiveUsers(cancelToken);

        return () => {
          cancelToken.cancel();
        }
    }, [fetchEventsCounts, fetchEventsPerProject, fetchEventsPerOffice, fetchEventsToday, fetchTopActiveUsers]);

    return (
        <>
            <div className={cx.header}>
                <h1>Активность - Мониторинг</h1>
            </div>
            <div className={cx.chartRow}>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Действия в системе"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={eventsCounts?.labels}
                        data={eventsCounts?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Активность в проектах"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={eventsPerProject?.labels}
                        data={eventsPerProject?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Активность филиалов"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={eventsPerOffice?.labels}
                        data={eventsPerOffice?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Топ активности участников"
                        chartType="bar"
                        chartTypes={['bar']}
                        labels={topActiveUsers?.labels}
                        data={topActiveUsers?.datasets}
                        options={barChartOptions}
                    />
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Активность за сутки</h3>
                        {Object.keys(eventsToday).map((key, idx) => {
                            return (
                                <div key={idx} className={cx.listRow}>
                                    <div> {eventsToday[key].title}</div>
                                    <div>{eventsToday[key].count ? eventsToday[key].count : 0}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticDashboardsActivities;
