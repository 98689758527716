export const COLOR_FORMAT = {
    HEX: 'hex',
    RGB: 'rgba',
    CSS: 'css',
    HSL: 'hsl',
    HSB: 'hsb',
}

export const MULTI_LIST_OPTIONS = {
    'list-upper-alpha': 'upper-alpha',
    'list-lower-alpha': 'lower-alpha',
    'list-lower-roman': 'lower-roman',
    'list-upper-roman': 'upper-roman',
    'list-decimal': 'decimal',
    'list-disc': 'disc',
    'list-option-1': "'⊙ '",
    'list-circle': 'circle',
    'list-option-2': "'◍ '",
    'list-option-3': "'◆ '",
    'list-option-4': "'❖ '",
    'list-option-5': "'✦ '",
    'list-option-6': "'✧ '",
    'list-option-7': "'✱ '",
    'list-option-8': "'☆ '",
    'list-option-9': "'◌ '",
    'list-option-10': "'✓ '",
}

export const DEFAULT_FONT_FAMILY_SELECT_OPTIONS = [
    { title: 'Noto Sans', value: 'NotoSans, sans-serif', types: [400, 600, 700] },
    { title: 'Arial', value: 'Arial', types: [300, 400, 600, 700] },
    { title: 'Comic Sans MS', value: 'Comic Sans MS', types: [300, 400, 600, 700] },
    { title: 'Courier New', value: 'Courier New', types: [300, 400, 600, 700] },
    { title: 'Georgia', value: 'Georgia', types: [300, 400, 600, 700] },
    { title: 'Lucida Sans Unicode', value: 'Lucida Sans Unicode', types: [300, 400, 600, 700] },
    { title: 'Tahoma', value: 'Tahoma', types: [300, 400, 600, 700] },
    { title: 'Times New Roman', value: 'Times New Roman', types: [300, 400, 600, 700] },
    { title: 'Trebuchet MS', value: 'Trebuchet MS', types: [300, 400, 600, 700] },
    { title: 'Verdana', value: 'Verdana', types: [300, 400, 600, 700] },
    { title: 'Lobster', value: 'Lobster', types: [400] },
    { title: 'Comfortaa', value: 'Comfortaa', types: [300, 400, 600, 700] },
    { title: 'Lora', value: 'Lora', types: [400, 600, 700] },
    { title: 'Merriweather', value: 'Merriweather', types: [300, 400, 700] },
    { title: 'Roboto', value: 'Roboto', types: [300, 400, 700] },
    { title: 'Montserrat', value: 'Montserrat', types: [300, 400, 600, 700] },
    { title: 'Inter', value: 'Inter', types: [300, 400, 600, 700] },
    { title: 'Raleway', value: 'Raleway', types: [300, 400, 600, 700] },
    { title: 'Playfair Display', value: 'Playfair Display', types: [300, 400, 600, 700] },
    { title: 'Manrope', value: 'Manrope', types: [300, 400, 600, 700] },
    { title: 'Bitter', value: 'Bitter', types: [300, 400, 600, 700] },
    { title: 'Prata', value: 'Prata', types: [400] },
    { title: 'Poiret One', value: 'Poiret One', types: [400] },
    { title: 'Commissioner', value: 'Commissioner', types: [300, 400, 600, 700] },
    { title: 'Literata', value: 'Literata', types: [300, 400, 600, 700] },
    { title: 'News Cycle', value: 'News Cycle', types: [400, 700] },
    { title: 'Tenor Sans', value: 'Tenor Sans', types: [400] },
    { title: 'PT Serif Caption', value: 'PT Serif Caption', types: [300, 400] },
    { title: 'Nobile', value: 'Nobile', types: [400, 700] },
    { title: 'Overpass Mono', value: 'Overpass Mono', types: [300, 400, 600, 700] },
    { title: 'Noto Serif Display', value: 'Noto Serif Display', types: [300, 400, 600, 700] },
    { title: 'Marmelad', value: 'Marmelad', types: [400] },
    { title: 'Vollkorn SC', value: 'Vollkorn SC', types: [400, 600, 700] },
    { title: 'Podkova', value: 'Podkova', types: [400, 600, 700] },
    { title: 'Alegreya SC', value: 'Alegreya SC', types: [400, 700] },
    { title: 'Petit Formal Script', value: 'Petit Formal Script', types: [400] },
    { title: 'Manjari', value: 'Manjari', types: [400, 700] },
    { title: 'Magra', value: 'Magra', types: [400, 700] },
    { title: 'Amiko', value: 'Amiko', types: [400, 600, 700] },
    { title: 'Arsenal', value: 'Arsenal', types: [400, 700] },
    { title: 'Gabriela', value: 'Gabriela', types: [400] },
    { title: 'Andika', value: 'Andika', types: [400, 700] },
    { title: 'Wix Madefor Display', value: 'Wix Madefor Display', types: [400, 600, 700] },
    { title: 'Prosto One', value: 'Prosto One', types: [400] },
    { title: 'Ledger', value: 'Ledger', types: [400] },
    { title: 'Lunasima', value: 'Lunasima', types: [400, 700] },
    { title: 'Murecho', value: 'Murecho', types: [300, 400, 600, 700] },
    { title: 'Ysabeau', value: 'Ysabeau', types: [300, 400, 600, 700] },
    { title: 'Piazzolla', value: 'Piazzolla', types: [300, 400, 600, 700] },
  ];
