import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, Router } from '@reach/router';
import { useSelector } from 'react-redux';
import { useAuthorized, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import { ContentWrapper } from 'containers/content-wrapper';
import { FoldersTree, MobileTree } from 'components/trees';
import Icon, { Icons } from 'uikit/icon';
import UsersPage from 'app/users/users-page/users-page';
import GroupsPage from 'app/users/groups-page/groups-page';
import BlockedUsersPage from 'app/users/blocked-users-page/blocked-users-page';
import cs from './users.module.scss';
import { useCustomMobileMenu } from 'lib/hooks/useCustomMobileMenu';
import { useWindowSize } from 'lib/hooks/useWindowSize';

const Users = ({ navigate, location, ...props }) => {
    const { width } = useWindowSize();
    const [showTree, setShowTree] = useState(false);

    const { platform } = useGlobalContext();
    const treeRef = useRef(null);

    const [projectUuid, setProjectUuid] = useState('');
    const [tab, setActiveTab] = useState(null);

    const newsPicker = useSelector(selectNewsPicker);
    const { isAuthorizedAction } = useAuthorized();

    const visibleBlocked = isAuthorizedAction([GLOBAL_ACTIONS.VIEW_BLOCKED_USERS]);

    useEffect(() => {
        const s = props['*'].split('/');

        let _activeTab = 'users-page';

        if (s.length === 1) {
            if (s[0] === '') {
                navigate('/users/users-page/all', { replace: true });
            } else {
                _activeTab = s[0];
                navigate(`/users/${_activeTab}/all`, { replace: true });
            }
        } else {
            _activeTab = s[0];
            const _projectUuid = s[1];

            if ((tab && tab !== _activeTab) || (projectUuid !== '' && projectUuid !== _projectUuid)) {
                navigate(`/users/${_activeTab}/${_projectUuid}`, { replace: true });
            }
            setProjectUuid(_projectUuid);
        }
        setActiveTab(_activeTab);
    }, [location.pathname, navigate, props, tab, projectUuid]);

    const getNodeLink = useCallback((node) => {
        return '/users' + (tab ? '/' + tab : '') + (node.id ? '/' + node.id : '/all');
    }, [tab])

    useCustomMobileMenu(useCallback((onClose) => {
        return (
            <MobileTree
                ref={treeRef}
                getNodeLink={getNodeLink}
                allButtonTitle="Вся система"
                withArticles={false}
                onSelect={(node) => {
                    onClose();

                    if (node !== null) {
                        return;
                    }

                    treeRef?.current?.tree.selectNode(node, { silent: true });
                    navigate('/users' + (tab ? '/' + tab : '') + '/all');
                }}
            />
        );
    }, [navigate, tab, getNodeLink]));
    useEffect(() => {
        const fetch = () => {
            if (!projectUuid || projectUuid === 'all') {
                return;
            }

            const tree = treeRef?.current?.tree;

            if (tree && tree.nodes.length !== 0) {
                let node = tree.getNodeById(projectUuid);
                let nodes = [];

                while (true) {
                    if (!node.id) {
                        break;
                    }
                    nodes.push(node);
                    node = node.parent;
                }

                nodes.reverse().forEach((node) => {
                    tree.openNode(node, { silent: true });
                    tree.selectNode(node, { silent: true });
                });
            } else {
                setTimeout(fetch, 300);
            }
        };
        fetch();
    }, [treeRef, projectUuid]);

    return (
        <ContentWrapper>
            <div className={classNames(cs.base, {[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.toggleTreeButton} onClick={() => setShowTree(!showTree)}>
                    <Icon type={Icons.SANDWICH} width={20} height={14} />
                </div>
                {platform !== 'mobile' && (
                    <div className={classNames(cs.treeContainer, {
                        [cs.treeContainerHidden]: width >= 768 && width <= 1024 && !showTree
                    })} onClick={() => setShowTree(!showTree)}>
                        <div
                            className={classNames(cs.tree, { [cs.withNewsTicker]: newsPicker.count > 0 })}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            {tab !== 'groups-page' && (
                                <FoldersTree
                                    ref={treeRef}
                                    getNodeLink={getNodeLink}
                                    allButtonTitle={'Все проекты'}
                                    path={`users/${tab}/all`}
                                    scrollToNode={projectUuid === 'all' ? null : projectUuid}
                                />
                            )}
                            {tab === 'groups-page' && (
                                <div className={cs.noTree}>
                                    <Icon type={Icons.TEXT_ALIGN_LEFT} width={24} height={24} />
                                    <div>Иерархия отсутствует</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={cs.contentUsers}>
                    <ul className={cs.usersUl}>
                        <li className={tab === 'users-page' ? cs.active : ''}>
                            <Link to={`/users/users-page/${projectUuid || 'all'}`}>
                                Активные
                            </Link>
                        </li>
                        {visibleBlocked &&
                            <li className={tab === 'blocked-users' ? cs.active : ''}>
                                <Link to={`/users/blocked-users/${projectUuid || 'all'}`}>
                                    Заблокированные
                                </Link>
                            </li>}
                        <li className={tab === 'groups-page' ? cs.active : ''}>
                            <Link to={'/users/groups-page/all'} replace={true}>
                                Группы
                            </Link>
                        </li>
                    </ul>
                    <div className={cs.tableWrapper}>
                        <Router className={cs.router}>
                            <UsersPage
                                id="all"
                                path="users-page/all"
                                hasAnyAuthorities={[GLOBAL_ACTIONS.USER_PAGE]}
                                treeRef={treeRef}
                            />
                            <UsersPage
                                path="users-page/:id"
                                hasAnyAuthorities={[GLOBAL_ACTIONS.USER_PAGE]}
                                treeRef={treeRef}
                            />
                            <BlockedUsersPage
                                path="blocked-users/:id"
                                hasAnyAuthorities={[GLOBAL_ACTIONS.VIEW_BLOCKED_USERS]}
                                project={projectUuid}
                                treeRef={treeRef}
                            />
                            <GroupsPage
                                path="groups-page/all"
                                hasAnyAuthorities={[GLOBAL_ACTIONS.GROUP_READ]}
                            />
                        </Router>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
};

export default Users;
