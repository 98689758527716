import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useCombinedRefs, useGlobalContext } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import { LocationTree, MobileTree } from 'components/trees';
import Checkbox from 'uikit/checkbox';
import IconButton from 'uikit/icon-button';
import cs from './location-change.module.scss';
import CommonDialog from 'components/trees/common-dialog';
import Input from 'uikit/input';
import TreeUtils from 'lib/util/tree.util';
import Loading from 'uikit/loading';

const LocationChange = ({
                            error, required, visualPath, onClearPath, onChange, autoOpenPath,
                            withSections = true, forwardedRef, excludeNodes, title, disableChange,
                            disableChangeTitle, onToggleDisableChange, placeholder,
                            rights, fetchTreeFunc, loading = false, locationPathClassName
                        }) => {
    const { platform } = useGlobalContext();
    const [selectedNode, setSelectedNode] = useState(null);
    const innerRef = useCombinedRefs(forwardedRef);

    const [dialog, setDialog] = React.useState(false);

    const _onClearPath = e => {
        e.stopPropagation();

        onClearPath();
        setDialog(false);
    };

    const _onChange = node => {
        onChange(node);
        setDialog(false);
    };

    useEffect(() => {
        if (error) {
            innerRef.current.classList.add('field-invalid');
        } else {
            innerRef.current.classList.remove('field-invalid');
        }
    }, [error, innerRef]);

    return (
        <>
            <div className={classNames(cs.locationChange, { [cs.error]: error })}>
                <div className={classNames(cs.titleBlock, {
                    [cs.required]: required
                })}>{title}
                </div>
                {disableChangeTitle &&
                <Checkbox className={cs.disabledCheckbox} checked={disableChange} label={disableChangeTitle}
                          onClick={onToggleDisableChange}/>}
                {disableChange && <div className={cs.disabledOverflow}/>}
                <div
                    className={classNames(cs.blockLocation, {[cs.disabled]: disableChange})}
                    ref={innerRef}
                    onClick={() => setDialog(true)}
                >
                    {loading && <div className={cs.loading}><Loading withOverlay={false} small/></div>}
                    <div id="locationBlockDiv" data-testid="locationBlockDiv" className={classNames(cs.path, locationPathClassName)}>
                        {visualPath && visualPath.length > 0
                            ? visualPath?.join(" / ")
                            : <span className={cs.placeholder}>{placeholder}</span>}
                    </div>
                    {visualPath.length > 0 && (
                        <IconButton
                            id="locationBlockClearIcon"
                            icon={<Icon type={Icons.CROSS}
                            width={10}
                            height={10}/>}
                            onClick={_onClearPath}
                            className={cs.iconCross}
                        />
                    )}
                    {visualPath.length === 0 && (
                        <Icon id="locationBlockMoreIcon" type={Icons.MORE}/>
                    )}
                </div>
                {error && <div className={cs.errorBlock}>{error}</div>}
            </div>

            {dialog === true && platform !== 'mobile' &&
            <LocationTree onDismiss={() => setDialog(false)} onSubmit={_onChange} autoOpenPath={autoOpenPath}
                          withSections={withSections} open excludeNodes={excludeNodes} rights={rights}
                          fetchTreeFunc={fetchTreeFunc}/>}
            {dialog === true && platform === 'mobile' &&
            <CommonDialog title="Выбор расположения" open={true} onDismiss={() => setDialog(false)}
                          onSubmit={() => {
                              onChange(selectedNode);
                              setDialog(false);
                          }}>
                <div className={cs.content}>
                    <Input className={cs.path} value={selectedNode ? TreeUtils.getVisualPathTo(selectedNode).join(' / ') : '/'} data-testid="locationInputMobile"/>
                    <MobileTree
                        onSelect={node => setSelectedNode(node)}
                        withCheckboxes={false}
                        fetchTreeFunc={fetchTreeFunc}
                        withSections={withSections}
                        withArticles={false}
                    />
                </div>
            </CommonDialog>}
        </>
    );
};

LocationChange.propTypes = {
    withSections: true
};

LocationChange.propTypes = {
    onClearPath: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    visualPath: PropTypes.array,
    autoOpenPath: PropTypes.array,
    withSections: PropTypes.bool,
    error: PropTypes.string,
    excludeNodes: PropTypes.array,
    required: PropTypes.bool,
    title: PropTypes.string,
    disableChange: PropTypes.bool,
    disableChangeTitle: PropTypes.string,
    onToggleDisableChange: PropTypes.func,
    placeholder: PropTypes.string,
    fetchTreeFunc: PropTypes.func,
    locationPathClassName: PropTypes.string,
};

LocationChange.defaultProps = {
    title: 'Выберите месторасположение',
    disableChange: false,
    disableChangeTitle: '',
    placeholder: 'Нажмите для выбора расположения...',
    locationPathClassName: "",
}

export default React.forwardRef((props, ref) => <LocationChange {...props} forwardedRef={ref}/>);
