import React, { forwardRef, useEffect, useMemo } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

import { Controls } from './Controls';
import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';
import { useCombinedRefs, useEventListener } from 'lib/hooks';
import cx from './video-player.module.scss';

export const VideoPlayer = forwardRef(
    (
        {
            onClick,
            onReady,
            onError,
            onPlayerUnmount,
            width = null,
            height = null,
            autoplay = false,
            responsive = true,
            fluid = false,
            src,
        },
        ref
    ) => {
        const videoContainerRef = useCombinedRefs(ref);
        const videoRef = React.useRef(null);
        const playerRef = React.useRef(null);

        const [watchedTime] = React.useState(0);
        const [currentTime, setCurrentTime] = React.useState(0);
        const [volume, setVolume] = React.useState(1);

        const [status, setStatus] = React.useState('PAUSE');

        const options = useMemo(
            () => ({
                autoplay,
                controls: false,
                responsive,
                fluid,
                width,
                height,
                aspectRatio: '16:9',
            }),
            [autoplay, width, height, responsive, fluid]
        );

        const getCurrentTime = () => {
            if (!playerRef.current) {
                return 0;
            }

            return playerRef.current.currentTime();
        };

        const getDuration = () => {
            if (!playerRef.current) {
                return 0;
            }

            return playerRef.current.duration();
        };

        useEffect(() => {
            if (!playerRef.current && src) {
                const videoElement = document.createElement('video-js');

                videoElement.classList.add('vjs-big-play-centered');
                videoRef.current.appendChild(videoElement);

                const player = (playerRef.current = videojs(videoElement, options));

                player.src(src);

                player.on('ready', () => {
                    videojs.log('player is ready');
                    onReady?.(player);
                });
                player.on('error', e => {
                    videojs.log('player error', e);

                    onError?.(e);
                });
            } else {
                const player = playerRef.current;
                player?.autoplay(options.autoplay);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [options, videoRef, src]);

        useEffect(() => {
            const player = playerRef.current;

            if (player) {
                player.on('timeupdate', () => {
                    setCurrentTime(player.currentTime() ?? 0);
                });

                player.on('volumechange', () => {
                    setVolume(player.volume() ?? 0);
                });

                player.on('ended', () => {
                    player.pause();
                    setStatus('END');
                });
            }

            return () => {
                if (player && !player.isDisposed()) {
                    onPlayerUnmount?.(Math.round(player.currentTime() ?? 0));
                    player.dispose();
                    playerRef.current = null;
                }
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [playerRef]);

        const onPlayStatusChange = () => {
            if (!playerRef.current) {
                return;
            }

            setStatus(prevState => {
                switch (prevState) {
                    case 'PAUSE':
                    case 'END':
                        playerRef.current?.play();

                        return 'PLAY';
                    case 'PLAY':
                        playerRef.current?.pause();

                        return 'PAUSE';

                    default:
                        return 'PAUSE';
                }
            });
        };

        const onTimeChange = time => {
            if (!playerRef.current) {
                return;
            }

            playerRef.current.currentTime(time);
        };

        const onVolumeChange = value => {
            if (!playerRef.current) {
                return;
            }

            playerRef.current.volume(value);
        };

        const onFullscreenHandler = async () => {
            if (document.fullscreenElement) {
                await document.exitFullscreen();
            } else {
                await videoContainerRef.current?.requestFullscreen();
            }
        };

        useEventListener(
            'fullscreenchange',
            () => {
                const videoEl = playerRef.current?.el();
                if (!document.fullscreenElement) {
                    videoEl.style.removeProperty('height');
                    videoEl.style.removeProperty('padding-top');
                } else {
                    videoEl?.classList.add('vjs-fullscreen');
                    videoEl.style.height = '100%';
                    videoEl.style.paddingTop = '0';
                }
            },
            videoContainerRef.current
        );

        return (
            <div ref={videoContainerRef} className={cx.videoContainer} onClick={onClick}>
                <div className={cx.videoWrapper} onClick={onPlayStatusChange}>
                    <div className={cx.video} ref={videoRef} />
                    {status === 'PAUSE' && (
                        <div className={cx.icon}>
                            <Icon type={Icons.PlayFilled} width={24} height={24} color="white" />
                        </div>
                    )}
                    {status === 'END' && (
                        <div className={cx.icon}>
                            <Icon type={Icons.Refresh} width={24} height={24} color="white" />
                        </div>
                    )}
                </div>
                <Controls
                    status={status}
                    volume={volume}
                    watchedTime={watchedTime}
                    currentTime={currentTime}
                    getDuration={getDuration}
                    getCurrentTime={getCurrentTime}
                    onPlayStatusChange={onPlayStatusChange}
                    onFullscreenHandler={onFullscreenHandler}
                    onTimeChange={onTimeChange}
                    onVolumeChange={onVolumeChange}
                />
            </div>
        );
    }
);
