import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import cx from '../../editor.module.scss';

export function GoogleDocView(props) {
  const node = props.node;

  return (
    <NodeViewWrapper>
      <div className={cx.googleDoc} data-node-type="remote-google-doc">
        <iframe title="doc" src={node.attrs.src} width={node.attrs.width} height={node.attrs.height} />
      </div>
    </NodeViewWrapper>
  );
}
