import { mergeAttributes, Node } from '@tiptap/core';

export const TableTitle = Node.create({
    name: 'tableTitle',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    content: 'paragraph',

    tableRole: 'table_title',

    isolating: true,

    parseHTML() {
        return [{ tag: 'caption' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['caption', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});
