import React, { useCallback, useEffect, useState, useMemo } from 'react';
import Modal from 'react-modal';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import { desktopStyles } from 'lib/util/modalStyles';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import { SelectSearch } from 'uikit/select';
import { useDialog, useGlobalContext } from 'lib/hooks';
import { SelectOption } from 'uikit/select/select';
import Button from 'uikit/button';
import { EditRowToolbar } from 'uikit/table';
import { selectUsers } from 'slice/authSlice';
import Confirmation from 'components/confirmation';
import { TableComponent } from 'components/data-components';
import modals from 'app/global-settings/license-page/LicenseUploadModal.module.scss';
import cs from './AccessManagement.module.scss';
import api from 'api/index';

const AccessManagement = ({ activeLicenses, allActiveLicenses, allActiveLicensesTotal, getAllActiveLicenses, getLicenseInfo }) => {
    const { platform } = useGlobalContext();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [showBindModal, setShowBindModal] = useState(false);
    const [editValue, setEditValue] = useState();

    const columns = useMemo(() => {
        return [
            {
                Header: 'Лицензия',
                accessor: 'subscriptionName',
                width: 120,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Лицензия</div>}
                        {data.row.original['subscriptionName']}
                    </div>
            },
            {
                Header: 'Пользователь',
                width: 120,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Пользователь</div>}
                        <Link to={'/edit-user/' + data.row.original['user']['login']}>
                            {data.row.original['user']['firstName'] + ' ' + data.row.original['user']['lastName']}
                        </Link>
                    </div>
            },
            {
                Header: 'Логин',
                width: 90,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Логин</div>}
                        {data.row.original['user']['login']}
                    </div>
            },
            {
                Header: 'Активна',
                width: 60,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Активна</div>}
                        {data.row.original['active'] ? 'Да' : 'Нет'}
                    </div>
            },
            {
                Header: 'Истекает',
                width: 90,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Истекает</div>}
                        {new Date(data.row.original.expirationDate).toLocaleString().slice(0, -3)}
                    </div>
            },
            {
                id: 5,
                Header: '',
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                width: 40,
                Cell: function (data) {
                    let toolbar = [];
                    // toolbar.push({
                    //     icon: Icons.EDIT_PEN,
                    //     tooltip: 'Редактировать',
                    //     onClick: (data) => {
                    //         setEditValue(data);
                    //         setShowBindModal(true);
                    //     },
                    // });
                    toolbar.push({
                        icon: Icons.TRASH,
                        tooltip: 'Удалить',
                        onClick: (data) => {
                            openDialog({
                                title: 'Удаление',
                                text: 'Вы уверены, что хотите удалить лицензию у пользователя?',
                                closeBtnText: 'Нет, отменить',
                                submitBtnText: 'Да, удалить',
                                onSubmit: async () => {
                                    await api.license.deleteSubscriptionToLicense(data.licenseId, data.user.id);
                                    await api.user.blockUser(data.user.login);

                                    getAllActiveLicenses();
                                    getLicenseInfo();

                                    closeDialog();
                                },
                                onClose: closeDialog,
                                contentType: 'TEXT'
                            });
                        },
                    });
                    return EditRowToolbar(toolbar, null, true, false, true)(data);
                },
            },
        ];
    }, [platform, openDialog, closeDialog, getAllActiveLicenses, getLicenseInfo]);

    const toggleModal = () => {
        setShowBindModal(!showBindModal);
        setEditValue(undefined);
    };
    const onAddAccept = () => {
        getAllActiveLicenses();
        getLicenseInfo();

        toggleModal();
    };

    return (
        <div className={cs.accessManagement}>
            <div className={cs.accessManagementTitle}>
                <h1>Управление доступом участников</h1>
                {activeLicenses.length !== 0 &&
                    <button className={cs.addBtn} onClick={toggleModal}>
                        <div className={cs.icon}>
                            <Icon type={Icons.ADD} width={8} height={8}/>
                        </div>
                        Привязать лицензию
                    </button>}
            </div>
            <TableComponent
                name="activeLicense"
                searchTitle="Поиск по пользователю"
                columns={columns}
                data={allActiveLicenses}
                total={allActiveLicensesTotal}
                onPaginate={getAllActiveLicenses}
                isPaginate={false}
                isMobile={platform === 'mobile'}
            />
            <BindLicenseModal
                open={showBindModal}
                onAccept={onAddAccept}
                onClose={toggleModal}
                initialValue={editValue}
                licenses={activeLicenses.map((license, index) => ({ label: license.licenseName, value: license.id ?? index }))}
            />
            <Confirmation {...dialogState} />
        </div>
    );
};

const BindLicenseModal = ({ open, onAccept, onClose, initialValue, licenses }) => {
    const selector = useSelector((state) => state);

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const [usersSearchField, setSearchField] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);

    const [currentLicense, setCurrentLicense] = useState();
    const [errors, setErrors] = useState();

    const [licenseError, setLicenseError] = useState(false);
    const [userError, setUserError] = useState(false);

    const [oldUser, setOldUser] = useState(null);
    const [oldLicense, setOldLicense] = useState(null);

    const filterUser = useCallback((value = '') => {
        return Object.assign([], users).filter(
            (user) => user['label'].toLowerCase().indexOf(value.toLowerCase()) !== -1
                || user['value'].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }, [users]);
    const onUserSelectInputChange = (e, setValue) => {
        if (setValue.action === 'input-change') {
            if (e.toString().length > 0) {
                setFilteredUsers(filterUser(e));
            } else if (e.toString().length === 0) {
                setFilteredUsers([]);
                setSelectedUser(null);
            }
            setSearchField(e);
        }
    };

    const onUserSelectMenuOpen = () => {
        if (selectedUser && !usersSearchField) {
            setSearchField(selectedUser.label);
            setFilteredUsers(filterUser(selectedUser.label));
        }
    };
    const onUserSelectChange = (e) => {
        if (errors?.user) {
            const _errors = { ...errors };
            delete _errors.user;
            setErrors(_errors);
        }

        setSearchField('');
        setSelectedUser(e);
    };

    const hideErrors = () => {
        setLicenseError(false);
        setUserError(false);
    }
    const handleClose = () => {
        onClose();
        setCurrentLicense(null);
        setSelectedUser(null);
        hideErrors();
    };
    const handleSubmit = async () => {
        const licenseId = currentLicense?.value;
        const userId = selectedUser?.payload?.id;
        hideErrors();

        if (licenseId && userId) {
            try {
                await api.license.linkUserToLicense(licenseId, userId);

                if (oldLicense && oldUser) {
                    await api.license.deleteSubscriptionToLicense(oldLicense, oldUser);
                }

                onAccept();

                setCurrentLicense(null);
                setSelectedUser(null);
                setOldUser(null);
                setOldLicense(null);
            } catch (e) {
                handleClose();
            }
        } else {
            if (!licenseId) {
                setLicenseError(true);
            }

            if (!userId) {
                setUserError(true);
            }
        }
    };
    const handleLicenseFocus = () => {
        setLicenseError(false);
    }
    const handleUserError = () => {
        setUserError(false);
    }

    useEffect(() => {
        if (initialValue) {
            setCurrentLicense({
                label: initialValue.subscriptionName,
                value: initialValue.licenseId,
            });

            const user = initialValue.user;
            setSelectedUser({
                label: `${user.firstName} ${user.lastName}`,
                value: user.login,
                payload: user,
            });

            setOldLicense(initialValue.licenseId);
            setOldUser(user.id);
        }
    }, [initialValue]);
    useEffect(() => {
        const fetchUsers = async () => {
            const response = await selectUsers(selector);
            setUsers(
                response.content.map((user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.login,
                    payload: user,
                }))
            );
        };

        fetchUsers();
    }, [selector]);

    return (
        <Modal className={modals.modal} isOpen={open} contentLabel="Привязать лицензию" style={{ ...desktopStyles, height: 250 }}>
            <div className={modals.modalContent} style={{ height: 300 }}>
                <div className={modals.title}>
                    <div>Привязать лицензию</div>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS} />} onClick={handleClose} />
                </div>
                <div className={cs.form}>
                    <SelectOption id="newsFormPriorityField" label="Укажите лицензию" options={licenses} required
                                  defaultValue={{ label: '', value: '' }} value={currentLicense} isError={licenseError}
                                  onChange={setCurrentLicense} onFocus={handleLicenseFocus}/>
                    <SelectSearch label="Выберите участника" options={filteredUsers} value={selectedUser} isSearchable
                                  required inputValue={usersSearchField} error={errors?.user} filterOption={null}
                                  noDataMessage="Ничего не найдено" onMenuOpen={onUserSelectMenuOpen}
                                  onInputChange={onUserSelectInputChange} onChange={onUserSelectChange}
                                  isError={userError} onFocus={handleUserError}/>
                </div>
                <div className={cs.buttons}>
                    <Button onClick={handleClose}>Отмена</Button>
                    <Button color="green" onClick={handleSubmit}>Отправить</Button>
                </div>
            </div>
        </Modal>
    );
};

export default AccessManagement;
