import { mergeAttributes, Node } from '@tiptap/core';

export const Font = Node.create({
    name: 'font',

    group: 'inline',
    inline: true,

    content: 'inline*',

    addAttributes() {
        return {
            color: {
                default: null,
            },
            style: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'font',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['font', mergeAttributes(HTMLAttributes), 0];
    },
});
