import React, { useRef } from 'react';
import { CustomPicker } from 'react-color';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import cx from './color-picker.module.scss';
import { InputFields } from './InputFields';

const Bar = ({ type, hsl }) => {
    return (
        <div
            style={{
                boxShadow: 'rgb(0 0 0 / 60%) 0px 0px 2px',
                width: 8,
                height: 8,
                border: '1px solid #fff',
                borderRadius: 9999,
                background:
                    type === 'saturation'
                        ? 'transparent'
                        : type === 'hue'
                        ? `hsl(${Math.round(hsl['h'])},100%,50%)`
                        : `hsla(${Math.round(hsl['h'])},100%,50%,${hsl['a']})`,
            }}
        />
    );
};

const BarSaturation = props => {
    return <Bar type="saturation" {...props} />;
};

const BarHue = props => {
    return <Bar type="hue" {...props} />;
};

const BarAlpha = props => {
    return <Bar type="alpha" {...props} />;
};

const CustomColorPicker = ({ hex, hsl, hsv, rgb, onChange }) => {
    const ref = useRef(null);

    const onInputsChange = data => {
        onChange(data);
    };

    const onSaturationChange = newColor => {
        onChange(newColor);
    };

    const onHueChange = newHue => {
        onChange(newHue);
    };

    const onAlphaChange = newAlpha => {
        onChange(newAlpha);
    };

    return (
        <div className={cx.colorPicker} style={{ width: '100%' }}>
            <div>
                <div className={cx.saturation}>
                    <Saturation
                        hsl={hsl}
                        hsv={hsv}
                        style={{ pointer: { cursor: 'pointer' } }}
                        pointer={BarSaturation}
                        onChange={onSaturationChange}
                        ref={ref}
                    />
                </div>
                <div className={cx.hue}>
                    <Hue hsl={hsl} width="auto" height={10} pointer={BarHue} onChange={onHueChange} />
                </div>
                <div className={cx.alpha}>
                    <Alpha rgb={rgb} hsl={hsl} pointer={BarAlpha} onChange={onAlphaChange} />
                </div>
            </div>
            <InputFields hsl={hsl} rgb={rgb} hex={hex} onChange={onInputsChange} />
        </div>
    );
};

export const ColorPicker = CustomPicker(CustomColorPicker);
