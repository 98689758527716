import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import formatDateTime from 'lib/helpers/formatDateTime';
import MultiChart from 'components/statistic/resources-chart';
import { doughnutChartOptions, lineChartOptions } from 'components/statistic/charts-options';
import api from 'api/index';
import cx from './statistic-total-stats-page.module.scss';

const StatisticDashboardsUsers = () => {
    const [usersPerDay, setUsersPerDay] = useState(null);
    const [numberOfDayUsers, setNumberOfDayUsers] = useState(0);

    const [usersOnlinePerHour, setUsersOnlinePerHour] = useState(null);
    const [rolesCounts, setRolesCounts] = useState(null);

    const [rolesCountsOnline, setRolesCountsOnline] = useState(null);
    const [officesUsersCount, setOfficesUsersCount] = useState(null);

    const fetchUsersPerDay = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getUsersPerDay(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((user) => {
                data.labels.push(formatDateTime(new Date(user.date)));
                data.datasets.push(user.numberOfUsers);
            });

            if (res && res.length) {
                setNumberOfDayUsers(res[res.length - 1]['numberOfDayUsers']);
            }

            setUsersPerDay(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchUsersOnline = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getUsersPerHourOnline(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((user) => {
                data.labels.push(user.eventDateHour);
                data.datasets.push(user.numberOfUsers);
            });

            setUsersOnlinePerHour(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchRolesCount = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getRolesCount(cancelToken.token);
            const roles = { labels: [], datasets: [] };

            res.roleItems.forEach((role) => {
                roles.labels.push(role.roleTitle);
                roles.datasets.push(role.count);
            });

            setRolesCounts(roles);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchRolesCountOnline = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getRolesCountOnline(cancelToken.token);
            const roles = { labels: [], datasets: [] };

            res.roleItems.forEach((role) => {
                roles.labels.push(role.roleTitle);
                roles.datasets.push(role.count);
            });

            setRolesCountsOnline(roles);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchUsersCountOfEachOffice = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getUsersCountOfEachOffice(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((office) => {
                data.labels.push(office.officeName || 'Без филиала');
                data.datasets.push(office.numberOfUsers);
            });

            setOfficesUsersCount(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        fetchUsersPerDay(cancelToken);
        fetchUsersOnline(cancelToken);
        fetchRolesCount(cancelToken);
        fetchRolesCountOnline(cancelToken);
        fetchUsersCountOfEachOffice(cancelToken);

        return () => {
            cancelToken.cancel();
        };
    }, [fetchUsersPerDay, fetchUsersOnline, fetchRolesCount, fetchRolesCountOnline, fetchUsersCountOfEachOffice]);

    return (
        <>
            <div className={cx.header}>
                <h1>Участники - Мониторинг</h1>
            </div>
            <div className={cx.chartRow}>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Участников в системе"
                        chartType={'line'}
                        chartTypes={['line']}
                        labels={usersPerDay?.labels}
                        data={usersPerDay?.datasets}
                        options={lineChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Роли участников"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={rolesCounts?.labels}
                        data={rolesCounts?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Участники онлайн"
                        chartType={'line'}
                        chartTypes={['line']}
                        labels={usersOnlinePerHour?.labels}
                        data={usersOnlinePerHour?.datasets}
                        options={lineChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Роли (Онлайн)"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={rolesCountsOnline?.labels}
                        data={rolesCountsOnline?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Разделение по филиалам"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={officesUsersCount?.labels}
                        data={officesUsersCount?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <div className={cx.sessionTimeWrapper}>
                        <div className={cx.sessionTimeTitle}>Среднее время сессии</div>
                        <div className={cx.sessionTime}>02:41:17</div>
                    </div>
                    <div className={cx.sessionTimeWrapper}>
                        <div className={cx.sessionTimeTitle}>Новых участников за последние сутки</div>
                        <div className={cx.sessionTime}>{numberOfDayUsers}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticDashboardsUsers;
