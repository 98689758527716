import { useCallback, useState } from 'react';

const defaultDialogState = {
    isOpen: false,
    onChange: () => {},
    onRequestClose: () => {},
    onRequestSubmit: () => {},
    title: '',
    subTitle: '',
    text: '',
    contentType: 'TEXT',
    color: 'green',
    closeBtnText: '',
    submitBtnText: ''
};

export const useDialog = () => {
    const [dialogState, setDialogState] = useState({ defaultDialogState });

    const openDialog = useCallback(({ title, subTitle, text, color, contentType, onChange, onSubmit, onClose, closeBtnText, submitBtnText }) => {
        setDialogState({
            isOpen: true,
            title,
            subTitle,
            text,
            color,
            contentType,
            closeBtnText,
            submitBtnText,
            onChange,
            onRequestSubmit: onSubmit,
            onRequestClose: onClose,
        });
    }, []);

    const closeDialog = useCallback(() => {
        setDialogState({ ...defaultDialogState });
    }, []);

    return { dialogState, openDialog, closeDialog };
};