import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import TagsInput from 'react-tagsinput';
import { useSelector } from 'react-redux';
import { NewsFormModel, getInitialExactQueryGroupsItem } from 'model/news/news-form-model';
import { useMessage } from 'lib/hooks/useMessage';
import { tickerColorOptions, tickerTimeRangeOptions } from 'lib/config/constant';
import Input from 'uikit/input';
import Checkbox from 'uikit/checkbox';
import Editor from 'editors/editor';
import { DateTimePicker } from 'uikit/datetime';
import Divider from 'uikit/divider';
import Icon, { Icons } from 'uikit/icon';
import { SelectOption } from 'uikit/select/select';
import LabelWithColor from 'uikit/select/components/label-with-color';
import Button from 'uikit/button';
import IconButton from 'uikit/icon-button';
import InputImage from 'components/input-image';
import Card from 'components/card';
import { NODE_TYPES } from 'components/trees';
import Attachments from 'components/attachments';
import LocationChange from 'components/location-change';
import cx from 'components/aritcle-form/article-form.module.scss';
import cs from './news-form.module.scss';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { useGlobalContext, useManualFormDataChange } from 'lib/hooks';
import api from 'api/index';
import Switch from 'uikit/switch/switch';
import TextArea from 'uikit/textarea';
import { selectEditorExperimentalSwitch, selectEditorType } from 'slice/authSlice';
import { TreePropContext } from 'containers/side-tree-wrapper';

const priorityOptions = [
    { label: 'Обычная', value: 'MEDIUM' },
    { label: 'Срочная', value: 'HIGH' }
];

const NewsForm = ({ news, loading, children, edit = false }) => {
    const { platform, formDataChanged, setFormDataChanged, setFormDataChangedSubmitHandler } = useGlobalContext();
    const { addError } = useMessage();
    const [isNewsPickerOpen, toggleNewsPickerOpen] = useState(false);
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const newsRef = useRef(news);
    const editorRef = useRef(null);
    const treePropsContext = React.useContext(TreePropContext);
    const [isLogoEdit, setIsLogoEdit] = useState(false);
    const [dueTo, setDueTo] = useState(!!news.from);
    const { isFormDataTouched, handleManualFormDataChange } = useManualFormDataChange(edit);

    const editorType = useSelector(selectEditorType);
    const editorExperimentalSwitch = useSelector(selectEditorExperimentalSwitch);

    const _onDestinationChange = node => {
        if (node.nodeType !== NODE_TYPES.PROJECT) {
            addError('Для выбора доступны только проекты.');
        } else {
            news.parentProjectId = node.id;
            news.setVisualPath(node);
            news.validateFields(['location']);
            news.commit();
        }
        setFormDataChanged(true);
    };

    const handleMinimize = () => {
        const { reloadTree } = treePropsContext;
        reloadTree();
    };

    const onEditorSaveInstance = editor => {
        news.setEditorInstance(editor);
    };

    const onClearPath = () => {
        news.clearPath();
        news.commit();
        handleManualFormDataChange();
    };

    const onLogoRemove = () => {
        news.logo = '';
        news.logoUuid = '';
        news.commit();
    };

    const onLogoChange = (logo) => {
        handleManualFormDataChange();
        news.logo = logo;
        news.commit();
        setIsLogoEdit(false);
    };

    const ChangeTicker = () => {
        news.isTicker = !news.isTicker;
        news.commit();
        setFormDataChanged(true);
    };

    const toggleGlobal = () => {
        if (news.global) {
            news.global = false;
        } else {
            news.clearPath();
            news.global = true;
        }
        news.commit();
        setFormDataChanged(true);
    }

    const onexactQueryGroupsItemDelete = (index) => {
        if (news.exactQueryGroups.length === 1) {
            news.exactQueryGroups = [
              getInitialExactQueryGroupsItem()
            ];
        } else {
            news.exactQueryGroups.splice(index, 1);
        }
        news.commit();
    }
    const onexactQueryGroupsAdd = () => {
        news.exactQueryGroups.push(getInitialExactQueryGroupsItem())
        news.commit();
    }

    const onTitleFocus = () => {
        news.touched.title = true;
    };
    const onTitleChange = (value) => {
        handleManualFormDataChange();

        const re = new RegExp(String.fromCharCode(160), "g");
        news.title = value.replace(re, " ");

        news.validateFields(['title']);
        news.commit();
    };

    useEffect(() => {
      if (!loading) {
        newsRef.current = cloneDeep(news);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    useEffect(() => {
        if (!loading){
            const {
                logo: refLogo,
                title: refTitle,
                priority: refPriority,
                meta: {
                    visualPath: refVisualPath
                },
                from: refFrom,
                originalLifeTime: refOriginalLifeTime,
                tickerTime: refTickerTime,
                tickerColor: refTickerColor,
                global: refGlobal
            } = newsRef.current;
            const {
                logo,
                title,
                priority,
                meta: {
                    visualPath
                },
                from,
                originalLifeTime,
                tickerTime,
                tickerColor,
                global
            } = news;

            const checkedRefParams = [refLogo, refTitle, refPriority, refVisualPath, refFrom, refOriginalLifeTime, refTickerTime, refTickerColor, refGlobal];
            const checkedParams = [logo, title, priority, visualPath, from, originalLifeTime, tickerTime, tickerColor, global];

            setFormDataChanged(
                !isEqual(checkedParams, checkedRefParams)
                || !isEqual(news.exactQueryGroups, newsRef.current.exactQueryGroups)
                || !isEqual(news.meta.files, newsRef.current.meta.files)
                || !isEqual(news.minusWords, newsRef.current.minusWords)
                || !isEqual(news.documentTags, newsRef.current.documentTags)
                || isFormDataTouched
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        news.title,
        news.priority,
        news.exactQueryGroups,
        news.meta.files,
        news.meta.visualPath,
        news.from,
        news.lifeTime,
        news.originalLifeTime,
        news.minusWords,
        news.documentTags,
        news.logo
    ]);
    useEffect(() => {
        if (news.exactQueryGroups.length === 0) {
            news.exactQueryGroups.push(getInitialExactQueryGroupsItem())
            news.commit();
        }
    }, [news]);

    // Сброс до инициализирующих данных при попытке уйти с формы добавления новости, нажав на тот же пункт в меню - "Новость"
    function resetToInitialValues () {
        return () => {
            news?.editor?.setData?.(null);

            const initialEntity = new NewsFormModel();
            const initialEntityKeys = Object.keys(initialEntity);
            initialEntityKeys.forEach((key) => {
                news[key] = initialEntity[key];
            });
            news.exactQueryGroups = [
                getInitialExactQueryGroupsItem()
            ];
            setDueTo(!!news.from)
            // news.tickerTime = tickerTimeRangeOptions[0];
            setIsPickerOpen(false);
            news.commit();
        }
    }

    useEffect(() => {
        if (!edit) {
            setFormDataChangedSubmitHandler(resetToInitialValues);
        }
        return () => {
            if (!edit) {
                setFormDataChangedSubmitHandler(null);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setDueTo(!!news.from);
    }, [news.from]);

    return (
        <>
            <div className={classNames({[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.field}>
                    {(news.logo || news.logoUuid) && !isLogoEdit
                        ? (
                            <div className={cs.logoWrapper}>
                                <div className={cs.logoOverlay}/>
                                <div className={cs.cover} style={{ backgroundImage: `url(${news.logo})` }}/>
                                <div className={cs.logoBtnWrapper}>
                                    <Button className={cs.logoBtn} color={'transparent'} size="small"
                                            onClick={onLogoRemove}>Удалить обложку</Button>
                                    <InputImage title={'Изменить обложку'} btn={true} btnClassName={cs.logoBtn}
                                                btnColor={'transparent'} btnSize={'small'} onChange={onLogoChange}/>
                                </div>
                            </div>
                        ) : (<InputImage onChange={onLogoChange} title={'Обложка новости'}/>)
                    }
                </div>
                <Card id="newsFormCard" className={cs.form}>
                    <Input
                        id="newsFormTitleInput"
                        className={cs.field}
                        type="text"
                        name="title"
                        label="Заголовок"
                        value={news.title}
                        onChange={onTitleChange}
                        disabled={loading}
                        required
                        error={news.errors.title}
                        onFocus={onTitleFocus}
                    />
                    <SelectOption
                        label="Приоритет новости"
                        defaultValue={{ label: 'Обычная', value: 'MEDIUM' }}
                        value={priorityOptions.find(o => o.value === news.priority)}
                        disabled={loading}
                        isSearchable={false}
                        options={priorityOptions} className={cs.field} id={'newsFormPriorityField'}
                        onChange={option => {
                            handleManualFormDataChange();
                            news.priority = option.value;
                            news.commit();
                        }}
                    />
                    <Editor
                        ref={editorRef}
                        key={news.id}
                        id="newsFormEditorContainer"
                        label="Полный текст новости"
                        editorExperimentalSwitch={editorExperimentalSwitch}
                        type={editorType}
                        entity={news}
                        onEditorSaveInstance={onEditorSaveInstance}
                        initId={news.id ?? news.initId}
                        value={news.text}
                        error={news.errors['text']}
                        onMinimize={handleMinimize}
                        handleManualFormDataChange={handleManualFormDataChange}
                        formDataChanged={formDataChanged}
                        setFormDataChanged={setFormDataChanged}
                    />
                    <Divider />
                    <div className={classNames(cx.blockText, cx.fieldGroup, cx.pickerTitle)}
                         onClick={() => setIsPickerOpen(!isPickerOpen)}>
                        <div>
                            {!isPickerOpen && <Icon type={Icons.ARROW_DOWN} width={12} height={12}/>}
                            {isPickerOpen && <Icon type={Icons.CHEVRON_UP} width={12} height={12}/>}
                        </div>
                        Точные поисковые ответы
                    </div>
                    {isPickerOpen && <div className={classNames(cx.exactQueryGroupsBlock)}>
                        {
                            news.exactQueryGroups.map((item, itemIndex) => {
                                return (
                                    <div key={`exactQueryGroups_${itemIndex}`} className={cx.exactQueryGroupsItemBlock}>
                                        <div className={cx.exactQueryGroupsIconBlock}>
                                            <IconButton icon={<Icon type={Icons.DELETE} width={16} height={16}/>}
                                                onClick={() => { onexactQueryGroupsItemDelete(itemIndex) }}/>
                                        </div>
                                        <div className={cx.exactQueryGroupsDataBlock}>
                                            <div>
                                                <p className={classNames(cx.fieldLabel, cx.fieldLabelTop)}>
                                                    Запросы
                                                </p>
                                                <TagsInput inputProps={{ className: 'react-tagsinput-input', placeholder: 'Запросы' }}
                                                    value={item.exactQueries}
                                                    onChange={exactQueries => {
                                                        handleManualFormDataChange();
                                                        item.exactQueries = exactQueries;
                                                        news.exactQueryGroups[itemIndex] = item;
                                                        news.commit();
                                                        setFormDataChanged(true);
                                                    }}/>
                                                <p className={classNames(cx.fieldLabel, cx.fieldLabelBottom)}>
                                                    Нажмите Enter чтобы применить ввод в поле
                                                </p>
                                            </div>
                                            <div>
                                                <TextArea label="Точный ответ на запросы" value={item.exactAnswer} onChange={(exactAnswer) => {
                                                    handleManualFormDataChange();
                                                    item.exactAnswer = exactAnswer;
                                                    news.exactQueryGroups[itemIndex] = item;
                                                    news.commit();
                                                    setFormDataChanged(true);
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className={cx.exactQueryGroupsAdd}>
                            <Button className={cx.exactQueryGroupsAddBtn} type="link" onClick={onexactQueryGroupsAdd}>
                                <Icon type={Icons.PLUS_ADD_FILLED} width={16} height={16}/>
                                <span>Добавить группу запрос-ответ</span>
                            </Button>
                        </div>
                    </div>}
                    <Divider/>
                    <div className={classNames(cs.blockText, cs.fieldGroup)}>Прикреплённые файлы</div>
                    <Attachments uploadsId={news.id || news.timeId} files={news.getFiles()}
                                 onChange={files => {
                                     news.setFiles(files);
                                     news.commit();
                                 }}/>
                    <Divider/>
                    <LocationChange
                        onClearPath={onClearPath}
                        visualPath={news.getVisualPath()}
                        onChange={_onDestinationChange}
                        autoOpenPath={news.getParentsIds()}
                        withSections={false}
                        required
                        error={news.errors.location}
                        title={'Выберите проект для новости'}
                        placeholder={'Выберите проект из списка...'}
                        disableChange={news.global}
                        disableChangeTitle={'Общая новость'}
                        onToggleDisableChange={toggleGlobal}
                        rights={[GLOBAL_ACTIONS.NEWS_CREATE, GLOBAL_ACTIONS.GLOBAL_NEWS_CREATE]}
                        fetchTreeFunc={api.project.getUsersDocumentTree}
                    />

                    {news.publishedType !== 'draft' &&
                        <>
                          <Divider/>
                          <div className={classNames(cs.blockText, cs.fieldGroup, cs.newsPickerTitle)}
                              onClick={() => toggleNewsPickerOpen(!isNewsPickerOpen)}>
                              <div>
                                  {!isNewsPickerOpen && <Icon type={Icons.ARROW_DOWN} width={12} height={12}/>}
                                  {isNewsPickerOpen && <Icon type={Icons.CHEVRON_UP} width={12} height={12}/>}
                              </div>
                              Бегущая строка
                          </div>
                        </>
                    }

                    {news.publishedType !== 'draft' && isNewsPickerOpen &&
                    <div className={cs.blockNewsTicker}>
                        <Checkbox
                            checked={news.isTicker}
                            onClick={() => ChangeTicker()}
                            label="Опубликовать новость в бегущей строке"
                        />
                        <div className={cs.blockNewsTickerSelects}>
                            <SelectOption
                                label="Срок нахождения в бегущей строке"
                                defaultValue={news.tickerTime}
                                value={news.tickerTime}
                                options={tickerTimeRangeOptions}
                                disabled={loading}
                                isSearchable={false}
                                onChange={(item) => {
                                    handleManualFormDataChange();
                                    news.tickerTime = item;
                                    news.commit();
                                    setFormDataChanged(true);
                                }}
                            />
                            <SelectOption
                                label="Цвет строки"
                                options={tickerColorOptions}
                                defaultValue={news.tickerColor}
                                value={news.tickerColor}
                                components={{ SingleValue: LabelWithColor, Option: LabelWithColor }}
                                disabled={loading}
                                isSearchable={false}
                                onChange={(item) => {
                                    handleManualFormDataChange();
                                    news.tickerColor = item;
                                    news.commit();
                                    setFormDataChanged(true);
                                }
                            }/>
                        </div>
                    </div>}
                    <Divider/>
                    <div className={classNames(cs.blockText, cs.fieldGroup, cs.dueto)}>
                        Срок действия новости
                        <Switch className={cs.switch} checked={dueTo} onClick={(e) => {
                            setDueTo(e.target.checked);
                            setFormDataChanged(true);
                        }}/>
                    </div>
                    {dueTo &&
                        <div className={cs.blockDates}>
                            <div>
                                <DateTimePicker
                                    disabled={news.publishedType === 'news'}
                                    value={news.from}
                                    label={'От'}
                                    pickerProps={{ minDate: new Date(), maxDate: new Date('9999-01-01') }}
                                    onChange={from => {
                                        handleManualFormDataChange();
                                        news.from = from;
                                        news.commit();
                                        setFormDataChanged(true);
                                    }}
                                />
                            </div>
                            <div>
                                <DateTimePicker value={news.expireTime} label={'До'} error={news.errors.to} pickerProps={{ maxDate: new Date('9999-01-01') }}
                                    onChange={lifeTime => {
                                        handleManualFormDataChange();
                                        news.expireTime = lifeTime;
                                        news.originalExpireTime = new Date();
                                        news.commit();
                                        setFormDataChanged(true);
                                    }}/>
                            </div>
                        </div>
                    }
                    {/* <div className={classNames(cs.blockText, cs.fieldGroup, cs.tags)}>
                        Тэги
                    </div>
                    <TagAccordion onTagClick={handleTagClick} tags={news.documentTags}/> */}
                    {children}
                </Card>
            </div>
        </>
    );
};

NewsForm.defaultProps = {
    loading: false
};

NewsForm.propTypes = {
    news: PropTypes.instanceOf(NewsFormModel).isRequired,
    loading: PropTypes.bool
};

export default NewsForm;
