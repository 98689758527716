import React, { useState, useMemo, useEffect } from 'react';
import { ReportsComponent } from 'components/data-components/reports-component';
import api from 'api';
import { articleSearchBy, newsSearchBy, objectTypes } from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';

const StatisticReportsUsersInDocuments = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [dynamicColumns, setDynamicColumns] = useState([]);

    const getUsersInDocumentsReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);

        try {
            const res = await api.reports.getUserRoles({ page, size, filters, cancelToken: ReportService.cancelToken.token });
            const roleRes = await api.customRoles.getRoleList();

            setDynamicColumns(
                roleRes
                    .filter((i) => !i.defaultRole || !i.name.includes('system'))
                    .map((i) => ({
                        Header: i.title,
                        accessor: i.name,
                    }))
            );
            setReports(
                res.projectPathRoles.content.map((item) => {
                    let row = { projectPath: item.projectPath, totalUsers: item.totalUsers };

                    item.roleItems.forEach((i) => {
                        row = { ...row, [i.roleName]: i.count };
                    });

                    return row;
                })
            );
            setReportsTotal(res.projectPathRoles.totalElements);

            if (!res.projectPathRoles.totalElements) {
                addError('По вашему запросу нет данных');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports('user-roles', filters, ReportService.cancelToken.token);
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Документы',
                accessor: 'projectPath',
                width: 260,
                maxWidth: 260,
            },
            {
                Header: 'Участников всего',
                accessor: 'totalUsers',
            },
            ...dynamicColumns.map((column) => {
                return {
                    ...column,
                    Cell: ({ cell }) => {
                        return cell.value || 0;
                    },
                };
            }),
        ];

        return data;
    }, [dynamicColumns]);

    const filtersTmp = useMemo(() => {
        return [
            {
                fields: [
                    {
                        labels: ['На момент', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: 'Тип документа',
                fields: [
                    {
                        name: 'document-type-select',
                        type: 'document-type-select',
                        options: objectTypes,
                        default: objectTypes[0],
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'articles-group',
                        type: 'articles',
                        options: articleSearchBy,
                        default: articleSearchBy[0],
                        required: true,
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'news-group',
                        type: 'news',
                        options: newsSearchBy,
                        default: newsSearchBy[1],
                        required: true,
                    },
                ],
                width: '100%',
            },
        ];
    }, []);

    useEffect(() => {
        ReportService.init('USERS_IN_DOCUMENTS');

        return () => {
            ReportService.destroy();
        };
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getUsersInDocumentsReports}
        />
    );
};

export default StatisticReportsUsersInDocuments;
