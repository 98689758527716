import { Node, mergeAttributes } from '@tiptap/core';

export const Column = Node.create({
  name: 'column',
  group: 'column',
  content: '(paragraph|block)*',

  addAttributes() {
    return {
      width: {
        default: null,
        renderHTML: () => {
          return {
            class: `column`,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
        {
            tag: 'div.column'
        }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes);
    return ['div', attrs, 0];
  },

  addKeyboardShortcuts() {
    return {
        Enter: ({editor}) => {
            const selection = editor.state.selection;
            const parent = selection.$head.node(selection.$head.depth - 1);

            if (parent?.type !== this.type) return false;

            if (
                !selection.empty ||
                selection.$from.parent.childCount !== 0 ||
                selection.$from.parent.type.spec.code ||
                selection.$to.parent.type.spec.code
            ) {
                return false;
            }

            const node = editor.$pos(selection.$head.pos);

            editor.chain().insertContentAt(node.to, { type: 'paragraph' }).run();

            return true;
        }
    }
  }
});
