import { Icons } from 'uikit/icon';

export const PAGE_SIZE = 10;

export const FILES_TYPE_ICONS = {
    'application/x-zip-compressed': Icons.ZIP_FILE,
    'application/zip': Icons.ZIP_FILE,
    'application/xml': Icons.XML_FILE,
    'application/vnd.ms-excel': Icons.XLS_FILE,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': Icons.XLS_FILE,
    'text/xml': Icons.XML_FILE,
    'text/plain': Icons.TXT_FILE,
    'application/vnd.rar': Icons.RAR_FILE,
    'application/vnd.ms-powerpoint': Icons.PPT_FILE,
    'image/png': Icons.PNG_FILE,
    'application/pdf': Icons.PDF_FILE,
    'audio/mpeg': Icons.MP3_FILE,
    'application/json': Icons.JSON_FILE,
    'image/jpeg': Icons.JPG_FILE,
    'text/html': Icons.HTML_FILE,
    'application/x-msdownload': Icons.EXE_FILE,
    'application/msword': Icons.DOC_FILE,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': Icons.DOC_FILE,
    'text/csv': Icons.CSV_FILE,
    'image/bmp': Icons.BMP_FILE,
    'video/x-ms-wmv': Icons.WMV_FILE,
    'audio/wav': Icons.WAV_FILE,
    'image/tiff': Icons.TIFF_FILE,
    'image/vnd.adobe.photoshop': Icons.PSD_FILE,
    'video/mpeg': Icons.MPG_FILE,
    'video/mp4': Icons.MP4_FILE,
    'video/quicktime': Icons.MOV_FILE,
    'video/x-quicktime': Icons.MOV_FILE,
    'image/mov': Icons.MOV_FILE,
    'image/gif': Icons.GIF_FILE,
    'application/postscript': Icons.EPS_FILE,
    'application/eps': Icons.EPS_FILE,
    'application/x-eps': Icons.EPS_FILE,
    'image/eps': Icons.EPS_FILE,
    'image/x-eps': Icons.EPS_FILE,
    'video/x-msvideo': Icons.AVI_FILE,
    'application/vnd.oasis.opendocument.spreadsheet': Icons.ODS_FILE,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': Icons.PPTX_FILE,
    'application/vnd.oasis.opendocument.text': Icons.ODT_FILE,
};

export const FILES_TYPE_SHORT_NAME = {
    'application/x-zip-compressed': 'ZIP',
    'application/zip': 'ZIP',
    'application/xml': 'XML',
    'application/vnd.ms-excel': 'XLS',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
    'text/xml': 'XML',
    'text/plain': 'TXT',
    'application/vnd.rar': 'RAR',
    'application/vnd.ms-powerpoint': 'PPT',
    'image/png': 'PNG',
    'application/pdf': 'PDF',
    'audio/mpeg': 'MP3',
    'application/json': 'JSON',
    'image/jpeg': 'JPG',
    'text/html': 'HTML',
    'application/x-msdownload': 'EXE',
    'application/msword': 'DOC',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
    'text/csv': 'CSV',
    'image/bmp': 'BMP',
    'video/x-ms-wmv': 'WMV',
    'audio/wav': 'WAV',
    'image/tiff': 'TIFF',
    'image/vnd.adobe.photoshop': 'PSD',
    'video/mpeg': 'MPG',
    'video/mp4': 'MP4',
    'video/quicktime': 'MOV',
    'video/x-quicktime': 'MOV',
    'image/mov': 'MOV',
    'image/gif': 'GIF',
    'application/postscript': 'EPS',
    'application/eps': 'EPS',
    'application/x-eps': 'EPS',
    'image/eps': 'EPS',
    'image/x-eps': 'EPS',
    'video/x-msvideo': 'AVI',
    'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
    'application/vnd.oasis.opendocument.text': 'ODT'
};

export const tickerTimeRangeOptions = [
    { label: '1 день', value: 1 },
    { label: '3 дня', value: 3 },
    { label: '7 дней', value: 7 },
    { label: '10 дней', value: 10 },
    { label: '14 дней', value: 14 },
    { label: '21 день', value: 21 },
    { label: '30 дней', value: 30 }
];

export const tickerColorOptions = [
    { value: 'FFE989', label: 'Желтый текст', color: 'FFE989' },
    { value: 'B2F1FF', label: 'Голубой текст', color: 'B2F1FF' },
    { value: 'FFFFFF', label: 'Белый текст', color: 'FFFFFF' },
    { value: 'C2F9A8', label: 'Зеленый текст', color: 'C2F9A8' },
    { value: 'E2D0FF', label: 'Пурпурный текст', color: 'E2D0FF' },
    { value: 'FFD1D1', label: 'Розовый текст', color: 'FFD1D1' }
];

export const TRAINING_QUESTION_TYPES = [
    {
        label: 'Несколько вариантов ответа',
        value: 0
    },
    {
        label: 'Один вариант ответа',
        value: 1
    },
    {
        label: 'Открытый ответ',
        value: 2
    },
    {
        label: 'Продолжить фразу',
        value: 3
    },
    {
        label: 'Сопоставление',
        value: 4
    },
    {
        label: 'Сортировка',
        value: 5
    }
];

export const TRAINING_PASSING_TYPES = [
    {
        label: 'Линейное',
        value: 0
    },
    {
        label: 'Произвольное',
        value: 1
    }
];
export const TRAINING_RATING_SCALES = [
    {
        label: 'Без оценивания',
        value: 0
    },
    {
        label: 'Баллы',
        value: 1
    }
];
