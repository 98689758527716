import {
    addColumn,
    addColumnAfter,
    addColumnBefore,
    addRow,
    addRowAfter,
    addRowBefore,
    deleteColumn,
    deleteRow,
    deleteTable,
    goToNextCell,
    mergeCells,
    setCellAttr,
    splitCell,
    toggleHeader,
    toggleHeaderCell,
    selectedRect,
    toggleTableHead,
    toggleTableHeaders,
    recalculateRowCellsWidth,
} from '../commands';
import { callOrReturn, getExtensionField, findParentNodeClosestToPos, Node } from '@tiptap/core';
import { TextSelection } from '@tiptap/pm/state';

import { TableView } from './TableView';
import { createTable } from './utilities/createTable';
import { deleteTableWhenAllCellsSelected } from './utilities/deleteTableWhenAllCellsSelected';
import { columnResizing } from './utilities/columnResizing';
import { findParentNode } from '../../../utils/findParentNode';
import { CellSelection } from '../cellSelection';
import { tableEditing } from '../tableEditing';
import { fixTables } from '../fixTables';
import { tableNodeTypes } from './utilities/getTableNodeTypes';
import { stringToBoolean } from '../../../utils/stringToBool';

function renewalBorderColor(state, dispatch) {
    const table = findParentNode(state, 'table');

    if (table) {
        table.node.descendants((node, pos) => {
            if (node.type.name === 'tableCell' || node.type.name === 'tableHeader') {
                state.tr.setNodeMarkup(table.pos + pos + 1, null, {
                    ...node.attrs,
                    'data-border-color': table.node.attrs.borderColor,
                });
            }
        });
    }

    return dispatch(state.tr);
}

export const Table = Node.create({
    name: 'table',

    // @ts-ignore
    addOptions() {
        return {
            HTMLAttributes: {},
            resizable: true,
            handleWidth: 5,
            cellMinWidth: 45,
            // TODO: fix
            View: TableView,
            lastColumnResizable: true,
            allowTableNodeSelection: true,
        };
    },

    content: 'tableTitle* tableColgroup? tableHead* tableBody*',

    tableRole: 'table',

    isolating: true,

    group: 'block',

    addAttributes() {
        return {
            borderColor: {
                default: '#D8D8D8',
                parseHTML: el => el.getAttribute('bordercolor'),
                renderHTML: attrs => {
                    if (!attrs.borderColor) {
                        return {};
                    }

                    return {
                        bordercolor: attrs.borderColor,
                    };
                },
            },
            'data-border': {
                default: 1,
                parseHTML: el => el.getAttribute('border'),
                renderHTML: attrs => (attrs['data-border'] ? { ...attrs, border: attrs['data-border'] } : {}),
            },
            'data-align': {
                default: 'left',
                parseHTML: el => el.getAttribute('data-align'),
                renderHTML: attrs => {
                    return {
                        'data-align': attrs['data-align'],
                        class: `align-${attrs['data-align']}`,
                    };
                },
            },
            'data-headers-lock': {
                default: 'NONE',
            },
            'data-responsive': {
                default: true,
                parseHTML: el => el.getAttribute('data-responsive'),
                renderHTML: attrs => {
                    if (attrs['data-responsive'] === undefined) {
                        return {};
                    }

                    return {
                        'data-responsive': attrs['data-responsive'],
                        style: attrs['data-responsive'] === false ? 'table-layout: fixed' : null,
                    };
                },
            },
            width: {
                default: null,
                parseHTML: el => el.style.width || el.getAttribute('width'),
                renderHTML: attrs => {
                    if (!attrs.width) {
                        return {};
                    }
                    if (attrs.width === 'auto') {
                        return {
                            style: 'width: 100%',
                        };
                    } else if (String(attrs.width).includes('%')) {
                        return {
                            style: `width: ${attrs.width}; max-width: ${attrs.width}`,
                        };
                    } else {
                        return {
                            style: `min-width: ${attrs.width};`,
                        };
                    }
                },
            },
        };
    },

    parseHTML() {
        return [{ tag: 'table' }];
    },

    renderHTML({ HTMLAttributes }) {
        return [
            'div',
            { style: 'position: relative' },
            [
                'div',
                { class: 'tableActionsWrapper' },
                [
                    'table',
                    {
                        'data-align': HTMLAttributes['data-align'],
                        bordercolor: HTMLAttributes.bordercolor,
                        'data-border': HTMLAttributes['data-border'],
                        'data-responsive': HTMLAttributes['data-responsive'],
                        border: HTMLAttributes.border,
                        width: HTMLAttributes.width,
                        style: HTMLAttributes.style,
                    },
                    0,
                ],
            ],
        ];
    },

    addCommands() {
        return {
            insertTable:
                ({ rows = 3, cols = 3, withHeaderRow = true } = {}) =>
                ({ editor, state, dispatch }) => {
                    const { selection } = state;
                    const { from, to } = selection;

                    const node = createTable(editor.schema, rows, cols, withHeaderRow);
                    const tr = state.tr.replaceWith(from - 1, to, node);

                    tr.setSelection(TextSelection.near(tr.doc.resolve(from + 1)));
                    // tr.scrollIntoView();

                    if (dispatch) {
                        return dispatch(tr);
                    }
                    return true;
                },
            addColumnBefore:
                () =>
                ({ state, dispatch }) => {
                    addColumnBefore(state, dispatch);
                    state.apply(state.tr);
                    renewalBorderColor(state, dispatch);

                    return true;
                },
            addColumnAfter:
                () =>
                ({ state, dispatch }) => {
                    addColumnAfter(state, dispatch);
                    state.apply(state.tr);
                    renewalBorderColor(state, dispatch);

                    return true;
                },
            addColumnToEnd:
                () =>
                ({ tr, state, dispatch }) => {
                    const rect = selectedRect(state);

                    dispatch(addColumn(tr, rect, rect.map.width));

                    return renewalBorderColor(state.apply(state.tr), dispatch);
                },

            addColumnCopyAfter:
                () =>
                ({ tr, state, dispatch }) => {
                    const { map, table, tableStart, left } = selectedRect(state);

                    for (let row = 0; row < map.height; row++) {
                        const index = row * map.width + left;
                        const pos = map.map[index];
                        const cell = table.nodeAt(pos);

                        if (!stringToBoolean(table.attrs['data-responsive']) && table.attrs.width?.includes('%')) {
                            const isValid = recalculateRowCellsWidth(tr, { map, tableStart, table }, row, cell.attrs.colwidth[0]);

                            if (!isValid) {
                                return false;
                            }
                        }

                        tr.insert(tr.mapping.map(tableStart + map.map[index]), cell);
                        row += cell.attrs.rowspan - 1;
                    }

                    dispatch(tr);
                },
            deleteColumn:
                () =>
                ({ state, dispatch }) => {
                    deleteColumn(state, dispatch);

                    return true;
                },
            addRowBefore:
                () =>
                ({ state, dispatch }) => {
                    addRowBefore(state, dispatch);
                    state.apply(state.tr);
                    renewalBorderColor(state, dispatch);

                    return true;
                },
            addRowAfter:
                () =>
                ({ _tr, state, dispatch }) => {
                    addRowAfter(state, dispatch);
                    state.apply(state.tr);
                    renewalBorderColor(state, dispatch);

                    return true;
                },
            addRowCopyAfter:
                () =>
                ({ tr, state, dispatch }) => {
                    const { map, table, tableStart, top } = selectedRect(state);
                    const cells = [];
                    let rowPos = tableStart;
                    for (let i = 0; i < top; i++) rowPos += table.firstChild.child(i).nodeSize;
                    tr.insert(rowPos, tableNodeTypes(table.type.schema).row.create(null, cells));

                    for (let col = 0, index = map.width * top; col < map.width; col++, index++) {
                        const pos = map.map[index];
                        const cell = table.nodeAt(pos);

                        tr.insert(tableStart + pos, cell);
                        col += cell.attrs.colspan - 1;
                    }

                    dispatch(tr);
                },
            addRowToEnd:
                () =>
                ({ tr, state, dispatch }) => {
                    const rect = selectedRect(state);
                    return dispatch(addRow(tr, rect, rect.map.height));
                },
            deleteRow:
                () =>
                ({ state, dispatch }) => {
                    deleteRow(state, dispatch);

                    return true;
                },
            deleteTable:
                () =>
                ({ state, dispatch }) => {
                    return deleteTable(state, dispatch);
                },
            mergeCells:
                () =>
                ({ state, dispatch }) => {
                    return mergeCells(state, dispatch);
                },
            splitCell:
                () =>
                ({ state, dispatch }) => {
                    return splitCell(state, dispatch);
                },
            toggleHeaderColumn:
                () =>
                ({ state, dispatch }) => {
                    return toggleHeader('column')(state, dispatch);
                },
            toggleHeaderRow:
                () =>
                ({ state, dispatch }) => {
                    return toggleTableHead(state, dispatch);
                },
            toggleTableHeaders:
                ({ row, column }) =>
                ({ state, dispatch }) => {
                    return toggleTableHeaders({ row, column })(state, dispatch);
                },
            toggleHeaderCell:
                () =>
                ({ state, dispatch }) => {
                    return toggleHeaderCell(state, dispatch);
                },
            mergeOrSplit:
                () =>
                ({ state, dispatch }) => {
                    if (mergeCells(state, dispatch)) {
                        return true;
                    }

                    return splitCell(state, dispatch);
                },
            setCellAttribute:
                (name, value) =>
                ({ state, dispatch }) => {
                    return setCellAttr(name, value)(state, dispatch);
                },
            goToNextCell:
                () =>
                ({ state, dispatch }) => {
                    return goToNextCell(1)(state, dispatch);
                },
            goToPreviousCell:
                () =>
                ({ state, dispatch }) => {
                    return goToNextCell(-1)(state, dispatch);
                },
            fixTables:
                () =>
                ({ state, dispatch }) => {
                    if (dispatch) {
                        fixTables(state);
                    }

                    return true;
                },
            setCellSelection:
                position =>
                ({ tr, dispatch }) => {
                    if (dispatch) {
                        const selection = CellSelection.create(tr.doc, position.anchorCell, position.headCell);

                        tr.setSelection(selection);
                    }

                    return true;
                },
            setBorderColor:
                color =>
                ({ tr, dispatch, view }) => {
                    const table = findParentNodeClosestToPos(view?.state.selection.$anchor, node => {
                        return node.type.name === 'table';
                    });
                    const resolvedTablePos = view.state.doc.resolve(table.start);

                    table.node.descendants((node, pos) => {
                        if (node.type.name === 'tableCell' || node.type.name === 'tableHeader') {
                            const resolvedCellPos = view.state.doc.resolve(table.start + pos);

                            if (resolvedCellPos.depth - resolvedTablePos.depth === 2) {
                                tr.setNodeMarkup(table.start + pos, null, {
                                    ...node.attrs,
                                    'data-border-color': color,
                                });
                            }
                        }
                    });

                    tr.setNodeMarkup(table.pos, null, {
                        ...table.node.attrs,
                        borderColor: color,
                    });

                    dispatch(tr);
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            Tab: () => {
                const { selection } = this.editor.view.state;

                const resolvedPos = findParentNodeClosestToPos(selection.$from, node => {
                    if (node.type.name === 'tableCell') {
                        return true;
                    }
                });

                if (resolvedPos?.node.type.name !== 'tableCell') {
                    return false;
                }

                if (this.editor.commands.goToNextCell()) {
                    return true;
                }

                if (!this.editor.can().addRowAfter()) {
                    return false;
                }

                return this.editor.chain().addRowAfter().goToNextCell().run();
            },
            'Shift-Tab': () => this.editor.commands.goToPreviousCell(),
            Backspace: deleteTableWhenAllCellsSelected,
            'Mod-Backspace': deleteTableWhenAllCellsSelected,
            Delete: deleteTableWhenAllCellsSelected,
            'Mod-Delete': deleteTableWhenAllCellsSelected,
            'Mod-a': () => {
                const { selection, tr, doc } = this.editor.view.state;
                const { dispatch } = this.editor.view;

                const resolvedPos = findParentNodeClosestToPos(selection.$from, node => {
                    if (node.type.name === 'tableCell') {
                        return true;
                    }
                });

                if (resolvedPos?.node.type.name !== 'tableCell') {
                    return false;
                }

                tr.setSelection(TextSelection.create(doc, resolvedPos.start + 1, resolvedPos.start + resolvedPos.node.content.size - 1));

                dispatch(tr);
                return true;
            },
        };
    },

    addProseMirrorPlugins() {
        const isResizable = this.options.resizable && this.editor.isEditable;

        return [
            ...(isResizable
                ? [
                      columnResizing({
                          handleWidth: 5,
                          cellMinWidth: this.options.cellMinWidth,
                          View: this.options.View,
                          lastColumnResizable: this.options.lastColumnResizable,
                      }),
                  ]
                : []),
            tableEditing({
                allowTableNodeSelection: this.options.allowTableNodeSelection,
            }),
        ];
    },

    extendNodeSchema(extension) {
        const context = {
            name: extension.name,
            options: extension.options,
            storage: extension.storage,
        };

        return {
            tableRole: callOrReturn(getExtensionField(extension, 'tableRole', context)),
        };
    },
});
