import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import InputMask from 'react-input-mask';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from 'react-accessible-accordion';
import { translate } from 'lib/helpers';
import { UserFormModel } from 'model/user';
import Card from 'components/card';
import { UserAccessDialog } from 'components/trees';
import LockTree from 'components/lock/lock-tree';
import Loading from 'uikit/loading';
import Divider from 'uikit/divider';
import Input from 'uikit/input';
import Checkbox from 'uikit/checkbox/checkbox';
import { Select } from 'uikit/select';
import { DatePicker } from 'uikit/datetime';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import api from 'api/index';
import cs from './user-form.module.scss';
import { Role } from 'model/role';
import { useGlobalContext, useMessage, useManualFormDataChange } from 'lib/hooks';
import { useSelector } from 'react-redux';
import { selectSession, selectUser } from 'slice/authSlice';
import { Link } from '@reach/router';
import * as validators from 'lib/util/validators.util';

const UserForm = ({ user, isEditMode, allowEmptyPassword, disableLoginEdit, showAllNotificationTypes, loading, children, edit = false }) => {
    const { platform, setFormDataChanged, setFormDataChangedSubmitHandler } = useGlobalContext();
    const { addError } = useMessage();
    const userRef = useRef(user);
    const [passwordChangedManually, setPasswordChangedManually] = useState(false);
    const [dataChangedManually, setDataChangedManually] = useState(false);
    const { /* isFormDataTouched, */ handleManualFormDataChange } = useManualFormDataChange(edit);

    const [isAccessModal, setIsAccessModal] = useState(false);
    const [isLockTree, setIsLockTree] = useState(false);

    const [positions, setPositions] = useState([]);
    const [supervisors, setSupervisors] = useState([]);

    const [offices, setOffices] = useState([]);
    const [activeProject, setActiveProject] = useState(null);
    const [generatedLogin, setGeneratedLogin] = useState('');
    const [loginExistsUser, setLoginExistsUser] = useState(null);

    const [expandedAccordionItems, setExpandedAccordionItems] = useState([]);

    const [systemRoles, setSystemRoles] = useState([Role.defaultRole]);
    const session = useSelector(selectSession);

    const currentUser = useSelector(selectUser);
    const currentUserLogin = session?.login;

    const notificationActionTypesInfo = [
        { type: 'ARTICLE', title: 'Статьи', disabled: false },
        { type: 'NEWS', title: 'Новости', disabled: false },
        { type: 'FILE', title: 'Файлы', disabled: false },
        { type: 'USER', title: 'Участники', disabled: false },
        { type: 'PROJECT', title: 'Проекты', disabled: false },
        { type: 'SECTION', title: 'Разделы', disabled: false },
        // { type: 'SETTING', title: 'Настройки', disabled: true },
        // { type: 'CHAT', title: 'Чат', disabled: true },
        // { type: 'ARCHIVE', title: 'Архив', disabled: true },
        // { type: 'TRAINING', title: 'Обучение', disabled: true },
        // { type: 'SCRIPTING', title: 'Скриптинг', disabled: true }
    ];

    const notificationTypesInfo = [
        {
            type: 'PUSH',
            title: 'Разрешить PUSH-уведомления',
            desc: 'Уведомления о действиях будут приходить прямо в браузере, без необходимости находится на вкладке системы',
            disabled: !isEditMode
        },
        {
            type: 'EMAIL',
            title: 'Разрешить Email-уведомления',
            desc: `Уведомления о действиях будут приходить на указанный в профиле Email адрес ${user.email ?? ''}`,
            disabled: !isEditMode || !user.email || user.email?.length === 0,
        },
        {
            type: 'WEBSOCKET',
            title: 'Разрешить системные уведомления',
            desc: 'Уведомления о действиях будут приходить внутри системы управления знаниями',
            disabled: !isEditMode
        }
    ];

    const getNestingResource = useCallback((resources, userProject) => {
        if (resources['articles'] && resources['articles'] != null && resources['articles'].length > 0) {
            for (let i = 0; i < resources['articles'].length; i++) {
                userProject.resources.push(
                    {
                        isFullAccess: false,
                        resourceId: resources['articles'][i]['uuid'],
                        resourceType: 'ARTICLE'
                    });
            }
        }

        if (resources['subSections'] && resources['subSections'] != null && resources['subSections'].length > 0) {
            for (let i = 0; i < resources['subSections'].length; i++) {
                userProject.resources.push(
                    {
                        isFullAccess: false,
                        resourceId: resources['subSections'][i]['uuid'],
                        resourceType: 'SECTION'
                    });

                getNestingResource(resources['subSections'][i], userProject);
            }
        }
    }, []);

    const onCloseModal = () => {
        setIsAccessModal(false);
        setActiveProject(null);
    };
    const updateLogin = async () => {
        let newGenLogin = '';

        let isValid = false;
        let generateIndex = 0;

        while (!isValid) {
            if (user.firstName && !user.errors.firstName) {
                let translated = translate(user.firstName[0]);
                if (validators.loginPassRe.test(translated)) {
                    newGenLogin += translated;
                }
            }

            newGenLogin += '_';

            if (user.lastName && !user.errors.lastName) {
                let translated = translate(user.lastName);
                for (let i = 0; i < translated.length; i++) {
                    newGenLogin += validators.loginPassRe.test(translated[i]) ? translated[i] : '_';
                }
            }

            if (generateIndex !== 0) {
                newGenLogin += Math.floor(Math.random() * 1000);
            }

            generateIndex += 1;

            const response = await api.user.findByLogin(newGenLogin);

            if (response.length === 0) {
                isValid = true;
            } else {
                newGenLogin = '';
                continue;
            }
        }

        setGeneratedLogin(newGenLogin);
    };

    const saveUserProject = (nodeData, roleData, projectData, editData, projects) => {
        if (projectData['id'] === 0) {
            const newRoles = [];

            for (let i = 0; i < projects.length; i++) {
                if (i === 0) {
                    continue;
                }

                newRoles.push({
                    projectId: projects[i]['id'],
                    roleId: roleData['value'],
                    projectName: projects[i]['label'],
                    roleName: roleData['label'],
                    logo: projects[i]['logo'],
                    resources: []
                });
            }
            user.roles = newRoles;
        } else {
            const item = user.roles.find(v => v.projectId === projectData['id']);

            if (item) {
                item.roleId = roleData['value'];
                item.roleName = roleData['label'];
            } else {
                user.roles.push({
                    projectId: projectData['id'],
                    roleId: roleData['value'],
                    projectName: projectData['label'],
                    roleName: roleData['label'],
                    logo: projectData['logo'],
                    resources: []
                });
            }
        }

        user.rolesChanged = true;
        user.commit();

        setDataChangedManually(true);
        handleManualFormDataChange();

        setIsAccessModal(false);
        setActiveProject(null);
    };
    const editProject = (project, i) => {
        setActiveProject({ ...project, customRuleId: i });
        setIsAccessModal(true);
    };
    const showProject = (project, i) => {
        setActiveProject({ ...project, customRuleId: i });
        setIsLockTree(true);
    };
    const removeProject = (project) => {
        user.roles = user.roles.filter(r => r.projectId !== project.projectId);
        user.rolesChanged = true;
        user.commit();

        setDataChangedManually(true);
        handleManualFormDataChange();
    }

    useEffect(() => {
        const getResources = async () => {
            try {
                let tmpOffices = await api.office.getOffices(0, 2000);
                setOffices(tmpOffices.content.map(office => ({ label: office.name, value: office.id })));

                let tmpSupervisors = await api.office.getSupervisors(0, 2000);
                setSupervisors(tmpSupervisors.content.map(superv => ({
                    label: superv.user.firstName + ' ' + superv.user.lastName,
                    value: superv.id
                })));

                let tmpPositions = await api.userPosition.getUserPosition(0, 2000);
                setPositions(tmpPositions.content.map(pos => ({ label: pos.title, value: pos.id })));

                let roles = (await api.role.getPermittedRoles('00000000-0000-0000-0000-000000000000', currentUserLogin)).map(Role.create);

                if (currentUser['systemRoleId'] !== 5) {
                    roles = roles.filter(p => p.id === 5 ? null : p);
                }

                setSystemRoles(roles);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };

        getResources();
    }, [addError, currentUserLogin, currentUser]);

    const formatLoginExistsError = () => {
        return <>
            {user.errors.login}
            {loginExistsUser && <>
                {' '}Указанный логин уже используется{' '}
                <Link className={cs.userLink} to={`/edit-user/${user.login}`}>{`${loginExistsUser.firstName} ${loginExistsUser.lastName}`}</Link>
            </>}
        </>
    }

    useEffect(() => {
        const loadExistingUser = async () => {
            const existingUser = await api.user.getUserByLogin(user.login);
            setLoginExistsUser(existingUser);
        }

        if (user.errors.loginExists) {
            loadExistingUser();
        }
    }, [user.errors.login, user.errors.loginExists, user.login]);

    const styles = useMemo(() => ({
        multiValue: (_state) => {
            return { background: '#F5F5F5', borderRadius: 100, padding: 0 };
        },
        multiValueLabel: (_state) => {
            return { fontWeight: '500', color: '262626', paddingRight: 6, paddingLeft: 6};
        },
        multiValueRemove: (_state) => {
            return !isEditMode ? { display: 'none' } : { background: '#1280CE', color: '#fff', borderRadius: 100, width: 14, height: 14, padding: 0, margin: 4, marginLeft: 0};
        },
        dropdownIndicator: (_state) => {
            return !isEditMode ? { display: 'none' } : {};
        },
        indicatorsContainer: (_state) => {
            return !isEditMode ? { display: 'none' } : {};
        },
        valueContainer: (_state, disabled) => {
            return { background: disabled ? '#FBFBFD' : '#fff'}
        },
        control: (_state, disabled) => {
            return { background: disabled ? '#FBFBFD' : '#fff' }
        },
    }), [isEditMode])

    const disabledCheckbox = (disabled, type, key) => {
        if (!isEditMode) {
            return true;
        }

        if (!user.enabledNotificationTypes['WEBSOCKET']) {
            return true;
        }

        if (key === 'ARCHIVE') {
            return !(type === 'ARTICLE' || type === 'NEWS' || type === 'PROJECT' || type === 'SECTION');
        }

        return disabled || !isEditMode;
    }

    const onAccordionExpandedItemsChange = (items) => {
        let _items = items.reduce((acc, item) => {
            acc[item] = true;

            return acc;
        }, {})
        setExpandedAccordionItems(_items)
    }

    useEffect(() => {
        if (!dataChangedManually) {
            userRef.current = cloneDeep(user);
        } else {
            const { firstName: refFirstName, lastName: refLastName, middleName: refMiddleName, birthDate: refBirthDate, login: refLogin, email: refEmail, password: refPassword, phone: refPhone, position: refPosition, office: refOffice, supervisorId: refSupervisorId, employmentDate: refEmploymentDate, systemRoleId: refSystemRoleId } = userRef.current;
            const { firstName, lastName, middleName, birthDate, login, email, password, phone, position, office, supervisorId, employmentDate, systemRoleId } = user;
            const checkedRefParams = [ refFirstName, refLastName, refMiddleName, refBirthDate, refLogin, refEmail, refPhone, refPosition, refOffice, refSupervisorId, refEmploymentDate, refSystemRoleId ];
            const checkedParams = [ firstName, lastName, middleName, birthDate, login, email, phone, position, office, supervisorId, employmentDate, systemRoleId ];

            if (passwordChangedManually) {
                checkedRefParams.push(refPassword);
                checkedParams.push(password);
            }

            setFormDataChanged(!isEqual(checkedRefParams, checkedParams) || !isEqual(user.enabledNotificationTypes, userRef.current.enabledNotificationTypes) || !isEqual(user.roles, userRef.current.roles)/*  || isFormDataTouched */);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    // Сброс до инициализирующих данных при попытке уйти с формы добавления участника, нажав на тот же пункт в меню - "Участника"
    function resetToInitialValues () {
        return () => {
            const initialEntity = new UserFormModel();
            const initialEntityKeys = Object.keys(initialEntity);
            initialEntityKeys.forEach((key) => {
                user[key] = initialEntity[key];
                userRef.current[key] = initialEntity[key];
            })
            user.roles = [];
            userRef.current.roles = [];

            user.rolesWithGroups = [];
            userRef.current.rolesWithGroups = [];
            for (const i of ['ARTICLE', 'NEWS', 'FILE', 'USER', 'PROJECT', 'SECTION']) {
                for (const j of ['READ', 'CREATE', 'EDIT', 'DELETE', 'ARCHIVE']) {
                    if (i === 'ARTICLE' || i === 'NEWS' || i === 'PROJECT') {
                        user.enabledNotificationActionTypes[i][j] = true;
                        userRef.current.enabledNotificationActionTypes[i][j] = true;
                    } else {
                        if (j !== 'ARCHIVE') {
                            user.enabledNotificationActionTypes[i][j] = true;
                            userRef.current.enabledNotificationActionTypes[i][j] = true;
                        }
                    }
                }
            }
            const enabledNotificationTypesInitial = {
                'PUSH': false,
                'EMAIL': false,
                'WEBSOCKET': false
            };
            user.enabledNotificationTypes = enabledNotificationTypesInitial;
            userRef.current.enabledNotificationTypes = enabledNotificationTypesInitial;
            user.commit();
        }
    }

    useEffect(() => {
        if (!edit) {
            setFormDataChangedSubmitHandler(resetToInitialValues);
        }
        return () => {
            if (!edit) {
                setFormDataChangedSubmitHandler(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        user.login = generatedLogin;
        user.commit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatedLogin]);

    return (
        <>
            {loading && <Loading/>}

            <UserAccessDialog activeProjectData={activeProject} open={isAccessModal} mode={!isEditMode ? 'edit' : ''}
                              onDismiss={onCloseModal} onSubmit={saveUserProject}/>
            {isLockTree &&
                <div className={cs.overlay}>
                    <LockTree projectId={activeProject['projectId']} resourceId={activeProject['projectId']}
                              resourceType="PROJECT"
                              objectRoleLabel={activeProject['roleName']} objectId={user['login']}
                              objectTitle={user['firstName'] + ' ' + user['lastName']}
                              objectAvatar={user['avatarUuid'] ? api.upload.getImage(user['avatarUuid'], false, 128) : null}
                              onClose={() => setIsLockTree(false)}/>
                </div>}
            <Card id={'userFormCard'} className={classNames(cs.form, {[cs.mobile]: platform === 'mobile'})}>
                <div>
                    <div className={classNames(cs.blockText, cs.fieldGroup)}>Личные данные</div>
                    <div className={cs.dataLine}>
                        <Input type='text'
                               value={user.firstName}
                               onChange={str => {
                                   setDataChangedManually(true);
                                   handleManualFormDataChange();

                                   user.firstName = str
                                       .toLowerCase()
                                       .split('-')
                                       .map(p => p.charAt(0).toUpperCase() + p.slice(1))
                                       .join('-')
                                       .replace(/ {2,}/g, ' ')
                                       .split(' ')
                                       .map(p => p.charAt(0).toUpperCase() + p.slice(1))
                                       .join(' ');

                                   user.validateFields(['firstName']);
                                   user.commit();
                               }}
                               onBlur={() => {
                                   user.firstName = user.firstName.trim();
                                   user.commit();

                                   if (!disableLoginEdit) {
                                       updateLogin();
                                   }
                               }}
                               placeholder={'Имя'}
                               label='Имя'
                               disabled={!isEditMode}
                               name={'firstName'}
                               required
                               id={'userFormFirstName'}
                               error={user.errors.firstName}/>
                        <Input type='text'
                               value={user.middleName}
                               onChange={str => {
                                   setDataChangedManually(true);
                                   handleManualFormDataChange();

                                   user.middleName = str
                                       .toLowerCase()
                                       .split('-')
                                       .map(p => p.charAt(0).toUpperCase() + p.slice(1))
                                       .join('-')
                                       .replace(/ {2,}/g, ' ')
                                       .split(' ')
                                       .map(p => p.charAt(0).toUpperCase() + p.slice(1))
                                       .join(' ');

                                   user.validateFields(['middleName']);
                                   user.commit();
                               }}
                               onBlur={() => {
                                   user.middleName = user.middleName.trim();
                                   user.commit();
                               }}
                               placeholder={'Отчество'}
                               label='Отчество'
                               disabled={!isEditMode}
                               name={'middleName'}
                               id={'userFormMiddleName'}
                               error={user.errors.middleName}/>
                    </div>
                    <div className={cs.dataLine}>
                        <Input type='text'
                               value={user.lastName}
                               onChange={str => {
                                   setDataChangedManually(true);
                                   handleManualFormDataChange();

                                   user.lastName = str
                                       .toLowerCase()
                                       .split('-')
                                       .map(p => p.charAt(0).toUpperCase() + p.slice(1))
                                       .join('-')
                                       .replace(/ {2,}/g, ' ')
                                       .split(' ')
                                       .map(p => p.charAt(0).toUpperCase() + p.slice(1))
                                       .join(' ');

                                   user.validateFields(['lastName']);
                                   user.commit();
                               }}
                               onBlur={() => {
                                   user.lastName = user.lastName.trim();
                                   user.commit();

                                   if (!disableLoginEdit) {
                                       updateLogin();
                                   }
                               }}
                               placeholder={'Фамилия'}
                               label='Фамилия'
                               required
                               disabled={!isEditMode}
                               name={'lastName'}
                               id={'userFormLastName'}
                               error={user.errors.lastName}/>
                        <DatePicker
                            value={user.birthDate ? new Date(user.birthDate) : null}
                            label='Дата рождения'
                            error={user.errors.birthDate}
                            disabled={!isEditMode}
                            required
                            maxDate={new Date('9999-01-01')}
                            id={'userFormBirthDate'}
                            onChange={date => {
                                setDataChangedManually(true);
                                handleManualFormDataChange();
                                user.birthDate = date;
                                user.validateFields(['birthDate']);
                                user.commit();
                            }}/>
                    </div>
                </div>
                <Divider/>
                <div>
                    <div className={classNames(cs.blockText, cs.fieldGroup)}>Данные аккаунта</div>
                    <div className={cs.dataLine}>
                        <Input type='text'
                               value={user.login}
                               onChange={str => {
                                   setDataChangedManually(true);
                                   handleManualFormDataChange();
                                   user.login = str;
                                   user.validateFields(['login']);
                                   user.commit();
                               }}
                               placeholder={'Логин'}
                               label='Логин'
                               disabled={!isEditMode || disableLoginEdit}
                               name={'login'}
                               required
                               id={'userFormLogin'}
                               error={user.errors.loginExists ? formatLoginExistsError() : user.errors.login}/>
                        <Input type='text'
                               value={user.email}
                               onChange={str => {
                                   setDataChangedManually(true);
                                   handleManualFormDataChange();
                                   user.email = str;
                                   user.validateFields(['email']);
                                   if (user.email?.length === 0) {
                                       user.enabledNotificationTypes['EMAIL'] = false;
                                   }
                                   user.commit();
                               }}
                               placeholder={'Почта'}
                               label='Почта'
                               disabled={!isEditMode}
                               name={'email'}
                               id={'userFormEmail'}
                               error={user.errors.email}/>
                    </div>
                    <div className={cs.dataLine}>
                        <Input type='text' value={user.password} placeholder={'Пароль'} label='Пароль'
                               disabled={!isEditMode} name={'password'} error={user.errors.password}
                               id={'userFormPassword'}
                               onChange={str => {
                                   setPasswordChangedManually(true);
                                   setDataChangedManually(true);
                                   handleManualFormDataChange();
                                   user.password = str;
                                   user.validateFields(['password'], allowEmptyPassword);
                                   user.commit();
                               }}/>
                        <InputMask value={user.phone} disabled={!isEditMode} mask={'+7 999 999 9999'}
                                   onChange={(str) => {
                                       if (typeof str !== 'string') {
                                           return;
                                       }
                                       setDataChangedManually(true);
                                       handleManualFormDataChange();

                                       if (str.length === 16 && str[str.length - 2] === '_') {
                                           str = str.slice(0, -1);
                                       }

                                       if (str.replaceAll('_', '').replaceAll(' ', '') === user.phone) {
                                           user.phone = user.phone.slice(0, -1);
                                       } else {
                                           user.phone = str.replaceAll('_', '').replaceAll(' ', '');
                                       }

                                       user.validateFields(['phone']);
                                       user.commit();
                                   }}>
                            <Input id={'userFormPhone'} type={'text'} placeholder={'Телефон'} label={'Телефон'}
                                   error={user.errors.phone}/>
                        </InputMask>
                    </div>
                </div>
                <Divider/>
                <div>
                    <div className={classNames(cs.blockText, cs.fieldGroup)}>Рабочие данные</div>
                    <div className={cs.dataLine}>
                        <Select
                            id="userFormPosition"
                            label="Должность"
                            placeholder="Должность"
                            disabled={!isEditMode}
                            value={positions.find(v => v.value === user.position)}
                            options={positions}
                            onChange={(v) => {
                                setDataChangedManually(true);
                                handleManualFormDataChange();

                                user.position = v.value;
                                user.commit();
                            }}
                        />
                        <Select
                            id="userFormOffice"
                            label="Офис"
                            placeholder="Офис"
                            disabled={!isEditMode}
                            value={offices.find(v => v.value === user.office)}
                            options={offices}
                            onChange={(v) => {
                                setDataChangedManually(true);
                                handleManualFormDataChange();

                                user.office = v.value;
                                user.commit();
                            }}
                        />
                    </div>
                    <div className={cs.dataLine}>
                        <Select disabled={!isEditMode} label={'Руководитель'} placeholder="Руководитель"
                                id={'userFormSupervisor'}
                                value={supervisors.find(v => v.value === user.supervisorId)} options={supervisors}
                                onChange={(v) => {
                                    setDataChangedManually(true);
                                    handleManualFormDataChange();
                                    user.supervisorId = v.value;
                                    user.commit();
                                }}/>
                        <DatePicker
                            value={user.employmentDate ? new Date(user.employmentDate) : null}
                            label='Дата приема на работу'
                            error={user.errors.employmentDate}
                            disabled={!isEditMode}
                            required
                            maxDate={new Date('9999-01-01')}
                            id={'userFormEmploymentDate'}
                            onChange={date => {
                                setDataChangedManually(true);
                                handleManualFormDataChange();
                                user.employmentDate = date;
                                user.validateFields(['employmentDate']);
                                user.commit();
                            }}
                        />
                    </div>
                </div>
                <Divider/>
                <div>
                    <div className={classNames(cs.blockText, cs.fieldGroup)}>Группы участника</div>
                    <Select value={user.groupList.map((item) => {
                        return { label: item.name, value: item.id };
                    })} isMulti style={styles} disabled/>
                </div>
                <Divider className={cs.topDivider}/>
                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} onChange={onAccordionExpandedItemsChange}>
                    <AccordionItem uuid="notifications">
                        <AccordionItemHeading>
                            <AccordionItemButton className={classNames(cs.accordionButton, {
                                [cs.accordionButtonActive]: expandedAccordionItems['notifications']
                            })}>
                                Настройка уведомлений
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className={cs.notifSettingsContainer}>
                                <div className={cs.modeContainer}>
                                    {notificationTypesInfo.map(({ type, title, desc, disabled }) => {
                                        if (!showAllNotificationTypes && type !== 'WEBSOCKET' && type !== 'EMAIL') {
                                            return null;
                                        } else {
                                            return (
                                                <div key={title} className={cs.modeItem}>
                                                    <div className={cs.controlContainer}>
                                                        <Checkbox
                                                            type="checkbox"
                                                            checked={user.enabledNotificationTypes[type]}
                                                            disabled={disabled}
                                                            onChange={() => {
                                                                setDataChangedManually(true);
                                                                handleManualFormDataChange();
                                                                user.enabledNotificationTypes = {
                                                                    ...user.enabledNotificationTypes,
                                                                    [type]: !user.enabledNotificationTypes[type]
                                                                };
                                                                user.commit();
                                                            }}/>
                                                    </div>
                                                    <div className={cs.descContainer}>
                                                        <p className={cs.label}>{title}</p>
                                                        <p className={cs.desc}>{desc}</p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                                {platform !== 'mobile' &&
                                    <table className={cs.rulesTable}>
                                        <thead>
                                        <tr>
                                            <th/>
                                            <th>Просмотр</th>
                                            <th>Создание</th>
                                            <th>Изменение</th>
                                            <th>Удаление</th>
                                            <th>Архивирование</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {notificationActionTypesInfo.map(({ type, title, disabled }) => {
                                            return (
                                                <tr key={type}>
                                                    <td>{title}</td>
                                                    {['READ', 'CREATE', 'EDIT', 'DELETE', 'ARCHIVE'].map((key) => {
                                                        return (
                                                            <td key={key}>
                                                                <Checkbox
                                                                    type="checkbox"
                                                                    checked={user.enabledNotificationActionTypes[type][key]}
                                                                    disabled={disabledCheckbox(disabled, type, key)}
                                                                    onChange={() => {
                                                                        setDataChangedManually(true);
                                                                        handleManualFormDataChange();
                                                                        user.enabledNotificationActionTypes[type][key] =
                                                                            !user.enabledNotificationActionTypes[type][key];
                                                                        user.commit();
                                                                    }}
                                                                />
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                        }
                                        </tbody>
                                    </table>}
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem uuid="project-access">
                        <AccordionItemHeading>
                            <AccordionItemButton className={classNames(cs.accordionButton, {
                                'field-invalid': user.errors.roles,
                                [cs.accordionButtonActive]: expandedAccordionItems['project-access']
                            })}>
                                Доступ к проектам
                                {user.errors.roles && <span className={cs.errorText}>{user.errors.roles}</span>}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className={cs.addProjectContainer}>
                                {isEditMode &&
                                    <div onClick={() => setIsAccessModal(true)} className={cs.addButton}>
                                        <Icon width={20} height={20} type={Icons.OPEND_LOCK}/>
                                        <p>Добавить доступ</p>
                                    </div>
                                }
                                <div className={cs.projectsContainer}>
                                    {(isEditMode ? user.roles : user.rolesWithGroups).map((item, i) => (
                                        <div key={i} className={cs.projectContainer}>
                                            <div className={cs.projectInfo}>
                                                <div className={cs.projectAvatar}>
                                                    {item['logo'] &&
                                                        <img src={api.upload.getImage(item['logo'], false, 128)}
                                                             alt=""/>}
                                                </div>
                                                <p className={cs.projectName}>{item['projectName']}</p>
                                            </div>
                                            <div className={cs.projectButtons}>
                                                <p>{item['roleName']}</p>
                                                <div className={cs.buttonsContainer}>
                                                    {!isEditMode &&
                                                        <div className={cs.iconButton}>
                                                            <IconButton icon={<Icon type={Icons.EYE} tooltip={'Обзор'}/>}
                                                                        onClick={() => showProject(item, i)}/>
                                                        </div>}
                                                    {isEditMode &&
                                                        <div className={cs.iconButton}>
                                                            <IconButton icon={<Icon type={Icons.EDIT_PEN}
                                                                                    tooltip={'Редактировать'}/>}
                                                                        onClick={() => editProject(item, i)}/>
                                                        </div>}
                                                    {isEditMode &&
                                                        <div className={cs.iconButton}>
                                                            <IconButton icon={<Icon type={Icons.TRASH}
                                                                                    tooltip={'Удалить'}/>}
                                                                        onClick={() => removeProject(item)}/>
                                                        </div>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>

                    <AccordionItem uuid="system-role">
                        <AccordionItemHeading>
                            <AccordionItemButton className={classNames(cs.accordionButton, cs.lastAccordion, {
                                [cs.accordionButtonActive]: expandedAccordionItems['system-role']
                            })}>
                                Системная роль
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className={cs.systemAccessContainer}>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <div className={cs.controlContainer} style={{ width: platform === 'desktop' ? '40%' : '100%'}}>
                                        <Select
                                            id="systemRole"
                                            disabled={!isEditMode}
                                            label=""
                                            placeholder="Системная роль"
                                            isSearchable={false}
                                            value={(systemRoles.find(r => r.id === user.systemRoleId) || Role.defaultRole).dropdownOption()}
                                            options={systemRoles.map(r => r.dropdownOption())}
                                            onChange={(v) => {
                                                setDataChangedManually(true);
                                                handleManualFormDataChange();
                                                user.systemRoleId = v.value;
                                                user.rolesChanged = true;
                                                user.commit();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>

                <Divider className={cs.toolDivider}/>
                {children}
            </Card>
        </>
    );
};

UserForm.propTypes = {
    user: PropTypes.instanceOf(UserFormModel).isRequired,
    loading: PropTypes.bool
};

UserForm.defaultProps = {
    loading: false
};

export default UserForm;
