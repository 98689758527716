import { useState, useEffect } from 'react';

export function useDebounceValue(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    const [cancel, setCancel] = useState(null);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            setCancel(handler);

            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay]
    );

    return [debouncedValue, cancel];
}

export function useDebounceFunction(func, delay) {
    const [handler, setHandler] = useState(null);

    return function(...args) {
        if (handler) {
            clearTimeout(handler);
        }

        setHandler(
            setTimeout(() => {
                func(...args);
            }, delay)
        );
    };
}