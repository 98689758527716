import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import cs from './back-button.module.scss';
import { Link } from '@reach/router';

const BackButton = ({ id, className, onClick, link }) => {
    if (link) {
        return (
            <Link to={link}>
                <Icon id={id + "BackBtn"} className={classNames(cs.backButton, className)} type={Icons.BACK2} width={32} height={32}/>
            </Link>
        );
    } else {
        return (
            <Icon id={id + "BackBtn"} className={classNames(cs.backButton, className)} type={Icons.BACK2} width={32} height={32}
                  onClick={onClick} />
        );
    }
};

BackButton.propTypes = {
    id: propTypes.string,
    className: propTypes.string,
    onClick: propTypes.func,
    link: propTypes.string
};

BackButton.defaultProps = {
    id: "",
    className: null,
    onClick: () => {

    },
    link: ''
};

export default BackButton;