import { DateTime } from 'luxon';
import TreeUtils from 'lib/util/tree.util';
import { tickerColorOptions, tickerTimeRangeOptions } from 'lib/config/constant';

export const getInitialExactQueryGroupsItem = () => {
    return {
        exactAnswer: '',
        exactQueries: []
    }
}

export class NewsFormModel {
    id = null;
    initId = null;
    logo = '';
    title = '';
    text = '';
    expireTime = null;
    originalExpireTime = null;
    createTime = null;
    archived = false;
    global = false;
    files = [];
    attachments = [];
    logoUuid = null;
    priority = null;
    parentProjectId = null;
    isTicker = false;
    ticker = null;
    tickerTime = tickerTimeRangeOptions[0];
    tickerColor = tickerColorOptions[0];
    meta = {
        project: null,
        visualPath: [],
        files: []
    };
    errors = {
        title: null,
        text: null,
        to: null
    };
    touched = {};
    timeId = null;
    insignificantChanges = false;
    notifySystem = false;
    notifyEmail = false;
    from = null;
    exactQueryGroups = [
        getInitialExactQueryGroupsItem()
    ];
    documentTags = [];

    constructor(news) {
        if (news?.id) {
            this.id = news.id;
            this.initId = news.initId;
            this.logo = news.logo || '';
            this.title = news.title;
            this.text = news.text;
            this.description = news.description;
            this.publishedType = news.publishedType || 'news';
            this.expireTime = news.expireTime ? new Date(Date.parse(news.expireTime)) : DateTime.local().plus({ years: 10 }).toJSDate();
            this.createTime = news.createTime;
            this.archived = news.archived;
            this.global = news.global;
            this.files = news.files || [];
            this.attachments = news.attachments || [];
            this.logoUuid = news.logoUuid;
            this.priority = news.priority || 'MEDIUM';
            this.parentProjectId = news.project?.id;
            this.meta.project = news.project;
            this.ticker = news.ticker;
            this.to = news.to;
            this.exactQueryGroups = news.exactQueryGroups || [
                getInitialExactQueryGroupsItem()
            ];
            this.documentTags = news.documentTags ?? [];
            if (news.publicationTime) {
                this.from = new Date(Date.parse(news.publicationTime));
            } else {
                this.from = news.from;
            }

            if (news.ticker) {
                this.isTicker = true;
                this.tickerColor = tickerColorOptions.find((item) => item.value === news.ticker.color);
                this.tickerTime = tickerTimeRangeOptions.find((item) => item.value === (news.ticker.timeToLive / 24));
            } else {
                this.isTicker = false;
                this.tickerColor = tickerColorOptions[0];
                this.tickerTime = tickerTimeRangeOptions[0];
            }

            this.meta.visualPath = (function(project) {
                const _path = [];

                if (project) {
                    _path.push(project.title);
                    _path.push('Корень проекта');
                }

                return _path;
            })(news.project);
        } else {
            this.initId = news?.initId
            this.priority = 'MEDIUM';
            this.expireTime = DateTime.local().plus({ years: 10 }).toJSDate();
            this.timeId = Date.now().toString();
        }
    }

    copy() {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }

    getVisualPath() {
        return this.meta.visualPath;
    }

    setVisualPath(node) {
        this.meta.visualPath = TreeUtils.getVisualPathTo(node);
    }

    getParentsIds() {
        return [this.parentProjectId];
    }

    clearPath() {
        this.parentProjectId = null;
        this.meta.visualPath = [];
    }

    getFormData() {
        const data = {};

        if (this.id) {
            data.id = this.id;
            data.newsId = this.id;
        }

        data.initId = this.initId;
        data.title = this.title;
        data.description = this.description;
        data.text = this.text;
        data.priority = this.priority;
        data.global = this.global;
        data.parentProjectId = this.parentProjectId;
        data.files = this.getFiles().filter(f => f.status === 'done').map(f => f.fileId);
        data.expireDate = (this.expireTime instanceof Date) ? this.expireTime?.toISOString() : this.expireTime;

        data.logoType = this.logo !== null && this.logo.length > 0 ? this.logo.split(';')[0].split(':')[1] : null;
        data.logo = this.logo !== null ?
                this.logo.length > 0 ? this.logo.split(',')[1] : ''
            : null;
        data.documentTags = this.documentTags;
        // data.global = false;

        if (this.from instanceof Date && this.from > Date.now()) {
            data.publicationTime = this.from?.toISOString();
        }

        if (this.isTicker) {
            data.tickerRequest = {
                timeToLive: this.tickerTime.value * 24,
                color: this.tickerColor.value
            };
        } else {
            data.tickerRequest = null;
        }

        data.insignificantChanges = this.insignificantChanges;
        data.notifySystem = this.notifySystem;
        data.notifyEmail = this.notifyEmail;
        data.exactQueryGroups = this.exactQueryGroups;

        return data;
    }

    static asFiles(attachments) {
        return attachments.map(att => ({
            status: 'done',
            fileId: att.id || att.fileId,
            serverData: { ...att }
        }));
    }

    getFiles() {
        return this.meta.files.length > 0 ? this.meta.files : NewsFormModel.asFiles(this.attachments);
    }

    setFiles(files) {
        this.meta.files = files;
    }
}
