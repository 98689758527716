import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonUndo = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const undo = e => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);
        editor?.chain().focus().undo().run();
    };

    return (
        <MenuButton
            tooltip="Отменить"
            icon={Icons.EditorIconUndo}
            onClick={undo}
            disabled={!editor?.isEditable || !editor?.can().undo()}
        ></MenuButton>
    );
};
