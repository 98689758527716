import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';
import { desktopStyles } from 'lib/util/modalStyles';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import Input from 'uikit/input/input';

export const MenuButtonYoutube = ({ setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const [show, setShow] = React.useState(false);

    const toggleDialog = () => {
        setCurrentMenu(undefined);
        setShow(prev => !prev);
    };

    const handleSubmit = (src, width, height) => {
        editor.commands.addEmbedVideo({
            src,
            width,
            height,
            align: 'left',
        });
        toggleDialog();
    };

    return (
        <>
            <MenuButton
                icon={Icons.EditorIconYoutube}
                tooltip={isTooltip === false ? null : 'Добавить видео с видеохостинга'}
                label="Добавить видео с видеохостинга"
                onClick={toggleDialog}
            ></MenuButton>

            {show && <EmbedVideoModal show={show} onClose={toggleDialog} onSubmit={handleSubmit} />}
        </>
    );
};

export const EmbedVideoModal = ({ data, show, onClose, onSubmit }) => {
    const [url, setUrl] = React.useState('');
    const [width, setWidth] = React.useState('640');
    const [height, setHeight] = React.useState('360');

    const handleSubmit = () => {
        onSubmit(url, width, height);
    };

    useEffect(() => {
        if (data) {
            setUrl(data.url || '');
            setWidth(data.width || '640');
            setHeight(data.height || '360');
        }
    }, [data]);

    return (
        <Modal
            isOpen={show}
            preventScroll
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 570, width: 'calc(100% - 20px)', border: 'none' } }}
        >
            <ModalHeader title="Добавление видео из видеохостинга" onClose={onClose} />
            <ModalBody>
                <Input
                    wrapperStyle={{ paddingBottom: 20 }}
                    type="text"
                    value={url}
                    onChange={setUrl}
                    label="Ссылка на видео"
                    placeholder="Вставьте ссылку"
                    required={true}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Input wrapperStyle={{ marginRight: 11 }} type="number" value={width} onChange={setWidth} label="Ширина" />
                    <Input wrapperStyle={{ marginLeft: 11 }} type="number" value={height} onChange={setHeight} label="Высота" />
                </div>
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: onClose },
                    {
                        label: 'Сохранить',
                        color: 'blue',
                        onClick: handleSubmit,
                        disabled: !url.replaceAll(' ', '')
                    },
                ]}
            />
        </Modal>
    );
};
