import { Node, mergeAttributes } from '@tiptap/core';
import { findParentNodeClosestToPos } from '@tiptap/core';

export const MultiList = Node.create({
    name: 'multi-list',

    group: 'block',
    content: 'list*',

    addOptions() {
        return {
            levels: 3,
        };
    },

    addAttributes() {
        const attributes = {};

        for (let i = 1; i <= this.options.levels; i++) {
            attributes['data-level-' + i] = { default: null };
        }

        return attributes;
    },

    parseHTML() {
        return [
            {
                tag: 'div.multi-list',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(HTMLAttributes, { class: 'multi-list' }), 0];
    },

    addCommands() {
        return {
            addMultiList:
                (levels, listTypes, wrapRange) =>
                ({ chain, editor, tr, dispatch, view }) => {
                    const attrs = {};

                    listTypes.forEach((type, idx) => {
                        attrs['data-level-' + (idx + 1)] = type;
                    });

                    if (wrapRange) {
                        const nearestParent = findParentNodeClosestToPos(view.state.selection.$anchor, node => {
                            return node.type.name === levels.type;
                        });

                        nearestParent.node.descendants((node, pos) => {
                            if (node.type.name === 'listItem') {
                                tr.setNodeMarkup(nearestParent.pos + pos + 1, editor.schema.nodes.multiListItem, {
                                    'data-type': 'multi-list-item',
                                    ...node.attrs,
                                });
                            }
                        });

                        chain().setNodeSelection(nearestParent.pos).wrapIn(this.name, attrs).run();

                        dispatch(tr);

                        return true;
                    }

                    tr.insert(
                        editor.state.selection.$from.pos - 1,
                        editor.view.state.doc.type.schema.nodeFromJSON({ type: this.name, attrs, content: [levels] })
                    );

                    return dispatch(tr);
                },
        };
    },
});
