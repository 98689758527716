import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
    name: 'application',
    initialState: {
        lastLockUser: null
    },
    reducers: {
        setLastLockUser: (state, action) => {
            state.lastLockUser = action.payload;
        }
    }
});

export const { setLastLockUser } = appSlice.actions;
export const selectLastLockUser = state => state.app.lastLockUser;
export default appSlice.reducer;