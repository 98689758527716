import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import api from 'api';
import cx from './chat-dialog.module.scss';
import IconButton from 'uikit/icon-button';

export default function ChatDialog({ users, onOpenChat, onClose }) {
    const [usersData, setUsersData] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setUsersData(users.filter((user) => {
                const title = user.firstName + ' ' + user.lastName;
                return title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }));
        };
        fetchData();
    }, [users, search]);

    return (
        <Modal isOpen={true} className={cx.chatDialog} overlayClassName={cx.chatDialogOverlay}>
            <div className={cx.chatDialogHeader}>
                <div className={cx.chatDialogHeaderTop}>
                    <h3>Ваши контакты</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={onClose}/>
                </div>
                <div className={cx.chatDialogHeaderBottom}>
                    <div className={cx.chatDialogHeaderBottomSearch}>
                        <Input placeholder="Поиск по имени ..." value={search} onChange={(value) => setSearch(value)}/>
                        <Icon type={Icons.SEARCH} width={13} height={13}/>
                    </div>
                </div>
            </div>
            <div className={cx.chatDialogBody}>
                {usersData.length === 0 &&
                <div className={cx.chatDialogBodyEmpty}>
                    <Icon type={Icons.SEARCH} width={50} height={50}/>
                    <p>Ничего не найдено</p>
                </div>}
                {usersData.length !== 0 &&
                <div className={cx.chatDialogBodyUsers}>
                    {usersData.map((user) => {
                        return (
                            <div key={user.id} className={cx.chatDialogBodyUsersItem}
                                 onClick={() => onOpenChat(user.id, 0)}>
                                <div className={cx.chatDialogBodyUsersItemContent}>
                                    <div className={cx.chatDialogBodyUsersItemContentAvatar}>
                                        <img src={user.avatarUuid ? api.upload.getImage(user.avatarUuid, false, 128) : '/img/avatar.png'} alt="" />
                                    </div>
                                    <div className={cx.chatDialogBodyUsersItemContentData}>
                                        <p>{user.firstName} {user.lastName}</p>
                                        {/*<span>{user.isOnline ? 'Онлайн' : 'Оффлайн'}</span>*/}
                                    </div>
                                </div>
                                <Icon type={Icons.CHAT} width={15} height={13}/>
                            </div>
                        );
                    })}
                </div>}
            </div>
        </Modal>
    );
};
