import React, { useState } from 'react';
import Modal from 'react-modal';
import Tree from 'components/trees/training-tree/training-tree';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Button from 'uikit/button';
import cx from './training-question-modal.module.scss';

export default function TrainingQuestionModal({ onQuestions, onClose }) {
    const [nodes, setNodes] = useState([]);

    const onCheck = (node) => {
        let items;

        if (node['resourceType'] === 'THEME') {
            items = node.children;
        } else {
            items = [node];
        }

        const data = Object.assign([], nodes);

        for (let i = 0; i < items.length; i++) {
            const nodeIndex = data.indexOf(items[i]);

            if (items[i].state.checked && nodeIndex === -1) {
                data.push(items[i]);
            } else if (!items[i].state.checked && nodeIndex !== -1) {
                data.splice(nodeIndex, 1);
            }
        }

        setNodes(data);
    };

    return (
        <Modal isOpen={true} className={cx.trainingQuestionModal} overlayClassName={cx.trainingQuestionModalOverlay}
               onRequestClose={() => onClose()}>
            <div className={cx.trainingQuestionModalHeader}>
                <h2>Добавить содержимое</h2>
                <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>} onClick={onClose}/>
            </div>
            <div className={cx.trainingQuestionModalBody}>
                <Tree canAdd={false} onCheck={onCheck}/>
            </div>
            <div className={cx.trainingQuestionModalFooter}>
                <Button label="Отмена" onClick={onClose}/>
                <Button label="Подтвердить" color="green" onClick={() => onQuestions(nodes)}/>
            </div>
        </Modal>
    );
};