import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export function useVideoPlayer({ src, controls, fluid, autoplay, startTime = 0, rewind = true }) {
    const playerRef = useRef(null);
    const [duration, setDuration] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const memoizedSrc = useMemo(() => {
        return { src: src, type: "video/mp4" };
    }, [src]);

    const onReady = useCallback(
        (player) => {
            if (!memoizedSrc) {
                return;
            }
            playerRef.current = player;
            setIsReady(true);
        },
        [memoizedSrc],
    );

    const onError = useCallback((player) => {
        console.log(player);
    }, []);

    const getPlayerProps = useCallback(() => {
        return {
            controls,
            fluid,
            autoplay,
            onReady,
            rewind,
            watchTime: startTime,
            src: memoizedSrc,
            onError,
        };
    }, [controls, fluid, autoplay, onReady, rewind, startTime, memoizedSrc, onError]);

    useEffect(() => {
        if (!playerRef.current || !isReady) {
            return;
        }

        if (!memoizedSrc?.src) {
            setIsReady(false);
        } else {
            playerRef.current.one("canplay", () => {
                setTimeout(() => {
                    playerRef.current.currentTime(startTime);
                }, 2000);
            });
            playerRef.current.one("durationchange", () => {
                setDuration(playerRef.current?.duration() || 0);
            });
        }
    }, [memoizedSrc, playerRef, isReady, startTime]);

    return {
        player: playerRef.current,
        getPlayerProps,
        duration,
    };
}
