import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { idFromString } from 'lib/helpers';
import cx from './textarea.module.scss';

const TextArea = ({ id, label, error, className, onChange, description, ...attrs }) => {
    const key = id || idFromString(label) || idFromString(attrs.placeholder);
    const clsName = classNames(cx.base, className, {
        [cx.error]: error
    });

    return (
        <div className={clsName}>
            {label.length > 1 ? <label htmlFor={key} className={cx.label}>{label}</label> : ''}
            <div className={cx.inputWrapper}>
                <textarea id={key} className={classNames(cx.input)}
                          onChange={(e) => onChange(e.target.value)} {...attrs}/>
            </div>
            {description && <span className={cx.description}>{description}</span>}
            {error && <span className={cx['error-message']}>{error}</span>}
        </div>
    );
};

TextArea.defaultProps = {
    id: null,
    label: '',
    className: '',
    onChange: () => {

    }
};

TextArea.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.string
};

export default TextArea;