import React from 'react';
import { ContentWrapper } from 'containers/content-wrapper';
import { Empty } from '../../../components/projects';
import cx from './development-page.module.scss';

export default function DevelopmentPage() {
    return (
        <ContentWrapper>
            <div className={cx.container}>
                <Empty title="Страница в разработке"
                       description="Сейчас страница разрабатывается, но скоро здесь что-то будет"/>
            </div>
        </ContentWrapper>
    );
};