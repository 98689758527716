import React from 'react';
import hot from 'containers/hot-container';
import { contentManagementProjectFormPage } from 'components/content-management-project-form';
import Button from 'uikit/button';
import { useGlobalContext, useMessage } from 'lib/hooks';
import api from 'api/index';

const ContentManagementProjectPage = ({ project, loading, setLoading, navigate }) => {
    const { platform } = useGlobalContext();
    const { addSuccess } = useMessage();

    const save = async () => {
        setLoading(true);

        if (project.users?.length > 0) {
            await api.errorReportResource.addErrorReportManagers(project.users, project.id, 'PROJECT');
        }

        if (project.managersRemove?.length > 0) {
            await api.errorReportResource.deleteErrorReportManagers(project.managersRemove, project.id, 'PROJECT');
        }

        setLoading(false);
        navigate(`/projects/${project.id}/articles`);

        addSuccess('Контент-менеджеры обновлены');
    }

    return (
        <>
            <Button label={'Сохранить'} disabled={loading} color={'green'} fullWidth={platform === 'mobile'}
                    onClick={save}/>
        </>
    );
};

export default hot(contentManagementProjectFormPage(ContentManagementProjectPage, 'Контент-менеджмент'));