import { useCallback, useEffect, useState } from 'react';
import api from 'api';

export function usePermittedActions(resourceType = null, resourceId = null) {
    const [permissions, setPermissions] = useState([]);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (resourceType && resourceId) {
            setReady(false);

            api.resource.getPermittedActions(resourceType, resourceId)
                .then(perms => {
                    setPermissions(perms);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    setReady(true);
                });
        }
    }, [resourceType, resourceId]);

    const checkPermission = useCallback((perm) => {
        if (resourceType === null && resourceId === null) {
            return true;
        }

        if (Array.isArray(perm)) {
            for (let p of perm) {
                if (!permissions.includes(p)) {
                    return false;
                }
            }

            return true;
        } else {
            return permissions.includes(perm);
        }
    }, [permissions, resourceId, resourceType]);

    return { checkPermission, ready };
}