import React, { useState } from 'react';
import Modal from 'react-modal';
import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';
import { desktopStyles } from 'lib/util/modalStyles';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import Input from 'uikit/input/input';

export const MenuButtonAnchor = ({ setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const [data] = useState({ name: '' });
    const [show, setShow] = React.useState(false);

    const toggleDialog = () => {
        setCurrentMenu(undefined);

        setShow(prev => !prev);
    };

    const handleSubmit = name => {
        editor.commands.setAnchor(name);

        toggleDialog();
    };

    return (
        <>
            <MenuButton
                icon={Icons.EditorIconAnchor}
                onClick={toggleDialog}
                tooltip={isTooltip === false ? null : 'Якорь'}
                label="Якорь"
                selected={editor?.isActive('anhor') ?? undefined}
            ></MenuButton>

            {show && <AnchorModal data={data} show={show} onClose={toggleDialog} onSubmit={handleSubmit} />}
        </>
    );
};

export const AnchorModal = ({ data, show, onClose, onSubmit }) => {
    const [name, setName] = React.useState('');

    const onChange = value => {
        let str = String(value);
        str = str.replace(/[^0-9a-zа-яё_]/gim, '');

        setName(str);
    };

    const handleClose = () => {
        setName('');
        onClose();
    };

    const handleSubmit = () => {
        setName('');
        onSubmit(name);
    };

    React.useEffect(() => {
        if (data) {
            setName(data.name || '');
        }
    }, [data]);

    return (
        <Modal
            isOpen={show}
            preventScroll
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 570, width: 'calc(100% - 20px)', border: 'none' } }}
        >
            <ModalHeader title="Свойства якоря" onClose={handleClose} />
            <ModalBody>
                <Input
                    wrapperStyle={{ paddingBottom: 20 }}
                    type="text"
                    value={name}
                    onChange={onChange}
                    label="Имя якоря"
                    placeholder="Введите имя якоря"
                    required={true}
                />
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: handleClose },
                    {
                        label: 'Сохранить',
                        color: 'blue',
                        onClick: handleSubmit,
                        disabled: !String(name).replaceAll(' ', ''),
                    },
                ]}
            />
        </Modal>
    );
};
