import React, { useEffect, useMemo, useState } from 'react';
import cs from './TagCategory.module.scss';
import Divider from 'uikit/divider';
import api from 'api';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import { Tag } from 'app/global-settings/tags-page/Tag';
import Confirmation from 'components/confirmation';

export const TagCategory = ({ id, name, tags: allTags, onEdit, onAfterDelete }) => {
    const [deleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => {
        const getCategory = async () => {
            const response = await api.tags.getCategory(id);
            console.log('response', response);
        }

        getCategory();
    }, [id]);

    const tags = useMemo(() => {
        return allTags.filter(test => test.documentTagCategory.id === id);
    }, [allTags, id]);

    const handleDelete = async () => {
        await api.tags.deleteCategory(id);
        onAfterDelete();
    }

    return (
        <>
            <div className={cs.category}>
                <div className={cs.name}>{name}</div>
                <div className={cs.tags}>
                    {tags.map(tag => (
                        <Tag key={tag.id} name={tag.name}/>
                    ))}
                </div>
                <div className={cs.buttons}>
                    <IconButton
                        icon={<Icon type={Icons.PLUS_ADD} width={16} height={16}/>}
                        onClick={() => onEdit(id, name, tags, true)}
                    />
                    <IconButton
                        icon={<Icon type={Icons.EDIT_PEN} width={16} height={16}/>}
                        onClick={() => onEdit(id, name, tags)}
                    />
                    <IconButton
                        icon={<Icon type={Icons.TRASH} width={16} height={16}/>}
                        onClick={() => setDeleteOpen(true)}
                    />
                </div>
            </div>
            <Confirmation
                isOpen={deleteOpen}
                text={`Удалить группу "${name}"?`}
                title='Удаление группы'
                submitBtnText='Удалить'
                color='red'
                onRequestClose={() => setDeleteOpen(false)}
                onRequestSubmit={handleDelete}
                contentType={'TEXT'}
            />
            <Divider/>
        </>
    )
}
