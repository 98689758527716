import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useCancelBehavior, useGlobalContext, useMessage } from 'lib/hooks';
import hot from 'containers/hot-container';
import { ContentWrapper } from 'containers/content-wrapper';
import Button from 'uikit/button';
import api from 'api';
import cs from 'components/user-form/user-form.module.scss';
import { useUserFormModel } from 'lib/hooks/use-user-form-model';
import PrivatePage from 'containers/private-page-wrapper';
import AvatarChange from 'components/avatar-change/avatar-change';
import UserForm from 'components/user-form/user-form';
import {setUsers} from 'slice/authSlice';
import {useDispatch} from 'react-redux';

const EditUserPage = ({ userLogin, hasAnyAuthorities, navigate }) => {
    const { platform, setFormDataChanged } = useGlobalContext();
    const { addError, addSuccess } = useMessage();
    const { cancel } = useCancelBehavior();
    const { user, loading, setLoading } = useUserFormModel(userLogin);
    // const userRef = useRef(user);
    // console.log('start userRef.current', userRef.current);

    const [isSubmit, setIsSubmit] = React.useState(false);
    const dispatch = useDispatch();

    const onAvatarSubmit = (avatar) => {
        setFormDataChanged(true);
        user.avatar = avatar;
        user.avatarChanged = true;
        user.commit();
    };

    const onAvatarRemove = () => {
        setFormDataChanged(true);
        user.avatar = null;
        user.avatarUuid = null;
        user.avatarChanged = true;
        user.commit();
    };

    const editUser = async () => {
        setIsSubmit(true);

        if (!user.validateFields([], true)) {
            return;
        }

        setLoading(true);

        try {
            const formData = user.getFormData();
            await api.user.putUser(formData);

            addSuccess('Участник изменен');

            const users = await api.user.getUsers(0, 999999);
            setFormDataChanged(false);
            dispatch(setUsers(users.data));

            navigate(`/edit-user/${user.login}`);
        } catch (error) {
            user.errors.email = "Данная почта уже используется";
            addError('Не удалось изменить участника');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isSubmit && Object.values(user.errors).some(v => v)) {
            user.scrollToError();
            setIsSubmit(false);
        }

    }, [isSubmit, user, user.errors]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <ContentWrapper>
                <div className={cs.pageTitle}>{'Изменение участника'}</div>

                <div className={cs.avatarContainer}>
                    <AvatarChange value={user.avatarChanged && user.avatar ? user.avatar
                        : (user.avatarUuid ? api.upload.getImage(user.avatarUuid, false, 512) : '/img/avatar.png')}
                                  onSubmit={onAvatarSubmit} onRemove={onAvatarRemove}/>
                </div>

                <UserForm user={user} loading={loading} isEditMode={true} allowEmptyPassword={true} disableLoginEdit={true} edit={true}>
                    <div className={classNames(cs.controlsContainer, {[cs.mobile]: platform === 'mobile'})}>
                        <Button label={'Отмена'} onClick={cancel} fullWidth={platform === 'mobile'}/>
                        <Button onClick={editUser} label={'Сохранить'} disabled={loading} color={'green'}
                                fullWidth={platform === 'mobile'}/>
                    </div>
                </UserForm>
            </ContentWrapper>
        </PrivatePage>
    );
};

export default hot(EditUserPage);
