import ApiClient from './api-axios';

export default class LoggingApi extends ApiClient {
    getLogs = async ({ size = 20, page = 0, sort = 'timestamp,desc', filters = {}, objectUuid = '', cancelToken }) => {
        const defaultFromDate = new Date();
        defaultFromDate.setMonth(defaultFromDate.getMonth() - 1);

        const params = new URLSearchParams({
            page, size, sort, fromDate: defaultFromDate.toISOString(),
            toDate: new Date().toISOString()
        });

        if (objectUuid) {
            params.append('objectUuid', objectUuid);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.delete('fromDate');
                params.append('fromDate', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.delete('toDate');
                params.append('toDate', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['eventTypes']) {
            params.append('eventTypes', filters['eventTypes'].map((filter) => filter.value));
        }

        if (filters['users']) {
            params.append('users', filters['users'].map((filter) => filter.value));
        }

        if (filters['actionType']) {
            params.append('actionType', filters['actionType'].value);
        } else {
            params.append('actionType', 'ALL');
        }

        return await this.get('logs?' + params.toString(), {}, false, '', {}, cancelToken);
    };

    getResourceLogs = async (uuid) => {
        return await this.get(`logs/resources/${uuid}/last`);
    };
}
