import { NODE_TYPES } from 'components/trees';

function getTopParentNode(node) {
    let parentNode = node;

    while (true) {
        if (!parentNode.parent || !parentNode.parent.id) {
            break;
        }

        parentNode = parentNode.parent;
    }

    return parentNode;
}

function getRootSectionNode(node) {
    let parentNode = node;

    while (true) {
        if (parentNode.isRoot || parentNode.parent.nodeType === 'PROJECT') {
            break;
        }

        parentNode = parentNode.parent;
    }

    return parentNode;
}

function getRoot(node) {
    let parentNode = node;

    while (true) {
        if (parentNode.nodeType === 'PROJECT') {
            break;
        }

        parentNode = parentNode.parent;
    }

    return parentNode;
}

function getVisualPathTo(node) {
    let _path = [];

    if (node.nodeType === NODE_TYPES.PROJECT) {
        _path = [node.name, 'Корень проекта'];
    } else {
        function traverseNode(_node) {
            _path.unshift(_node.name);

            if (_node.nodeType !== NODE_TYPES.PROJECT) {
                traverseNode(_node.parent);
            }
        }

        traverseNode(node);
    }

    return _path;
}

function findSectionInNode(node, nodeId) {
    let result = [];

    if (node.children && node.children.length) {
        for (const _node of node.children) {
            if (_node.id === nodeId) {
                result = _node;
                break;
            } else {
                result = findSectionInNode(_node, nodeId);
            }
        }
    }

    return result;
}

function getPathIdsTo(node) {
    let _path = [];

    function traverseNode(_node) {
        if (_node.id) {
            _path.unshift(_node.id);
        }

        if (_node.parent) {
            traverseNode(_node.parent);
        }
    }

    traverseNode(node);
    return _path;
}

const TreeUtils = { getTopParentNode, getVisualPathTo, findSectionInNode, getRootSectionNode, getRoot, getPathIdsTo };
export default TreeUtils;