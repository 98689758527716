import React from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'uikit/dropdown-menu';
import { useGlobalContext } from 'lib/hooks';
import MobileDropdownMenu from 'uikit/dropdown-menu/mobile-dropdown-menu';

/*
const items = [{
    icon: Icons.LETTER,
    title: 'Отправить по электронной почте',
    id: '',
    className: '',
    onClick: () => {}
}, {
    icon: Icons.EDIT_PEN,
    title: 'Редактировать',
    id: '',
    className: '',
    link: `/archive/article/${article.id}/edit`
}, {
    icon: Icons.DOWNLOAD,
    title: 'Скачать документ',
    id: '',
    className: '',
    submenu: [
        {
            title: 'Microsoft Word (DOCX)',
            onClick: () => {}
        },
        {
            title: 'Документ PDF',
            onClick: () => {}
        },
        {
            title: 'Текст в формате TXT',
            onClick: () => {}
        },
        {
            title: 'Текст в формате RTF',
            onClick: () => {}
        }
    ],
}];
 */

const ButtonMenu = ({ tooltip, position, items, alwaysFilled, toggler, submenuPosition, id, ...rest }) => {
    const { platform } = useGlobalContext();
    return (
        <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }} id={id}>
            {platform !== 'mobile' &&
            <DropdownMenu buttonId={`${id}Button`} tooltip={tooltip} position={position} alwaysFilled={alwaysFilled} toggler={toggler} {...rest}>
                {items.map((item, i) => {
                    if (item.isShow === false) {
                        return null;
                    }
                    if (item.submenu) {
                        return (
                            <DropdownMenu.Item key={i} id={item.id} className={item.className} iconType={item.icon}
                                               iconWidth={item.width} iconHeight={item.height} text={item.title} submenuPosition={submenuPosition}>
                                {item.submenu.map((submenuItem, j) => (
                                    <DropdownMenu.Item key={j} text={submenuItem.title} onClick={submenuItem.onClick} link={submenuItem.link} />
                                ))}
                            </DropdownMenu.Item>
                        );
                    } else {
                        return (
                            <DropdownMenu.Item key={i} id={item.id} className={item.className} iconType={item.icon}
                                               text={item.title} onClick={item.onClick} link={item.link}
                                               iconWidth={item.width} iconHeight={item.height} />
                        );
                    }
                })}
            </DropdownMenu>}
            {platform === 'mobile' &&
            <MobileDropdownMenu items={items} alwaysFilled={alwaysFilled} toggler={toggler} {...rest}/>}
        </div>
    );
};

ButtonMenu.defaultProps = {
    tooltip: '',
    position: 'right',
    items: [],
    alwaysFilled: false,
    className: '',
    submenuPosition: 'right',
    id: '',
};

ButtonMenu.propTypes = {
    tooltip: PropTypes.string,
    position: PropTypes.oneOf(['left', 'right']),
    items: PropTypes.array,
    alwaysFilled: PropTypes.bool,
    className: PropTypes.string,
    toggler: PropTypes.oneOfType([PropTypes.func, PropTypes.oneOf([null])]),
    submenuPosition: PropTypes.oneOf(['left', 'right']),
    id: PropTypes.string
};

export default ButtonMenu;
