import ApiClient from './api-axios';

export default class ArchiveApi extends ApiClient {
    getProjects = async () => {
        return await this.get('archive');
    };

    getSectionArticles = async (uuid, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append(
                'authorLogin.in',
                filters['authorLogin.in'].map((filter) => filter.value)
            );
        }

        if (filters['archiveAddTime.greaterOrEqualThan']) {
            params.append('archiveAddTime.greaterOrEqualThan', new Date(filters['archiveAddTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['archiveAddTime.lessOrEqualThan']) {
            params.append('archiveAddTime.lessOrEqualThan', new Date(filters['archiveAddTime.lessOrEqualThan']).toISOString());
        }

        if (filters['archiveUserLogin.in']) {
            params.append(
                'archiveUserLogin.in',
                filters['archiveUserLogin.in'].map((filter) => filter.value)
            );
        }

        return await this.get(`archive/section/${uuid}/articles?` + params);
    };

    getArticles = async (page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append(
                'authorLogin.in',
                filters['authorLogin.in'].map((filter) => filter.value)
            );
        }

        if (filters['archiveAddTime.greaterOrEqualThan']) {
            params.append('archiveAddTime.greaterOrEqualThan', new Date(filters['archiveAddTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['archiveAddTime.lessOrEqualThan']) {
            params.append('archiveAddTime.lessOrEqualThan', new Date(filters['archiveAddTime.lessOrEqualThan']).toISOString());
        }

        if (filters['archiveUserLogin.in']) {
            params.append(
                'archiveUserLogin.in',
                filters['archiveUserLogin.in'].map((filter) => filter.value)
            );
        }

        return await this.get('archive/articles?' + params);
    };

    moveProjectToArchive = async (project) => {
        return await this.post('archive/move-project-to-archive/' + project);
    };

    moveProjectFromArchive = async (project) => {
        return await this.post('archive/move-project-from-archive/' + project);
    };

    getRootSections = async (project) => {
        return await this.get('archive/' + project + '/root-sections');
    };

    getSectionsContents = async (project, section) => {
        return await this.get('archive/' + project + '/' + section + '/content');
    };

    moveSectionToArchive = async (section) => {
        return await this.post('archive/move-section-to-archive/' + section);
    };

    moveSectionFromArchive = async (section) => {
        return await this.post('archive/move-section-from-archive/' + section);
    };

    getProjectArticles = async (project, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append(
                'authorLogin.in',
                filters['authorLogin.in'].map((filter) => filter.value)
            );
        }

        if (filters['archiveAddTime.greaterOrEqualThan']) {
            params.append('archiveAddTime.greaterOrEqualThan', new Date(filters['archiveAddTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['archiveAddTime.lessOrEqualThan']) {
            params.append('archiveAddTime.lessOrEqualThan', new Date(filters['archiveAddTime.lessOrEqualThan']).toISOString());
        }

        if (filters['archiveUserLogin.in']) {
            params.append(
                'archiveUserLogin.in',
                filters['archiveUserLogin.in'].map((filter) => filter.value)
            );
        }

        return await this.get(`archive/${project}/articles?` + params);
    };

    moveFromArchive = async (uuids) => {
        return this.post('archive/move-from-archive', JSON.stringify({ list: uuids }));
    };

    moveToArchive = async (uuids) => {
        return this.post('archive/move-to-archive', JSON.stringify({ list: uuids }));
    };

    moveArticleToArchive = async (article) => {
        return await this.post('archive/move-article-to-archive/' + article);
    };

    moveArticleFromArchive = async (article) => {
        return await this.post('archive/move-article-from-archive/' + article);
    };

    moveNewsToArchive = async (news) => {
        return await this.post('archive/news/move-to-archive/' + news);
    };

    moveNewsFromArchive = async (news) => {
        return await this.post('archive/news/move-from-archive/' + news);
    };

    moveScriptToArchive = async (script) => {
        return await this.post('archive/move-script-to-archive/' + script);
    };

    moveScriptFromArchive = async (script) => {
        return await this.post('archive/move-script-from-archive/' + script);
    };

    getNews = async (page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['expireTime.greaterOrEqualThan']) {
            params.append('expireTime.greaterOrEqualThan', new Date(filters['expireTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['expireTime.lessOrEqualThan']) {
            params.append('expireTime.lessOrEqualThan', new Date(filters['expireTime.lessOrEqualThan']).toISOString());
        }

        if (filters['priority.in'] && filters['priority.in']['value']) {
            params.append('priority.in', filters['priority.in']['value']);
        }

        if (filters['global.in'] && filters['global.in']['value']) {
            params.append('global.in', filters['global.in']['value']);
        }

        if (filters['archiveAddTime.greaterOrEqualThan']) {
            params.append('archiveAddTime.greaterOrEqualThan', new Date(filters['archiveAddTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['archiveAddTime.lessOrEqualThan']) {
            params.append('archiveAddTime.lessOrEqualThan', new Date(filters['archiveAddTime.lessOrEqualThan']).toISOString());
        }

        if (filters['archiveUserLogin.in']) {
            params.append(
                'archiveUserLogin.in',
                filters['archiveUserLogin.in'].map((filter) => filter.value)
            );
        }

        if (filters['authorLogin.in']) {
            params.append(
                'authorLogin.in',
                filters['authorLogin.in'].map((filter) => filter.value)
            );
        }

        return await this.get('archive/news?' + params);
    };

    getProjectNews = async (projectId, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['expireTime.greaterOrEqualThan']) {
            params.append('expireTime.greaterOrEqualThan', new Date(filters['expireTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['expireTime.lessOrEqualThan']) {
            params.append('expireTime.lessOrEqualThan', new Date(filters['expireTime.lessOrEqualThan']).toISOString());
        }

        if (filters['priority.in'] && filters['priority.in']['value']) {
            params.append('priority.in', filters['priority.in']['value']);
        }

        if (filters['archiveAddTime.greaterOrEqualThan']) {
            params.append('archiveAddTime.greaterOrEqualThan', new Date(filters['archiveAddTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['archiveAddTime.lessOrEqualThan']) {
            params.append('archiveAddTime.lessOrEqualThan', new Date(filters['archiveAddTime.lessOrEqualThan']).toISOString());
        }

        if (filters['archiveUserLogin.in']) {
            params.append(
                'archiveUserLogin.in',
                filters['archiveUserLogin.in'].map((filter) => filter.value)
            );
        }

        if (filters['authorLogin.in']) {
            params.append(
                'authorLogin.in',
                filters['authorLogin.in'].map((filter) => filter.value)
            );
        }

        return await this.get(`archive/news/project/${projectId}?${params}`);
    };

    getUsersDocumentTree = async () => {
        return await this.get('archive/user/tree');
    };

    getScripts = async (page = 0, size = 20, sort = '', _search = '') => {
        const params = new URLSearchParams({ page, size, sort });

        if (_search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', _search);
            params.append('authorLogin.contains', _search);
            params.append('authorLastName.contains', _search);
            params.append('authorFirstName.contains', _search);
            params.append('authorMiddleName.contains', _search);
        }

        return await this.get('/archive/scripts?' + params)
    }

    getSectionScripts = async (uuid, page = 0, size = 20, sort = '', _search = '') => {
      const params = new URLSearchParams({ page, size, sort });

        if (_search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', _search);
            params.append('authorLogin.contains', _search);
            params.append('authorLastName.contains', _search);
            params.append('authorFirstName.contains', _search);
            params.append('authorMiddleName.contains', _search);
        }

        return await this.get(`/archive/section/${uuid}/scripts?` + params)
    }

    getProjectScripts = async (uuid, page = 0, size = 20, sort = '', _search = '') => {
        const params = new URLSearchParams({ page, size, sort });

        if (_search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', _search);
            params.append('authorLogin.contains', _search);
            params.append('authorLastName.contains', _search);
            params.append('authorFirstName.contains', _search);
            params.append('authorMiddleName.contains', _search);
        }

        return await this.get(`/archive/project/${uuid}/scripts?` + params)
    }
}
