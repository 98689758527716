import React, { useEffect } from 'react';
import Modal from 'react-modal';

import Input from 'uikit/input';
import { useEditorContext } from '../../context';
import { desktopStyles } from 'lib/util/modalStyles';
import { Icons } from 'uikit/icon';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import { MenuButton } from './MenuButton';

export const MenuButtonDiagram = ({ mode, className, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const [show, setShow] = React.useState(false);

    const toggleDialog = () => {
        setCurrentMenu(undefined);
        setShow(prev => !prev);
    };

    const handleSubmit = (url, width, height) => {
        editor?.chain().focus().setIframe({ src: url, width, height }).run();
        toggleDialog();
    };

    return (
        <div>
            <MenuButton
                icon={Icons.EditorIconDiagram}
                tooltip={isTooltip === false ? null : 'Добавить диаграмму Draw.io'}
                label="Добавить диаграмму Draw.io"
                onClick={toggleDialog}
                className={className}
            />
            {show && <DiagramModal mode={mode} show={show} onClose={toggleDialog} onSubmit={handleSubmit} />}
        </div>
    );
};

export const DiagramModal = ({ mode, data, show, onClose, onSubmit }) => {
    const [url, setUrl] = React.useState('');
    const [width, setWidth] = React.useState(mode === 'MOBILE' ? '300' : '500');
    const [height, setHeight] = React.useState(mode === 'MOBILE' ? '300' : '500');

    const handleSubmit = () => {
        onSubmit(url, width, height);
    };

    useEffect(() => {
        if (data) {
            setUrl(data.url || '');
            setWidth(data.width || (mode === 'MOBILE' ? '300' : '500'));
            setHeight(data.height || (mode === 'MOBILE' ? '300' : '500'));
        }
    }, [data, mode]);

    return (
        <Modal
            isOpen={show}
            preventScroll
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 570, width: 'calc(100% - 20px)', border: 'none' } }}
        >
            <ModalHeader title="Встроить диаграмму из Draw.io" onClose={onClose} />
            <ModalBody>
                <div style={{ paddingBottom: 20, fontSize: 13, lineHeight: '150%' }}>
                    Для того, чтобы добавить диаграмму в статью нужно опубликовать ее в{' '}
                    <a style={{ textDecoration: 'underline' }} href="https://app.diagrams.net/">
                        draw.io
                    </a>
                    <div>Для этого перейдите в раздел Файл ➔ Опубликовать ➔ Ссылка ➔ Создать ➔ Копировать</div>
                </div>
                <Input
                    wrapperStyle={{ paddingBottom: 20 }}
                    type="text"
                    value={url}
                    onChange={setUrl}
                    label="Ссылка на диаграмму"
                    placeholder="Вставьте ссылку"
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Input
                        wrapperStyle={{ marginRight: 11 }}
                        type="number"
                        value={height}
                        onChange={setHeight}
                        label="Высота (до 800 px)"
                    />
                    <Input wrapperStyle={{ marginLeft: 11 }} type="number" value={width} onChange={setWidth} label="Ширина фрейма" />
                </div>
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: onClose },
                    { label: 'Сохранить', disabled: !width || !height || !url, color: 'blue', onClick: handleSubmit },
                ]}
            />
        </Modal>
    );
};
