let lastId = 0;

export const idFromString = (str) => {
    lastId++;
    let id = 'id_';

    if (!str || !str.length) {
        return id + lastId;
    }

    for (let i = 0; i < str.length; i++) {
        id += str.charCodeAt(i);
    }

    id += '_' + lastId;
    return id;
};

export const formatDate = (value) => {
    const d = value.split('.');
    const getDate = d[0];

    const getMonth = d[1] - 1;
    const getYear = d[2];

    if (value.length === 10) {
        return new Date(getYear, getMonth, getDate);
    } else {
        return undefined;
    }
};