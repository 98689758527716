import { mergeAttributes, Node } from '@tiptap/core';

export const TableBody = Node.create({
    name: 'tableBody',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    content: 'tableRow+',

    tableRole: 'table_body',

    isolating: true,

    parseHTML() {
        return [{ tag: 'tbody' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['tbody', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});
