import React from 'react';
import PropTypes from 'prop-types';
import { useCombinedRefs } from 'lib/hooks';
import { idFromString } from 'lib/helpers';
import cx from './switch.module.scss';
import classNames from 'classnames';

const Switch = ({ id, label, checked, onClick, forwardedRef, className, ...attrs }) => {
    const key = id || idFromString(attrs.placeholder);
    const innerRef = useCombinedRefs(forwardedRef);

    return (
        <div className={classNames(cx.switch, className)}>
            <input id={key} ref={innerRef} type="checkbox" className={cx.input} checked={checked}
                   onChange={e => onClick(e)}/>
            <label className={cx.label} htmlFor={key}>
                <span className={cx.toggler}/>
            </label>
            {label && <span className={cx.description}>{label}</span>}
        </div>
    );
};

Switch.defaultProps = {
    id: null,
    className: '',
    onChange: () => {

    }
};

Switch.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func
};

export default React.forwardRef((props, ref) => <Switch {...props} forwardedRef={ref}/>);
