import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Icon, { Icons } from 'uikit/icon';
import cx from 'components/projects/empty.module.scss';
import { selectLogoutUrl } from 'slice/authSlice';

const MultiplyErrorPage = () => {
    const logoutUrl = useSelector(selectLogoutUrl);

    useEffect(() => {
        setTimeout(async () => {
            if (logoutUrl) {
                document.location.replace(process.env.NODE_ENV === 'development' ? '/login' : logoutUrl);
            }
        }, 5000);
    }, [logoutUrl]);

    return (
        <div className={cx.empty}>
            <Icon type={Icons.LOCK} width={56} height={50}/>
            <h3>Кто-то вошел в систему под вашим логином</h3>
            <p>Вы были разлогинены. Смените пароль от своего аккаунта во избежание повторения данного события.</p>
        </div>
    );
};

export default MultiplyErrorPage;
