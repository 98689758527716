import ApiClient from './api-axios';

export default class FileCommentsApi extends ApiClient {
    postFileComment = async ({ attachmentId, parentCommentId, text }) => {
        return this.post('', { attachmentId, parentId: parentCommentId, text });
    };

    getFileComments = async uuid => {
        return await this.get(`/file/${uuid}`);
    };

    editFileComment = async (id, text) => {
        return await this.post('/edit', { id, text });
    };

    deleteFileComment = async uuid => {
        return await this.deleteRequest(`/${uuid}`);
    };

    getFileCommentById = async uuid => {
        return await this.get(`/${uuid}`);
    };
}