import React from 'react';
import classNames from 'classnames';
import { BubbleMenu } from '@tiptap/react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';
import Icon, { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import DropdownList from 'uikit/dropdown-list/dropdown-list';
import { EmojiPicker } from 'uikit/emoji-picker/emoji-picker';
import { MenuButtonColorPicker } from '../Menu/MenuButtonColorPicker';
import cx from '../../editor.module.scss';
import { findParentNodeOfType } from 'prosemirror-utils';

export const BubbleMenuCallout = ({ editor, mode, currentMenu, setCurrentMenu }) => {
    const [value, setValue] = React.useState('rgba(0, 0, 0, 1)');

    const emojiPickerRef = React.useRef(null);
    const colorPickerRef = React.useRef(null);

    const updateSelectedNodeAttributes = attributes => {
        const { state, view } = editor;
        const { selection } = state;

        const { $from, $to } = selection;
        const nodes = [];

        state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
            if (node.type.name === 'callout') {
                nodes.push({ node, pos });
            }
        });

        const current = nodes[nodes.length - 1];

        const transaction = state.tr.setNodeMarkup(current.pos, undefined, {
            ...current.node.attrs,
            ...attributes,
        });

        view.dispatch(transaction);
    };
    
    const onEmojiClick = emoji => {
        updateSelectedNodeAttributes({
            'data-emoji': emoji.e,
        });
    };

    const onBackgroundChange = color => {
        setValue(color);
        updateSelectedNodeAttributes({
            'data-background': color,
        });
    };

    const onTypeChange = type => {
        updateSelectedNodeAttributes({
            type,
            'data-background': null,
            'data-emoji': null,
        });
    };

    return (
        <>
            <div style={{ display: currentMenu !== 'callout-emoji' && currentMenu !== 'callout-color' ? 'none' : 'block' }}>
                <DropdownList
                    innerRef={emojiPickerRef}
                    mode={mode}
                    name="callout-emoji"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                >
                    <EmojiPicker onClick={onEmojiClick} />
                </DropdownList>
                <DropdownList
                    innerRef={colorPickerRef}
                    mode={mode}
                    name="callout-color"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                >
                    <MenuButtonColorPicker onChange={onBackgroundChange} value={value} />
                </DropdownList>
            </div>
            <BubbleMenu
                tippyOptions={{
                    placement: 'bottom',
                    arrow: false,
                    getReferenceClientRect: () => {
                        const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                            return node.type.name === 'callout';
                        });

                        if (nearestParent) {
                            const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                            if (wrapperDomNode) {
                                return wrapperDomNode.getBoundingClientRect();
                            }
                        }
                        const { ranges } = editor?.state.selection;
                        const from = Math.min(...ranges.map(range => range.$from.pos));
                        const to = Math.max(...ranges.map(range => range.$to.pos));
                        return posToDOMRect(editor?.view, from, to);
                    },
                }}
                editor={editor}
                shouldShow={({ editor }) => {
                    const isActive = editor.isActive('callout');

                    if (isActive) {
                        document.querySelector('.ProseMirror').className += ' callout-active';
                    } else {
                        document.querySelector('.ProseMirror').className = document
                            .querySelector('.ProseMirror')
                            .className.replace(' callout-active', '');
                    }

                    return isActive;
                }}
            >
                <div className={cx.bubbleMenu}>
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutYellow}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('yellow');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutGreen}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('green');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutBlue}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('blue');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutPirple}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('pirple');
                        }}
                    />
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconCalloutRed}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            onTypeChange('red');
                        }}
                    />
                    {mode === 'DESKTOP' && (
                        <DropdownList
                            position="none"
                            menuStyle={{ right: 0, top: 30 }}
                            toggler={showMenu => (
                                <div className={classNames('editor-button', cx.editorMenuButton, cx.bubbleMenuButton)} onClick={showMenu}>
                                    <Icon type={Icons.EditorIconEmoji} width={18} height={18} />
                                </div>
                            )}
                        >
                            <EmojiPicker onClick={onEmojiClick} />
                        </DropdownList>
                    )}
                    {mode !== 'DESKTOP' && (
                        <MenuButton
                            className={cx.bubbleMenuButton}
                            icon={Icons.EditorIconEmoji}
                            onClick={e => emojiPickerRef.current.showmenu(e)}
                        />
                    )}
                    <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                    {mode === 'DESKTOP' && (
                        <DropdownList
                            position="none"
                            menuStyle={{ right: 0, top: 50 }}
                            toggler={showMenu => (
                                <div className={classNames('editor-button', cx.editorMenuButton, cx.bubbleMenuButton)} onClick={showMenu}>
                                    <Icon type={Icons.EditorIconFill} width={18} height={18} />
                                </div>
                            )}
                        >
                            <MenuButtonColorPicker onChange={onBackgroundChange} value={value} />
                        </DropdownList>
                    )}
                    {mode !== 'DESKTOP' && (
                        <MenuButton
                            className={cx.bubbleMenuButton}
                            icon={Icons.EditorIconFill}
                            onClick={e => colorPickerRef.current.showmenu(e)}
                        />
                    )}
                    <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                    <MenuButton
                        className={cx.bubbleMenuButton}
                        icon={Icons.EditorIconTrash}
                        onClick={() => {
                            setCurrentMenu(undefined);
                            const callout = findParentNodeOfType(editor.state.schema.nodes.callout)(editor.state.selection);
                            const tr = editor.state.tr;

                            tr.replaceWith(callout.pos, callout.start + callout.node.nodeSize, callout.node.content.content)                            
                            editor.view.dispatch(tr);
                        }}
                    />
                </div>
            </BubbleMenu>
        </>
    );
};
