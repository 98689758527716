import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import Modal from 'react-modal';
import classNames from 'classnames';
import axios from 'axios';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button';
import { FileViewDialog } from 'components/file/file-view-dialog';
import cx from './statistic-reports-page.module.scss';
import { useGlobalContext } from 'lib/hooks';
import StatisticReportsArticlesInProjects from './statistic-reports-articles-in-projects';
import StatisticReportsArticlesInSections from './statistic-reports-articles-in-sections';
import StatisticReportsNewsInProject from './statistic-reports-news-in-project';
import StatisticReportsGeneralNews from './statistic-reports-general-news';
import StatisticReportsFilesInProjects from './statistic-reports-files-in-projects';
import StatisticReportsFilesInSections from './statistic-reports-files-in-sections';
import StatisticReportsFilesInDocuments from './statistic-reports-files-in-documents';
import StatisticReportsActionsInProjects from './statistic-reports-actions-in-projects';
import StatisticReportsActionsInSections from './statistic-reports-actions-in-sections';
import StatisticReportsActionsInArticles from './statistic-reports-actions-in-articles';
import StatisticReportsActionsInNews from './statistic-reports-actions-in-news';
import StatisticReportsSearchRequests from './statistic-reports-search-requests';
import StatisticReportsDetailedSearchRequests from './statistic-reports-detailed-search-requests';
import StatisticReportsUsersInProjects from './statistic-reports-users-in-projects';
import StatisticReportsUsersInSections from './statistic-reports-users-in-sections';
import StatisticReportsUsersInDocuments from './statistic-reports-users-in-documents';
import StatisticReportsReads from './statistic-reports-reads';
import StatisticReportsDocumentsEvaluation from './statistic-reports-documents-evaluation';
import StatisticReportsContentReports from './statistic-reports-content-reports';
import StatisticReportsFilesReports from './statistic-reports-files-reports';
import StatisticReportsActionsReports from './statistic-reports-actions-reports';
import StatisticReportsPopularityByRating from './statistic-reports-popularity-by-rating';
import StatisticReportsUsersReports from './statistic-reports-users-reports';

const StatisticReportsPage = ({ title = '', sectionId }) => {
    const { platform } = useGlobalContext();

    const [isDownloadModal, setIsDownloadModal] = useState(false);
    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const [viewFile, setViewFile] = useState(null);

    const [cancelToken, setCancelToken] = useState(null);

    const onViewFile = (file) => {
        setViewFile(file);
        setIsViewFileModal(true);
    };

    const cancelDownload = () => {
        cancelToken.cancel();
        setCancelToken(null);
        setIsDownloadModal(false);
    };

    useEffect(() => {
        if (!cancelToken) {
            setCancelToken(axios.CancelToken.source());
        }
        return () => {
            if (cancelToken) {
                cancelToken.cancel();
                setCancelToken(null);
            }
        };
    }, [sectionId, cancelToken]);

    return (
        <div className={cx.pageWrapper}>
            {isViewFileModal && <FileViewDialog file={viewFile} handleClose={() => setIsViewFileModal(false)} />}

            <div className={cx.header}>
                <h1>{title}</h1>
            </div>
            <Router className={cx.routerWrapper} primary={false}>
                <StatisticReportsArticlesInProjects path="articles-in-projects" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsArticlesInSections path="articles-in-sections" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsNewsInProject path="news-in-projects" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsGeneralNews path="general-news" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsContentReports path="content-report" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsFilesInProjects path="files-in-projects" onViewFile={onViewFile} setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsFilesInSections path="files-in-sections" onViewFile={onViewFile} setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsFilesInDocuments
                    path="files-in-documents"
                    onViewFile={onViewFile}
                    setIsDownloadModal={setIsDownloadModal}
                />
                <StatisticReportsFilesReports path="files-reports" onViewFile={onViewFile} setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsPopularityByRating path="documents-popularity-by-rating" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsDocumentsEvaluation path="documents-evaluation" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsUsersInProjects path="user-in-projects" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsUsersInSections path="user-in-sections" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsUsersInDocuments path="user-in-documents" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsUsersReports path="users-report" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsActionsInProjects path="actions-in-projects" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsActionsInSections path="actions-in-sections" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsActionsInArticles path="actions-in-articles" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsActionsInNews path="actions-in-news" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsActionsReports path="actions-report" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsReads path="reads" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsSearchRequests path="search-requests" setIsDownloadModal={setIsDownloadModal} />
                <StatisticReportsDetailedSearchRequests path="detailed-search-request" setIsDownloadModal={setIsDownloadModal} />
            </Router>

            <Modal isOpen={isDownloadModal} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
                <div className={classNames(cx.modal, { [cx.mobile]: platform === 'mobile' })}>
                    <div className={cx.head}>
                        <div className={cx.text}>Скачивание отчета</div>
                        <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16} />} onClick={cancelDownload} />
                    </div>
                    <div className={cx.content}>Формирование отчета началось. После завершения, ссылка придет вам в уведомления</div>
                    <div className={cx.footer}>
                        <Button onClick={cancelDownload}>Закрыть</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default StatisticReportsPage;
