import React, { useState, useEffect, useCallback, useRef } from 'react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';
import { NodeSelection } from '@tiptap/pm/state';

import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import Icon from 'uikit/icon/icon';
import { findParentNode } from '../../utils/findParentNode';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { MultiListModal } from '../Menu/MenuButtonMultiList';
import { markers } from '../Menu/MenuButtonList';

import cx from '../../editor.module.scss';

const propsEqualCompare = (prevProps, nextProps) => {
    if (!prevProps.isActive && !nextProps.isActive) {
        return true;
    }

    if (prevProps.isActive !== nextProps.isActive) {
        return false;
    }

    if (prevProps.selection.from !== nextProps.selection.from) {
        return false;
    }

    if (prevProps.setCurrentMenu !== nextProps.setCurrentMenu) {
        return false;
    }

    if (prevProps.mode !== nextProps.mode) {
        return false;
    }

    return true;
};

export const BubbleMenuListItem = React.memo(({ isActive, selection, mode, currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();

    const bubbleMenuRef = useRef(null);
    const [menuPosition, setMenuPosition] = useState({});

    const [canUp, setCanUp] = useState(null);
    const [canDown, setCanDown] = useState(null);

    const [isEdit, setIsEdit] = useState(false);
    const [listData, setListData] = useState(null);

    const toggleEdit = () => {
        if (isEdit) {
            setIsEdit(false);
        } else {
            const nearestParent = findParentNodeClosestToPos(
                editor?.state.selection.$anchor,
                node => {
                    return node.type.name === 'multi-list';
                },
                1
            );

            if (nearestParent) {
                setListData(nearestParent.node.toJSON());
                setIsEdit(true);
            }
        }
    };

    const handleSubmit = (levels, listTypes) => {
        const list = findParentNodeClosestToPos(
            editor?.state.selection.$anchor,
            node => {
                return node.type.name === 'multi-list';
            },
            1
        );
        const attrs = {};
        listTypes.forEach((type, idx) => {
            attrs['data-level-' + (idx + 1)] = type;
        });

        let { tr } = editor.view.state;
        const resolvedPos = editor.view.state.doc.resolve(list.pos);
        const sel = new NodeSelection(resolvedPos);

        tr = tr.setSelection(sel);
        tr = tr.deleteSelection();
        tr = tr.insert(resolvedPos.pos, editor.view.state.doc.type.schema.nodeFromJSON(levels));
        tr = tr.setNodeMarkup(resolvedPos.pos, null, attrs);

        editor.view.dispatch(tr);
        setIsEdit(false);
    };

    const lift = () => {
        bubbleMenuRef.current?.hidemenu(false);

        editor
            .chain()
            .liftListItem('multiListItem')
            .command(({ tr }) => {
                const list = findParentNode(editor?.state, ['multiListItem']);
                const multilistWrapper = findParentNode(editor?.state, 'multi-list');

                list.node.descendants((node, pos) => {
                    if (['bulletList', 'orderedList'].includes(node.type.name)) {
                        tr.setNodeMarkup(list.start + pos, null, {
                            ...node.attrs,
                            'data-level': node.attrs['data-level'] - 1,
                        });
                    }
                });

                multilistWrapper.node.descendants((node, pos, parent) => {
                    if (['bulletList', 'orderedList'].includes(node.type.name) && parent.type.name === 'multi-list') {
                        tr.setNodeMarkup(multilistWrapper.start + pos, null, {
                            ...node.attrs,
                            'data-list-type': multilistWrapper.node.attrs[`data-level-1`],
                            'data-level': 1,
                        });
                    }
                });

                return true;
            })
            .run();

        setMenuPosition(getItemPosition());
    };
    const sink = () => {
        const multilistWrapper = findParentNode(editor?.state, 'multi-list');

        const list = findParentNode(editor?.state, ['bulletList', 'orderedList']);

        editor.commands.sinkListItem('multiListItem');

        const newList = findParentNode(editor?.state, ['bulletList', 'orderedList']);

        bubbleMenuRef.current?.hidemenu(false);
        editor?.view.dispatch(
            editor?.state.tr.setNodeMarkup(newList.pos, null, {
                'data-list-type': multilistWrapper.node.attrs[`data-level-${list.node.attrs['data-level'] + 1}`],
                'data-level': list.node.attrs['data-level'] + 1,
                'data-type': 'multi-list',
            })
        );

        setMenuPosition(getItemPosition());
    };

    const getItemPosition = useCallback(() => {
        if (!isActive || !editor) {
            return {};
        }

        const a = selection.$from.pos + (selection.$from.nodeAfter?.nodeSize || 0);

        const boundingClientRect = posToDOMRect(editor?.view, a, a);
        const editorBoundingClientRect = document.getElementById('editor-content').getBoundingClientRect();

        if (!boundingClientRect || !editorBoundingClientRect) {
            return {};
        }

        return {
            top: boundingClientRect.top - editorBoundingClientRect.top - boundingClientRect.height / 4 + 'px',
            left: boundingClientRect.left - editorBoundingClientRect.left + boundingClientRect.width + 'px',
        };
    }, [selection, editor, isActive]);

    useEffect(() => {
        if (isActive) {
            setMenuPosition(getItemPosition());
        } else {
            bubbleMenuRef.current?.hidemenu(false);
        }
    }, [isActive, getItemPosition]);

    useEffect(() => {
        if (!selection) {
            return;
        }

        const nearestList = findParentNodeClosestToPos(selection?.$anchor, node => {
            return node?.attrs?.['data-type'] === 'multi-list';
        });

        if (!nearestList) {
            return;
        }

        const multiListItem = findParentNodeClosestToPos(selection?.$anchor, node => {
            return node?.type.name === 'multiListItem';
        });
        let isCanDown = true;

        const getNestedLists = node => {
            node?.descendants(n => {
                if (n?.attrs?.['data-level'] === 3) {
                    isCanDown = false;
                }
                getNestedLists(n);
            });
        };

        getNestedLists(multiListItem.node);

        setCanUp(nearestList?.node.attrs['data-level'] > 1);
        setCanDown(() => {
            if (nearestList.node.firstChild === multiListItem.node) {
                return false;
            } else if (!isCanDown) {
                return false;
            } else if (nearestList.node?.attrs?.['data-level'] === 3) {
                return false;
            } else {
                return true;
            }
        });
    }, [selection, isActive]);

    return (
        <>
            <div
                id="editorBubbleMenuMultiListItem"
                className={cx.bubbleMenu}
                style={{
                    position: 'absolute',
                    display: 'flex',
                    opacity: isActive ? 1 : 0,
                    pointerEvents: isActive ? 'initial' : 'none',
                    alignItems: 'center',
                    cursor: 'pointer',
                    ...menuPosition,
                }}
            >
                <Menu
                    ref={bubbleMenuRef}
                    mode={mode}
                    name="list-item"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    label={<Icon type={Icons.PLUS_ADD} width={18} height={18} color="blue" />}
                    menuContentStyles={{ maxHeight: 'initial', overflow: 'initial' }}
                >
                    <MenuItem
                        disabled={!canUp}
                        onClick={lift}
                        label={
                            <div style={{ display: 'flex', gap: 6 }}>
                                <Icon type={Icons.EditorIconListUp} width={18} height={18} />
                                <span>Уровень выше</span>
                            </div>
                        }
                    />
                    <MenuItem
                        disabled={!canDown}
                        onClick={sink}
                        label={
                            <div style={{ display: 'flex', gap: 6 }}>
                                <Icon type={Icons.EditorIconListDown} width={18} height={18} />
                                <span>Уровень ниже</span>
                            </div>
                        }
                    />
                    <MenuItem
                        label={
                            <div style={{ display: 'flex', gap: 6 }}>
                                <Icon type={Icons.EditorIconSettings} onClick={toggleEdit} width={18} height={18} />
                                <span>Настройка списка</span>
                            </div>
                        }
                        onClick={toggleEdit}
                    />
                </Menu>
            </div>
            {isEdit && <MultiListModal data={listData} markers={markers} show={isEdit} onClose={toggleEdit} onSubmit={handleSubmit} />}
        </>
    );
}, propsEqualCompare);
