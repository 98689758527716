import React, { useCallback, useEffect, useState } from 'react';
import { Router } from '@reach/router';
import classNames from 'classnames';
import { useMessage } from 'lib/hooks';
import FavoritesPageTabProjects from './favorites-page-tab-projects';
import FavoritesPageTabNews from './favorites-page-tab-news';
import FavoritesPageTabArticles from './favorites-page-tab-articles';
import api from 'api';
import cx from './favorites-page.module.scss';

const FavoritesPage = ({ navigate, location, ...props }) => {
    const { addError } = useMessage();
    const propTab = props['*'];

    const [tab, setActiveTab] = useState('projects');
    const [favoritesCounts, setFavoritesCounts] = useState({ projectCount: 0, articleCount: 0, newsCount: 0 });

    const onTabChange = (tab) => {
        navigate('/user/favorites' + (tab ? '/' + tab : ''));
        setActiveTab(tab);
    };
    const getTabCounts = useCallback(async () => {
        try {
            const counts = await api.favorites.getFavoritesCounts();
            setFavoritesCounts(counts);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);

    useEffect(() => { getTabCounts() }, [getTabCounts]);
    useEffect(() => { setActiveTab(propTab) }, [location.pathname, propTab]);

    return (
        <div className={cx.content}>
            <div className={cx.title}>Избранное</div>
            <ul className={cx.tabs}>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'projects' })}
                    onClick={() => onTabChange('projects')}>
                    Проекты
                    <div className={cx.count}>{favoritesCounts.projectCount}</div>
                </li>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'articles' })}
                    onClick={() => onTabChange('articles')}>
                    Статьи
                    <div className={cx.count}>{favoritesCounts.articleCount}</div>
                </li>
                <li className={classNames(cx.tab, { [cx.active]: tab === 'news' })}
                    onClick={() => onTabChange('news')}>
                    Новости
                    <div className={cx.count}>{favoritesCounts.newsCount}</div>
                </li>
            </ul>
            <div className={cx.tableWrapper}>
                <Router className={cx.router}>
                    <FavoritesPageTabProjects path="projects" onCountChangeHandler={getTabCounts}/>
                    <FavoritesPageTabArticles path="articles" totalCount={favoritesCounts.articleCount}
                                              onCountChangeHandler={getTabCounts}/>
                    <FavoritesPageTabNews path="news" totalCount={favoritesCounts.newsCount}
                                          onCountChangeHandler={getTabCounts}/>
                </Router>
            </div>
        </div>
    );
};

export default FavoritesPage;
