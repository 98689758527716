import React, { useEffect } from 'react';
import Modal from 'react-modal';

import Input from 'uikit/input';
import { useEditorContext } from '../../context';
import { desktopStyles } from 'lib/util/modalStyles';
import { Icons } from 'uikit/icon';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import { MenuButton } from './MenuButton';

export const MenuButtonGoogleDoc = ({ mode, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const [show, setShow] = React.useState(false);

    const toggleDialog = () => {
        setCurrentMenu(undefined);
        setShow(prev => !prev);
    };

    const handleSubmit = (src, height) => {
        editor?.chain().focus().setGoogleDocIframe({ src, height }).run();
        toggleDialog();
    };

    return (
        <>
            <MenuButton
                icon={Icons.EditorIconGoogleDoc}
                tooltip={isTooltip === false ? null : 'Добавить файл из Google Docs'}
                label="Добавить файл из Google Docs"
                onClick={toggleDialog}
            ></MenuButton>
            {show && <GoogleDocModal mode={mode} show={show} onClose={toggleDialog} onSubmit={handleSubmit} />}
        </>
    );
};

export const GoogleDocModal = ({ mode, data, show, onClose, onSubmit }) => {
    const [url, setUrl] = React.useState('');
    const [height, setHeight] = React.useState(mode === 'MOBILE' ? '300' : '500');

    const handleSubmit = () => {
        let src = !url.includes('/pub') ? url.split('/edit')[0] + '/pub?embedded=true' : url;
        onSubmit(src, height);
    };

    useEffect(() => {
        if (data) {
            setUrl(data.url || '');
            setHeight(data.height || (mode === 'MOBILE' ? '300' : '500'));
        }
    }, [data, mode]);

    return (
        <Modal
            isOpen={show}
            preventScroll
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 570, width: 'calc(100% - 20px)', border: 'none' } }}
        >
            <ModalHeader title="Добавление документа Google Docs" onClose={onClose} />
            <ModalBody>
                <div style={{ paddingBottom: 20, fontSize: 13, lineHeight: '150%' }}>
                    Для того, чтобы добавить документ в статью нужно поделиться файлом в Google Docs.
                    <div>Для этого перейдите в раздел Файл ➔ Поделиться ➔ Опубликовать в интернете ➔ Скопировать ссылку</div>
                </div>
                <Input
                    wrapperStyle={{ paddingBottom: 20 }}
                    type="text"
                    value={url}
                    onChange={setUrl}
                    label="Ссылка на документ"
                    placeholder="Вставьте ссылку"
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Input
                        wrapperStyle={{ marginRight: 11 }}
                        type="number"
                        value={height}
                        onChange={setHeight}
                        label="Высота фрейма (до 800 px)"
                    />
                </div>
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: onClose },
                    { label: 'Сохранить', disabled: !height || !url, color: 'blue', onClick: handleSubmit },
                ]}
            />
        </Modal>
    );
};
