import { getMarkRange } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import Link from '@tiptap/extension-link';
import { formatLinkName } from './commands';

export const CustomLink = Link.extend({
    exitable: true,

    addCommands() {
        return {
            setLink:
                (name, attrs) =>
                ({ chain }) => {
                    return chain()
                        .command(({ state }) => {
                            return formatLinkName(name, state);
                        })
                        .setMark(this.name, attrs)
                        .setMeta('preventAutolink', true)
                        .run();
                },
            updateLink:
                (name, attrs) =>
                ({ chain }) => {
                    chain()
                        .updateAttributes('link', attrs)
                        .command(({ state }) => formatLinkName(name, state))
                        .run();
                },
            unsetLink:
                () =>
                ({ chain }) => {
                    return chain().unsetMark(this.name, { extendEmptyMarkRange: true }).setMeta('preventAutolink', true).run();
                },
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('linkKeyDown'),
                props: {
                    handleTextInput: (view, _from, _to) => {
                        const { $from } = view.state.selection;
                        const marks = $from.marks();
                        const removedMark = marks.find(m => m.type.name === 'link');

                        if (!removedMark) {
                            return false;
                        }
                        const { tr } = view.state;
                        const { from, to } = getMarkRange($from, removedMark.type);

                        const isAtStart = $from.pos === from;
                        const isAtEnd = $from.pos === to;

                        if (isAtStart || isAtEnd) {
                            tr.removeStoredMark(removedMark);
                        }
                        view.dispatch(tr);
                    },
                },
            }),
        ];
    },
});
