import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { Slice } from '@tiptap/pm/model';
import { useEditor, EditorContent, PureEditorContent } from '@tiptap/react';
import classNames from 'classnames';
import { extensions } from './extensions';
import { Menu } from './components/Menu/Menu';
import EditorProvider from './EditorProvider';
import { BubbleMenuCallout } from './components/BubbleMenu/BubbleMenuCallout';
import { BubbleMenuDiagram } from './components/BubbleMenu/BubbleMenuDiagram';
import { BubbleMenuGoogleDoc } from './components/BubbleMenu/BubbleMenuGoogleDoc';
import { BubbleMenuTOC } from './components/BubbleMenu/BubbleMenuTOC';
import { BubbleMenuTemplate } from './components/BubbleMenu/BubbleMenuTemplate';
import { BubbleMenuLink } from './components/BubbleMenu/BubbleMenuLink';
import useCodeView from './hooks/useCodeView';
import { BubbleMenuAnchor } from './components/BubbleMenu/BubbleMenuAnchor';
import { BubbleMenuCodeBlock } from './components/BubbleMenu/BubbleMenuCodeBlock';
import 'highlight.js/styles/github.css';
import { BubbleMenuListItem } from './components/BubbleMenu/BubbleMenuListItem';
import { BubbleMenuEmbedVideo } from './components/BubbleMenu/BubbleMenuEmbedVideo';
import { BubbleMenuImage } from './components/BubbleMenu/BubbleMenuImage';
import EditorNotificationProvider from './EditorNotificationProvider';
import { Notifications } from './components/Notifications/Notifications';
import { BubbleMenuTable } from './components/BubbleMenu/BubbleMenuTable';
import { BubbleMenuTableCell } from './components/BubbleMenu/BubbleMenuTableCell';
import { BubbleMenuDetails } from './components/BubbleMenu/BubbleMenuDetails';
import { BubbleMenuTableLeftMenu } from './components/BubbleMenu/BubbleMenuTableLeftMenu';
import { BubbleMenuTableBottomMenu } from './components/BubbleMenu/BubbleMenuTableBottomMenu';
import { BubbleMenuTableRightMenu } from './components/BubbleMenu/BubbleMenuTableRightMenu';
import './editor-styles.css';
import './styles/details.css';
import cx from './editor.module.scss';
import Confirmation from 'components/confirmation';
import decodeHtmlEntities from 'lib/helpers/decodeHtmlEntities';
import { parseHTML } from './extensions/CopyPaste/extension-copy-paste';
import { debounce } from 'lodash';

PureEditorContent.prototype.maybeFlushSync = function maybeFlushSync(fn) {
    if (this.initialized) {
        flushSync(fn);
        this.initialized = false;
    } else {
        fn();
    }
};

export const Editor = forwardRef(({ value, onInstanceReady, initId, onChange, error }, ref) => {
    const textAreaRef = useRef(null);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [mode, setMode] = React.useState('DESKTOP');
    const [currentMenu, setCurrentMenu] = useState(undefined);

    const [isPasteModal, setIsPasteModal] = useState(false);
    const [pasteData, setPasteData] = useState();

    const onEditorCreate = async ({ editor }) => {
        onInstanceReady?.(editor);

        if (value) {
            const document = parseHTML(editor.schema, decodeHtmlEntities(value)).document;
            const { firstChild, childCount } = document.content;

            const toPaste = childCount === 1 && firstChild.type.name === 'paragraph' ? document.content : document.content;
            const slice = new Slice(toPaste, 0, 0);

            const { tr } = editor.state;
            const transaction = tr.replace(0, editor.state.doc.content.size, slice);

            transaction.setMeta('addToHistory', false);
            editor.view.dispatch(transaction);
        }

        editor.commands.setTextSelection(0);

        editor.storage.resizableImage.initId = initId;
        editor.storage.copyPaste.initId = initId;
    };

    const onEditorUpdate = debounce((props) => {
        if (!props) {
            return;
        }

        const { editor } = props;
        onChange?.(editor.getHTML());
    }, 300);

    const onEditorPaste = (view, event) => {
        if (view.state.selection.$head.parent.type.name === 'codeBlock') {
            return;
        }

        if (event.clipboardData.files.length) {
            let types = [];
            event.clipboardData.files.forEach(f => types.push(f.type));

            if (!types.every(t => ['image/gif', 'image/jpeg', 'image/png'].includes(t))) {
                return false;
            }
        }

        if (!isPasteModal) {
            const itemsArray = [];

            for (let i = 0; i < event.clipboardData.items.length; i++) {
                const item = event.clipboardData.items[i];

                if (item.kind === 'string') {
                    item.getAsString(str => {
                        itemsArray.push({
                            kind: 'string',
                            type: item.type,
                            data: str,
                        });
                    });
                } else if (item.kind === 'file') {
                    itemsArray.push({
                        kind: 'file',
                        type: item.type,
                        data: item.getAsFile(),
                    });
                }
            }

            setIsPasteModal(true);
            setPasteData({
                text: event.clipboardData.getData('text/plain'),
                html: event.clipboardData.getData('text/html'),
                rtf: event.clipboardData.getData('text/rtf'),
                items: itemsArray,
                types: event.clipboardData.types,
            });

            return true;
        }
    };

    const editor = useEditor({
        editable: true,
        extensions,
        content: undefined,
        autofocus: 'start',
        onCreate: onEditorCreate,
        onUpdate: onEditorUpdate,
        editorProps: {
            handlePaste: onEditorPaste,
        },
    });

    const onEditorClickOutside = () => {
        editor.chain().setTextSelection(editor.state.doc.content.size).focus().run();
    };

    const { isCodeViewMode, toggleIsCodeViewMode, cmSelectAll } = useCodeView(editor, textAreaRef);

    useImperativeHandle(ref, () => ({
        editor: editor,
    }));

    return (
        <>
            <div id="editor" className={classNames('editor', cx.editor, mode !== 'DESKTOP' ? 'noneDrag' : null, { [cx.error]: error })}>
                <Confirmation
                    isOpen={isPasteModal}
                    contentType="TEXT"
                    color="base"
                    closeBtnText="Нет, удалить"
                    submitBtnText="Да, сохранить"
                    title="Настройки форматирования"
                    text="Сохранить форматирование текста при вставке?"
                    onRequestClose={() => {
                        editor.commands.execPasteTest(pasteData, true);
                        setIsPasteModal(false);
                    }}
                    onRequestSubmit={() => {
                        editor.commands.execPasteTest(pasteData, false);
                        setIsPasteModal(false);
                    }}
                />
                <EditorNotificationProvider>
                    <EditorProvider editor={editor}>
                        <Menu
                            initId={initId}
                            toggleIsCodeViewMode={toggleIsCodeViewMode}
                            isCodeViewMode={isCodeViewMode}
                            cmSelectAll={cmSelectAll}
                            isFullScreen={isFullScreen}
                            setIsFullScreen={setIsFullScreen}
                            mode={mode}
                            setMode={setMode}
                            currentMenu={currentMenu}
                            setCurrentMenu={setCurrentMenu}
                        />
                        <div className={cx.editorContentContainer}>
                            <div className={classNames('editor-content', cx.editorContentWrapper)}>
                                <div
                                    id="editor-content-code"
                                    className={classNames('editor-content-wrapper', cx.editorContent, {
                                        [cx.hide]: !isCodeViewMode,
                                    })}
                                >
                                    <textarea style={{ display: isCodeViewMode ? 'initial' : 'none' }} id="code-view" ref={textAreaRef} />
                                </div>
                                <div
                                    id="editor-content"
                                    className={classNames('editor-content-wrapper', cx.editorContent, {
                                        [cx.hide]: isCodeViewMode,
                                    })}
                                >
                                    <EditorContent editor={editor} />
                                    <BubbleMenuCallout editor={editor} mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuCodeBlock mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuDiagram setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuGoogleDoc setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuImage
                                        isActive={editor?.isActive('resizableImage')}
                                        selection={editor?.state.selection}
                                        setCurrentMenu={setCurrentMenu}
                                    />
                                    <BubbleMenuTemplate setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuLink setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuTOC setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuTable setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuTableLeftMenu mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuTableBottomMenu setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuTableRightMenu setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuTableCell mode={mode} currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuAnchor
                                        isActive={editor?.isActive('anchor')}
                                        selection={editor?.state.selection}
                                        mode={mode}
                                        setCurrentMenu={setCurrentMenu}
                                    />
                                    <BubbleMenuListItem
                                        isActive={editor?.isActive('multiListItem')}
                                        selection={editor?.state.selection}
                                        mode={mode}
                                        currentMenu={currentMenu}
                                        setCurrentMenu={setCurrentMenu}
                                    />
                                    <BubbleMenuEmbedVideo setCurrentMenu={setCurrentMenu} />
                                    <BubbleMenuDetails setCurrentMenu={setCurrentMenu} />
                                </div>
                                <div className={cx.editorContentOutside} onClick={onEditorClickOutside}></div>
                                <Notifications key={isFullScreen} />
                            </div>
                        </div>
                    </EditorProvider>
                </EditorNotificationProvider>
            </div>
            {error && <div className={cx.editorErrorText}>{error}</div>}
        </>
    );
});

export default Editor;
