import { Node } from '@tiptap/core';
import { emojies } from 'uikit/emoji-picker/emojies';

export const Emoji = Node.create({
    name: 'emoji',

    inline: true,

    group: 'inline',

    draggable: true,

    addStorage() {
        return {
            emojies: {},
        };
    },

    addAttributes() {
        return {
            moji: { default: null, parseHTML: el => el.getAttribute('title')?.replace(' ', '_') },
            name: { default: null },
            title: { default: null },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'img',
                getAttrs: el => el.src.includes('smiley') && null,
                priority: 100,
            },
        ];
    },

    renderHTML({ node }) {
        return [
            'span',
            {
                'data-name': node.attrs.name,
                title: node.attrs.title,
            },
            this.editor.storage.emoji.emojies[node.attrs.moji] || '',
        ];
    },

    onCreate() {
        this.editor.storage.emoji.emojies = emojies.reduce((acc, v) => {
            acc[v.n] = v.e;

            return acc;
        }, {});
    },
});
