import ApiClient from './api-axios';

export default class ProjectApi extends ApiClient {
    getProject = async (uuid) => {
        return await this.get('project/' + uuid);
    };

    getProjects = async (page = 0, size = 20, sort = 'createTime,desc', search = '') => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
          params.append('orSearch.specified', 'true');
          params.append('title.contains', search)
        }
        return await this.get('project?' + params.toString());
    };

    searchByTitle = async (search) => {
      return await this.get('project/search/for-filters/by-title?title=' + search.toString());
    }

    getLogo = (uuid) => {
        return this.prefix + 'project/logo?uuid=' + uuid;
    };

    getSmallLogo = (uuid, scale = 128) => {
        return this.prefix + `project/small-logo?uuid=${uuid}&scale=${scale}`;
    };

    postProject = async (project) => {
        return await this.post('project', project);
    };

    editProject = async (project) => {
        return await this.post('project/edit', project);
    };

    getRules = async () => {
        return await this.get('project/access-mode');
    };

    addUserToProject = async (data) => {
        return await this.post('project/user/add', data);
    };

    deleteProject = async (id) => {
        return await this.post(`project/delete/${id}`, {});
    };

    deleteResources = async (uuids) => {
        return await this.post('project/delete-resources', { list: [...uuids] });
    };

    getAccessMode = async () => {
        return await this.get('project/access-mode');
    };

    givePermissionsToResource = async (projectId, permittedUsers, permittedGroups) => {
        return await this.post('project/users/add', { projectId, permittedUsers, permittedGroups });
    };

    removePermissionsToResource = async (resourceId, userIds, groupIds) => {
        return await this.post('project/users/delete', { resourceId, userIds, groupIds });
    };

    getUsersDocumentTree = async () => {
        return await this.get('project/user/tree');
    };

    getUsersScriptsTree = async () => {
        return await this.get('project/user/tree/scripts');
    };


    validateTitle = async (title) => {
        return await this.get(`project/validate/title?title=${encodeURIComponent(title)}`);
    };
}
