import React from 'react';
import { useSelector } from 'react-redux';
import { selectScriptingEnabled } from 'slice/authSlice';
import cx from './global.module.scss';

const ArchiveGlobal = ({ tab, onTabChange }) => {
    const isScriptingEnabled = useSelector(selectScriptingEnabled);
    return (
        <div className={cx.archiveGlobal}>
            <ul>
                <li className={!tab || tab === 'articles' ? cx.active : ''}
                    onClick={() => onTabChange('articles')}>Статьи
                </li>
                <li className={tab === 'news' ? cx.active : ''}
                    onClick={() => onTabChange('news')}>Новости
                </li>
                {isScriptingEnabled && (
                    <li id={cx.scriptsTab} className={tab === 'scripts' ? cx.active : ''}
                        onClick={() => onTabChange('scripts')}>Скрипты
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ArchiveGlobal;
