import React, { useEffect, useState } from 'react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';

import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import { EmbedVideoModal } from '../Menu/MenuButtonYoutube';
import { BubbleMenuView } from '../../extensions/BubbleMenu/BubbleMenuView';

import cx from '../../editor.module.scss';

export const BubbleMenuEmbedVideo = ({ setCurrentMenu }) => {
    const editor = useEditorContext();
    const [showSettings, setShowSettings] = useState(false);

    const [settings, setSettings] = useState(null);

    const toggleSettings = () => {
        setShowSettings(prev => !prev);
    };

    const submitSettings = (src, width, height) => {
        editor?.commands.updateAttributes('embed-video', {
            src,
            width,
            height,
        });
        toggleSettings();
    };

    useEffect(() => {
        if (showSettings) {
            const attrs = editor.getAttributes('embed-video');

            setSettings({ ...attrs, url: attrs.src });
        }
    }, [showSettings, editor]);

    return (
        <BubbleMenuView
            tippyOptions={{
                placement: 'bottom',
                arrow: false,
                getReferenceClientRect: () => {
                    const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                        return node.type.name === 'embed-video';
                    });

                    if (nearestParent) {
                        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                        if (wrapperDomNode) {
                            return wrapperDomNode.getBoundingClientRect();
                        }
                    }
                    const { ranges } = editor?.state.selection;
                    const from = Math.min(...ranges.map(range => range.$from.pos));
                    const to = Math.max(...ranges.map(range => range.$to.pos));
                    return posToDOMRect(editor?.view, from, to);
                },
            }}
            editor={editor}
            shouldShow={({ editor }) => {
                return editor?.isActive('embed-video');
            }}
        >
            <div className={cx.bubbleMenu}>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconBubbleAlignLeft}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.commands.updateAttributes('embed-video', { dataAlign: 'left' });
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconBubbleAlignCenter}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.commands.updateAttributes('embed-video', { dataAlign: 'center' });
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconBubbleAlignRight}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.commands.updateAttributes('embed-video', { dataAlign: 'right' });
                    }}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconSettings}
                    onClick={e => {
                        setCurrentMenu(undefined);
                        toggleSettings(e);
                    }}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconTrash}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor.commands.deleteSelection();
                    }}
                />
            </div>
            {showSettings && <EmbedVideoModal data={settings} show={showSettings} onClose={toggleSettings} onSubmit={submitSettings} />}
        </BubbleMenuView>
    );
};
