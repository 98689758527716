import React, { forwardRef, useState } from 'react';
import { isEqual } from './utils';
import { EDITOR_CKEDITOR, EDITOR_TIPTAP } from './constants';
import Icon, { Icons } from '../uikit/icon';
import CKEditor from './ck-editor';
import ParsingForm from './parsing-form/parsing-form';
import TiptapEditor from './tiptap-editor/Editor';
import cn from './editor.module.scss';

export const Editor = forwardRef((props, ref) => {
    const { id, label, editorExperimentalSwitch, type, entity, onEditorSaveInstance, setFormDataChanged, formDataChanged } = props;

    const [editorType, setEditorType] = useState(type);
    const [parsingForm, setParsingForm] = useState(false);

    const [previousText, setPreviousText] = useState(null);

    const onChangeEditorType = () => {
        setEditorType(prev => {
            const newEditorType = isEqual(prev, EDITOR_TIPTAP) ? EDITOR_CKEDITOR : EDITOR_TIPTAP;
            localStorage.setItem('editorType', newEditorType);

            entity.text = entity.editor?.getData?.() || entity.editor?.getHTML?.();
            return newEditorType;
        });
    };

    const onDocumentParsed = (html, isClearAll) => {
        entity.text = html;
        entity.commit();

        if (isEqual(editorType.toString(), EDITOR_TIPTAP)) {
            entity.editor?.commands.execPasteTest({
                'text': '',
                'html': html,
                'rtf': '',
                'items': [],
                'types': ['text/html'],
            }, isClearAll);
        }

        setParsingForm(false);
    };

    const onChange = (html) => {
        entity.text = html;

        if (formDataChanged) {
            return;
        }

        if (previousText !== null && previousText !== html) {
            setFormDataChanged(true);
        }

        setPreviousText(html);
    };

    if (props.initId === undefined && !props.id) {
        return null;
    }

    return (
        <div id={id} className={cn.editor}>
            <ParsingForm
                isOpen={parsingForm}
                onDismiss={() => setParsingForm(false)}
                onOk={onDocumentParsed}
            />
            <div className={cn.header}>
                <div className={cn.label}>
                    {label}<span className={cn.required}>*</span>
                </div>
                <div className={cn.menu}>
                    <div className={cn.import} onClick={() => setParsingForm(true)}>
                        <Icon type={Icons.DOWNLOAD} width={16} height={16} />
                        <span className={cn.desktop}>Импортировать из документа</span>
                        <span className={cn.mobile}>Импорт</span>
                    </div>
                    {editorExperimentalSwitch === true && (
                        <div className={cn.experimentalSwitch} onClick={onChangeEditorType}>
                            <Icon type={Icons.SWITCH} width={16} height={16} />
                            <span>Переключить редактор</span>
                        </div>
                    )}
                </div>
            </div>
            {isEqual(editorType.toString(), EDITOR_TIPTAP) && (
                <TiptapEditor
                    {...props}
                    onChange={onChange}
                    onInstanceReady={onEditorSaveInstance}
                    ref={ref}
                />
            )}
            {isEqual(editorType.toString(), EDITOR_CKEDITOR) && (
                <CKEditor
                    {...props}
                    onChange={onChange}
                    saveEditorInstance={onEditorSaveInstance}
                />
            )}
        </div>
    );
});

export default Editor;
