import React from 'react';

const UnArchiveIcon = (props) => {
    return (
        <svg width="20" height="18" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.3237 5.22873L11.1336 7.45062C11.0066 7.57758 10.8479 7.64107 10.6733 7.64107C10.4987 7.64107 10.3401 7.57758 10.2131 7.45062L8.02294 5.22873C7.78488 4.9748 7.78488 4.57804 8.02294 4.32411C8.27687 4.08605 8.67362 4.08605 8.92755 4.32411L10.1496 5.56202C9.9909 3.26078 8.10229 1.43566 5.80105 1.43566C3.38872 1.43566 1.43664 3.43536 1.43664 5.87943C1.43664 8.3235 3.38872 10.3232 5.80105 10.3232C6.68981 10.3232 7.54682 10.0534 8.261 9.54554C8.54667 9.33922 8.94343 9.41857 9.14975 9.70424C9.35606 9.98991 9.27671 10.3867 8.99104 10.593C8.05468 11.2437 6.94374 11.5928 5.80105 11.5928C2.69042 11.5928 0.166992 9.03768 0.166992 5.87943C0.166992 2.72118 2.69042 0.166015 5.80105 0.166015C8.72124 0.166015 11.1336 2.43551 11.4034 5.33983L12.4032 4.32411C12.6571 4.07018 13.0539 4.07018 13.3079 4.32411C13.5618 4.57804 13.5618 4.9748 13.3237 5.22873Z"
                fill="#7B8F9C"/>
        </svg>
    );
};

export default UnArchiveIcon;