import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { DatePicker } from 'uikit/datetime';
import { Select, SelectSearch } from 'uikit/select';
import Input from 'uikit/input/input';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import Button from 'uikit/button';
import api from 'api';
import cx from './reports-filter.module.scss';
import { useGlobalContext } from 'lib/hooks';
import InputMultiValue from 'uikit/input/input-multi-value';
import ReportProjectSelect from 'components/report-project-select/report-project-select';
import { SelectionUsers } from 'components/selection-users';

const isFilledArray = (array) => {
    return array && Array.isArray(array) && array.length > 0;
};

const ReportsFilter = ({ filters = [], onChange, onSubmit, onDownload }) => {
    const { platform } = useGlobalContext();

    const [isTreeClear, setIsTreeClear] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [isNewsHidden, setIsNewsHidden] = useState(true);
    const [documentType, setDocumentType] = useState(null);
    const [isProjectHidden, setIsProjectHidden] = useState(false);
    // const [isArticleProjectHidden, setIsArticleProjectHidden] = useState(false);
    const [articleProjectWithSection, setArticleProjectWithSection] = useState(null);
    const [articleProjectWithArticle, setArticleProjectWithArticle] = useState(null);
    // const [isNewsProjectHidden, setIsNewsProjectHidden] = useState(false);
    const [projectWithSection, setProjectWithSection] = useState(null);
    const [projectWithArticle, setProjectWithArticle] = useState(null);
    const [isUserSelectOpen, setIsUserSelectOpen] = useState(false);
    const [news, setNews] = useState([]);
    const [projectId, setProjectId] = useState(null);

    const [errors, setErrors] = useState({});
    const errorsRef = useRef(errors);

    const [filtersDataTemp, setFiltersDataTemp] = useState({});

    const onChangeFilters = useCallback(
        async (field, item) => {
            removeErrors(field['name']);
            if (
                field['type'] === 'project-options' ||
                field['name'] === 'articles-group' ||
                field['name'] === 'news-group' ||
                field['name'] === 'document-type-select'
            ) {
                setIsTreeClear(true);
                setIsNewsHidden(true);
                setFiltersDataTemp((prevState) => {
                    const newState = {
                        ...prevState,
                        projectUuids: [],
                        sectionPaths: [],
                        sectionUuids: [],
                        articleUuids: [],
                        newsUuids: [],
                    };
                    onChange(newState);

                    return newState;
                });
                if (item.value === 'ALL_ARTICLES') {
                    setIsProjectHidden(true);
                    // setIsArticleProjectHidden(true);
                } else if (item.value === 'ALL_NEWS') {
                    setIsProjectHidden(true);
                    // setIsNewsProjectHidden(true);
                } else if (item.value === 'SECTION_ARTICLES') {
                    setArticleProjectWithSection(true);
                    setArticleProjectWithArticle(false);
                    setIsProjectHidden(false);
                    // setIsArticleProjectHidden(false);
                } else if (item.value === 'CUSTOM_ARTICLES') {
                    setArticleProjectWithArticle(true);
                    setIsProjectHidden(false);
                    // setIsArticleProjectHidden(false);
                } else if (item.value === 'GLOBAL_NEWS') {
                    setProjectWithSection(false);
                    setProjectWithArticle(false);
                    setIsProjectHidden(true);
                    // setIsNewsProjectHidden(true);
                    setIsNewsHidden(false);
                    setProjectId(null);

                    try {
                        const res = await api.news.getNewsTitles(0, 99999);
                        setNews(res.content.filter((n) => !n.project).map((n) => ({ value: n.id, label: n.title })));
                    } catch (error) {
                        console.log(error);
                    }
                } else if (item.value === 'PROJECT_NEWS') {
                    setIsProjectHidden(false);
                    // setIsNewsProjectHidden(false);
                    setProjectWithSection(true);
                    setProjectWithArticle(false);
                } else if (item.value === 'NEWS') {
                    setIsProjectHidden(true);
                    // setIsNewsProjectHidden(true);
                } else if (item.value === 'ARTICLE') {
                    setIsProjectHidden(false);
                    // setIsArticleProjectHidden(false);
                }
            }

            if (field['type'] === 'document-type-select') {
                setFiltersDataTemp((prevState) => ({ ...prevState, 'document-type-select': item.value }));
                setDocumentType(item.value);
            }

            setFiltersDataTemp((prevState) => {
                const newState = { ...prevState, [field['name']]: item };

                onChange(newState);
                return newState;
            });
        },
        [setFiltersDataTemp, onChange]
    );

    const onLocationChange = async (values, field) => {
        removeErrors(field['name']);
        Object.keys(values).forEach((key) => {
            onChangeFilters({ name: key }, values[key]);
        });

        if (filtersDataTemp[field['name']]?.value === 'PROJECT_NEWS') {
            setProjectId(values['projectUuids'][0]);
            try {
                const res = await api.news.getProjectNews(values['projectUuids'][0], 0, 99999);
                setNews(res.content.map((n) => ({ value: n.id, label: n.title })));
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleOnDownload = () => {
        onDownload(filtersDataTemp);
    };

    const removeErrors = (key) => {
        if (['sectionPaths', 'projectUuids', 'sectionUuids'].includes(key)) {
            delete errorsRef.current['projectPaths'];
        } else if (key === 'articlesUuids') {
            delete errorsRef.current['article-group'];
        } else if (key === 'newsUuids') {
            delete errorsRef.current['news-group'];
        } else {
            delete errorsRef.current[key];
        }
    };

    const checkErrors = useCallback(() => {
        let _errors = {};
        const requiredFields = filters.reduce((acc, filter) => {
            filter.fields.forEach((f) => {
                if (f['required']) {
                    if (
                        (f.name === 'articles-group' &&
                            (filtersDataTemp['document-type-select']?.value !== 'ARTICLE' ||
                                filtersDataTemp[f.name].value === 'ALL_ARTICLES')) ||
                        (f.name === 'news-group' &&
                            (filtersDataTemp['document-type-select']?.value !== 'NEWS' || filtersDataTemp[f.name].value === 'ALL_NEWS'))
                    ) {
                        return;
                    }
                    acc[f.name] = f;
                }
            });

            return acc;
        }, {});

        Object.keys(requiredFields).forEach((key) => {
            let isError = false;
            if (!filtersDataTemp[key] && key !== 'projectPaths') {
                isError = true;
            }

            if (Array.isArray(filtersDataTemp[key]) && !filtersDataTemp[key].length) {
                isError = true;
            }

            if (!Array.isArray(filtersDataTemp[key]) && filtersDataTemp[key] instanceof Object && !filtersDataTemp[key]['value']) {
                isError = true;
            }

            if (
                (key === 'articles-group' || key === 'news-group') &&
                !isFilledArray(filtersDataTemp.projectUuids) &&
                !isFilledArray(filtersDataTemp.sectionUuids) &&
                !isFilledArray(filtersDataTemp.sectionPaths) &&
                !isFilledArray(filtersDataTemp.articleUuids) &&
                !isFilledArray(filtersDataTemp.newsUuids)
            ) {
                isError = true;
            }

            if (
                key === 'projectPaths' &&
                !isFilledArray(filtersDataTemp.projectUuids) &&
                !isFilledArray(filtersDataTemp.sectionUuids) &&
                !isFilledArray(filtersDataTemp.sectionPaths)
            ) {
                isError = true;
            }

            if (isError) {
                _errors = { ..._errors, [key]: true };
                setErrors((prevValue) => ({ ...prevValue, [key]: true }));
            }
        });

        return _errors;
    }, [filters, filtersDataTemp, setErrors]);

    const submitFilters = useCallback(() => {
        if (!isEmpty(checkErrors())) {
            return;
        }
        onSubmit(filtersDataTemp);
    }, [onSubmit, checkErrors, filtersDataTemp]);

    const onChangeFiltersObject = (field, key, value) => {
        const filtersDataCopy = JSON.parse(JSON.stringify(filtersDataTemp));

        if (!filtersDataCopy[field['name']]) {
            filtersDataCopy[field['name']] = {};
        }

        filtersDataCopy[field['name']][key] = value;

        if (field['type'] === 'date-range' && key === 'type') {
            let today = new Date();

            if (value.value === 0 || value.value === 6) {
                filtersDataCopy[field['name']]['from'] = null;
                filtersDataCopy[field['name']]['to'] = null;
            } else if (value.value === 1) {
                filtersDataCopy[field['name']]['from'] = new Date(today.setHours(0, 0, 0)).toISOString();
                filtersDataCopy[field['name']]['to'] = new Date(today.setHours(23, 59, 59)).toISOString();
            } else if (value.value === 2) {
                let current = new Date(today - 60 * 60 * 24 * 1000);
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0)).toISOString();
                filtersDataCopy[field['name']]['to'] = new Date(current.setHours(23, 59, 59)).toISOString();
            } else if (value.value === 3) {
                let current = new Date(today - 60 * 60 * 24 * 7 * 1000);
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0)).toISOString();
                filtersDataCopy[field['name']]['to'] = new Date(today.setHours(23, 59, 59)).toISOString();
            } else if (value.value === 4) {
                let current = new Date(today - 60 * 60 * 24 * 30 * 1000);
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0)).toISOString();
                filtersDataCopy[field['name']]['to'] = new Date(today.setHours(23, 59, 59)).toISOString();
            } else if (value.value === 5) {
                let current = new Date(today - 60 * 60 * 24 * 90 * 1000);
                filtersDataCopy[field['name']]['from'] = new Date(current.setHours(0, 0, 0)).toISOString();
                filtersDataCopy[field['name']]['to'] = new Date(today.setHours(23, 59, 59)).toISOString();
            }
        }

        if (field['type'] === 'date-range' && (key === 'from' || key === 'to')) {
            if (
                !filtersDataCopy[field['name']] ||
                (filtersDataCopy[field['name']] && filtersDataCopy[field['name']]['type']['value'] !== 6)
            ) {
                filtersDataCopy[field['name']]['type'] = { label: 'Произвольный диапазон', value: 6 };
            }
            if (key === 'from') {
                filtersDataCopy[field['name']]['from'] = new Date(value.setHours(0, 0, 0)).toISOString();
            }
            if (key === 'to') {
                filtersDataCopy[field['name']]['to'] = new Date(value.setHours(23, 59, 59)).toISOString();
            }
        }
        setFiltersDataTemp(filtersDataCopy);
        onChange(filtersDataCopy);
    };

    const parseFilters = useCallback(() => {
        const data = {};

        filters.forEach((filter) => {
            filter['fields'].forEach((field) => {
                if (field['type'] === 'document-type-select') {
                    data['document-type-select'] = field['default']['value'];
                    setDocumentType(field['default']['value']);
                }

                data[field['name']] = field['default'];
            });
        });

        return data;
    }, [filters]);

    const loadNewsOptions = useCallback(
        async (search, _loadedOptions, { page }) => {
            let options = [];

            if (projectId) {
                const newsResponse = await api.news.getProjectNews(projectId, 0, 20, '', search);

                options = newsResponse.content.map((office) => ({ label: office.title, value: office.id }));
                return {
                    options: options,
                    hasMore: page < newsResponse.totalPages - 1,

                    additional: {
                        page: page + 1,
                    },
                };
            } else if (!projectId) {
                if (search) {
                    options = news.filter((user) => {
                        return user.label.toString().toLowerCase().includes(search.toLowerCase());
                    });
                }

                return { options };
            }

            return { options };
        },
        [projectId, news]
    );

    useEffect(() => {
        if (isInit) {
            return;
        }
        const data = parseFilters();
        setFiltersDataTemp(data);
        onChange(data);
        setIsInit(true);
    }, [isInit, parseFilters, onChange]);

    useEffect(() => {
        const data = parseFilters();

        setFiltersDataTemp((prevState) => {
            const newState = {
                ...data,
                projectUuids: prevState['projectUuids'] || [],
                sectionPaths: prevState['sectionPaths'] || [],
                sectionUuids: prevState['sectionUuids'] || [],
                articleUuids: prevState['articleUuids'] || [],
                newsUuids: prevState['newsUuids'] || [],
            };

            return newState;
        });
        setIsInit(true);
    }, [parseFilters]);

    useEffect(() => {
        errorsRef.current = errors;
    }, [errors]);

    return (
        <>
            {isInit ? (
                <div className={classNames(cx.filters, { [cx.mobileFilters]: platform === 'mobile' })}>
                    {filters &&
                        filters.map((filter, i) => {
                            if (filter['isHidden']) {
                                return null;
                            }
                            if (filter['fields'][0]['type'] === 'project' && isProjectHidden) {
                                return null;
                            }
                            if (filter['fields'][0]['type'] === 'hidden-select' && isNewsHidden) {
                                return null;
                            }

                            return (
                                <div
                                    key={i}
                                    className={cx.row}
                                    style={{
                                        width: platform === 'mobile' ? '100%' : filter['width'] || 'initial',
                                    }}
                                >
                                    {filter['label'] ? <h4>{filter['label']}</h4> : null}
                                    {filter['fields'].map((field) => {
                                        return (
                                            <div
                                                key={JSON.stringify(filtersDataTemp[field['name']])}
                                                className={cx.filtersField}
                                                style={{
                                                    width: platform === 'mobile' ? '100%' : field['width'] || 'initial',
                                                }}
                                            >
                                                {field['type'] === 'date-range' && (
                                                    <div className={cx.dateRange}>
                                                        <div>
                                                            <h4>{field['labels'][0]}</h4>
                                                            <Select
                                                                isMulti={false}
                                                                isSearchable={false}
                                                                defaultValue={{
                                                                    label: 'За сегодня',
                                                                    value: 1,
                                                                }}
                                                                value={filtersDataTemp[field['name']]?.type}
                                                                options={[
                                                                    {
                                                                        label: 'За сегодня',
                                                                        value: 1,
                                                                    },
                                                                    {
                                                                        label: 'За вчера',
                                                                        value: 2,
                                                                    },
                                                                    {
                                                                        label: 'За неделю',
                                                                        value: 3,
                                                                    },
                                                                    {
                                                                        label: 'За месяц',
                                                                        value: 4,
                                                                    },
                                                                    {
                                                                        label: 'За три месяца',
                                                                        value: 5,
                                                                    },
                                                                    {
                                                                        label: 'Произвольный диапазон',
                                                                        value: 6,
                                                                    },
                                                                ]}
                                                                onChange={(value) => onChangeFiltersObject(field, 'type', value)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h4>{field['labels'][1]}</h4>
                                                            <DatePicker
                                                                value={new Date(filtersDataTemp[field['name']]?.from)}
                                                                defaultValue={new Date(new Date().setHours(0, 0, 0))}
                                                                onChange={(value) => onChangeFiltersObject(field, 'from', value)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <h4>{field['labels'][2]}</h4>
                                                            <DatePicker
                                                                value={new Date(filtersDataTemp[field['name']]?.to)}
                                                                defaultValue={new Date()}
                                                                onChange={(value) => onChangeFiltersObject(field, 'to', value)}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {field['type'] === 'date' && (
                                                    <div className={cx.filterWrapper}>
                                                        <DatePicker
                                                            className={cx.datePicker}
                                                            value={new Date(filtersDataTemp[field['name']])}
                                                            onChange={(value) => onChangeFilters(field, value)}
                                                        />
                                                    </div>
                                                )}
                                                {field['type'] === 'input-multi-value' && (
                                                    <div className={cx.filterWrapper}>
                                                        <InputMultiValue
                                                            value={filtersDataTemp[field['name']]}
                                                            onChange={(value) => onChangeFilters(field, value)}
                                                        />
                                                    </div>
                                                )}
                                                {(field['type'] === 'select' || field['type'] === 'project-options') && (
                                                    <div className={cx.filterWrapper}>
                                                        <Select
                                                            isMulti={field['isMulti']}
                                                            isSearchable={false}
                                                            value={filtersDataTemp[field['name']]}
                                                            defaultValue={field['default']}
                                                            options={field['options']}
                                                            onChange={(value) => onChangeFilters(field, value)}
                                                            error={errors[field['name']] && 'Поле обязательно для заполнения'}
                                                        />
                                                    </div>
                                                )}
                                                {field['type'] === 'range' && (
                                                    <div className={cx.rangeInputs}>
                                                        <Input
                                                            label={field['label'][0]}
                                                            className={cx.rangeInputsFrom}
                                                            type="text"
                                                            defaultValue={filtersDataTemp[field['name']]?.from}
                                                            onChange={(value) => {
                                                                onChangeFiltersObject(field, 'from', value);
                                                            }}
                                                        />
                                                        <div className={cx.rangeSeparator}>&mdash;</div>
                                                        <Input
                                                            label={field['label'][1]}
                                                            className={cx.rangeInputsTo}
                                                            type="text"
                                                            defaultValue={filtersDataTemp[field['name']]?.to}
                                                            onChange={(value) => {
                                                                onChangeFiltersObject(field, 'to', value);
                                                            }}
                                                        />
                                                        <Select
                                                            isSearchable={false}
                                                            defaultValue={field['default']?.type}
                                                            options={field['options']}
                                                            onChange={(value) => {
                                                                onChangeFiltersObject(field, 'type', value);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {field['type'] === 'user-select' && (
                                                    <div className={cx.filterWrapper}>
                                                        <InputMultiValue
                                                            value={filtersDataTemp[field['name']] || []}
                                                            onChange={(value) => onChangeFilters(field, value)}
                                                            onClick={() => setIsUserSelectOpen(true)}
                                                            readOnly
                                                        />
                                                        <SelectionUsers
                                                            close={() => setIsUserSelectOpen(false)}
                                                            onChange={(value) => {
                                                                onChangeFilters(
                                                                    field,
                                                                    value.map((user) => {
                                                                        return {
                                                                            ...user,
                                                                            img: user['avatar']
                                                                                ? api.upload.getImage(user['avatar'], false, 128)
                                                                                : '/img/avatar.png',
                                                                            label: `${user.firstName} ${user.lastName}`,
                                                                            value: user.id,
                                                                        };
                                                                    })
                                                                );
                                                            }}
                                                            isOpen={isUserSelectOpen}
                                                            value={() => {
                                                                return filtersDataTemp[field['name']] || [];
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                                {field['type'] === 'hidden-select' && !isNewsHidden && (
                                                    <div className={cx.filterWrapper}>
                                                        <Select
                                                            isMulti={field['isMulti']}
                                                            value={filtersDataTemp[field['name']]}
                                                            defaultValue={field['default']}
                                                            options={field['options']}
                                                            onChange={(value) => onChangeFilters(field, value)}
                                                        />
                                                    </div>
                                                )}
                                                {field['type'] === 'document-type-select' && (
                                                    <div className={cx.filterWrapper}>
                                                        <Select
                                                            value={filtersDataTemp[field['name']]}
                                                            defaultValue={field['default']}
                                                            options={field['options']}
                                                            onChange={(value) => onChangeFilters(field, value)}
                                                            isSearchable={false}
                                                        />
                                                    </div>
                                                )}
                                                {field['type'] === 'articles' && (documentType === 'ARTICLE' || !documentType) && (
                                                    <>
                                                        <h4>Статьи</h4>
                                                        <div className={cx.filterWrapper}>
                                                            <Select
                                                                value={filtersDataTemp[field['name']]}
                                                                defaultValue={field['default']}
                                                                options={field['options']}
                                                                onChange={(value) => onChangeFilters(field, value)}
                                                                isSearchable={false}
                                                            />
                                                        </div>
                                                        {filtersDataTemp[field['name']]?.value !== 'ALL_ARTICLES' && (
                                                            <>
                                                                <h4>
                                                                    {filtersDataTemp[field['name']]?.value === 'SECTION_ARTICLES'
                                                                        ? 'Выберите проекты или разделы'
                                                                        : 'Выберите статьи'}
                                                                </h4>
                                                                <div className={cx.filterWrapper}>
                                                                    <ReportProjectSelect
                                                                        error={errors[field['name']] && 'Поле обязательно для заполнения'}
                                                                        onChange={(value) => onLocationChange(value, field)}
                                                                        withSections={articleProjectWithSection || field['withSections']}
                                                                        withArticles={articleProjectWithArticle || field['withArticles']}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {field['type'] === 'news' && (documentType === 'NEWS' || !documentType) && (
                                                    <>
                                                        <h4>Новости</h4>
                                                        <div className={cx.filterWrapper}>
                                                            <Select
                                                                value={filtersDataTemp[field['name']]}
                                                                defaultValue={field['default']}
                                                                options={field['options']}
                                                                onChange={(value) => onChangeFilters(field, value)}
                                                                isSearchable={false}
                                                            />
                                                        </div>
                                                        {filtersDataTemp[field['name']]?.value === 'PROJECT_NEWS' && (
                                                            <>
                                                                <h4>Выберите проекты</h4>
                                                                <div className={cx.filterWrapper}>
                                                                    <ReportProjectSelect
                                                                        error={errors[field['name']] && 'Поле обязательно для заполнения'}
                                                                        withArticles={false}
                                                                        withSections={false}
                                                                        isMulti={false}
                                                                        onChange={(value) => onLocationChange(value, field)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {filtersDataTemp[field['name']]?.value === 'GLOBAL_NEWS' ||
                                                        (filtersDataTemp[field['name']]?.value === 'PROJECT_NEWS' &&
                                                            filtersDataTemp['projectUuids']?.length) ? (
                                                            <>
                                                                <h4>Выберите новости</h4>
                                                                <div className={cx.filterWrapper}>
                                                                    <SelectSearch
                                                                        error={errors[field['name']] && 'Поле обязательно для заполнения'}
                                                                        isMulti={true}
                                                                        closeMenuOnSelect={false}
                                                                        onChange={(item) => onChangeFilters({ name: 'newsUuids' }, item)}
                                                                        loadOptions={loadNewsOptions}
                                                                        paginate
                                                                        additional={{ page: 0 }}
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </>
                                                )}
                                                {field['type'] === 'project' && !isProjectHidden && (
                                                    <div className={cx.filterWrapper}>
                                                        <ReportProjectSelect
                                                            error={errors[field['name']] && 'Поле обязательно для заполнения'}
                                                            onChange={(value) => onLocationChange(value, field)}
                                                            withSections={
                                                                projectWithSection !== null ? projectWithSection : field['withSections']
                                                            }
                                                            withArticles={
                                                                projectWithArticle !== null ? projectWithArticle : field['withArticles']
                                                            }
                                                            isClear={isTreeClear}
                                                            onRemove={() => setIsTreeClear(false)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    <div className={cx.filtersBtns}>
                        <Button color="green" onClick={submitFilters} fullWidth={platform === 'mobile'}>
                            Сформировать отчет
                        </Button>
                        <IconButton
                            label="Скачать"
                            onClick={handleOnDownload}
                            type={IconButtonType.TABLE}
                            fullWidth={platform === 'mobile'}
                            icon={<Icon type={Icons.DOWNLOAD} width={14} height={14} />}
                        />
                        <IconButton
                            label="Отправить по email"
                            type={IconButtonType.TABLE}
                            fullWidth={platform === 'mobile'}
                            icon={<Icon type={Icons.DOWNLOAD} width={14} height={14} />}
                        />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ReportsFilter;
