import React, { useEffect } from 'react';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import buttons from 'model/global-menu/buttons';
import { submenuWrapper } from 'containers/sub-menu-wrapper';
import PrivatePage from 'containers/private-page-wrapper';
import cx from './global-settings.module.scss';
import { useLocation, useNavigate } from '@reach/router';

const menuConfig = [
    {
        to: '/global-settings/companies',
        name: 'Компании и офисы',
        auth: buttons.SETTINGS_COMPANY
    },
    {
        to: '/global-settings/managers',
        name: 'Руководители',
        auth: buttons.SETTINGS_SUPERVISORS
    },
    {
        to: '/global-settings/positions',
        name: 'Должности',
        auth: buttons.SETTINGS_POSITIONS
    },
    {
        to: '/global-settings/custom-roles',
        name: 'Роли',
        auth: buttons.SETTINGS_ROLES
    },
    {
        to: '/global-settings/license',
        name: 'Лицензии',
        auth: buttons.SETTINGS_LICENSE,
    },
    {
        to: '/global-settings/glossary',
        name: 'Глоссарий',
        auth: buttons.SETTINGS_GLOSSARY
    },
    // {
    //     to: '/global-settings/integrations',
    //     links: [
    //         '/global-settings/integrations',
    //         '/global-settings/integrations/naumen',
    //         '/global-settings/integrations/1cbitrix'
    //     ],
    //     name: 'Интеграции',
    //     auth: buttons.SETTINGS_INTEGRATION
    // },
    {
        to: '/global-settings/about',
        name: 'О программе'
    },
    // {
    //     to: '/global-settings/tags',
    //     name: 'Тэги',
    // },
];

const GlobalSettingsPage = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/global-settings') {
            navigate('/global-settings/companies', { replace: true });
        }
    }, [navigate, location.pathname])

    return (
        <PrivatePage hasAnyAuthorities={[GLOBAL_ACTIONS.SYSTEM_SETTINGS]}>
            <div className={cx.pageWrapper}>
                {children}
            </div>
        </PrivatePage>
    );
};

export default submenuWrapper(menuConfig, GlobalSettingsPage);
