import * as Stomp from 'stompjs';
import SockJS from 'sockjs-client';

let stompClient = null;
const subscribers = [];

export const ChatConnect = () => {
    if (stompClient) {
        return;
    }

    const socket = new SockJS('/websocket/chat');

    stompClient = Stomp.over(socket);
    stompClient.debug = null;

    stompClient.connect({}, function() {
        stompClient.subscribe('/chat', (message) => {
            subscribers.forEach(item => {
                item(message);
            });
        });
    });
};

export const ChatSubscribe = (onMessage) => {
    subscribers.push(onMessage);
};

export const ChatMessage = (fromUserId, toUserId, chatGroupId, message) => {
    if (chatGroupId) {
        stompClient.send('/chat', {}, JSON.stringify({ message, fromUserId, chatGroupId }));
    } else {
        stompClient.send('/chat', {}, JSON.stringify({ message, fromUserId, toUserId }));
    }
};