import React from 'react';
import Modal from 'react-modal';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import cx from './training-question-preview.module.scss';

export default function TrainingQuestionPreview({ isOpen, avatar, index, title, mark, type, answers, onRemove, onCopy, onEdit, onClose }) {
    return (
        <Modal isOpen={isOpen} className={cx.trainingQuestionPreviewModal}
               overlayClassName={cx.trainingQuestionPreviewModalOverlay} onRequestClose={() => onClose()}>
            <div className={cx.trainingQuestionPreviewModalHeader}>
                <IconButton type={IconButtonType.TABLE} label="Выйти из просмотра" onClick={() => onClose()}
                            icon={<Icon type={Icons.ARROW_LEFT} width={18} height={18}/>}/>
                {index !== null &&
                <div className={cx.trainingQuestionPreviewModalHeaderButtons}>
                    <IconButton type={IconButtonType.TABLE} label="Удалить вопрос" onClick={() => onRemove(index)}
                                icon={<Icon type={Icons.TRASH} width={14} height={15}/>}/>
                    <IconButton type={IconButtonType.TABLE} label="Дублировать вопрос" onClick={() => onCopy(index)}
                                icon={<Icon type={Icons.COPY} width={14} height={15}/>}/>
                    <IconButton type={IconButtonType.TABLE} label="Редактировать вопрос" onClick={() => onEdit(index)}
                                icon={<Icon type={Icons.EDIT_PEN} width={14} height={15}/>}/>
                </div>}
            </div>
            <div className={cx.trainingQuestionPreviewModalBody}>
                {avatar && <img src={avatar} alt="" />}
                <h3>{title}</h3>
                {type.value !== 2 && <p className={cx.trainingQuestionPreviewModalBodyMark}>{mark} балла(-ов)</p>}
                {type.value === 2 && <p className={cx.trainingQuestionPreviewModalBodyMark}>Без оценивания</p>}
                <div className={cx.trainingQuestionPreviewModalBodySeparator}/>
                {type.value === 0 &&
                <div className={cx.trainingQuestionPreviewModalBodyMany}>
                    <p>Выберите несколько правильных ответов:</p>
                    <ul>
                        {answers.map((answer, index) => {
                            return (
                                <li key={index}>
                                    <label>{answer['value1']}</label>
                                    {answer['isRight'] && <span>Правильный ответ</span>}
                                </li>
                            );
                        })}
                    </ul>
                </div>}
                {type.value === 1 &&
                <div className={cx.trainingQuestionPreviewModalBodyOne}>
                    <p>Выберите один правильный ответ:</p>
                    <ul>
                        {answers.map((answer, i) => {
                            return (
                                <li key={i}>
                                    <label>{answer['value1']}</label>
                                    {answer['isRight'] && <span>Правильный ответ</span>}
                                </li>
                            );
                        })}
                    </ul>
                </div>}
                {type.value === 2 &&
                <div className={cx.trainingQuestionPreviewModalBodyOpen}>
                    <Icon type={Icons.INFO} width={15} height={15}/>
                    <span>Открытый ответ не имеет правильного ответа</span>
                </div>}
                {type.value === 3 &&
                <div className={cx.trainingQuestionPreviewModalBodyNext}>
                    <p>Продолжить фразу:</p>
                    <ul>
                        {answers.map((answer, i) => (
                            <li key={i}>{answer['value1']}</li>
                        ))}
                    </ul>
                </div>}
                {type.value === 4 &&
                <div className={cx.trainingQuestionPreviewModalBodyMatch}>
                    <p>Сопоставление (варианты ответов будут перемешаны):</p>
                    <ul>
                        {answers.map((answer, i) => (
                            <li key={i}>{answer['value1']} - {answer['value2']}</li>
                        ))}
                    </ul>
                </div>}
                {type.value === 5 &&
                <div className={cx.trainingQuestionPreviewModalBodySort}>
                    <p>Сортировка:</p>
                    <ul>
                        {answers.map((answer, i) => (
                            <li key={i}>{answer['value1']}</li>
                        ))}
                    </ul>
                </div>}
            </div>
        </Modal>
    );
};