import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from '@reach/router';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useDialog, useAuthorized, useMessage, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectUsers } from 'slice/authSlice';
import { ContentWrapper } from 'containers/content-wrapper';
import { TableComponent } from 'components/data-components';
import Confirmation from 'components/confirmation';
import { Empty } from 'components/projects';
import { News, NewsStatusColors, Project } from 'uikit/panel';
import { EditRowToolbar } from 'uikit/table';
import Carousel from 'uikit/carousel';
import { Icons } from 'uikit/icon';
import api from 'api';
import cx from './home-page.module.scss';
import { collectArticleBreadCrumbs } from 'lib/helpers';
import ResourceTypes from 'model/resource/resource-type';
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';
import TableTitleCell from 'components/table-title-cell';

export default function HomePage() {
    const tableRef = useRef(null);

    const navigate = useNavigate();
    const selector = useSelector((state) => state);

    const { platform } = useGlobalContext();
    const { isAuthorizedAction } = useAuthorized();

    const { addSuccess, addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [users, setUsers] = useState([]);

    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [projects, setProjects] = useState([]);
    const [news, setNews] = useState([]);

    const [articlesTotal, setArticlesTotal] = useState(0);
    const [articles, setArticles] = useState([]);

    const [lastOpenDocuments, setLastOpenDocuments] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestProjects = useCallback(async () => {
        try {
            const projectsResponse = await api.project.getProjects(0, 10);
            const projects = projectsResponse.content.map((project) => {
                return {
                    id: project['id'],
                    image: project['logoUuid'] ? api.upload.getImage(project['logoUuid'], false, 128) : '',
                    title: project['title'],
                    managerLogin: project['author']['login'],
                    managerShortName: project['author']['firstName'] + ' ' + project['author']['lastName'].slice(0, 1) + '.',
                    manager: project['author']['firstName'] + ' ' + project['author']['lastName'],
                    managerStatus: project['author']['status'],
                    activityUserLogin: project['lastAction'] ? project['lastAction']['userInfo']['login'] : project['author']['login'],
                    activityUser: project['lastAction']
                        ? project['lastAction']['userInfo']['firstName'] + ' ' + project['lastAction']['userInfo']['lastName']
                        : project['author']['firstName'] + ' ' + project['author']['lastName'],
                    activityUserAvatar: project['lastAction']
                        ? project['lastAction']['userInfo']['avatarUuid']
                            ? api.upload.getImage(project['lastAction']['userInfo']['avatarUuid'], false, 128)
                            : ''
                        : project['author']['avatarUuid']
                            ? api.upload.getImage(project['author']['avatarUuid'], false, 128)
                            : '',
                    activityDate: project['lastAction'] ? new Date(project['lastAction']['timestamp']) : new Date(project['createTime']),
                    articles: project['numberOfArticles'],
                    news: project['numberOfNews'],
                    files: project['numberOfAttachments'],
                    members: project['numberOfMembers'],
                    favorite: project['favorite'],
                };
            });

            setProjects(projects);
            setIsFetched(true);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);
    const requestNews = useCallback(async () => {
        try {
            const newsResponse = await api.news.getNews(0, 10, 'modifyTime,desc');
            const news = newsResponse.content
                .map((item) => {
                    return {
                        id: item['id'],
                        statusColor: NewsStatusColors.NONE,
                        statusText: '',
                        image: item['logoUuid'] ? api.upload.getImage(item['logoUuid'], false, 512) : '',
                        projectId: item['project']?.['id'],
                        projectImage: item['project']?.['logoUuid'] ? api.upload.getImage(item['project']?.['logoUuid'], false, 128) : '',
                        projectTitle: item['project']?.['title'],
                        title: item['title'],
                        description: item['text']?.replace(/<\/?[^>]+>/g, '')?.replace(/&nbsp;/gi, ''),
                        authorLogin: item['author']['login'],
                        authorImage: item['author']['avatarUuid'] ? api.upload.getImage(item['author']['avatarUuid'], false, 128) : '',
                        authorShortName: item['author']['firstName'] + ' ' + item['author']['lastName'].slice(0, 1) + '.',
                        authorName: item['author']['firstName'] + ' ' + item['author']['lastName'],
                        authorStatus: item['author']['status'],
                        date: item['modifyTime'] ? new Date(item['modifyTime']) : new Date(item['createTime']),
                        priority: item['priority'],
                        permittedActions: item['permittedActions'],
                        favorite: item['favorite'],
                        permissions: item['permissions'],
                    };
                })
                .sort((a, b) => {
                    return b.date - a.date;
                });

            const highPriorityNews =
                news.filter((n) => n['priority'] === 'HIGH').sort((a, b) => new Date(a.createTime) - new Date(b.createTime)) || [];
            const anotherPriorityNews = news.filter((n) => n['priority'] !== 'HIGH') || [];

            setNews(highPriorityNews.concat(anotherPriorityNews));
            setIsFetched(true);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);

    const requestLastOpenDocuments = async (offset, count, sort) => {
        try {
            const res = await api.user.getLastOpenDocuments(sort);

            setLastOpenDocuments(
                res.slice(0, 3).map((item) => {
                    return { ...item.resource, resourceType: item.resourceType, openTime: item.timestamp };
                })
            );
        } catch (error) {}
    };
    const requestArticles = async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        try {
            setIsLoading(true);

            const articlesResponse = await api.article.getArticles(
                offset / count,
                count,
                sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                search,
                filters
            );

            setArticlesTotal(articlesResponse.totalElements);
            setArticles(articlesResponse.content);

            setIsSearch(!!search);
            setIsEmpty(articlesResponse.totalElements === 0 && _.isEmpty(filters));

            setIsFetched(true);
            setIsLoading(false);
        } catch {}
    };

    const onNewsFavoriteHandler = (newsFavorite, id) => {
        const newsCopy = Object.assign([], news);
        newsCopy.find((item) => item.id === id).favorite = newsFavorite;
        setNews(newsCopy);
    };
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some((c) => c.id === node.id)) {
                return prevValue.filter((c) => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);

    const archiveArticles = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.archive.moveArticleToArchive(data.id);
            } else if (checkedCheckboxRef.current.length) {
                await api.archive.moveToArchive(data.map((article) => article.id));
            }

            tableRef?.current?.reload();
            addSuccess(data?.id ? 'Статья перемещена в архив' : 'Выбранные статьи перенесены в архив');
        } catch (error) {
            addError('При архивировании произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [closeDialog, addSuccess, addError]);
    const onArticlesArchive = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map((d) => d.original));
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <span
                            style={{ color: '#279BD9' }}
                            onClick={() => {
                                navigate(`/projects/articles/${item.original['id']}`);
                                closeDialog();
                            }}
                        >
                            {item.original.title}
                        </span>
                    ),
                };
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите перенести в архив статью&nbsp;
                    <span style={{ color: '#279BD9' }} onClick={() => navigate(`/projects/articles/${data['id']}`)}>
                        {data?.title}
                    </span>
                    &nbsp;? Объекты в архиве могут быть восстановлены
                </span>
            );
        }

        openDialog({
            title: 'Архивирование',
            subTitle: data?.length
                ? 'Вы действительно хотите переместить в архив нижеперечисленные объекты? Объекты в архиве могут быть восстановлены'
                : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onChange: (articles) => checkboxHandler(articles),
            onSubmit: () => archiveArticles(data?.length ? checkedCheckboxRef.current : data),
            onClose: closeDialog,
        });
    }, [checkboxHandler, archiveArticles, navigate, openDialog, closeDialog]);

    const collectCrumbs = (article) => {
        const crumbs = collectArticleBreadCrumbs(article.id, article.title, article.parents);
        return crumbs.map((item) => {
            return {
                name: item.title,
                state: item.state,
                linkTo: article.id === item.id ? '' : item.state === 'ARCHIVED'
                    ? item.type === 'PROJECT'
                        ? `/archive/${item.id}/articles`
                        : `/archive/${crumbs[0].id}/${item.id}/section/articles`
                    : item.type === 'PROJECT'
                        ? `/projects/${item.id}/articles`
                        : `/projects/${crumbs[0].id}/${item.id}/section/articles`,
            };
        });
    };

    const articlesColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title',
            Cell: (data) => (
                <TableTitleCell
                    state={data.row.original.state}
                    title={data.row.original.title}
                    link={`/projects/article/${data.row.original['id']}`}
                    crumbs={collectCrumbs(data.row.original)}
                    description={data.row.original.description}
                />
            ),
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime',
            Cell: (data) => {
                const createTime = new Date(data.row.original['createTime']).toLocaleString();
                const modifyTime = new Date(data.row.original['modifyTime']).toLocaleString();

                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {modifyTime ? modifyTime.substr(0, modifyTime.length - 3) : createTime.substr(0, createTime.length - 3)}
                    </div>
                );
            },
        },
        {
            Header: 'Автор',
            accessor: 'multiName',
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Автор</div>}
                        {data.row.original['author']['status'] === 'DELETED' && (
                            <span style={{ opacity: 1 }}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </span>
                        )}
                        {data.row.original['author']['status'] !== 'DELETED' && (
                            <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </Link>
                        )}
                    </div>
                );
            },
        },
        {
            id: 4,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function (data) {
                let toolbar = [];

                toolbar.push({
                    icon: Icons.EDIT_PEN,
                    tooltip: 'Редактировать',
                    link: `/projects/article/${data.row.original.id}/edit`,
                    isHidden: (original) => !original.permissions.canEdit,
                });

                toolbar.push({
                    icon: data.row.original['favorite'] ? Icons.STAR_FILLED : Icons.STAR,
                    iconActive: data.row.original['favorite'],
                    tooltip: 'В избранное',
                    onClick: async (data) => {
                        if (data.favorite) {
                            await api.favorites.deleteArticleFromFavorites(data.id);
                            addSuccess('Статья удалена из избранного');
                        } else {
                            await api.favorites.addArticleToFavorites(data.id);
                            addSuccess('Статья добавлена в избранное');
                        }

                        const articlesCopy = Object.assign([], articles);
                        articlesCopy.find((item) => item.id === data.id).favorite = !data.favorite;
                        setArticles(articlesCopy);
                    },
                });

                toolbar.push({
                    icon: Icons.ARCHIVE,
                    tooltip: 'Архивировать',
                    onClick: (data) => onArticlesArchive(data),
                    isHidden: (original) => !original.permissions.canArchive,
                });

                return EditRowToolbar(toolbar)(data);
            },
        },
    ], [addSuccess, articles, onArticlesArchive, platform]);

    const articlesActions = useMemo(() => {
        const actions = [];
        const canArchive = (articles ?? []).some(article => article.permissions?.canArchive);

        if (canArchive) {
            actions.push({
                icon: Icons.ARCHIVE,
                label: 'В архив',
                onClick: (data) => onArticlesArchive(data),
            });
        }

        return actions;
    }, [articles, onArticlesArchive]);

    const loadUsers = async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
              return user.label.toString().toLowerCase().includes(search.toLowerCase());
            })
        }

        return { options }
    }

    const articlesFiltersConfig = [
        {
            label: 'Дата создания',
            fields: [
                {
                    name: 'createTime',
                    type: 'date-select',
                    default: {
                        type: {
                            label: 'Все время',
                            value: 0,
                        },
                        from: null,
                        to: null,
                    },
                },
            ],
        },
        {
            label: 'Автор',
            fields: [
                {
                    name: 'authorLogin.in',
                    type: 'search',
                    default: null,
                    isMulti: true,
                    loadOptions: loadUsers,
                },
            ],
        },
        {
            label: 'Срок действия статьи',
            fields: [
                {
                    name: 'lifeTime.greaterOrEqualThan',
                    type: 'date',
                    default: null,
                },
                {
                    name: 'lifeTime.lessOrEqualThan',
                    type: 'date',
                    default: null,
                },
            ],
        }
    ];

    const lastDocumentsColumn = useMemo(() => [
        {
            Header: 'Содержание',
            Cell: (data) => (
                <TableTitleCell
                    state={data.row.original.state}
                    title={data.row.original.title}
                    link={data.row.original.resourceType === ResourceTypes.ARTICLE
                        ? `/projects/article/${data.row.original['id']}`
                        : (data.row.original.project === null ? '' : '/projects') + `/news/${data.row.original['id']}`}
                    crumbs={data.row.original.resourceType !== 'NEWS'
                        ? collectCrumbs(data.row.original)
                        : data.row.original.project
                            ? [{
                                name: data.row.original.project.title,
                                linkTo: `/projects/${data.row.original.project.id}/news`,
                                state: data.row.original.project.state
                            }] : []}
                    description={data.row.original.description}
                />
            ),
        },
        {
            Header: 'Дата открытия',
            width: 130,
            Cell: (data) => {
                const openTime = new Date(data.row.original['openTime']).toLocaleString();

                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата открытия</div>}
                        {openTime.substr(0, openTime.length - 3)}
                    </div>
                );
            },
        },
        {
            Header: 'Дата создания',
            width: 130,
            Cell: (data) => {
                if (!data.row.original['createTime'] && !data.row.original['modifyTime']) {
                    return '-';
                }

                const createTime = new Date(data.row.original['createTime']).toLocaleString();
                const modifyTime = new Date(data.row.original['modifyTime']).toLocaleString();

                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата создания</div>}
                        {modifyTime ? modifyTime.substr(0, modifyTime.length - 3) : createTime.substr(0, createTime.length - 3)}
                    </div>
                );
            },
        },
        {
            Header: 'Автор',
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Автор</div>}
                        {data.row.original['author']['status'] === 'DELETED' && (
                            <span style={{ opacity: 1 }}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </span>
                        )}
                        {data.row.original['author']['status'] !== 'DELETED' && (
                            <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </Link>
                        )}
                    </div>
                );
            },
        },
        {
            id: 4,
            width: 60,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            Cell: function (data) {
                let toolbar = [];

                toolbar.push({
                    icon: data.row.original['favorite'] ? Icons.STAR_FILLED : Icons.STAR,
                    iconActive: data.row.original['favorite'],
                    tooltip: 'В избранное',
                    onClick: async (data) => {
                        try {
                            if (data.resourceType === ResourceTypes.ARTICLE) {
                                if (data.favorite) {
                                    await api.favorites.deleteArticleFromFavorites(data.id);
                                    addSuccess('Статья удалена из избранного');
                                } else {
                                    await api.favorites.addArticleToFavorites(data.id);
                                    addSuccess('Статья добавлена в избранное');
                                }
                            } else if (data.resourceType === ResourceTypes.NEWS) {
                                if (data.favorite) {
                                    await api.favorites.deleteNewsFromFavorites(data.id);
                                    addSuccess('Новость удалена из избранного');
                                } else {
                                    await api.favorites.addNewsToFavorites(data.id);
                                    addSuccess('Новость добавлена в избранное');
                                }
                            }

                            const lastOpenDocumentsCopy = Object.assign([], lastOpenDocuments);
                            lastOpenDocumentsCopy.find((item) => item.id === data.id).favorite = !data.favorite;
                            setLastOpenDocuments(lastOpenDocumentsCopy);
                        } catch (error) {
                            console.log(error);
                            if (data.favorite) {
                                addError('Произошла ошибка при удалении документа из избранного');
                            } else {
                                addError('Произошла ошибка при добавлении документа в избранное');
                            }
                        }
                    },
                });

                return EditRowToolbar(toolbar, null, false, true)(data);
            },
        },
    ], [platform, lastOpenDocuments, addError, addSuccess]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        const fetchData = async () => {
            const users = await selectUsers(selector);
            setUsers(users.content?.map(user => ({
                label: user.firstName + ' ' + user.lastName,
                value: user.login,
            })));
        };
        fetchData();
    }, [selector]);
    useEffect(() => {
        requestNews();
        requestProjects();
    }, [requestNews, requestProjects]);

    const { setMenuContentType } = useContext(MobileMenuContext);
    useEffect(() => {
        if (!setMenuContentType) {
            return;
        }

        setMenuContentType(MENU_CONTENT_TYPE.MAIN_MENU);
    }, [setMenuContentType]);

    const handleDeleteFromFavorites = async (id) => {
        await api.favorites.deleteProjectFromFavorites(id);
        setProjects(projects.map(project => {
            if (project.id !== id) {
                return project;
            }

            return { ...project, favorite: false }
        }))
    }

    return (
        <ContentWrapper>
            <Confirmation {...dialogState} />
            <div className={cx.container}>
                {isFetched && isEmpty && !isSearch && projects.length === 0 && news.length === 0 &&
                <Empty
                    title="Тут сейчас пусто"
                    description="Создайте свой первый проект"
                    buttons={[
                        {
                            icon: Icons.PROJECTS,
                            title: 'Добавить проект',
                            onClick: () => {
                                navigate('/add/project');
                            },
                        },
                    ]}
                />}
                {news.length !== 0 &&
                <div className={cx.news}>
                    <div className={cx.head}>
                        <h2>Последние новости</h2>
                        {platform === 'desktop' && <Link to="/news">Смотреть все новости</Link>}
                    </div>
                    <Carousel className={cx.newsCarousel} itemWidth={window.innerWidth >= 420 ? 380 : 280}>
                        {news.map((item) => {
                            return (
                                <div key={item.id} style={{ width: window.innerWidth >= 420 ? '380px' : '280px' }}>
                                    <News
                                        id={item.id}
                                        statusColor={item.statusColor}
                                        statusText={item.statusText}
                                        image={item.image || '/img/news-card-cover.jpg'}
                                        projectId={item.projectId}
                                        projectImage={item.projectImage}
                                        projectTitle={item.projectTitle}
                                        title={item.title}
                                        description={item.description}
                                        authorLogin={item.authorLogin}
                                        authorImage={item.authorImage}
                                        authorName={item.authorName}
                                        authorShortName={item.authorShortName}
                                        authorStatus={item.authorStatus}
                                        date={item.date}
                                        favorite={item.favorite}
                                        link={(!item.projectId ? '' : '/projects') + `/news/${item['id']}`}
                                        onActionArchive={requestNews}
                                        onFavoriteHandler={onNewsFavoriteHandler}
                                        priority={item.priority}
                                        permittedActions={item.permittedActions}
                                        permissions={item.permissions}
                                    />
                                </div>
                            );
                        })}
                    </Carousel>
                </div>}
                <div className={cx.articleTableContainer}>
                    <TableComponent
                        name="lastDocuments"
                        title="Последняя активность"
                        columns={lastDocumentsColumn}
                        total={lastOpenDocuments.length}
                        data={lastOpenDocuments}
                        onPaginate={requestLastOpenDocuments}
                        archive={true}
                        isPaginate={false}
                        isGlobalCheckbox={false}
                        canRowSelect={false}
                        isMobile={platform === 'mobile'}
                    />
                </div>
                {projects.length !== 0 &&
                <div className={cx.projects}>
                    <div className={cx.head}>
                        <h2>Ваши проекты</h2>
                        <Link to="/projects">Перейти к проектам</Link>
                    </div>
                    <Carousel className={cx.projectsCarousel} itemWidth={380}>
                        {projects.map((item) => {
                            return (
                                <div key={item.id} style={{ width: '380px' }}>
                                    <Project
                                        id={item.id}
                                        image={item.image}
                                        title={item.title}
                                        managerLogin={item.managerLogin}
                                        manager={item.manager}
                                        managerStatus={item.managerStatus}
                                        managerShortName={item.managerShortName}
                                        activityUser={item.activityUser}
                                        favorite={item.favorite}
                                        activityUserAvatar={item.activityUserAvatar}
                                        activityDate={item.activityDate}
                                        activityUserLogin={item.activityUserLogin}
                                        articles={item.articles}
                                        news={item.news}
                                        files={item.files}
                                        members={item.members}
                                        link={'/projects/' + item.id + '/articles'}
                                        onDeleteFromFavorites={handleDeleteFromFavorites}
                                    />
                                </div>
                            );
                        })}
                    </Carousel>
                </div>}
                {(!isEmpty || isSearch) &&
                <div className={cx.articleTableContainer}>
                    <TableComponent
                        innerRef={tableRef}
                        isLoading={isLoading}
                        name="articles"
                        title="Статьи"
                        searchTitle="Поиск по автору и названию"
                        columns={articlesColumns}
                        actions={articlesActions}
                        total={articlesTotal}
                        data={articles}
                        onPaginate={requestArticles}
                        addTitle="Добавить статью"
                        filters={articlesFiltersConfig}
                        isMobile={platform === 'mobile'}
                        onAdd={isAuthorizedAction([GLOBAL_ACTIONS.ARTICLE_CREATE]) ? () => navigate('/add/article') : null}
                        addLink={isAuthorizedAction([GLOBAL_ACTIONS.ARTICLE_CREATE]) ? '/add/article' : ''}
                        defaultSort={{ by: 'modifyTime', desc: true }}
                        isShowReadiedFilter={true}
                        sortOptions={[
                            { Header: 'По дате создания', accessor: 'createTime' },
                            { Header: 'По дате изменения (по умолчанию)', accessor: 'modifyTime' },
                            { Header: 'По названию (по алфавиту)', accessor: 'title' },
                            { Header: 'По автору (по алфавиту по фамилии)', accessor: 'multiName' },
                            { Header: 'По количеству лайков', accessor: 'likesCount' },
                            { Header: 'По количеству дизлайков', accessor: 'dislikesCount' },
                            { Header: 'По популярности (кол-во просмотров)', accessor: 'readCount' },
                            { Header: 'По количеству комментариев', accessor: 'commentsCount' },
                            { Header: 'По рейтингу (оценка пользователя после прочтения)', accessor: '' },
                        ]}
                    />
                </div>}
            </div>
        </ContentWrapper>
    );
}
