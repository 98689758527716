import React, { useRef, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { textValidator, descValidator } from 'lib/util/validators.util';
import { useGlobalContext, useMessage } from 'lib/hooks';
import Button from 'uikit/button';
import Input from 'uikit/input';
import Icon, { Icons } from 'uikit/icon';
import TextArea from 'uikit/textarea';
import AvatarEditor from 'components/avatar-editor/avatar-editor';
import BackButton from 'components/back-button';
import api from 'api';
import cx from './companies-page-edit-company.module.scss';

const CompaniesPageEditCompany = ({ companyId, navigate }) => {
    const { addError, addSuccess } = useMessage();
    const { platform } = useGlobalContext();
    const uploadInputRef = useRef();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [logoUuid, setLogoUuid] = useState(null);
    const [removedLogoId, setRemovedLogoId] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);

    const [isAvatarModal, setIsAvatarModal] = useState(false);
    const [uploadAvatar, setUploadAvatar] = useState(null);
    const [croppableUploadAvatar, setCroppableUploadAvatar] = useState(null);

    const [nameErr, setNameErr] = useState(null);
    const [descErr, setDescErr] = useState(null);

    const fetchCompany = useCallback(async () => {
        if (!companyId) {
            return;
        }

        try {
            const res = await api.company.getCompanyById(companyId);

            setName(res.name);
            setDescription(res.description);
            setLogoUuid(res.avatarUuid);
        } catch (error) {
            console.log(error);
        }
    }, [companyId]);

    const selectAvatar = (e) => {
        if (e.target.files.length > 0 && e.target.files[0].type.match(/^image\//)) {
            var oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);

            oFReader.onload = function () {
                setUploadAvatar(this.result);
                setIsAvatarModal(true);
            };
        } else {
            addError('для загрузки допустимы следующие форматы - JPG, PNG, WEBM.');
        }
    };

    const saveAvatar = (avatar) => {
        setCroppableUploadAvatar(avatar);
        setIsAvatarModal(false);
        setRemovedLogoId(null);
    };
    const onAvatarCancel = () => {
        document.getElementById('company-avatar').value = '';
        setIsAvatarModal(false);
    };
    const removeLogo = async () => {
        try {
            setRemovedLogoId(987);
            setCroppableUploadAvatar(null);
        } catch (error) {
            console.log(error);
        }
    };

    const onSubmit = async () => {
        let isErr = false;

        setIsSubmitted(true);

        if (name.trim().length === 0) {
            setNameErr(textValidator(name));
            isErr = true;
        }

        if (description.trim().length === 0) {
            setDescErr(descValidator(description));
            isErr = true;
        }

        if (isErr) {
            return;
        }

        const logo = removedLogoId
            ? ''
            : croppableUploadAvatar
            ? croppableUploadAvatar?.replace(/^data:image\/(png|jpg);base64,/, '')
            : null;

        let companyRes;
        try {
            if (!companyId) {
                companyRes = await api.company.addCompany({ name, description, logo });
                addSuccess('Компания создана');
            } else {
                companyRes = await api.company.updateCompany({
                    name,
                    description,
                    logo,
                    id: companyId,
                });
                addSuccess('Компания изменена');
            }

            goBack();
        } catch (error) {
            addError(!companyId ? 'Ошибка при создании компании' : 'Ошибка при изменении данных компании')
        }

        if (companyRes.message === 'error.validation') {
            addError('Указан не полный адрес');
        }
    };

    const onNameChange = (str) => {
        setNameErr(textValidator(str));
        setName(str);
    };

    const onDescChange = (str) => {
        setDescErr(descValidator(str));
        setDescription(str);
    };

    const goBack = () => {
      navigate('/global-settings/companies')
    };

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    return (
        <div className={classNames(cx.container, { [cx.mobile]: platform === 'mobile' })}>
            <div className={cx.header}>
                <BackButton onClick={goBack} />
                <h1>Редактирование компании</h1>
            </div>
            <div>
                {isAvatarModal && (
                    <AvatarEditor
                        open={isAvatarModal}
                        onSubmit={(avatar) => saveAvatar(avatar)}
                        onDismiss={onAvatarCancel}
                        img={uploadAvatar}
                        title={'Загрузка аватара'}
                    />
                )}
                <div className={cx.avatarContainer}>
                    <img
                        src={
                            !removedLogoId && logoUuid && !croppableUploadAvatar
                                ? api.upload.getImage(logoUuid, false, 512)
                                : croppableUploadAvatar !== null
                                ? croppableUploadAvatar
                                : '/img/avatar.png'
                        }
                        alt=""
                    />
                    <div className={cx.infoContainer}>
                        <p>Загрузите логотип компании.</p>
                        <p>Поддерживаются файлы форматов JPG, PNG, WEBM.</p>
                        <div className={cx.btnContainer}>
                            <Button
                                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                fullWidth={platform === 'mobile'}
                            >
                                <Icon type={Icons.TRASH} width={15} height={15} /> Загрузить новое фото
                            </Button>
                            <Button onClick={removeLogo} fullWidth={platform === 'mobile'}>
                                <Icon type={Icons.TRASH} width={15} height={15}/> Удалить фото
                            </Button>
                        </div>
                        <input
                            id="company-avatar"
                            style={{ display: 'none' }}
                            ref={uploadInputRef}
                            onChange={selectAvatar}
                            type="file"
                            name="myfile"
                            accept="image/png, image/jpeg"
                        />
                    </div>
                </div>
                <div className={cx.formContainer}>
                    <Input
                        className={cx.name}
                        label="Название компании"
                        value={name}
                        onChange={onNameChange}
                        error={isSubmitted ? nameErr : ''}
                        id={'companyFormTitle'}
                    />
                    <TextArea
                        className={cx.desc}
                        label="Краткое описание"
                        rows="2"
                        value={description}
                        onChange={onDescChange}
                        error={isSubmitted ? descErr : ''}
                    />
                    <div className={cx.footer}>
                        <Button label="Отмена" onClick={goBack} fullWidth={platform === 'mobile'}  />
                        <Button
                            label="Подтвердить"
                            color="green"
                            onClick={onSubmit}
                            fullWidth={platform === 'mobile'}
                            disabled={isSubmitted && !!(nameErr || descErr)}
                         />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompaniesPageEditCompany;

