import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { makeMoveable, Scalable } from 'react-moveable';
import { NodeViewWrapper } from '@tiptap/react';
import { VideoPlayer } from '../../components/VideoPlayer';
import { MenuButton } from '../../components/Menu/MenuButton';
import { Icons } from 'uikit/icon';
import { VideoSettingsModal } from '../../components/Modals/VideoSettingsModal';
import { useVideoPlayer } from 'lib/hooks/useVideoPlayer';
import { useClickOutside } from '../ResizableImage/use-click-outside';

import cx from '../../editor.module.scss';

const Moveable = makeMoveable([Scalable]);

export function ResizableVideoNodeView({ node, editor, extension, updateAttributes, selected }) {
    const [showSettings, setShowSettings] = useState(false);
    const [settings, setSettings] = useState(null);
    const [focused, setFocused] = useState(false);

    const videoRef = useClickOutside(() => setFocused(false));
    const src = node?.attrs?.src;
    const { player, getPlayerProps } = useVideoPlayer({
        fluid: true,
        controls: true,
        rewind: true,
        src: src ?? null,
        autoplay: node.attrs.autoplay,
    });

    const options = extension.options;
    const { attrs } = node;

    const toggleSettings = () => {
        setShowSettings(prev => !prev);
    };

    const submitSettings = ({ autoplay, responsive }) => {
        if (responsive) {
            updateAttributes({
                width: '100%',
            });
        }

        updateAttributes({
            autoplay,
            responsive,
        });
        toggleSettings();
    };

    const scaleDirection = useMemo(() => {
        if (attrs.dataAlign === 'center') {
            return [0, -1];
        } else if (attrs.dataAlign === 'right') {
            return [1, -1];
        } else {
            return [-1, -1];
        }
    }, [attrs.dataAlign]);

    const onScale = event => {
        event.target.style.transform = event.drag.transform;
    };

    const onScaleEnd = event => {
        const rect = event.target.getBoundingClientRect();
        if (rect.width <= options.maxWidth) {
            updateAttributes({
                width: rect.width,
            });
            player.dimensions(rect.width, rect.height);
        } else {
            updateAttributes({
                width: options.maxWidth,
            });
        }
        event.target.style.transform = '';
    };

    const onClick = e => {
        e.preventDefault();
        e.stopPropagation();
        setFocused(true);
    };

    useEffect(() => {
        if (showSettings) {
            const attrs = editor.getAttributes('video');

            setSettings({ autoplay: attrs.autoplay, responsive: attrs.responsive });
        }
    }, [showSettings, editor]);

    return (
        <NodeViewWrapper
            as="article"
            className={classNames('media-node-view not-prose', {
                [`align-${attrs.dataAlign}`]: attrs.dataAlign,
            })}
            style={{ pointerEvents: editor.isActive('video') ? 'initial' : 'none' }}
            data-align={attrs.dataAlign}
        >
            <div style={{ width: attrs.width || '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                <VideoPlayer {...getPlayerProps()} onClick={onClick} ref={videoRef} />

                {selected ? (
                    <>
                        <div
                            className={cx.bubbleMenu}
                            style={{
                                position: 'absolute',
                                bottom: -40,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.12)',
                            }}
                        >
                            <MenuButton
                                className={cx.bubbleMenuButton}
                                icon={Icons.EditorIconBubbleAlignLeft}
                                onClick={() => {
                                    updateAttributes({ dataAlign: 'left' });
                                }}
                            />
                            <MenuButton
                                className={cx.bubbleMenuButton}
                                icon={Icons.EditorIconBubbleAlignCenter}
                                onClick={() => {
                                    updateAttributes({ dataAlign: 'center' });
                                }}
                            />
                            <MenuButton
                                className={cx.bubbleMenuButton}
                                icon={Icons.EditorIconBubbleAlignRight}
                                onClick={() => {
                                    updateAttributes({ dataAlign: 'right' });
                                }}
                            />
                            <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                            <MenuButton
                                className={cx.bubbleMenuButton}
                                icon={Icons.EditorIconSettings}
                                onClick={e => {
                                    toggleSettings(e);
                                }}
                            />
                            <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                            <MenuButton
                                className={cx.bubbleMenuButton}
                                icon={Icons.EditorIconTrash}
                                onClick={() => {
                                    editor?.chain().focus().deleteSelection().run();
                                }}
                            />
                        </div>
                        {showSettings && (
                            <VideoSettingsModal data={settings} show={showSettings} onClose={toggleSettings} onSubmit={submitSettings} />
                        )}
                    </>
                ) : null}
            </div>
            {!attrs.responsive && (
                <Moveable
                    target={focused ? videoRef : null}
                    scalable={true}
                    keepRatio={true}
                    origin={false}
                    throttleScale={0}
                    renderDirections={['se', 'nw', 'ne', 'sw']}
                    snappable={true}
                    onScale={onScale}
                    onScaleEnd={onScaleEnd}
                    onScaleStart={e => {
                        e.setFixedDirection(scaleDirection);
                    }}
                    onBeforeScale={e => {
                        e.setFixedDirection(scaleDirection);
                    }}
                    {...options.moveableProps}
                />
            )}
        </NodeViewWrapper>
    );
}
