import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonRedo = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const redo = e => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);
        editor?.chain().focus().redo().run();
    };

    return (
        <MenuButton
            tooltip="Повторить"
            icon={Icons.EditorIconRedo}
            onClick={redo}
            disabled={!editor?.isEditable || !editor?.can().redo()}
        ></MenuButton>
    );
};
