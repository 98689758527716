import React from 'react';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input/input';
import cx from './training-question-answer-match.module.scss';

export default function TrainingQuestionAnswerMatch({ answers, onChange }) {
    const OnAnswerAdd = () => {
        const temp = Object.assign([], answers);
        temp.push({ value1: '', value2: '', isRight: false });
        onChange(temp);
    };
    const OnAnswerChange = (text, i, type) => {
        const temp = Object.assign([], answers);
        temp[i][type] = text;
        onChange(temp);
    };
    const OnAnswerRemove = (i) => {
        const temp = Object.assign([], answers);
        temp.splice(i, 1);
        onChange(temp);
    };

    return (
        <div className={cx.trainingQuestionAnswerMatch}>
            <p className={cx.trainingQuestionAnswerMatchDescription}>
                Вы можете создать лишний ответ, создав группу вопрос-ответ и оставив поле “Вопрос” пустым.
            </p>
            {answers.map((answer, i) => {
                return (
                    <div key={i} className={cx.trainingQuestionAnswerMatchItem}>
                        <div className={cx.trainingQuestionAnswerMatchItemContainer}>
                            <Input type="text" label={'Вопрос - ' + (i + 1)} value={answer['value1']}
                                   onChange={(str) => OnAnswerChange(str, i, 'value1')}/>
                            <IconButton icon={<Icon type={Icons.TRASH} width={14} height={15}/>}
                                        onClick={() => OnAnswerRemove(i)}/>
                        </div>
                        <div className={cx.trainingQuestionAnswerMatchItemContainer}>
                            <Input type="text" label={'Ответ - ' + (i + 1)} value={answer['value2']}
                                   onChange={(str) => OnAnswerChange(str, i, 'value2')}/>
                            <IconButton icon={<Icon type={Icons.TRASH} width={14} height={15}/>}
                                        onClick={() => OnAnswerRemove(i)}/>
                        </div>
                    </div>
                );
            })}
            <div className={cx.trainingQuestionAnswerMatchAdd} onClick={() => OnAnswerAdd()}>
                <div className={cx.trainingQuestionAnswerMatchAddIcon}>
                    <Icon type={Icons.ADD} width={10} height={10}/>
                </div>
                <span>Добавить группу вопрос-ответ</span>
            </div>
        </div>
    );
};