import React, { useCallback, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { NODE_TYPES, ScriptingTree } from 'components/trees';
import { useGlobalContext } from 'lib/hooks';
import TreeUtils from 'lib/util/tree.util';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import cx from './scripts-wrapper.module.scss';

// export const ScriptsTreeContext = React.createContext(null);
export const ScriptsTreeContext = React.createContext({
    reloadTree: () => {},
    setOnReloadTreeAction: () => {},
});

function ScriptsWrapper({ navigate, children, ...props }) {
    const { platform } = useGlobalContext();
    const newsPicker = useSelector(selectNewsPicker);

    const treeRef = useRef(null);
    const [node, setNode] = useState(null);

    const [onReloadTreeAction, setOnReloadTreeAction] = useState(() => () => {});
    const [isReload, setIsReload] = useState(false);

    const reloadTree = (withAction = true) => {
        setIsReload((prev) => !prev);

        if (withAction && onReloadTreeAction) {
            onReloadTreeAction();
        }
    };

    const onNavigate = useCallback(
        (treeNode) => {
            if (!treeNode) {
                navigate('/scripting');
            } else if (treeNode.nodeType === NODE_TYPES.PROJECT) {
                navigate(`/scripting/${treeNode.id}`);
            } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
                navigate('/scripting/' + TreeUtils.getTopParentNode(treeNode).id + '/' + treeNode.id.toLowerCase().replace(/_/g, '-'));
            } else if (treeNode.nodeType === NODE_TYPES.SCRIPTS) {
                navigate('/scripting/script/' + treeNode.id);
            } else if (treeNode.nodeType === NODE_TYPES.ARTICLE) {
                navigate(`/projects/article/${treeNode.id}`);
            }
        },
        [navigate]
    );

    const getNodeLink = useCallback((treeNode) => {
        if (!treeNode) {
            return '/scripting';
        } else if (treeNode.nodeType === NODE_TYPES.PROJECT) {
            return '/scripting/' + treeNode.id;
        } else if (treeNode.nodeType === NODE_TYPES.SECTION) {
            return '/scripting/' + TreeUtils.getTopParentNode(treeNode).id + '/' + treeNode.id.toLowerCase().replace(/_/g, '-');
        } else if (treeNode.nodeType === NODE_TYPES.SCRIPTS) {
            return '/scripting/script/' + treeNode.id;
        } else if (treeNode.nodeType === NODE_TYPES.ARTICLE) {
            return `/projects/article/${treeNode.id}`;
        }
    }, []);

    useEffect(() => {
        const path = props['*'].split('/');
        if (path[path.length - 1] !== node) {
            setNode(path[path.length - 1]);
        }
    }, [props, node]);

    return (
        <ScriptsTreeContext.Provider value={{tree: treeRef, reloadTree, setOnReloadTreeAction }}>
            <div className={classNames(cx.pageWrapper, { [cx.mobile]: platform === 'mobile' })}>
                {platform === 'desktop' && (
                    <div
                        className={classNames(cx.tree, {
                            [cx.withNewsTicker]: newsPicker.count > 0,
                        })}
                    >
                        <ScriptingTree 
                            scrollToNode={node ? node : null} 
                            onNavigate={onNavigate} 
                            ref={treeRef} 
                            getNodeLink={getNodeLink} 
                            isReload={isReload}
                            reloadTree={reloadTree}
                        />
                    </div>
                )}
                <section
                    className={classNames(cx.contentWrapper, {
                        [cx.withNewsTicker]: newsPicker.count > 0,
                    })}
                >
                    {children}
                </section>
            </div>
        </ScriptsTreeContext.Provider>
    );
}

export default ScriptsWrapper;
