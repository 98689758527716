import { mergeAttributes, Node } from '@tiptap/core';

export const TableHeader = Node.create({
    name: 'tableHeader',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    content: 'block+',

    addAttributes() {
        return {
            colspan: {
                default: 1,
            },
            rowspan: {
                default: 1,
            },
            align: {
                default: null,
                parseHTML: el => el.getAttribute('align') || el.style.textAlign,
                renderHTML: attrs => {
                    return attrs.align ? { align: attrs.align, style: `text-align: ${attrs.align}` } : {};
                },
            },
            valign: {
                default: 'middle',
                renderHTML: attrs => {
                    return attrs.valign ? { valign: attrs.valign, style: `vertical-align: ${attrs.valign}` } : {};
                },
            },
            colwidth: {
                default: null,
                parseHTML: element => {
                    const colwidth = element.getAttribute('colwidth') || element.style.width;
                    const value = colwidth ? [parseInt(colwidth, 10)] : null;
                    return value;
                },
                renderHTML: attrs => {
                    if (!attrs.colwidth) {
                        return {};
                    }

                    return {
                        colwidth: attrs.colwidth,
                        style: `width: ${attrs.colwidth}px`,
                    };
                },
            },
            bgcolor: {
                default: '',
                parseHTML: el => el.getAttribute('bgcolor') || el.style.backgroundColor,
                renderHTML: attrs => {
                    if (!attrs.bgcolor) {
                        return {};
                    }
                    return {
                        style: `background-color: ${attrs.bgcolor};`,
                    };
                },
            },
            'data-border-color': {
                default: '',
                parseHTML: el => el.getAttribute('data-border-color') || el.style.borderColor,
                renderHTML: attrs => {
                    if (!attrs['data-border-color']) {
                        return {};
                    }

                    return {
                        style: `border-color: ${attrs['data-border-color']}`,
                    };
                },
            },
            textcolor: {
                default: '',
                parseHTML: el => el.getAttribute('textcolor') || el.style.color,
                renderHTML: attrs => {
                    if (!attrs.textcolor) {
                        return {};
                    }

                    return {
                        style: `color: ${attrs.textcolor};`,
                    };
                },
            },
        };
    },

    tableRole: 'header_cell',

    isolating: true,

    parseHTML() {
        return [{ tag: 'th' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['th', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});
