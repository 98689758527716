import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import Icon, { Icons } from 'uikit/icon';
import { Select } from 'uikit/select';
import cx from './pagination.module.scss';

const countOptions = [
    {
        label: 5,
        value: 5
    },
    {
        label: 10,
        value: 10
    },
    {
        label: 20,
        value: 20
    },
    {
        label: 50,
        value: 50
    }
];

const Pagination = ({ total, count, offset, onPaginate, isMobile, id = "" }) => {
    const { width } = useWindowSize();

    const paginate = (page, count) => {
        onPaginate(page, count);
    };

    let buttonsIndex = 0;
    let buttonsCount = Math.ceil(total / count);

    const allowButtonsCount = width >= 768 && width <= 1024 ? 3 : 5;
    const allowButtonsOffset = width >= 768 && width <= 1024 ? 1 : 2;

    if (Math.ceil(total / count) > allowButtonsCount) {
        buttonsIndex = offset / count > allowButtonsOffset ? offset / count - allowButtonsOffset : 0;
        buttonsIndex = buttonsIndex + allowButtonsOffset >= total / count - allowButtonsOffset
            ? total / count - allowButtonsCount : buttonsIndex;

        buttonsCount = total / count >= allowButtonsCount ? buttonsIndex + allowButtonsCount : total / count;
    }

    const buttons = [];

    for (let i = Math.ceil(buttonsIndex); i < buttonsCount; i++) {
        buttons.push({ id: i, label: i + 1 });
    }

    if (buttons.length === 0) {
        buttons.push({ id: 0, label: 1 });
    }

    return (
        <div className={classNames(cx.pagination, {[cx.mobile]: isMobile})} data-testid={id}>
            <div className={cx.container}>
                <button className={cx.first}
                        onClick={() => paginate(0, count)}
                        disabled={offset === 0}>Первая</button>
                <button className={cx.prev}
                        onClick={() => paginate(offset - count, count)}
                        disabled={offset - count < 0}>
                    <Icon type={Icons.ARROW} width={8} height={14}/>
                </button>
                <div className={cx.buttons}>
                    {!isMobile && buttons.map((item) => {
                        return (
                            <button key={item.id} className={offset / count === item.id ? cx.active : ''}
                                    onClick={() => paginate(item.id * count, count)}>
                                {item.label}
                            </button>
                        );
                    })}
                    {isMobile &&
                    <button className={cx.active}>{offset / count + 1}</button>}
                </div>
                <button className={cx.next}
                        onClick={() => paginate(offset + count, count)}
                        disabled={offset + count >= total}>
                    <Icon type={Icons.ARROW} width={8} height={14}/>
                </button>
                <button className={cx.last} disabled={offset + count >= total}
                        onClick={() => paginate(total % count === 0
                            ? total - count
                            : Math.floor(total / count) * count, count)}>Последняя
                </button>
            </div>
            {!isMobile &&
            <Select value={{ label: count.toString(), value: count.toString() }} className={cx.counts}
                    options={countOptions} onChange={(item) => paginate(0, item.value)}/>}
        </div>
    );
};

Pagination.defaultProps = {
    total: 0,
    count: 0,
    offset: 0,
    onPaginate: () => {}
};

Pagination.propTypes = {
    total: PropTypes.number,
    count: PropTypes.number,
    offset: PropTypes.number,
    onPaginate: PropTypes.func
};

export default React.forwardRef((props, ref) => <Pagination {...props} forwardedRef={ref}/>);
