import React, { useCallback, useMemo, useState } from 'react';
import { forEach } from 'lodash';
import { Link } from '@reach/router';
import { collectArticleBreadCrumbs } from 'lib/helpers';
import Icon, { Icons } from 'uikit/icon';
import style from 'components/file/file-usage-list.module.scss';
import api from 'api/index';
import IconButton from 'uikit/icon-button';
import { TableComponent } from 'components/data-components';
import { useGlobalContext } from 'lib/hooks';

const FileUsageList = ({ uuid, filePreviewClose, handleClose }) => {
    const { platform } = useGlobalContext();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [usageTableTotal, setUsageTableTotal] = useState(0);
    const [usageTableData, setUsageTableData] = useState([]);

    const usageColumns = useMemo(
        () => [
            {
                Header: 'Содержание',
                accessor: 'title',
                width: 300,
                maxWidth: 300,
                Cell: (data) => {
                    const type = data.row.original.newsBreadCrumbs ? 'news' : 'article';

                    return (
                        <div>
                            <div>
                                <Link
                                    style={{ display: 'flex', color: '#262626' }}
                                    to={`/projects/${type}/${data.row.original['id']}`}
                                    onClick={filePreviewClose}
                                >
                                    {data.row.original['title']}
                                </Link>
                                <p>
                                    {data.row.original['articleBreadCrumbs'] && (
                                        <div className={style.crumbs}>
                                            {data.row.original['articleBreadCrumbs'].map((c, i) => {
                                                return (
                                                    <>
                                                        {i !== 0 && <span>&nbsp;</span>}/&nbsp;
                                                        {c.linkTo ? (
                                                            <a href={c.linkTo} target="_blank" rel="noreferrer" className={style.crumbLink}>
                                                                {c.name}
                                                            </a>
                                                        ) : (
                                                            <span>{c.name}</span>
                                                        )}
                                                    </>
                                                );
                                            })}
                                        </div>
                                    )}
                                    {data.row.original['newsBreadCrumbs'] && (
                                        <>
                                            {!data.row.original['newsBreadCrumbs'].projectTitle && <span>Общие</span>}
                                            {data.row.original['newsBreadCrumbs'] && (
                                                <a
                                                    href={`/projects/${data.row.original['newsBreadCrumbs']['projectId']}/articles`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {data.row.original['newsBreadCrumbs']['projectTitle']}
                                                </a>
                                            )}
                                            &nbsp;/&nbsp;
                                            <a href={`/projects/news/${data.row.original['id']}`} target="_blank" rel="noreferrer">
                                                {data.row.original['title']}
                                            </a>
                                        </>
                                    )}
                                </p>
                                <span>{data.row.original['description']}</span>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Прикреплен',
                accessor: 'createTime',
                Cell: (data) => {
                    const date = new Date(data.row.original['createTime']).toLocaleString();
                    return date.substr(0, date.length - 3);
                },
            },
            {
                Header: 'Прикрепил',
                accessor: 'author',
                Cell: (data) => {
                    return `${data.row.original.author?.firstName} ${data.row.original.author?.lastName}`;
                },
            },
        ],
        [filePreviewClose]
    );

    const fetchFileUsage = useCallback(
        async (offset, count, sort = { by: '', desc: false }) => {
            if (uuid) {
                setIsLoaded(false);

                try {
                    let usages = [];
                    let totalElements = 0;

                    const res = await api.file.getFileUsage({
                        uuid,
                        offset: offset / count,
                        count,
                        sort: sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                    });

                    forEach(res, (value) => {
                        totalElements += value.totalElements;
                        usages = [
                            ...usages,
                            ...value.content.map((usage) => {
                                const article = usage.article ? usage.article : [];
                                const news = usage.news ? usage.news : [];

                                const articleBreadcrumbs = usage.articleParents
                                    ? collectArticleBreadCrumbs(article.id, article.title, usage.articleParents)
                                    : [];
                                const newsBreadCrumbs = usage.newsParents ? usage.newsParents : null;

                                return {
                                    ...article,
                                    ...news,
                                    articleBreadCrumbs: articleBreadcrumbs.map((item) => {
                                        console.log(item);
                                        return {
                                            name: item.title,
                                            linkTo:
                                                item.type === 'PROJECT'
                                                    ? `/projects/${item.id}/articles`
                                                    : item.type === 'SECTION'
                                                    ? `/projects/${articleBreadcrumbs[0].id}/${item.id}/section/articles`
                                                    : `/projects/article/${item.id}`,
                                        };
                                    }),
                                    newsBreadCrumbs,
                                };
                            }),
                        ];
                    });

                    setUsageTableData(usages);
                    setUsageTableTotal(totalElements);

                    setIsEmpty(totalElements === 0);
                    setIsLoaded(true);
                } catch (e) {
                    console.log(e);
                }
            }
        },
        [uuid]
    );

    // useEffect(() => {
    //     fetchFileUsage();
    // }, [fetchFileUsage]);

    return (
        <>
            <div className={style.usageHeader}>
                <div className={style.usageTitle}>Где использован файл</div>
                <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16} />} onClick={handleClose} />
            </div>
            {isEmpty && (
                <div className={style.usageEmpty}>Файл не используется в документах</div>
            )}
            {!isEmpty && (
                <TableComponent
                    isLoading={!isLoaded}
                    searchTitle="Поиск по имени"
                    columns={usageColumns}
                    data={usageTableData}
                    total={usageTableTotal}
                    onPaginate={fetchFileUsage}
                    isMobile={platform === 'mobile'}
                    name="usages"
                />
            )}
        </>
    );
};

export default FileUsageList;
