import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import cx from './audio-player.module.scss';

export default function Volume({ volume, onVolumeUpdate }) {
    const [isMute, setMute] = useState(false);
    const [currentVolume, setCurrentVolume] = useState();
    const volumePercentage = volume * 100;

    const calcClickedVolume = (e) => {
        const clickPositionInPage = e.pageX;
        const bar = document.getElementById('volume-bar');

        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;

        const clickPositionInBar = clickPositionInPage - barStart > barWidth
            ? barWidth
            : clickPositionInPage - barStart < 0
                ? 0
                : clickPositionInPage - barStart;

        const volumePerPixel = 100 / barWidth;
        return (volumePerPixel * clickPositionInBar) / 100;
    };

    const handleVolumeDrag = (e) => {
        if (isMute) {
            setMute(false);
        }

        onVolumeUpdate(calcClickedVolume(e));

        const updateVolumeOnMove = eMove => {
            onVolumeUpdate(calcClickedVolume(eMove));
        };

        document.addEventListener('mousemove', updateVolumeOnMove);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateVolumeOnMove);
        });
    };

    function mute() {
        if (!isMute) {
            setMute(true);
            setCurrentVolume(volume);

            onVolumeUpdate('0');
        } else {
            setMute(false);
            setCurrentVolume(null);

            onVolumeUpdate(currentVolume);
        }
    }

    return (
        <div className={cx.volume}>
            <div className={cx.muteIcon} onClick={mute}>
                <Icon type={isMute ? Icons.VOLUME_OFF : Icons.VOLUME_ON} width={14} height={14}/>
            </div>
            <div id="volume-bar" className={classNames(cx.barProgress, cx.volumeValue)}
                 style={{
                     background: `linear-gradient(to right, #1280CE ${volumePercentage}%, #1280CE 0, #EAEDF3  ${volumePercentage}%, #EAEDF3 100%)`
                 }} onMouseDown={e => handleVolumeDrag(e)}>
                <span className={cx.barProgressKnob} style={{ left: `${volumePercentage - 2}%` }}/>
            </div>
        </div>
    );
}

Volume.defaultProps = {
    volume: 0,
    onVolumeUpdate: () => {

    }
};

Volume.propTypes = {
    volume: PropTypes.number,
    onVolumeUpdate: PropTypes.func
};