import React from 'react';
import PropTypes from 'prop-types';
import ReactIframeResizer from 'react-iframe-resizer-super';

const iframeResizerOptions = {
    checkOrigin: false,
    autoResize: false,
    sizeWidth: false,
    sizeHeight: true,
    scrolling: false,
    resizeFrom: 'parent'
};

class Frame extends React.PureComponent {
    render() {
        return this.props.body ?
            <ReactIframeResizer iframeResizerOptions={iframeResizerOptions} content={this.props.body} id="iframeId"
                                style={{ width: '100%', height: '100%', marginBottom: '-5px' }}/> : <div/>;
    }
}

Frame.propTypes = {
    body: PropTypes.string.isRequired
};

export default Frame;