import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from '@reach/router';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDialog, useMessage } from 'lib/hooks';
import formatDateTime from 'lib/helpers/formatDateTime';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Loading from 'uikit/loading';
import api from 'api/index';
import cx from './news.module.scss';
import { Confirmation } from 'components/confirmation/confirmation';
import Tippy from '@tippyjs/react';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import { useDispatch } from 'react-redux';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';

export const NewsStatusColors = {
    NONE: 'none',
    GRAY: 'gray',
    YELLOW: 'yellow',
    RED: 'red'
};

const News = ({ id, className, statusColor, statusText, image, projectId, projectImage, projectTitle, title,
                  authorLogin, authorImage, authorName, authorShortName, authorStatus, date, favorite, onClick, onActionArchive,
                  onFavoriteHandler, priority, link, permissions }) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { addSuccess, addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isLoading, setIsLoading] = useState(false);

    const onArchive = useCallback(async () => {
        setIsLoading(true);
        closeDialog();

        try {
            await api.archive.moveNewsToArchive(id);
            await onActionArchive();

            addSuccess('Новость перемещена в архив');
            dispatch(updateNewsTicker(true));
        } catch (error) {
            addError('При архивировании произошла ошибка');
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, id, addSuccess, addError, closeDialog, onActionArchive]);

    const onArchiveNews = useCallback(() => {
        openDialog({
            title: 'Архивирование',
            text: (
                <span>
                    Вы действительно хотите перенести в архив новость 
                    <span
                        style={{ color: '#279BD9', cursor: 'pointer' }}
                        onClick={() => navigate((!projectId ? '' : '/projects') + `/news/${id}`)}
                    >
                        {title}
                    </span> ?
                    Объекты в архиве могут быть восстановлены
                </span>
            ),
            onSubmit: () => onArchive(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    }, [id, title, openDialog, closeDialog, onArchive, navigate, projectId])

    const onFavorite = async () => {
        try {
            if (favorite) {
                await api.favorites.deleteNewsFromFavorites(id);
                addSuccess('Новость удалена из избранного');
            } else {
                await api.favorites.addNewsToFavorites(id);
                addSuccess('Новость добавлена в избранное');
            }

            onFavoriteHandler(!favorite, id);
        } catch (e) {
            if (favorite) {
                addError('Не удалось удалить новость из избранного');
            } else {
                addError('Не удалось добавить новость в избранное');
            }
        }
    };

    return (
        <div className={classNames(cx.news, className)}>
            <Confirmation {...dialogState} />
            {isLoading &&
            <div className={cx.loader}>
                <Loading withOverlay={false}/>
            </div>}
            <div className={cx.top}>
                {statusColor !== NewsStatusColors.NONE &&
                <div className={classNames(cx.status, cx[statusColor])}>{statusText}</div>}
                <Link to={link}>
                    <div className={cx.image} onClick={() => onClick(id)} style={{ backgroundImage: `url(${image})` }}/>
                </Link>
                {projectTitle &&
                <Link className={cx.company} to={'/projects/' + projectId + '/news'}>
                    <div id={'projectAvatar'} className={cx.image}>
                        {projectImage && <img src={projectImage} alt={''}/>}
                        {!projectImage && <img src={'/img/default-icon-project.jpg'} alt={''}/>}
                    </div>
                    <MultiClumpTooltip 
                        className={cx.projectTitle} 
                        clamp={1} 
                        label={projectTitle}
                    >
                        {projectTitle}
                    </MultiClumpTooltip>
                </Link>}
            </div>
            <div className={cx.body}>
                <Link to={link}>
                    <MultiClumpTooltip 
                        className={cx.title} 
                        clamp={2} 
                        label={title}
                    >
                        {title}
                    </MultiClumpTooltip>
                </Link>
                <div className={cx.author}>
                    <div className={cx.container}>
                        <div className={cx.image} style={{ backgroundImage: `url(${authorImage ? authorImage : '/img/avatar.png'})` }}/>
                        <div>
                            <p>Автор:</p>
                            <Tippy content={authorName}>
                                {authorStatus === 'DELETED' ? <span>{authorShortName}</span>
                                                            : <Link to={'/edit-user/' + authorLogin}>{authorShortName}</Link>}
                            </Tippy>
                        </div>
                    </div>
                    <span>{formatDateTime(date)}</span>
                </div>
                {priority === 'HIGH' && <div className={cx.highPriority}>Срочная новость</div>}
            </div>
            <div className={cx.bottom}>
                <div className={cx.container}>
                    {permissions?.canEdit &&
                    <IconButton link={(!projectId ? '' : '/projects') + `/news/${id}/edit`}
                                icon={<Icon type={Icons.EDIT_PEN} tooltip={'Редактировать'}/>}/>}
                    <IconButton icon={<Icon type={favorite ? Icons.STAR_FILLED : Icons.STAR} tooltip={'В избранное'}
                                            className={classNames({ [cx.favorite]: favorite })}/>}
                                onClick={onFavorite}/>
                    {permissions?.canArchive &&
                    <IconButton icon={<Icon type={Icons.ARCHIVE} tooltip={'Архивировать'}/>}
                                onClick={onArchiveNews}/>}
                </div>
            </div>
        </div>
    );
};

News.defaultProps = {
    id: '',
    className: '',
    statusColor: NewsStatusColors.NONE,
    statusText: '',
    image: '',
    projectId: '',
    projectImage: '',
    projectTitle: '',
    title: '',
    description: '',
    authorLogin: '',
    authorImage: '',
    authorName: '',
    date: '',
    link: '',
    favorite: false,
    onClick: () => {

    },
    onActionRemove: () => {

    },
    onActionArchive: () => {

    },
    permittedActions: []
};

News.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    statusColor: PropTypes.string,
    statusText: PropTypes.string,
    image: PropTypes.string,
    projectId: PropTypes.string,
    projectImage: PropTypes.string,
    projectTitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    authorLogin: PropTypes.string,
    authorImage: PropTypes.string,
    authorName: PropTypes.string,
    link: PropTypes.string,
    date: PropTypes.any,
    favorite: PropTypes.bool,
    onClick: PropTypes.func,
    onActionRemove: PropTypes.func,
    onActionArchive: PropTypes.func,
    permissions: PropTypes.object,
};

export default News;
