import React from 'react';
import { BubbleMenu } from '@tiptap/react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import { LinkModal } from '../Menu/MenuButtonLink';

import cx from '../../editor.module.scss';

export const BubbleMenuLink = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const [isEdit, setIsEdit] = React.useState(false);
    const [linkData, setLinkData] = React.useState({ linkUrl: '', linkName: '' });

    const toggleEdit = () => {
        if (isEdit) {
            setIsEdit(false);
        } else {
            const link = editor?.getAttributes('link');
            editor.commands.extendMarkRange('link');

            const { from, to } = editor.state.selection;
            const text = editor.state.doc.textBetween(from, to);

            setLinkData({ linkUrl: link.href, linkName: text });
            setIsEdit(true);
        }
    };

    const navigateLink = () => {
        const { href } = editor?.getAttributes('link');

        if (href.includes('#')) {
            const editorContent = document.querySelector('.editor-content');
            const anchors = document.querySelectorAll('[data-type="anchor"]');

            for (let i = 0; i < anchors.length; i++) {
                if (anchors[i].getAttribute('id') === href.slice(1) || anchors[i].getAttribute('name') === href.slice(1)) {
                    const editorRect = editorContent.getBoundingClientRect();
                    const anchorRect = anchors[i].getBoundingClientRect();

                    editorContent.scrollTo({
                        top: editorContent.scrollTop + anchorRect.top - editorRect.top - 5,
                        behavior: 'smooth',
                    });

                    editor.commands.blur();
                    break;
                }
            }
        } else {
            window.open(href, '_blank');
        }
    };

    const handleSubmit = (name, url) => {
        editor.commands.updateLink(name, { href: url });

        setIsEdit(false);
    };

    return (
        <BubbleMenu
            tippyOptions={{
                placement: 'bottom',
                arrow: false,
                getReferenceClientRect: () => {
                    const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                        return node.type.name === 'link';
                    });

                    if (nearestParent) {
                        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                        if (wrapperDomNode) {
                            return wrapperDomNode.getBoundingClientRect();
                        }
                    }
                    const { ranges } = editor?.state.selection;
                    const from = Math.min(...ranges.map(range => range.$from.pos));
                    const to = Math.max(...ranges.map(range => range.$to.pos));
                    return posToDOMRect(editor?.view, from, to);
                },
            }}
            editor={editor}
            shouldShow={({ editor }) => editor.isActive('link')}
        >
            <div className={cx.bubbleMenu}>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconEdit}
                    onClick={e => {
                        setCurrentMenu(undefined);
                        toggleEdit(e);
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconLinkUnset}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().extendMarkRange('link').unsetLink().run();
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconLinkOpen}
                    onClick={e => {
                        setCurrentMenu(undefined);
                        navigateLink(e);
                    }}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconTrash}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor.chain().focus().extendMarkRange('link').unsetLink().deleteSelection().run();
                    }}
                />
            </div>
            {isEdit && <LinkModal data={linkData} show={isEdit} onClose={toggleEdit} onSubmit={handleSubmit} />}
        </BubbleMenu>
    );
};
