import ApiClient from './api-axios';

export default class UserPositionApi extends ApiClient {
    getUserPosition = async (page = 0, size = 2000, sort = '', search) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
        }

        return await this.get('?' + params.toString());
    };

    addUserPosition = async (data) => {
        return await this.post('', data);
    };

    updateUserPosition = async (data) => {
        return await this.put(`/${data.id}`, data);
    };

    deleteUserPosition = async (id) => {
        return await this.deleteRequest(`/${id}`);
    };
}
