import React from 'react';
import PropTypes from 'prop-types';
import cs from './tree-toggler.module.scss';
import Icon, { Icons } from 'uikit/icon';
import classNames from 'classnames';

const Toggler = ({ state, depth, ...props }) => {
    return (
        <div {...props}
             className={classNames(cs.toggler, { [cs.opened]: state === 'opened' }) }
             style={{ marginLeft: `${depth * 14}px` }}
        >
            {state === 'opened' && <Icon type={Icons.ARROW_DOWN} width={12} height={12}/>}
            {state === 'closed' && <Icon type={Icons.ARROW_RIGHT} width={12} height={12}/>}
        </div>
    );
};

Toggler.propTypes = {
    state: PropTypes.string,
    depth: PropTypes.number
};

export default Toggler;