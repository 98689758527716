// Раскрывает/закрывает ноды дерева
const toggleTreeNodes = ({ tree }, collapse) => {
    function toggleNode(node) {
        node.children.forEach(toggleNode);
        if ((!collapse && !node.state.open) || (collapse && node.state.open)) {
            tree.toggleNode(node, { silent: true });
        }
    }

    if (tree) {
        for (let node of tree.nodes) {
            toggleNode(node);
        }
    }
};

export default toggleTreeNodes;