import { Extension } from '@tiptap/core';
import { Plugin, PluginKey, TextSelection } from '@tiptap/pm/state';

const Delete = Extension.create({
    name: 'delete',

    addOptions() {
        return {
            blockTypes: [],
        };
    },

    addKeyboardShortcuts() {
        const onRemoveHandler = (isBackspace = true) => {
            const { state } = this.editor.view;
            const { $from, $to } = state.selection;
            const currentNode = $from.parent;

            // Prevent delete if current node is doc. It's tiptap bug;
            if (currentNode.type.name === 'doc') {
                return true;
            }

            // Check text selection;
            const cursorPos = $from.pos;
            const startPos = $from.start();
            const endPos = $from.end();

            if (cursorPos === endPos && state.selection.$cursor === null) {
                if (cursorPos === state.selection.to) {
                    return true;
                }
            }

            if (currentNode.textContent) {
                if (isBackspace && cursorPos === endPos) {
                    return false;
                }

                if (!isBackspace && cursorPos === startPos) {
                    return false;
                }

                if (cursorPos !== startPos && cursorPos !== endPos) {
                    return false;
                }
            }

            // Check elements around selection;
            const beforeNode = state.doc.resolve($from.before()).nodeBefore;
            const afterNode = state.doc.resolve($from.after()).nodeAfter;

            if (beforeNode && this.options.blockTypes.includes(beforeNode.type.name)) {
                return true;
            }

            if (!isBackspace && afterNode && this.options.blockTypes.includes(afterNode.type.name)) {
                return true;
            }

            // Check selection element;
            const parents = [];
            const protectedBlocks = [];

            let isText = false;

            state.doc.nodesBetween($from.pos, $to.pos, (node) => {
                parents.push(node);

                if (this.options.blockTypes.includes(node.type.name)) {
                    protectedBlocks.push(node.type.name);
                }

                if (node.type.name === 'text') {
                    isText = true;
                }
            });

            // if (protectedBlocks.some((item, index) => protectedBlocks.indexOf(item) !== index)) {
            //     return true;
            // }

            if (protectedBlocks.length === 0 || isText) {
                return false;
            }

            let isFirst = false;
            let isLast = false;

            parents.forEach((parent) => {
                if (parent.type.name === 'div') {
                    return;
                }

                if (parent.firstChild === state.selection.$head.node()) {
                    isFirst = true;
                }

                if (parent.lastChild === state.selection.$head.node()) {
                    isLast = true;
                }
            });

            if (!isFirst && !isLast) {
                return false;
            }

            if (isBackspace && !isFirst && isLast) {
                return false;
            }

            if (!isBackspace && isFirst && !isLast) {
                return false;
            }

            if (isBackspace && !state.selection.$head.nodeBefore) {
                return true;
            }

            if (!isBackspace && !state.selection.$head.nodeAfter) {
                return true;
            }

            return false;
        };

        return {
            Backspace: () => onRemoveHandler(true),
            Delete: () => onRemoveHandler(false),
        };
    },

    addProseMirrorPlugins() {
        return [
            new Plugin({
                key: new PluginKey('copyPaste'),
                props: {
                    handleDOMEvents: {
                        click: (view, event) => {
                            if (event.detail >= 3) {
                                this.editor.commands.blur();
                            }
                        },
                        dblclick: (view) => {
                            const { state, dispatch } = view;
                            const { $from } = state.selection;

                            const cursorPos = $from.pos;
                            const endPos = $from.end();

                            if (cursorPos === endPos && state.selection.$cursor === null) {
                                const newPos = state.selection.$anchor.pos;
                                dispatch(state.tr.setSelection(TextSelection.create(state.doc, newPos, newPos)));
                            }
                        },
                    },
                },
            }),
        ];
    },
});

export default Delete;
