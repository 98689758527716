import Paragraph from '@tiptap/extension-paragraph';
import { AllSelection, TextSelection } from '@tiptap/pm/state';
import { findParentNodeOfType } from 'prosemirror-utils';

export const CustomParagraph = Paragraph.extend({
    addOptions() {
        return {
            HTMLAttributes: {},
            types: ['paragraph'],
            minLevel: 0,
            maxLevel: 7,
        };
    },

    addAttributes() {
        return {
            'data-indent': {
                default: null,
                renderHTML: attrs => {
                    return attrs?.['data-indent'] > this.options.minLevel
                        ? { 'data-indent': attrs['data-indent'], style: `text-indent: ${attrs['data-indent'] * 30}px` }
                        : null;
                },
                parseHTML: element => {

                    const level = Number(element.getAttribute('data-indent')) || element.style.textIndent?.replace(/\D+/g, '') / 30;
                    console.log(level && level > this.options.minLevel ? level : null);
                    return level && level > this.options.minLevel ? level : null;
                },
            },
        };
    },

    addCommands() {
        const setNodeIndentMarkup = (tr, pos, delta) => {
            const node = tr?.doc?.nodeAt(pos);

            if (node) {
                const nextLevel = (node.attrs['data-indent'] || 0) + delta;
                const { minLevel, maxLevel } = this.options;
                const indent = nextLevel < minLevel ? minLevel : nextLevel > maxLevel ? maxLevel : nextLevel;

                if (indent !== node.attrs['data-indent']) {
                    const { /*'data-indent': oldIndent,*/ ...currentAttrs } = node.attrs;
                    const nodeAttrs = indent > minLevel ? { ...currentAttrs, 'data-indent': indent } : currentAttrs;
                    return tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks);
                }
            }
            return tr;
        };

        const updateIndentLevel = (tr, delta) => {
            const { doc, selection } = tr;

            if (selection.$anchor.node(-1)?.type.name === 'listItem') {
                return false;
            }

            if (doc && selection && (selection instanceof TextSelection || selection instanceof AllSelection)) {
                const { from, to } = selection;
                doc.nodesBetween(from, to, (node, pos) => {
                    if (this.options.types.includes(node.type.name)) {
                        tr = setNodeIndentMarkup(tr, pos, delta);
                        return false;
                    }

                    return true;
                });
            }

            return tr;
        };
        const applyIndent =
            direction =>
            () =>
            ({ tr, state, dispatch }) => {
                const { selection } = state;
                tr = tr.setSelection(selection);
                tr = updateIndentLevel(tr, direction);

                if (tr.docChanged) {
                    dispatch?.(tr);
                    return true;
                }

                return false;
            };

        return {
            indent: applyIndent(1),
            outdent: applyIndent(-1),
            setParagraph:
                () =>
                ({ commands }) => {
                    return commands.setNode(this.name);
                },
        };
    },

    parseHTML() {
        return [
            { tag: 'p' },
            {
                tag: 'br',
                getAttrs: function (dn) {
                    var pdn = dn.parentNode;
                    // creating empty paragraph if not soft br ()
                    // need to make it a loop since can be inside span (like google docs)
                    while (pdn) {
                        if (['DIV', 'P', 'TD', 'TH'].indexOf(pdn.tagName) >= 0) {
                            if (pdn.childNodes.length === 1) return null; // create empty paragarph
                            return false; // don't create a paragarph and stop
                        }
                        pdn = pdn.parentNode;
                    }
                    return false; // don't create a paragarph
                },
            },
        ];
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Alt-0': () => this.editor.commands.setParagraph(),
            Tab: () => this.editor.commands.indent(),
            'Cmd-]': () => this.editor.commands.indent(),
            'Shift-Tab': () => this.editor.commands.outdent(),
            'Cmd-[': () => this.editor.commands.outdent(),
            Backspace: ({ editor }) => {
                const paragraph = findParentNodeOfType(editor.state.schema.nodes.paragraph)(editor.state.selection);

                if (paragraph?.node.attrs['data-indent'] && paragraph.start === editor.state.selection.from) {
                    return this.editor.commands.outdent();
                }

                return false;
            },
        };
    },
});
