import React from 'react';
import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';

export const MenuButtonCodeBlock = ({ setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const toggleCodeBlock = () => {
        setCurrentMenu(undefined);

        editor?.chain().focus().toggleCodeBlock().run();
        editor?.chain().focus().updateAttributes('codeBlock', { language: 'cpp' }).run();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconCode}
            tooltip={isTooltip === false ? null : 'Добавить код'}
            label="Добавить код"
            onClick={toggleCodeBlock}
        ></MenuButton>
    );
};
