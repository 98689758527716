import ApiClient from './api-axios';

const getDate = (date) => {
    const _date = new Date(date);
    let month = _date.getMonth() + 1;
    month = month.toString().length === 1 ? '0' + month : month;
    let day = _date.getDate().toString().length === 1 ? '0' + _date.getDate() : _date.getDate();

    return `${_date.getFullYear()}-${month}-${day}`;
}

export default class UserApi extends ApiClient {
    info = async () => {
        return await this.get('v1/info');
    };

    getUsersInitList = async () => {
        return await this.get('users/init-list');
    }

    getUsers = async (page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', true);
            params.append('login.contains', search);
            params.append('multiName.contains', search);
            params.append('email.contains', search);
            // params.append('positionTitle.contains', search);
            // params.append('roleName.contains', search);
            params.append('phone.contains', search);
        }

        if (filters['positionTitle.in']) {
            params.append('positionTitle.in', filters['positionTitle.in'].map((filter) => filter.value));
        }

        if (filters['supervisorLogin.in']) {
            params.append('supervisorLogin.in', filters['supervisorLogin.in'].map((filter) => filter.value));
        }

        if (filters['officeName.in']) {
            params.append('officeName.in', filters['officeName.in'].map((filter) => filter.value));
        }

        if (filters['group.in']) {
            params.append('groupId.in', filters['group.in'].map((filter) => filter.value));
        }

        if (filters['project.in']) {
            params.append('project.in', filters['project.in'].map((filter) => filter.value));
        }

        if (filters['roleName.in']) {
            params.append('roleName.in', filters['roleName.in'].map((filter) => filter.value));
        }

        if (filters['projectRoleName.in']) {
            params.append('projectRoleName.in', filters['projectRoleName.in'].map((filter) => filter.value));
        }

        return await this.get('users?' + params.toString(), '', true);
    };

    getLogo = (uuid) => {
        return this.prefix + 'logo?uuid=' + uuid;
    };

    getUserSmallLogo = (uuid) => {
        return `/services/kms/api/small-logo?uuid=${uuid}`;
    };

    postUser = async (user) => {
        user['birthDate'] = getDate(user.birthDate)
        user['employmentDate'] = getDate(user.employmentDate);

        return await this.post('users/', user);
    };

    putUser = async (user) => {
        user['birthDate'] = getDate(user.birthDate);
        user['employmentDate'] = getDate(user.employmentDate);

        return await this.put('users/', user);
    };

    deleteUser = async (login) => {
        return await this.post(`users/delete/${login}`);
    };

    getUserByLogin = async (login) => {
        return await this.get(`users/profile/${login}`);
    };

    findByLogin = async text => {
        return await this.get(`users/search/${text}`);
    };

    changePassword = async data => {
        return await this.post('users/change-password', { ...data });
    };

    getCurrentUser = async () => {
        return await this.get('users/user/current');
    };

    getBlockedUsers = async (project, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', true);
            params.append('login.contains', search);
            params.append('multiName.contains', search);
            params.append('email.contains', search);
            // params.append('positionTitle.contains', search);
            // params.append('roleName.contains', search);
            params.append('phone.contains', search);
        }

        if (filters['positionTitle.in']) {
            params.append('positionTitle.in', filters['positionTitle.in'].map((filter) => filter.value));
        }

        if (filters['supervisorLogin.in']) {
            params.append('supervisorLogin.in', filters['supervisorLogin.in'].map((filter) => filter.value));
        }

        if (filters['officeName.in']) {
            params.append('officeName.in', filters['officeName.in'].map((filter) => filter.value));
        }

        if (filters['group.in']) {
            params.append('groupId.in', filters['group.in'].map((filter) => filter.value));
        }

        if (filters['project.in']) {
            params.append('project.in', filters['project.in'].map((filter) => filter.value));
        }

        if (filters['roleName.in']) {
            params.append('roleName.in', filters['roleName.in'].map((filter) => filter.value));
        }

        return await this.get(`users/blocked${project && project !== 'all' ? '/' + project : ''}?` + params.toString(), '', true);
    };

    getLastOpenDocuments = async (sort) => {
      const params = new URLSearchParams();
      params.append('sort', sort && sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc');
      return await this.get('/users/lastOpenDocuments?' + params.toString());
    }

    blockUser = async (login) => {
        return await this.post('users/block/' + login);
    };

    unBlockUser = async (login) => {
        return await this.post('users/unblock/' + login);
    };

    validateLogin = async (login) => {
        return await this.get('users/login/validate', { 'login': login });
    };

    validateUserData = async (login) => {
        return await this.post('/users/validate', { 'login': login });
    };

    validateImport = async (files) => {
        const formData = new FormData();
        formData.append('data', new Blob(files));
        return await this.post('users/validate-upload', formData);
    };

    uploadUsers = async (users) => {
        // const formData = new FormData();
        // formData.append('data', users);
        return await this.post('users/upload', JSON.stringify(users));
    }

    template = () => {
        return this.prefix + 'users/upload-template';
    };
}
