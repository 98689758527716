import { Node, mergeAttributes } from '@tiptap/core';
import { v4 } from 'uuid';

export const CalloutNode = Node.create({
    name: 'callout',

    content: 'block* paragraph*',

    group: 'block',

    allowGapCursor: true,

    addOptions() {
        return {
            types: {
                aqua: 'aquablock',
                red: 'redblock',
                green: 'greenblock',
                blue: 'blueblock',
                yellow: 'yellowblock',
                pirple: 'pirpleblock',
            },
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            type: {
                default: 'green',
                parseHTML: el => {
                    const cs = Array.from(el.classList).filter(cs => cs.includes('block'))?.[0];

                    return cs?.replace('block', '');
                },
                renderHTML: attributes => {
                    return {
                        class: this.options.types[attributes.type] ?? '',
                    };
                },
            },
            id: {
                default: v4(),
                rendered: false,
            },
            'data-background': {
                default: null,
                parseHTML: el => el.style.backgroundColor,
                renderHTML: attrs => {
                    if (attrs['data-background']) {
                        return { style: 'background-color:' + attrs['data-background'] };
                    } else {
                        return {}
                    }
                },
            },
            'data-emoji': {
                default: null,
                parseHTML: el => el.getAttribute('data-emoji'),
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'section.callout',
            },
            {
                tag: 'section.aquablock',
            },
            {
                tag: 'section.redblock',
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        return [
            'section',
            mergeAttributes(HTMLAttributes, { class: 'callout' }),
            ['span', !node.attrs['data-emoji'] ? { class: 'icon ' + node.attrs.type } : {}, node.attrs['data-emoji'] || ''],
            ['div', {}, 0],
        ];
    },
});
