import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonUnderline = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const toggleUnderline = e => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);
        editor?.chain().focus().toggleUnderline().run();
    };

    return (
        <MenuButton
            tooltip="Подчеркнутый"
            icon={Icons.EditorIconUnderline}
            onClick={toggleUnderline}
            selected={editor?.isActive('underline') ?? undefined}
        ></MenuButton>
    );
};
