import React, { useState, useCallback } from 'react';
import MultiChart from 'components/statistic/resources-chart';
import Loading from 'uikit/loading';
import Table from 'uikit/table';
import ReportsFilter from 'uikit/filter/reports-filter';
import Icon, { Icons } from 'uikit/icon';
import Pagination from 'uikit/pagination/pagination';
import cx from './reports-component.module.scss';
import { barChartOptions } from 'components/statistic/charts-options';

export const ReportsComponent = ({
    isEmpty = true,
    isLoading = false,
    total = 0,
    columns = [],
    actions = [],
    data = [],
    filters = [],
    charts = [],
    onDownload = () => {},
    onPaginate = () => {},
    onChangeFilters = () => {}
}) => {

    const [tableCount, setTableCount] = useState(50);
    const [tableOffset, setTableOffset] = useState(0);
    const [tableFilters, setTableFilters] = useState({});

    const onTablePaginate = useCallback(
        (offset, count, filtersData = tableFilters) => {
            setTableOffset(offset);
            setTableCount(count);

            onPaginate(Math.floor(offset / count), count, filtersData);
        },
        [onPaginate, tableFilters]
    );

    const onTableFilters = useCallback(
        (filtersData) => {
            setTableFilters(filtersData);
            onTablePaginate(tableOffset, tableCount, filtersData);
        },
        [onTablePaginate, tableCount, tableOffset]
    );
    
    return (
        <>
            <ReportsFilter filters={filters} onChange={onChangeFilters} onSubmit={onTableFilters} onDownload={onDownload} />
            {charts.length
                ? charts.map((chart, idx) => {
                      return (
                          <div key={idx} className={cx.chart}>
                              <MultiChart
                                  title={chart.title}
                                  chartType="bar"
                                  chartTypes={['bar']}
                                  labels={chart.labels}
                                  data={chart.datasets}
                                  options={barChartOptions}
                              />
                          </div>
                      );
                  })
                : null}
            <div className={cx.table}>
                <div className={cx.body}>
                    {isEmpty && !isLoading && (
                        <div className={cx.empty}>
                            <Icon type={Icons.FILTER} width={41} height={41} />
                            <div>Данные не сформированы</div>
                            <div>Сформируйте отчет для отображения данных</div>
                        </div>
                    )}
                    {isEmpty && isLoading && (
                        <div className={cx.loader}>
                            <Loading withOverlay={false} />
                        </div>
                    )}
                    {!isEmpty && (
                        <div className={cx.tableWrapper}>
                            {isLoading && (
                                <div className={cx.loader}>
                                    <Loading withOverlay={false} />
                                </div>
                            )}
                            <Table columns={columns} actions={actions} data={data} />
                            <div className={cx.pagination}>
                                <Pagination total={total} count={tableCount} offset={tableOffset} onPaginate={onTablePaginate} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};