import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactHtmlParser from 'react-html-parser';
import * as levenshtein from 'fast-levenshtein';
import { parse } from 'query-string';

import { useEventListener, useGlobalContext, useMessage } from 'lib/hooks';
import { selectUsers, selectUser, selectVoiceSearchEnabled } from 'slice/authSlice';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import FilterIndicator from 'uikit/filterIndicator/filterIndicator';
import GlobalFilter from 'uikit/global-filter';
import { setDefaultFilters, selectFilters, setFilter, initFilter } from 'slice/globalSearchSlice';
import api from 'api';
import cx from './search.module.scss';
import { useSearch } from 'lib/hooks/useSearch';

const Search = ({ className, title, isTicker }) => {
    const { platform } = useGlobalContext();
    const { addError } = useMessage();

    const searchInputRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();

    let store = useSelector(selectFilters);
    const dispatch = useDispatch();

    const selector = useSelector((state) => state);
    const user = useSelector(selectUser);
    const isVoiceSearchEnabled = useSelector(selectVoiceSearchEnabled);

    const [autoCompleteResult, setAutoCompleteResult] = useState([]);
    const searchResult = useMemo(() => autoCompleteResult.filter((i) => i.type === 'searchResult'), [autoCompleteResult]);
    const historyResult = useMemo(() => autoCompleteResult.filter((i) => i.type === 'historyResult'), [autoCompleteResult]);

    const [defSearch, setDefSearch] = useState(false);
    const [onSearch, setOnSearch] = useState(false);

    const [isSearch, setIsSearch] = useState(false);
    const [isFilters, setIsFilters] = useState(false);

    const [fromDate, setFromDate] = useState(new Date(0));
    const [toDate, setToDate] = useState(new Date());

    const [authors, setAuthors] = useState([]);
    const [users, setUsers] = useState(null);
    const [selectedProjects, setSelectedProjects] = useState([]);

    const [timePeriod, setTimePeriod] = useState(null);
    const [timePeriodMode, setTimePeriodMode] = useState(false);

    const [whereLooking, setWhereLooking] = useState({
        includeNews: true,
        includeArticles: true,
        includeArchivedNews: true,
        includeArchivedArticles: true,
        includeFiles: true,
    });

    const [userData, setUserData] = useState({});
    const [tags, setTags] = useState([]);

    const [minusWords, setMinusWords] = useState([]);
    const [attributes, setAttributes] = useState([]);

    const [isInit, setIsInit] = useState(false);

    const [isSetFilter, setIsSetFilter] = useState(false);
    const [isGetFilter, setIsGetFilter] = useState(false);

    const [value, setValue] = useState({});

    const onChangeSearch = useCallback(
        async (text) => {
            try {
                openAutocompleteWindow();

                let tmp = text.split(' ');

                for (let i = 0; i < tmp.length; i++) {
                    if (levenshtein.get(tmp[i], 'доставка') <= 2) {
                        tmp[i] = 'доставка';
                    }
                }

                tmp = tmp.join(' ');

                let queryResultTmp = await api.search.searchAutocomplete(tmp.trim());
                let objectResult = [];

                queryResultTmp.forEach((item) => {
                    objectResult.push({
                        text: item,
                        isActive: false,
                        type: 'searchResult',
                    });
                });
                setAutoCompleteResult((prevResult) => {
                    return [...objectResult, ...prevResult.filter((i) => i.type === 'historyResult')];
                });
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        },
        [addError]
    );

    const { query, setQuery, status, start, stop } = useSearch({ isVoice: true, onChangeSearch });

    useEffect(() => {
        setTags(store.tags);
        setValue((value) => ({ ...value, tags: store.tags }));
    }, [store]);

    const getFilterStore = useCallback(() => {
        if (isGetFilter) {
            if (store.timePeriod !== timePeriod) {
                setTimePeriod(store.timePeriod);
            }
            if (store.tags.length !== tags.length) {
                setTags(store.tags);
            }
            if (store.minusWords.length !== minusWords.length) {
                setMinusWords(store.minusWords);
            }
            if (store.attributes.length !== attributes.length) {
                setAttributes(store.attributes);
            }
            if (store.authors.length !== authors.length) {
                setAuthors(store.authors);
            }
            if (
                store.whereLooking.includeNews !== whereLooking.includeNews ||
                store.whereLooking.includeArchivedArticles !== whereLooking.includeArchivedArticles ||
                store.whereLooking.includeFiles !== whereLooking.includeFiles ||
                store.whereLooking.includeArticles !== whereLooking.includeArticles ||
                store.whereLooking.includeArchivedNews !== whereLooking.includeArchivedNews
            ) {
                const storeWhereLooking = { ...store };
                setWhereLooking(storeWhereLooking.whereLooking);
            }

            if (store.projects.length !== selectedProjects.length) {
                setSelectedProjects(store.projects);
                // setSelectedProjects(value.projects);
            }
            setIsGetFilter(false);
        }
    }, [store, timePeriod, tags, minusWords, attributes, authors, whereLooking, isGetFilter, selectedProjects]);

    const setFilterStore = useCallback(async () => {
        if (isSetFilter) {
            let filter = {
                timePeriod: timePeriod,
                tags: tags,
                minusWords: minusWords,
                attributes: attributes,
                authors: authors,
                projects: selectedProjects,
                whereLooking: whereLooking,
                init: true,
            };
            dispatch(setFilter(filter));
            setIsSetFilter(false);
        }
    }, [dispatch, attributes, authors, minusWords, tags, timePeriod, whereLooking, isSetFilter, selectedProjects]);

    const openAutocompleteWindow = () => {
        setOnSearch(true);
    };

    const closeSearch = () => {
        setAutoCompleteResult([]);
        setOnSearch(false);
    };
    const deleteHtml = (text) => {
        return text.replace(/<\/?[^>]+>/g, '');
    };

    const onKeyDown = (e) => {
        setDefSearch(false);
        const result = autoCompleteResult;

        if (e.keyCode === 13) {
            e.preventDefault();

            let activeIndex = result.filter((i) => (!query ? true : i.type === 'searchResult')).findIndex((item) => item.isActive === true);

            if (activeIndex === -1) {
                search();
            } else {
                search(result[activeIndex]['type'] === 'searchResult' ? result[activeIndex].text : result[activeIndex].query);
            }
        }

        if (result.length > 0) {
            if (e.keyCode === 40) {
                e.preventDefault();
                let i = result.filter((i) => (!query ? true : i.type === 'searchResult')).findIndex((item) => item.isActive === true);

                if (i === -1) {
                    result[0].isActive = true;
                } else if (i < result.filter((i) => (!query ? true : i.type === 'searchResult')).length - 1) {
                    result[i].isActive = false;
                    result[i + 1].isActive = true;
                } else if (i === result.filter((i) => (!query ? true : i.type === 'searchResult')).length - 1 && !query) {
                    result[i].isActive = false;
                    result[0].isActive = true;
                } else if (defSearch === true && i === -1) {
                    result[0].isActive = true;
                } else if (i === result.filter((i) => (!query ? true : i.type === 'searchResult')).length - 1 && query) {
                    result[i].isActive = false;
                    setDefSearch(true);
                }

                setAutoCompleteResult(Object.assign([], result));
            } else if (e.keyCode === 38) {
                e.preventDefault();
                let i = result.filter((i) => (!query ? true : i.type === 'searchResult')).findIndex((item) => item.isActive === true);

                if (i === -1 && defSearch === false) {
                    result[0].isActive = true;
                } else if (i > 0) {
                    result[i].isActive = false;
                    result[i - 1].isActive = true;
                } else if (defSearch === true) {
                    result[result.filter((i) => (!query ? true : i.type === 'searchResult')).length - 1].isActive = true;
                    setDefSearch(false);
                } else if (i === 0 && !query) {
                    result[0].isActive = false;
                    result[result.length - 1].isActive = true;
                } else if (i === 0 && query) {
                    result[0].isActive = false;
                    setDefSearch(true);
                }

                setAutoCompleteResult(Object.assign([], result));
            }
        }

        if (e.keyCode === 27) {
            e.preventDefault();

            setAutoCompleteResult([]);
            setOnSearch(false);
        }
    };
    const inputOnFocus = (e) => {
        e.target.setAttribute('autocomplete', 'off');
        setIsSearch(true);
    };
    const onChangeFromDate = (date) => {
        setFromDate(new Date(date));
    };
    const onChangeToDate = (date) => {
        const toDate = new Date(date);
        toDate.setHours(23, 59, 59);
        setToDate(toDate);
    };
    const onResetDates = () => {
        setTimePeriodMode(false);
        setTimePeriod(null);
        setFromDate(new Date(0));
        setToDate(new Date());

        let tmp = JSON.parse(JSON.stringify(value));
        tmp.timePeriod = null;
        setValue(tmp);
    };

    const acrossAllPlaces = () => {
        onResetWhereLooking();
    };
    const removeAcrossAllPlaces = () => {
        setWhereLooking({
            includeNews: false,
            includeArticles: false,
            includeArchivedNews: false,
            includeArchivedArticles: false,
            includeFiles: false,
        });
    };

    const onChangeAuthors = (item) => {
        if (item) {
            setAuthors(item);
        } else {
            setAuthors([]);
        }
    };
    const onResetAuthors = () => {
        setAuthors([]);
    };

    const saveActiveFilter = () => {
        let filter = {
            timePeriod: timePeriod,
            tags: tags,
            minusWords: minusWords,
            attributes: attributes,
            authors: authors,
            whereLooking: whereLooking,
            projects: selectedProjects,
        };
        localStorage.setItem('searchFilter', JSON.stringify(filter));
    };
    const deleteSaveActiveFilter = () => {
        localStorage.removeItem('searchFilter');
        onResetAll();
    };

    const onChangeTimePeriod = (item) => {
        setTimePeriod(item);

        if (item['value'] === 'period') {
            setTimePeriodMode(true);
        } else {
            setTimePeriodMode(false);
        }
    };
    const onResetTimePeriod = () => {
        setTimePeriod(null);
        setTimePeriodMode(false);
    };

    const onResetTags = () => {
        setTags([]);
    };
    const onResetWords = () => {
        setMinusWords([]);
    };
    const onResetAttributes = () => {
        setAttributes([]);
    };
    const onResetProjects = () => {
        setSelectedProjects([]);
    };
    const onResetWhereLooking = () => {
        setWhereLooking({
            includeNews: true,
            includeArticles: true,
            includeArchivedNews: true,
            includeArchivedArticles: true,
            includeFiles: true,
        });
    };
    const onResetPlaces = () => {
        setWhereLooking({
            includeNews: true,
            includeArticles: true,
            includeArchivedNews: true,
            includeArchivedArticles: true,
            includeFiles: true,
        });
    };
    const onResetAll = () => {
        onResetDates();
        onResetAuthors();
        onResetAttributes();
        onResetWords();
        onResetTimePeriod();
        onResetTags();
        onResetPlaces();
        onResetProjects();
        onResetWhereLooking();
        setValue({});

        dispatch(setDefaultFilters());
        //search('', 'no_filter');
    };

    const onChangeTags = (data) => {
        setTags(data);
    };
    const onChangeWhereLooking = (data) => {
        setWhereLooking(data);
    };
    const onChangeFilter = (value) => {
        setValue(value);
    };
    const onChangeProjects = (item) => {
        if (item) {
            setSelectedProjects(item);
            setValue({ projects: item });
        } else {
            setSelectedProjects([]);
            setValue({ projects: [] });
        }
    };

    const getDefaultFilterString = () => {
        return '&mode=MORPHOLOGY&whereLooking=Everywhere';
    };
    const getFilterString = () => {
        let filters = '';

        // filters = '&mode=' + searchMode;
        filters = '&mode=MORPHOLOGY';

        //filters += '&whereLooking=' + whereLooking;

        if (timePeriod !== null && timePeriod['value'] !== 'period') {
            filters += '&timePeriod=' + timePeriod['value'];
        } else if (timePeriod !== null && timePeriod['value'] === 'period') {
            filters += '&fromDate=' + fromDate.toISOString() + '&toDate=' + toDate.toISOString();
        }

        if (tags.length > 0) {
            filters += '&tags=' + tags.join(',');
        }

        if (attributes.length > 0) {
            filters += '&attributes=' + attributes.join(',');
        }

        if (minusWords.length > 0) {
            filters += '&minusWords=' + minusWords.join(',');
        }

        if (authors && authors.length > 0) {
            filters += '&authors=';

            for (let i = 0; i < authors.length; i++) {
                filters += authors[i].value + ',';
            }

            filters = filters.substring(0, filters.length - 1);

            filters += '&nameAuthors=';

            for (let i = 0; i < authors.length; i++) {
                filters += authors[i].label + ',';
            }

            filters = filters.substring(0, filters.length - 1);
        }

        if (selectedProjects && selectedProjects.length > 0) {
            filters += '&projectIds=';

            for (const p of selectedProjects) {
                filters += p.value + ',';
            }

            filters = filters.substring(0, filters.length - 1);
        }

        return filters;
    };

    const selectAutocomplete = (text) => search(text);

    const onSearchHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();

        let activeIndex = autoCompleteResult
            .filter((i) => (!query ? true : i.type === 'searchResult'))
            .findIndex((item) => item.isActive === true);

        if (activeIndex === -1) {
            search();
        } else {
            search(
                autoCompleteResult[activeIndex]['type'] === 'searchResult'
                    ? autoCompleteResult[activeIndex].text
                    : autoCompleteResult[activeIndex].query
            );
        }
    };

    const search = (text = '', mode = 'default') => {
        setIsSetFilter(true);
        setFilterStore();

        let searchText = text === '' ? query : text;

        if (searchText.trim() === '') {
            return;
        }

        saveSearchHistory(searchText.trim());

        let filterString;

        if (mode === 'default') {
            filterString = getFilterString();
        } else if (mode === 'no_filter') {
            filterString = getDefaultFilterString();
        }

        navigate(`/search?query=${encodeURIComponent(deleteHtml(searchText.trim()))}${filterString}`, { replace: true });
        setQuery(deleteHtml(searchText).trim());

        setAutoCompleteResult([]);
        setOnSearch(false);
    };
    const saveSearchHistory = (text) => {
        const _historyResult = autoCompleteResult.filter((item) => item.type === 'historyResult');
        const matchIndex = _historyResult.findIndex((i) => i.query === text);

        if (matchIndex !== -1) {
            _historyResult.splice(matchIndex, 1);
        }

        if (_historyResult.length >= 8 && matchIndex === -1) {
            _historyResult.splice(0, 1);
        }

        _historyResult.push({ query: text, isActive: false, type: 'historyResult' });
        localStorage.setItem(userData['login'] + '_searchHistory', JSON.stringify(_historyResult));
        setAutoCompleteResult([...searchResult, ..._historyResult]);
    };

    const getFilterCounter = () => {
        let counter = 0;

        if (
            !whereLooking.includeNews ||
            !whereLooking.includeArticles ||
            !whereLooking.includeArchivedNews ||
            !whereLooking.includeArchivedArticles ||
            !whereLooking.includeFiles
        )
            counter++;

        if (timePeriod !== null) counter++;

        if (authors !== null && authors.length > 0) counter++;

        if ([selectedProjects, value.projects].some((items) => {
            return items && items.length > 0
        })) {
            counter++;
        }

        if (attributes !== null && attributes.length > 0) {
            counter++;
        }

        if (tags !== null && tags.length > 0) {
            counter++;
        }

        if (minusWords !== null && minusWords.length > 0) {
            counter++;
        }
        return counter;
    };

    const loadProjectOptions = async (search, loadedOptions, { page }) => {
        let options = [];
        const projectsResponse = await api.project.getProjects(page, 20, 'title,desc', search);

        options = projectsResponse.content.map((project) => ({ label: project.title, value: project.id }));

        return {
            options: options,
            hasMore: page < projectsResponse.totalPages - 1,

            additional: {
                page: page + 1,
            },
        };
    };

    const focusSearch = () => {
        searchInputRef.current.focus();
        openAutocompleteWindow();
    };

    useEventListener('keydown', (e) => e.key === 'Escape' && closeSearch(), document);
    useEventListener(
        'click',
        (e) => {
            if (e.path?.find?.((item) => item.id === 'searchInput') === undefined) {
                closeSearch();
                stop();
            }
        },
        window
    );

    useEffect(() => {
        let filter = localStorage.getItem('searchFilter');
        filter = JSON.parse(filter);

        if (filter) {
            setTimePeriod(filter.timePeriod);
            setTags(filter.tags);
            setMinusWords(filter.minusWords);
            setAttributes(filter.attributes);
            setAuthors(filter.authors);
            setWhereLooking(filter.whereLooking);
            setSelectedProjects(filter.projects);
            dispatch(setFilter(filter));
            setIsInit(true);
        }

        dispatch(initFilter());
    }, [location.pathname, dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await selectUsers(selector);
            setUsers(
                response.content.map((user) => {
                    return { label: user.firstName + ' ' + user.lastName, value: user.id, avatar: user.avatar };
                })
            );
        };
        fetchData();
    }, [selector]);

    useEffect(() => {
        setIsGetFilter(true)
        getFilterStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInit, store]);

    useEffect(() => {
        setFilterStore();
    }, [isInit, setFilterStore]);

    useEffect(() => {
        const fetchData = async () => {
            const searchParams = parse(location.search);

            if (searchParams['query']) {
                setQuery(searchParams['query']);
            } else {
                setQuery('');
            }

            let tmpUserData = { ...user };
            setUserData(tmpUserData);

            let tmpSearchHistory = localStorage.getItem(tmpUserData['login'] + '_searchHistory');
            if (tmpSearchHistory !== null) {
                setAutoCompleteResult(
                    JSON.parse(tmpSearchHistory)
                        .map((value) => ({ ...value, isActive: false, type: 'historyResult' }))
                        .reverse()
                );
            }
        };
        fetchData();
    }, [location, user, setQuery]);

    return (
        <div className={classNames(cx.search, className, { [cx.mobile]: platform === 'mobile' })}>
            <GlobalFilter
                value={value}
                isOpen={isFilters}
                timePeriod={timePeriod}
                tags={tags}
                authors={authors}
                projects={selectedProjects}
                onChangeTags={onChangeTags}
                authorsOptions={users}
                whereLooking={whereLooking}
                timePeriodMode={timePeriodMode}
                acrossAllPlaces={acrossAllPlaces}
                removeAcrossAllPlaces={removeAcrossAllPlaces}
                fromDate={fromDate}
                onResetAuthors={onResetAuthors}
                onChangeTimePeriod={onChangeTimePeriod}
                toDate={toDate}
                saveActiveFilter={saveActiveFilter}
                deleteSaveActiveFilter={deleteSaveActiveFilter}
                onChangeAuthors={onChangeAuthors}
                acceptFilter={search}
                onResetAll={onResetAll}
                onChangeFromDate={onChangeFromDate}
                onChangeToDate={onChangeToDate}
                onChangeWhereLooking={onChangeWhereLooking}
                onChange={onChangeFilter}
                close={() => setIsFilters(false)}
                onChangeProjects={onChangeProjects}
                loadProjectsOptions={loadProjectOptions}
                onResetProjects={onResetProjects}
            />
            <div className={classNames(cx.searchInput, isSearch ? cx.searchInputActive : null)} onClick={focusSearch}>
                <IconButton
                    className={cx.searchIcon}
                    icon={<Icon type={Icons.SEARCH} width={13} height={13} />}
                    onClick={onSearchHandler}
                />
                <input
                    id="searchInput"
                    type="text"
                    placeholder={title}
                    value={query ?? ""}
                    autoComplete="off"
                    ref={searchInputRef}
                    onFocus={inputOnFocus}
                    onBlur={() => setIsSearch(false)}
                    onChange={(e) => {
                        setQuery(e.target.value);
                        onChangeSearch(e.target.value);
                    }}
                    onKeyDown={(e) => onKeyDown(e)}
                />
                {(isVoiceSearchEnabled !== undefined && isVoiceSearchEnabled) && (
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            if (status) {
                                stop();
                            } else {
                                start();
                                setOnSearch(false);
                            }
                        }}
                    >
                        {status ? (
                            <Icon type={Icons.MICROPHONE_ON} width={20} height={20} />
                        ) : (
                            <Icon type={Icons.MICROPHONE_OFF} width={20} height={20} />
                        )}
                    </div>
                )}
            </div>
            <div id={'searchFilterContainer'} className={cx.filterContainer}>
                <IconButton
                    className={cx.countFilter}
                    icon={
                        <Icon
                            type={Icons.FILTER}
                            width={15}
                            height={15}
                            tooltip="Фильтры"
                            color={getFilterCounter() > 0 ? 'blue' : null}
                            onClick={() => setIsFilters(true)}
                        />
                    }
                />
                {getFilterCounter() > 0 && (
                    <div className={cx.mobileIndicator}>{getFilterCounter()}</div>
                )}
                {getFilterCounter() > 0 && (
                    <div className={cx.desktopIndicator}>
                        <FilterIndicator
                            label="Фильтры"
                            text={getFilterCounter()}
                            click={() => setIsFilters(true)}
                            onRemove={() => onResetAll()}
                        />
                    </div>
                )}
            </div>
            {onSearch && platform === 'mobile' && autoCompleteResult.length > 0 && <div className={classNames(cx.mobileOverlay, { [cx.mobileOverlayTicker]: isTicker })} />}
            {onSearch && (platform === 'desktop' || autoCompleteResult.length > 0) && (
                <div id={'autocompleteWindow'} className={classNames(cx.autoComleteContainer, { [cx.autoComleteContainerTicker]: isTicker })}>
                    {platform !== 'mobile' && (
                        <div className={cx.navigationContainer}>
                            <div className={cx.navigatinItem}>
                                <div className={cx.chartContainer}>
                                    <div className={cx.imgContainer}>
                                        <img alt="" src="/icons/searchArrowUp.svg" />
                                    </div>
                                </div>
                                <div className={cx.chartContainer}>
                                    <div className={cx.imgContainer}>
                                        <img alt="" src="/icons/searchArrowDown.svg" />
                                    </div>
                                </div>
                                <p className={cx.navigationLabel}>Навигация</p>
                            </div>
                            <div className={cx.navigatinItem}>
                                <div className={cx.chartContainer}>
                                    <p className={cx.textContainer}>Enter</p>
                                </div>
                                <p className={cx.navigationLabel}>Перейти</p>
                            </div>
                            <div className={cx.navigatinItem}>
                                <div className={cx.chartContainer}>
                                    <p className={cx.textContainer}>Escape</p>
                                </div>
                                <p className={cx.navigationLabel}>Закрыть</p>
                            </div>
                        </div>
                    )}
                    {searchResult.length > 0 && (
                        <div className={cx.resultContainer}>
                            <p className={cx.resultHeader}>Наиболее релевантные</p>
                            <div className={cx.resultItems}>
                                {searchResult.map((item, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={classNames(cx.resultItem, item.isActive === true ? cx.active : '')}
                                            onClick={() => selectAutocomplete(item.text)}
                                        >
                                            &#160;&#160;&#160;{ReactHtmlParser('<div>' + item.text + '</div>')}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {query === '' && (
                        <div className={cx.resultContainer}>
                            {historyResult.length > 0 && <p className={cx.resultHeader}>Недавно искали</p>}
                            <div className={cx.resultItems}>
                                {historyResult.map((item, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className={classNames(cx.resultItem, item.isActive === true ? cx.active : '')}
                                            onClick={() => selectAutocomplete(item['query'])}
                                        >
                                            <div className={cx.searchIcon}>
                                                <Icon type={Icons.SEARCH} width={13} height={13} />
                                            </div>
                                            &#160;&#160;&#160;{ReactHtmlParser(item['query'])}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                    {query !== '' && (
                        <div onClick={() => search()} className={cx.allMatchesContainer}>
                            <p className={defSearch === true ? cx.active : ''}>&#160;&#160;&#160;Искать все совпадения с «{query}»</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

Search.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
};

Search.defaultProps = {
    className: '',
    title: 'Поиск по системе...',
};

export { Search };
