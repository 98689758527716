import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
//import formatDateTime from 'lib/helpers/formatDateTime';
import MultiChart from 'components/statistic/resources-chart';
import { doughnutChartOptions } from 'components/statistic/charts-options';
//import { lineChartOptions } from 'components/statistic/charts-options';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import cx from './statistic-total-stats-page.module.scss';

const StatisticDashboardsDocuments = () => {
    //const [newsPerDay, setNewsPerDay] = useState(null);
    //const [articlesPerDay, setArticlesPerDay] = useState(null);

    const [articlesCount] = useState(null);
    const [newsCount] = useState(null);

    const [articlesPerProject, setArticlesPerProject] = useState(null);
    const [newsPerProject, setNewsPerProject] = useState(null);

    const [topArticles, setTopArticles] = useState([]);
    const [antiTopArticles, setAntiTopArticles] = useState([]);

    // const fetchNewsPerDay = useCallback(async (cancelToken) => {
    //     try {
    //         const res = await api.stats.getNewsPerDay(cancelToken.token);
    //         const data = { labels: [], datasets: [] };

    //         res.forEach((news) => {
    //             data.labels.push(formatDateTime(new Date(news.date)));
    //             data.datasets.push(news.numberOfNews);
    //         });

    //         setNewsPerDay(data);

    //         if (res && res.length) {
    //             setNewsCount(res[res.length - 1].numberOfDayNews);
    //         } else {
    //             setNewsCount(0);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, []);

    // const fetchArticlesPerDay = useCallback(async (cancelToken) => {
    //     try {
    //         const res = await api.stats.getArticlesPerDay(cancelToken.token);
    //         const data = { labels: [], datasets: [] };

    //         res.forEach((news) => {
    //             data.labels.push(formatDateTime(new Date(news.date)));
    //             data.datasets.push(news.numberOfArticles);
    //         });

    //         setArticlesPerDay(data);

    //         if (res && res.length) {
    //             setArticlesCount(res[res.length - 1].numberOfDayArticles);
    //         } else {
    //             setArticlesCount(0);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, []);

    const fetchTopProjectByArticles = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopProjectByArticles(cancelToken.token);
            const data = { labels: [], articles: [] };

            res.forEach((project) => {
                data.labels.push(project.projectTitle);
                data.articles.push(project.numberOfProjectItems);
            });

            setArticlesPerProject(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopProjectByNews = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopProjectByNews(cancelToken.token);
            const data = { labels: [], news: [] };

            res.forEach((project) => {
                data.labels.push(project.projectTitle);
                data.news.push(project.numberOfProjectItems);
            });

            setNewsPerProject(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopArticles = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopArticles('active', cancelToken.token);
            setTopArticles(res);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchAntiTopArticles = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopArticles('inactive', cancelToken.token);
            setAntiTopArticles(res);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source(); 

        fetchTopProjectByArticles(cancelToken);
        fetchTopProjectByNews(cancelToken);
        fetchTopArticles(cancelToken);
        fetchAntiTopArticles(cancelToken);
        //fetchNewsPerDay(cancelToken);
        //fetchArticlesPerDay(cancelToken);

        return () => {
          cancelToken.cancel();
        }
    }, [
        fetchTopProjectByArticles, 
        fetchTopProjectByNews, 
        fetchTopArticles, 
        fetchAntiTopArticles, 
        //fetchNewsPerDay, 
        //fetchArticlesPerDay,
    ]);

    return (
        <>
            <div className={cx.header}>
                <h1>Документы - Мониторинг</h1>
            </div>
            <div className={cx.chartRow}>
                {/*
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Статей в системе"
                        chartType={'line'}
                        chartTypes={['line']}
                        labels={articlesPerDay?.labels}
                        data={articlesPerDay?.datasets}
                        options={lineChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Новостей в системе"
                        chartType={'line'}
                        chartTypes={['line']}
                        labels={newsPerDay?.labels}
                        data={newsPerDay?.datasets}
                        options={lineChartOptions}
                    />
                </div>
                */}
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Статьи в проектах"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={articlesPerProject?.labels}
                        data={articlesPerProject?.articles}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Новости в проектах"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={newsPerProject?.labels}
                        data={newsPerProject?.news}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.sessionTimeWrapper}>
                        <div className={cx.sessionTimeTitle}>Статей создано за последние сутки</div>
                        <div className={cx.sessionTime}>{articlesCount}</div>
                    </div>
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.sessionTimeWrapper}>
                        <div className={cx.sessionTimeTitle}>Новостей создано за последние сутки</div>
                        <div className={cx.sessionTime}>{newsCount}</div>
                    </div>
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Топ-10 статей</h3>
                        {topArticles.length === 0 && <div className={cx.listEmpty}>Нет данных для отображения</div>}
                        {topArticles.map((item) => {
                            return (
                                <div key={item.id} className={cx.requestsRow}>
                                    <div>
                                        <Icon type={Icons.SEARCH} /> {item.article.title}
                                    </div>
                                    <div>{item.numberOfAppeals ? item.numberOfAppeals : 0} обращений</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Антитоп статей</h3>
                        {antiTopArticles.length === 0 && <div className={cx.listEmpty}>Нет данных для отображения</div>}
                        {antiTopArticles.map((item) => {
                            return (
                                <div key={item.id} className={cx.requestsRow}>
                                    <div>
                                        <Icon type={Icons.SEARCH} /> {item.article.title}
                                    </div>
                                    <div>{item.numberOfAppeals ? item.numberOfAppeals : 0} обращенией</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticDashboardsDocuments;
