import React, { useRef } from 'react';
import { useEditorContext } from '../../context';
import { MenuButtonColorPicker } from './MenuButtonColorPicker';
import { Icons } from 'uikit/icon';
import { DropdownList } from 'uikit/dropdown-list/dropdown-list';
import { MenuButton } from './MenuButton';

export const MenuButtonColorText = ({ mode, currentMenu, setCurrentMenu }) => {
    const dropdownRef = useRef(null);
    const editor = useEditorContext();
    const [value, setValue] = React.useState('#162745');

    const setTextColor = color => {
        setValue(color);

        editor?.commands.setColor(color);
        editor?.view.focus();
    };

    const handleClose = () => {
        dropdownRef.current.hidemenu();
    };

    return (
        <DropdownList
            innerRef={dropdownRef}
            mode={mode}
            name="color-text"
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            position={mode === 'DESKTOP' ? 'left' : 'right'}
            toggler={showMenu => (
                <MenuButton
                    icon={Icons.EditorIconColorText}
                    tooltip="Цвет текста"
                    onClick={e => {
                        showMenu(e);
                        setValue(editor?.getAttributes('textStyle').color || '#162745');
                    }}
                />
            )}
        >
            <MenuButtonColorPicker onChange={setTextColor} onClose={handleClose} value={value} />
        </DropdownList>
    );
};
