const ResourcePermissions = {
    document: {
        // Project/section/article management permissions.
        READ: 'READ',
        EDIT: 'EDIT',
        DELETE_REQUEST: 'DELETE_REQUEST',
        DELETE: 'DELETE',
        CREATE: 'CREATE',

        ARTICLE_READ: 'ARTICLE_READ',
        ARTICLE_EDIT: 'ARTICLE_EDIT',
        ARTICLE_DELETE: 'ARTICLE_DELETE',
        ARTICLE_DELETE_REQUEST: 'ARTICLE_DELETE_REQUEST',
        ARTICLE_CREATE: 'ARTICLE_CREATE',

        PROJECT_READ: 'PROJECT_READ',
        PROJECT_EDIT: 'PROJECT_EDIT',
        PROJECT_DELETE: 'PROJECT_DELETE',
        PROJECT_DELETE_REQUEST: 'PROJECT_DELETE_REQUEST',
        PROJECT_CREATE: 'PROJECT_CREATE',

        SECTION_READ: 'SECTION_READ',
        SECTION_EDIT: 'SECTION_EDIT',
        SECTION_DELETE: 'SECTION_DELETE',
        SECTION_DELETE_REQUEST: 'SECTION_DELETE_REQUEST',
        SECTION_CREATE: 'SECTION_CREATE',

        // Project/section/article archive permissions.
        RESOURCE_ARCHIVE_MOVE: 'RESOURCE_ARCHIVE_MOVE',
        RESOURCE_ARCHIVE_RESTORE: 'RESOURCE_ARCHIVE_RESTORE',

        LOCK: 'LOCK'
        // All these actions above address only the respective permissions for either projects, sections or articles.
    },
    // User management permissions.
    user: {
        USER_READ: 'USER_READ',
        USER_EDIT: 'USER_EDIT',
        USER_CREATE: 'USER_CREATE',
        USER_DELETE: 'USER_DELETE'
    },
    // File management permissions.
    file: {
        FILE_READ: 'FILE_READ',
        FILE_EDIT: 'FILE_EDIT',
        FILE_CREATE: 'FILE_CREATE',
        FILE_DELETE: 'FILE_DELETE'
    },
    // News management permissions.
    news: {
        NEWS_READ: 'NEWS_READ',
        NEWS_EDIT: 'NEWS_EDIT',
        NEWS_CREATE: 'NEWS_CREATE',
        NEWS_DELETE: 'NEWS_DELETE'
    },
    // Group management permissions.
    group: {
        GROUP_READ: 'GROUP_READ',
        GROUP_EDIT: 'GROUP_EDIT',
        GROUP_CREATE: 'GROUP_CREATE',
        GROUP_DELETE: 'GROUP_DELETE'
    },
    // permission to read the activity logs
    ACTIVITY_READ: 'ACTIVITY_READ',
    // permission to see the statistics page
    STATS_READ: 'STATS_READ',
    // permission to change the system settings
    SYSTEM_SETTINGS: 'SYSTEM_SETTINGS'
};
export default ResourcePermissions;