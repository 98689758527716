export function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

export function hslToHsb(hsl) {
    let { h, s, l } = hsl;
    const br = l + (s / 100) * Math.min(l, 100 - l);
    s = br === 0 ? 0 : 2 * (1 - l / br) * 100;
    return { h, s, br };
}

export function hsbToHsl(hsb) {
    let { h, s, br } = hsb;
    const l = (br / 100) * (100 - s / 2);
    s = l === 0 || l === 1 ? 0 : ((br - l) / Math.min(l, 100 - l)) * 100;
    return { h, s, l };
}

export function isValidRGB(rgb) {
    let i = 0,
        itm,
        M = rgb.replace(/ +/g, "").match(/(rgba?)|(\d+(\.\d+)?%?)|(\.\d+)/g);
    if (M && M.length > 3) {
        while (i < 3) {
            itm = M[++i];
            if (itm.indexOf("%") !== -1) {
                itm = Math.round(parseFloat(itm) * 2.55);
            } else itm = parseInt(itm);
            if (itm < 0 || itm > 255) return NaN;
            M[i] = itm;
        }
        if (rgb.indexOf("rgba") === 0) {
            if (M[4] === undefined || M[4] < 0 || M[4] > 1) return NaN;
        } else if (M[4]) return NaN;
        return M[0] + "(" + M.slice(1).join(",") + ")";
    }
    return NaN;
}

export function isValidHSL(hsl) {
    const cssNumberMatcher = /[+-]?(?=\.\d|\d)\d*(?:\.\d+)?(?:[eE][+-]?\d+)?/;
    const separatorMatcher = /(?=[,\s])\s*(?:,\s*)?/;
    // eslint-disable-next-line no-useless-escape
    const alphaSeparatorMatcher = /\s*[,\/]\s*/;
    const num = cssNumberMatcher.source;
    const sep = separatorMatcher.source;
    const asep = alphaSeparatorMatcher.source;

    const hslMatcher = new RegExp(
        `hsla?\\(\\s*(${num}(?:deg|rad|grad|turn)?)${sep}(${num})%${sep}(${num})%(?:${asep}(${num}%?))?\\s*\\)`,
        "i"
    );

    const match = hslMatcher.exec(hsl);
    return match?.slice(1) ?? null;
}
