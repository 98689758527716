const desktopStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 8000,
        padding: 0,
        background: '#FFFFFF',
        border: '1px solid #EAEDF3',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: 5
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(38, 38, 38, 0.65)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'auto',
        zIndex: 10000,
    }
};

const mobileStyles = {
    content: {
        zIndex: 8000,
        padding: 0,
        background: '#FFFFFF',
        border: '1px solid #EAEDF3',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: 5,
        margin: '50px 16px',
        position: 'initial',
        inset: 0
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(38, 38, 38, 0.65)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'auto'
    }
};

export { desktopStyles, mobileStyles };
