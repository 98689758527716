import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cx from './icon-button.module.scss';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';

export const IconButtonType = {
    NORMAL: 'normal',
    LIGHT: 'light',
    DARK: 'dark',
    TABLE: 'table',
    ACTIVE: 'active',
};

export const IconButtonStatus = {
    NONE: 'none',
    RED: 'red',
    GREEN: 'green',
    HOVER_RED: 'hoverRed'
};

const IconButton = ({ id, className, buttonId, label, type, status, zIndex, icon, onClick, fullWidth, count,
                    hoverColor, link }) => {
    return (
        <AdaptiveLink link={link} id={id} data-testid={id} className={classNames(cx[type], cx[status], cx[`hover-${hoverColor}`] , {[cx.fullWidth]: fullWidth}, className)}>
            <button id={buttonId} onClick={onClick} style={{ zIndex: zIndex }}>
                {icon}
                {label && <span>{label}</span>}
                {status !== 'none' && count !== 0 && <span className={cx.status}>{count}</span>}
            </button>
        </AdaptiveLink>
    );
};

IconButton.defaultProps = {
    id: null,
    className: null,
    buttonId: null,
    label: '',
    type: IconButtonType.NORMAL,
    status: IconButtonStatus.NONE,
    zIndex: 0,
    fullWidth: false,
    onClick: () => {

    },
    hoverColor: 'blue',
};

IconButton.propTypes = {
    id: PropTypes.string,
    classNames: PropTypes.string,
    buttonId: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    zIndex: PropTypes.number,
    onClick: PropTypes.func,
    fullWidth: PropTypes.bool,
    hoverColor: PropTypes.string
};

export default IconButton;
