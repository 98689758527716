import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useMessage } from 'lib/hooks';
import { ChatGroupAdd } from 'components/chat';
import AvatarEditor from 'components/avatar-editor/avatar-editor';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import Input from 'uikit/input';
import Button from 'uikit/button';
import api from 'api';
import cx from './chat-group.module.scss';

export default function ChatGroup({ users = [], chat = null, onOpenChat, onClose }) {
    const { addError } = useMessage();

    const [isChatGroupAdd, setIsChatGroupAdd] = useState(false);
    const [isChatGroupAdminsAdd, setIsChatGroupAdminsAdd] = useState(false);

    const [groups, setGroups] = useState([]);

    const [groupsData, setGroupsData] = useState([]);
    const [usersData, setUsersData] = useState([]);

    const [isAvatarModal, setIsAvatarModal] = useState(false);
    const [avatarModal, setAvatarModal] = useState(null);

    const [groupAvatar, setGroupAvatar] = useState(null);
    const [groupTitle, setGroupTitle] = useState('');
    const [groupDescription, setGroupDescription] = useState('');

    const onChangeAvatarModal = (e) => {
        if (e.target.files.length <= 0 || !e.target.files[0].type.match(/^image\//)) {
            return;
        }

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setAvatarModal(reader.result);
            setIsAvatarModal(true);
        };
    };
    const onChangeAvatar = (avatar) => {
        setGroupAvatar(avatar);
        setIsAvatarModal(false);
    };

    const onChatGroupAdd = (groupsData, usersData) => {
        setGroupsData(groupsData);
        setUsersData(usersData);

        setIsChatGroupAdd(false);
        setIsChatGroupAdminsAdd(false);
    };
    const onUserDelete = (id) => {
        setUsersData(usersData.map((user) => {
            if (user.id === id) {
                user.isSelect = false;
            }
            return user;
        }));
    };

    const onGroupDelete = (id) => {
        setGroupsData(groupsData.map((group) => {
            if (group.id === id) {
                group.isSelect = false;
            }
            return group;
        }));
    };
    const onGroupToggle = (groupId) => {
        const groups = groupsData.map((group) => {
            if (group['id'] === groupId) {
                group['isOpen'] = group['isOpen'] ? !group['isOpen'] : true;
            }
            return group;
        });
        setGroupsData(groups);
    };

    const onAvatarCancel = () => {
        document.getElementById('chat-group-avatar').value = '';
        setIsAvatarModal(false)
    };
    const onSubmit = async () => {
        const chatUsers = usersData.filter((user) => user.isSelect || user.isAdmin);
        const chatGroups = groupsData.filter((group) => group.isSelect || group.isAdmin);

        if (groupTitle.length <= 0 || (chatUsers.length === 0 && chatGroups.length === 0)) {
            return;
        }

        const avatarType = groupAvatar ? groupAvatar.split(';')[0].split(':')[1] : null;
        const avatar = groupAvatar ? groupAvatar.split(',')[1] : null;

        try {
            const group = chat
                ? await api.chat.updateChatGroup(chat.id, groupTitle, groupDescription, avatarType, avatar, chatUsers, chatGroups)
                : await api.chat.createChatGroup(groupTitle, groupDescription, avatarType, avatar, chatUsers, chatGroups);
            onOpenChat(group['id'], 1, group);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    };

    useEffect(() => {
        setGroupsData(groups.map((group) => {
            const chatGroup = chat ? chat.groups.find((item) => item.id === group.id) : null;

            return {
                id: group.id,
                title: group.name,
                avatar: '',
                isSelect: !!chatGroup,
                members: !!chatGroup ? chatGroup.members : []
            };
        }));
        setUsersData(users.map((user) => {
            return {
                id: user.id,
                title: user.firstName + ' ' + user.lastName,
                avatar: user.smallAvatarUuid,
                isSelect: chat && chat.users ? !!chat.users.find((item) => item.id === user.id) : false,
                isAdmin: chat && chat.users ? !!chat.users.find((item) => item.id === user.id && item.isAdmin) : false
            };
        }));
    }, [chat, groups, users]);
    useEffect(() => {
        const fetchData = async () => {
            const groups = await api.group.getGroups(0, 2000);
            setGroups(groups.data.content);

            if (!chat) {
                return;
            }

            setGroupTitle(chat.title);
            setGroupDescription(chat.description);
            setGroupAvatar(chat.image);
        };
        fetchData();
    }, [chat]);

    return (
        <div className={cx.chatGroup}>
            <AvatarEditor open={isAvatarModal} onSubmit={(avatar) => onChangeAvatar(avatar)} title="Загрузка аватара"
                          onDismiss={onAvatarCancel} img={avatarModal}/>
            <ChatGroupAdd isOpen={isChatGroupAdd || isChatGroupAdminsAdd} isAdmins={isChatGroupAdminsAdd}
                          groups={isChatGroupAdd ? groupsData : []} users={usersData} onApprove={onChatGroupAdd}
                          onClose={() => {
                              setIsChatGroupAdd(false);
                              setIsChatGroupAdminsAdd(false);
                          }}/>
            <h2>{chat ? 'Изменение чата' : 'Создание чата'}</h2>
            <div className={cx.chatGroupAvatar}>
                <div className={cx.chatGroupAvatarPreview}>
                    {!groupAvatar && <Icon type={Icons.PHOTO} width={25} height={21}/>}
                    {groupAvatar && <img src={groupAvatar} alt=""/>}
                </div>
                <div className={cx.chatGroupAvatarLoad}>
                    <input id="chat-group-avatar" type="file" accept="image/png,image/jpeg"
                           onChange={onChangeAvatarModal}/>
                    <IconButton type={IconButtonType.TABLE} label="Загрузить фото"
                                icon={<Icon type={Icons.PHOTO} width={15} height={13}/>}
                                onClick={() => document.getElementById('chat-group-avatar').click()}/>
                    <IconButton type={IconButtonType.TABLE} label="Удалить фото"
                                icon={<Icon type={Icons.TRASH} width={15} height={13}/>}
                                onClick={() => setGroupAvatar(null)}/>
                </div>
            </div>
            <Input label="Название беседы" className={cx.chatGroupInput} value={groupTitle} onChange={setGroupTitle}/>
            <Input label="Описание группы" className={cx.chatGroupInput} value={groupDescription}
                   onChange={setGroupDescription}/>
            <div className={cx.separator}/>
            <div className={cx.chatGroupUsers}>
                <p>Администраторы чата</p>
                <button onClick={() => setIsChatGroupAdminsAdd(true)}>
                    <div className={cx.icon}>
                        <Icon type={Icons.ADD} width={8} height={8}/>
                    </div>
                    <span>Добавить администратора</span>
                </button>
                {usersData.filter(u => u.isAdmin).length !== 0 &&
                <div className={cx.chatGroupUsersItems}>
                    {/*<p>Участники</p>*/}
                    {usersData.filter(u => u.isAdmin).map((user) => {
                        return (
                            <div key={user.id} className={cx.chatGroupUsersItemsItem}>
                                <div className={cx.chatGroupUsersItemsItemContainer}>
                                    <div className={cx.chatGroupUsersItemsItemContainerAvatar}>
                                        {user.avatar &&
                                        <img src={api.upload.getImage(user.avatar, false, 128)} alt="" />}
                                    </div>
                                    <p>{user.title}</p>
                                </div>
                                <Icon type={Icons.TRASH} tooltip={'Удалить'} width={12} height={12}
                                      onClick={() => onUserDelete(user.id)}/>
                            </div>
                        );
                    })}
                </div>}
                <p>Участники группы</p>
                <button onClick={() => setIsChatGroupAdd(true)}>
                    <div className={cx.icon}>
                        <Icon type={Icons.ADD} width={8} height={8}/>
                    </div>
                    <span>Добавить участника или группу</span>
                </button>
                {groupsData.filter(g => g.isSelect).length !== 0 &&
                <div className={cx.chatGroupGroupsItems}>
                    <p>Группы</p>
                    {groupsData.filter(g => g.isSelect).map((group) => {
                        return (
                            <div key={group['id']} className={classNames(cx.group, group['isOpen'] && cx.groupOpen)}>
                                <div className={cx.row}>
                                    <div className={cx.left} onClick={() => onGroupToggle(group['id'])}>
                                        <Icon type={Icons.ARROW} width={10} height={6} className={cx.arrow}/>
                                        <div className={cx.icon}>
                                            <Icon type={Icons.GROUP} width={11} height={8}/>
                                        </div>
                                        <p>{group['title']}</p>
                                    </div>
                                    <div className={cx.right}>
                                        <Icon type={Icons.TRASH} tooltip={'Удалить'} width={12} height={12}
                                              className={cx.trashIcon}
                                              onClick={() => onGroupDelete(group['id'])}/>
                                    </div>
                                </div>
                                {group['isOpen'] &&
                                <div className={cx.items}>
                                    {group['members'] && group['members'].map((user) => {
                                        return (
                                            <div key={user['id']} className={cx.item}>
                                                <div className={cx.avatar}>
                                                    {user['smallAvatarUuid'] &&
                                                    <img src={api.upload.getImage(user['smallAvatarUuid'], false, 128)} alt="" />}
                                                </div>
                                                <p>{user['firstName'] + ' ' + user['lastName']}</p>
                                            </div>
                                        );
                                    })}
                                </div>}
                            </div>
                        );
                    })}
                </div>}
                {usersData.filter(u => u.isSelect).length !== 0 &&
                <div className={cx.chatGroupUsersItems}>
                    <p>Участники</p>
                    {usersData.filter(u => u.isSelect).map((user) => {
                        return (
                            <div key={user.id} className={cx.chatGroupUsersItemsItem}>
                                <div className={cx.chatGroupUsersItemsItemContainer}>
                                    <div className={cx.chatGroupUsersItemsItemContainerAvatar}>
                                        {user.avatar &&
                                        <img src={api.upload.getImage(user.avatar, false, 128)} alt="" />}
                                    </div>
                                    <p>{user.title}</p>
                                </div>
                                <Icon type={Icons.TRASH} tooltip={'Удалить'} width={12} height={12}
                                      onClick={() => onUserDelete(user.id)}/>
                            </div>
                        );
                    })}
                </div>}
            </div>
            <div className={cx.chatGroupButtons}>
                <Button label="Отмена" onClick={onClose}/>
                <Button color="green" label="Подтвердить" onClick={onSubmit}/>
            </div>
        </div>
    );
};
