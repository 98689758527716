import React, { useEffect } from 'react';
import { useNavigate } from '@reach/router';
import PropTypes from 'prop-types';
import { useProjectFormModel } from 'lib/hooks';
import ProjectForm from './project-form';
import cs from './project-form-page.module.scss';

export const projectFormPage = (Component, pageTitle, edit = false) => {
    const FormPage = (props) => {
        const navigate = useNavigate();
        const { uuid } = props;

        const model = useProjectFormModel(uuid);
        const { project, loading, permissionsOk } = model;

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401', { replace: true });
            }
        }, [navigate, permissionsOk]);

        return (
            <div className={cs.content}>
                <div id={'projectFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                <ProjectForm project={project} loading={loading} edit={edit}>
                    <Component {...props} {...model}/>
                </ProjectForm>
            </div>
        );
    };

    FormPage.propTypes = {
        uuid: PropTypes.string
    };

    return FormPage;
};