import React from 'react';
import cs from './LicensePage.module.scss';

const Information = ({ licenseInfo }) => {
    return (
        <div className={cs.info}>
            <div className={cs.row}>
                <div>Активные пакеты лицензий</div>
                <div>{licenseInfo?.activeLicenses}</div>
            </div>
            <div className={cs.row}>
                <div>Всего лицензий</div>
                <div>{licenseInfo?.overallSubscriptions}</div>
            </div>
            <div className={cs.row}>
                <div>Используется лицензий</div>
                <div>{licenseInfo?.subscriptionsInUse}</div>
            </div>
            <div className={cs.row}>
                <div>Свободных лицензий</div>
                <div>{licenseInfo?.availableSubscriptions}</div>
            </div>
        </div>
    );
};

export default Information;