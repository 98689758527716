import React, { useMemo } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import classNames from 'classnames';
import {
    getEmbedUrlFromYoutubeUrl,
    getRutubeEmbedUrl,
    getVimeoEmbedUrl,
    getVKEmbedUrl,
    isValidRutubeUrl,
    isValidVimeoUrl,
    isValidVKUrl,
    isValidYoutubeUrl,
} from './utils';

export const EmbedVideoView = props => {
    const attrs = props.node.attrs;
    const src = useMemo(() => {
        let embedUrl;
        if (isValidRutubeUrl(attrs.src)) {
            embedUrl = getRutubeEmbedUrl(attrs.src);
        } else if (isValidVKUrl(attrs.src)) {
            embedUrl = getVKEmbedUrl(attrs.src);
        } else if (isValidVimeoUrl(attrs.src)) {
            embedUrl = getVimeoEmbedUrl(attrs.src);
        } else if (isValidYoutubeUrl(attrs.src)) {
            embedUrl = getEmbedUrlFromYoutubeUrl({
                url: attrs.src,
                allowFullscreen: props.extension.options.allowFullscreen,
                autoplay: props.extension.options.autoplay,
                ccLanguage: props.extension.options.ccLanguage,
                ccLoadPolicy: props.extension.options.ccLoadPolicy,
                controls: props.extension.options.controls,
                disableKBcontrols: props.extension.options.disableKBcontrols,
                enableIFrameApi: props.extension.options.enableIFrameApi,
                endTime: props.extension.options.endTime,
                interfaceLanguage: props.extension.options.interfaceLanguage,
                ivLoadPolicy: props.extension.options.ivLoadPolicy,
                loop: props.extension.options.loop,
                modestBranding: props.extension.options.modestBranding,
                nocookie: props.extension.options.nocookie,
                origin: props.extension.options.origin,
                playlist: props.extension.options.playlist,
                progressBarColor: props.extension.options.progressBarColor,
                startAt: attrs.start || 0,
            });
        }

        return embedUrl;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attrs.src]);
    const isActive = props.editor?.isActive('embed-video') || (props.editor?.isFocused && props.editor?.isActive('embed-video'));

    return (
        <NodeViewWrapper
            className={classNames('media-node-view not-prose', {
                [`align-${attrs.dataAlign}`]: attrs.dataAlign,
            })}
            style={{ pointerEvents: isActive ? 'initial' : 'none' }}
            data-align={attrs.dataAlign}
        >
            <iframe
                title="EmbdVideoView"
                {...props.extension.options}
                src={src}
                width={attrs.width}
                height={attrs.height}
                frameborder="0"
            ></iframe>
        </NodeViewWrapper>
    );
};
