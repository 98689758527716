import React, { useEffect, useState } from 'react';
import cs from 'app/import-users/import-users-xls.module.scss';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import Divider from 'uikit/divider';
import Button from 'uikit/button';
import Card from 'components/card';
import api from 'api';
import { useGlobalContext } from 'lib/hooks';

const formatSize = (size) => {
    if (size < 1024) {
        return `${size.toFixed(1)} Б`;
    } else if (size < 1024*1024) {
        return `${(size / 1024).toFixed(1)} Кб`;
    } else {
        return `${(size / 1024 / 1024).toFixed(1)} Мб`;
    }
}

const ImportUsersXls = ({ setLoading, onProceed }) => {
    const { setFormDataChanged, setFormDataChangedSubmitHandler } = useGlobalContext();
    const [files, setFiles] = useState(null);
    const [fileError, setFileError] = useState(null);

    const [usersResponse, setUsersResponse] = useState([]);

    const [isFileValidated, setIsFileValidated] = useState(false);
    const [isReadyToProceed, setIsReadyToProceed] = useState(false);

    const onStartImportClick = () => {
        if (isFileValidated) {
            onProceed(usersResponse);
        } else {
            setLoading(true);
            setIsReadyToProceed(true);
        }
    };

    useEffect(() => {
        const validateFile = async () => {
            if (files) {
                try {
                    const result = await api.user.validateImport(files);

                    if (isReadyToProceed) {
                        setLoading(false);
                        onProceed(result);
                    } else {
                        setUsersResponse(result);
                        setIsFileValidated(true);
                    }
                } catch (e) {
                    setFileError('Ошибка обработки файла. Структура таблицы не соответствует шаблону. Внесите необходимые изменения и попробуйте заново')
                }
            } else {
                setFileError(null);
                setIsReadyToProceed(false);
                setUsersResponse([]);
            }
        }

        validateFile();
    }, [files, isReadyToProceed, setLoading, onProceed]);

    // Сброс до инициализирующих данных при попытке уйти с формы импорта участников, нажав на тот же пункт в меню - "Импорт участников"
    function resetToInitialValues () {
        return () => {
            setFiles(null);
        }
    }

    useEffect(() => {
        setFormDataChangedSubmitHandler(resetToInitialValues);
        return () => {
            setFormDataChangedSubmitHandler(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Card className={cs.card}>
            <div className={cs.blockText}>Импорт участников предназначен для групповой загрузки участников.
                Воспользуйтесь шаблоном Excel таблицы для заполнения необходимых данных об участниках.</div>
            <div className={cs.templateFileBlock}>
                <div className={cs.templateFileInfo}>
                    <Icon type={Icons.XLS_FILE} width={24} height={24}/>
                    <div className={cs.templateFileNameWrapper}>
                        <div className={cs.templateFileName}>Шаблон-для-импорта</div>
                        <div className={cs.templateFileType}>XLS — 4 Кб</div>
                    </div>
                </div>
                <a href={api.user.template()} download>
                    <IconButton type={IconButtonType.TABLE} label="Скачать шаблон"
                                icon={<Icon type={Icons.DOWNLOAD} width={15} height={15}/>}/>
                </a>
            </div>
            <Divider/>
            <div className={cs.uploadFileTitle}>Загрузите таблицу с участниками</div>
            {!files &&
            <label className={cs.uploadFileLabel}>
                <Icon type={Icons.CLIP}/>
                <span className={cs.uploadFileText}>Прикрепить файл</span>
                <input type='file' accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                       onChange={(e) => {
                           setFiles(e.target.files);
                           setFormDataChanged(true);
                       }}/>
            </label>}
            {files &&
            <>
                <div className={cs.fileContainer}>
                    <div className={cs.fileLeft}>
                        <Icon type={Icons.XLS_FILE} width={32} height={32}/>
                        <div className={cs.fileNameWrapper}>
                            <div className={cs.fileName}>{files[0].name}</div>
                            <div className={cs.fileType}>XLS — {formatSize(files[0].size)}</div>
                        </div>
                    </div>
                    <IconButton icon={<Icon type={Icons.TRASH} width={20} height={20}/>}
                                onClick={() => setFiles(null)}/>
                </div>
                {fileError && <div className={cs.error}>{fileError}</div>}
            </>}
            <Divider/>
            <div className={cs.columnsInfoTitle}>Правила колонок:</div>
            <div className={cs.columnsInfo}>
                <div className={cs.column}>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Имя</div>
                        <div className={cs.rowText}>Допустимы русские буквы и тире</div>
                    </div>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Отчество</div>
                        <div className={cs.rowText}>Допустимы русские буквы и тире</div>
                    </div>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Почта</div>
                        <div className={cs.rowText}>Стандартные правила для электронной почты</div>
                    </div>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Проект</div>
                        <div className={cs.rowText}>Название проекта из базы знаний</div>
                    </div>
                </div>
                <div className={cs.column}>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Фамилия</div>
                        <div className={cs.rowText}>Допустимы русские буквы и тире</div>
                    </div>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Логин</div>
                        <div className={cs.rowText}>Допустимы латинские буквы, цифры, тире</div>
                    </div>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Пароль</div>
                        <div className={cs.rowText}>Длина пароля должна быть от 8 до 120 символов, и он должен содержать как минимум одну прописную букву, одну заглавную букву и одну цифру</div>
                    </div>
                    <div className={cs.infoBlock}>
                        <div className={cs.rowName}>Роль</div>
                        <div className={cs.rowText}>Название роли базы знаний</div>
                    </div>
                </div>
            </div>
            <div className={cs.controlsContainer}>
                <Button label="Отмена"/>
                <Button onClick={() => onStartImportClick()} label="Начать импорт" disabled={!files} color="green"/>
            </div>
        </Card>
    )
}

export default ImportUsersXls;
