import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { useSelector } from 'react-redux';
import { useSession, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectSession, selectScriptingEnabled } from 'slice/authSlice';
import { MenuWrapper } from 'containers/menu-wrapper';
import { SplashScreen } from 'containers/splash-screen';
import { SideTreeWrapper } from 'containers/side-tree-wrapper';
import NoAuthorizedPage from 'components/no-authorized';
import MultiplyErrorPage from 'components/multiply-error';
import { AddPage } from 'app/add-page';
import { AddProjectPage } from 'app/add-page/add-project-page';
import { AddSectionPage } from 'app/add-page/add-section-page';
import { AddArticlePage } from 'app/add-page/add-article-page';
import { AddNewsPage } from 'app/add-page/add-news-page';
import { LoginPage, LoginLicensePage } from 'app/auth';
import { HomePage } from 'app/home';
import { ProjectsExamplePage, ArchivePage } from 'app/projects';
import { Users } from 'app/users';
import { AddUserPage } from 'app/add-page/add-user-page';
import { ActionsLog } from 'app/action-log';
import { EditArticlePage } from 'app/article/pages/edit-article-page';
import EditUserPage from 'app/edit-user/pages/edit-user-page';
import EditGroupPage from 'app/edit-group/edit-group-page';
import { UserProfilePage } from 'app/user-profile';
import { FilesPage } from 'app/files';
import { NewsPage } from 'app/news';
import { SearchResultPage } from 'app/search-result';
import ArticlePosting from 'app/article/pages/article-posting';
import NewsPosting from 'app/news/pages/news-posting';
import AddFilePage from 'app/add-page/add-file-page/add-file-page';
import { EditProjectPage } from 'app/projects';
import DraftsPage from 'app/user-profile/drafts-page/drafts-page';
import FavoritesPage from 'app/user-profile/favorites-page/favorites-page';
import GlobalSettingsPage from 'app/global-settings/global-settings-page';
import { CompaniesPage } from 'app/global-settings/companies-page';
import { ManagersPage } from 'app/global-settings/managers-page';
import { PositionsPage } from 'app/global-settings/positions-page';
import GlossaryPage from 'app/global-settings/glossary-page/glossary-page';
import { RolesPage } from 'app/global-settings/roles-page';
import { IntegrationsPage, IntegrationsNaumenPage, Integrations1CBitrixPage } from 'app/global-settings/integrations-page';
import { AboutPage } from 'app/global-settings/about-page';
import { ChatPage } from 'app/chat';
import { EditSectionPage } from 'app/section';
import EditNewsPage from 'app/news/pages/edit-news-page/edit-news-page';
import { AddGroupPage } from 'app/add-page/add-group-page';
import StatisticWrapperPage from 'app/statistic/pages/statistic-wrapper-page';
import CustomRoles from 'app/global-settings/custom-roles/custom-roles';
import CustomRoleForm from 'app/global-settings/custom-roles/custom-role-form';
import CustomRolesList from 'app/global-settings/custom-roles/custom-roles-list';
import UserPage from 'app/users/user-page/user-page';
import ProfilePageEdit from 'app/user-profile/profile-page-edit/profile-page-edit';
import ProfilePage from 'app/user-profile/profile-page/profile-page';
import { TrainingPage } from 'app/training';
import { DevelopmentPage } from 'app/development';
import CreateScript from 'app/scripting/pages/create-script/create-script';
import ScriptingPage from 'app/scripting/pages/scripting-page/scripting-page';
import TrainingTestStart from 'components/training/training-test/training-test-start/training-test-start';
import TrainingTestQuestion from 'components/training/training-test/training-test-question/training-test-question';
import TrainingTestEnd from 'components/training/training-test/training-test-end/training-test-end';
import ContentManagementNewsPage from 'app/news/pages/content-management-news-page';
import ContentManagementProjectPage from 'app/projects/pages/content-management-project-page';
import ContentManagementSectionPage from 'app/section/content-management-section-page';
import ContentManagementArticlePage from 'app/article/pages/content-management-acticle-page';
import ImportUsersPage from 'app/import-users';
import DelayedPublicationsPage from 'app/user-profile/delayed-publications-page/delayed-publications-page';
import ErrorBoundary from './error-boundary';
import LicensePage from 'app/global-settings/license-page/LicensePage';
import ScriptPosting from './scripting/pages/script-posting/script-posting';
import ScriptsWrapper from 'containers/scripts-wrapper/scripts-wrapper';
import CompaniesPageEditCompany from './global-settings/companies-page/companies-page-edit-company';
import CompaniesPageEditOffice from './global-settings/companies-page/companies-page-edit-office';
import { TagsPage } from 'app/global-settings/tags-page/TagsPage';
import { ChatBot } from './chat-bot/chat-bot';

export default function Routes({ props }) {
    const session = useSelector(selectSession);
    const isScriptingEnabled = useSelector(selectScriptingEnabled);
    useSession();

    const { locationUrl, setLocationUrl, setPrevLocationUrl } = useGlobalContext();
    const [, setCurrentLocation] = React.useState(null);

    // Сохранение url с параметрами запроса для случаев, когда происходит переход не через вызов navigate(...)
    // Например, при открытии ссылки на статью/новость из результатов поиска в новой вкладке, когда необходима подсветка совпадений (берётся из параметров)
    useEffect(() => {
        if (locationUrl !== props.location.pathname && !locationUrl.includes(props.location.pathname)) {
            setLocationUrl(`${props.location.pathname}${props.location.search}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationUrl]);

    useEffect(() => {
        setCurrentLocation(prev => {
            setPrevLocationUrl(prev);
            return props.location.pathname;
        });
    }, [props.location, setPrevLocationUrl]);

    return (
        <ErrorBoundary>
            {!session && (
                <Router>
                    <LoginPage path="/login" />
                    <LoginLicensePage path="/login-license" />
                    <SplashScreen default />
                </Router>
            )}
            {session && (
                <Router>
                    <TrainingTestStart path="/training/test/:id/process" />
                    <TrainingTestQuestion path="/training/test/:testId/process/:questionId" />
                    <TrainingTestEnd path="/training/test/:id/process/end" />
                    <MenuWrapper path="/">
                        <AddPage path={'add'}>
                            <AddProjectPage path="project" />
                            <AddSectionPage path="section" />
                            <AddArticlePage path="article" />
                            {/* <CreateScript path="script" /> */}
                            <AddNewsPage path="news" />
                            <AddUserPage path="user" />
                            <AddFilePage path="file" />
                            <AddGroupPage path="group" />
                            <ImportUsersPage path="importUsers" hasAnyAuthorities={[GLOBAL_ACTIONS.USER_CREATE]} />
                        </AddPage>
                        <SideTreeWrapper path={'projects'} multipleChoice={false} allButtonTitle="Все проекты">
                            <ProjectsExamplePage default />
                            <ProjectsExamplePage path=":uuid/:tab" />
                            <ProjectsExamplePage path="section/:sectionId/:tab" />
                            <ProjectsExamplePage path=":uuid/:sectionId/section/:tab" />
                            <ArticlePosting path="article/:id" />
                            <ArticlePosting path="article/:id/history/:snapshotId" />
                            <NewsPosting path="news/:id" />
                            <NewsPosting path="news/:id/history/:snapshotId" />
                            <EditArticlePage path="article/:uuid/edit" />
                            <EditProjectPage path="project/:uuid/edit" />
                            <EditSectionPage path="section/:uuid/edit" />
                            <EditNewsPage path="news/:uuid/edit" />
                            <ContentManagementProjectPage path="project/:uuid/content-management" />
                            <ContentManagementSectionPage path="section/:uuid/content-management" />
                            <ContentManagementArticlePage path="article/:uuid/content-management" />
                            <ContentManagementNewsPage path="news/:uuid/content-management" />
                        </SideTreeWrapper>
                        <Users path="users/*" />
                        <StatisticWrapperPage path="statistic/*" hasAnyAuthorities={[GLOBAL_ACTIONS.STATS_PAGE]} />
                        <SideTreeWrapper path={'archive'} multipleChoice={false} archive={true} allButtonTitle="Все проекты">
                            <ArchivePage default />
                            <ArchivePage path=":tab" />
                            <ArchivePage path=":projectId/:tab" />
                            <ArchivePage path=":projectId/:sectionId/section/:tab" />
                            <ArticlePosting path="article/:id" archive={true} />
                            <EditArticlePage path="article/:uuid/edit" />
                            <NewsPosting path="news/:id" archive={true} />
                            <ScriptPosting path="script/:scriptUuid" archive={true} />
                            <EditNewsPage path="news/:uuid/edit" />
                        </SideTreeWrapper>
                        <ActionsLog path="action-log/*" />
                        <UserPage path={'edit-user/:userLogin'} />
                        <EditUserPage path={'edit-user/:userLogin/edit'} hasAnyAuthorities={[GLOBAL_ACTIONS.USER_EDIT]} />
                        <EditGroupPage path="edit-group/:groupId" />
                        <EditGroupPage mode="edit" path="edit-group/:groupId/edit" />
                        <UserProfilePage path="user">
                            <ProfilePage path="profile" />
                            <ProfilePageEdit path="profile/edit" />
                            <DraftsPage path="drafts/:tab" />
                            <EditArticlePage path="article-draft/:uuid/:type/edit" />
                            <EditNewsPage path="news-draft/:uuid/:type/edit" />
                            <ArticlePosting path="article-draft/:id/:type" />
                            <ScriptPosting path="script-draft/:scriptUuid/:type" />
                            <NewsPosting path="news-draft/:id/:type" />
                            <FavoritesPage path="favorites/*" />
                            <DelayedPublicationsPage path="delayed-publications/*" />
                            <EditArticlePage path="delayed-article/:uuid/:type/edit" />
                            <EditNewsPage path="delayed-news/:uuid/:type/edit" />
                        </UserProfilePage>
                        <GlobalSettingsPage path="global-settings">
                            <LicensePage path="license" hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />
                            <CompaniesPage path="companies" hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />
                            <CompaniesPageEditCompany path="company/create" hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />
                            <CompaniesPageEditCompany path="company/edit/:companyId" hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />
                            <CompaniesPageEditOffice path="office/create" hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />
                            <CompaniesPageEditOffice path="office/edit/:officeId" hasAnyAuthorities={[GLOBAL_ACTIONS.COMPANY_PAGE]} />
                            <ManagersPage path="managers" hasAnyAuthorities={[GLOBAL_ACTIONS.SUPERVISOR_PAGE]} />
                            <PositionsPage path="positions" hasAnyAuthorities={[GLOBAL_ACTIONS.POSITION_PAGE]} />
                            <RolesPage path="roles" />
                            <IntegrationsPage path="integrations" hasAnyAuthorities={[GLOBAL_ACTIONS.INTEGRATION_PAGE]} />
                            <IntegrationsNaumenPage path="integrations/naumen" hasAnyAuthorities={[GLOBAL_ACTIONS.INTEGRATION_PAGE]} />
                            <Integrations1CBitrixPage path="integrations/1cbitrix" hasAnyAuthorities={[GLOBAL_ACTIONS.INTEGRATION_PAGE]} />
                            <AboutPage path="about" />
                            <GlossaryPage path="glossary" />
                            <CustomRoles path={'custom-roles'}>
                                <CustomRoleForm path={'add'} hasAnyAuthorities={[GLOBAL_ACTIONS.CUSTOM_ROLE_CREATE]} />
                                <CustomRoleForm path={'role/:id'} hasAnyAuthorities={[GLOBAL_ACTIONS.CUSTOM_ROLE_EDIT]} />
                                <CustomRolesList default hasAnyAuthorities={[GLOBAL_ACTIONS.CUSTOM_ROLE_READ]} />
                            </CustomRoles>
                            <TagsPage path="tags" />
                        </GlobalSettingsPage>
                        <FilesPage path="files/*" />
                        <NewsPage path="news" />
                        <SideTreeWrapper path="news" multipleChoice={false} allButtonTitle="Все проекты">
                            <NewsPosting path=":id" archive={false} />
                            <NewsPosting path=":id/history/:snapshotId" />
                            <EditNewsPage path=":uuid/edit" />
                        </SideTreeWrapper>
                        <SearchResultPage path="search" />
                        <ChatPage path="chat/*" />
                        <HomePage default />
                        <TrainingPage path="training/*" />

                        {isScriptingEnabled && (
                            <>
                                <ScriptsWrapper path={'scripting'}>
                                    <ScriptingPage path=":projectUuid" />
                                    <ScriptingPage path=":projectUuid/:sectionUuid" />
                                    <ScriptingPage path=":projectUuid/:sectionUuid/:scriptUuid" />
                                    <ScriptPosting path="script/:scriptUuid" />
                                    <ScriptingPage default />
                                </ScriptsWrapper>
                                <CreateScript path="scripting/create" />
                                <CreateScript path="scripting/edit/:uuid" edit={true} />
                                <CreateScript path="scripting/edit/:uuid/:type" edit={true} />
                            </>
                        )}
                        <DevelopmentPage path="script" />
                        <NoAuthorizedPage path="401" />
                        <MultiplyErrorPage path="multiply" />
                        <CreateScript path="add/script" />
                        <ChatBot path="ai" />
                    </MenuWrapper>
                </Router>
            )}
        </ErrorBoundary>
    );
}
