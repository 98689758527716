import React from 'react';

export const useSearch = ({ isVoice = false, onChangeSearch }) => {
    const recognizingRef = React.useRef(false);
    const [query, setQuery] = React.useState(null);
    const [recognition, setRecognition] = React.useState(null);
    const [status, setStatus] = React.useState(false);

    React.useEffect(() => {
        if (!isVoice) {
            return;
        }

        const speechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

        if (!speechRecognition) {
            return;
        }

        const recognition = new speechRecognition();
        recognition.continuous = true;
        recognition.interimResults = true;
        recognition.maxAlternatives = 3;
        recognition.lang = 'ru-RU';

        recognition.onstart = () => {
            setStatus(true);
        };

        recognition.onerror = (e) => {
            if (e.error === 'no-speech') {
                recognizingRef.current = false;
                recognition.stop();
            }
        };

        recognition.onend = (/* e */) => {
            if (!recognizingRef.current) {
                setStatus(false);
                setQuery((prev) => {
                    onChangeSearch?.(prev);
                    return prev;
                });
                return;
            }
            recognition.start();
        };

        recognition.onresult = (e) => {
            let transcript = '';

            for (let i = e.resultIndex; i < e.results.length; i++) {
                const result = e.results[i][0]?.transcript;
                transcript += result;

                if (e.results[i].isFinal) {
                    // eslint-disable-next-line no-loop-func
                    setQuery((prev) => prev + String(transcript).replace('.', ' '));
                }
            }

        };

        setRecognition(recognition);
    }, [isVoice, onChangeSearch, recognizingRef]);

    const start = () => {
        if (!recognition) {
            return;
        }

        setQuery('');

        recognizingRef.current = true;
        recognition.start();
    };

    const stop = () => {
        if (!recognition) {
            return;
        }
        recognizingRef.current = false;
        recognition.stop();
    };

    return {
        status,
        query,
        setQuery,
        start,
        stop,
        recognition,
    };
};
