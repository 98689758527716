import ApiClient from './api-axios';

export default class GroupApi extends ApiClient {
    getGroups = async (page = 0, size = 20, sort = '', search) => {
        const params = new URLSearchParams({page, size, sort});

        if (search) {
          params.append('name.contains', search);
        }

        return await this.get('?' + params.toString(), {}, true);
    };

    searchByName = async (name) => {
      return await this.get('search/for-filters/by-name?name=' + name.toString());
    }
}