const CustomRolesConfig = [
    {
        name: 'Проект/раздел',
        roles: [
            {
                name: 'Смотреть проект',
                value: 'PROJECT_READ'
            },
            {
                name: 'Смотреть раздел',
                value: 'SECTION_READ'
            },
            {
                name: 'Создать проект',
                value: 'PROJECT_CREATE'
            },
            {
                name: 'Создать раздел',
                value: 'SECTION_CREATE'
            },
            {
                name: 'Редактировать проект',
                value: 'PROJECT_EDIT'
            },
            {
                name: 'Редактировать раздел',
                value: 'SECTION_EDIT'
            },
            {
                name: 'Удалить проект',
                value: 'PROJECT_DELETE'
            },
            {
                name: 'удалить раздел',
                value: 'SECTION_DELETE'
            }
        ]
    },
    {
        name: 'Статья/новость',
        roles: [
            {
                name: 'Читать статью',
                value: 'ARTICLE_READ'
            },
            {
                name: 'Читать новость',
                value: 'NEWS_READ'
            },
            {
                name: 'Создать статью',
                value: 'ARTICLE_CREATE'
            },
            {
                name: 'Создать новость',
                value: 'NEWS_CREATE'
            },
            {
                name: 'Создать глобальную новость',
                value: 'GLOBAL_NEWS_CREATE'
            },
            {
                name: 'Редактировать статью',
                value: 'ARTICLE_EDIT'
            },
            {
                name: 'Редактировать новость',
                value: 'NEWS_EDIT'
            },
            {
                name: 'Удалить статью',
                value: 'ARTICLE_DELETE'
            },
            {
                name: 'Удалить новость',
                value: 'NEWS_DELETE'
            },
            {
                name: 'Комментарии (писать, редактировать, удалять свои',
                value: 'COMMENTS_ADD'
            },
            {
                name: 'Отправить по электронной почте',
                value: 'DOCUMENT_SEND_TO_EMAIL'
            },
            {
                name: 'Скачать документ',
                value: 'DOCUMENT_DOWNLOAD'
            },
            {
                name: 'Распечатать документ',
                value: 'DOCUMENT_PRINT'
            },
            {
                name: 'Сообщить об ошибке',
                value: 'ERROR_REPORT_ADD'
            },
            {
                name: 'Посмотреть действия над документом',
                value: 'ACTIVITY_READ'
            },
            {
                name: 'Подписаться на обновления',
                value: 'NOTIFICATION_SUBSCRIBE_ADD'
            },
            {
                name: 'Поставить лайк/дизлайк',
                value: 'LIKE_DISLIKE_ADD'
            }
        ]
    },
    {
        name: 'Замок (возможность назначить роль участника)',
        roles: [
            {
                name: '',
                value: 'LOCK'
            }
        ]
    },
    {
        name: 'Участники',
        roles: [
            {
                name: 'Доступ к разделу "Участники"',
                value: 'USER_PAGE'
            }
        ]
    },
    {
        name: 'Список участников',
        roles: [
            {
                name: 'Просмотреть список активных участников',
                value: 'USER_READ'
            },
            {
                name: 'Просмотреть список заблокированных участников',
                value: 'VIEW_ALL_USERS'
            },
            {
                name: 'Просмотреть чужой профиль',
                value: 'USER_VIEW_PROFILE'
            },
            {
                name: 'Создать',
                value: 'USER_CREATE'
            },
            {
                name: 'Редактировать',
                value: 'USER_EDIT'
            },
            {
                name: 'Заблокировать/разблокировать',
                value: 'USER_BLOCK'
            },
            {
                name: 'Удалить',
                value: 'USER_DELETE'
            }
        ]
    },
    {
        name: 'Группы участников',
        roles: [
            {
                name: 'Просмотреть список',
                value: 'GROUP_READ'
            },
            {
                name: 'Создать',
                value: 'GROUP_CREATE'
            },
            {
                name: 'Редактировать',
                value: 'GROUP_EDIT'
            },
            {
                name: 'Удалить',
                value: 'GROUP_DELETE'
            }
        ]
    },
    {
        name: 'Чат',
        roles: [
            {
                name: 'Доступ к разделу "Чат" + Получать/отправлять сообщения',
                value: 'CHAT_PAGE'
            }
        ]
    },
    {
        name: 'Диалог (чат с одним участником)',
        roles: [
            {
                name: 'Создать диалог',
                value: 'CHAT_CONVERSATION_ADD'
            },
            {
                name: 'Просмотреть информацию о диалоге',
                value: 'CHAT_CONVERSATION_INFO'
            },
            {
                name: 'Удалить диалог',
                value: 'CHAT_CONVERSATION_DELETE'
            }
        ]
    },
    {
        name: 'Беседа (чат с несколькими участником)',
        roles: [
            {
                name: 'Создать беседу',
                value: 'CHAT_GROUP_ADD'
            },
            {
                name: 'Редактировать беседу',
                value: 'CHAT_GROUP_EDIT'
            },
            {
                name: 'Просмотреть информацию',
                value: 'CHAT_GROUP_INFO'
            },
            {
                name: 'Удалить беседу',
                value: 'CHAT_GROUP_DELETE'
            }
        ]
    },
    {
        name: 'Журнал действий',
        roles: [
            {
                name: 'Доступ к разделу',
                value: 'ACTIVITY_PAGE'
            }
        ]
    },
    {
        name: 'Статистика',
        roles: [
            {
                name: 'Доступ к разделу (сформировать отчет)',
                value: 'STATS_PAGE'
            },
            {
                name: 'Выгрузить отчёт',
                value: 'STATS_EXPORT'
            },
            {
                name: 'Отправить по эл. почте',
                value: 'STATS_SEND_EMAIL'
            }
        ]
    },
    {
        name: 'Архив',
        roles: [
            {
                name: 'Доступ к разделу',
                value: 'RESOURCE_ARCHIVE_READ'
            },
            {
                name: 'Просмотр документов',
                value: 'RESOURCE_ARCHIVE_READ'
            },
            {
                name: 'Разархивация документов',
                value: 'RESOURCE_ARCHIVE_MOVE'
            },
            {
                name: 'Удаление из архива',
                value: 'RESOURCE_ARCHIVE_RESTORE\n'
            }
        ]
    },
    {
        name: 'Общие файлы',
        roles: [
            {
                name: 'Просмотр (Доступ к разделу)',
                value: 'FILE_LIST'
            },
            {
                name: 'Редактировать',
                value: 'FILE_EDIT'
            },
            {
                name: 'Загрузить',
                value: 'FILE_UPLOAD'
            },
            {
                name: 'Скачать',
                value: 'FILE_READ'
            },
            {
                name: 'Заменить',
                value: 'FILE_REPLACE'
            },
            {
                name: 'Удалить',
                value: 'FILE_DELETE'
            }
        ]
    },
    {
        name: 'Неприкрепленные файлы (свои)',
        roles: [
            {
                name: 'Просмотр',
                value: 'FILE_UNATTACHED_LIST'
            },
            {
                name: 'Редактировать',
                value: 'FILE_UNATTACHED_EDIT'
            },
            {
                name: 'Загрузить',
                value: 'FILE_UNATTACHED_UPLOAD'
            },
            {
                name: 'Скачать',
                value: 'FILE_UNATTACHED_READ'
            },
            {
                name: 'Заменить',
                value: 'FILE_UNATTACHED_REPLACE'
            },
            {
                name: 'Удалить',
                value: 'FILE_UNATTACHED_DELETE'
            }
        ]
    },
    {
        name: 'Профиль',
        roles: [
            {
                name: 'Редактировать',
                value: 'USER_OWN_EDIT'
            }
        ]
    },
    {
        name: 'Настройка уведомлений',
        roles: [
            {
                name: 'Редактирование',
                value: 'NOTIFICATION_SUBSCRIBE_EDIT'
            }
        ]
    },
    {
        name: 'Системные настройки',
        roles: [
            {
                name: 'Доступ к разделу (просмотр)',
                value: 'SYSTEM_SETTINGS'
            }
        ]
    },
    {
        name: 'Компании и офисы',
        roles: [
            {
                name: 'Доступ к разделу (просмотр)',
                value: 'COMPANY_PAGE'
            },
            {
                name: 'Добавить/редактировать данные о компании',
                value: 'COMPANY_EDIT'
            },
            {
                name: 'Добавить офис',
                value: 'COMPANY_OFFICE_CREATE'
            },
            {
                name: 'Редактировать офис',
                value: 'COMPANY_OFFICE_EDIT'
            },
            {
                name: 'Удалить офис',
                value: 'COMPANY_OFFICE_DELETE'
            }
        ]
    },
    {
        name: 'Руководители',
        roles: [
            {
                name: 'Доступ к разделу (просмотр)',
                value: 'SUPERVISOR_PAGE'
            },
            {
                name: 'Назначить руководителя',
                value: 'SUPERVISOR_ADD'
            },
            {
                name: 'Редактировать',
                value: 'SUPERVISOR_EDIT'
            },
            {
                name: 'Удалить',
                value: 'SUPERVISOR_DELETE'
            }
        ]
    },
    {
        name: 'Должности',
        roles: [
            {
                name: 'Доступ к разделу (просмотр)',
                value: 'POSITION_PAGE'
            },
            {
                name: 'Создать должность',
                value: 'POSITION_CREATE'
            },
            {
                name: 'Редактировать',
                value: 'POSITION_EDIT'
            },
            {
                name: 'Удалить',
                value: 'POSITION_DELETE'
            }
        ]
    },
    {
        name: 'Роли',
        roles: [
            {
                name: 'Доступ к разделу (просмотр)',
                value: 'CUSTOM_ROLE_READ'
            },
            {
                name: 'Создать роль',
                value: 'CUSTOM_ROLE_CREATE'
            },
            {
                name: 'Редактировать',
                value: 'CUSTOM_ROLE_EDIT'
            },
            {
                name: 'Удалить',
                value: 'CUSTOM_ROLE_DELETE'
            }
        ]
    },
    {
        name: 'Интеграция',
        roles: [
            {
                name: 'Доступ к разделу',
                value: 'INTEGRATION_PAGE'
            }
        ]
    },
    {
        name: 'Обучение',
        roles: [
            {
                name: 'Доступ к редактированию',
                value: 'TRAINING'
            }
        ]
    },
    {
        name: 'Информация о программе',
        roles: [
            {
                name: 'Доступ к разделу',
                value: 'APP_PAGE'
            }
        ]
    }
];
export default CustomRolesConfig;