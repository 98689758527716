import React, { useState } from 'react';
import AvatarEditor from 'components/avatar-editor/avatar-editor';
import Icon, { Icons } from 'uikit/icon';
import IconButton, { IconButtonType } from 'uikit/icon-button';
import cx from './training-question-avatar.module.scss';

export default function TrainingQuestionAvatar({ avatar, onAvatarSave }) {
    const [isAvatarModal, setIsAvatarModal] = useState(false);
    const [uploadAvatar, setUploadAvatar] = useState(null);

    const selectAvatar = (e) => {
        if (e.target.files.length > 0 && e.target.files[0].type.match(/^image\//)) {
            const oFReader = new FileReader();
            oFReader.readAsDataURL(e.target.files[0]);

            oFReader.onload = function() {
                setUploadAvatar(this.result);
                setIsAvatarModal(true);
            };
        } else {
            // addError('для загрузки допустимы следующие форматы - JPG, PNG, WEBM.');
        }
    };
    const onAvatarCancel = () => {
        document.getElementById('question-avatar').value = '';
        setIsAvatarModal(false)
    };
    const saveAvatar = (avatar) => {
        onAvatarSave(avatar);
        setIsAvatarModal(false);
    };

    return (
        <div className={cx.trainingThemeFormQuestionAvatar}>
            {isAvatarModal &&
            <AvatarEditor open={isAvatarModal} onSubmit={(avatar) => saveAvatar(avatar)} type="rectangle"
                          img={uploadAvatar} title="Загрузка аватара" onDismiss={onAvatarCancel}/>}
            <input id="question-avatar" type="file" accept="image/png, image/jpeg" onChange={selectAvatar}/>
            {!avatar &&
            <div className={cx.trainingThemeFormQuestionAvatarEmpty}>
                <IconButton type={IconButtonType.TABLE} label="Загрузить фото"
                            icon={<Icon type={Icons.PHOTO} width={15} height={13}/>}
                            onClick={() => document.getElementById('question-avatar').click()}/>
                <div className={cx.trainingThemeFormQuestionAvatarDescription}>
                    <p>Загрузите обложку вопроса</p>
                    <p>Поддерживаются файлы форматов JPG, PNG, WEBM до 2Мб.</p>
                </div>
            </div>}
            {avatar &&
            <div className={cx.trainingThemeFormQuestionAvatarFull}>
                <img src={avatar} alt="Question Avatar"/>
                <div className={cx.trainingThemeFormQuestionAvatarReadyContainer}>
                    <h4>Обложка вопроса</h4>
                    <div className={cx.trainingThemeFormQuestionAvatarReadyContainerButtons}>
                        <IconButton type={IconButtonType.TABLE} label="Загрузить фото"
                                    icon={<Icon type={Icons.PHOTO} width={15} height={13}/>}
                                    onClick={() => document.getElementById('question-avatar').click()}/>
                        <IconButton type={IconButtonType.TABLE} label="Удалить фото"
                                    icon={<Icon type={Icons.TRASH} width={15} height={13}/>}
                                    onClick={() => onAvatarSave(null)}/>
                    </div>
                </div>
            </div>}
        </div>
    );
};