import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteTree from 'react-infinite-tree';
import TreeNode from './tree-node';

const Tree = ({
                  onUpdate, width, height, data, autoOpen, multipleChoice, forwardedRef, onCheckedChange,
                  isDeselecting = false, onLoadTree, id, getNodeLink, showUnreadCounters, ...rest }) => {
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        const { tree } = forwardedRef.current;
        if (tree) {
            onLoadTree && onLoadTree(tree, forwardedRef);
        }
    }, [forwardedRef, onLoadTree]);

    useEffect(() => {
        const { tree } = forwardedRef.current;
        if (tree) {
            tree.loadData(data);
        }
    }, [data, forwardedRef]);

    // event handlers are not passing to the tree through props so fixing it manually
    useEffect(() => {
        const events = [
            { key: 'selectNode', handler: onUpdate }
        ];
        const { tree } = forwardedRef.current;

        if (tree) {
            events.forEach(ev => {
                if (tree._events[ev.key]) {
                    tree.off(ev.key, ev.handler);
                }
                tree.on(ev.key, ev.handler);
            });
        }
        return () => {
            if (tree) {
                events.forEach(ev => {
                    tree.off(ev.key, ev.handler);
                });
            }
        };
    }, [onUpdate, forwardedRef]);

    const rowHeight = rest.statistics ? 48 : 32;

    useEffect(() => {
        const { tree } = forwardedRef.current;

        if (!rest.scrollToNode) {
            return;
        }

        if (!Array.isArray(rest.scrollToNode)) {
            return;
        }

        const nodeIds = [...rest.scrollToNode];

        nodeIds.forEach((id) => {
            let node = tree.getNodeById(id);

            if (!node) {
                return;
            }

            while (true) {
                node = node.parent;

                if (node.id === null) {
                    break;
                }

                if (nodeIds.includes(node.id)) {
                    continue;
                }

                nodeIds.unshift(node.id);
            }
        });

        nodeIds.forEach((id, i) => {
            const node = tree.getNodeById(id);

            if (!node) {
                return;
            }

            tree.openNode(node, { silent: true });

            if (i + 1 === nodeIds.length && (!tree.getSelectedNode() || tree.getSelectedNode().id !== node.id)) {
                tree.selectNode(node, { silent: true });
            }
        });
    }, [forwardedRef, isUpdate, rest.scrollToNode]);

    return (
        <InfiniteTree
            id={id}
            ref={forwardedRef}
            autoOpen={autoOpen}
            selectable
            tabIndex={0}
            data={data}
            width={width}
            height={height}
            rowHeight={rowHeight}
            shouldSelectNode={(node) => { // Defaults to null
                // eslint-disable-next-line no-restricted-globals
                if (event && event.target?.tagName?.toLowerCase() === 'label') {
                    return false;
                }
                const { tree } = forwardedRef.current;

                if (!isDeselecting && node === tree.getSelectedNode()) {
                    return false; // Prevent from deselecting the current node
                }
                return true;
            }}
            onKeyUp={(event) => {
                console.log('onKeyUp', event.target);
            }}
            onKeyDown={(event) => {
                const { tree } = forwardedRef.current;

                const node = tree.getSelectedNode();
                const nodeIndex = tree.getSelectedIndex();

                if (event.keyCode === 37) { // Left
                    tree.closeNode(node);
                } else if (event.keyCode === 38) { // Up
                    const prevNode = tree.nodes[nodeIndex - 1] || node;
                    tree.selectNode(prevNode);
                } else if (event.keyCode === 39) { // Right
                    tree.openNode(node);
                } else if (event.keyCode === 40) { // Down
                    const nextNode = tree.nodes[nodeIndex + 1] || node;
                    tree.selectNode(nextNode);
                }
            }}
            onContentDidUpdate={() => {
                setIsUpdate(true);
            }}
            onSelectNode={onUpdate}
        >
            {({ node, tree }) => {
                const hasChildren = node.hasChildren();
                let toggleState = '';

                if ((!hasChildren && node.loadOnDemand) || (hasChildren && !node.state.open)) {
                    toggleState = 'closed';
                }

                if (hasChildren && node.state.open) {
                    toggleState = 'opened';
                }

                return (
                    <TreeNode {...{
                        node,
                        tree,
                        toggleState,
                        onUpdate,
                        getNodeLink,
                        multipleChoice,
                        checkedChange: onCheckedChange,
                        showUnreadCounters,
                        ...rest
                    }} />
                );
            }}
        </InfiniteTree>
    );
};

Tree.defaultProps = {
    onUpdate: () => {

    },
    width: '100%',
    data: [],
    autoOpen: true,
    wordBreak: false,
    hideIcons: false,
};

Tree.propTypes = {
    onUpdate: PropTypes.func,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.number.isRequired,
    data: PropTypes.array.isRequired,
    multipleChoice: PropTypes.bool,
    onEditNode: PropTypes.func,
    onDeleteNode: PropTypes.func,
    onArchiveNode: PropTypes.func,
    onUnArchiveNode: PropTypes.func,
    autoOpen: PropTypes.bool,
    onAddNode: PropTypes.func,
    id: PropTypes.string,
    // highlight: PropTypes.string,
    highlight: PropTypes.array,
    wordBreak: PropTypes.bool,
    getNodeLink: PropTypes.func,
    hideIcons: PropTypes.bool,
    checkNodeOnSelect: PropTypes.bool,
    toggleNodeOnSelect: PropTypes.bool,
};

export default React.forwardRef((props, ref) => <Tree {...props} forwardedRef={ref}/>);
