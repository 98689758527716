import React, { forwardRef } from 'react';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';
import cx from '../../editor.module.scss';
import classNames from 'classnames';

export const SubMenu = ({ subMenuList = [], mode, currentMenu, setCurrentMenu }) => {
    const menuRef = React.useRef(null);
    const buttonRef = React.useRef(null);

    const [show, setShow] = React.useState(false);

    const toggleMenu = (status = !show) => {
        if (status) {
            if (setCurrentMenu) {
                setCurrentMenu('sub');
            }

            setShow(true);

            if (document.body.offsetWidth > 1024) {
                document.addEventListener('click', closeMenu);
            }
        } else {
            setShow(false);
            document.removeEventListener('click', closeMenu);
        }
    };

    const closeMenu = event => {
        if (buttonRef.current && menuRef.current && !buttonRef.current.contains(event.target) && !menuRef.current.contains(event.target)) {
            document.removeEventListener('click', closeMenu);
            setShow(false);
        }
    };

    React.useEffect(() => {
        if (mode === 'DESKTOP') {
            return;
        }

        setShow(currentMenu === 'sub');
    }, [mode, currentMenu, setCurrentMenu]);

    return (
        <div className={cx.subMenu}>
            <MenuButton ref={buttonRef} tooltip="Меню" className={cx.subMenuButton} icon={Icons.EditorIconMore} onClick={toggleMenu} />
            <Menu ref={menuRef} subMenuList={subMenuList} show={show} toggleMenu={toggleMenu} mode={mode} />
        </div>
    );
};

const Menu = forwardRef(({ subMenuList, show, toggleMenu }, ref) => {
    const commonList = React.useMemo(() => {
        return subMenuList.filter(list => list.type === 'COMMON') || [];
    }, [subMenuList]);

    const mediaList = React.useMemo(() => {
        return subMenuList.filter(list => list.type === 'MEDIA') || [];
    }, [subMenuList]);

    const additionalList = React.useMemo(() => {
        return subMenuList.filter(list => list.type === 'ADDITIONAL') || [];
    }, [subMenuList]);

    const externalList = React.useMemo(() => {
        return subMenuList.filter(list => list.type === 'EXTERNAL') || [];
    }, [subMenuList]);

    const handleMenuClose = el => {
        if (el.label === 'Смайлик' || el.label === 'Форматирование') {
            return;
        }
        toggleMenu(false);
    };

    return (
        <div
            ref={ref}
            className={classNames('sub-menu', cx.subMenuList, {
                [cx.show]: show,
            })}
        >
            {commonList.length > 0 && (
                <>
                    <div className={cx.subMenuHeader}>Стандартные блоки</div>
                    {commonList.map(el => {
                        return (
                            <div
                                key={el.label}
                                className={cx.subMenuItem}
                                onClick={() => {
                                    if ([14, 15, 16, 25].indexOf(el.id) === -1) {
                                        handleMenuClose(el);
                                    }
                                }}
                            >
                                {React.cloneElement(el.component, { isTooltip: false })}
                            </div>
                        );
                    })}
                </>
            )}
            {mediaList.length > 0 && (
                <>
                    <div className={cx.subMenuHeader}>Медиа блоки</div>
                    {mediaList.map(el => {
                        return (
                            <div key={el.label} className={cx.subMenuItem} onClick={() => handleMenuClose(el)}>
                                {React.cloneElement(el.component, { isTooltip: false })}
                            </div>
                        );
                    })}
                </>
            )}
            {additionalList.length > 0 && (
                <>
                    <div className={cx.subMenuHeader}>Дополнительная информация</div>
                    {additionalList.map(el => {
                        return (
                            <div key={el.label} className={cx.subMenuItem} onClick={() => toggleMenu(false)}>
                                {React.cloneElement(el.component, { isTooltip: false })}
                            </div>
                        );
                    })}
                </>
            )}
            {externalList.length > 0 && (
                <>
                    <div className={cx.subMenuHeader}>Внешние ресурсы</div>
                    {externalList.map(el => {
                        return (
                            <div key={el.label} className={cx.subMenuItem} onClick={() => toggleMenu(false)}>
                                {React.cloneElement(el.component, { isTooltip: false })}
                            </div>
                        );
                    })}
                </>
            )}
            <div className={cx.MenuContentClose} onClick={() => toggleMenu(false)}>
                Отмена
            </div>
        </div>
    );
});
