import React, { useState } from 'react';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input/input';
import api from 'api/index';
import formatDateTime from 'lib/helpers/formatDateTime';
import style from 'components/file/file-comments-list-item.module.scss';

const FileCommentsListItem = ({ comment, onLike, onDislike, onEdit, onRemove }) => {
    const [selectedComment, setSelectedComment] = useState('');
    const isEditing = comment.id === selectedComment.id;

    const onLikeHandler = () => {
        onLike(comment.id);
    };

    const onDislikeHandler = () => {
        onDislike(comment.id);
    };

    const onEditHandler = () => {
        setSelectedComment(comment);
    };

    const cancelEdit = () => {
        setSelectedComment('');
    };

    const editComment = async () => {
        try {
            await api.fileComments.editFileComment(selectedComment.id, selectedComment.text);
            setSelectedComment('');
            onEdit();
        } catch (e) {
            console.log(e);
        }
    };

    const onRemoveHandler = () => {
        onRemove(comment.id);
    };

    return (
        <div className={style.comment}>
            <div className={style.commentUser}>
                <div className={style.commentUserAvatar}>
                    <div style={{
                        width: '100%',
                        height: '100%',
                        background: '#EAEDF3',
                        backgroundImage: `url(${api.upload.getImage(comment.user.avatarUuid, false, 128)})`,
                        backgroundSize: 'cover'
                    }}/>
                </div>
                <div>
                    <div className={style.commentUserName}>{comment.user.firstName} {comment.user.lastName}</div>
                    <div className={style.commentDate}>{formatDateTime(new Date(comment.createTime), false, 'в ')}</div>
                </div>
            </div>
            <div className={style.commentContent}>
                {isEditing &&
                <>
                    <Input className={style.commentInput} value={selectedComment?.text} label="Комментарий"
                           onChange={(text) => setSelectedComment({ ...selectedComment, text })}/>
                    <div className={style.buttonBlock}>
                        <div className={style.commentButton} onClick={cancelEdit}>
                            <Icon width={16} height={16} type={Icons.CLOSE_CIRCLE}/>
                            Отмена
                        </div>
                        <div className={style.commentButton} onClick={editComment}>
                            <Icon width={16} height={16} type={Icons.SEND}/>
                            Отправить
                        </div>
                    </div>
                </>}
                {!isEditing && comment.text}
            </div>
            <div className={style.commentCounters}>
                <div>
                    <Icon className={style.commentIcon} type={Icons.LIKE} width={12} height={12} color={'green'}
                          onClick={onLikeHandler}/>
                    {comment.likes !== 0 && <span className={style.likeCount}>{comment.likes}</span>}
                </div>
                <div>
                    <Icon className={style.commentIcon} type={Icons.DISLIKE} width={12} height={12} color={'red'}
                          onClick={onDislikeHandler}/>
                    {comment.dislikes !== 0 && <span className={style.likeCount}>{comment.dislikes}</span>}
                </div>
                <div>
                    <Icon className={style.commentIcon} type={Icons.EDIT_PEN} width={12} height={12} color={'blue'}
                          onClick={onEditHandler}/>
                </div>
                <div>
                    <Icon className={style.commentIcon} type={Icons.TRASH} width={12} height={12} color={'red'}
                          onClick={onRemoveHandler}/>
                </div>
            </div>
        </div>
    );
};

export default FileCommentsListItem;