import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import Modal from 'react-modal';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { useUpload } from 'components/background-upload/upload-provider';
import { FileViewDialog } from 'components/file/file-view-dialog';
import { FileEditDialog } from 'components/file/file-edit-dialog';
import { Empty } from 'components/projects';
import { TableComponent } from 'components/data-components';
import Confirmation from 'components/confirmation';
import { EditRowToolbar } from 'uikit/table';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import style from './files-page-tabs-content.module.scss';
import className from 'classnames';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import getFileTypesOptions from 'lib/helpers/getFileTypeOptions';

export const FilesPageUnpublishedFilesTab = () => {
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const { upload } = useUpload();
    const fileInputRef = useRef();
    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();

    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    const [filesOffset, setFilesOffset] = useState(null);
    const [filesCount, setFilesCount] = useState(null);
    const [filesSort, setFilesSort] = useState(null);
    const [filesFilters, setFilesFilters] = useState(null);
    const [filesTotal, setFilesTotal] = useState(0);
    const [fileSizeRange, setFileSizeRange] = useState([0, 1]);

    const [isLoading, setIsLoading] = useState(true);

    const [filesIsEmpty, setFilesIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const [isViewFileModal, setViewFileModal] = useState(false);
    const [isEditFileModal, setEditFileModal] = useState(false);

    const checkboxHandler = useCallback(node => {
        setCheckedCheckbox(prevValue => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);
    const fetchFiles = useCallback(
        async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
            setIsLoading(true);

            try {
                const res = await api.file.getNotAttachedFileList({
                    page: offset / count,
                    size: count,
                    sort: sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'modifyTime,desc',
                    search,
                    filters,
                });

                setFilesOffset(offset);
                setFilesCount(count);
                setFilesSort(sort);
                setFilesFilters(filters);
                setFilesTotal(res.totalElements);
                setFiles(res.content);

                setIsSearch(!!search);
                setFilesIsEmpty(res.totalElements === 0 && _.isEmpty(filters));
                setFileSizeRange([Math.floor(res.minFileSize / 1000), Math.ceil(res.maxFileSize / 1000)]);

                setIsLoading(false);

                return res;
            } catch (e) {
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                console.log(e);
            }
        },
        [addError]
    );

    const deleteFiles = useCallback(
        async data => {
            try {
                setIsLoading(true);
                closeDialog();

                if (data?.fileId) {
                    await api.file.removeFile(data.fileId);
                } else if (checkedCheckboxRef.current.length) {
                    for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                        await api.file.removeFile(checkedCheckboxRef.current[i].fileId);
                    }
                }

                tableRef?.current?.reload();
                addSuccess(data?.fileId ? 'Файл удален' : 'Выбранные файлы удалены');
            } catch (error) {
                addError('При удалении произошла ошибка');
            } finally {
                setIsLoading(false);
            }
        },
        [closeDialog, addSuccess, addError]
    );
    const onFilesDelete = useCallback(
        async data => {
            if (data?.length) {
                setCheckedCheckbox(
                    data.map(d => {
                        d.original.id = d.original.fileId;
                        return d.original;
                    })
                );
            }

            let text = '';

            if (data?.length) {
                text = data.map(item => {
                    return {
                        ...item.original,
                        text: (
                            <>
                                <span onClick={() => fileSelectHandler(item.original)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                    {item?.original?.name}
                                </span>
                            </>
                        ),
                    };
                });
            } else {
                text = (
                    <span>
                        Вы действительно хотите удалить файл&nbsp;
                        <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => fileSelectHandler(data)}>
                            {data?.name}
                        </span>
                        &nbsp;? Этот процесс нельзя будет отменить
                    </span>
                );
            }

            openDialog({
                title: 'Удаление',
                subTitle: data?.length
                    ? 'Вы действительно хотите удалить нижеперечисленные объекты? Этот процесс нельзя будет отменить'
                    : null,
                text,
                contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
                color: 'red',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Да, удалить',
                onChange: files => checkboxHandler(files),
                onSubmit: () => deleteFiles(data?.length ? checkedCheckboxRef.current : data),
                onClose: closeDialog,
            });
        },
        [checkboxHandler, deleteFiles, openDialog, closeDialog]
    );

    const columns = useMemo(() => {
        return [
            {
                Header: 'Название',
                accessor: 'name',
                width: 400,
                Cell: data => {
                    return (
                        <div
                            className={className(style.nameCell, { [style.mobileNameCell]: data.isMobile })}
                            onClick={() => fileSelectHandler(data.row.original)}
                        >
                            <div className={style.iconWrapper}>
                                <Icon type={FILES_TYPE_ICONS[data.row.original.type] || Icons.NONE_FILE} width={24} height={24} />
                            </div>
                            <div>
                                <MultiClumpTooltip className={style.fileName} label={data.row.original.name} />
                                <div className={style.fileDescription}>
                                    <span className={style.fileType}>{FILES_TYPE_SHORT_NAME[data.row.original.type]}</span> —{' '}
                                    {humanFileSize(data.row.original.size, true)}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: 'Дата создания',
                accessor: 'createTime',
                width: 120,
                Cell: function (data) {
                    const date = new Date(data.row.original.createTime).toLocaleString();

                    return (
                        <div>
                            {data.isMobile && <div className={style.subheader}>Дата создания</div>}
                            {date.substr(0, date.length - 3)}
                        </div>
                    );
                },
            },
            {
                id: 4,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                width: 100,
                Cell: function (data) {
                    const permissions = data.cell.row.original.permissions;
                    let toolbar = [];
                    if (permissions?.rename || permissions?.replace) {
                        toolbar.push({
                            icon: Icons.EDIT_PEN,
                            tooltip: 'Редактировать',
                            onClick: data => {
                                setSelectedFile(data);
                                setEditFileModal(true);
                            },
                        });
                    }
                    if (permissions?.delete) {
                        toolbar.push({
                            icon: Icons.TRASH,
                            tooltip: 'Удалить',
                            onClick: async data => onFilesDelete(data),
                        });
                    }
                    if (permissions?.download) {
                        toolbar.push({
                            icon: Icons.DOWNLOAD,
                            tooltip: 'Скачать',
                            loader: true,
                            onClick: async data => {
                                const res = await api.file.download(data.fileId);
                                const blob = new Blob([res], { type: data.type });
                                saveAs(blob, data.name);
                            },
                        });
                    }
                    return EditRowToolbar(toolbar)(data);
                },
            },
        ];
    }, [onFilesDelete, platform]);
    const filesActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.DOWNLOAD,
            label: 'Скачать',
            onClick: data => {
                console.log(data);
            },
        });
        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: data => onFilesDelete(data),
        });

        return actions;
    }, [onFilesDelete]);
    const _filesFilters = [
        {
            label: 'Формат файла',
            fields: [
                {
                    name: 'type.in',
                    type: 'select',
                    default: null,
                    isMulti: true,
                    options: getFileTypesOptions(),
                },
            ],
        },
        {
            label: 'Размер файла',
            fields: [
                {
                    name: 'fileSize',
                    type: 'range',
                    default: {
                        min: fileSizeRange[0],
                        max: fileSizeRange[1],
                        from: fileSizeRange[0],
                        to: fileSizeRange[1],
                        type: {
                            label: 'Кб',
                            value: 0,
                        },
                        range: fileSizeRange,
                    },
                    options: [
                        {
                            label: 'Кб',
                            value: 0,
                        },
                        {
                            label: 'Мб',
                            value: 1,
                        },
                        {
                            label: 'Гб',
                            value: 2,
                        },
                    ],
                },
            ],
        },
        {
            label: 'Дата загрузки',
            fields: [
                {
                    name: 'createTime',
                    type: 'date-select',
                    default: {
                        type: {
                            label: 'Все время',
                            value: 0,
                        },
                        from: null,
                        to: null,
                    },
                },
            ],
        },
    ];

    const fileSelectHandler = values => {
        setSelectedFile(values);
        setViewFileModal(true);
    };
    const toggleViewModal = () => {
        setViewFileModal(isOpen => !isOpen);
        // fetchFiles();
    };

    const onFileEdit = fileName => {
        setFiles(prevState => {
            return prevState.map(file => {
                if (file.fileId === selectedFile.fileId) {
                    return {
                        ...file,
                        name: fileName,
                    };
                }

                return file;
            });
        });
    };
    const onAddFile = async event => {
        for (const f of event.target.files) {
            upload({ file: f, onFinished: () => fetchFiles() }).start({ inBackground: false });
        }
    };

    const onFilesChange = () => {
        fetchFiles(filesOffset, filesCount, filesSort, filesFilters);
    };

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <>
            <Confirmation {...dialogState} />

            {(!filesIsEmpty || isSearch) && <h1 className={style.title}>Неприкрепленные</h1>}

            {filesIsEmpty && !isSearch && <Empty title="Файлы не найдены" />}

            {(!filesIsEmpty || isSearch) && (
                <>
                    <TableComponent
                        innerRef={tableRef}
                        isLoading={isLoading}
                        searchTitle="Поиск по имени"
                        columns={columns}
                        addTitle="Загрузить файл"
                        onAdd={() => fileInputRef.current && fileInputRef.current.click()}
                        data={files}
                        actions={filesActions}
                        total={filesTotal}
                        filters={_filesFilters}
                        onPaginate={fetchFiles}
                        isMobile={platform === 'mobile'}
                        name="files"
                    />
                    <input style={{ display: 'none' }} type="file" multiple ref={fileInputRef} onChange={onAddFile} />
                </>
            )}

            {isViewFileModal && (
                <FileViewDialog
                    isOpen={isViewFileModal}
                    file={selectedFile}
                    onChange={onFilesChange}
                    onRemove={onFilesChange}
                    handleClose={toggleViewModal}
                />
            )}

            <Modal
                isOpen={isEditFileModal}
                className={style.editModal}
                onRequestClose={() => setEditFileModal(false)}
                overlayClassName={style.editModalOverlay}
            >
                <FileEditDialog file={selectedFile} handleClose={() => setEditFileModal(false)} onFileChange={onFileEdit} />
            </Modal>
        </>
    );
};
