import React from 'react';
import { useDispatch } from 'react-redux';
import { fetchRoles } from 'slice/customRolesSlice';

const CustomRoles = ({ children }) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchRoles());
    }, [dispatch]);

    return (
        <div>
            {children}
        </div>
    );
};

export default CustomRoles;
