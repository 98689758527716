import ApiClient from './api-axios';

export default class CompanyApi extends ApiClient {
    getCompanies = async () => {
        return await this.get('');
    };

    getCompanyById = async (companyId) => {
        return await this.get(`/${companyId}`)
    }

    addCompany = async (data) => {
        return await this.post('', data);
    };

    updateCompany = async (data) => {
        return await this.put(`/${data.id}`, data);
    };
}