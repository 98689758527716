import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';

import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import Icon from 'uikit/icon/icon';
import { Select } from 'uikit/select';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import { desktopStyles } from 'lib/util/modalStyles';
import { buildMultilistOrderedList } from '../../extensions/List/utils';

import cx from '../../editor.module.scss';
import { MULTI_LIST_OPTIONS } from '../../constants';

const PADDING = 16;

export const MenuButtonMultiList = ({ label, onClick }) => {
    return (
        <>
            <MenuButton
                tooltipLabel={label}
                icon={Icons.EditorIconListNumber}
                onClick={onClick}
                label={label}
                style={{ whiteSpace: 'nowrap', display: 'flex' }}
            ></MenuButton>
        </>
    );
};

const traverseLevelsTree = (child, callback) => {
    child = callback(child) || child;

    if (!child.content || !child.content.length) return;

    child.content.forEach(c => traverseLevelsTree(c, callback));
};

const initialValue = buildMultilistOrderedList({});

export const MultiListModal = ({ data, markers, show, onSubmit, onClose }) => {
    const editor = useEditorContext();
    const [levels, setLevels] = useState(initialValue);
    const [selectedLevel, setSelectedLevel] = useState(1);

    const paddingOptions = [
        { label: '1см', value: 1 },
        { label: '2см', value: 2 },
        { label: '3см', value: 3 },
    ];

    const levelsTree = useMemo(() => {
        const result = [];

        traverseLevelsTree(levels, child => {
            if (
                (child.type === 'bulletList' || child.type === 'orderedList') &&
                !result.some(i => i.attrs['data-level'] === child.attrs['data-level'])
            ) {
                result.push(child);
            }

            return child;
        });

        return result;
    }, [levels]);

    useEffect(() => {
        if (data) {
            setLevels(data);
        }
    }, [data, markers]);

    const levelsCount = useMemo(() => levelsTree.length || 1, [levelsTree.length]);

    const handleClose = () => {
        onClose?.();
    };

    const handleSubmit = () => {
        if (typeof onSubmit === 'function') {
            onSubmit?.(
                levels,
                levelsTree.map(list => {
                    return list.attrs['data-list-type'];
                })
            );
            return;
        }

        if (!data) {
            const { $from, $to } = editor.state.selection;
            const wrapRange = $from.pos !== $to.pos;

            if (wrapRange) {
                editor.commands.toggleList(levels.type, 'multiListItem', false, levels.attrs);
            }

            editor.commands.addMultiList(
                levels,
                levelsTree.map(list => {
                    return list.attrs['data-list-type'];
                }),
                wrapRange
            );
        }

        handleClose();
    };

    const decreaseLevels = () => {
        if (levelsCount <= 1) return;

        if (levelsTree.length - 1 < selectedLevel) {
            setSelectedLevel(prev => prev - 1);
        }

        setLevels(prev => {
            const newValue = JSON.parse(JSON.stringify(prev));

            traverseLevelsTree(newValue, node => {
                if (node.attrs?.['data-level'] === levelsCount - 1) {
                    node.content.forEach(listItem => {
                        listItem.content.forEach((n, idx) => {
                            if (n.type === 'orderedList' || n.type === 'bulletList') {
                                listItem.content.splice(idx, 1);
                            }
                        });
                    });
                }

                return node;
            });

            return newValue;
        });
    };

    const increaseLevels = () => {
        if (levelsCount >= 3) return;

        setLevels(prev => {
            const newValue = JSON.parse(JSON.stringify(prev));

            traverseLevelsTree(newValue, node => {
                if (node.attrs?.['data-level'] === levelsCount) {
                    node.content[node.content.length - 1]?.content.push(
                        buildMultilistOrderedList({ attrs: { 'data-level': levelsCount + 1 } })
                    );
                }

                return node;
            });

            return newValue;
        });
    };

    const onMarkerChange = e => {
        setLevels(prev => {
            const newValue = JSON.parse(JSON.stringify(prev));

            traverseLevelsTree(newValue, node => {
                if (node.attrs?.['data-level'] === selectedLevel) {
                    node.attrs['data-list-type'] = e.value;
                    node.type = e.type;
                }
            });

            return newValue;
        });
    };

    const onPaddingChange = e => {
        setLevels(prev => {
            const newValue = JSON.parse(JSON.stringify(prev));

            traverseLevelsTree(newValue, node => {
                if (node.attrs?.['data-level'] === selectedLevel) {
                    node.attrs['data-padding'] = e.value;
                }
            });

            return newValue;
        });
    };

    return (
        <Modal
            isOpen={show}
            preventScroll
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 570, width: '100%', border: 'none' } }}
        >
            <ModalHeader title="Многоуровневый список" onClose={handleClose} />

            <ModalBody style={{ display: 'flex' }}>
                <div className={cx.levelsLeftColumn}>
                    <div className={cx.levelCounterWrapper}>
                        <div>Кол-во уровней:</div>
                        <div className={cx.levelCounter}>
                            <Icon type={Icons.MINUS} width={13} height={13} color="blue" onClick={decreaseLevels} />
                            <div>{levelsTree.length}</div>
                            <Icon type={Icons.PLUS_ADD} width={13} height={13} color="blue" onClick={increaseLevels} />
                        </div>
                    </div>

                    <div className={cx.levelsSelectWrapper}>
                        <div>Уровень:</div>
                        <div className={cx.levelsSelect}>
                            {levelsTree.map((_, idx) => {
                                return (
                                    <div className={selectedLevel === idx + 1 ? cx.active : null} onClick={() => setSelectedLevel(idx + 1)}>
                                        {idx + 1}
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    <div style={{ paddingBottom: 20 }}>
                        <Select
                            label="Маркер для уровня"
                            isMulti={false}
                            value={markers?.find(i => i.value === levelsTree[selectedLevel - 1].attrs['data-list-type'])}
                            onChange={e => onMarkerChange(e)}
                            options={markers}
                            style={{ menuPortal: base => ({ ...base, zIndex: 19999 }) }}
                            menuPortalTarget={document.body}
                        />
                    </div>

                    <div style={{ paddingBottom: 20 }}>
                        <Select
                            label="Отступ"
                            isMulti={false}
                            value={paddingOptions.find(i => i.value === levelsTree[selectedLevel - 1].attrs['data-padding'])}
                            onChange={e => onPaddingChange(e)}
                            options={paddingOptions}
                            style={{ menuPortal: base => ({ ...base, zIndex: 19999 }) }}
                            menuPortalTarget={document.body}
                        />
                    </div>
                </div>

                <div className={cx.levelsRightColumn}>
                    {levelsTree.map((l, idx) => {
                        const TagName = l.type === 'orderedList' ? 'ol' : 'ul';

                        return (
                            <div style={{ width: '100%' }}>
                                <TagName
                                    key={idx}
                                    style={{
                                        listStyleType: `${MULTI_LIST_OPTIONS[l.attrs['data-list-type']]}`,
                                        paddingLeft: l.attrs['data-padding'] * PADDING + PADDING * (idx + 1),
                                    }}
                                >
                                    <li style={{ height: 16, width: '100%', backgroundColor: 'rgba(39, 155, 217, 0.1)' }}></li>
                                </TagName>
                            </div>
                        );
                    })}
                </div>
            </ModalBody>

            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: handleClose },
                    { label: 'Сохранить', color: 'green', onClick: handleSubmit },
                ]}
            />
        </Modal>
    );
};
