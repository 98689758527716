import React, { useCallback, useEffect, useState } from 'react';
import { Project } from 'uikit/panel';
import Confirmation from 'components/confirmation';
import cx from './favorites-page-tab-projects.module.scss';
import api from 'api';
import { useDialog, useMessage } from 'lib/hooks';

const FavoritesPageTabProjects = ({ navigate, onCountChangeHandler }) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const {addError} = useMessage();

    const [projects, setProjects] = useState([]);

    const fetchFavoritesProjects = useCallback(async () => {
        try {
            const res = await api.favorites.getFavoritesProjects({ page: 0, size: 2000 });

            const projects = res.content.map((project) => {
                return {
                    id: project['resource']['id'],
                    image: '',
                    title: project['resource']['title'],
                    managerLogin: project['resource']['author']['login'],
                    managerShortName:
                        project['resource']['author']['firstName'] + ' ' + project['resource']['author']['lastName'].slice(0, 1) + '.',
                    manager: project['resource']['author']['firstName'] + ' ' + project['resource']['author']['lastName'],
                    activityUserLogin: project['lastAction']
                        ? project['resource']['lastAction']['userInfo']['login']
                        : project['resource']['author']['login'],
                    activityUser: project['resource']['lastAction']
                        ? project['resource']['lastAction']['userInfo']['firstName'] +
                          ' ' +
                          project['resource']['lastAction']['userInfo']['lastName']
                        : project['resource']['author']['firstName'] + ' ' + project['resource']['author']['lastName'],
                    activityUserAvatar: project['resource']['lastAction']
                        ? api.upload.getImage(project['resource']['lastAction']['userInfo']['avatarUuid'], false, 128)
                        : api.upload.getImage(project['resource']['author']['avatarUuid'], false, 128),
                    activityDate: project['resource']['lastAction']
                        ? new Date(project['resource']['lastAction']['timestamp'])
                        : new Date(project['resource']['createTime']),
                    articles: project['resource']['numberOfArticles'],
                    news: project['resource']['numberOfNews'],
                    files: project['resource']['numberOfAttachments'],
                    members: project['resource']['numberOfMembers'],
                    logoUuid: project['resource']['logoUuid'],
                    smallLogoUuid: project['resource']['smallLogoUuid'],
                    favorite: true,
                };
            });

            setProjects(projects);
        } catch {}
    }, []);

    const deleteFromFavorites = async (id) => {
        try {
            await api.favorites.deleteProjectFromFavorites(id);
            await fetchFavoritesProjects();

            onCountChangeHandler();
            closeDialog();
        } catch (error) {
            addError('Произошла ошибка при удалении из избранного')
        }
    };

    const onDeleteFromFavorites = (id, title) => {
        const text = (
            <span>
                Вы действительно хотите удалить проект 
                <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/projects/${id}/articles`)}>
                    {title}
                </span>{' '}
                из избранного?
            </span>
        );
        openDialog({
            title: 'Удаление из избранного',
            text,
            contentType: 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onSubmit: () => deleteFromFavorites(id),
            onClose: closeDialog,
        });
    };

    useEffect(() => {
        fetchFavoritesProjects();
    }, [fetchFavoritesProjects]);

    return (
        <>
            <Confirmation {...dialogState} />

            <div className={cx.content}>
                {projects.length !== 0 &&
                    projects.map((item) => {
                        return (
                            <div key={item.id} className={cx.projectWrapper}>
                                <Project
                                    id={item.id}
                                    image={
                                        item['logoUuid']
                                            ? api.upload.getImage(item.logoUuid, false, 128)
                                            : `${process.env.PUBLIC_URL + '/img/default-icon-project.jpg'}`
                                    }
                                    title={item.title}
                                    managerLogin={item.managerLogin}
                                    manager={item.manager}
                                    activityUser={item.activityUser}
                                    activityUserAvatar={item.activityUserAvatar}
                                    activityDate={item.activityDate}
                                    activityUserLogin={item.activityUserLogin}
                                    isFavoritesProject={true}
                                    articles={item.articles}
                                    news={item.news}
                                    files={item.files}
                                    members={item.members}
                                    favorite={item.favorite}
                                    smallLogoUuid={item.smallLogoUuid}
                                    link={`/projects/${item.id}/articles`}
                                    managerShortName={item.managerShortName}
                                    onDeleteFromFavorites={onDeleteFromFavorites}
                                />
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default FavoritesPageTabProjects;
