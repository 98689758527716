import ApiClient from './api-axios';

export default class AuthApi extends ApiClient {
    login = async ({ username, password }) => {
        return await this.axios().post(this.prefix + 'authorization', { login: username, password });
    };

    getAccount = async () => {
        return await this.axios().get(this.prefix + 'account');
    };

    logout = async () => {
        return await this.post('logout', {});
    };
}