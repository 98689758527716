import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMessage, useAuthorized, useDialog, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectRoles, selectLoading, deleteRole } from 'slice/customRolesSlice';
import PrivatePage from 'containers/private-page-wrapper';
import { Confirmation } from 'components/confirmation/confirmation';
import Loading from 'uikit/loading';
import Filter from 'uikit/filter/filter';
import Table, { EditRowToolbar } from 'uikit/table';
import { Icons } from 'uikit/icon';
import api from 'api';
import cs from './custom-roles-list.module.scss';
import { Link } from '@reach/router';

const CustomRolesList = ({ hasAnyAuthorities, navigate }) => {
    const { platform } = useGlobalContext();

    const dispatch = useDispatch();
    const _roles = useSelector(selectRoles);
    const loading = useSelector(selectLoading);

    const { addSuccess, addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const { isAuthorizedAction } = useAuthorized();
    const [roles, setRoles] = useState(_roles);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const [rolesTableSort, setRolesTableSort] = useState({ by: '', desc: false });

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox])

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
          if (prevValue.some(c => c.id === node.id)) {
              return prevValue.filter(c => c.id !== node.id);
          } else {
              return [...prevValue, node]
          }
        })
    }, [])

    const deleteRoles = useCallback(async (data) => {
        closeDialog();

        try {
            if (data.id) {
                await api.customRoles.deleteRole(data.id);
                dispatch(deleteRole(data.id));

            } else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                  await api.customRoles.deleteRole(checkedCheckboxRef.current[i].id);
                  dispatch(deleteRole(checkedCheckboxRef.current[i].id));
                }
            } else {
              return;
            }
        } catch (error) {
           addError('При удалении произошла ошибка')
        }

        addSuccess(data.id ? 'Роль удалена' : 'Выбранные роли удалены')
    }, [dispatch, closeDialog, addSuccess, addError]);

    const onRolesDelete = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        const text = data?.length ?
            data.map(item => {
                return {
                    ...item.original,
                    text: (
                      <>
                        <span style={{ color: '#279BD9', cursor: 'pointer' }}>
                          {item?.original?.title}
                        </span>
                      </>
                    )
                }
            }) : (
                <span>
                    Вы действительно хотите удалить роль 
                    <span
                        style={{ color: '#279BD9', cursor: 'pointer' }}
                        onClick={() => navigate(`/projects/news/${data['id']}`)}
                    >
                        {data?.title}
                    </span> ? Этот процесс нельзя будет отменить
                </span>
            )
        const subTitle = data?.length ? 'Вы действительно хотите удалить нижеперечисленные роли? Этот процесс нельзя будет отменить' : null

        openDialog({
            title: 'Удаление',
            text,
            subTitle,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            color: 'red',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (roles) => checkboxHandler(roles),
            onSubmit: () => deleteRoles(data),
            onClose: closeDialog,
        })
    }, [openDialog, closeDialog, deleteRoles, navigate, checkboxHandler])

    const rolesActions = (() => {
        if (!isAuthorizedAction([GLOBAL_ACTIONS.CUSTOM_ROLE_DELETE])) {
            return [];
        } else {
            return [{
                icon: Icons.TRASH,
                label: 'Удалить',
                onClick: (data) => onRolesDelete(data)
            }];
        }
    })();

    const columns = useMemo(() => {
        return [
            {
                Header: 'Наименование роли',
                accessor: 'title',
                align: 'center',
                Cell: (data) => {
                    return <Link to={`/global-settings/custom-roles/role/${data.row.original.id}`} className={cs.roleTitle}>
                        {data.isMobile && <div className={cs.subheader}>Название роли</div>}
                        {data.isMobile && data.row.original['title']}
                        {!data.isMobile && <p>{data.row.original['title']}</p>}
                    </Link>
                }
            },
            {
                Header: 'Тип роли',
                accessor: 'name',
                align: 'center',
                Cell: (data) => {
                    return <>
                        {data.isMobile && <div className={cs.subheader}>Тип</div>}
                        <div>{data.row.original['name']}</div>
                    </>
                }
            },
            {
                id: 4,
                Header: '',
                align: 'center',
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: data => {
                    let toolbar = [];
                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        onClick: data => {
                            navigate(`/global-settings/custom-roles/role/${data.id}`);
                        },
                        isHidden: (data) => !isAuthorizedAction([GLOBAL_ACTIONS.CUSTOM_ROLE_EDIT]) || data.defaultRole
                    });
                    toolbar.push({
                        icon: Icons.DELETE,
                        tooltip: 'Удалить',
                        onClick: (data) => onRolesDelete(data),
                        isHidden: (data) => !isAuthorizedAction([GLOBAL_ACTIONS.CUSTOM_ROLE_DELETE]) || data.defaultRole
                    });
                    return EditRowToolbar(toolbar, null, true, false, true)(data);
                }
            }
        ];
    }, [isAuthorizedAction, navigate, onRolesDelete, platform]);

    const onRolesTableSort = async (column) => {
        const sort = Object.assign({}, rolesTableSort);

        if (sort.by === column.id) {
            sort.desc = !sort.desc;
        } else {
            sort.by = column.id;
            sort.desc = false;
        }

        setRolesTableSort(sort);
    };

    useEffect(() => {
        if (_roles) {
            setRoles([..._roles.map(role => ({ ...role, rowCanChecked: !role.defaultRole }))]);
        }
    }, [_roles]);

    useEffect(() => {
        if (rolesTableSort.by) {
            setRoles(roles => {
                const rolesDataNext = Object.assign([], roles);
                return rolesDataNext.sort((a, b) => {
                    if (a[rolesTableSort.by] > b[rolesTableSort.by]) {
                        return rolesTableSort.desc ? 1 : -1;
                    } else if (a[rolesTableSort.by] < b[rolesTableSort.by]) {
                        return rolesTableSort.desc ? -1 : 1;
                    }

                    return 0;
                });
            });
        }
    }, [rolesTableSort]);

    const filterRoles = str => {
        if (!str || str === '') {
            setRoles([..._roles]);
        } else {
            setRoles(_roles.filter(r => r.name.includes(str) || r.title.includes(str)));
        }
    };

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />

            <div className={cs.contentWrapper}>
                <Loading active={loading}/>
                <div className={cs.title}>Роли</div>
                <div className={cs.table}>
                    <div className={cs.filter}>
                        <Filter id={'customRolesFilter'} add={'Создать роль'} addLink={'/global-settings/custom-roles/add'} canAdd={true} onSearch={filterRoles}/>
                    </div>
                    <Table
                        columns={columns}
                        data={roles}
                        sort={rolesTableSort}
                        actions={rolesActions}
                        onSort={onRolesTableSort}
                        isMobile={platform === 'mobile'}
                    />
                </div>
            </div>
        </PrivatePage>
    );
};

export default CustomRolesList;
