import { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

export function useMessage() {
    const { addToast } = useToasts();

    const addSuccess = useCallback((text) => {
        addToast(text, { appearance: 'success', autoDismiss: true });
    }, [addToast]);

    const addInfo = useCallback((text) => {
        addToast(text, { appearance: 'info', autoDismiss: true });
    }, [addToast]);

    const addError = useCallback((text) => {
        addToast(text, { appearance: 'error', autoDismiss: true });
    }, [addToast]);

    return { addSuccess, addInfo, addError };
}