import ApiClient from './api-axios';

export default class NewsApi extends ApiClient {
    initNews = async () => {
      return await this.post('news/initialize');
    };

    getNews = async (page = 0, size = 20, sort = 'modifyTime,desc', search = '', filters = {}) => {
        const params = new URLSearchParams(Object.assign({ page, size }));

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['expireTime.greaterOrEqualThan']) {
            params.append('expireTime.greaterOrEqualThan', new Date(filters['expireTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['priority.in'] && filters['priority.in']['value']) {
            params.append('priority.in', filters['priority.in']['value']);
        }

        if (filters['global.in'] && filters['global.in']['value']) {
            params.append('global.in', filters['global.in']['value']);
        }

        if (filters['publishTicker.equals'] && filters['publishTicker.equals']['value']) {
            params.append('publishTicker.equals', filters['publishTicker.equals']['value']);
        }

        if (filters['containsAttachment.equals'] && filters['containsAttachment.equals']['value']) {
            params.append('containsAttachment.equals', filters['containsAttachment.equals']['value']);
        }

        if (filters['readied.in'] && filters['readied.in']['value']) {
            params.append('readied.in', filters['readied.in']['value']);
        }

        if (filters['expireTime.lessOrEqualThan']) {
            params.append('expireTime.lessOrEqualThan', new Date(filters['expireTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append('authorLogin.in', filters['authorLogin.in'].map((filter) => filter.value));
        }

        if (filters['uuid.in']) {
            params.append('uuid.in', filters['uuid.in']);
        }

        let request = 'news?' + params.toString();

        if (Array.isArray(sort)) {
            sort.map((sortItem) => request += '&sort=' + sortItem);
        } else {
            request += '&sort=' + sort;
        }

        return await this.get(request);
    };

    getDraftNews = async ({ page = 0, size = 20, sort = 'createTime,desc', filters = {}, search }) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['expireTime.greaterOrEqualThan']) {
            params.append('expireTime.greaterOrEqualThan', new Date(filters['expireTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['expireTime.lessOrEqualThan']) {
            params.append('expireTime.lessOrEqualThan', new Date(filters['expireTime.lessOrEqualThan']).toISOString());
        }

        return await this.get('news-draft?' + params.toString());
    };

    getDelayedNews = async ({ page = 0, size = 20, sort = '', search = '', filters = {} }) => {
        const params = new URLSearchParams(Object.assign({ page, size }));

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }

            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['publicationTime']) {
            if (filters['publicationTime']['from'] !== null) {
                params.append('publicationTime.greaterOrEqualThan', new Date(filters['publicationTime']['from']).toISOString());
            }

            if (filters['publicationTime']['to'] !== null) {
                params.append('publicationTime.lessOrEqualThan', new Date(filters['publicationTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        let request = 'delayed-news/user?' + params.toString();

        if (Array.isArray(sort)) {
            sort.map((sortItem) => request += '&sort=' + sortItem);
        } else {
            request += '&sort=' + sort;
        }

        return await this.get(request);
    };

    getNewsTitles = async (page = 0, size = 20, sort = '') => {
      const params = new URLSearchParams(Object.assign({ page, size, sort }));

      return await this.get('news/public/titles?' + params.toString());
    }

    getLogo = (uuid, async = false, scale = 512) => {
        if (async) {
            return async () => await this.get(
                'news/logo',
                { uuid },
                false,
                'arraybuffer',
                { 'Accept': 'image/png', 'Content-Type': 'image/png' }
            );
        }

        return this.prefix + 'news/logo?uuid=' + uuid + '&scale=' + scale;
    };

    getDraftNewsLogo = (uuid, async = false) => {
        if (async) {
            return async () => await this.get(
                'news-draft/logo',
                { uuid },
                false,
                'arraybuffer',
                { 'Accept': 'image/png', 'Content-Type': 'image/png' }
            );
        }

        return this.prefix + 'news-draft/logo?uuid=' + uuid;
    };

    postNews = async (news) => {
        return await this.post('news', news);
    };

    postNewsDraft = async (news) => {
        return await this.post('news-draft', news);
    };

    postDelayedNews = async (news) => {
        return await this.post('delayed-news', news);
    }

    deleteNews = async (id) => {
        return await this.post('news/delete', { newsId: id });
    };

    deleteDraftNews = async (id) => {
        const formData = new FormData();
        formData.append('uuid', id);
        return await this.post(`news-draft/delete/${id}`, formData);
    };

    deleteDelayedNews = async (id) => {
        return await this.deleteRequest(`delayed-news/${id}`);
    }

    getProjectNews = async (project, page = 0, size = 20, sort = 'modifyTime,desc', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }

            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['expireTime.greaterOrEqualThan']) {
            params.append('expireTime.greaterOrEqualThan', new Date(filters['expireTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['expireTime.lessOrEqualThan']) {
            params.append('expireTime.lessOrEqualThan', new Date(filters['expireTime.lessOrEqualThan']).toISOString());
        }

        if (filters['priority.in'] && filters['priority.in']['value']) {
            params.append('priority.in', filters['priority.in']['value']);
        }

        if (filters['publishTicker.equals'] && filters['publishTicker.equals']['value']) {
            params.append('publishTicker.equals', filters['publishTicker.equals']['value']);
        }

        if (filters['containsAttachment.equals'] && filters['containsAttachment.equals']['value']) {
            params.append('containsAttachment.equals', filters['containsAttachment.equals']['value']);
        }

        if (filters['readied.in'] && filters['readied.in']['value']) {
            params.append('readied.in', filters['readied.in']['value']);
        }

        if (filters['authorLogin.in']) {
            params.append('authorLogin.in', filters['authorLogin.in'].map((filter) => filter.value));
        }

        return await this.get(`news/project/${project}?` + params);
    };

    getProjectDelayedNews = async (projectUuid, page = 0, size = 20, sort = 'publicationTime,desc', search = '', filters = {}) => {
        const params = new URLSearchParams(Object.assign({ page, size }));

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['publicationTime']) {
            if (filters['publicationTime']['from'] !== null) {
                params.append('publicationTime.greaterOrEqualThan', new Date(filters['publicationTime']['from']).toISOString());
            }
            if (filters['publicationTime']['to'] !== null) {
                params.append('publicationTime.lessOrEqualThan', new Date(filters['publicationTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        let request = `delayed-news/project/${projectUuid}?` + params.toString();

        if (Array.isArray(sort)) {
            sort.map((sortItem) => request += '&sort=' + sortItem);
        } else {
            request += '&sort=' + sort;
        }

        return await this.get(request);
    };

    getNewsById = async (uuid) => {
        return await this.get(`news/${uuid}`);
    };

    getNewsDraftById = async (uuid) => {
        return await this.get(`news-draft/${uuid}`);
    };

    getDelayedNewsById = async (uuid) => {
        return await this.get(`delayed-news/${uuid}`);
    }

    readNews = async (uuid, time) => {
        return await this.post(`news/${uuid}/read?readDurationMillis=${time * 1000}`);
    };

    getReadNewsTime = async (uuid) => {
        return await this.get(`news/${uuid}/read`);
    };

    editNews = async news => {
        return await this.post('news/edit', news);
    };

    editNewsDraft = async news => {
        return await this.post('news-draft/edit', news);
    };

    editDelayedNews = async news => {
        return await this.post('delayed-news/edit', news);
    }

    publishNewsDraft = async news => {
        return await this.post('news-draft/save-and-publish', news);
    };

    publishDelayedNews = async uuid => {
        return await this.post(`delayed-news/publish/${uuid}`);
    }

    getTickers = async () => {
        return await this.get('/ticker');
    };

    deleteTicker = async (id) => {
        return await this.deleteRequest('/ticker/' + id);
    };

    getDelayedNewsCount = async () => {
        return await this.get('delayed-news/count');
    }
}
