import React, { useMemo, useState } from 'react';
import { useGlobalContext } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import { TableComponent } from 'components/data-components';
import LicenseUploadModal from './LicenseUploadModal';
import LicenseViewModal from './LicenseViewModal';
import cs from './LicensePage.module.scss';

const ActiveLicenses = ({ activeLicenses, activeLicensesTotal, getActiveLicenses, getLicenseInfo }) => {
    const { platform } = useGlobalContext();

    const [uploadModal, setUploadModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Пакет лицензий',
                accessor: 'licenseName',
                width: 60,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Пакет лицензий</div>}
                        {data.row.original['licenseName']}
                    </div>
            },
            {
                Header: 'Компания',
                accessor: 'companyName',
                width: 60,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Компания</div>}
                        {data.row.original['companyName']}
                    </div>
            },
            {
                Header: 'Выдана',
                accessor: 'createDate',
                width: 60,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Выдана</div>}
                        {new Date(data.row.original['createDate']).toLocaleString().slice(0, -3)}
                    </div>
            },
            {
                Header: 'Дата окончания',
                accessor: 'expirationDate',
                width: 60,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Дата окончания</div>}
                        {new Date(data.row.original['expirationDate']).toLocaleString().slice(0, -3)}
                    </div>
            },
            {
                Header: 'Лицензий',
                accessor: 'numberOfLicenses',
                width: 60,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Лицензий</div>}
                        {data.row.original['numberOfLicenses']}
                    </div>
            },
            {
                Header: 'Осталось дней',
                accessor: 'daysLeft',
                width: 60,
                Cell: (data) =>
                    <div>
                        {data.isMobile && <div className={cs.subheader}>Осталось дней</div>}
                        {data.row.original['daysLeft']}
                    </div>
            },
        ];
    }, []);

    const toggleUploadModal = () => {
        setUploadModal(!uploadModal);
    };
    const handleFileUpload = () => {
        getLicenseInfo();
        getActiveLicenses();

        toggleUploadModal();
        setViewModal(true);
    };

    return (
        <div className={cs.activeLicenses}>
            <LicenseUploadModal
                open={uploadModal}
                onCloseModal={toggleUploadModal}
                onUpload={handleFileUpload}
            />
            <LicenseViewModal
                open={viewModal}
                license={activeLicenses[activeLicenses.length - 1] || {}}
                onCloseModal={() => setViewModal(false)}
            />
            <div className={cs.activeLicensesTitle}>
                <h1>Пакеты лицензий</h1>
                <button className={cs.addBtn} onClick={toggleUploadModal}>
                    <div className={cs.icon}>
                        <Icon type={Icons.ADD} width={8} height={8}/>
                    </div>
                    Загрузить лицензию
                </button>
            </div>
            <TableComponent
                name="activeLicense"
                searchTitle="Поиск по названию и компании"
                columns={columns}
                data={activeLicenses}
                total={activeLicensesTotal}
                isPaginate={false}
                onPaginate={getActiveLicenses}
                isMobile={platform === 'mobile'}
            />
        </div>
    );
};

export default ActiveLicenses;
