import Bold from '@tiptap/extension-bold';

const CustomBold = Bold.extend({
    addAttributes() {
        return {
            style: {
                default: null,
            },
        };
    },
});

export default CustomBold;
