import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { pushNotificationEvent } from '../EditorNotificationProvider';

export const CopyFormat = Extension.create({
    name: 'copyFormat',

    addStorage() {
        return {
            marks: [],
        };
    },

    addCommands() {
        return {
            copyFormat:
                () =>
                ({ tr, editor }) => {
                    const { doc, selection } = tr;
                    const { from, to } = selection;
                    let marks = new Set();

                    doc.nodesBetween(from, to, node => {
                        node.marks.forEach(nodeMark => {
                            if (!marks.has(nodeMark.type.name)) {
                                marks.add(nodeMark);
                            }
                        });
                    });

                    let result = [...Array.from(marks)];

                    editor.storage.copyFormat.marks = result;
                },
        };
    },

    addProseMirrorPlugins() {
        const plugins = [];
        const _editor = this.editor;

        plugins.push(
            new Plugin({
                key: new PluginKey('handleClickDetails'),
                props: {
                    handleDOMEvents: {
                        mouseup(view) {
                            if (_editor.storage.copyFormat.marks.length) {
                                const from = view.state.selection.$anchor.pos;
                                const to = view.state.selection.$head.pos;

                                const marks = _editor?.storage.copyFormat.marks.filter(m => m.type);

                                _editor
                                    .chain()
                                    .focus()
                                    .command(({ tr, dispatch }) => {
                                        marks.forEach(m => {
                                            tr.addMark(from < to ? from : to, to > from ? to : from, m);
                                        });

                                        return dispatch(tr);
                                    })
                                    .run();
                                pushNotificationEvent({ message: 'Форматирование успешно скопировано!' });
                                _editor.storage.copyFormat.marks = [];
                            }

                            return true;
                        },
                    },
                },
            })
        );

        return plugins;
    },
});
