const rtfRecognizableImageTypes = [
    {
        marker: /\\pngblip/,
        type: 'image/png'
    },

    {
        marker: /\\jpegblip/,
        type: 'image/jpeg'
    },

    {
        marker: /\\emfblip/,
        type: 'image/emf'
    },

    {
        marker: /\\wmetafile\d/,
        type: 'image/wmf'
    }
];
const rtfSupportedImageTypes = [
    'image/png',
    'image/jpeg',
    'image/gif'
];

function rtfGetNonWhitespaceChar( content, startIndex, direction ) {
    const whiteSpaceRegex = /[\s]/;

    let index = startIndex + direction;
    let current = content[index];

    while (current && whiteSpaceRegex.test(current)) {
        index = index + direction;
        current = content[ index ];
    }

    return current;
}
function rtfGetPreviousNonWhitespaceChar(content, index) {
    return rtfGetNonWhitespaceChar(content, index, -1);
}
function rtfGetNextNonWhitespaceChar(content, index) {
    return rtfGetNonWhitespaceChar(content, index, 1);
}

function rtfGetGroup(content, groupName, options) {
    let open = 0;

    let startRegex = new RegExp( '\\{\\\\' + groupName, 'g' );
    let group;

    let i;
    let current;

    const mergedOptions = { ...{ start: 0 }, ...options || {} };

    startRegex.lastIndex = mergedOptions.start;
    group = startRegex.exec(content);

    if (!group) {
        return null;
    }

    i = group.index;
    current = content[i];

    do {
        const isValidGroupStart = current === '{'
            && rtfGetPreviousNonWhitespaceChar(content, i) !== '\\'
            && rtfGetNextNonWhitespaceChar(content, i) === '\\';

        const isValidGroupEnd = current === '}'
            && rtfGetPreviousNonWhitespaceChar(content, i) !== '\\'
            && open > 0;

        if (isValidGroupStart) {
            open++;
        } else if (isValidGroupEnd) {
            open--;
        }

        current = content[ ++i ];
    } while (current && open > 0);

    return {
        start: group.index,
        end: i,
        content: content.substring(group.index, i)
    };
}
function rtfRemoveGroups(rtfContent, groupName) {
    let current = rtfGetGroup(rtfContent, groupName);

    while (current) {
        const beforeContent = rtfContent.substring(0, current.start);
        const afterContent = rtfContent.substring(current.end);

        rtfContent = beforeContent + afterContent;
        current = rtfGetGroup(rtfContent, groupName);
    }

    return rtfContent;
}
function rtfGetGroups(rtfContent, groupName) {
    let groups = [];

    let from = 0;
    let current = rtfGetGroup(rtfContent, groupName, { start: from });

    while (current) {
        from = current.end;

        groups.push(current);
        current = rtfGetGroup(rtfContent, groupName, { start: from })
    }

    return groups;
}

function rtfGetImageIndex(hexImages, id) {
    if (typeof id !== 'string') {
        return -1;
    }

    return hexImages.findIndex(image => image.id === id);
}
function rtfGetImageId(image) {
    const blipUidRegex = /\\blipuid (\w+)\}/;
    const blipTagRegex = /\\bliptag(-?\d+)/;

    const blipUidMatch = image.match(blipUidRegex);
    const blipTagMatch = image.match(blipTagRegex);

    if (blipUidMatch) {
        return blipUidMatch[1];
    } else if (blipTagMatch) {
        return blipTagMatch[1];
    }

    return null;
}

function rtfGetGroupName(group) {
    const groupNameRegex = /^\{\\(\w+)/;
    const groupName = group.match(groupNameRegex);

    if (!groupName) {
        return null;
    }

    return groupName[1];
}
function rtfExtractGroupContent(group) {
    const groupName = rtfGetGroupName(group);

    const controlWordsRegex = /^\{(\\[\w-]+\s*)+/g;
    const subgroupWithoutSpaceRegex = /\}([^{\s]+)/g;

    group = group.replace(subgroupWithoutSpaceRegex, '} $1');
    group = rtfRemoveGroups(group, '(?!' + groupName + ')');
    group = group.replace(controlWordsRegex, '').trim();

    return group.replace(/}$/, '');
}
function rtfGetImageContent(image) {
    const content = rtfExtractGroupContent(image);
    return content.replace(/\s/g, '');
}

function rtfGetImageType(imageContent) {
    const extractedType = rtfRecognizableImageTypes
        .find(test => test.marker.test(imageContent));

    if (extractedType) {
        return extractedType.type;
    }

    return 'unknown';
}

function rtfConvertHexStringToBytes(hexString) {
    const bytesArray = [];
    const bytesArrayLength = hexString.length / 2;

    for (let i = 0; i < bytesArrayLength; i++) {
        bytesArray.push(parseInt(hexString.substr(i * 2, 2), 16));
    }

    return bytesArray;
}
function rtfConvertBytesToBase64(bytesArray) {
    const base64characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    let base64string = '';
    const bytesArrayLength = bytesArray.length;

    for (let i = 0; i < bytesArrayLength; i += 3) {
        const array3 = bytesArray.slice(i, i + 3);
        const array3length = array3.length;
        const array4 = [];

        if (array3length < 3) {
            for (let j = array3length; j < 3; j++) {
                array3[j] = 0;
            }
        }

        array4[0] = (array3[0] & 0xFC) >> 2;
        array4[1] = ((array3[0] & 0x03) << 4) | (array3[1] >> 4);
        array4[2] = ((array3[1] & 0x0F) << 2) | ((array3[2] & 0xC0) >> 6);
        array4[3] = array3[2] & 0x3F;

        for (let j = 0; j < 4; j++) {
            if (j <= array3length) {
                base64string += base64characters.charAt(array4[j]);
            } else {
                base64string += '=';
            }
        }
    }

    return base64string;
}
function rtfCreateSrcWithBase64(img) {
    const isSupportedType = rtfSupportedImageTypes.indexOf(img.type) !== -1;
    let data = img.hex;

    if (!isSupportedType) {
        return null;
    }

    if (typeof data === 'string')  {
        data = rtfConvertHexStringToBytes(img.hex);
    }

    return img.type ? 'data:' + img.type + ';base64,' + rtfConvertBytesToBase64(data) : null;
}

export default function pasteRtfImages(html, rtf) {
    const parser = new DOMParser();

    const doc = parser.parseFromString(html, "text/html");
    const imgTags = doc.querySelectorAll('img');

    if (imgTags.length === 0) {
        return html;
    }

    let isLocal = false;

    for (const imgTag of imgTags) {
        if (imgTag.src.indexOf('file:') === -1) {
            continue;
        }

        isLocal = true;
    }

    if (!isLocal) {
        return html;
    }

    const rtfContent = rtfRemoveGroups(rtf, '(?:(?:header|footer)[lrf]?|nonshppict|shprslt)');
    const wholeImages = rtfGetGroups(rtfContent, 'pict');

    if (!wholeImages) {
        return html;
    }

    const hexImages = [];

    for (let i = 0; i < wholeImages.length; i++) {
        let currentImage = wholeImages[i].content;
        const imageId = rtfGetImageId(currentImage);

        const imageType = rtfGetImageType(currentImage);
        const imageDataIndex = rtfGetImageIndex(hexImages, imageId);

        const isAlreadyExtracted = imageDataIndex !== -1 && hexImages[imageDataIndex].hex;
        const isDuplicated = isAlreadyExtracted && hexImages[imageDataIndex].type === imageType;

        const isAlternateFormat = isAlreadyExtracted
            && hexImages[imageDataIndex].type !== imageType
            && imageDataIndex === imageDataIndex.length - 1;

        const isWordArtShape = currentImage.indexOf('\\defshp') !== -1;
        const isSupportedType = true;

        if (isDuplicated) {
            hexImages.push(hexImages[imageDataIndex]);
            continue;
        }

        if (isAlternateFormat || isWordArtShape) {
            continue;
        }

        const newImageData = {
            id: imageId,
            hex: isSupportedType ? rtfGetImageContent(currentImage) : null,
            type: imageType
        };

        if (imageDataIndex !== -1) {
            hexImages.splice(imageDataIndex, 1, newImageData);
        } else {
            hexImages.push(newImageData);
        }
    }

    if (hexImages.length === 0) {
        return html;
    }

    const newSrcValues = hexImages.map(img => rtfCreateSrcWithBase64(img));

    if (imgTags.length !== newSrcValues.length) {
        return html;
    }

    for (let i = 0; i < imgTags.length; i++) {
        if (imgTags[i].src.indexOf('file://') !== 0) {
            continue;
        }

        if (!newSrcValues[i]) {
            continue;
        }

        const escapedPath = imgTags[i].src.replace(/\\/g, '\\\\');
        const imgRegex = new RegExp('(<img [^>]*src=["\']?)' + escapedPath);

        html = html.replace(imgRegex, '$1' + newSrcValues[i]);
    }

    return html;
}
