import { Plugin } from '@tiptap/pm/state';
import { tableEditingKey } from './utils';
import { drawCellSelection, normalizeSelection } from './cellSelection';
import { handleKeyDown, handleMouseDown, handlePaste, handleTripleClick } from './input';
import { fixTables } from './fixTables';

export function tableEditing({ allowTableNodeSelection = false } = {}) {
    return new Plugin({
        key: tableEditingKey,

        state: {
            init() {
                return null;
            },
            apply(tr, cur) {
                const set = tr.getMeta(tableEditingKey);
                if (set != null) return set === -1 ? null : set;
                if (cur == null || !tr.docChanged) return cur;
                const { deleted, pos } = tr.mapping.mapResult(cur);
                return deleted ? null : pos;
            },
        },

        props: {
            decorations: drawCellSelection,

            handleDOMEvents: {
                mousedown: handleMouseDown,
            },

            createSelectionBetween(view) {
                return tableEditingKey.getState(view.state) != null ? view.state.selection : null;
            },

            handleTripleClick,

            handleKeyDown,

            handlePaste,
        },

        appendTransaction(_, oldState, state) {
            return normalizeSelection(state, fixTables(state, oldState), allowTableNodeSelection);
        },
    });
}
