import ApiClient from './api-axios';

export default class LicenseApi extends ApiClient {
    getLicenseInfo = async () => {
        return await this.get('/info');
    }

    getActiveLicenses = async (_page = 0, _size = 20, _sort = 'modifyTime,desc', _search = '', _filters = {}) => {
        return await this.get('/active');
    }

    getAllActiveLicenseSubscriptions = async (_page = 0, _size = 20, _sort = 'modifyTime,desc', _search = '', _filters = {}) => {
        return await this.get('/subscriptions');
    }

    uploadNewLicense = async (file) => {
        let formData = new FormData();
        formData.append('file', file);
        return await this.post('/upload', formData);
    }

    linkUserToLicense = async (licenseUUID, userId) => {
        return await this.post('/subscriptions/add', { licenseUUID, userId });
    }

    deleteSubscriptionToLicense = async (licenseUUID, userId) => {
        return await this.post('/subscriptions/remove', { licenseUUID, userId });
    }
}
