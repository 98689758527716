import React, { useState } from 'react';
import { Tag } from 'app/global-settings/tags-page/Tag';
import cs from './TagList.module.scss';
import { desktopStyles } from 'lib/util/modalStyles';
import Modal from 'react-modal';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button';
import classNames from 'classnames';

export const TagList = ({ tags: allTags, maxTags = 9999, onClick, selectedTags = [], className }) => {
    const [showAll, setShowAll] = useState(false);

    const tags = allTags.slice(0, Math.min(allTags.length, maxTags));
    const restTagsCount = allTags.length - tags.length;

    const handleShowAllTags = () => {
        setShowAll(!showAll);
    }

    return (
        <div className={classNames(cs.tagList, className)}>
            <Tags tags={tags} onClick={onClick} selectedTags={selectedTags} />
            {restTagsCount > 0 && (
                <Tag name={`+${restTagsCount}`} selectable onClick={handleShowAllTags}/>
            )}
            <Modal
                isOpen={showAll}
                onRequestClose={handleShowAllTags}
                contentLabel='Все тэги документа'
                style={desktopStyles}
            >
                <div className={cs.modalContent}>
                    <div className={cs.title}>
                        <div>Все тэги документа</div>
                        <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>} onClick={handleShowAllTags}/>
                    </div>
                    <div className={classNames(cs.body, cs.tagList)}>
                        <Tags tags={allTags} onClick={onClick} selectedTags={selectedTags} />
                    </div>
                    <div className={cs.footer}>
                        <Button
                            onClick={handleShowAllTags}
                            label='Закрыть'
                        />

                    </div>
                </div>
            </Modal>
        </div>
    )
}

const Tags = ({ tags, onClick, selectedTags = [] }) => {

    return (
        <>
            {tags.map(tag => {
                const handleTagClick = (selected) => {
                    if (onClick) {
                        onClick(tag, selected);
                    }
                }

                const selected = selectedTags.some(t => t.id === tag.id);

                return (
                    <Tag key={tag.id} name={tag.name} selectable selected={selected} onClick={handleTagClick} />
                )
            })}
        </>
    )
}
