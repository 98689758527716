import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cx from './button-group.module.scss';

const ButtonGroup = ({ position, type, children }) => {
    return (
        <div className={classNames(cx.buttonGroup, cx[position], cx[type])}>{children}</div>
    );
};

ButtonGroup.defaultProps = {
    position: 'left',
    type: 'normal',
    children: null
};

ButtonGroup.propTypes = {
    position: PropTypes.oneOf(['center', 'left', 'right']),
    type: PropTypes.oneOf(['normal', 'lastToEnd', 'firstToStart']),
    children: PropTypes.node
};

export default ButtonGroup;