import React from 'react';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input/input';
import Radio from 'uikit/radio-button/radio-button';
import cx from './training-question-answer-one.module.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);
    return result;
};

export default function TrainingQuestionAnswerOne({ answers, onChange }) {
    const OnAnswerAdd = () => {
        const temp = Object.assign([], answers);
        temp.push({ value1: '', value2: '', isRight: false });
        onChange(temp);
    };
    const OnAnswerChange = (answer, i) => {
        const temp = Object.assign([], answers);
        temp[i]['value1'] = answer;
        onChange(temp);
    };
    const OnAnswerCheck = (i) => {
        const temp = Object.assign([], answers);

        temp.map((item) => {
            item['isRight'] = false;
            return item;

        });

        temp[i]['isRight'] = !temp[i]['isRight'];
        onChange(temp);
    };
    const OnAnswerRemove = (i) => {
        const temp = Object.assign([], answers);
        temp.splice(i, 1);
        onChange(temp);
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        onChange(reorder(answers, result.source.index, result.destination.index));
    };

    return (
        <div className={cx.trainingQuestionAnswerOne}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {answers.map((answer, i) => (
                                <Draggable key={i} draggableId={'draggable-' + i} index={i}>
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                             style={{ marginBottom: '24px', ...provided.draggableProps.style }}>
                                            <div key={i} className={cx.trainingQuestionAnswerOneItem}>
                                                <div className={cx.trainingQuestionAnswerOneItemContainer}>
                                                    <IconButton icon={<Icon type={Icons.DRAG} width={14} height={15}/>}/>
                                                    <Input type="text" value={answer['value1']}
                                                           onChange={(str) => OnAnswerChange(str, i)}/>
                                                    <IconButton icon={<Icon type={Icons.TRASH} width={14} height={15}/>}
                                                                onClick={() => OnAnswerRemove(i)}/>
                                                </div>
                                                <Radio label="Правильный ответ" value={answer['isRight']} checked={answer['isRight']}
                                                       onChange={() => OnAnswerCheck(i)}/>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <div className={cx.trainingQuestionAnswerOneAdd} onClick={() => OnAnswerAdd()}>
                <div className={cx.trainingQuestionAnswerOneAddIcon}>
                    <Icon type={Icons.ADD} width={10} height={10}/>
                </div>
                <span>Добавить вариант ответа</span>
            </div>
        </div>
    );
};