import { createSlice } from '@reduxjs/toolkit';

export const defaultFilters = {
    timePeriod: null,
    tags: [],
    minusWords: [],
    attributes: [],
    authors: [],
    projects: [],
    init: true,
    whereLooking: {
        includeNews: true,
        includeArticles: true,
        includeArchivedNews: true,
        includeArchivedArticles: true,
        includeFiles: true
    }
}

export const globalSearchSlice = createSlice({
    name: 'globalSearch',
    initialState: {
        filters: {
            timePeriod: null,
            tags: [],
            minusWords: [],
            attributes: [],
            authors: [],
            projects: [],
            init: false,
            whereLooking: {
                includeNews: true,
                includeArticles: true,
                includeArchivedNews: true,
                includeArchivedArticles: true,
                includeFiles: true
            }
        },
        openArticle: {
            uuid: null,
            query: null
        }
    },
    reducers: {
        setFilter: (state, action) => {
            state.filters = action.payload;
        },
        setDefaultFilters: (state) => {
            state.filters = defaultFilters;
        },
        setTags: (state, action) => {
            state.filters.tags = action.payload;
        },
        setAuthors: (state, action) => {
            state.filters.authors = action.payload;
        },
        setTimePeriod: (state, action) => {
            state.filters.timePeriod = action.payload;
        },
        setWhereLooking: (state, action) => {
            state.filters.whereLooking = action.payload;
        },
        setProjects: (state, action) => {
            state.filters.projects = action.payload;
        },
        openArticle: (state, action) => {
            state.openArticle.uuid = action.payload.uuid;
            state.openArticle.query = action.payload.query;
        },
        initFilter: (state) => {
            state.filters.init = true;
        },
        clearArticle: (state) => {
            state.openArticle = {
                uuid: null,
                query: null
            }
        }
    },
});

export const {
    setFilter,
    setDefaultFilters,
    setTags,
    setAuthors,
    setTimePeriod,
    setWhereLooking,
    setProjects,
    openArticle,
    initFilter,
    clearArticle
} = globalSearchSlice.actions;
export const selectFilters = state => state.globalSearch.filters
export const selectOpenArticle = state => state.globalSearch.openArticle

export default globalSearchSlice.reducer;
