import { Node, nodeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ResizableVideoNodeView } from './ResizableVideoNodeView';

export const VIDEO_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const ResizableVideo = Node.create({
    name: 'video',
    group: 'block',

    addOptions() {
        return {
            maxWidth: 16384,
            isAllowedVideoSrc: src => {
                if (!src) {
                    return false;
                }

                return true;
            },
        };
    },

    addAttributes() {
        return {
            src: {
                default: null,
                parseHTML: element => {
                    if (element.tagName === 'VIDEO') {
                        return element.getAttribute('src');
                    }

                    const videoElement = element.querySelector('video');

                    if (!videoElement) {
                        return null;
                    }

                    return videoElement.getAttribute('src');
                },
                renderHTML: attrs => ({ src: attrs.src }),
            },
            width: {
                default: '100%',
                renderHTML: attributes => ({
                    width: attributes.width,
                }),
                parseHTML: element => {
                    if (element.tagName === 'VIDEO') {
                        return element.getAttribute('width');
                    }

                    const videoElement = element.querySelector('video');

                    if (!videoElement) {
                        return null;
                    }

                    return Number(videoElement.getAttribute('width')) || '100%';
                },
            },
            autoplay: {
                default: null,
            },
            responsive: {
                default: null,
            },
            aspectRatio: {
                default: null,
                renderHTML: attributes => {
                    if (!attributes.aspectRatio) {
                        return {};
                    }

                    return {
                        style: `aspect-ratio: ${attributes.aspectRatio}`,
                    };
                },
                parseHTML: element => {
                    if (element.tagName === 'VIDEO') {
                        return element.style.aspectRatio;
                    }

                    const videoElement = element.querySelector('video');

                    if (!videoElement) {
                        return null;
                    }

                    return videoElement.style.aspectRatio;
                },
            },
            dataAlign: {
                default: 'center',
                parseHTML: element => {
                    return element.getAttribute('class')?.replace('align-', '');
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'figure[data-type="video"]',
            },
            {
                tag: 'video',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        const { autoplay, dataAlign, ...rest } = HTMLAttributes;

        const attrs = rest;
        if (autoplay) {
            attrs['autoplay'] = true;
        }

        return [
            'figure',
            { 'data-type': 'video', class: `align-${dataAlign}` },
            ['video', { controls: 'true', style: 'aspect-ratio: 1.777758946;', ...attrs }, ['source', attrs]],
        ];
    },

    addCommands() {
        return {
            setVideo:
                src =>
                ({ chain }) => {
                    chain()
                        .focus()
                        .insertContent({ type: this.name, attrs: { controls: true, src } })
                        .run();
                },
        };
    },

    addInputRules() {
        return [
            nodeInputRule({
                find: VIDEO_INPUT_REGEX,
                type: this.type,
                getAttributes: match => {
                    const [, , src] = match;

                    return { src };
                },
            }),
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ResizableVideoNodeView, { as: 'figure' });
    },
});
