import React from 'react';
import Icon, { Icons } from 'uikit/icon';
import cx from './training-question-answer-open.module.scss';

export default function TrainingQuestionAnswerOpen() {
    return (
        <div className={cx.trainingQuestionAnswerOpen}>
            <Icon type={Icons.INFO} width={15} height={15}/>
            <span>Открытый ответ не имеет правильного ответа</span>
        </div>
    );
};