import React from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import Button from 'uikit/button';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import { CheckboxList } from './components/checkbox-list';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import cs from './confirmation.module.scss';
import { useGlobalContext } from 'lib/hooks';

// TODO refactoring along with SendEmail and SubscribeUpdates
export const Confirmation = ({ isOpen, 
    text, 
    title, 
    subTitle, 
    color, 
    contentType, 
    submitBtnText, 
    closeBtnText, 
    onChange, 
    onRequestClose, 
    onRequestSubmit, 
    submitBtnDisabled = false
}) => {
    const { platform } = useGlobalContext();

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel={title} style={platform === 'mobile' ? mobileStyles : desktopStyles} testId="confirmModal">
            <div className={classNames(cs.modal, {[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.head}>
                    <div className={cs.text} data-testid="confirmModalTitle">{title}</div>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                                onClick={onRequestClose} id="confirmModalCrestBtn"/>
                </div>

                {subTitle && <div className={classNames(cs.subTitle, {[cs.subTitle__noContent]: !contentType})} data-testid="confirmModalSubTitle">{subTitle}</div>}

                {contentType ? <div className={cs.content} data-testid="confirmModalContent">
                    <div className={cs.hint}>
                        {contentType === 'TEXT' ? 
                            text
                        : contentType === 'CHECKBOX_LIST' ? (
                            <CheckboxList list={text} onChange={onChange} />
                        ) : null}
                    </div>
                </div>: null}
                
                <div className={cs.footer}>
                    <Button onClick={onRequestClose} label={closeBtnText} fullWidth={platform === 'mobile'} data-testid="confirmModalCloseBtn"/>
                    <Button onClick={onRequestSubmit} label={submitBtnText} disabled={submitBtnDisabled} color={color} fullWidth={platform === 'mobile'} data-testid="confirmModalOkBtn"/>
                </div>
            </div>
        </Modal>
    );
};

Confirmation.defaultProps = {
    color: 'green',
    isOpen: false,
    onRequestClose: () => {

    },
    onRequestSubmit: () => {

    },
    onChange: () => {

    },
    contentType: null,
    text: '',
    title: '',
    subTitle: '',
    submitBtnText: 'Отправить',
    closeBtnText: 'Отмена'
};
