import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalContext, useMessage } from 'lib/hooks';
import TreeUtils from 'lib/util/tree.util';
import treeUtils from 'lib/util/tree.util';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { defaultParentObject, setParent } from 'slice/treeSlice';
import { TreePropContext } from 'containers/side-tree-wrapper';
import PrivatePage from 'containers/private-page-wrapper';
import { ArchiveGlobal, ArchiveGlobalTabArticles, ArchiveGlobalTabNews, ArchiveGlobalTabScripts } from 'components/archive';
import { ArchiveProject, ArchiveProjectTabArticles, ArchiveProjectTabNews } from 'components/archive';
import { ArchiveSection, ArchiveSectionTabArticles } from 'components/archive';
import { NODE_TYPES } from 'components/trees';
import api from 'api';
import cx from './projects-example-page.module.scss';
import { useSection } from 'lib/hooks/useSection';
import ArchiveSectionTabScripts from 'components/archive/section-tab-scripts';
import ArchiveProjectTabScripts from "components/archive/project-tab-scripts";
import { selectScriptingEnabled } from 'slice/authSlice';

export default function ArchivePage({ projectId, sectionId, tab }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isScriptingEnabled = useSelector(selectScriptingEnabled);

    const { platform } = useGlobalContext();
    const { setTreeProps, setScrollToNode, tree: treePlugin, treeRef, reloadTree, setOnReloadTreeAction } = React.useContext(TreePropContext);
    const { addError } = useMessage();

    const [project, setProject] = useState(null);
    const section = useSection(sectionId);

    const getNodeLink = useCallback(
        (node) => {
            if (node) {
                if (node.nodeType === 'PROJECT') {
                    return { link: '/archive/' + node.id + (tab ? '/' + tab : '/articles'), replace: true };
                } else if (node.nodeType === 'SECTION') {
                    const parentNode = TreeUtils.getTopParentNode(node);
                    const sectionData = {
                        id: node.id,
                        projectId: node.id !== parentNode.id ? parentNode.id : projectId,
                    };

                    return { link: '/archive/' + sectionData.projectId + '/' + sectionData.id + '/section/articles', replace: true };
                } else if (node.nodeType === 'ARTICLE') {
                    return `/archive/article/${node.id}`;
                } else if (node.nodeType === NODE_TYPES.SCRIPTS) {
                    return `/archive/script/${node.id}`;
                }
            } else {
                return '/archive';
            }
        },
        [projectId, tab]
    );

    const onTabChange = (tab) => {
        if (projectId && sectionId) {
            navigate(`/archive/${projectId}/${sectionId}/section/${tab ?? ''}`, { replace: true });
        } else if (projectId) {
            navigate('/archive/' + project.id + (tab ? '/' + tab : ''), { replace: true });
        } else {
            navigate('/archive' + (tab ? '/' + tab : ''), { replace: true });
        }
    };

    const onDelete = () => {
        reloadTree();
        navigate('/archive');
    };
    const onDeleteSection = () => {
        reloadTree();
        navigate('/archive');
    };

    useEffect(() => {
        const fetchData = async () => {
            if (projectId && sectionId) {
                setProject(null);
            } else if (projectId) {
                try {
                    const projectData = await api.project.getProject(projectId);

                    setProject({
                        id: projectData['id'],
                        title: projectData['title'],
                        avatar: projectData['logoUuid'] ? api.upload.getImage(projectData['logoUuid'], false, 128) : '',
                        description: projectData['description'],
                        manager: projectData['author']['firstName'] + ' ' + projectData['author']['lastName'],
                        managerLogin: projectData['author']['login'],
                        favorite: projectData['favorite'],
                    });
                } catch (e) {
                    console.log(e);
                    addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                }
            }
        };
        fetchData();
    }, [addError, projectId, sectionId]);

    useEffect(() => {
        setTreeProps({ getNodeLink });
    }, [setTreeProps, getNodeLink]);

    useEffect(() => {
        let handler;

        const rememberLocation = () => {
            if (!treeRef.current) {
                handler = setTimeout(rememberLocation, 1000);
                return;
            }

            const parents = { ...defaultParentObject };
            const node = treeRef.current.state.nodes.find((node) => node.id === (sectionId || projectId));

            if (!node) {
                handler = setTimeout(rememberLocation, 1000);
                return;
            }

            parents.visualPath = TreeUtils.getVisualPathTo(node);

            if (node.nodeType === NODE_TYPES.PROJECT) {
                parents.parentProjectId = node.id;
            } else {
                parents.parentProjectId = TreeUtils.getTopParentNode(node).id;
                parents.parentSectionId = node.id;
            }

            dispatch(setParent(parents));

            const pathIds = treeUtils.getPathIdsTo(node);
            setScrollToNode(pathIds);
        };

        rememberLocation();

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [projectId, sectionId, treeRef, dispatch, setScrollToNode]);

    return (
        <PrivatePage hasAnyAuthorities={[GLOBAL_ACTIONS.RESOURCE_ARCHIVE_READ]}>
            <div className={cx.content}>
                {!projectId && !sectionId && (
                    <ArchiveGlobal
                        tab={tab}
                        onTabChange={onTabChange}
                    />
                )}
                {project && (
                    <ArchiveProject
                        id={project.id}
                        avatar={project.avatar}
                        title={project.title}
                        description={project.description}
                        manager={project.manager}
                        onAdd={() => {}}
                        onEdit={() => {}}
                        onMore={() => {}}
                        tab={tab}
                        onTabChange={onTabChange}
                        handleOnDelete={onDelete}
                        isMobile={platform === 'mobile'}
                    />
                )}
                {section && (
                    <ArchiveSection
                        id={sectionId}
                        title={section?.title}
                        projectId={projectId}
                        tab={tab}
                        onTabChange={onTabChange}
                        handleOnDelete={onDeleteSection}
                    />
                )}
                {!projectId && !sectionId && (!tab || tab === 'articles') && (
                    <ArchiveGlobalTabArticles
                        tree={treePlugin}
                        reloadTree={reloadTree}
                        setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                    />
                )}
                {project && tab === 'articles' && (
                    <ArchiveProjectTabArticles
                        projectId={project.id}
                        tree={treePlugin}
                        reloadTree={reloadTree}
                        setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                    />
                )}
                {section && tab === 'articles' && (
                    <ArchiveSectionTabArticles
                        projectId={projectId}
                        sectionId={sectionId}
                        tree={treePlugin}
                        reloadTree={reloadTree}
                        setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                    />
                )}
                {!projectId && !sectionId && tab === 'news' && (
                    <ArchiveGlobalTabNews />
                )}
                {project && tab === 'news' && (
                    <ArchiveProjectTabNews
                        projectId={project.id}
                        tree={treePlugin}
                    />
                )}
                {isScriptingEnabled && (
                    <>
                        {project && tab === 'scripts' && (
                            <ArchiveProjectTabScripts
                                projectId={projectId}
                                tree={treePlugin}
                                reloadTree={reloadTree}
                                setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                            />
                        )}
                        {!projectId && !sectionId && tab === 'scripts' && (
                            <ArchiveGlobalTabScripts
                                tree={treePlugin}
                                reloadTree={reloadTree}
                                setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                            />
                        )}
                        {section && tab === 'scripts' && (
                            <ArchiveSectionTabScripts
                                projectId={projectId}
                                sectionId={sectionId}
                                tree={treePlugin}
                                reloadTree={reloadTree}
                                setOnReloadTreeAction={setOnReloadTreeAction || (() => {})}
                            />
                        )}
                    </>
                )}
            </div>
        </PrivatePage>
    );
}
