import React from 'react';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import cx from './custom-toast.module.scss';

const CustomToast = ({ appearance, children, ...props }) => {
    return (
        <div id={appearance} className={classNames(cx.toast, {
            [cx.red]: appearance === 'error',
            [cx.green]: appearance === 'success',
            [cx.blue]: appearance === 'info'
        })}>
            <div className={cx.toastMessage}>
                {appearance === 'info' && <Icon type={Icons.INFO} width={18} height={18}/>}
                {appearance === 'error' && <Icon type={Icons.BLOCK} width={18} height={18}/>}
                {appearance === 'success' && <Icon type={Icons.CHECK} width={18} height={18}/>}
                {children}
            </div>
            <div onClick={props.onDismiss}>
                <Icon type={Icons.CROSS} width={10} height={10}/>
            </div>
        </div>
    );
};

export default CustomToast;