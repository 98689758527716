import React from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import Button from 'uikit/button';
import ButtonGroup from 'uikit/button-group';
import Icon, { Icons } from 'uikit/icon';
import cs from './script-preview.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';
import ScriptPlayback from 'components/script-playback/script-playback';
import MultiClamp from 'react-multi-clamp';

function ScriptPreview({ show, onPublish, onEditDraft, onDismiss, title, elements = [] }) {
    const { platform } = useGlobalContext();

    return (
        <>
            {show && (
                <Modal
                    id={'newsPreviewModal'}
                    isOpen
                    onRequestClose={onDismiss}
                    contentLabel={''}
                    style={platform === 'mobile' ? mobileStyles : desktopStyles}
                    testId="scriptPreviewModal"
                >
                    <div className={classNames(cs.preview)}>
                        <div className={cs.menu}>
                            <ButtonGroup type="lastToEnd">
                                <Button color={'base'} className={cs.buttonIcon} onClick={onDismiss} data-testid="scriptPreviewModalCloseBtn">
                                    <Icon className={cs.backIcon} width={32} height={32} type={Icons.ARROW_LEFT} />{' '}
                                    <span>Выйти из предпросмотра</span>
                                </Button>
                                {/* {type === 'news' && !news && <Button onClick={onDraft}>В черновик</Button>} */}
                                <Button color={'green'} onClick={onEditDraft ? onEditDraft : onPublish} data-testid="scriptPreviewModalOkBtn">
                                    {onEditDraft ? 'Сохранить черновик' : 'Опубликовать'}
                                </Button>
                            </ButtonGroup>
                        </div>
                        <div className={cs.header}>
                            <div id={'newsPreviewTitle'} data-testid="scriptPreviewModalTitle" className={cs.title}>
                                <MultiClamp clamp={2} ellipsis={'...'}>
                                    {title || 'Название не указано'}
                                </MultiClamp>
                            </div>
                        </div>
                        <div className={cs.content} data-testid="scriptPreviewModalContent">
                          <ScriptPlayback elements={elements} onDismiss={onDismiss} preview={true} />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default ScriptPreview;
