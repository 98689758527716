import React, { createRef, useState } from 'react';
import { v4 } from 'uuid';
import { useEventListener } from 'lib/hooks';
import { EditorNotificationContext } from './context';

export const EDITOR_NOTIFICATIONS = {
    Add: 'EDITOR_ADD_NOTIFICATION',
}

export const pushNotificationEvent = ({ id = v4(), message }) => {
    window.dispatchEvent(new CustomEvent(EDITOR_NOTIFICATIONS.Add, {
        detail: { id, message }
    }));
}

export default function EditorNotificationProvider({ children }) {
    const [notifications, setNotifications] = useState([]);

    const pushNotification = ({ message }) => pushNotificationEvent({ message });
    const removeNotification = (id) => setNotifications(prev => prev.filter(n => n.id !== id));

    useEventListener(EDITOR_NOTIFICATIONS.Add, (e) => {
        const newNotification = { id: e.detail.id, message: e.detail.message, nodeRef: createRef(null) };
        setNotifications(prev => [...prev, newNotification]);
        setTimeout(() => removeNotification(newNotification.id), 5000);
    }, window);

    return (
        <EditorNotificationContext.Provider value={{ notifications, pushNotification, removeNotification }}>
            {children}
        </EditorNotificationContext.Provider>
    );
}
