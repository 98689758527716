import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import { setSessionHasBeenFetched } from 'slice/authSlice';
import Input from 'uikit/input';
import Button from 'uikit/button';
import { Icons } from 'uikit/icon';
import api from 'api';
import cx from './login-page.module.scss';

// const loginBackground = 'url(/img/loginPage/' + Math.floor(Math.random() * 6 + 1) + '.jpg) center center no-repeat';

export default function LoginPage () {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [error, setError] = useState(null);

    const _onSubmit = async () => {
        await api.auth.login({ username, password }).then(() => {
            dispatch(setSessionHasBeenFetched(false));
            navigate('/', { replace: true });
        }).catch((error) => {
            if (error.response?.data?.error?.indexOf('LICENSE') >= 0) {
                setError('Аккаунт не активирован. Обратитесь к Вашему администратору');
            } else {
                setError('Неверный логин или пароль');
            }
        });
    };

    return (
        <div className={cx.loginPage} /*style={{ background: loginBackground }}*/>
            <div className={cx.loginPageForm}>
                <div className={cx.loginPageFormHeader}>
                    {/*<img src="/icons/logo.svg" alt="Logo"/>*/}
                    <div>
                        <h1>Вход в базу знаний</h1>
                        <p>Пожалуйста введите свои данные чтобы продолжить</p>
                    </div>
                </div>
                {error && <div className={cx.loginPageFormHeaderError}>{error}</div>}
                <div className={cx.loginPageFormFields}>
                    <Input type="text" className={cx.loginPageFormFieldsInput} label="Ваш логин" icon={Icons.USER}
                           placeholder="Введите ваш логин" value={username} onChange={setUsername}
                           onFocus={() => setError(null)}/>
                    <Input type="password" className={cx.loginPageFormFieldsInput} label="Пароль для входа"
                           icon={Icons.LOCK} placeholder="•••••••" value={password} onChange={setPassword}
                           onFocus={() => setError(null)}/>
                    <Button className={cx.loginPageFormFieldsButton} label="Войти" color="blue" onClick={_onSubmit}/>
                </div>
            </div>
        </div>
    );
};
