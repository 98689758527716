import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useEditorNotificationContext } from '../../context';
import Icon, { Icons } from 'uikit/icon';
import cx from './Notifications.module.scss'

export const Notifications = () => {
    const { notifications, removeNotification } = useEditorNotificationContext();

    const [left, setLeft] = useState(0);
    const [right, setRight] = useState(0);

    useEffect(() => {
        const editor = document.getElementById('editor');
        const editorBoundingClientRect = editor.getBoundingClientRect();

        setLeft(editorBoundingClientRect.left);
        setRight(document.body.offsetWidth - (editorBoundingClientRect.left + editorBoundingClientRect.width));

        const onResize = () => {
            const editorBoundingClientRect = editor.getBoundingClientRect();

            setLeft(editorBoundingClientRect.left);
            setRight(document.body.offsetWidth - (editorBoundingClientRect.left + editorBoundingClientRect.width));
        };

        window.addEventListener('resize', onResize, true);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <div className={cx.notificationWrapper} style={{ paddingLeft: left + 'px', paddingRight: right + 'px' }}>
            <TransitionGroup component={null}>
                {notifications.map(n => (
                    <CSSTransition key={n.id} nodeRef={n.nodeRef} timeout={500} classNames={{ ...cx }}>
                        <div ref={n.nodeRef} className={cx.notification} onClick={() => removeNotification(n.id)}>
                            <div>
                                <Icon type={Icons.EditorIconSuccess} width={24} height={24} />
                            </div>
                            <div>{n.message}</div>
                        </div>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    )
}
