import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { findParentNodeClosestToPos } from '@tiptap/core';
import Tippy from '@tippyjs/react';

import Icon, { Icons } from 'uikit/icon';
import { useEditorContext } from '../../context';
import { MenuButtonBulletList } from './MenuButtonBulletList';
import { MenuButtonOrderedList } from './MenuButtonOrderList';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { MenuButtonMultiList, MultiListModal } from './MenuButtonMultiList';

import cx from '../../editor.module.scss';

export const markers = [
    { value: 'list-upper-alpha', label: 'A, B, C', type: 'orderedList' },
    { value: 'list-lower-alpha', label: 'a, b, c', type: 'orderedList' },
    { value: 'list-lower-roman', label: 'i, ii, iii', type: 'orderedList' },
    { value: 'list-upper-roman', label: 'I, II, III', type: 'orderedList' },
    { value: 'list-decimal', label: '1, 2, 3', type: 'orderedList' },
    { value: 'list-disc', label: '•', type: 'bulletList' },
    { value: 'list-option-1', label: '⊙', type: 'bulletList' },
    { value: 'list-circle', label: '○', type: 'bulletList' },
    { value: 'list-option-2', label: '◍', type: 'bulletList' },
    { value: 'list-option-3', label: '◆', type: 'bulletList' },
    { value: 'list-option-4', label: '❖', type: 'bulletList' },
    { value: 'list-option-5', label: '✦', type: 'bulletList' },
    { value: 'list-option-6', label: '✧', type: 'bulletList' },
    { value: 'list-option-7', label: '✱', type: 'bulletList' },
    { value: 'list-option-8', label: '☆', type: 'bulletList' },
    { value: 'list-option-9', label: '◌', type: 'bulletList' },
    { value: 'list-option-10', label: '✓', type: 'bulletList' },
];

export const MenuButtonList = ({ mode, currentMenu, setCurrentMenu }) => {
    const menuRef = useRef(null);
    const editor = useEditorContext();

    const [show, setShow] = useState(false);

    const orderedMarkers = useMemo(() => markers.filter(m => m.type === 'orderedList'), []);
    const bulletMarkers = useMemo(() => markers.filter(m => m.type === 'bulletList'), []);

    const toggleDialog = () => {
        const { selection } = editor.state;
        const { $from, $to } = selection;
        const range = $from.blockRange($to);

        if (!range) {
            setShow(prev => !prev);
            return false;
        }

        setShow(prev => !prev);
    };

    const setBulletList = type => {
        const textAlign = editor?.getAttributes('paragraph')?.textAlign;
        const list = findParentNodeClosestToPos(
            editor?.state.selection.$anchor,
            node => {
                return node.type.name === 'bulletList';
            },
            1
        );

        if (list) {
            editor
                ?.chain()
                .updateAttributes('bulletList', { 'data-list-type': type })
                .updateAttributes('listItem', { class: `list-${textAlign}` })
                .focus(editor?.state.selection.$anchor.pos + 1)
                .run();
        } else {
            editor
                ?.chain()
                .toggleBulletList()
                .updateAttributes('bulletList', { 'data-list-type': type })
                .updateAttributes('listItem', { class: `list-${textAlign}` })
                .focus(editor?.state.selection.$anchor.pos + 1)
                .run();
        }

        if (mode === 'DESKTOP') {
            menuRef.current?.hidemenu?.();
        }
    };

    const setOrderedList = type => {
        const textAlign = editor?.getAttributes('paragraph')?.textAlign;
        const list = findParentNodeClosestToPos(
            editor?.state.selection.$anchor,
            node => {
                return node.type.name === 'orderedList';
            },
            1
        );

        if (list) {
            editor
                ?.chain()
                .updateAttributes('orderedList', { 'data-list-type': type })
                .updateAttributes('listItem', { class: `list-${textAlign}` })
                .focus(editor?.state.selection.$anchor.pos + 1)
                .run();
        } else {
            editor
                ?.chain()
                .toggleOrderedList()
                .updateAttributes('orderedList', { 'data-list-type': type })
                .updateAttributes('listItem', { class: `list-${textAlign}` })
                .focus(editor?.state.selection.$anchor.pos + 1)
                .run();
        }

        if (mode === 'DESKTOP') {
            menuRef.current?.hidemenu?.();
        }
    };

    return (
        <>
            <Menu
                ref={menuRef}
                nested={mode === 'MOBILE'}
                mode={mode}
                name="list"
                currentMenu={currentMenu}
                setCurrentMenu={name => {
                    if (mode === 'MOBILE' && name !== 'list') {
                        setCurrentMenu('sub');
                    } else {
                        setCurrentMenu(name);
                    }
                }}
                label={
                    <Tippy content="Списки" disabled={mode !== 'DESKTOP'}>
                        {mode !== 'MOBILE' ? (
                            <div className={classNames('editor-button', cx.editorMenuButton)}>
                                <Icon type={Icons.EditorIconList} width={18} height={18} />
                                <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                            </div>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                }}
                            >
                                <div className={classNames('editor-button', cx.editorMenuButton)}>
                                    <Icon type={Icons.EditorIconList} width={18} height={18} />
                                    <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                                        Списки
                                    </span>
                                </div>
                                <span aria-hidden style={{ display: 'flex', marginRight: 6 }}>
                                    <Icon type={Icons.EditorIconArrowRight} width={12} height={12} />
                                </span>
                            </div>
                        )}
                    </Tippy>
                }
                menuButtonStyles={mode === 'MOBILE' ? { width: '100%' } : null}
                menuContentStyles={mode === 'MOBILE' ? { overflow: 'initial', minWidth: 254 } : null}
            >
                <Menu
                    mode={mode}
                    name="list"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    label={<MenuButtonBulletList label="Маркированный список" />}
                >
                    {bulletMarkers.map(m => {
                        return <MenuItem key={m.label} label={m.label} onClick={() => setBulletList(m.value)} />;
                    })}
                </Menu>

                <Menu
                    mode={mode}
                    name="list"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    label={<MenuButtonOrderedList label="Нумерованный список" />}
                >
                    {orderedMarkers.map(m => {
                        return <MenuItem key={m.label} label={m.label} onClick={() => setOrderedList(m.value)} />;
                    })}
                </Menu>

                {mode === 'DESKTOP' && <MenuItem label={<MenuButtonMultiList label="Многоуровневый список" onClick={toggleDialog} />} />}
            </Menu>

            {show && <MultiListModal show={show} markers={markers} onClose={toggleDialog} />}
        </>
    );
};
