import React, { useState } from 'react';

import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import { AnchorModal } from '../Menu/MenuButtonAnchor';
import cx from '../../editor.module.scss';
import { findParentNode } from '../../utils/findParentNode';
import { translate } from 'lib/helpers';
import { BubbleMenuView } from '../../extensions/BubbleMenu/BubbleMenuView';
import { NodeSelection } from '@tiptap/pm/state';
import { findChildrenByType } from 'prosemirror-utils';

const propsEqualCompare = (prevProps, nextProps) => {
    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.selection.from !== nextProps.selection.from) return false;
    if (prevProps.setCurrentMenu !== nextProps.setCurrentMenu) return false;
    if (prevProps.mode !== nextProps.mode) return false;

    return true;
};

export const BubbleMenuAnchor = React.memo(({ mode, setCurrentMenu }) => {
    const editor = useEditorContext();

    const [isEdit, setIsEdit] = useState(false);
    const [anchorData, setAnchorData] = useState({ name: '' });

    const toggleEdit = () => {
        setCurrentMenu(undefined);

        if (isEdit) {
            setIsEdit(false);
        } else {
            const node = editor?.state.selection?.node ? editor?.state.selection?.node : editor?.state.selection?.$anchor.parent;

            setAnchorData({ name: node.attrs.title || node.attrs.id });
            setIsEdit(true);
        }
    };

    const deleteAnchor = () => {
        setCurrentMenu(undefined);

        const { dispatch, state } = editor.view;
        const { tr, selection } = state;
        let curSelection = selection;

        const isNodeSelection = curSelection instanceof NodeSelection;

        if (!isNodeSelection) {
            const currentNode = findChildrenByType(state.schema.nodes.anchor)(state.selection);
            tr.setSelection(NodeSelection.create(state.doc, currentNode.pos));
            curSelection = tr.curSelection;
        }

        if (curSelection.node.textContent) {
            tr.replaceWith(curSelection.from, curSelection.to, editor.state.selection.node.content);
        } else {
            tr.delete(curSelection.from, curSelection.to);
        }

        dispatch(tr);
    };

    const submitChanges = name => {
        const nearestParent = findParentNode(editor.state, 'anchor');
        const id = translate(name.replace(/ /gi, '_').toLowerCase());

        if (nearestParent) {
            editor.commands.setNodeSelection(nearestParent.pos);
        }

        const previousName = translate(anchorData['name'].replace(/ /gi, '_').toLowerCase());
        const { state, view } = editor;

        const { doc } = state;
        const tr = state.tr;

        doc.descendants((node, pos) => {
            if (!node.isText) {
                return;
            }

            node.marks.forEach(mark => {
                if (mark.type.name !== 'link' || mark.attrs.href !== '#' + previousName) {
                    return;
                }

                tr.removeMark(pos, pos + node.nodeSize, mark);
                tr.addMark(
                    pos,
                    pos + node.nodeSize,
                    editor.schema.marks.link.create({
                        ...mark.attrs,
                        href: '#' + id,
                    })
                );
            });
        });

        if (tr.docChanged) {
            view.dispatch(tr);
        }

        editor.chain().focus().updateAttributes('anchor', { id, name: id, title: name, 'data-tooltip': name, 'data-type': 'anchor' }).run();
        setIsEdit(false);
    };

    return (
        <BubbleMenuView
            tippyOptions={{
                placement: 'bottom',
                arrow: false,
            }}
            editor={editor}
            shouldShow={({ editor }) => {
                return editor.isActive('anchor') && editor.state.selection instanceof NodeSelection;
            }}
        >
            <div className={cx.bubbleMenu}>
                {mode !== 'DESKTOP' ? (
                    <>
                        <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconEdit} onClick={toggleEdit} />
                        <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                        <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconTrash} onClick={deleteAnchor} />
                    </>
                ) : (
                    <div>
                        <MenuButton
                            icon={Icons.EditorIconAnchor}
                            style={{ whiteSpace: 'nowrap', display: 'flex' }}
                            label="Изменить якорь"
                            className={cx.edit}
                            onClick={toggleEdit}
                        />
                        <MenuButton
                            icon={Icons.EditorIconTrash}
                            iconColor="red"
                            style={{ whiteSpace: 'nowrap', display: 'flex' }}
                            label="Удалить якорь"
                            className={cx.remove}
                            onClick={deleteAnchor}
                        />
                    </div>
                )}
            </div>
            {isEdit && <AnchorModal data={anchorData} show={isEdit} onClose={() => setIsEdit(prev => !prev)} onSubmit={submitChanges} />}
        </BubbleMenuView>
    );
}, propsEqualCompare);
