import React, { useRef, useEffect } from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from 'uikit/textarea';
import { Select } from 'uikit/select';
import cs from '../create-script.module.scss';

const getConditionOptionMarker = (value) => {
  switch (value) {
      case 'neutral':
          return cs.conditionOptionMarkerNeutral;
      case 'positive':
          return cs.conditionOptionMarkerPositive;
      case 'negative':
          return cs.conditionOptionMarkerNegative;
      default:
          return cs.conditionOptionMarkerNeutral;
  }
}

function ActiveConditionNodeSettings({ activeConditionNode, onChange, onChangeTarget, onRemove, errors, watchTextLength, stepsOptions, conditionTarget, watchTextCharacters }) {
    const activeConditionNodeRef = useRef(null);
    const textLenghtLimit = 254;

    const conditionTypeOptions = [
        {
            label: 'Нейтральное',
            value: 'neutral',
        },
        {
            label: 'Положительное',
            value: 'positive',
        },
        {
            label: 'Отрицательное',
            value: 'negative',
        },
    ];

    useEffect(() => {
        activeConditionNodeRef.current = JSON.parse(JSON.stringify(activeConditionNode));
        activeConditionNodeRef.current.isStep = false;
    }, [activeConditionNode]);

    useEffect(() => {
        watchTextCharacters(activeConditionNodeRef.current.data.text.trim(), textLenghtLimit);
    }, [activeConditionNode, watchTextCharacters]);

    const onNodeNameChange = (e) => {
        watchTextLength(e.trim().length, textLenghtLimit);
        activeConditionNodeRef.current['data']['text'] = e;
        onChange(activeConditionNodeRef.current);
    };

    const onNodeTypeChange = (e) => {
        activeConditionNodeRef.current.type = e.value;
        onChange(activeConditionNodeRef.current);
    };

    const onRemoveHandler = () => {
      onRemove(activeConditionNodeRef.current);
    }

    const SingleValue = ({
      children,
      data: { value },
      ...props
    }) => {
      return (
        <components.SingleValue {...props}>
            <div className={cs.conditionOptionSingleValue}>
                <div className={classNames(cs.conditionOptionMarker, getConditionOptionMarker(value))}></div>
                {children}
            </div>
        </components.SingleValue>
      )
    };
    
    return (
        <div className={cs.stepContainer} data-testid="activeCondition">
            <div className={cs.stepHeading}>
                <div className={cs.stepHeadingLabel} data-testid="activeConditionLabel">Условие</div>
                <div className={cs.stepRemoveBtn} onClick={onRemoveHandler} data-testid="activeConditionDeleteBtn">
                    Удалить условие
                </div>
            </div>

            <Textarea
                onChange={onNodeNameChange}
                value={activeConditionNode.data.text}
                className={cs.desc}
                label="Текст условия"
                rows="2"
                error={errors?.textCharacters}
                data-testid="activeConditionName"
            />

            <Select
                label={'Оценка условия'}
                placeholder="Оценка условия"
                id="activeConditionType"
                value={conditionTypeOptions.find((e) => e.value === activeConditionNode.type)}
                options={conditionTypeOptions}
                onChange={onNodeTypeChange}
                className={cs.conditionNodeSelect}
                components={{
                    SingleValue,
                    Option: ({ innerProps, innerRef, label, isSelected, value }) => {
                        return (
                            <div
                                ref={innerRef}
                                {...innerProps}
                                className={classNames(
                                    cs.conditionOption,
                                    {
                                        [cs.conditionOptionActive]: isSelected,
                                    },
                                )}
                            >
                                <div className={classNames(cs.conditionOptionMarker, getConditionOptionMarker(value))}></div>
                                <span>{label}</span>
                            </div>
                        );
                    }
                }}
                isSearchable={ false }
            />

            <Select
                label={'Условие ведет к шагу'}
                placeholder="Условие ведет к шагу"
                id="activeConditionTarget"
                value={stepsOptions.find((e) => e.value === conditionTarget)}
                options={stepsOptions}
                onChange={onChangeTarget}
                className={cs.conditionNodeSelect}
                isSearchable={ false }
            />
        </div>
    );
}

ActiveConditionNodeSettings.propTypes = {
  activeConditionNode: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default ActiveConditionNodeSettings;