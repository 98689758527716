import React from 'react';

const LabelWithColor = ({ innerProps, data }) => {
    return (
        <div {...innerProps}>
            <div style={
                {
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#46535F',
                    height: '28px',
                    cursor: 'pointer',
                    color: '#' + data.color,
                    width: 'fit-content',
                    padding: '6px',
                    borderRadius: '5px',
                    marginTop: '4px',
                    marginBottom: '4px'
                }
            }>
                {data.label}
            </div>
        </div>
    );
};

export default LabelWithColor;
