import { Node, mergeAttributes } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { GoogleDocView } from './GoogleDocView';

export default Node.create({
    name: 'remote-google-doc',
    group: 'block',
    atom: true,
    draggable: true,
    selectable: true,

    addOptions() {
        return {
            allowFullscreen: true,
            HTMLAttributes: {
                class: 'flex w-full',
                'data-node-type': 'remote-google-doc',
            },
        };
    },

    addAttributes() {
        return {
            src: {
                default: null,
            },
            frameborder: {
                default: 0,
            },
            width: {
                default: '100%',
            },
            height: {
                default: '500px',
            },
            allowfullscreen: {
                default: this.options.allowFullscreen,
                parseHTML: () => this.options.allowFullscreen,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'iframe',
            },
        ];
    },

    renderHTML({ node, HTMLAttributes }) {
        return [
            'div',
            mergeAttributes(this.options.HTMLAttributes),
            ['iframe', { src: HTMLAttributes.src, width: node.attrs.width, height: node.attrs.height }],
        ];
    },

    addCommands() {
        return {
            setGoogleDocIframe:
                options =>
                ({ tr, dispatch }) => {
                    const { selection } = tr;
                    const node = this.type.create(options);

                    if (dispatch) {
                        tr.replaceRangeWith(selection.from, selection.to, node);
                    }

                    return true;
                },
        };
    },

    addNodeView() {
        return ReactNodeViewRenderer(GoogleDocView);
    },
});
