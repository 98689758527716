import { mergeAttributes } from '@tiptap/core';
import { Heading } from '@tiptap/extension-heading';
import { v4 } from 'uuid';

export const CustomHeading = Heading.extend({
    name: 'customHeading',
    addOptions() {
        return {
            ...this.parent?.(),
        };
    },
    addAttributes() {
        return {
            ...this.parent?.(),
            'data-uniq-id': {
                default: null,
                parseHTML: el => el.getAttribute('data-uniq-id'),
                renderHTML: attrs => {
                    if (!attrs['data-uniq-id']) {
                        return {
                            'data-uniq-id': v4(),
                        };
                    }

                    return {
                        'data-uniq-id': attrs['data-uniq-id'],
                    };
                },
            },
            level: {
                default: null,
                parseHTML: el => {
                    return el.tagName.includes('H') ? el.tagName?.[1] : null;
                },
            },
            'data-mark': {
                default: null,
                renderHTML: attrs => {
                    if (!attrs['data-mark']) return {};
                    if (attrs['data-mark'] === 'italic') return { ...attrs, style: 'font-style: italic' };
                    if (attrs['data-mark'] === 'bold') return { ...attrs, style: 'font-weight: 600' };
                    if (attrs['data-mark'] === 'underline') return { ...attrs, style: 'text-decoration: underline' };
                },
            },
        };
    },
    parseHTML() {
        return this.options.levels.map(level => ({
            tag: `h${level}`,
        }));
    },

    renderHTML({ node, HTMLAttributes }) {
        const hasLevel = this.options.levels.includes(+node.attrs.level);
        const level = hasLevel ? +node.attrs.level : this.options.levels[0];

        return [`h${level}`, mergeAttributes(HTMLAttributes, { class: `h${level}` }), 0];
    },
});
