export const buildNode = ({ type, content }) => (content ? { type, content } : { type });

export const buildParagraph = ({ content }) => buildNode({ type: 'paragraph', content });

export const buildColumn = ({ content }) => buildNode({ type: 'column', content });

export const buildColumnBlock = ({ content }) => buildNode({ type: 'columnBlock', content });

export const buildNColumns = n => {
    const content = [buildParagraph({})];
    const fn = () => buildColumn({ content });

    return Array.from({ length: n }, _ => fn());
};

export const findParentNodeClosestToPos = ($pos, predicate, depth = $pos.depth) => {
    for (let i = depth; i > 0; i--) {
        const node = $pos.node(i);
        const pos = i > 0 ? $pos.before(i) : 0;
        const start = $pos.start(i);

        if (predicate({ node, pos, start })) {
            return {
                start,
                depth: i,
                node,
                pos,
            };
        }
    }
};
