import React, { useState } from 'react';

import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Input from 'uikit/input';

import cx from './chat-input-message.module.scss';

export const ChatInputMessage = ({ submitting, onSubmit }) => {
    const [message, setMessage] = useState('');

    const onMessage = async e => {
        if (submitting) {
            return false;
        }

        if (e.key === 'Enter') {
            try {
                await onSubmit(String(message).trim());

                setMessage('');
            } catch (error) {}
        }
    };
    return (
        <div className={cx.chatWindowFooter} onKeyDown={onMessage}>
            <div className={cx.chatWindowFooterSend}>
                <Input
                    className={cx.chatWindowFooterInput}
                    placeholder="Введите сообщение"
                    value={message}
                    onChange={message => setMessage(message)}
                />
                <IconButton icon={<Icon type={Icons.PLAIN} />} onClick={() => onMessage({ key: 'Enter' })} />
            </div>
        </div>
    );
};
