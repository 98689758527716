import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { selectUsers } from 'slice/authSlice';
import Confirmation from 'components/confirmation';
import { Empty } from 'components/projects';
import { TableComponent } from 'components/data-components';
import { EditRowToolbar } from 'uikit/table';
import { Icons } from 'uikit/icon';
import api from 'api/index';
import cx from './favorites-page-tab-articles.module.scss';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { collectArticleBreadCrumbs } from 'lib/helpers';
import Crumbs from 'components/crumbs';
import formatDateTime from 'lib/helpers/formatDateTime';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';

const FavoritesPageTabArticles = ({ navigate, onCountChangeHandler }) => {
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const selector = useSelector(state => state);

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();
    const { width } = useWindowSize();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [articlesTotal, setArticlesTotal] = useState(0);
    const [articles, setArticles] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestArticles = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        setIsLoading(true);

        try {
            const articlesResponse = await api.favorites.getFavoritesArticles({
                page: offset / count,
                size: count,
                sort: sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '',
                filters,
                search
            });

            setArticlesTotal(articlesResponse.totalElements);
            setArticles(articlesResponse.content);

            setIsSearch(!!search);
            setIsEmpty(articlesResponse.totalElements === 0);

            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, [])

    const removeArticlesFromFavorites = useCallback(async (data) => {
        setIsLoading(true);
        closeDialog();

        try {
            if (data.id) {
                await api.favorites.deleteArticleFromFavorites(data.resource.id);
            } else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.favorites.deleteArticleFromFavorites(checkedCheckboxRef.current[i].id);
                }
            } else {
                setIsLoading(false);
            }

            tableRef?.current?.reload();
            onCountChangeHandler();

            addSuccess(data.id ? 'Статья удалена из избранного' : 'Выбранные статьи удалены из избранного')
        } catch (error) {
            addError('При удалении из избранного произошла ошибка')
        } finally {
            setIsLoading(false);
        }
    }, [onCountChangeHandler, closeDialog, addSuccess, addError]);

    const onArticlesRemoveFromFavorites = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original.resource))
        }

        const text = data?.length ?
            data.map(item => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/projects/article/${item.original.resource.id}`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.resource.title}
                            </span>
                        </>
                    )
                }
            }) : (
                <span>
                    Вы действительно хотите удалить статью 
                    <span
                        style={{ color: '#279BD9', cursor: 'pointer' }}
                        onClick={() => navigate(`/projects/article/${data.resource.id}`)}
                    >
                        {data?.resource.title}
                    </span> из избранного?
                </span>
            )
        const subTitle = data?.length ? 'Вы действительно хотите удалить нижеперечисленные объекты из избранного?' : null

        openDialog({
            title: 'Удаление из избранного',
            text,
            subTitle,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (articles) => checkboxHandler(articles.resource),
            onSubmit: () => removeArticlesFromFavorites(data),
            onClose: closeDialog,
        })
    }, [navigate, closeDialog, openDialog, removeArticlesFromFavorites, checkboxHandler])

    const collectCrumbs = (article) => {
        const crumbs = collectArticleBreadCrumbs(article.id, article.title, article.parents);
        return crumbs.map(item => {
            return {
                name: item.title,
                linkTo: article.id === item.id ? '' :
                    item.type === 'PROJECT'
                        ? `/projects/${item.id}/articles`
                        : `/projects/${crumbs[0].id}/${item.id}/section/articles`
            };
        })
    }

    const articlesColumns = useMemo(() => {
        return [
            {
                Header: 'Содержание',
                accessor: 'title',
                width: 400,
                maxWidth: 400,
                Cell: (data) => {
                    if (data.isMobile) {
                        return (
                            <div className={cx.mobileTitleCellWrapper}
                                 onClick={() => navigate(`/projects/article/${data.row.original.resource.id}`)}>
                                <p>{data.row.original.resource['title']}</p>
                                <div className={cx.crumbs}>
                                    <Crumbs data={collectCrumbs(data.row.original.resource)} maxWidth={width - 80} />
                                </div>
                                <span>{data.row.original.resource['description']}</span>
                                <div className={cx.logoContainer}>
                                    {data.row.original.resource['logoUuid'] ?
                                    <img src={api.upload.getImage(data.row.original.resource['logoUuid'], false, 512)}
                                         alt=""
                                         style={{ height: '100%', borderRadius: '5px', objectFit: 'cover' }} /> :
                                        <img alt="" style={{ width: '100%', height: '100%', borderRadius: '5px', objectFit: 'cover' }} src={'/img/article-card-cover.jpg'}/>}
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <Link to={`/projects/article/${data.row.original.resource.id}`} className={cx.titleCellWrapper}
                                  onClick={() => navigate(`/projects/article/${data.row.original.resource.id}`)}>
                                <div
                                    className={cx.titleCellWrapperImage}
                                    style={{
                                        flexShrink: 0,
                                        marginRight: '12px',
                                        width: '88px',
                                        height: '56px',
                                        background: '#EAEDF3',
                                        borderRadius: '5px'
                                    }}
                                >
                                    {data.row.original.resource['logoUuid'] &&
                                    <img style={{ width: '100%', height: '100%', borderRadius: '5px', objectFit: 'cover' }}
                                         src={api.upload.getImage(data.row.original.resource['logoUuid'], false, 512)} alt=""/>}
                                    {!data.row.original.resource['logoUuid'] &&
                                    <img style={{ width: '100%', height: '100%', borderRadius: '5px', objectFit: 'cover' }}
                                         src="/img/article-card-cover.jpg" alt=""/>}
                                </div>
                                <div>
                                    <MultiClumpTooltip
                                        clamp={2}
                                        contentClassName={cx.articleTitle}
                                        label={data.row.original.resource['title']}
                                    />
                                    <div className={cx.crumbs}>
                                        <Crumbs data={collectCrumbs(data.row.original.resource)} maxWidth={width / 5} />
                                    </div>
                                    <MultiClumpTooltip
                                        clamp={2}
                                        contentClassName={cx.articleDescription}
                                        label={data.row.original.resource.description}
                                    />
                                </div>
                            </Link>
                        );
                    }
                }
            },
            {
                Header: 'Дата изменения',
                accessor: 'modifyTime',
                width: 130,
                Cell: (data) => {
                    const modifyTime = data.row.original.resource['modifyTime'];
                    const createTime = data.row.original.resource['createTime'];

                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                            <div>
                                {formatDateTime(modifyTime ? modifyTime : createTime, true)}
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: 'Автор',
                accessor: 'author.firstName',
                width: 130,
                Cell: (data) => {
                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Автор</div>}
                            <Link to={'/edit-user/' + data.row.original.resource['author']['login']}>
                                {data.row.original.resource['author']['firstName'] + ' ' + data.row.original.resource['author']['lastName']}
                            </Link>
                        </div>
                    );
                }
            },
            {
                id: 4,
                width: 52,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: function(data) {
                    const toolbar = [];

                    toolbar.push({
                        icon: Icons.STAR_FILLED,
                        tooltip: 'Убрать из избранного',
                        iconActive: true,
                        onClick: (data) => onArticlesRemoveFromFavorites(data)
                    });

                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        link: `/projects/article/${data.row.original.resource.id}`,
                        isHidden: original => !(original?.resource?.permittedActions ?? []).includes(GLOBAL_ACTIONS.ARTICLE_EDIT)
                    });

                    return EditRowToolbar(toolbar)(data);
                }
            }
        ];
    }, [navigate, onArticlesRemoveFromFavorites, platform, width]);

    const loadUsers = async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
                return (user.firstName + ' ' + user.lastName).toString().toLowerCase().includes(search.toLowerCase());
            });
        }

        return {
            options: options.map(user => ({
                label: user.firstName + ' ' + user.lastName,
                value: user.login,
            }))
        };
    };

    const articlesFilters = [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Автор',
            'fields': [
                {
                    'name': 'authorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadUsers
                }
            ]
        },
        {
            'label': 'Срок действия статьи',
            'fields': [
                {
                    'name': 'lifeTime.greaterOrEqualThan',
                    'type': 'date',
                    'default': null
                },
                {
                    'name': 'lifeTime.lessOrEqualThan',
                    'type': 'date',
                    'default': null
                }
            ]
        }
    ];

    const articlesActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.STAR_FILLED,
            label: 'Удалить из избранного',
            onClick: (data) => onArticlesRemoveFromFavorites(data)
        });

        return actions;
    }, [onArticlesRemoveFromFavorites]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        const fetchData = async () => {
            setUsers(await selectUsers(selector).content);
        };
        fetchData();
    }, [selector]);

    return (
        <div className={cx.tableWrapper}>
            <Confirmation {...dialogState} />

            {isEmpty && !isSearch && <Empty title="Статьи не найдены"/>}

            {(!isEmpty || isSearch) &&
            <TableComponent
                innerRef={tableRef}
                isLoading={isLoading}
                searchTitle="Поиск по автору и названию"
                columns={articlesColumns}
                actions={articlesActions}
                total={articlesTotal}
                data={articles}
                onPaginate={requestArticles}
                filters={articlesFilters}
                isMobile={platform === 'mobile'}
                defaultSort={{ by: 'modifyTime', desc: true }}
                sortOptions={[
                    { Header: 'По дате создания', accessor: 'createTime' },
                    { Header: 'По дате изменения (по умолчанию)', accessor: 'modifyTime' },
                    { Header: 'По названию (по алфавиту)', accessor: 'title' },
                    { Header: 'По автору (по алфавиту по фамилии)', accessor: 'multiName' },
                    { Header: 'По количеству лайков', accessor: 'likesCount' },
                    { Header: 'По количеству дизлайков', accessor: 'dislikesCount' },
                    { Header: 'По популярности (кол-во просмотров)', accessor: 'readCount' },
                    { Header: 'По количеству комментариев', accessor: 'commentsCount' },
                    { Header: 'По рейтингу (оценка пользователя после прочтения)', accessor: '' },
                ]}
            />}
        </div>
    );
};

export default FavoritesPageTabArticles;
