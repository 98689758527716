import { buildMultiListItem, buildOrderedList } from "../../utils/buildNodes";

export const buildMultilistOrderedList = ({ attrs, content }) => {
    attrs = {
        'data-level': 1,
        'data-list-type': 'list-upper-alpha',
        'data-type': 'multi-list',
        'data-padding': 1,
        ...attrs || {}
    }

    return buildOrderedList({ attrs, content: content || [buildMultiListItem({})] })
}
