import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Duration } from 'luxon';
import cx from './audio-player.module.scss';

export default function Bar({ duration, curTime, onTimeUpdate }) {
    const curPercentage = (curTime / duration) * 100;

    function formatDuration(duration) {
        return Duration.fromObject({ seconds: duration }).toFormat('mm:ss');
    }

    function calcClickedTime(e) {
        const clickPositionInPage = e.pageX;
        const bar = document.getElementById('progress-bar');

        const barStart = bar.getBoundingClientRect().left + window.scrollX;
        const barWidth = bar.offsetWidth;

        const clickPositionInBar = clickPositionInPage - barStart;
        const timePerPixel = duration / barWidth;

        return timePerPixel * clickPositionInBar;
    }

    function handleTimeDrag(e) {
        onTimeUpdate(calcClickedTime(e));

        const updateTimeOnMove = eMove => {
            onTimeUpdate(calcClickedTime(eMove));
        };

        document.addEventListener('mousemove', updateTimeOnMove);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', updateTimeOnMove);
        });
    }

    return (
        <div className={cx.bar}>
            <span>{formatDuration(curTime)}</span>
            <div id="progress-bar" className={classNames(cx.barProgress, cx.durationValue)}
                 style={{
                     background: `linear-gradient(to right, #1280CE ${curPercentage}%, #1280CE 0, #EAEDF3  ${curPercentage}%, #EAEDF3 100%)`
                 }} onMouseDown={e => handleTimeDrag(e)}>
                <span className={cx.barProgressKnob} style={{ left: `${curPercentage - 2}%` }}/>
            </div>
            <span>{formatDuration(duration)}</span>
        </div>
    );
}

Bar.defaultProps = {
    duration: 0,
    curTime: 0,
    onTimeUpdate: () => {

    }
};

Bar.propTypes = {
    duration: PropTypes.number,
    curTime: PropTypes.number,
    onTimeUpdate: PropTypes.func
};