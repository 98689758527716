import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { useSelector } from 'react-redux';
import { useMessage, usePermittedActions, useAuthorized, useDialog } from 'lib/hooks';
import { perm, RESOURCE_TYPE } from 'model/resource';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import Confirmation from 'components/confirmation';
import Lock from 'components/lock';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button/icon-button';
import api from 'api';
import cx from './section.module.scss';
import ButtonMenu from 'uikit/button-menu';
import { selectScriptingEnabled } from 'slice/authSlice';

const Section = ({ id, title, tab, onTabChange, handleOnDelete, isMobile, empty }) => {
    const { isAuthorizedAction } = useAuthorized();
    const { checkPermission } = usePermittedActions(RESOURCE_TYPE.SECTION, id);
    const isScriptingEnabled = useSelector(selectScriptingEnabled);

    const { addError, addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [lock, setLock] = useState(false);
    const p = {
        lock: checkPermission(perm.document.LOCK),
        add: checkPermission(perm.document.SECTION_CREATE),
        edit: checkPermission(perm.document.SECTION_EDIT),
        delete: checkPermission(perm.document.SECTION_DELETE_REQUEST),
        archive: checkPermission(perm.document.RESOURCE_ARCHIVE_MOVE)
    };

    const moveToArchive = async () => {
        try {
            await api.archive.moveSectionToArchive(id);
            addSuccess('Раздел перемещен в архив');

            handleOnDelete(id);
        } catch (e) {
            addError('Не удалось переместить раздел в архив');
        }
        closeDialog();
    };

    const onMoveToArchive = async () => {
        const text = (
            <span>
              Вы действительно хотите перенести в архив раздел&nbsp;
              <span style={{ color: '#279BD9' }}>
                  {title}
              </span> ?
              Разделы в архиве могут быть восстановлены
          </span>
        )

        openDialog({
            title: 'Архивирование',
            text,
            onSubmit: () => moveToArchive(),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    };

    return (
        <>
            {lock && (
                <Lock
                    title="Доступ к разделу"
                    onClose={() => setLock(false)}
                    emptyTitle="Раздел в закрытом доступе"
                    emptyDescription="Пригласите первого участника для работы с разделом"
                    projectId={id}
                    resourceId={id}
                    resourceType="SECTION"
                    projectName={title}
                    empty={empty}
                />
            )}
            <div className={cx.section}>
                {isMobile && (
                    <ButtonMenu className={cx.navigationMenu} items={[
                        p.edit && {
                            title: 'Редактировать',
                            icon: Icons.EDIT_PEN,
                            link: `/projects/section/${id}/edit`,
                            id: 'sectionEditItem'
                        },
                        p.lock && {
                            title: 'Доступы',
                            icon: Icons.LOCK,
                            onClick: () => setLock(true),
                            id: 'sectionLockItem'
                        },
                        p.archive && {
                            title: 'Архивировать',
                            icon: Icons.ARCHIVE,
                            onClick: onMoveToArchive,
                            id: 'sectionArchiveItem'
                        }
                    ].filter(Boolean)} />
                )}
                <div className={cx.title}>
                    <h1 id="sectionTitle">{title}</h1>
                    {!isMobile && (
                        <div className={cx.navigation}>
                            {p.edit && (
                                <Link id="sectionEditIcon" to={`/projects/section/${id}/edit`}>
                                    <Icon type={Icons.EDIT_PEN} tooltip="Редактировать" />
                                </Link>
                            )}
                            {p.lock && (
                                <IconButton
                                    id="sectionLockIcon"
                                    icon={<Icon type={Icons.LOCK} tooltip="Доступы" />}
                                    onClick={() => setLock(true)}
                                />
                            )}
                            {p.archive && (
                                <IconButton
                                    id="sectionArchiveIcon"
                                    icon={<Icon type={Icons.ARCHIVE} tooltip="Архивировать" />}
                                    onClick={onMoveToArchive}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            <div className={cx.sectionMenuWrapper}>
                <div id="sectionMenuFogLeft" className={cx.sectionMenuFogLeft} style={{ opacity: 0 }}></div>
                <div id="sectionMenuFogRight" className={cx.sectionMenuFogRight}></div>
                <ul id="sectionMenu" className={cx.sectionMenu} onScroll={(e) => {
                    if (e.target.scrollWidth - 10 < e.target.clientWidth + e.target.scrollLeft) {
                        document.getElementById('sectionMenuFogRight').style.opacity = '0';
                    } else {
                        document.getElementById('sectionMenuFogRight').style.opacity = '1';
                    }

                    if (e.target.scrollLeft > 10) {
                        document.getElementById('sectionMenuFogLeft').style.opacity = '1';
                    } else {
                        document.getElementById('sectionMenuFogLeft').style.opacity = '0';
                    }
                }}>
                    <li className={tab === 'articles' ? cx.active : ''}
                        onClick={() => onTabChange('articles')}>Статьи
                    </li>
                    {isAuthorizedAction([GLOBAL_ACTIONS.ACTIVITY_PAGE]) &&
                        <li className={tab === 'actions' ? cx.active : ''} onClick={() => onTabChange('actions')}>
                            Журнал Действий
                        </li>}
                    <li className={tab === 'users' ? cx.active : ''}
                        onClick={() => onTabChange('users')}>Участники
                    </li>
                    <li className={tab === 'files' ? cx.active : ''}
                        onClick={() => onTabChange('files')}>Файлы
                    </li>
                    {isScriptingEnabled && (
                        <li id={cx.scriptsTab} className={tab === 'scripts' ? cx.active : ''}
                            onClick={() => onTabChange('scripts')}>Скрипты
                        </li>
                    )}
                </ul>
            </div>
            <Confirmation {...dialogState} />
        </>
    );
};

Section.defaultProps = {
    id: '',
    projectId: '',
    title: ''
};

Section.propTypes = {
    id: PropTypes.string,
    projectId: PropTypes.string,
    title: PropTypes.string
};

export default Section;
