import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '@reach/router';
import { useGlobalContext } from 'lib/hooks';
import {
    getSession,
    selectRoles,
    selectSessionHasBeenFetched
} from 'slice/authSlice';

export const useSession = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const sessionHasBeenFetched = useSelector(selectSessionHasBeenFetched);

    useEffect(() => {
        if (!sessionHasBeenFetched) {
            dispatch(getSession(navigate));
        }
    }, [sessionHasBeenFetched, navigate, dispatch]);

    // TODO: remove
    const roles = useSelector(selectRoles);
    const { setUserRolesInfo } = useGlobalContext();

    useEffect(() => {
        setUserRolesInfo(roles);
    }, [roles, setUserRolesInfo]);
};