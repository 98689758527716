import { getRow } from './extension-table/utilities/getRow';
import { isTableSection } from './extension-table/utilities/isTableSection';

let readFromCache;
let addToCache;

if (typeof WeakMap !== 'undefined') {
    // eslint-disable-next-line
    let cache = new WeakMap();
    readFromCache = key => cache.get(key);
    addToCache = (key, value) => {
        cache.set(key, value);
        return value;
    };
} else {
    const cache = [];
    const cacheSize = 10;
    let cachePos = 0;
    readFromCache = key => {
        for (let i = 0; i < cache.length; i += 2) if (cache[i] === key) return cache[i + 1];
    };
    addToCache = (key, value) => {
        if (cachePos === cacheSize) cachePos = 0;
        cache[cachePos++] = key;
        return (cache[cachePos++] = value);
    };
}

export class TableMap {
    constructor(width, height, map, sectionRows, problems) {
        this.width = width;
        this.height = height;
        this.map = map;
        this.sectionRows = sectionRows;
        this.problems = problems;
    }

    findCell(pos) {
        for (let i = 0; i < this.map.length; i++) {
            const curPos = this.map[i];
            if (curPos !== pos) continue;

            const left = i % this.width;
            const top = (i / this.width) | 0;
            let right = left + 1;
            let bottom = top + 1;

            for (let j = 1; right < this.width && this.map[i + j] === curPos; j++) {
                right++;
            }
            for (let j = 1; bottom < this.height && this.map[i + this.width * j] === curPos; j++) {
                bottom++;
            }

            return { left, top, right, bottom };
        }
        throw new RangeError(`No cell with offset ${pos} found`);
    }

    colCount(pos) {
        for (let i = 0; i < this.map.length; i++) {
            if (this.map[i] === pos) {
                return i % this.width;
            }
        }
        throw new RangeError(`No cell with offset ${pos} found`);
    }

    nextCell(pos, axis, dir) {
        const { left, right, top, bottom } = this.findCell(pos);
        if (axis === 'horiz') {
            if (dir < 0 ? left === 0 : right === this.width) return null;
            return this.map[top * this.width + (dir < 0 ? left - 1 : right)];
        } else {
            if (dir < 0 ? top === 0 : bottom === this.height) return null;
            return this.map[left + this.width * (dir < 0 ? top - 1 : bottom)];
        }
    }

    rectBetween(a, b) {
        const { left: leftA, right: rightA, top: topA, bottom: bottomA } = this.findCell(a);
        const { left: leftB, right: rightB, top: topB, bottom: bottomB } = this.findCell(b);
        return {
            left: Math.min(leftA, leftB),
            top: Math.min(topA, topB),
            right: Math.max(rightA, rightB),
            bottom: Math.max(bottomA, bottomB),
        };
    }

    cellsInRect(rect) {
        const result = [];
        const seen = {};
        for (let row = rect.top; row < rect.bottom; row++) {
            for (let col = rect.left; col < rect.right; col++) {
                const index = row * this.width + col;
                const pos = this.map[index];

                if (seen[pos]) continue;
                seen[pos] = true;

                if (
                    (col === rect.left && col && this.map[index - 1] === pos) ||
                    (row === rect.top && row && this.map[index - this.width] === pos)
                ) {
                    continue;
                }
                result.push(pos);
            }
        }
        return result;
    }

    sectionsInRect(rect) {
        const result = [];
        const sectionRows = this.sectionRows;
        let top = 0,
            bottom = 0;
        for (let i = 0; i < sectionRows.length; i++) {
            bottom += sectionRows[i];
            if (rect.top < bottom && rect.bottom > top) result.push(i);
            top = bottom;
        }
        return result;
    }

    isLastRowInSection(row) {
        const srows = this.sectionRows;
        let lastRow = 0;
        for (let s = 0; s < srows.length; s++) {
            lastRow += srows[s];
            if (lastRow === row) return true;
            if (lastRow > row) return false;
        }
        return false;
    }

    positionAt(row, col, table) {
        for (let i = 0; ; i++) {
            const { node, pos: rowStart } = getRow(table, row);
            const rowEnd = rowStart + node.nodeSize;
            if (i === row) {
                let index = col + row * this.width;
                const rowEndIndex = (row + 1) * this.width;
                while (index < rowEndIndex && this.map[index] < rowStart) index++;
                return index === rowEndIndex ? rowEnd - 1 : this.map[index];
            }
        }
    }

    findSection(pos) {
        const { top } = this.findCell(pos);
        let rows = 0,
            nextRows = 0;
        for (let s = 0; s < this.sectionRows.length; s++) {
            nextRows = rows + this.sectionRows[s];
            if (top < rows)
                return {
                    left: 0,
                    top: rows,
                    right: this.width,
                    bottom: nextRows,
                };
            rows = nextRows;
        }
        return {
            left: 0,
            top: 0,
            right: this.width,
            bottom: this.height,
        };
    }

    sectionOfRow(row) {
        let countRows = 0;
        for (let i = 0; i < this.sectionRows.length; i++) {
            countRows += this.sectionRows[i];
            if (row < countRows) return i;
        }
        return -1;
    }

    rectOverOneSection(rect) {
        const topSection = this.sectionOfRow(rect.top);
        return topSection >= 0 && topSection === this.sectionOfRow(rect.bottom - 1);
    }

    static get(table) {
        return readFromCache(table) || addToCache(table, computeMap(table));
    }
}

// Compute a table map.
function computeMap(table) {
    if (table.type.spec.tableRole !== 'table') throw new RangeError('Not a table node: ' + table.type.name);
    const width = findWidth(table);
    const height = findHeight(table);
    const tmap = new TableMap(width, height, [], [], null);

    let offset = 0;
    let colWidths = [];
    let rowsOffset = 0;
    for (let c = 0; c < table.childCount; c++) {
        const section = table.child(c);
        if (isTableSection(section)) {
            tmap.sectionRows.push(section.childCount);
            let smap = computeSectionMap(section, width, offset + 1, colWidths);
            tmap.map = tmap.map.concat(smap.map);
            if (smap.problems) {
                tmap.problems = tmap.problems || [];

                for (let j = 0; j < smap.problems.length; j++) {
                    const prob = smap.problems[j];

                    if (prob.type === 'missing' || prob.type === 'collision') {
                        prob.row += rowsOffset;
                    }

                    tmap.problems?.push(prob);
                }
            }
            rowsOffset += section.childCount;
        }
        offset += section.nodeSize;
    }
    let badWidths = false;

    for (let i = 0; !badWidths && i < colWidths.length; i += 2) if (colWidths[i] !== null && colWidths[i + 1] < height) badWidths = true;
    if (badWidths) findBadColWidths(tmap, colWidths, table);
    return tmap;
}

function computeSectionMap(section, width, offset, colWidths) {
    if (!isTableSection(section)) throw new Error('Not a table section node: ' + section.type.name);
    const height = section.childCount;
    const map = [];
    let mapPos = 0;
    let problems = null;
    for (let i = 0, e = width * height; i < e; i++) map[i] = 0;
    for (let row = 0, pos = offset; row < height; row++) {
        const rowNode = section.child(row);
        pos++;
        for (let i = 0; ; i++) {
            while (mapPos < map.length && map[mapPos] !== 0) mapPos++;
            if (i === rowNode.childCount) break;
            const cellNode = rowNode.child(i);
            const { colspan, rowspan, colwidth } = cellNode.attrs;
            for (let h = 0; h < rowspan; h++) {
                if (h + row >= height) {
                    (problems || (problems = [])).push({
                        type: 'overlong_rowspan',
                        pos,
                        n: rowspan - h,
                    });
                    break;
                }
                const start = mapPos + h * width;
                for (let w = 0; w < colspan; w++) {
                    if (map[start + w] === 0) map[start + w] = pos;
                    else
                        (problems || (problems = [])).push({
                            type: 'collision',
                            row,
                            pos,
                            n: colspan - w,
                        });
                    const colW = colwidth && colwidth[w];
                    if (colW) {
                        const widthIndex = ((start + w) % width) * 2,
                            prev = colWidths[widthIndex];
                        if (prev === null || (prev !== colW && colWidths[widthIndex + 1] === 1)) {
                            colWidths[widthIndex] = colW;
                            colWidths[widthIndex + 1] = 1;
                        } else if (prev === colW) {
                            colWidths[widthIndex + 1]++;
                        }
                    }
                }
            }
            mapPos += colspan;
            pos += cellNode.nodeSize;
        }
        const expectedPos = (row + 1) * width;
        let missing = 0;
        while (mapPos < expectedPos) if (map[mapPos++] === 0) missing++;
        if (missing) (problems || (problems = [])).push({ type: 'missing', row, n: missing });
        pos++;
    }
    const tableMap = new TableMap(width, height, map, [], problems);
    return tableMap;
}

export function recomputeMapSectionRows(map, table) {
    for (let c = 0; c < table.childCount; c++) {
        const section = table.child(c);
        if (isTableSection(section)) map.sectionRows.push(section.childCount);
    }
}

function findWidth(table) {
    let width = -1;
    let hasRowSpan = false;
    for (let cIndex = 0; cIndex < table.childCount; cIndex++) {
        const sectionNode = table.child(cIndex);
        if (isTableSection(sectionNode)) {
            for (let row = 0; row < sectionNode.childCount; row++) {
                const rowNode = sectionNode.child(row);
                let rowWidth = 0;
                if (hasRowSpan)
                    for (let j = 0; j < row; j++) {
                        const prevRow = sectionNode.child(j);
                        for (let i = 0; i < prevRow.childCount; i++) {
                            const cell = prevRow.child(i);
                            if (j + cell.attrs.rowspan > row) rowWidth += cell.attrs.colspan;
                        }
                    }
                for (let i = 0; i < rowNode.childCount; i++) {
                    const cell = rowNode.child(i);
                    rowWidth += cell.attrs.colspan;
                    if (cell.attrs.rowspan > 1) hasRowSpan = true;
                }
                if (width === -1) width = rowWidth;
                else if (width !== rowWidth) width = Math.max(width, rowWidth);
            }
        }
    }
    return width;
}

function findHeight(table) {
    let height = 0;
    for (let cIndex = 0; cIndex < table.childCount; cIndex++) {
        const sectionNode = table.child(cIndex);
        if (isTableSection(sectionNode)) {
            height += sectionNode.childCount;
        }
    }
    return height;
}

function findBadColWidths(map, colWidths, table) {
    if (!map.problems) map.problems = [];
    const seen = {};
    for (let i = 0; i < map.map.length; i++) {
        const pos = map.map[i];
        if (seen[pos]) continue;
        seen[pos] = true;
        const node = table.nodeAt(pos);
        if (!node) {
            throw new RangeError(`No cell with offset ${pos} found`);
        }

        let updated = null;
        const attrs = node.attrs;
        for (let j = 0; j < attrs.colspan; j++) {
            const col = (i + j) % map.width;
            const colWidth = colWidths[col * 2];
            if (colWidth !== null && (!attrs.colwidth || attrs.colwidth[j] !== colWidth))
                (updated || (updated = freshColWidth(attrs)))[j] = colWidth;
        }
        if (updated)
            map.problems.unshift({
                type: 'colwidth mismatch',
                pos,
                colwidth: updated,
            });
    }
}

function freshColWidth(attrs) {
    if (attrs.colwidth) return attrs.colwidth.slice();
    const result = [];
    for (let i = 0; i < attrs.colspan; i++) result.push(0);
    return result;
}
