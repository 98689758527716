import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import cx from './icon.module.scss';
import { useGlobalContext } from 'lib/hooks';

const Icon = ({ id, type, color, className, width, height, tooltip, tooltipPlacement="top", ...attrs }) => {
    const { platform } = useGlobalContext();
    const _color = color ? cx[color] : '';

    return (
        <>
            {platform === 'desktop' && tooltip &&
            <Tippy content={tooltip} placement={tooltipPlacement}>
                <i id={id} data-testid={id} className={classNames(className, [_color])} style={{
                    display: 'inherit',
                    width: width + 'px',
                    height: height + 'px'
                }} {...attrs}>{type}</i>
            </Tippy>}
            {(platform !== 'desktop' || !tooltip) &&
            <i id={id} data-testid={id} className={classNames(className, [_color])} style={{
                display: 'inherit',
                width: width + 'px',
                height: height + 'px'
            }} {...attrs}>{type}</i>}
        </>
    );
};

Icon.defaultProps = {
    id: "",
    type: '',
    color: '',
    className: '',
    width: 15,
    height: 15,
    tooltip: null
};

Icon.propTypes = {
    id: PropTypes.string,
    type: PropTypes.object,
    color: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    tooltip: PropTypes.string
};

export default Icon;