import React from 'react';

export const GlobalContext = React.createContext({
    userRolesInfo: [],
    setUserRolesInfo: () => {

    },

    sessionHasBeenFetched: false,
    setSessionHasBeenFetched: () => {

    },

    avatar: '',
    setAvatar: () => {

    },

    platform: '',

    visitedUrls: [],
    setVisitedUrls: () => {

    },

    formDataChanged: false,
    setFormDataChanged: () => {

    },

    locationUrl: '',
    setLocationUrl: () => {

    },

    prevLocationUrl: '',
    setPrevLocationUrl: () => {

    },

    gotScriptProgress: false,
    setGotScriptProgress: () => {

    },

    formDataChangedSubmitHandler: null,
    setFormDataChangedSubmitHandler: () => {

    },
});
