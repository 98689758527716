import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PrivatePage from 'containers/private-page-wrapper';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button';
import cx from './integrations-page.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { Link } from '@reach/router';

const integrationTime = 900;

const IntegrationsPage = ({ hasAnyAuthorities, navigate }) => {
    const { platform } = useGlobalContext();

    const [integrationDate, setIntegrationDate] = useState(null);
    const [integrationPercent, setIntegrationPercent] = useState(0);
    const [integrationLast, setIntegrationLast] = useState(0);

    const integrationNaumen = localStorage.getItem('integrationNaumen');
    const integration1CBitrix = localStorage.getItem('integration1CBitrix');

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('integrationNaumen'));

        if (!data) {
            return;
        }

        if ((data['date'] + integrationTime) > Math.floor(Date.now() / 1000)) {
            setIntegrationDate(data['date']);
        }
    }, []);
    useEffect(() => {
        if (!integrationDate) {
            return;
        }

        setTimeout(() => {
            const difference = (integrationDate + integrationTime) - Math.floor(Date.now() / 1000);

            setIntegrationPercent((integrationTime - difference) * 100 / integrationTime);
            setIntegrationLast(difference);

            if (difference <= 0) {
                setIntegrationDate(null);
            }
        }, 1000);
    });

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={classNames(cx.contentWrapper, {[cx.mobile]: platform === 'mobile'})}>
                <h1>Интеграции</h1>
                <ul className={cx.integrationsList}>
                    <li className={cx.integrationsListItem}>
                        <div className={cx.integrationsListItemContainer}>
                            <img src="/img/naumen.png" alt="NAUMEN"/>
                            <div className={cx.integrationsListItemContent}>
                                <span>NAUMEN</span>
                                {integrationDate &&
                                <div className={cx.integrationsListItemLoader}>
                                    <span style={{ width: integrationPercent + '%' }}/>
                                </div>}
                                {integrationDate &&
                                <p>Завершено {Math.floor(integrationPercent)}% -
                                    осталось {Math.floor(integrationLast / 60)} мин. {integrationLast % 60} с.</p>}
                            </div>
                        </div>
                        <div className={cx.integrationsListItemButtons}>
                            <Link to={'/global-settings/integrations/naumen'}>
                                <Button color={integrationNaumen ? 'green' : 'blue'}
                                        label={integrationNaumen
                                            ? (<div><Icon type={Icons.CHECK} width={18} height={18}/>Подключено</div>)
                                            : 'Подключить'}/>
                            </Link>
                            {(integrationNaumen && !integrationDate) &&
                            <Link to={'/global-settings/integrations/naumen'}>
                                <Button label={(<Icon type={Icons.SETTINGS} width={15} height={15}/>)}
                                        onClick={() => navigate('/global-settings/integrations/naumen')}/>
                            </Link>}
                        </div>
                    </li>
                    <li className={cx.integrationsListItem}>
                        <div className={cx.integrationsListItemContainer}>
                            <img src="/img/1cbitrix.png" alt="1С Битрикс"/>
                            <div className={cx.integrationsListItemContent}>
                                <span>1С Битрикс</span>
                            </div>
                        </div>
                        <div className={cx.integrationsListItemButtons}>
                            <Link to={'/global-settings/integrations/1cbitrix'}>
                                <Button color={integration1CBitrix ? 'green' : 'blue'}
                                        label={integration1CBitrix
                                            ? (<div><Icon type={Icons.CHECK} width={18} height={18}/>Подключено</div>)
                                            : 'Подключить'}/>
                            </Link>
                            {integration1CBitrix &&
                            <Link to={'/global-settings/integrations/1cbitrix'}>
                                <Button label={(<Icon type={Icons.SETTINGS} width={15} height={15}/>)}/>
                            </Link>}
                        </div>
                    </li>
                </ul>
            </div>
        </PrivatePage>
    );
};

export default IntegrationsPage;