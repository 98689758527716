import React, { useMemo, useState } from 'react';
import { Icons } from 'uikit/icon';
import Icon from 'uikit/icon/icon';

import cx from './video-player.module.scss'

export const Volume = ({ volume, onVolumeUpdate }) => {
  const [isMute, setIsMute] = useState(false);
  const volumePercentage = useMemo(() => volume * 100, [volume]);

  const calcClickedVolume = e => {
    const clickPositionInPage = e.pageX;
    const bar = document.getElementById('volume-bar');

    if (!bar) {
      return 0;
    }

    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;

    let clickPositionInBar;

    if (clickPositionInPage - barStart > barWidth) {
      clickPositionInBar = barWidth;
    } else if (clickPositionInPage - barStart < 0) {
      clickPositionInBar = 0;
    } else {
      clickPositionInBar = clickPositionInPage - barStart;
    }

    const volumePerPixel = 100 / barWidth;
    return (volumePerPixel * clickPositionInBar) / 100;
  };

  const handleVolumeDrag = e => {
    if (isMute) {
      setIsMute(false);
    }

    onVolumeUpdate(calcClickedVolume(e));

    const updateVolumeOnMove = eMove => {
      onVolumeUpdate(calcClickedVolume(eMove));
    };

    document.addEventListener('mousemove', updateVolumeOnMove);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateVolumeOnMove);
    });
  };

  function mute() {
    setIsMute(prevState => {
      onVolumeUpdate(Number(prevState));

      return !prevState;
    });
  }

  return (
    <div className={cx.volumeContainer}>
      <div className={cx.volumeWrapper}>
        <div className={cx.volumeMute} onClick={mute}>
          <Icon type={isMute ? Icons.VolumeOff : Icons.VolumeOn} width={20} height={20} color="white" />
        </div>
        <div
          id="volume-bar"
          className={cx.volumeBar}
          style={{
            background: `linear-gradient(to right, #1280CE ${volumePercentage}%, #1280CE 0, #EAEDF3  ${volumePercentage}%, #EAEDF3 100%)`,
          }}
          onMouseDown={e => handleVolumeDrag(e)}
        >
          <span className={cx.volume} style={{ left: `calc(${volumePercentage}% - 6px)` }} />
        </div>
      </div>
    </div>
  );
};
