import { createSlice } from '@reduxjs/toolkit';
import api from 'api/index';

function startLoading(state) {
    state.isLoading = true;
}

function loadingFailed(state, action) {
    state.isLoading = false;
    state.error = action.payload;
}

function posting(state) {
    state.isPosting = true;
}

const initialState = {
    text: '',
    list: [],
    isLoading: false,
    error: null,
    isPosting: false,
    replyComment: null,
    editComment: null,

    totalElements: 0,
    totalPages: 0,
    empty: false,
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
};

function findComment(id, path, stateList) {
    function traverse(pathRest, comment) {
        if (pathRest.length === 0) {
            return comment;
        }

        const [seekingId, ...rest] = pathRest;
        const cc = (comment?.children ?? stateList).find(com => com.id === seekingId);

        return traverse(rest, cc);
    }

    return traverse([...path, id], null);
}

export const commentsSlice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
        fetchCommentsStart: startLoading,
        fetchCommentsSuccess: (state, action) => {
            state.list = action.payload.content.sort((a, b) => Date.parse(a['createTime']) >= Date.parse(b['createTime']) ? 1 : -1);
            state.totalElements = action.payload.totalElements;
            state.totalPages = action.payload.totalPages;
            state.empty = action.payload.empty;
            state.first = action.payload.first;
            state.last = action.payload.last;
            state.number = action.payload.number;
            state.numberOfElements = action.payload.numberOfElements;
            state.size = action.payload.size;
            state.isLoading = false;
        },
        fetchCommentsFailure: loadingFailed,
        postCommentStart: posting,
        postCommentEnd: (state) => {
            state.isPosting = false;
            state.replyComment = null;
            state.text = '';
        },
        setCommentReplyFor: (state, action) => {
            state.replyComment = action.payload;
            state.editComment = null;
        },
        setCommentEditFor: (state, action) => {
            state.editComment = action.payload;
            state.replyComment = null;
        },
        insertComment: (state, action) => {
            const { comment, path } = action.payload;

            if (path.length === 0) {
                state.list = [...state.list, comment];
            } else {
                function traverseComments(path, comments, comment) {
                    if (path.length === 0) {
                        return comment;
                    } else {
                        const found = comments.find(c => c.id === path[0]);
                        return traverseComments(path.slice(1), found?.children, found);
                    }
                }

                const insertTo = traverseComments(path, state.list, null);

                if (insertTo) {
                    insertTo.children = [...insertTo.children, comment];
                }
            }

            state.isPosting = false;
            state.replyComment = null;
            state.text = '';
        },
        invalidateState: () => initialState,
        updateComment: (state, action) => {
            const { id, path, ...fields } = action.payload;
            const com = findComment(id, path, state.list);

            if (com) {
                Object.getOwnPropertyNames(fields).forEach(prop => {
                    com[prop] = fields[prop];
                });
            }
        },
        setText: (state, action) => {
            state.text = action.payload;
        }
    }
});

export const {
    fetchCommentsStart,
    fetchCommentsSuccess,
    fetchCommentsFailure,
    postCommentStart,
    postCommentEnd,
    setCommentReplyFor,
    setCommentEditFor,
    insertComment,
    invalidateState,
    updateComment,
    setText,
    setLoading
} = commentsSlice.actions;

export const selectCommentsList = state => state.comments.list;
export const selectIsLoading = state => state.comments.isLoading;
export const selectReplyComment = state => state.comments.replyComment;
export const selectEditComment = state => state.comments.editComment;
export const selectIsPosting = state => state.comments.isPosting;
export const selectText = state => state.comments.text;
export const selectPaging = state => ({
    totalElements: state.comments.totalElements,
    totalPages: state.comments.totalPages,
    empty: state.comments.empty,
    first: state.comments.first,
    last: state.comments.last,
    number: state.comments.number,
    numberOfElements: state.comments.numberOfElements,
    size: state.comments.size,
})

export const fetchCommentsList = (fetchF, id) => async dispatch => {
    try {
        dispatch(fetchCommentsStart());

        const comments = await fetchF(id);
        dispatch(fetchCommentsSuccess(comments));
    } catch (err) {
        console.log(err);
        dispatch(fetchCommentsFailure(err.toString()));
    }
};

export const postComment = (leaveCommentF, id, parentsPath, text, successCallback) => async dispatch => {
    try {
        dispatch(postCommentStart());

        const parentId = parentsPath?.length > 0 ? parentsPath[parentsPath.length - 1] : null;
        const res = await leaveCommentF(id, parentId, text);

        if (parentsPath?.length > 0) {
            dispatch(insertComment({ comment: res, path: parentsPath }));
        }

        (typeof successCallback === 'function') && successCallback();
        dispatch(postCommentEnd());
    } catch (err) {
        dispatch(fetchCommentsFailure(err.toString()));
    }
};

export const putComment = (editCommentF, id, path, text) => async dispatch => {
    try {
        dispatch(postCommentStart());
        await editCommentF(id, text);

        dispatch(updateComment({ id, path, text, updateTime: new Date().toISOString() }));
        dispatch(setCommentEditFor(null));

        dispatch(postCommentEnd());
    } catch (error) {
        dispatch(postCommentEnd(false));
    }
};

export const deleteComment = (deleteCommentF, id, path, successCallback) => async (dispatch) => {
    try {
        dispatch(postCommentStart());
        await deleteCommentF(id);

        dispatch(postCommentEnd());
        (typeof successCallback === 'function') && successCallback();
    } catch (error) {
        dispatch(postCommentEnd());
    }
};

export const likeComment = (addCommentLikeF, getCommentLikesF, login, id, path) => async (dispatch, getState) => {
    dispatch(updateComment({ id, path, liking: true }));
    const comment = findComment(id, path, getState().comments.list);

    if (comment.liked) {
        const allLikes = await getCommentLikesF(id);
        const myLike = allLikes.content.find(lk => lk.username === login);

        myLike && (await api.like.removeLike(myLike.uuid));
        dispatch(updateComment({ id, path, liked: false, likes: comment.likes - 1 }));
    } else {
        await addCommentLikeF(id);
        dispatch(updateComment({ id, path, liked: true, likes: comment.likes + 1 }));
    }

    dispatch(updateComment({ id, path, liking: false }));
};

export const dislikeComment = (addCommentDislikeF, getCommentDislikesF, login, id, path) => async (dispatch, getState) => {
    dispatch(updateComment({ id, path, liking: true }));
    const comment = findComment(id, path, getState().comments.list);

    if (comment.disliked) {
        const allDislikes = await getCommentDislikesF(id);
        const myDislike = allDislikes.content.find(lk => lk.username === login);

        myDislike && (await api.dislike.removeDislike(myDislike.uuid));
        dispatch(updateComment({ id, path, disliked: false, dislikes: comment.dislikes - 1 }));
    } else {
        await addCommentDislikeF(id);
        dispatch(updateComment({ id, path, disliked: true, dislikes: comment.dislikes + 1 }));
    }

    dispatch(updateComment({ id, path, liking: false }));
};

export default commentsSlice.reducer;
