import React from 'react';
import PropTypes from 'prop-types';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button';
import Tree from 'components/trees/lock-tree/lock-tree';
import cx from './lock-tree.module.scss';
import IconButton from 'uikit/icon-button';

const LockTree = ({ objectId, objectTitle, objectAvatar, objectRoleId, objectRoleLabel, resourceId, resourceType, onClose }) => {
    return (
        <div className={cx.lockTree}>
            <div className={cx.header}>
                <div className={cx.top}>
                    <h3>Дерево доступа</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={onClose}/>
                </div>
                <div className={cx.bottom}>
                    <div className={cx.left}>
                        <div className={cx.avatar}>
                            {objectAvatar && <img src={objectAvatar} alt="" />}
                        </div>
                        <p>{objectTitle}</p>
                    </div>
                    <div className={cx.right}>
                        <span>{objectRoleLabel}</span>
                    </div>
                </div>
            </div>
            <div className={cx.body}>
                <Tree objectId={objectId} resourceId={resourceId} resourceType={resourceType}
                      objectRoleId={objectRoleId}/>
            </div>
            <div className={cx.footer}>
                <Button label="Назад" onClick={onClose}/>
            </div>
        </div>
    );
};

LockTree.defaultProps = {
    objectId: '',
    objectTitle: '',
    objectRole: '',
    projectId: '',
    resourceId: '',
    resourceType: '',
    onClose: () => {
    }
};

LockTree.propTypes = {
    objectId: PropTypes.string,
    objectTitle: PropTypes.string,
    objectRole: PropTypes.string,
    projectId: PropTypes.string,
    resourceId: PropTypes.string,
    resourceType: PropTypes.string,
    onClose: PropTypes.func
};

export default LockTree;
