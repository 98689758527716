import React, { useRef, useState } from 'react';
import { EditableInput } from 'react-color/lib/components/common';
import { isValidHex } from 'react-color/lib/helpers/color';
import { COLOR_FORMAT } from '../../constants';
import cx from './color-picker.module.scss';
import Icon, { Icons } from 'uikit/icon';
import { DropdownList } from 'uikit/dropdown-list/dropdown-list';
import { hexToRgb, hslToHsb, isValidHSL, isValidRGB } from './utils';
import { isUndefined } from 'lodash';

const styles = {
    alpha: {
        paddingLeft: '6px',
        width: '100%',
    },
    toggle: {
        width: '32px',
        textAlign: 'right',
        position: 'relative',
    },
    icon: {
        marginRight: '-4px',
        marginTop: '12px',
        cursor: 'pointer',
        position: 'relative',
    },
    iconHighlight: {
        position: 'absolute',
        width: '24px',
        height: '28px',
        background: '#eee',
        borderRadius: '4px',
        top: '10px',
        left: '12px',
        display: 'none',
    },
    input: {
        fontSize: '11px',
        color: '#333',
        width: '38px',
        height: '26px',
        textAlign: 'center',
    },
    hexInput: {
        width: 107,
    },
    cssInput: {
        width: 152,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: '11px',
        lineHeight: '11px',
        color: '#969696',
        textAlign: 'center',
        display: 'block',
        marginTop: '12px',
    },
    svg: {
        fill: '#333',
        width: '24px',
        height: '24px',
        border: '1px transparent solid',
        borderRadius: '5px',
    },
};

export const InputFields = ({ hex, rgb, hsl, onChange }) => {
    const dropdownRef = useRef(null);

    const formats = [
        { label: 'Hex', value: COLOR_FORMAT.HEX },
        { label: 'RGB', value: COLOR_FORMAT.RGB },
        { label: 'CSS', value: COLOR_FORMAT.CSS },
        { label: 'HSB', value: COLOR_FORMAT.HSB },
        { label: 'HSL', value: COLOR_FORMAT.HSL },
    ];
    const [colorFormat, setColorFormat] = useState({
        label: 'Hex',
        value: COLOR_FORMAT.HEX,
    });

    const handleChange = (data, e) => {
        if (data.hex?.length === 7 && isValidHex(data.hex)) {
            onChange({
                ...hexToRgb(data.hex),
                a: hsl.a,
                source: 'hex',
            });
        } else if (data.css) {
            let isRGB = isValidRGB(data.css);
            let isHex = isValidHex(data.css);
            let isHSL = isValidHSL(data.css);

            if (isRGB || isHex || isHSL) {
                onChange(data.css);
            }
        } else if (data.r || data.g || data.b) {
            onChange(
                {
                    r: data.r || rgb.r,
                    g: data.g || rgb.g,
                    b: data.b || rgb.b,
                    source: 'rgb',
                },
                e
            );
        } else if (data.a) {
            if (data.a < 0) {
                data.a = 0;
            } else if (data.a > 100) {
                data.a = 100;
            }

            onChange(
                {
                    h: hsl.h,
                    s: hsl.s,
                    l: hsl.l,
                    a: Math.round(Number(data.a)) / 100,
                    source: 'rgb',
                },
                e
            );
        } else if (data.h || data.s || data.l || data.br) {
            if (typeof data.s === 'string' && data.s.includes('%')) {
                data.s = data.s.replace('%', '');
            }
            if (typeof data.l === 'string' && data.l.includes('%')) {
                data.l = data.l.replace('%', '');
            }

            if (+data.s === 1) {
                data.s = 0.01;
            } else if (+data.l === 1) {
                data.l = 0.01;
            } else if (data.br) {
                data.l = (data.br / 100) * (100 - hsl.s / 2);
            } else if (colorFormat.value === COLOR_FORMAT.HSB && data.s) {
                const br = hsl.l * 100 + hsl.s * 100 * Math.min(hsl.l * 100, 100 - hsl.l * 100);
                const l = ((br / 100) * (100 - (hsl.s * 100) / 2)) / 100;
                data.s = l === 0 || l === 1 ? 0 : ((br - l) / Math.min(l, 100 - l)) * 100;
            }
            onChange(
                {
                    h: data.h || hsl.h,
                    s: Number(!isUndefined(data.s) ? data.s : hsl.s),
                    l: Number(!isUndefined(data.l) ? data.l : hsl.l),
                    source: 'hsl',
                },
                e
            );
        }
    };

    const renderFields = () => {
        if (colorFormat.value === COLOR_FORMAT.HEX) {
            return (
                <div className={cx.fields}>
                    <div className={cx.field}>
                        <EditableInput
                            style={{
                                input: { ...styles.input, ...styles.hexInput },
                                label: styles.label,
                            }}
                            hideLabel
                            label="hex"
                            value={hex}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="a"
                            value={Math.round(rgb.a * 100)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                </div>
            );
        } else if (colorFormat.value === COLOR_FORMAT.CSS) {
            return (
                <div className={cx.fields}>
                    <div className={cx.field}>
                        <EditableInput
                            style={{
                                input: { ...styles.input, ...styles.cssInput },
                                label: styles.label,
                            }}
                            hideLabel
                            label="css"
                            value={`rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                </div>
            );
        } else if (colorFormat.value === COLOR_FORMAT.RGB) {
            return (
                <div className={cx.fields}>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="r"
                            value={rgb.r}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="g"
                            value={rgb.g}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="b"
                            value={rgb.b}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="a"
                            value={Math.round(rgb.a * 100)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                </div>
            );
        } else if (colorFormat.value === COLOR_FORMAT.HSL) {
            return (
                <div className={cx.fields}>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="h"
                            value={Math.round(hsl.h)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="s"
                            value={Math.round(hsl.s * 100)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="l"
                            value={Math.round(hsl.l * 100)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="a"
                            value={`${Math.round(hsl.a * 100)}%`}
                            arrowOffset={1}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                </div>
            );
        } else if (colorFormat.value === COLOR_FORMAT.HSB) {
            const hsb = hslToHsb({ h: hsl.h, s: hsl.s * 100, l: hsl.l * 100 });

            return (
                <div className={cx.fields}>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="h"
                            value={Math.round(hsb.h)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="s"
                            value={Math.round(hsb.s)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="br"
                            value={Math.round(hsb.br)}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                    <div className={cx.field}>
                        <EditableInput
                            style={{ input: styles.input, label: styles.label }}
                            hideLabel
                            label="a"
                            value={`${Math.round(hsl.a * 100)}%`}
                            arrowOffset={1}
                            onChange={handleChange}
                            onMouseDown={e => {
                                e.preventDefault();
                                e.stopPropagation();

                                return false;
                            }}
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={cx.fieldsWrapper}>
            <div>
                <DropdownList
                    innerRef={dropdownRef}
                    position="none"
                    className={cx.dropdown}
                    menuClassName={cx.dropdownMenu}
                    toggler={(showMenu, isOpen) => (
                        <div
                            onClick={showMenu}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 6,
                                cursor: 'pointer',
                            }}
                        >
                            <div>{colorFormat.label}</div>
                            <Icon type={isOpen ? Icons.EditorIconArrowUp : Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    )}
                >
                    {formats.map(format => {
                        return (
                            <div
                                key={format.label}
                                className={cx.dropdownItem}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    setTimeout(() => {
                                        setColorFormat(format);
                                        dropdownRef.current.hidemenu();
                                    }, 100);
                                }}
                            >
                                {format.label}
                            </div>
                        );
                    })}
                </DropdownList>
            </div>
            {renderFields()}
        </div>
    );
};
