import React, { useMemo, useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { Link, useNavigate } from '@reach/router';
import { useMessage, useAuthorized, useDialog, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import PrivatePage from 'containers/private-page-wrapper';
import Filter from 'uikit/filter/filter';
import Table, { EditRowToolbar } from 'uikit/table';
import { Icons } from 'uikit/icon';
import ManagersPageAddManager from 'app/global-settings/managers-page/managers-page-add-manager';
import cx from './managers-page.module.scss';
import api from 'api';
import Confirmation from 'components/confirmation';

const ManagersPage = ({ hasAnyAuthorities }) => {
    const { platform } = useGlobalContext();
    const navigate = useNavigate();

    const { addSuccess, addError } = useMessage();
    const { isAuthorizedAction } = useAuthorized();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [searchValue, setSearchValue] = useState('');
    const [managers, setManagers] = useState([]);

    const [filteredManagers, setFilteredManagers] = useState([]);
    const [managerTableSort, setManagerTableSort] = useState({ by: '', desc: false });

    const [selectedManager, setSelectedManager] = useState(null);
    const [isEditManagerModal, setEditManagerModal] = useState(false);

    const fetchManagers = useCallback(async () => {
        try {
            const res = await api.office.getSupervisors(0, 20, managerTableSort.by ? (managerTableSort.by + ',' + (managerTableSort.desc ? 'desc' : 'asc')) : '');

            setManagers(res.content);
            setFilteredManagers(res.content);
        } catch (e) {
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            console.log(e);
        }
    }, [addError, managerTableSort]);

    const deleteManager = useCallback(async (manager, office) => {
        closeDialog();

        await api.office.deleteSupervisorsByOfficeId(manager.login, office.id);

        addSuccess('Руководитель удален');
        fetchManagers();
    }, [addSuccess, closeDialog, fetchManagers]);
    const removeManager = useCallback(async (manager, office) => {

        openDialog({
            title: 'Удаление',
            text: 'Вы действительно хотите удалить руководителя ' + manager.firstName + ' ' + manager.lastName + '? Этот процесс нельзя будет отменить',
            color: 'red',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onSubmit: () => deleteManager(manager, office),
            onClose: () => closeDialog(),
            contentType: 'TEXT'
        })

    }, [closeDialog, deleteManager, openDialog]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Участник',
                accessor: 'user.firstName',
                Cell: (data) => {
                    if (data.isMobile) {
                        return (
                            <div className={cx.mobileManagerInfo} onClick={() => navigate(`/edit-user/${data.row.original.user.login}`)}>
                                <img src={!data.row.original.user.avatarUuid ? '/img/avatar.png' : api.upload.getImage(data.row.original.user.avatarUuid, false, 128)} alt="" />
                                <div>{data.row.original.user.firstName} {data.row.original.user.lastName}</div>
                            </div>
                        );
                    } else {
                        return (
                            <Link to={`/edit-user/${data.row.original.user.login}`} className={cx.managerInfo}>
                                <div style={{
                                    marginRight: '12px',
                                    width: '40px',
                                    height: '40px',
                                    background: '#EAEDF3',
                                    backgroundImage: `url(${!data.row.original.user.avatarUuid
                                        ? '/img/avatar.png'
                                        : api.upload.getImage(data.row.original.user.avatarUuid, false, 128)})`,
                                    backgroundSize: 'cover',
                                    borderRadius: '9999px'
                                }} />
                                <p>{data.row.original.user.firstName} {data.row.original.user.lastName}</p>
                            </Link>
                        );
                    }
                }
            },
            {
                Header: 'Офис',
                accessor: 'office.name',
                align: 'center',
                width: 120,
                Cell: (data) => {
                    return <>
                        {data.isMobile && <div className={cx.subheader}>Офис</div>}
                        {data.row.original.office['name']}
                    </>
                }
            },
            {
                id: 4,
                Header: '',
                align: 'center',
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                width: 75,
                Cell: function(data) {
                    let toolbar = [];
                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        onClick: (data) => {
                            setSelectedManager(data);
                            setEditManagerModal(true);
                        }
                    });
                    if (isAuthorizedAction([GLOBAL_ACTIONS.SUPERVISOR_DELETE])) {
                        toolbar.push({
                            icon: Icons.TRASH,
                            tooltip: 'Убрать руководителя',
                            onClick: (data) => {
                                removeManager(data.user, data.office);
                            }
                        });
                    }
                    return EditRowToolbar(toolbar, null, true, false, true)(data);
                }
            }
        ];
    }, [navigate, isAuthorizedAction, removeManager, platform]);
    const tableActions = (function() {
        if (!isAuthorizedAction([GLOBAL_ACTIONS.SUPERVISOR_DELETE])) {
            return [];
        } else {
            return [{
                icon: Icons.TRASH,
                label: 'Удалить',
                onClick: (rows) => onMultiRemove(rows)
            }];
        }
    })();

    const onManagerTableSort = async (column) => {
        const sort = Object.assign({}, managerTableSort);

        if (sort.by === column.id) {
            sort.desc = !sort.desc;
        } else {
            sort.by = column.id;
            sort.desc = false;
        }

        setManagerTableSort(sort);
    };

    const onAddManagerHandler = () => {
        setSelectedManager(null);
        setEditManagerModal(true);
    };
    const toggleEditModal = () => {
        setEditManagerModal(isOpen => !isOpen);
    };
    const submitManager = async (managerLogin, officeId, managerId) => {
        if (!managerId) {
            await api.office.addSupervisorsByOfficeId(managerLogin, officeId);
            addSuccess('Руководитель добавлен');
        } else {
            await api.office.updateSupervisorsByOfficeId(managerId, managerLogin, officeId);
            addSuccess('Руководитель изменен');
        }

        fetchManagers();
    };

    const onMultiRemove = async (rows) => {
        for (let i = 0; i < rows.length; i++) {
            await api.office.deleteSupervisorsByOfficeId(rows[i].original.user.login, rows[i].original.office.id);
        }

        addSuccess('Руководители удалены');
        fetchManagers();
    };

    useEffect(() => {
        const individualWords = searchValue.split(' ');
        const _filteredManagers = managers.filter((manager) => {
            return individualWords.some((query) => {
                return manager.user.login.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    || manager.user.firstName.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    || manager.user.lastName.toLowerCase().indexOf(query.toLowerCase()) !== -1
                    || (manager.user.middleName && manager.user.middleName.toLowerCase().indexOf(query.toLowerCase()) !== -1)
                    || (manager.user.email && manager.user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
            });
        });

        setFilteredManagers(_filteredManagers);
    }, [searchValue, managers]);
    useEffect(() => {
        fetchManagers();
    }, [fetchManagers]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />

            <div className={cx.contentWrapper}>
                <div className={cx.title}>Руководители</div>
                <div className={cx.table}>
                    <div className={cx.filter}>
                        <Filter id={'managersPageFilter'} search="Поиск по имени" searchText={searchValue} onSearch={(text) => {
                            setSearchValue(text);
                        }} add="Назначить руководителя" onAdd={onAddManagerHandler} canAdd={true}/>
                    </div>
                    <Table
                        columns={columns}
                        data={filteredManagers}
                        sort={managerTableSort}
                        onSort={onManagerTableSort}
                        actions={tableActions}
                        isMobile={platform === 'mobile'}
                        search={searchValue}
                    />
                </div>
            </div>
            <Modal isOpen={isEditManagerModal} className={cx.modal} onRequestClose={toggleEditModal}
                   overlayClassName={cx.modalOverlay}>
                <ManagersPageAddManager manager={selectedManager} onCloseHandler={toggleEditModal}
                                        onSubmitHandler={submitManager}/>
            </Modal>
        </PrivatePage>
    );
};

export default ManagersPage;
