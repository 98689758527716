import React from 'react';
import { Link } from '@reach/router';
import { createSlice } from '@reduxjs/toolkit';
import { reportTitle } from 'lib/helpers/reportTitle';

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        count: 0,
        notifications: []
    },
    reducers: {
        setNotifications: (state, action) => {
            state.count = action.payload.filter((item) => !item['readByUser']).length;
            state.notifications = action.payload;
        },
        addNotification: (state, action) => {
            const newNotifications = Object.assign([], state.notifications);
            newNotifications.unshift(action.payload);

            state.count = newNotifications.filter((item) => !item['readByUser']).length;
            state.notifications = newNotifications;
        }
    }
});

export const { setNotifications, addNotification } = notificationsSlice.actions;
export const selectNotifications = state => state.notifications.notifications;
export const selectNotificationsCount = state => state.notifications.count;

export default notificationsSlice.reducer;
export const NotificationTypes = {
    'PROJECT.CREATE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;создал проект&nbsp;
                <Link to={'/projects/' + notification['objectUuid'] + '/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'PROJECT.EDIT': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отредактировал проект&nbsp;
                <Link to={'/projects/' + notification['objectUuid'] + '/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'PROJECT.DELETE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;удалил проект&nbsp;
                <span>{notification['data']['resource.title']}</span>
            </>
        );
    },
    'PROJECT.ARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отправил в архив проект&nbsp;
                <Link to={'/archive/' + notification['objectUuid'] + '/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'PROJECT.UNARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;восстановил из архива проект&nbsp;
                <Link to={'/projects/' + notification['objectUuid'] + '/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'SECTION.CREATE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;создал раздел&nbsp;
                <Link to={'/projects/' + notification['data']['project.id'] + '/' + notification['objectUuid'] + '/section/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'SECTION.EDIT': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отредактировал раздел&nbsp;
                <Link to={'/projects/' + notification['data']['project.id'] + '/' + notification['objectUuid'] + '/section/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'SECTION.DELETE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;удалил раздел&nbsp;
                <span>{notification['data']['resource.title']}</span>
            </>
        );
    },
    'SECTION.ARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отправил в архив раздел&nbsp;
                <Link to={'/archive/' + notification['data']['project.id'] + '/' + notification['objectUuid'] + '/section/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'SECTION.UNARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;восстановил из архива раздел&nbsp;
                <Link to={'/projects/' + notification['data']['project.id'] + '/' + notification['objectUuid'] + '/section/articles'} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'ARTICLE.CREATE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;создал статью&nbsp;
                <Link to={'/projects/article/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'ARTICLE.EDIT': (onClose, notification, isModal = false) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отредактировал статью&nbsp;
                <Link to={'/projects/article/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
                {!isModal && <span>Подтвердите прочтение уведомления и ознакомтесь с содержимым статьи.</span>}
            </>
        );
    },
    'ARTICLE.DELETE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;удалил статью&nbsp;
                <span>{notification['data']['resource.title']}</span>
            </>
        );
    },
    'ARTICLE.ARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отправил в архив статью&nbsp;
                <Link to={'/archive/article/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'ARTICLE.UNARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;восстановил из архива статью&nbsp;
                <Link to={'/projects/article/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'ARTICLE_COMMENT.CREATE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;{notification['data']['comment-parent.id']
                ? 'ответил на ваш комментарий к статье' : 'оставил комментарий к статье'}&nbsp;
                <Link to={'/projects/article/' + notification['data']['resource.id.1']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'NEWS.CREATE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;создал новость&nbsp;
                <Link to={'/projects/news/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'NEWS.EDIT': (onClose, notification, isModal = false) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отредактировал новость&nbsp;
                <Link to={'/projects/news/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
                {!isModal && <span>Подтвердите прочтение уведомления и ознакомьтесь с содержимым новости.</span>}
            </>
        );
    },
    'NEWS.DELETE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;удалил новость&nbsp;
                <span>{notification['data']['resource.title']}</span>
            </>
        );
    },
    'NEWS.ARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отправил в архив новость&nbsp;
                <Link to={'/archive/news/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'NEWS.UNARCHIVE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;восстановил из архива новость&nbsp;
                <Link to={'/projects/news/' + notification['objectUuid']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'NEWS_COMMENT.CREATE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;{notification['data']['comment-parent.id']
                ? 'ответил на ваш комментарий к новости' : 'оставил комментарий к новости'}&nbsp;
                <Link to={'/projects/news/' + notification['data']['resource.id.1']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>
            </>
        );
    },
    'REPORT.GENERATE': (onClose, notification) => {
        return (
            <>
                Отчет "{reportTitle(notification['data']['report.title'])}" был успешно сгенерирован. Скачайте его по этой&nbsp;
                <a href={notification['data']['report.id']} download onClick={(e) => {
                    e.preventDefault();
                    fetch(notification['data']['report.id']).then(response => response.blob()).then(blob => {
                        const link = document.createElement('a');

                        link.href = URL.createObjectURL(blob);
                        link.download = notification['data']['report.file-name'];

                        link.click();
                    }).catch(console.error);
                }}>ссылке</a>.
            </>
        );
    },
    'PERMISSION.CREATE': (onClose, notification) => {
        let type = '';
        let link = '';

        if (notification['data']['resource.type'] === 'PROJECT') {
            type = 'проекту';
            link = '/projects/' + notification['data']['resource.id.1'] + '/articles';
        } else if (notification['data']['resource.type'] === 'SECTION') {
            type = 'разделу';
            link = '/projects/section/' + notification['data']['resource.id.1'] + '/articles';
        } else if (notification['data']['resource.type'] === 'ARTICLE') {
            type = 'статье';
            link = '/projects/article/' + notification['data']['resource.id.1'];
        } else if (notification['data']['resource.type'] === 'NEWS') {
            type = 'новости';
            link = '/projects/news/' + notification['data']['resource.id.1'];
        }

        return (
            <>
                Вам открыт доступ к {type}&nbsp;
                "<Link to={link} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>"
            </>
        );
    },
    'PERMISSION.DELETE': (onClose, notification) => {
        let type = '';
        let link = '';

        if (notification['data']['resource.type'] === 'PROJECT') {
            type = 'проекту';
            link = '/projects/';
        } else if (notification['data']['resource.type'] === 'SECTION') {
            type = 'разделу';
            link = '/projects/';
        } else if (notification['data']['resource.type'] === 'ARTICLE') {
            type = 'статье';
            link = '/projects/article/';
        } else if (notification['data']['resource.type'] === 'NEWS') {
            type = 'новости';
            link = '/projects/news/';
        }

        return (
            <>
                Вам закрыт доступ к {type}&nbsp;
                "<Link to={link + notification['data']['resource.id.1']} onClick={onClose}>
                    {notification['data']['resource.title']}
                </Link>"
            </>
        );
    },
    'USER.CREATE': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;создал пользователя&nbsp;
                <Link to={'/edit-user/' + notification['data']['login']} onClick={onClose}>
                    {notification['data']['firstname']} {notification['data']['lastname']}
                </Link>
            </>
        );
    },
    'USER.EDIT': (onClose, notification) => {
        return (
            <>
                <Link to={'/edit-user/' + notification['author']['login']} onClick={onClose}>
                    {notification['author']['firstName']} {notification['author']['lastName']}
                </Link>
                &nbsp;отредактировал Ваш профиль
            </>
        );
    }
};
