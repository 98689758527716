import { Node, mergeAttributes } from '@tiptap/core';
import { findParentNodeOfType } from 'prosemirror-utils';

export const CustomListItem = Node.create({
    name: 'listItem',
    content: 'paragraph* block*',
    defining: true,

    addOptions() {
        return {
            HTMLAttributes: {},
            bulletListTypeName: 'bulletList',
            orderedListTypeName: 'orderedList',
        };
    },

    addAttributes() {
        return {
            style: {
                default: null,
            },
            class: {
                default: null,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'li',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['li', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addKeyboardShortcuts() {
        return {
            Enter: () => this.editor.commands.splitListItem(this.name),
            Tab: () => this.editor.commands.sinkListItem(this.name),
            'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
            Backspace: ({ editor }) => {
                const listItem = findParentNodeOfType(editor.schema.nodes.listItem)(editor.state.selection);

                if (listItem && !editor.state.selection.$anchor.nodeBefore) {
                    return editor.commands.joinTextblockBackward();
                }

                return false;
            },
        };
    },
});
