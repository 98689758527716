import ApiClient from './api-axios';

export default class UploadApi extends ApiClient {
    getImage = (uuid, async = false, scale = 512) => {
      if (async) {
          return async () => await this.get(
              'image/download/' + uuid + '/' + scale,
              false,
              false,
              'arraybuffer',
              { 'Accept': 'image/png', 'Content-Type': 'image/png' }
          );
      }

      return this.prefix + 'image/download/' + uuid + '/' + scale;
    };

    uploadImage = async (initId, image) => {
      const formData = new FormData();
      formData.append('init', initId);
      formData.append('upload', image);
      
      return await this.post('editor/image', formData)
    }
}