import { Extension } from '@tiptap/core';
import { CalloutNode } from './CalloutNode';

export const CalloutExtension = Extension.create({
    addExtensions() {
        return [CalloutNode];
    },

    addCommands() {
        return {
            addCallout: () => ({ editor, chain }) => {
                const { selection } = editor.state;

                const commonAncestor = selection.$from.sharedDepth(selection.to);
                const node = selection.$from.node(commonAncestor);

                if (selection.from !== selection.to && commonAncestor !== -1 && node.type.name !== 'paragraph') {
                    let index = commonAncestor;
                    let parentNode = null;

                    for (let i = commonAncestor + 1; i--; i > 0) {
                        const node = selection.$from.node(i);

                        if (['bulletList', 'orderedList', 'multi-list'].includes(node.type.name)) {
                            index = i;
                            parentNode = node;
                        }
                    }

                    if (parentNode) {
                        const parentPos = selection.$from.before(index);
                        const endPos = parentPos + parentNode.nodeSize;

                        const calloutNode = editor.state.schema.nodes.callout.create({}, parentNode);

                        chain()
                            .focus()
                            .deleteRange({ from: parentPos, to: endPos })
                            .insertContentAt(parentPos, calloutNode.toJSON())
                            .run();

                        return true;
                    }
                }

                return chain().focus().wrapIn('callout').run();
            },
        };
    },
});
