import { useEffect, useState } from 'react';
import Mark from 'mark.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from '@reach/router';
import { useMessage } from 'lib/hooks';
import { RESOURCE_TYPE } from 'model/resource';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import api from 'api/index';

export const useNews = (id, type = 'news') => {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { addSuccess, addError } = useMessage();

    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const [newsData, setNewsData] = useState(null);
    const [news, setNews] = useState(null);

    const [state, setState] = useState(null);
    const [viewersCount, setViewersCount] = useState(0);

    const onDelete = async () => {
        setModalLoading(true);

        try {
            if (type === 'news') {
                await api.news.deleteNews(news.id);
            } else if (type === 'news-draft') {
                await api.news.deleteDraftNews(news.id);
            } else if (type === 'delayed-news') {
                await api.news.deleteDelayedNews(news.id);
            }

            addSuccess('Новость удалена');
            dispatch(updateNewsTicker(true));

            if (news.project) {
                navigate(`/projects/${news.project.id}/news`);
            } else {
                navigate('/news');
            }
        } catch (error) {
            addError('Новость не была удалена');
        } finally {
            setModalLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (newsData?.id !== id) {
                    return;
                }

                if (newsData.read) {
                    const _newsRead = type === 'news' ? await api.news.getReadNewsTime(id) : 1000;
                    newsData.time = _newsRead / 1000;
                }

                if (id) {
                    newsData.permittedActions = await api.resource.getPermittedActions(RESOURCE_TYPE.NEWS, id);
                }

                // Highlight article title, description and content;
                const params = new URLSearchParams(document.location.search);

                if (params.get('highlights') && params.get('highlights') !== 'null') {
                    const highlights = JSON.parse(params.get('highlights'));
                    const content = document.createElement('div');

                    content.innerHTML = newsData['text'];
                    let instance = new Mark(content);

                    for (let i = 0; i < highlights.length; i++) {
                        instance.mark(highlights[i]);
                    }

                    newsData['text'] = content.innerHTML;
                }

                setState(newsData.state)
                setNews(newsData);

                if (newsData) {
                    setViewersCount(await api.view.getViewersCount(id));
                }
            } catch (e) {
                if (e.errorData.message === 'error.resource.deleted') {
                    setState('DELETED')
                }
                console.log(e);
            }
        })();
    }, [id, newsData, type]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            let _news;

            if (type === 'news') {
                _news = await api.news.getNewsById(id);
            } else if (type === 'news-draft') {
                _news = await api.news.getNewsDraftById(id);
            } else if (type === 'delayed-news') {
                _news = await api.news.getDelayedNewsById(id);
            }

            setNewsData(_news);
            setNews(_news);

            setLoading(false);
        })();
    }, [id, type]);

    return {
        loading,
        news,
        onDelete,
        modalLoading,
        viewersCount,
        state
    };
};
