import React, { useState, useEffect, useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@reach/router';
import { useEventListener } from 'lib/hooks';
import IconButton, { IconButtonStatus } from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import NotificationWindow from 'components/search/notification-window/notification-window';
import { MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';
import Search from 'components/search';
import buttons from 'model/global-menu/buttons';
import { selectMenuButtons, selectUser } from 'slice/authSlice';
import { selectNewsPicker, updateNewsTicker } from 'slice/newsPickerSlice';
import { selectNotificationsCount, selectNotifications, addNotification, setNotifications } from 'slice/notificationsSlice';
import cx from './header.module.scss';
import api from 'api/index';
import { NotificationSubscribe } from 'api/notification-wss';

function Header({ platform, isTicker }) {
    const dispatch = useDispatch();

    const { toggleMenu } = useContext(MobileMenuContext);

    const userData = useSelector(selectUser) ?? {};
    const menuButtons = useSelector(selectMenuButtons);
    const newsPicker = useSelector(selectNewsPicker);

    const onNotificationClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const getLinkToSettings = () => {
        if (menuButtons[buttons.SETTINGS_COMPANY]) {
            return '/global-settings/companies';
        } else if (menuButtons[buttons.SETTINGS_SUPERVISORS]) {
            return '/global-settings/managers';
        } else if (menuButtons[buttons.SETTINGS_POSITIONS]) {
            return '/global-settings/positions';
        } else if (menuButtons[buttons.SETTINGS_ROLES]) {
            return '/global-settings/custom-roles';
        } else if (menuButtons[buttons.SETTINGS_INTEGRATION]) {
            return '/global-settings/integrations';
        }

        return '/global-settings/about';
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const notificationsResponse = await api.notification.getUnread(0, 2000, true);
                dispatch(setNotifications(notificationsResponse));
            } catch {}
        };
        fetchData();
    }, [dispatch]);

    const notificationsCount = useSelector(selectNotificationsCount);
    const notifications = useSelector(selectNotifications);

    const [isNotifications, setIsNotifications] = useState(false);
    const [isNotificationsWindow, setIsNotificationsWindow] = useState(false);

    const onNotification = useCallback(
        (message) => {
            let newMessage = JSON.parse(message['body']);
            newMessage['author'] = newMessage['user'];
            dispatch(addNotification(newMessage));

            if (newMessage.data['news.ticker']) {
                dispatch(updateNewsTicker(true));
            }
        },
        [dispatch]
    );
    const onNotificationsSubscribe = useCallback(() => {
        NotificationSubscribe(onNotification);
        setIsNotifications(true);
    }, [onNotification]);

    const onNotificationOpen = () => {
        const payload = JSON.parse(JSON.stringify(notifications));

        for (let i = 0; i < payload.length; i++) {
            if ((payload[i]['objectType'] === 'ARTICLE' || payload[i]['objectType'] === 'NEWS') && payload[i]['eventType'] === 'EDIT') {
                continue;
            }

            payload[i]['readByUser'] = i < 5;

            if (payload[i]['readByUser']) {
                api.notification.read(payload[i]['id']);
            }
        }

        dispatch(setNotifications(payload));
        setIsNotificationsWindow(!isNotificationsWindow);
    };

    useEventListener('click', (e) => {
        if (isNotificationsWindow && !e.composedPath()?.find?.((item) => item.id === 'notifButtonContainer')) {
            const payload = JSON.parse(JSON.stringify(notifications)).filter((item) => !item['readByUser']);

            dispatch(setNotifications(payload));
            setIsNotificationsWindow(false);
        }
    });

    useEffect(() => {
        if (!isNotifications && notifications !== null) {
            onNotificationsSubscribe();
        }
    }, [notifications, isNotifications, onNotificationsSubscribe]);

    if (platform === 'mobile') {
        return (
            <>
                {isNotificationsWindow &&
                <NotificationWindow
                    isMobile={true}
                    notificationsCount={notificationsCount}
                    notifications={notifications}
                    onClick={onNotificationClick}
                    onClose={() => setIsNotificationsWindow(false)}/>}
                <div className={classNames(cx.mobileContentWrapper, { [cx.tickerMobile]: newsPicker.count > 0 })}>
                    <div className={cx.menuButton} onClick={() => toggleMenu()}>
                        <Icon type={Icons.SANDWICH} width={20} height={14} />
                    </div>
                    <div className={cx.head}>
                        <div className={cx.search}>
                            <Search title="Поиск..." isTicker={isTicker} />
                        </div>
                        <div id="notifButtonContainer" className={cx.notifBtn} onClick={onNotificationOpen}>
                            <IconButton
                                buttonId="notifButton"
                                icon={<Icon type={Icons.ALARM} tooltip="Уведомления" />}
                                count={notificationsCount}
                                status={notificationsCount !== 0 ? IconButtonStatus.RED : IconButtonStatus.NONE}
                            />
                        </div>
                        <Link className={cx.userInfo} to={'/user/profile'}>
                            <img
                                id="menu-user-avatar"
                                src={userData['avatarUuid'] ? api.upload.getImage(userData['avatarUuid'], false, 128) : '/img/avatar.png'}
                                alt=""
                            />
                        </Link>
                    </div>
                </div>
            </>
        );
    }

    if (platform === 'desktop') {
        return (
            <div id="head" className={cx.desktopHead}>
                <div className={cx.search}>
                    <Search />
                </div>
                <ul>
                    <li className={cx.separator} />
                    {userData['enabledNotificationTypes'].length !== 0 && (
                        <li id="notifButtonContainer" onClick={onNotificationOpen}>
                            <IconButton
                                buttonId="notifButton"
                                icon={<Icon type={Icons.ALARM} tooltip="Уведомления" />}
                                count={notificationsCount}
                                status={notificationsCount !== 0 ? IconButtonStatus.RED : IconButtonStatus.NONE}
                            />
                            {isNotificationsWindow && (
                                <NotificationWindow
                                    isMobile={false}
                                    notificationsCount={notificationsCount}
                                    notifications={notifications}
                                    onClick={onNotificationClick}
                                    onClose={() => setIsNotificationsWindow(false)}
                                />
                            )}
                        </li>
                    )}
                    {/*<li>*/}
                    {/*    <Link to="/chat">*/}
                    {/*        <IconButton icon={<Icon type={Icons.CHAT}/>} status={IconButtonStatus.GREEN}/>*/}
                    {/*    </Link>*/}
                    {/*</li>*/}
                    <li id="favoritesButtonContainer">
                        <Link to="/user/favorites/projects">
                            <IconButton buttonId="favoritesButton" icon={<Icon type={Icons.STAR} tooltip="Избранные" />} />
                        </Link>
                    </li>
                    <li className={cx.separator} />
                    <li>
                        <Link to="/user/profile" className={cx.userInfo}>
                            <img
                                id="menu-user-avatar"
                                src={userData['avatarUuid'] ? api.upload.getImage(userData['avatarUuid'], false, 128) : '/img/avatar.png'}
                                alt=""
                            />
                            <div>{userData['firstName']}</div>
                        </Link>
                    </li>
                    {menuButtons && menuButtons[buttons.SETTINGS] === true && (
                        <>
                            <li className={cx.separator} />
                            <li>
                                <Link to={getLinkToSettings()}>
                                    <IconButton buttonId="settingsButton" icon={<Icon type={Icons.SETTINGS} tooltip="Настройки" />} />
                                </Link>
                            </li>
                        </>
                    )}
                </ul>
            </div>
        );
    }
}

export default Header;
