import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from '@reach/router';
import { useMessage } from 'lib/hooks';
import { TRAINING_RATING_SCALES } from 'lib/config/constant';
import { TrainingNavigation } from 'components/training';
import Button from 'uikit/button';
import Icon, { Icons } from 'uikit/icon';
import api from 'api';
import cx from './training-tab-open.module.scss';

export default function TrainingTabOpen() {
    const navigate = useNavigate();
    const { addError } = useMessage();

    const [tests, setTests] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.training.getOpenTests();
                setTests(response);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };
        fetchData();
    }, [addError]);

    return (
        <div className={cx.trainingOpen}>
            <TrainingNavigation/>
            <div className={cx.trainingOpenContainer}>
                <h1>Открытые тесты</h1>
                <div className={cx.trainingOpenItems}>
                    {tests.map((test, i) => {
                        return (
                            <div key={i} className={cx.trainingOpenItem}>
                                <div className={cx.trainingOpenItemTop}>
                                    <img src={test['image'] ? test['image'] : '/img/article-card-cover.jpg'} alt={test['title']}/>
                                    <div className={cx.trainingOpenItemContent}>
                                        <Link to={'/training/test/' + test['id']}>
                                            <h3>{test['title']}</h3>
                                        </Link>
                                        <p>{test['description']}</p>
                                    </div>
                                    <Button color="green" onClick={() => navigate('/training/test/' + test['id'] + '/process')}>
                                        <Icon type={Icons.PLAY_FILL} width={18} height={18}/>
                                        <span>Пройти</span>
                                    </Button>
                                </div>
                                <ul className={cx.trainingOpenItemBottom}>
                                    <li>
                                        <span>Дата закрытия</span>
                                        <p>{new Date(test['closeDate']).toLocaleString()}</p>
                                    </li>
                                    <li>
                                        <span>Попыток осталось</span>
                                        <p>{test['tryCount']}</p>
                                    </li>
                                    <li>
                                        <span>Оценивание</span>
                                        <p>{TRAINING_RATING_SCALES.find(p => p.value === test.gradingType)['label']}</p>
                                    </li>
                                    <li>
                                        <span>Содержание</span>
                                        <p>{test['questionsCount']} вопросов</p>
                                    </li>
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
