import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUsers } from 'slice/authSlice';
import Button from 'uikit/button';
import { Select } from 'uikit/select';
import Table from 'uikit/table';
import api from 'api';
import cx from './training-test-reports.module.scss';
import { useMessage } from 'lib/hooks';

const reportTypeOptions = [
    {
        label: 'Общий отчет по тесту',
        value: 1
    },
    {
        label: 'Отчет по эффективности прохождения',
        value: 2
    },
    {
        label: 'Отчет по участнику',
        value: 3
    }
];
const reportTryOptions = [
    {
        label: 1,
        value: 1
    },
    {
        label: 2,
        value: 2
    },
    {
        label: 3,
        value: 3
    },
    {
        label: 4,
        value: 4
    },
    {
        label: 5,
        value: 5
    }
];
const reportColumns = {
    GLOBAL: [
        {
            Header: 'Дата создания',
            accessor: 'createDate',
            Cell: (data) => {
                return new Date(data['row']['original']['createDate']).toLocaleString();
            }
        },
        {
            Header: 'Название',
            accessor: 'title'
        },
        {
            Header: 'Общий процент правильных отв.',
            accessor: 'correct',
            Cell: (data) => {
                return data['row']['original']['correct'] + ' %';
            }
        },
        {
            Header: 'Пройдено / Всего попыток',
            accessor: 'passed',
            Cell: (data) => {
                return data['row']['original']['passed'] + ' / ' + data['row']['original']['attempts'];
            }
        },
        {
            Header: 'Cр. время прохождения',
            accessor: 'middleTime',
            Cell: (data) => {
                return new Date(data['row']['original']['middleTime']).toISOString().substr(11, 8);
            }
        }
    ],
    EFFICIENCY: [
        {
            Header: 'Дата/Время',
            accessor: 'createDate',
            Cell: (data) => {
                return new Date(data['row']['original']['createDate']).toLocaleString();
            }
        },
        {
            Header: 'Попытка',
            accessor: 'tryNumber'
        },
        {
            Header: 'Участник',
            accessor: 'user'
        },
        {
            Header: 'Ответы на вопросы',
            accessor: 'questions',
            Cell: (data) => {
                return data['row']['original']['questions'].map((question, i) => {
                    return (
                        <p style={{ margin: 0, fontSize: '13px' }}>
                            {(i + 1) + '. ' + (question['isRight'] ? 'Правильно' : 'Неправильно') + ';'}
                        </p>
                    );
                });
            }
        },
        {
            Header: 'Процент правильных',
            accessor: 'correctPercent',
            Cell: (data) => {
                return data['row']['original']['correctPercent'] + ' %';
            }
        },
        {
            Header: 'Правильных ответов',
            accessor: 'correctCount',
            Cell: (data) => {
                return data['row']['original']['correctCount'] + ' / ' + data['row']['original']['totalCount'];
            }
        },
        {
            Header: 'Тест пройден',
            accessor: 'isPassed',
            Cell: (data) => {
                return data['row']['original']['isPassed'] ? 'Да' : 'Нет';
            }
        },
        {
            Header: 'Время прохождения',
            accessor: 'time',
            Cell: (data) => {
                return new Date(data['row']['original']['time']).toISOString().substr(11, 8);
            }
        }
    ],
    USER: [
        {
            Header: 'Вопрос',
            accessor: 'question'
        },
        {
            Header: 'Ответы на вопросы',
            accessor: 'answers',
            Cell: (data) => {
                if (data['row']['original']['type'] === 0 || data['row']['original']['type'] === 1) {
                    return data['row']['original']['answers'].map(p => {
                        if (p['answer'] === 'true') {
                            return (
                                <p style={{ margin: 0, fontSize: '13px' }}>
                                    {p['value1'] + ' (' + (p['isRight'].toString() === p['answer'] ? 'Правильно' : 'Неправильно') + ')'}
                                </p>
                            );
                        }

                        return '';
                    });
                } else if (data['row']['original']['type'] === 2) {
                    return (
                        <p style={{ margin: 0, fontSize: '13px' }}>
                            {data['row']['original']['answers'][0]['answer'] + ' (Открытый вопрос)'}
                        </p>
                    );
                } else if (data['row']['original']['type'] === 3) {
                    return (
                        <p style={{ margin: 0, fontSize: '13px' }}>
                            {data['row']['original']['answers'][0]['answer'] + ' ' +
                                (data['row']['original']['answers'].filter(p => p['value1'] === p['answer']).length === 0
                                    ? '(Неправильно)' : '(Правильно)')}
                        </p>
                    );
                } else if (data['row']['original']['type'] === 4) {
                    return data['row']['original']['answers'].map(p => {
                        return (
                            <p style={{ margin: 0, fontSize: '13px' }}>
                                {p['value1'] + ' - ' + p['answer'] + ' ' + (p['value2'] === p['answer']
                                    ? '(Правильно)' : '(Неправильно)')}
                            </p>
                        );
                    });
                }
                else if (data['row']['original']['type'] === 5) {
                    return data['row']['original']['answers'].map(p => {
                        return (
                            <p style={{ margin: 0, fontSize: '13px' }}>
                                {p['answer'] + ' ' + (p['value1'] === p['answer'] ? '(Правильно)' : '(Неправильно)')}
                            </p>
                        );
                    });
                }
            }
        },
        {
            Header: 'Время на вопрос',
            accessor: 'time',
            Cell: (data) => {
                return new Date(data['row']['original']['time']).toISOString().substr(11, 8);
            }
        }
    ]
};

export default function TrainingTestReports({ id }) {
    const selector = useSelector((state) => state);
    const { addError } = useMessage();

    const [isFormed, setIsFormed] = useState(false);
    const [users, setUsers] = useState([]);

    const [reportType, setReportType] = useState(reportTypeOptions[0]);

    // const [reportTimeRange, setReportTimeRange] = useState(reportTimeRangeOptions[0]);
    // const [reportTimeFrom, setReportTimeFrom] = useState(null);
    // const [reportTimeTo, setReportTimeTo] = useState(null);

    const [reportUser, setReportUser] = useState(null);
    const [reportTry, setReportTry] = useState(reportTryOptions[0]);

    const [reportData, setReportData] = useState([]);

    const onChangeReportType = (reportType) => {
        setReportType(reportType);
        setIsFormed(false);
    };

    const canReport = () => {
        return reportType === reportTypeOptions[0] || reportType === reportTypeOptions[1]
            || (reportType === reportTypeOptions[2] && reportUser !== null);
    }
    const onReport = async () => {
        if (reportType === reportTypeOptions[0]) {
            const response = await api.training.getGlobalReport(id);
            setReportData([response]);
        } else if (reportType === reportTypeOptions[1]) {
            const response = await api.training.getEfficiencyReport(id, 0, 0);
            setReportData(response);
        } else if (reportType === reportTypeOptions[2]) {
            try {
                const response = await api.training.getUserReport(id, reportUser['value'], reportTry['value']);
                setReportData(response);
            } catch (e) {
                setReportData([]);
                addError('Не удалось сформировать отчет');
            }
        }

        setIsFormed(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            const users = await selectUsers(selector);
            setUsers(users.content.map((user) => {
                return { label: user.firstName + ' ' + user.lastName, value: user.id };
            }));
        };
        fetchData();
    }, [selector]);

    return (
        <div className={cx.trainingTestViewReports}>
            <div className={cx.trainingTestViewReportsHead}>
                <div className={cx.trainingTestViewReportsHeadItem}>
                    <Select label="Тип отчета" options={reportTypeOptions} value={reportType}
                            onChange={(value) => onChangeReportType(value)}/>
                    {/*<Select label="Версия документа" options={reportVersionOptions} value={reportVersion}*/}
                    {/*        onChange={(value) => setReportVersion(value)}/>*/}
                </div>
                {/*{reportType.value === 2 &&*/}
                {/*<div className={cx.trainingTestViewReportsHeadItem}>*/}
                {/*    <Select label="Диапазон времени" options={reportTimeRangeOptions} value={reportTimeRange}*/}
                {/*            onChange={(value) => setReportTimeRange(value)}/>*/}
                {/*    <div className={cx.trainingTestViewReportsHeadItemInner}>*/}
                {/*        <DatePicker label="От" value={reportTimeFrom} onChange={(value) => setReportTimeFrom(value)}/>*/}
                {/*        <DatePicker label="До" value={reportTimeTo} onChange={(value) => setReportTimeTo(value)}/>*/}
                {/*    </div>*/}
                {/*</div>}*/}
                {reportType.value === 3 &&
                <div className={cx.trainingTestViewReportsHeadItem}>
                    <Select label="Участник" options={users} value={reportUser}
                            onChange={(value) => setReportUser(value)}/>
                    {reportUser &&
                    <Select label="Попытка" options={reportTryOptions} value={reportTry}
                            onChange={(value) => setReportTry(value)}/>}
                </div>}
                <div className={cx.trainingTestViewReportsHeadButtons}>
                    <Button color="green" disabled={!canReport()} onClick={() => onReport()}>Сформировать отчет</Button>
                    {/*<IconButton type={IconButtonType.TABLE} label="Скачать"*/}
                    {/*            icon={<Icon type={Icons.DOWNLOAD} width={20} height={20}/>}/>*/}
                    {/*<IconButton type={IconButtonType.TABLE} label="Отправить по Email"*/}
                    {/*            icon={<Icon type={Icons.LETTER} width={20} height={20}/>}/>*/}
                </div>
            </div>
            {isFormed &&
            <div className={cx.trainingTestViewReportsBody}>
                <Table columns={reportType.value === 1 ? reportColumns.GLOBAL : reportType.value === 2
                    ? reportColumns.EFFICIENCY : reportColumns.USER} data={reportData}/>
            </div>}
        </div>
    );
}