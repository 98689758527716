import React from 'react';
import Submenu from 'uikit/submenu';
import sidebarLayout from 'containers/sidebar-layout';
import { ContentWrapper } from 'containers/content-wrapper';


export const submenuWrapper = (menuConfig, Component, mobileMenuTitle = '') => {
    const submenu = () => <Submenu menuConfig={menuConfig} mobileMenuTitle={mobileMenuTitle}/>;
    const SidebarLayout = sidebarLayout(submenu);

    return (props) => (
        <ContentWrapper>
            <SidebarLayout>
                <Component {...props}/>
            </SidebarLayout>
        </ContentWrapper>
    );
};
