import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from '@reach/router';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useCustomMobileMenu } from 'lib/hooks/useCustomMobileMenu';
import { useMessage, useDialog, useGlobalContext } from 'lib/hooks';
import TreeUtils from 'lib/util/tree.util';
import UrlUtils from 'lib/util/url.util';
import { setTreeInvalid, invalidateProjects } from 'slice/treeSlice';
import { ContentWrapper } from 'containers/content-wrapper';
import sidebarLayout from 'containers/sidebar-layout';
import { MobileTree, NavigationTree, NODE_TYPES } from 'components/trees';
import { useAutoNavigate } from 'components/trees/utils';
import Confirmation from 'components/confirmation';
import { useDefaultTreeProps } from './useDefaultTreeProps';
import { fetchMenuButtons } from 'slice/authSlice';
import api from 'api/index';
import {updateNewsTicker} from 'slice/newsPickerSlice';

const TreeComponent = React.forwardRef((props, treeRef) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { onTreeUpdate, getNodeLink } = useDefaultTreeProps(props, treeRef);

    const { addError, addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const getEditNodeLink = (node) => {
        switch (node.nodeType) {
            case NODE_TYPES.PROJECT:
                return `/projects/project/${node.id}/edit`;
            case NODE_TYPES.SECTION:
                return `/projects/section/${node.id}/edit`;
            case NODE_TYPES.ARTICLE:
                return `/projects/article/${node.id}/edit`;
            default:
                throw new Error('Unknown node type');
        }
    };
    const onNavigate = useCallback((node) => { onTreeUpdate(node) }, [onTreeUpdate]);

    const archiveNode = useCallback((node) => {
        let method;

        switch (node.nodeType) {
            case NODE_TYPES.PROJECT:
                method = api.archive.moveProjectToArchive;
                break;
            case NODE_TYPES.SECTION:
                method = api.archive.moveSectionToArchive;
                break;
            case NODE_TYPES.ARTICLE:
                method = api.archive.moveArticleToArchive;
                break;
            default:
                throw new Error('Unknown node type');
        }

        if (method) {
            method(node.id)
                .then(() => {
                    // treeRef.current.tree.removeNode(node);
                    // props.reloadTree();

                    switch (node.nodeType) {
                        case NODE_TYPES.PROJECT:
                            addSuccess('Проект перемещен в архив');
                            break;
                        case NODE_TYPES.SECTION:
                            addSuccess('Раздел перемещен в архив');
                            break;
                        case NODE_TYPES.ARTICLE:
                            addSuccess('Статья перемещена в архив');
                            break;
                        default:
                            throw new Error('Unknown node type');
                    }
                })
                .catch(() => {
                    switch (node.nodeType) {
                        case NODE_TYPES.PROJECT:
                            addError('Не удалось переместить проект в архив');
                            break;
                        case NODE_TYPES.SECTION:
                            addError('Не удалось переместить раздел в архив');
                            break;
                        case NODE_TYPES.ARTICLE:
                            addError('Не удалось переместить статью в архив');
                            break;
                        default:
                            throw new Error('Unknown node type');
                    }
                })
                .then(() => {
                    closeDialog();
                    dispatch(setTreeInvalid(true));
                    const tree_ = treeRef.current.tree;
                    const parentNode = node.parent;

                    if (node.state.selected) {
                        if (node?.nodeType === NODE_TYPES.ARTICLE && parentNode?.nodeType === NODE_TYPES.SECTION) {
                            const projectNode = TreeUtils.getRoot(parentNode);
                            const url = UrlUtils.getSectionUrl(projectNode.id, parentNode.id);
                            tree_.selectNode(undefined);
                            navigate(url);
                        }
                        if (node?.nodeType === NODE_TYPES.SECTION) {
                            const parentTopNode = TreeUtils.getTopParentNode(node);
                            let url = `/projects/${parentTopNode?.id}/articles`;

                            if (parentNode['nodeType'] === 'SECTION') {
                                url = UrlUtils.getSectionUrl(parentTopNode.id, parentNode.id, 'articles');
                            }

                            tree_.selectNode(undefined);
                            navigate(url);
                        }
                    }
                    if (parentNode?.nodeType === NODE_TYPES.PROJECT || !parentNode?.id) {
                        tree_.removeNode(node);
                        tree_.selectNode(null);
                        props?.setScrollToNode?.(null);
                        navigate('/projects');
                    }
                    dispatch(invalidateProjects());
                    dispatch(fetchMenuButtons());

                    if (node?.nodeType === NODE_TYPES.PROJECT) {
                        dispatch(updateNewsTicker(true));
                    }

                    function traverse(node) {
                        if (node) {
                            if (node.unreadCount > 0) {
                                tree_.updateNode(node, { unreadCount: Math.max(0, node.unreadCount - 1) }, { shallowRendering: false });
                            }
                            if (node.parent) {
                                traverse(node.parent);
                            }
                        }
                    }
                    traverse(node?.parent);

                    tree_.removeNode(node);
                    props.reloadTree();
                });
        }
    }, [treeRef, dispatch, addError, addSuccess, closeDialog, navigate, props]);
    const _onArchiveNode = useCallback((node) => {
        const nodeType = node.nodeType === NODE_TYPES.PROJECT ? 'проект' : node.nodeType === NODE_TYPES.SECTION ? 'раздел' : 'статью';
        const text = (
            <span>
                Вы действительно хотите перенести в архив {nodeType} <span style={{ color: '#279BD9' }}>{node?.name}</span> ? Объекты в
                архиве могут быть восстановлены
            </span>
        );

        openDialog({
            title: 'Архивирование',
            text,
            onSubmit: () => archiveNode(node),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    }, [archiveNode, closeDialog, openDialog]);

    const unArchiveNode = useCallback((node) => {
        let method;

        switch (node.nodeType) {
            case NODE_TYPES.PROJECT:
                method = api.archive.moveProjectFromArchive;
                break;
            case NODE_TYPES.SECTION:
                method = api.archive.moveSectionFromArchive;
                break;
            case NODE_TYPES.ARTICLE:
                method = api.archive.moveArticleFromArchive;
                break;
            default:
                throw new Error('Unknown node type');
        }

        if (method) {
            method(node.id)
                .then(() => {
                    // treeRef.current.tree.removeNode(node);
                    props.reloadTree();
                    dispatch(fetchMenuButtons());

                    if (node.nodeType === 'PROJECT') {
                        addSuccess('Проект восстановлен из архива');
                    } else if (node.nodeType === 'SECTION') {
                        addSuccess('Раздел восстановлен из архива');
                    }
                })
                .catch(() => {
                    addError('Не удалось восстановить проект из архива');
                })
                .then(() => {
                    closeDialog();
                });
        }
    }, [dispatch, addError, addSuccess, closeDialog, props]);
    const _onUnArchiveNode = useCallback((node) => {
        const nodeType = node.nodeType === NODE_TYPES.PROJECT ? 'проект' : node.nodeType === NODE_TYPES.SECTION ? 'раздел' : 'статью';
        const text = (
            <span>
                Вы действительно хотите восстановить из архива {nodeType}{' '}
                <span
                    style={{ color: '#279BD9' }}
                    onClick={() => {
                        navigate(`archive/${node.id}/articles`);
                        onNavigate(node);
                        closeDialog();
                    }}
                >
                    {node?.name}
                </span>{' '}
                ?
            </span>
        );

        openDialog({
            title: 'Восстановление',
            text,
            onSubmit: () => unArchiveNode(node),
            onClose: closeDialog,
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            contentType: 'TEXT'
        });
    }, [unArchiveNode, closeDialog, openDialog, navigate, onNavigate]);

    const onDeleteNode = useCallback(node => {
        let method;

        switch (node.nodeType) {
            case NODE_TYPES.PROJECT:
                method = api.project.deleteProject;
                break;
            case NODE_TYPES.SECTION:
                method = api.section.deleteSection;
                break;
            case NODE_TYPES.ARTICLE:
                method = api.article.deleteArticle;
                break;
            case NODE_TYPES.SCRIPTS:
                method = api.scripting.removeScript;
                break;
            default:
                throw new Error('Unknown node type');
        }

        method && method(node.id).then(() => {
            props.reloadTree();

            if (node.nodeType === 'PROJECT') {
                addSuccess('Проект удалён');
            } else if (node.nodeType === 'SECTION') {
                addSuccess('Раздел удалён');
            } else if (node.nodeType === 'ARTICLE') {
                addSuccess('Статья удалена');
            } else if (node.nodeType === 'SCRIPTS') {
                addSuccess('Скрипт удален');
            }
            navigate('/archive');
        }).catch(() => addError('Не удалось удалить. Попробуйте позже.')).then(() => closeDialog());
    }, [navigate, addError, addSuccess, closeDialog, props]);
    const _onDeleteNode = useCallback(node => {
        const nodeType = node.nodeType === NODE_TYPES.PROJECT ? 'проект' : node.nodeType === NODE_TYPES.SECTION
            ? 'раздел' : node.nodeType === NODE_TYPES.ARTICLE ? 'статью' : 'скрипт';
        const text = (
            <span>Вы действительно хотите удалить {nodeType}&nbsp;
                <span style={{ color: '#279BD9' }} onClick={() => {
                    navigate(`archive/${node.id}/articles`);
                    onNavigate(node);
                    closeDialog();
                }}>{node?.name}</span>? Этот процесс нельзя будет отменить
            </span>);

        openDialog({
            title: 'Удаление',
            text,
            onSubmit: () => onDeleteNode(node),
            onClose: closeDialog,
            contentType: 'TEXT',
            color: 'red',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
        });
    }, [onDeleteNode, closeDialog, openDialog, navigate, onNavigate]);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some((c) => c.id === node.id)) {
                return prevValue.filter((c) => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);

    const archiveBatch = useCallback(async (startAction, finishAction) => {
        closeDialog();
        startAction();
        const count = checkedCheckboxRef.current.length;
        if (count === 1) {
            archiveNode(checkedCheckboxRef.current[0]);
            finishAction();
        } else {
            await api.archive.moveToArchive(checkedCheckboxRef.current.map((node) => node.id));

            checkedCheckboxRef.current.forEach(() => {
                props.reloadTree();
            });

            setCheckedCheckbox([]);
            dispatch(setTreeInvalid(true));
            dispatch(invalidateProjects());
            dispatch(fetchMenuButtons());
            finishAction();
            navigate('/projects');
            addSuccess('Выбранные (' + count + ') ресурсы перемещены в архив');
        }
    }, [dispatch, checkedCheckboxRef, closeDialog, navigate, addSuccess, archiveNode, props]);
    const _onArchiveBatch = useCallback((nodes, startAction, finishAction) => {
        const subTitle =
            'Вы действительно хотите перенести в архив нижеперечисленные объекты? Объекты в архиве могут быть восстановлены';
        const text = nodes.map((node) => {
            return {
                ...node,
                text: (
                    <span
                        style={{ color: '#279BD9' }}
                        onClick={() => {
                            navigate(`archive/${node.id}/articles`);
                            onNavigate(node);
                            closeDialog();
                        }}
                    >
                        {node.name}
                    </span>
                ),
            };
        });

        openDialog({
            title: 'Архивирование',
            subTitle,
            text,
            contentType: 'CHECKBOX_LIST',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onChange: (node) => checkboxHandler(node),
            onSubmit: () => archiveBatch(startAction, finishAction),
            onClose: closeDialog,
        });
    }, [archiveBatch, closeDialog, navigate, openDialog, onNavigate, checkboxHandler]);

    const unArchiveBatch = useCallback(async (startAction, finishAction) => {
        closeDialog();
        startAction();
        const count = checkedCheckboxRef.current.length;

        await api.archive.moveFromArchive(checkedCheckboxRef.current.map((node) => node.id));
        dispatch(fetchMenuButtons());

        checkedCheckboxRef.current.forEach(() => {
            // treeRef.current.tree.checkNode(node, false);
            // treeRef.current.tree.removeNode(node);

            props.reloadTree();
        });

        setCheckedCheckbox([]);
        finishAction();
        navigate('/archive');

        addSuccess('Выбранные (' + count + ') ресурсы восстановлены из архива');
    }, [dispatch, checkedCheckboxRef, closeDialog, navigate, addSuccess, props]);
    const _onUnArchiveBatch = useCallback((nodes, startAction, finishAction) => {
        const subTitle = 'Вы действительно хотите восстановить из архива нижеперечисленные объекты?';
        const text = nodes.map((node) => {
            return {
                ...node,
                text: (
                    <span
                        style={{ color: '#279BD9' }}
                        onClick={() => {
                            navigate(`archive/${node.id}/articles`);
                            onNavigate(node);
                            closeDialog();
                        }}
                    >
                        {node.name}
                    </span>
                ),
            };
        });

        openDialog({
            title: 'Восстановление',
            subTitle,
            text,
            contentType: 'CHECKBOX_LIST',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onChange: (node) => checkboxHandler(node),
            onSubmit: () => unArchiveBatch(startAction, finishAction),
            onClose: closeDialog,
        });
    }, [unArchiveBatch, closeDialog, navigate, openDialog, onNavigate, checkboxHandler]);

    const deleteBatch = useCallback(async (startAction, finishAction) => {
        closeDialog();
        const count = checkedCheckboxRef.current.length;
        if (count === 0) return;
        startAction();
        await api.project.deleteResources(checkedCheckboxRef.current.filter(node => !!node).map((node) => node.id));

        props.reloadTree();
        // checkedCheckboxRef.current.forEach(node => treeRef.current.tree.removeNode(node));

        setCheckedCheckbox([]);
        finishAction();
        navigate('/archive');
        addSuccess('Выбранные (' + count + ') ресурсы удалены из архива');
    }, [checkedCheckboxRef, closeDialog, navigate, addSuccess, props]);
    const _onDeleteBatch = useCallback((nodes, startAction, finishAction) => {
        const subTitle = 'Вы действительно хотите удалить нижеперечисленные объекты? Этот процесс нельзя будет отменить.';
        const text = nodes.filter(node => !!node).map((node) => {
            return {
                ...node,
                text: (
                    <span
                        style={{ color: '#279BD9' }}
                        onClick={() => {
                            navigate(`archive/${node.id}/articles`);
                            onNavigate(node);
                            closeDialog();
                        }}
                    >
                        {node.name}
                    </span>
                ),
            };
        });

        openDialog({
            title: 'Удаление',
            subTitle,
            text,
            contentType: 'CHECKBOX_LIST',
            color: 'red',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Да, удалить',
            onChange: (node) => checkboxHandler(node),
            onSubmit: () => deleteBatch(startAction, finishAction),
            onClose: closeDialog,
        });
    }, [closeDialog, navigate, openDialog, deleteBatch, onNavigate, checkboxHandler]);

    const prepareNodes = (state) => {
        const nodes = state[NODE_TYPES.PROJECT]
          .concat(state[NODE_TYPES.SECTION] ? state[NODE_TYPES.SECTION] : [])
          .concat(state[NODE_TYPES.ARTICLE] ? state[NODE_TYPES.ARTICLE] : [])
          .concat(state[NODE_TYPES.SCRIPTS] ? state[NODE_TYPES.SCRIPTS] : []);
        setCheckedCheckbox(nodes);

        return nodes;
    };

    useAutoNavigate(props.scrollToNode, treeRef);
    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <div style={{ height: '100%' }}>
            <Confirmation {...dialogState} />

            <NavigationTree
                width={258}
                onNavigate={onNavigate}
                // onEditNode={_onEditNode}
                getEditNodeLink={getEditNodeLink}
                onDeleteNode={_onDeleteNode}
                onArchiveNode={_onArchiveNode}
                onUnArchiveNode={_onUnArchiveNode}
                onArchiveBatch={(state, startAction, finishAction) => {
                    const nodes = prepareNodes(state);
                    _onArchiveBatch(nodes, startAction, finishAction);
                }}
                onUnArchiveBatch={(state, startAction, finishAction) => {
                    const nodes = prepareNodes(state);
                    _onUnArchiveBatch(nodes, startAction, finishAction);
                }}
                onDeleteBatch={(state, startAction, finishAction) => {
                    const nodes = prepareNodes(state);
                    _onDeleteBatch(nodes, startAction, finishAction);
                }}
                showCheckbox={(node) => node.permissions?.canArchive}
                ref={treeRef}
                {...props}
                multipleChoice={true}
                getNodeLink={getNodeLink}
                isReload={props.isReload}
                showUnreadCounters={!props.archive && !props.script}
            />
        </div>
    );
});

TreeComponent.propTypes = {
    onTreeUpdate: PropTypes.func,
    getNodeLink: PropTypes.func,
};

const SideTreeComponent = sidebarLayout(TreeComponent);
export const TreePropContext = React.createContext({
    setTreeProps: () => {},
    setScrollToNode: null,
    treeRef: null,
    setTree: () => {},
    reloadTree: () => {},
    setOnReloadTreeAction: () => {},
});

export const SideTreeWrapper = ({ children, archive = false, script = false, ...props }) => {
    const { platform } = useGlobalContext();
    const treeRef = useRef(null);

    const [treeProps, setTreeProps] = useState({});
    const [scrollToNode, setScrollToNode] = useState(null);

    const [tree, setTree] = useState(null);

    const [onReloadTreeAction, setOnReloadTreeAction] = useState(() => () => {});
    const [isReload, setIsReload] = useState(false);

    const reloadTree = (withAction = true) => {
        setIsReload((prev) => !prev);

        if (withAction && onReloadTreeAction) {
            onReloadTreeAction();
        }
    };

    useCustomMobileMenu(
        useCallback((onClose) => {
            const getNodeLink = (node) => {
                const { getNodeLink } = treeProps;
                if (getNodeLink) {
                    return getNodeLink(node);
                } else {
                    if (archive) {
                        if (!node) {
                            return '/archive';
                        } else if (node.nodeType === NODE_TYPES.PROJECT) {
                            return `/archive/${node.id}/articles`;
                        } else if (node.nodeType === NODE_TYPES.ARTICLE) {
                            return `/archive/article/${node.id}`;
                        } else if (node.nodeType === NODE_TYPES.SECTION) {
                            return UrlUtils.getArchiveSectionUrl(TreeUtils.getTopParentNode(node).id, node.id);
                        }
                    } else if (script) {
                        if (!node) {
                            return '/scripting';
                        } else if (node.nodeType === NODE_TYPES.PROJECT) {
                            return `/scripting/${node.id}`;
                        } else if (node.nodeType === NODE_TYPES.SECTION) {
                            return `/scripting/${TreeUtils.getTopParentNode(node).id}/${node.id}`;
                        } else if (node.nodeType === NODE_TYPES.SCRIPTS) {
                            return `/scripting/script/${node.id}`
                        }
                    } else {
                        if (node.nodeType === NODE_TYPES.PROJECT) {
                            return { link: `/projects/${node.id}/articles`, replace: true };
                        } else if (node.nodeType === NODE_TYPES.ARTICLE) {
                            return `/projects/article/${node.id}`;
                        } else if (node.nodeType === NODE_TYPES.SECTION) {
                            return UrlUtils.getSectionUrl(TreeUtils.getTopParentNode(node).id, node.id);
                        }
                    }
                }
            };

            function tryTree() {
                const tree = treeRef.current?.tree;
                if (tree) {
                    setTree(tree);
                } else {
                    setTimeout(tryTree, 300);
                }
            }
            tryTree();

            return (
                <MobileTree
                    ref={treeRef}
                    archive={archive}
                    scrollToNode={scrollToNode}
                    getNodeLink={getNodeLink}
                    onSelect={onClose}
                    isReload={isReload}
                />
            );
        }, [treeRef, archive, script, scrollToNode, treeProps, setTree, isReload])
    );

    useEffect(() => {
        const path = props['*'].split('section')[0].split('/');

        if (path.length >= 2 && (!scrollToNode || scrollToNode.indexOf(path[path.length - 2]) === -1)) {
            setScrollToNode([path[path.length - 2]]);
        }
    }, [props, scrollToNode]);

    if (platform === 'mobile') {
        return (
            <ContentWrapper>
                <TreePropContext.Provider value={{ setTreeProps, setScrollToNode, setTree, tree, treeRef, reloadTree }}>
                    {children}
                </TreePropContext.Provider>
            </ContentWrapper>
        );
    }

    return (
        <ContentWrapper>
            <TreePropContext.Provider value={{ setTreeProps, setScrollToNode, setTree, tree, treeRef, reloadTree, setOnReloadTreeAction }}>
                <SideTreeComponent
                    {...treeProps}
                    scrollToNode={scrollToNode}
                    setScrollToNode={setScrollToNode}
                    setTree={setTree}
                    archive={archive}
                    script={script}
                    {...props}
                    ref={treeRef}
                    isReload={isReload}
                    reloadTree={reloadTree}
                >
                    {children}
                </SideTreeComponent>
            </TreePropContext.Provider>
        </ContentWrapper>
    );
};
