import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from '@reach/router';
import Confirmation from 'components/confirmation';
import { Empty } from 'components/projects';
import { TableComponent } from 'components/data-components';
import { EditRowToolbar } from 'uikit/table';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import cx from './delayed-publications-page-tab-articles.module.scss';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import formatDateTime from 'lib/helpers/formatDateTime';
import Crumbs from 'components/crumbs';
import { collectArticleBreadCrumbs } from 'lib/helpers';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';

const DelayedPublicationsTabArticles = ({ navigate, onCountChangeHandler }) => {
    const tableRef = useRef(null);
    const { platform } = useGlobalContext();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError } = useMessage();
    const { width } = useWindowSize();

    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [articlesTotal, setArticlesTotal] = useState(0);
    const [articles, setArticles] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestArticles = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '') => {
        setIsLoading(true);

        try {
            const articlesResponse = await api.article.getDelayedArticles({
                page: offset / count,
                size: count,
                sort: sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : '',
                search
            });

            setArticlesTotal(articlesResponse.totalElements);
            setArticles(articlesResponse.content);

            setIsSearch(!!search);
            setIsEmpty(articlesResponse.totalElements === 0);

            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, [])

    const deleteDelayedArticles = useCallback(async (data) => {
        setIsLoading(true);
        closeDialog();

        try {
            if (data.id) {
                await api.article.deleteDelayedArticle(data.id);
            } else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.article.deleteDelayedArticle(checkedCheckboxRef.current[i].id);
                }
            } else {
                setIsLoading(false);
            }

            tableRef?.current?.reload();
            onCountChangeHandler();

            addSuccess(data.id ? 'Отложенная статья удалена' : 'Выбранные отложенные статьи удалены')
        } catch (error) {
            addError('При удалении произошла ошибка')
        } finally {
            setIsLoading(false);
        }
    }, [onCountChangeHandler, closeDialog, addSuccess, addError]);

    const onDeleteDelayedArticles = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        const text = data?.length ?
            data.map(item => {
                return {
                    ...item.original,
                    text: (
                        <>
                            <span onClick={() => navigate(`/user/delayed-article/${item.original.id}/delayed-article/edit`)} style={{ color: '#279BD9', cursor: 'pointer' }}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            }) : (
                <span>
                    Вы действительно хотите удалить статью 
                    <Link to={`/user/delayed-article/${data.id}/delayed-article/edit`}
                          style={{ color: '#279BD9', cursor: 'pointer' }}
                    >
                        {data?.title}
                    </Link>
                </span>
            )
        const subTitle = data?.length ? 'Вы действительно хотите удалить нижеперечисленные объекты из избранного?' : null

        openDialog({
            title: 'Удаление отложенной статьи',
            text,
            subTitle,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (articles) => checkboxHandler(articles),
            onSubmit: () => deleteDelayedArticles(data),
            onClose: closeDialog,
        })
    }, [navigate, closeDialog, openDialog, deleteDelayedArticles, checkboxHandler])

    const collectCrumbs = (article) => {
        const crumbs = collectArticleBreadCrumbs(article.id, article.title, article.parents);
        return crumbs.map(item => {
            return {
                name: item.title,
                linkTo: article.id === item.id ? '' :
                    item.type === 'PROJECT'
                        ? `/projects/${item.id}/articles`
                        : `/projects/${crumbs[0].id}/${item.id}/section/articles`
            };
        })
    }

    const articlesColumns = useMemo(() => {
        return [
            {
                Header: 'Содержание',
                accessor: 'title',
                width: 333,
                maxWidth: 333,
                Cell: (data) => {
                    if (data.isMobile) {
                        return (
                            <div className={cx.mobileTitleCellWrapper}
                                 onClick={() => navigate(`/user/delayed-article/${data.row.original.id}/delayed-article/edit`)}>
                                <p>{data.row.original['title']}</p>
                                <div className={cx.crumbs}>
                                    <Crumbs data={collectCrumbs(data.row.original)} maxWidth={width - 80} />
                                </div>
                                <span>{data.row.original['description']}</span>
                                <div className={cx.logoContainer}>
                                    {data.row.original['logoUuid'] ?
                                        <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)}
                                             alt=""
                                             style={{ height: '100%', borderRadius: '5px', objectFit: 'cover' }} /> :
                                        <img alt="" style={{ width: '100%', height: '100%', borderRadius: '5px', objectFit: 'cover' }} src={'/img/article-card-cover.jpg'}/>}
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <Link to={`/user/delayed-article/${data.row.original.id}/delayed-article/edit`} className={cx.titleCellWrapper}>
                                <div style={{
                                    flexShrink: 0,
                                    marginRight: '12px',
                                    width: '88px',
                                    height: '56px',
                                    background: '#EAEDF3',
                                    borderRadius: '5px'
                                }}>
                                    {data.row.original['logoUuid'] ?
                                        <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)}
                                             alt=""
                                             style={{ width: '100%', height: '100%', borderRadius: '5px', objectFit: 'cover' }} /> :
                                        <img alt="" style={{ width: '100%', height: '100%', borderRadius: '5px', objectFit: 'cover' }} src={'/img/article-card-cover.jpg'}/>}
                                </div>
                                <div>
                                    <MultiClumpTooltip
                                        clamp={2}
                                        className={cx.articleTitle}
                                        label={data.row.original['title']}
                                    />
                                    <div className={cx.crumbs}>
                                        <Crumbs data={collectCrumbs(data.row.original)} maxWidth={width / 5} />
                                    </div>
                                    <MultiClumpTooltip
                                        clamp={2}
                                        className={cx.articleDescription}
                                        label={data.row.original['description']}
                                    />
                                </div>
                            </Link>
                        );
                    }
                }
            },
            {
                Header: 'Дата публикации',
                accessor: 'publicationTime',
                Cell: (data) => {
                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Дата публикации</div>}
                            {formatDateTime(data.row.original['publicationTime'], true)}
                        </div>
                    )
                }
            },
            {
                id: 4,
                Header: '',
                width: 52,
                minWidth: 52,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: function(data) {
                    const toolbar = [];

                    if (width > 1024) {
                        toolbar.push({
                            icon: Icons.EDIT_PEN,
                            tooltip: 'Редактировать',
                            link: `/user/delayed-article/${data.row.original.id}/delayed-article/edit`,
                            // isHidden: original => !(original??.permittedActions ?? []).includes(GLOBAL_ACTIONS.ARTICLE_EDIT)
                        });
                    }

                    toolbar.push({
                        icon: Icons.DELETE,
                        tooltip: 'Удалить отложенную статью',
                        onClick: (data) => onDeleteDelayedArticles(data)
                    });

                    return (
                        <>
                            {EditRowToolbar(toolbar)(data)}
                            <Icon
                                className={cx.mobileDelete}
                                type={Icons.TRASH}
                                width={15}
                                height={15}
                                onClick={() => onDeleteDelayedArticles(data.row.original)}
                            />
                        </>
                    );
                }
            }
        ];
    }, [navigate, onDeleteDelayedArticles, platform, width]);

    const articlesActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: (data) => onDeleteDelayedArticles(data)
        });

        return actions;
    }, [onDeleteDelayedArticles]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <div className={cx.tableWrapper}>
            <Confirmation {...dialogState} />

            {isEmpty && !isSearch && <Empty title="Статьи не найдены"/>}

            {(!isEmpty || isSearch) &&
            <TableComponent
                innerRef={tableRef}
                isLoading={isLoading}
                searchTitle="Поиск по автору и названию"
                columns={articlesColumns}
                actions={articlesActions}
                total={articlesTotal}
                data={articles}
                onPaginate={requestArticles}
                isMobile={platform === 'mobile'}
                defaultSort={{ by: 'createTime', desc: true }}
            />}
        </div>
    );
};

export default DelayedPublicationsTabArticles;
