import { Extension } from '@tiptap/core';

export const TextAlignMark = Extension.create({
    name: 'text-align',

    addOptions() {
        return {
            types: ['paragraph', 'customHeading'],
        };
    },

    addCommands() {
        return {
            setTextAlign:
                alignment =>
                ({ commands }) => {
                    return this.options.types.map(type => commands.updateAttributes(type, { textAlign: alignment })).every(res => res);
                },
            unsetTextAlign:
                () =>
                ({ commands }) => {
                    return this.options.types.map(type => commands.resetAttributes(type, 'textAlign')).every(res => res);
                },
        };
    },
    
    addKeyboardShortcuts() {
        return {
          'Mod-Shift-l': () => this.editor.commands.setTextAlign('left'),
          'Mod-Shift-e': () => this.editor.commands.setTextAlign('center'),
          'Mod-Shift-r': () => this.editor.commands.setTextAlign('right'),
          'Mod-Shift-j': () => this.editor.commands.setTextAlign('justify'),
        }
      },
});
