import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAutoNavigate, useLocationTree } from 'components/trees/utils';
import AutoSizer from 'react-virtualized-auto-sizer';
import cx from './mobile-tree.module.scss';
import InfiniteTree from 'react-infinite-tree';
import MobileTreeNode from 'components/trees/mobile-tree/mobile-tree-node';
import { useCombinedRefs, useDebounceValue } from 'lib/hooks';
import { toggleTreeNodes } from 'lib/helpers';
import Icon, { Icons } from 'uikit/icon';
import api from 'api';
import { getSwitchedLayout } from 'lib/util/language.util';
import classNames from 'classnames';

const MobileTree = ({
    forwardedRef,
    archive,
    onSelect,
    withCheckboxes,
    search,
    scrollToNode,
    fetchTreeFunc,
    allButtonTitle,
    getNodeLink,
    withArticles,
    withSections,
    isReload
}) => {
    const treeRef = useCombinedRefs(forwardedRef);
    const _fetchTreeFunc = fetchTreeFunc ? fetchTreeFunc : archive
        ? api.archive.getUsersDocumentTree : api.project.getUsersDocumentTree;

    const [treeInvalid, setTreeInvalid] = useState(false);
    const [query, setQuery] = useState('');
    const [debouncedValue] = useDebounceValue(query.trim().replace(/\s{2,}/g, " "), 300);

    const { tree } = useLocationTree({
        open: true,
        withArticles: withArticles,
        withSections: withSections,
        archive: archive,
        fetchTreeFunc: _fetchTreeFunc,
        treeInvalid,
        isReload
    });

    const onClick = (node) => onSelect(node);
    useAutoNavigate(scrollToNode, treeRef);

    useEffect(() => {
        setTreeInvalid(true);
    }, [archive, fetchTreeFunc])
    useEffect(() => {
        if (treeRef.current) {
            treeRef.current.tree.loadData(tree);
        }
    }, [tree, treeRef]);
    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(function(node) {
              const name = node.name.toLowerCase();
              const value = debouncedValue.toLowerCase().trim();
                return name.includes(value) || name.includes(getSwitchedLayout(value));
            });
            toggleTreeNodes(treeRef.current, debouncedValue.trim().length === 0);
        }
    }, [debouncedValue, treeRef]);

    return (
        <div className={cx.container} data-testid="mobileTreeContainer">
            {search &&
            <div className={cx.search}>
                <Icon className={cx.searchIcon} type={Icons.SEARCH} width={13} height={13}/>
                <input
                    type="text"
                    placeholder="Поиск по дереву..."
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    data-testid="mobileTreeSearch"
                />
            </div>}
            {allButtonTitle && !query &&
            <div className={classNames(cx.allButton, !treeRef?.current?.tree?.state?.selectedNode && cx.allButtonActive)}
                 onClick={() => onClick(null)} data-testid="mobileTreeAllBtn">
                <Icon className={cx.allButtonIcon} type={Icons.LIST} width={13} height={13}/>
                <span>{allButtonTitle}</span>
            </div>}
            <div className={cx.treeContainer} data-testid="mobileTree">
                <AutoSizer>
                    {({ height }) => {
                        return (
                            <InfiniteTree ref={treeRef} tabIndex={0} autoOpen={false} width='100%' height={height} rowHeight={48} data={tree}>
                                {({ node, tree }) => {
                                    const hasChildren = node.hasChildren();
                                    let toggleState = '';

                                    if ((!hasChildren && node.loadOnDemand) || (hasChildren && !node.state.open)) {
                                        toggleState = 'closed';
                                    }

                                    if (hasChildren && node.state.open) {
                                        toggleState = 'opened';
                                    }

                                    return (
                                        <MobileTreeNode
                                            node={node}
                                            tree={tree}
                                            toggleState={toggleState}
                                            archive={archive}
                                            onClick={onClick}
                                            withCheckboxes={withCheckboxes}
                                            getNodeLink={getNodeLink}
                                            highlight={[debouncedValue, getSwitchedLayout(debouncedValue)]}
                                        />
                                    );
                                }}
                            </InfiniteTree>
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    )
}

MobileTree.propTypes = {
    archive: PropTypes.bool,
    onSelect: PropTypes.func,
    withCheckboxes: PropTypes.bool,
    search: PropTypes.bool,
    setScrollToNode: PropTypes.func,
    scrollToNode: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fetchTreeFunc: PropTypes.func,
    allButtonTitle: PropTypes.string,
    getNodeLink: PropTypes.func,
    withArticles: PropTypes.bool,
    withSections: PropTypes.bool,
    isReload: PropTypes.bool,
}

MobileTree.defaultProps = {
    archive: false,
    withCheckboxes: false,
    search: true,
    onSelect: () => {},
    getNodeLink: () => {},
    withArticles: true,
    withSections: true,
    isReload: false,
}

export default React.forwardRef((props, ref) => <MobileTree {...props} forwardedRef={ref}/>);
