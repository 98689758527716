import { mergeAttributes, Node } from '@tiptap/core';

export const TableCol = Node.create({
    name: 'tableCol',

    addOptions() {
        return {
            HTMLAttributes: {},
        };
    },

    addAttributes() {
        return {
            width: {
                default: null,
            },
            span: {
                default: null,
            },
        };
    },

    content: 'text*',

    tableRole: 'table_col',

    isolating: true,

    parseHTML() {
        return [{ tag: 'col' }];
    },

    renderHTML({ HTMLAttributes }) {
        return ['col', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});
