import React from 'react';
import PropTypes from 'prop-types';
import cs from './close-button.module.scss';

export const CloseButton = ({ style, onClick }) => {
    return <span className={cs.close} style={{ ...style }} onClick={onClick}/>;
};

CloseButton.defaultProps = {
    style: {}
};

CloseButton.propTypes = {
    style: PropTypes.object,
    onClick: PropTypes.func.isRequired
};