import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMessage } from 'lib/hooks/useMessage';
import * as validators from 'lib/util/validators.util';
import { SectionFormModel } from 'model/section/section-form-model';
import { selectParent } from 'slice/treeSlice';
import api from 'api';

export function useSectionFormModel(sectionId) {
    const { addError } = useMessage();
    const parent = useSelector(selectParent);

    const [loading, setLoading] = useState(false);
    const [permissionsOk, setPermissionsOk] = useState(true);

    const [section, setSection] = useState(new SectionFormModel());

    useEffect(() => {
        if (section.getVisualPath().length === 0 && parent.visualPath) {
            section.meta.visualPath = parent.visualPath;
            section.parentProjectId = parent.parentProjectId;

            section.parentSectionId = parent.parentSectionId;
            section.commit();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parent]);

    useEffect(() => {
        if (sectionId) {
            api.section.getSection(sectionId).then(data => {
                setSection(new SectionFormModel(data));
            }).catch(e => {
                if (/has no permissions to read the section with id/.test(e.errorData.detail)) {
                    setPermissionsOk(false);
                } else {
                    throw e;
                }
            });
        }
    }, [sectionId]);

    function commit() {
        const copy = this.copy();
        copy.commit = commit;

        setSection(copy);
    }

    section.commit = commit;

    function validateFields(fields) {
        if (!fields) {
            fields = ['sectionTitle', 'location'];
        }

        let errors = {};

        if (fields.includes('sectionTitle')) {
            errors['sectionTitle'] = validators.textValidator(section.sectionTitle);
        }

        if (fields.includes('location')) {
            errors['location'] = section.parentProjectId ? null : 'Нужно указать местоположение для раздела';
        }

        section.errors = {
            ...section.errors,
            ...errors
        };

        section.commit();
        return Object.values(errors).every(v => v === null);
    }

    section.validateFields = validateFields;

    function scrollToError() {
        const errorField = document.querySelector('.field-invalid');

        if (!errorField) {
            return;
        }

        const wrapper = errorField.closest('.js-scroll-wrapper');
        const fieldPosition = errorField.getBoundingClientRect();

        if (fieldPosition.top <= 0) {
            wrapper.scrollTop = wrapper.scrollTop + fieldPosition.top - fieldPosition.height * 3;
        } else {
            wrapper.scrollTop = fieldPosition.top + fieldPosition.height * 4;
        }
    }

    section.scrollToError = scrollToError;

    async function validateTitleExists() {
        try {
            let err;

            if (section.parentProjectId) {
                err = (await api.section.validateTitle(section.parentProjectId, section.parentSectionId, section.id, section.sectionTitle))
                    ? null : 'Раздел с таким названием уже существует';
            } else {
                err = null;
            }

            section.errors = {
                ...section.errors,
                sectionTitleExists: err
            };

            section.commit();
            return !!err;
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            return true;
        }
    }

    section.validateTitleExists = validateTitleExists;

    function clearSection() {
        const newSection = new SectionFormModel();
        newSection.commit = commit;

        setSection(newSection);
    }

    return { section, setSection, clearSection, loading, setLoading, permissionsOk };
}
