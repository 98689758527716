import React from 'react';
import { GlobalContext } from 'lib/context';

export default function useGlobalContext() {
    const globalContext = React.useContext(GlobalContext);

    const isAuthorizedRole = React.useCallback((declaredAuthorities = []) => {
        if (declaredAuthorities.length === 0) {
            return true;
        }

        const havingAuthorities = Object.getOwnPropertyNames(globalContext?.userRolesInfo ?? {}).filter(a => globalContext.userRolesInfo[a] === true);
        return declaredAuthorities.some(auth => havingAuthorities.includes(auth));
    }, [globalContext]);

    return {
        userRolesInfo: globalContext.userRolesInfo,
        setUserRolesInfo: globalContext.setUserRolesInfo,
        sessionHasBeenFetched: globalContext.sessionHasBeenFetched,
        isAuthorizedRole: isAuthorizedRole,
        avatar: globalContext.avatar,
        setAvatar: globalContext.setAvatar,
        platform: globalContext.platform,
        visitedUrls: globalContext.visitedUrls,
        setVisitedUrls: globalContext.setVisitedUrls,
        formDataChanged: globalContext.formDataChanged,
        setFormDataChanged: globalContext.setFormDataChanged,
        locationUrl: globalContext.locationUrl,
        setLocationUrl: globalContext.setLocationUrl,
        prevLocationUrl: globalContext.prevLocationUrl,
        setPrevLocationUrl: globalContext.setPrevLocationUrl,
        gotScriptProgress: globalContext.gotScriptProgress,
        setGotScriptProgress: globalContext.setGotScriptProgress,
        formDataChangedSubmitHandler: globalContext.formDataChangedSubmitHandler,
        setFormDataChangedSubmitHandler: globalContext.setFormDataChangedSubmitHandler,
    };
}
