import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import Modal from 'react-modal';
import { useGlobalContext } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Checkbox from 'uikit/checkbox/checkbox';
import Button from 'uikit/button';
import Loading from 'uikit/loading';
import api from 'api';
import cs from './parsing-form.module.scss';

const desktopStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
    },
};
const mobileStyles = {
    content: {
        zIndex: 8000,
        padding: 0,
        background: '#FFFFFF',
        border: '1px solid #EAEDF3',
        boxSizing: 'border-box',
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
        borderRadius: 5,
        margin: '50px 16px',
        position: 'initial',
        inset: 0,
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(38, 38, 38, 0.65)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'auto',
    },
};

// TODO refactoring along with Confirmation, SubscribeUpdates and SendEmail
const ParsingForm = ({ isOpen, onDismiss, onOk }) => {
    const { platform } = useGlobalContext();

    const [loading, setLoading] = useState(false);
    const [resizeValue, setResizeValue] = useState(100);

    const [isClearAll, setIsClearAll] = useState(true);
    const [isError, setIsError] = useState(false);

    const onChange = async (files) => {
        setLoading(true);
        setIsError(false);

        const formData = new FormData();

        formData.append('file', files[0]);
        formData.append('clearAll', isClearAll);
        formData.append('zoom', resizeValue);

        const parserResponse = await api.parser.convertToHtml(formData);

        if (parserResponse.status === 'SUCCESS') {
            onOk(parserResponse.html, isClearAll);
            setLoading(false);
        } else if (parserResponse.status === 'FAILED') {
            setLoading(false);
            setIsError(true);
        } else if (parserResponse.status === 'IN_PROGRESS') {
            const interval = setInterval(async () => {
                const parserStatusResponse = await api.parser.getConvertStatus(parserResponse.taskId);

                if (parserStatusResponse.status === 'IN_PROGRESS') {
                    return;
                }

                if (parserStatusResponse.status === 'SUCCESS') {
                    onOk(parserStatusResponse.html, isClearAll);
                } else if (parserStatusResponse.status === 'FAILED') {
                    setIsError(true);
                }

                setLoading(false);
                clearInterval(interval);
            }, 3000);
        }
    };

    const decreaseSize = () => {
        if (resizeValue <= 50) {
            return;
        }

        setResizeValue(prevState => prevState - 25);
    };
    const increaseSize = () => {
        if (resizeValue >= 200) {
            return;
        }

        setResizeValue(prevState => prevState + 25);
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onDismiss} contentLabel="Импорт данных"
               style={platform === 'mobile' ? mobileStyles : desktopStyles}>
            {loading && <Loading withOverlay={false} withRelativeOverlay={true}/>}
            <div className={classNames(cs.modal, { [cs.mobile]: platform === 'mobile' })}>
                <div className={cs.head}>
                    <div className={cs.text}>Импорт данных</div>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>} onClick={onDismiss}/>
                </div>
                <div className={cs.content}>
                    <div>
                        <Checkbox label="Удалить оригинальное форматирование документа" checked={isClearAll}
                                  onChange={(e) => setIsClearAll(e.target.checked)}/>
                    </div>
                    <div className={cs.resize}>
                        <div className={cs.resizeLabel}>Масштаб:</div>
                        <div className={cs.resizeControls}>
                            <button className={classNames(cs.resizeButton, { [cs.disabled]: resizeValue <= 50 })}
                                    onClick={decreaseSize}>
                                <Icon type={Icons.MINUS} width={20} height={20} />
                            </button>
                            <div className={cs.resizeValue}>{resizeValue}%</div>
                            <button className={classNames(cs.resizeButton, { [cs.disabled]: resizeValue >= 200 })}
                                    onClick={increaseSize}>
                                <Icon type={Icons.PLUS_ADD} width={20} height={20} />
                            </button>
                        </div>
                    </div>
                    <div className={cs.divider} />
                    <Dropzone onDrop={onChange}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: cs.dropZone })}>
                                <input {...getInputProps()} />
                                <Icon type={Icons.DOWNLOAD} width={24} height={24} />
                                <p>Перетащите файл в эту область или нажмите для выбора файла с компьютера</p>
                            </div>
                        )}
                    </Dropzone>
                    <div className={cs.divider}/>
                    <div className={cs.text}>
                        После импорта данных, информация автоматически заполнит текст создаваемой статьи или новости.
                    </div>
                    <div className={cs.text}>Поддерживаемые форматы: DOC, XLS, RTF, PDF, CSV, TXT</div>
                    {isError && <p className={cs.error}>Ошибка импорта. Документ не может быть импортирован.</p>}
                </div>
                <div className={cs.footer}>
                    <Button onClick={onDismiss} label="Отмена" fullWidth={platform === 'mobile'} />
                </div>
            </div>
        </Modal>
    );
};

export default ParsingForm;
