export const emojies = [
  { n: 'grinning', c: 'people', e: '😀', d: 'grinning face', u: '6.1' },
  { n: 'smiley', c: 'people', e: '😃', d: 'smiling face with open mouth', u: '6.0' },
  { n: 'smile', c: 'people', e: '😄', d: 'smiling face with open mouth and smiling eyes', u: '6.0' },
  { n: 'grin', c: 'people', e: '😁', d: 'grinning face with smiling eyes', u: '6.0' },
  { n: 'laughing', c: 'people', e: '😆', d: 'smiling face with open mouth and tightly-closed ey', u: '6.0' },
  { n: 'sweat_smile', c: 'people', e: '😅', d: 'smiling face with open mouth and cold sweat', u: '6.0' },
  { n: 'rofl', c: 'people', e: '🤣', d: 'rolling on the floor laughing', u: '9.0' },
  { n: 'joy', c: 'people', e: '😂', d: 'face with tears of joy', u: '6.0' },
  { n: 'slight_smile', c: 'people', e: '🙂', d: 'slightly smiling face', u: '7.0' },
  { n: 'upside_down', c: 'people', e: '🙃', d: 'upside-down face', u: '8.0' },
  { n: 'wink', c: 'people', e: '😉', d: 'winking face', u: '6.0' },
  { n: 'blush', c: 'people', e: '😊', d: 'smiling face with smiling eyes', u: '6.0' },
  { n: 'innocent', c: 'people', e: '😇', d: 'smiling face with halo', u: '6.0' },
  { n: 'heart_eyes', c: 'people', e: '😍', d: 'smiling face with heart-shaped eyes', u: '6.0' },
  { n: 'kissing_heart', c: 'people', e: '😘', d: 'face throwing a kiss', u: '6.0' },
  { n: 'kissing', c: 'people', e: '😗', d: 'kissing face', u: '6.1' },
  { n: 'kissing_closed_eyes', c: 'people', e: '😚', d: 'kissing face with closed eyes', u: '6.0' },
  { n: 'kissing_smiling_eyes', c: 'people', e: '😙', d: 'kissing face with smiling eyes', u: '6.1' },
  { n: 'yum', c: 'people', e: '😋', d: 'face savouring delicious food', u: '6.0' },
  { n: 'stuck_out_tongue', c: 'people', e: '😛', d: 'face with stuck-out tongue', u: '6.1' },
  { n: 'stuck_out_tongue_winking_eye', c: 'people', e: '😜', d: 'face with stuck-out tongue and winking eye', u: '6.0' },
  { n: 'stuck_out_tongue_closed_eyes', c: 'people', e: '😝', d: 'face with stuck-out tongue and tightly-closed eyes', u: '6.0' },
  { n: 'money_mouth', c: 'people', e: '🤑', d: 'money-mouth face', u: '8.0' },
  { n: 'hugging', c: 'people', e: '🤗', d: 'hugging face', u: '8.0' },
  { n: 'thinking', c: 'people', e: '🤔', d: 'thinking face', u: '8.0' },
  { n: 'zipper_mouth', c: 'people', e: '🤐', d: 'zipper-mouth face', u: '8.0' },
  { n: 'neutral_face', c: 'people', e: '😐', d: 'neutral face', u: '6.0' },
  { n: 'expressionless', c: 'people', e: '😑', d: 'expressionless face', u: '6.1' },
  { n: 'no_mouth', c: 'people', e: '😶', d: 'face without mouth', u: '6.0' },
  { n: 'smirk', c: 'people', e: '😏', d: 'smirking face', u: '6.0' },
  { n: 'unamused', c: 'people', e: '😒', d: 'unamused face', u: '6.0' },
  { n: 'rolling_eyes', c: 'people', e: '🙄', d: 'face with rolling eyes', u: '8.0' },
  { n: 'grimacing', c: 'people', e: '😬', d: 'grimacing face', u: '6.1' },
  { n: 'lying_face', c: 'people', e: '🤥', d: 'lying face', u: '9.0' },
  { n: 'relieved', c: 'people', e: '😌', d: 'relieved face', u: '6.0' },
  { n: 'pensive', c: 'people', e: '😔', d: 'pensive face', u: '6.0' },
  { n: 'sleepy', c: 'people', e: '😪', d: 'sleepy face', u: '6.0' },
  { n: 'drooling_face', c: 'people', e: '🤤', d: 'drooling face', u: '9.0' },
  { n: 'sleeping', c: 'people', e: '😴', d: 'sleeping face', u: '6.1' },
  { n: 'mask', c: 'people', e: '😷', d: 'face with medical mask', u: '6.0' },
  { n: 'thermometer_face', c: 'people', e: '🤒', d: 'face with thermometer', u: '8.0' },
  { n: 'head_bandage', c: 'people', e: '🤕', d: 'face with head-bandage', u: '8.0' },
  { n: 'nauseated_face', c: 'people', e: '🤢', d: 'nauseated face', u: '9.0' },
  { n: 'sneezing_face', c: 'people', e: '🤧', d: 'sneezing face', u: '9.0' },
  { n: 'dizzy_face', c: 'people', e: '😵', d: 'dizzy face', u: '6.0' },
  { n: 'cowboy', c: 'people', e: '🤠', d: 'face with cowboy hat', u: '9.0' },
  { n: 'sunglasses', c: 'people', e: '😎', d: 'smiling face with sunglasses', u: '6.0' },
  { n: 'nerd', c: 'people', e: '🤓', d: 'nerd face', u: '8.0' },
  { n: 'confused', c: 'people', e: '😕', d: 'confused face', u: '6.1' },
  { n: 'worried', c: 'people', e: '😟', d: 'worried face', u: '6.1' },
  { n: 'slight_frown', c: 'people', e: '🙁', d: 'slightly frowning face', u: '7.0' },
  { n: 'open_mouth', c: 'people', e: '😮', d: 'face with open mouth', u: '6.1' },
  { n: 'hushed', c: 'people', e: '😯', d: 'hushed face', u: '6.1' },
  { n: 'astonished', c: 'people', e: '😲', d: 'astonished face', u: '6.0' },
  { n: 'flushed', c: 'people', e: '😳', d: 'flushed face', u: '6.0' },
  { n: 'frowning', c: 'people', e: '😦', d: 'frowning face with open mouth', u: '6.1' },
  { n: 'anguished', c: 'people', e: '😧', d: 'anguished face', u: '6.1' },
  { n: 'fearful', c: 'people', e: '😨', d: 'fearful face', u: '6.0' },
  { n: 'cold_sweat', c: 'people', e: '😰', d: 'face with open mouth and cold sweat', u: '6.0' },
  { n: 'disappointed_relieved', c: 'people', e: '😥', d: 'disappointed but relieved face', u: '6.0' },
  { n: 'cry', c: 'people', e: '😢', d: 'crying face', u: '6.0' },
  { n: 'sob', c: 'people', e: '😭', d: 'loudly crying face', u: '6.0' },
  { n: 'scream', c: 'people', e: '😱', d: 'face screaming in fear', u: '6.0' },
  { n: 'confounded', c: 'people', e: '😖', d: 'confounded face', u: '6.0' },
  { n: 'persevere', c: 'people', e: '😣', d: 'persevering face', u: '6.0' },
  { n: 'disappointed', c: 'people', e: '😞', d: 'disappointed face', u: '6.0' },
  { n: 'sweat', c: 'people', e: '😓', d: 'face with cold sweat', u: '6.0' },
  { n: 'weary', c: 'people', e: '😩', d: 'weary face', u: '6.0' },
  { n: 'tired_face', c: 'people', e: '😫', d: 'tired face', u: '6.0' },
  { n: 'triumph', c: 'people', e: '😤', d: 'face with look of triumph', u: '6.0' },
  { n: 'rage', c: 'people', e: '😡', d: 'pouting face', u: '6.0' },
  { n: 'angry', c: 'people', e: '😠', d: 'angry face', u: '6.0' },
  { n: 'smiling_imp', c: 'people', e: '😈', d: 'smiling face with horns', u: '6.0' },
  { n: 'imp', c: 'people', e: '👿', d: 'imp', u: '6.0' },
  { n: 'skull', c: 'people', e: '💀', d: 'skull', u: '6.0' },
  { n: 'skull_crossbones', c: 'objects', e: '☠', d: 'skull and crossbones', u: '1.1' },
  { n: 'poop', c: 'people', e: '💩', d: 'pile of poo', u: '6.0' },
  { n: 'clown', c: 'people', e: '🤡', d: 'clown face', u: '9.0' },
  { n: 'japanese_ogre', c: 'people', e: '👹', d: 'japanese ogre', u: '6.0' },
  { n: 'japanese_goblin', c: 'people', e: '👺', d: 'japanese goblin', u: '6.0' },
  { n: 'ghost', c: 'people', e: '👻', d: 'ghost', u: '6.0' },
  { n: 'alien', c: 'people', e: '👽', d: 'extraterrestrial alien', u: '6.0' },
  { n: 'space_invader', c: 'activity', e: '👾', d: 'alien monster', u: '6.0' },
  { n: 'robot', c: 'people', e: '🤖', d: 'robot face', u: '8.0' },
  { n: 'smiley_cat', c: 'people', e: '😺', d: 'smiling cat face with open mouth', u: '6.0' },
  { n: 'smile_cat', c: 'people', e: '😸', d: 'grinning cat face with smiling eyes', u: '6.0' },
  { n: 'joy_cat', c: 'people', e: '😹', d: 'cat face with tears of joy', u: '6.0' },
  { n: 'heart_eyes_cat', c: 'people', e: '😻', d: 'smiling cat face with heart-shaped eyes', u: '6.0' },
  { n: 'smirk_cat', c: 'people', e: '😼', d: 'cat face with wry smile', u: '6.0' },
  { n: 'kissing_cat', c: 'people', e: '😽', d: 'kissing cat face with closed eyes', u: '6.0' },
  { n: 'scream_cat', c: 'people', e: '🙀', d: 'weary cat face', u: '6.0' },
  { n: 'crying_cat_face', c: 'people', e: '😿', d: 'crying cat face', u: '6.0' },
  { n: 'pouting_cat', c: 'people', e: '😾', d: 'pouting cat face', u: '6.0' },
  { n: 'see_no_evil', c: 'nature', e: '🙈', d: 'see-no-evil monkey', u: '6.0' },
  { n: 'hear_no_evil', c: 'nature', e: '🙉', d: 'hear-no-evil monkey', u: '6.0' },
  { n: 'speak_no_evil', c: 'nature', e: '🙊', d: 'speak-no-evil monkey', u: '6.0' },
  { n: 'love_letter', c: 'objects', e: '💌', d: 'love letter', u: '6.0' },
  { n: 'cupid', c: 'symbols', e: '💘', d: 'heart with arrow', u: '6.0' },
  { n: 'gift_heart', c: 'symbols', e: '💝', d: 'heart with ribbon', u: '6.0' },
  { n: 'sparkling_heart', c: 'symbols', e: '💖', d: 'sparkling heart', u: '6.0' },
  { n: 'heartpulse', c: 'symbols', e: '💗', d: 'growing heart', u: '6.0' },
  { n: 'heartbeat', c: 'symbols', e: '💓', d: 'beating heart', u: '6.0' },
  { n: 'revolving_hearts', c: 'symbols', e: '💞', d: 'revolving hearts', u: '6.0' },
  { n: 'two_hearts', c: 'symbols', e: '💕', d: 'two hearts', u: '6.0' },
  { n: 'heart_decoration', c: 'symbols', e: '💟', d: 'heart decoration', u: '6.0' },
  { n: 'heart_exclamation', c: 'symbols', e: '❣', d: 'heavy heart exclamation mark ornament', u: '1.1' },
  { n: 'broken_heart', c: 'symbols', e: '💔', d: 'broken heart', u: '6.0' },
  { n: 'heart', c: 'symbols', e: '❤', d: 'heavy black heart', u: '1.1' },
  { n: 'yellow_heart', c: 'symbols', e: '💛', d: 'yellow heart', u: '6.0' },
  { n: 'green_heart', c: 'symbols', e: '💚', d: 'green heart', u: '6.0' },
  { n: 'blue_heart', c: 'symbols', e: '💙', d: 'blue heart', u: '6.0' },
  { n: 'purple_heart', c: 'symbols', e: '💜', d: 'purple heart', u: '6.0' },
  { n: 'black_heart', c: 'symbols', e: '🖤', d: 'black heart', u: '9.0' },
  { n: 'kiss', c: 'people', e: '💋', d: 'kiss mark', u: '6.0' },
  { n: '100', c: 'symbols', e: '💯', d: 'hundred points symbol', u: '6.0' },
  { n: 'anger', c: 'symbols', e: '💢', d: 'anger symbol', u: '6.0' },
  { n: 'boom', c: 'nature', e: '💥', d: 'collision symbol', u: '6.0' },
  { n: 'dizzy', c: 'nature', e: '💫', d: 'dizzy symbol', u: '6.0' },
  { n: 'sweat_drops', c: 'nature', e: '💦', d: 'splashing sweat symbol', u: '6.0' },
  { n: 'dash', c: 'nature', e: '💨', d: 'dash symbol', u: '6.0' },
  { n: 'hole', c: 'objects', e: '🕳', d: 'hole', u: '7.0' },
  { n: 'speech_balloon', c: 'symbols', e: '💬', d: 'speech balloon', u: '6.0' },
  { n: 'eye_in_speech_bubble', c: 'symbols', e: '👁‍🗨', d: 'eye in speech bubble', u: '7.0' },
  { n: 'speech_left', c: 'symbols', e: '🗨', d: 'left speech bubble', u: '7.0' },
  { n: 'anger_right', c: 'symbols', e: '🗯', d: 'right anger bubble', u: '7.0' },
  { n: 'thought_balloon', c: 'symbols', e: '💭', d: 'thought balloon', u: '6.0' },
  { n: 'zzz', c: 'people', e: '💤', d: 'sleeping symbol', u: '6.0' },
  { n: 'wave', c: 'people', e: '👋', d: 'waving hand sign', u: '6.0' },
  { n: 'raised_back_of_hand', c: 'people', e: '🤚', d: 'raised back of hand', u: '9.0' },
  { n: 'hand_splayed', c: 'people', e: '🖐', d: 'raised hand with fingers splayed', u: '7.0' },
  { n: 'raised_hand', c: 'people', e: '✋', d: 'raised hand', u: '6.0' },
  { n: 'vulcan', c: 'people', e: '🖖', d: 'raised hand with part between middle and ring fingers', u: '7.0' },
  { n: 'ok_hand', c: 'people', e: '👌', d: 'ok hand sign', u: '6.0' },
  { n: 'v', c: 'people', e: '✌', d: 'victory hand', u: '1.1' },
  { n: 'fingers_crossed', c: 'people', e: '🤞', d: 'hand with first and index finger crossed', u: '9.0' },
  { n: 'metal', c: 'people', e: '🤘', d: 'sign of the horns', u: '8.0' },
  { n: 'call_me', c: 'people', e: '🤙', d: 'call me hand', u: '9.0' },
  { n: 'point_left', c: 'people', e: '👈', d: 'white left pointing backhand index', u: '6.0' },
  { n: 'point_right', c: 'people', e: '👉', d: 'white right pointing backhand index', u: '6.0' },
  { n: 'point_up_2', c: 'people', e: '👆', d: 'white up pointing backhand index', u: '6.0' },
  { n: 'middle_finger', c: 'people', e: '🖕', d: 'reversed hand with middle finger extended', u: '7.0' },
  { n: 'point_down', c: 'people', e: '👇', d: 'white down pointing backhand index', u: '6.0' },
  { n: 'point_up', c: 'people', e: '☝', d: 'white up pointing index', u: '1.1' },
  { n: 'thumbsup', c: 'people', e: '👍', d: 'thumbs up sign', u: '6.0' },
  { n: 'thumbsdown', c: 'people', e: '👎', d: 'thumbs down sign', u: '6.0' },
  { n: 'fist', c: 'people', e: '✊', d: 'raised fist', u: '6.0' },
  { n: 'punch', c: 'people', e: '👊', d: 'fisted hand sign', u: '6.0' },
  { n: 'left_facing_fist', c: 'people', e: '🤛', d: 'left-facing fist', u: '9.0' },
  { n: 'right_facing_fist', c: 'people', e: '🤜', d: 'right-facing fist', u: '9.0' },
  { n: 'clap', c: 'people', e: '👏', d: 'clapping hands sign', u: '6.0' },
  { n: 'raised_hands', c: 'people', e: '🙌', d: 'person raising both hands in celebration', u: '6.0' },
  { n: 'open_hands', c: 'people', e: '👐', d: 'open hands sign', u: '6.0' },
  { n: 'handshake', c: 'people', e: '🤝', d: 'handshake', u: '9.0' },
  { n: 'pray', c: 'people', e: '🙏', d: 'person with folded hands', u: '6.0' },
  { n: 'writing_hand', c: 'people', e: '✍', d: 'writing hand', u: '1.1' },
  { n: 'nail_care', c: 'people', e: '💅', d: 'nail polish', u: '6.0' },
  { n: 'selfie', c: 'people', e: '🤳', d: 'selfie', u: '9.0' },
  { n: 'muscle', c: 'people', e: '💪', d: 'flexed biceps', u: '6.0' },
  { n: 'ear', c: 'people', e: '👂', d: 'ear', u: '6.0' },
  { n: 'nose', c: 'people', e: '👃', d: 'nose', u: '6.0' },
  { n: 'eyes', c: 'people', e: '👀', d: 'eyes', u: '6.0' },
  { n: 'eye', c: 'people', e: '👁', d: 'eye', u: '7.0' },
  { n: 'tongue', c: 'people', e: '👅', d: 'tongue', u: '6.0' },
  { n: 'lips', c: 'people', e: '👄', d: 'mouth', u: '6.0' },
  { n: 'baby', c: 'people', e: '👶', d: 'baby', u: '6.0' },
  { n: 'boy', c: 'people', e: '👦', d: 'boy', u: '6.0' },
  { n: 'girl', c: 'people', e: '👧', d: 'girl', u: '6.0' },
  { n: 'person_with_blond_hair', c: 'people', e: '👱', d: 'person with blond hair', u: '6.0' },
  { n: 'man', c: 'people', e: '👨', d: 'man', u: '6.0' },
  { n: 'woman', c: 'people', e: '👩', d: 'woman', u: '6.0' },
  { n: 'older_man', c: 'people', e: '👴', d: 'older man', u: '6.0' },
  { n: 'older_woman', c: 'people', e: '👵', d: 'older woman', u: '6.0' },
  { n: 'person_frowning', c: 'people', e: '🙍', d: 'person frowning', u: '6.0' },
  { n: 'person_with_pouting_face', c: 'people', e: '🙎', d: 'person with pouting face', u: '6.0' },
  { n: 'no_good', c: 'people', e: '🙅', d: 'face with no good gesture', u: '6.0' },
  { n: 'ok_woman', c: 'people', e: '🙆', d: 'face with ok gesture', u: '6.0' },
  { n: 'information_desk_person', c: 'people', e: '💁', d: 'information desk person', u: '6.0' },
  { n: 'raising_hand', c: 'people', e: '🙋', d: 'happy person raising one hand', u: '6.0' },
  { n: 'bow', c: 'people', e: '🙇', d: 'person bowing deeply', u: '6.0' },
  { n: 'face_palm', c: 'people', e: '🤦', d: 'face palm', u: '9.0' },
  { n: 'shrug', c: 'people', e: '🤷', d: 'shrug', u: '9.0' },
  { n: 'cop', c: 'people', e: '👮', d: 'police officer', u: '6.0' },
  { n: 'spy', c: 'people', e: '🕵', d: 'sleuth or spy', u: '7.0' },
  { n: 'guardsman', c: 'people', e: '💂', d: 'guardsman', u: '6.0' },
  { n: 'construction_worker', c: 'people', e: '👷', d: 'construction worker', u: '6.0' },
  { n: 'prince', c: 'people', e: '🤴', d: 'prince', u: '9.0' },
  { n: 'princess', c: 'people', e: '👸', d: 'princess', u: '6.0' },
  { n: 'man_with_turban', c: 'people', e: '👳', d: 'man with turban', u: '6.0' },
  { n: 'man_with_gua_pi_mao', c: 'people', e: '👲', d: 'man with gua pi mao', u: '6.0' },
  { n: 'man_in_tuxedo', c: 'people', e: '🤵', d: 'man in tuxedo', u: '9.0' },
  { n: 'bride_with_veil', c: 'people', e: '👰', d: 'bride with veil', u: '6.0' },
  { n: 'pregnant_woman', c: 'people', e: '🤰', d: 'pregnant woman', u: '9.0' },
  { n: 'angel', c: 'people', e: '👼', d: 'baby angel', u: '6.0' },
  { n: 'santa', c: 'people', e: '🎅', d: 'father christmas', u: '6.0' },
  { n: 'mrs_claus', c: 'people', e: '🤶', d: 'mother christmas', u: '9.0' },
  { n: 'massage', c: 'people', e: '💆', d: 'face massage', u: '6.0' },
  { n: 'haircut', c: 'people', e: '💇', d: 'haircut', u: '6.0' },
  { n: 'walking', c: 'people', e: '🚶', d: 'pedestrian', u: '6.0' },
  { n: 'runner', c: 'people', e: '🏃', d: 'runner', u: '6.0' },
  { n: 'dancer', c: 'people', e: '💃', d: 'dancer', u: '6.0' },
  { n: 'man_dancing', c: 'people', e: '🕺', d: 'man dancing', u: '9.0' },
  { n: 'levitate', c: 'activity', e: '🕴', d: 'man in business suit levitating', u: '7.0' },
  { n: 'dancers', c: 'people', e: '👯', d: 'woman with bunny ears', u: '6.0' },
  { n: 'fencer', c: 'activity', e: '🤺', d: 'fencer', u: '9.0' },
  { n: 'horse_racing', c: 'activity', e: '🏇', d: 'horse racing', u: '6.0' },
  { n: 'skier', c: 'activity', e: '⛷', d: 'skier', u: '5.2' },
  { n: 'snowboarder', c: 'activity', e: '🏂', d: 'snowboarder', u: '6.0' },
  { n: 'golfer', c: 'activity', e: '🏌', d: 'golfer', u: '7.0' },
  { n: 'surfer', c: 'activity', e: '🏄', d: 'surfer', u: '6.0' },
  { n: 'rowboat', c: 'activity', e: '🚣', d: 'rowboat', u: '6.0' },
  { n: 'swimmer', c: 'activity', e: '🏊', d: 'swimmer', u: '6.0' },
  { n: 'basketball_player', c: 'activity', e: '⛹', d: 'person with ball', u: '5.2' },
  { n: 'lifter', c: 'activity', e: '🏋', d: 'weight lifter', u: '7.0' },
  { n: 'bicyclist', c: 'activity', e: '🚴', d: 'bicyclist', u: '6.0' },
  { n: 'mountain_bicyclist', c: 'activity', e: '🚵', d: 'mountain bicyclist', u: '6.0' },
  { n: 'cartwheel', c: 'activity', e: '🤸', d: 'person doing cartwheel', u: '9.0' },
  { n: 'wrestlers', c: 'activity', e: '🤼', d: 'wrestlers', u: '9.0' },
  { n: 'water_polo', c: 'activity', e: '🤽', d: 'water polo', u: '9.0' },
  { n: 'handball', c: 'activity', e: '🤾', d: 'handball', u: '9.0' },
  { n: 'juggling', c: 'activity', e: '🤹', d: 'juggling', u: '9.0' },
  { n: 'bath', c: 'activity', e: '🛀', d: 'bath', u: '6.0' },
  { n: 'sleeping_accommodation', c: 'objects', e: '🛌', d: 'sleeping accommodation', u: '7.0' },
  { n: 'two_women_holding_hands', c: 'people', e: '👭', d: 'two women holding hands', u: '6.0' },
  { n: 'couple', c: 'people', e: '👫', d: 'man and woman holding hands', u: '6.0' },
  { n: 'two_men_holding_hands', c: 'people', e: '👬', d: 'two men holding hands', u: '6.0' },
  { n: 'couplekiss', c: 'people', e: '💏', d: 'kiss', u: '6.0' },
  { n: 'kiss_mm', c: 'people', e: '👨‍❤️‍💋‍👨', d: 'kiss (man,man)', u: '6.0' },
  { n: 'kiss_ww', c: 'people', e: '👩‍❤️‍💋‍👩', d: 'kiss (woman,woman)', u: '6.0' },
  { n: 'couple_with_heart', c: 'people', e: '💑', d: 'couple with heart', u: '6.0' },
  { n: 'couple_mm', c: 'people', e: '👨‍❤️‍👨', d: 'couple (man,man)', u: '6.0' },
  { n: 'couple_ww', c: 'people', e: '👩‍❤️‍👩', d: 'couple (woman,woman)', u: '6.0' },
  { n: 'family_mwg', c: 'people', e: '👨‍👩‍👧', d: 'family (man,woman,girl)', u: '6.0' },
  { n: 'family_mwgb', c: 'people', e: '👨‍👩‍👧‍👦', d: 'family (man,woman,girl,boy)', u: '6.0' },
  { n: 'family_mwbb', c: 'people', e: '👨‍👩‍👦‍👦', d: 'family (man,woman,boy,boy)', u: '6.0' },
  { n: 'family_mwgg', c: 'people', e: '👨‍👩‍👧‍👧', d: 'family (man,woman,girl,girl)', u: '6.0' },
  { n: 'family_mmb', c: 'people', e: '👨‍👨‍👦', d: 'family (man,man,boy)', u: '6.0' },
  { n: 'family_mmg', c: 'people', e: '👨‍👨‍👧', d: 'family (man,man,girl)', u: '6.0' },
  { n: 'family_mmgb', c: 'people', e: '👨‍👨‍👧‍👦', d: 'family (man,man,girl,boy)', u: '6.0' },
  { n: 'family_mmbb', c: 'people', e: '👨‍👨‍👦‍👦', d: 'family (man,man,boy,boy)', u: '6.0' },
  { n: 'family_mmgg', c: 'people', e: '👨‍👨‍👧‍👧', d: 'family (man,man,girl,girl)', u: '6.0' },
  { n: 'family_wwb', c: 'people', e: '👩‍👩‍👦', d: 'family (woman,woman,boy)', u: '6.0' },
  { n: 'family_wwg', c: 'people', e: '👩‍👩‍👧', d: 'family (woman,woman,girl)', u: '6.0' },
  { n: 'family_wwgb', c: 'people', e: '👩‍👩‍👧‍👦', d: 'family (woman,woman,girl,boy)', u: '6.0' },
  { n: 'family_wwbb', c: 'people', e: '👩‍👩‍👦‍👦', d: 'family (woman,woman,boy,boy)', u: '6.0' },
  { n: 'family_wwgg', c: 'people', e: '👩‍👩‍👧‍👧', d: 'family (woman,woman,girl,girl)', u: '6.0' },
  { n: 'speaking_head', c: 'people', e: '🗣', d: 'speaking head in silhouette', u: '7.0' },
  { n: 'bust_in_silhouette', c: 'people', e: '👤', d: 'bust in silhouette', u: '6.0' },
  { n: 'busts_in_silhouette', c: 'people', e: '👥', d: 'busts in silhouette', u: '6.0' },
  { n: 'family', c: 'people', e: '👪', d: 'family', u: '6.0' },
  { n: 'footprints', c: 'people', e: '👣', d: 'footprints', u: '6.0' },
  { n: 'tone1', c: 'modifier', e: '🏻', d: 'emoji modifier Fitzpatrick type-1-2', u: '8.0' },
  { n: 'tone2', c: 'modifier', e: '🏼', d: 'emoji modifier Fitzpatrick type-3', u: '8.0' },
  { n: 'tone3', c: 'modifier', e: '🏽', d: 'emoji modifier Fitzpatrick type-4', u: '8.0' },
  { n: 'tone4', c: 'modifier', e: '🏾', d: 'emoji modifier Fitzpatrick type-5', u: '8.0' },
  { n: 'tone5', c: 'modifier', e: '🏿', d: 'emoji modifier Fitzpatrick type-6', u: '8.0' },
  { n: 'monkey_face', c: 'nature', e: '🐵', d: 'monkey face', u: '6.0' },
  { n: 'monkey', c: 'nature', e: '🐒', d: 'monkey', u: '6.0' },
  { n: 'gorilla', c: 'nature', e: '🦍', d: 'gorilla', u: '9.0' },
  { n: 'dog', c: 'nature', e: '🐶', d: 'dog face', u: '6.0' },
  { n: 'dog2', c: 'nature', e: '🐕', d: 'dog', u: '6.0' },
  { n: 'poodle', c: 'nature', e: '🐩', d: 'poodle', u: '6.0' },
  { n: 'wolf', c: 'nature', e: '🐺', d: 'wolf face', u: '6.0' },
  { n: 'fox', c: 'nature', e: '🦊', d: 'fox face', u: '9.0' },
  { n: 'cat', c: 'nature', e: '🐱', d: 'cat face', u: '6.0' },
  { n: 'cat2', c: 'nature', e: '🐈', d: 'cat', u: '6.0' },
  { n: 'lion_face', c: 'nature', e: '🦁', d: 'lion face', u: '8.0' },
  { n: 'tiger', c: 'nature', e: '🐯', d: 'tiger face', u: '6.0' },
  { n: 'tiger2', c: 'nature', e: '🐅', d: 'tiger', u: '6.0' },
  { n: 'leopard', c: 'nature', e: '🐆', d: 'leopard', u: '6.0' },
  { n: 'horse', c: 'nature', e: '🐴', d: 'horse face', u: '6.0' },
  { n: 'racehorse', c: 'nature', e: '🐎', d: 'horse', u: '6.0' },
  { n: 'unicorn', c: 'nature', e: '🦄', d: 'unicorn face', u: '8.0' },
  { n: 'deer', c: 'nature', e: '🦌', d: 'deer', u: '9.0' },
  { n: 'cow', c: 'nature', e: '🐮', d: 'cow face', u: '6.0' },
  { n: 'ox', c: 'nature', e: '🐂', d: 'ox', u: '6.0' },
  { n: 'water_buffalo', c: 'nature', e: '🐃', d: 'water buffalo', u: '6.0' },
  { n: 'cow2', c: 'nature', e: '🐄', d: 'cow', u: '6.0' },
  { n: 'pig', c: 'nature', e: '🐷', d: 'pig face', u: '6.0' },
  { n: 'pig2', c: 'nature', e: '🐖', d: 'pig', u: '6.0' },
  { n: 'boar', c: 'nature', e: '🐗', d: 'boar', u: '6.0' },
  { n: 'pig_nose', c: 'nature', e: '🐽', d: 'pig nose', u: '6.0' },
  { n: 'ram', c: 'nature', e: '🐏', d: 'ram', u: '6.0' },
  { n: 'sheep', c: 'nature', e: '🐑', d: 'sheep', u: '6.0' },
  { n: 'goat', c: 'nature', e: '🐐', d: 'goat', u: '6.0' },
  { n: 'dromedary_camel', c: 'nature', e: '🐪', d: 'dromedary camel', u: '6.0' },
  { n: 'camel', c: 'nature', e: '🐫', d: 'bactrian camel', u: '6.0' },
  { n: 'elephant', c: 'nature', e: '🐘', d: 'elephant', u: '6.0' },
  { n: 'rhino', c: 'nature', e: '🦏', d: 'rhinoceros', u: '9.0' },
  { n: 'mouse', c: 'nature', e: '🐭', d: 'mouse face', u: '6.0' },
  { n: 'mouse2', c: 'nature', e: '🐁', d: 'mouse', u: '6.0' },
  { n: 'rat', c: 'nature', e: '🐀', d: 'rat', u: '6.0' },
  { n: 'hamster', c: 'nature', e: '🐹', d: 'hamster face', u: '6.0' },
  { n: 'rabbit', c: 'nature', e: '🐰', d: 'rabbit face', u: '6.0' },
  { n: 'rabbit2', c: 'nature', e: '🐇', d: 'rabbit', u: '6.0' },
  { n: 'chipmunk', c: 'nature', e: '🐿', d: 'chipmunk', u: '7.0' },
  { n: 'bat', c: 'nature', e: '🦇', d: 'bat', u: '9.0' },
  { n: 'bear', c: 'nature', e: '🐻', d: 'bear face', u: '6.0' },
  { n: 'koala', c: 'nature', e: '🐨', d: 'koala', u: '6.0' },
  { n: 'panda_face', c: 'nature', e: '🐼', d: 'panda face', u: '6.0' },
  { n: 'feet', c: 'nature', e: '🐾', d: 'paw prints', u: '6.0' },
  { n: 'turkey', c: 'nature', e: '🦃', d: 'turkey', u: '8.0' },
  { n: 'chicken', c: 'nature', e: '🐔', d: 'chicken', u: '6.0' },
  { n: 'rooster', c: 'nature', e: '🐓', d: 'rooster', u: '6.0' },
  { n: 'hatching_chick', c: 'nature', e: '🐣', d: 'hatching chick', u: '6.0' },
  { n: 'baby_chick', c: 'nature', e: '🐤', d: 'baby chick', u: '6.0' },
  { n: 'hatched_chick', c: 'nature', e: '🐥', d: 'front-facing baby chick', u: '6.0' },
  { n: 'bird', c: 'nature', e: '🐦', d: 'bird', u: '6.0' },
  { n: 'penguin', c: 'nature', e: '🐧', d: 'penguin', u: '6.0' },
  { n: 'dove', c: 'nature', e: '🕊', d: 'dove of peace', u: '7.0' },
  { n: 'eagle', c: 'nature', e: '🦅', d: 'eagle', u: '9.0' },
  { n: 'duck', c: 'nature', e: '🦆', d: 'duck', u: '9.0' },
  { n: 'owl', c: 'nature', e: '🦉', d: 'owl', u: '9.0' },
  { n: 'frog', c: 'nature', e: '🐸', d: 'frog face', u: '6.0' },
  { n: 'crocodile', c: 'nature', e: '🐊', d: 'crocodile', u: '6.0' },
  { n: 'turtle', c: 'nature', e: '🐢', d: 'turtle', u: '6.0' },
  { n: 'lizard', c: 'nature', e: '🦎', d: 'lizard', u: '9.0' },
  { n: 'snake', c: 'nature', e: '🐍', d: 'snake', u: '6.0' },
  { n: 'dragon_face', c: 'nature', e: '🐲', d: 'dragon face', u: '6.0' },
  { n: 'dragon', c: 'nature', e: '🐉', d: 'dragon', u: '6.0' },
  { n: 'whale', c: 'nature', e: '🐳', d: 'spouting whale', u: '6.0' },
  { n: 'whale2', c: 'nature', e: '🐋', d: 'whale', u: '6.0' },
  { n: 'dolphin', c: 'nature', e: '🐬', d: 'dolphin', u: '6.0' },
  { n: 'fish', c: 'nature', e: '🐟', d: 'fish', u: '6.0' },
  { n: 'tropical_fish', c: 'nature', e: '🐠', d: 'tropical fish', u: '6.0' },
  { n: 'blowfish', c: 'nature', e: '🐡', d: 'blowfish', u: '6.0' },
  { n: 'shark', c: 'nature', e: '🦈', d: 'shark', u: '9.0' },
  { n: 'octopus', c: 'nature', e: '🐙', d: 'octopus', u: '6.0' },
  { n: 'shell', c: 'nature', e: '🐚', d: 'spiral shell', u: '6.0' },
  { n: 'snail', c: 'nature', e: '🐌', d: 'snail', u: '6.0' },
  { n: 'butterfly', c: 'nature', e: '🦋', d: 'butterfly', u: '9.0' },
  { n: 'bug', c: 'nature', e: '🐛', d: 'bug', u: '6.0' },
  { n: 'ant', c: 'nature', e: '🐜', d: 'ant', u: '6.0' },
  { n: 'bee', c: 'nature', e: '🐝', d: 'honeybee', u: '6.0' },
  { n: 'beetle', c: 'nature', e: '🐞', d: 'lady beetle', u: '6.0' },
  { n: 'spider', c: 'nature', e: '🕷', d: 'spider', u: '7.0' },
  { n: 'spider_web', c: 'nature', e: '🕸', d: 'spider web', u: '7.0' },
  { n: 'scorpion', c: 'nature', e: '🦂', d: 'scorpion', u: '8.0' },
  { n: 'bouquet', c: 'nature', e: '💐', d: 'bouquet', u: '6.0' },
  { n: 'cherry_blossom', c: 'nature', e: '🌸', d: 'cherry blossom', u: '6.0' },
  { n: 'white_flower', c: 'symbols', e: '💮', d: 'white flower', u: '6.0' },
  { n: 'rosette', c: 'activity', e: '🏵', d: 'rosette', u: '7.0' },
  { n: 'rose', c: 'nature', e: '🌹', d: 'rose', u: '6.0' },
  { n: 'wilted_rose', c: 'nature', e: '🥀', d: 'wilted flower', u: '9.0' },
  { n: 'hibiscus', c: 'nature', e: '🌺', d: 'hibiscus', u: '6.0' },
  { n: 'sunflower', c: 'nature', e: '🌻', d: 'sunflower', u: '6.0' },
  { n: 'blossom', c: 'nature', e: '🌼', d: 'blossom', u: '6.0' },
  { n: 'tulip', c: 'nature', e: '🌷', d: 'tulip', u: '6.0' },
  { n: 'seedling', c: 'nature', e: '🌱', d: 'seedling', u: '6.0' },
  { n: 'evergreen_tree', c: 'nature', e: '🌲', d: 'evergreen tree', u: '6.0' },
  { n: 'deciduous_tree', c: 'nature', e: '🌳', d: 'deciduous tree', u: '6.0' },
  { n: 'palm_tree', c: 'nature', e: '🌴', d: 'palm tree', u: '6.0' },
  { n: 'cactus', c: 'nature', e: '🌵', d: 'cactus', u: '6.0' },
  { n: 'ear_of_rice', c: 'nature', e: '🌾', d: 'ear of rice', u: '6.0' },
  { n: 'herb', c: 'nature', e: '🌿', d: 'herb', u: '6.0' },
  { n: 'shamrock', c: 'nature', e: '☘', d: 'shamrock', u: '4.1' },
  { n: 'four_leaf_clover', c: 'nature', e: '🍀', d: 'four leaf clover', u: '6.0' },
  { n: 'maple_leaf', c: 'nature', e: '🍁', d: 'maple leaf', u: '6.0' },
  { n: 'fallen_leaf', c: 'nature', e: '🍂', d: 'fallen leaf', u: '6.0' },
  { n: 'leaves', c: 'nature', e: '🍃', d: 'leaf fluttering in wind', u: '6.0' },
  { n: 'mushroom', c: 'nature', e: '🍄', d: 'mushroom', u: '6.0' },
  { n: 'grapes', c: 'food', e: '🍇', d: 'grapes', u: '6.0' },
  { n: 'melon', c: 'food', e: '🍈', d: 'melon', u: '6.0' },
  { n: 'watermelon', c: 'food', e: '🍉', d: 'watermelon', u: '6.0' },
  { n: 'tangerine', c: 'food', e: '🍊', d: 'tangerine', u: '6.0' },
  { n: 'lemon', c: 'food', e: '🍋', d: 'lemon', u: '6.0' },
  { n: 'banana', c: 'food', e: '🍌', d: 'banana', u: '6.0' },
  { n: 'pineapple', c: 'food', e: '🍍', d: 'pineapple', u: '6.0' },
  { n: 'apple', c: 'food', e: '🍎', d: 'red apple', u: '6.0' },
  { n: 'green_apple', c: 'food', e: '🍏', d: 'green apple', u: '6.0' },
  { n: 'pear', c: 'food', e: '🍐', d: 'pear', u: '6.0' },
  { n: 'peach', c: 'food', e: '🍑', d: 'peach', u: '6.0' },
  { n: 'cherries', c: 'food', e: '🍒', d: 'cherries', u: '6.0' },
  { n: 'strawberry', c: 'food', e: '🍓', d: 'strawberry', u: '6.0' },
  { n: 'kiwi', c: 'food', e: '🥝', d: 'kiwifruit', u: '9.0' },
  { n: 'tomato', c: 'food', e: '🍅', d: 'tomato', u: '6.0' },
  { n: 'avocado', c: 'food', e: '🥑', d: 'avocado', u: '9.0' },
  { n: 'eggplant', c: 'food', e: '🍆', d: 'aubergine', u: '6.0' },
  { n: 'potato', c: 'food', e: '🥔', d: 'potato', u: '9.0' },
  { n: 'carrot', c: 'food', e: '🥕', d: 'carrot', u: '9.0' },
  { n: 'corn', c: 'food', e: '🌽', d: 'ear of maize', u: '6.0' },
  { n: 'hot_pepper', c: 'food', e: '🌶', d: 'hot pepper', u: '7.0' },
  { n: 'cucumber', c: 'food', e: '🥒', d: 'cucumber', u: '9.0' },
  { n: 'peanuts', c: 'food', e: '🥜', d: 'peanuts', u: '9.0' },
  { n: 'chestnut', c: 'nature', e: '🌰', d: 'chestnut', u: '6.0' },
  { n: 'bread', c: 'food', e: '🍞', d: 'bread', u: '6.0' },
  { n: 'croissant', c: 'food', e: '🥐', d: 'croissant', u: '9.0' },
  { n: 'french_bread', c: 'food', e: '🥖', d: 'baguette bread', u: '9.0' },
  { n: 'pancakes', c: 'food', e: '🥞', d: 'pancakes', u: '9.0' },
  { n: 'cheese', c: 'food', e: '🧀', d: 'cheese wedge', u: '8.0' },
  { n: 'meat_on_bone', c: 'food', e: '🍖', d: 'meat on bone', u: '6.0' },
  { n: 'poultry_leg', c: 'food', e: '🍗', d: 'poultry leg', u: '6.0' },
  { n: 'bacon', c: 'food', e: '🥓', d: 'bacon', u: '9.0' },
  { n: 'hamburger', c: 'food', e: '🍔', d: 'hamburger', u: '6.0' },
  { n: 'fries', c: 'food', e: '🍟', d: 'french fries', u: '6.0' },
  { n: 'pizza', c: 'food', e: '🍕', d: 'slice of pizza', u: '6.0' },
  { n: 'hotdog', c: 'food', e: '🌭', d: 'hot dog', u: '8.0' },
  { n: 'taco', c: 'food', e: '🌮', d: 'taco', u: '8.0' },
  { n: 'burrito', c: 'food', e: '🌯', d: 'burrito', u: '8.0' },
  { n: 'stuffed_flatbread', c: 'food', e: '🥙', d: 'stuffed flatbread', u: '9.0' },
  { n: 'egg', c: 'food', e: '🥚', d: 'egg', u: '9.0' },
  { n: 'cooking', c: 'food', e: '🍳', d: 'cooking', u: '6.0' },
  { n: 'shallow_pan_of_food', c: 'food', e: '🥘', d: 'shallow pan of food', u: '9.0' },
  { n: 'stew', c: 'food', e: '🍲', d: 'pot of food', u: '6.0' },
  { n: 'salad', c: 'food', e: '🥗', d: 'green salad', u: '9.0' },
  { n: 'popcorn', c: 'food', e: '🍿', d: 'popcorn', u: '8.0' },
  { n: 'bento', c: 'food', e: '🍱', d: 'bento box', u: '6.0' },
  { n: 'rice_cracker', c: 'food', e: '🍘', d: 'rice cracker', u: '6.0' },
  { n: 'rice_ball', c: 'food', e: '🍙', d: 'rice ball', u: '6.0' },
  { n: 'rice', c: 'food', e: '🍚', d: 'cooked rice', u: '6.0' },
  { n: 'curry', c: 'food', e: '🍛', d: 'curry and rice', u: '6.0' },
  { n: 'ramen', c: 'food', e: '🍜', d: 'steaming bowl', u: '6.0' },
  { n: 'spaghetti', c: 'food', e: '🍝', d: 'spaghetti', u: '6.0' },
  { n: 'sweet_potato', c: 'food', e: '🍠', d: 'roasted sweet potato', u: '6.0' },
  { n: 'oden', c: 'food', e: '🍢', d: 'oden', u: '6.0' },
  { n: 'sushi', c: 'food', e: '🍣', d: 'sushi', u: '6.0' },
  { n: 'fried_shrimp', c: 'food', e: '🍤', d: 'fried shrimp', u: '6.0' },
  { n: 'fish_cake', c: 'food', e: '🍥', d: 'fish cake with swirl design', u: '6.0' },
  { n: 'dango', c: 'food', e: '🍡', d: 'dango', u: '6.0' },
  { n: 'crab', c: 'nature', e: '🦀', d: 'crab', u: '8.0' },
  { n: 'shrimp', c: 'nature', e: '🦐', d: 'shrimp', u: '9.0' },
  { n: 'squid', c: 'nature', e: '🦑', d: 'squid', u: '9.0' },
  { n: 'icecream', c: 'food', e: '🍦', d: 'soft ice cream', u: '6.0' },
  { n: 'shaved_ice', c: 'food', e: '🍧', d: 'shaved ice', u: '6.0' },
  { n: 'ice_cream', c: 'food', e: '🍨', d: 'ice cream', u: '6.0' },
  { n: 'doughnut', c: 'food', e: '🍩', d: 'doughnut', u: '6.0' },
  { n: 'cookie', c: 'food', e: '🍪', d: 'cookie', u: '6.0' },
  { n: 'birthday', c: 'food', e: '🎂', d: 'birthday cake', u: '6.0' },
  { n: 'cake', c: 'food', e: '🍰', d: 'shortcake', u: '6.0' },
  { n: 'chocolate_bar', c: 'food', e: '🍫', d: 'chocolate bar', u: '6.0' },
  { n: 'candy', c: 'food', e: '🍬', d: 'candy', u: '6.0' },
  { n: 'lollipop', c: 'food', e: '🍭', d: 'lollipop', u: '6.0' },
  { n: 'custard', c: 'food', e: '🍮', d: 'custard', u: '6.0' },
  { n: 'honey_pot', c: 'food', e: '🍯', d: 'honey pot', u: '6.0' },
  { n: 'baby_bottle', c: 'food', e: '🍼', d: 'baby bottle', u: '6.0' },
  { n: 'milk', c: 'food', e: '🥛', d: 'glass of milk', u: '9.0' },
  { n: 'coffee', c: 'food', e: '☕', d: 'hot beverage', u: '4.0' },
  { n: 'tea', c: 'food', e: '🍵', d: 'teacup without handle', u: '6.0' },
  { n: 'sake', c: 'food', e: '🍶', d: 'sake bottle and cup', u: '6.0' },
  { n: 'champagne', c: 'food', e: '🍾', d: 'bottle with popping cork', u: '8.0' },
  { n: 'wine_glass', c: 'food', e: '🍷', d: 'wine glass', u: '6.0' },
  { n: 'cocktail', c: 'food', e: '🍸', d: 'cocktail glass', u: '6.0' },
  { n: 'tropical_drink', c: 'food', e: '🍹', d: 'tropical drink', u: '6.0' },
  { n: 'beer', c: 'food', e: '🍺', d: 'beer mug', u: '6.0' },
  { n: 'beers', c: 'food', e: '🍻', d: 'clinking beer mugs', u: '6.0' },
  { n: 'champagne_glass', c: 'food', e: '🥂', d: 'clinking glasses', u: '9.0' },
  { n: 'tumbler_glass', c: 'food', e: '🥃', d: 'tumbler glass', u: '9.0' },
  { n: 'fork_knife_plate', c: 'food', e: '🍽', d: 'fork and knife with plate', u: '7.0' },
  { n: 'fork_and_knife', c: 'food', e: '🍴', d: 'fork and knife', u: '6.0' },
  { n: 'spoon', c: 'food', e: '🥄', d: 'spoon', u: '9.0' },
  { n: 'knife', c: 'objects', e: '🔪', d: 'hocho', u: '6.0' },
  { n: 'amphora', c: 'objects', e: '🏺', d: 'amphora', u: '8.0' },
  { n: 'earth_africa', c: 'nature', e: '🌍', d: 'earth globe europe-africa', u: '6.0' },
  { n: 'earth_americas', c: 'nature', e: '🌎', d: 'earth globe americas', u: '6.0' },
  { n: 'earth_asia', c: 'nature', e: '🌏', d: 'earth globe asia-australia', u: '6.0' },
  { n: 'globe_with_meridians', c: 'symbols', e: '🌐', d: 'globe with meridians', u: '6.0' },
  { n: 'map', c: 'objects', e: '🗺', d: 'world map', u: '7.0' },
  { n: 'japan', c: 'travel', e: '🗾', d: 'silhouette of japan', u: '6.0' },
  { n: 'mountain_snow', c: 'travel', e: '🏔', d: 'snow capped mountain', u: '7.0' },
  { n: 'mountain', c: 'travel', e: '⛰', d: 'mountain', u: '5.2' },
  { n: 'volcano', c: 'travel', e: '🌋', d: 'volcano', u: '6.0' },
  { n: 'mount_fuji', c: 'travel', e: '🗻', d: 'mount fuji', u: '6.0' },
  { n: 'camping', c: 'travel', e: '🏕', d: 'camping', u: '7.0' },
  { n: 'beach', c: 'travel', e: '🏖', d: 'beach with umbrella', u: '7.0' },
  { n: 'desert', c: 'travel', e: '🏜', d: 'desert', u: '7.0' },
  { n: 'island', c: 'travel', e: '🏝', d: 'desert island', u: '7.0' },
  { n: 'park', c: 'travel', e: '🏞', d: 'national park', u: '7.0' },
  { n: 'stadium', c: 'travel', e: '🏟', d: 'stadium', u: '7.0' },
  { n: 'classical_building', c: 'travel', e: '🏛', d: 'classical building', u: '7.0' },
  { n: 'construction_site', c: 'travel', e: '🏗', d: 'building construction', u: '7.0' },
  { n: 'homes', c: 'travel', e: '🏘', d: 'house buildings', u: '7.0' },
  { n: 'house_abandoned', c: 'travel', e: '🏚', d: 'derelict house building', u: '7.0' },
  { n: 'house', c: 'travel', e: '🏠', d: 'house building', u: '6.0' },
  { n: 'house_with_garden', c: 'travel', e: '🏡', d: 'house with garden', u: '6.0' },
  { n: 'office', c: 'travel', e: '🏢', d: 'office building', u: '6.0' },
  { n: 'post_office', c: 'travel', e: '🏣', d: 'japanese post office', u: '6.0' },
  { n: 'european_post_office', c: 'travel', e: '🏤', d: 'european post office', u: '6.0' },
  { n: 'hospital', c: 'travel', e: '🏥', d: 'hospital', u: '6.0' },
  { n: 'bank', c: 'travel', e: '🏦', d: 'bank', u: '6.0' },
  { n: 'hotel', c: 'travel', e: '🏨', d: 'hotel', u: '6.0' },
  { n: 'love_hotel', c: 'travel', e: '🏩', d: 'love hotel', u: '6.0' },
  { n: 'convenience_store', c: 'travel', e: '🏪', d: 'convenience store', u: '6.0' },
  { n: 'school', c: 'travel', e: '🏫', d: 'school', u: '6.0' },
  { n: 'department_store', c: 'travel', e: '🏬', d: 'department store', u: '6.0' },
  { n: 'factory', c: 'travel', e: '🏭', d: 'factory', u: '6.0' },
  { n: 'japanese_castle', c: 'travel', e: '🏯', d: 'japanese castle', u: '6.0' },
  { n: 'european_castle', c: 'travel', e: '🏰', d: 'european castle', u: '6.0' },
  { n: 'wedding', c: 'travel', e: '💒', d: 'wedding', u: '6.0' },
  { n: 'tokyo_tower', c: 'travel', e: '🗼', d: 'tokyo tower', u: '6.0' },
  { n: 'statue_of_liberty', c: 'travel', e: '🗽', d: 'statue of liberty', u: '6.0' },
  { n: 'church', c: 'travel', e: '⛪', d: 'church', u: '5.2' },
  { n: 'mosque', c: 'travel', e: '🕌', d: 'mosque', u: '8.0' },
  { n: 'synagogue', c: 'travel', e: '🕍', d: 'synagogue', u: '8.0' },
  { n: 'shinto_shrine', c: 'travel', e: '⛩', d: 'shinto shrine', u: '5.2' },
  { n: 'kaaba', c: 'travel', e: '🕋', d: 'kaaba', u: '8.0' },
  { n: 'fountain', c: 'travel', e: '⛲', d: 'fountain', u: '5.2' },
  { n: 'tent', c: 'travel', e: '⛺', d: 'tent', u: '5.2' },
  { n: 'foggy', c: 'travel', e: '🌁', d: 'foggy', u: '6.0' },
  { n: 'night_with_stars', c: 'travel', e: '🌃', d: 'night with stars', u: '6.0' },
  { n: 'cityscape', c: 'travel', e: '🏙', d: 'cityscape', u: '7.0' },
  { n: 'sunrise_over_mountains', c: 'travel', e: '🌄', d: 'sunrise over mountains', u: '6.0' },
  { n: 'sunrise', c: 'travel', e: '🌅', d: 'sunrise', u: '6.0' },
  { n: 'city_dusk', c: 'travel', e: '🌆', d: 'cityscape at dusk', u: '6.0' },
  { n: 'city_sunset', c: 'travel', e: '🌇', d: 'sunset over buildings', u: '6.0' },
  { n: 'bridge_at_night', c: 'travel', e: '🌉', d: 'bridge at night', u: '6.0' },
  { n: 'hotsprings', c: 'symbols', e: '♨', d: 'hot springs', u: '1.1' },
  { n: 'carousel_horse', c: 'travel', e: '🎠', d: 'carousel horse', u: '6.0' },
  { n: 'ferris_wheel', c: 'travel', e: '🎡', d: 'ferris wheel', u: '6.0' },
  { n: 'roller_coaster', c: 'travel', e: '🎢', d: 'roller coaster', u: '6.0' },
  { n: 'barber', c: 'objects', e: '💈', d: 'barber pole', u: '6.0' },
  { n: 'circus_tent', c: 'activity', e: '🎪', d: 'circus tent', u: '6.0' },
  { n: 'steam_locomotive', c: 'travel', e: '🚂', d: 'steam locomotive', u: '6.0' },
  { n: 'railway_car', c: 'travel', e: '🚃', d: 'railway car', u: '6.0' },
  { n: 'bullettrain_side', c: 'travel', e: '🚄', d: 'high-speed train', u: '6.0' },
  { n: 'bullettrain_front', c: 'travel', e: '🚅', d: 'high-speed train with bullet nose', u: '6.0' },
  { n: 'train2', c: 'travel', e: '🚆', d: 'train', u: '6.0' },
  { n: 'metro', c: 'travel', e: '🚇', d: 'metro', u: '6.0' },
  { n: 'light_rail', c: 'travel', e: '🚈', d: 'light rail', u: '6.0' },
  { n: 'station', c: 'travel', e: '🚉', d: 'station', u: '6.0' },
  { n: 'tram', c: 'travel', e: '🚊', d: 'tram', u: '6.0' },
  { n: 'monorail', c: 'travel', e: '🚝', d: 'monorail', u: '6.0' },
  { n: 'mountain_railway', c: 'travel', e: '🚞', d: 'mountain railway', u: '6.0' },
  { n: 'train', c: 'travel', e: '🚋', d: 'Tram Car', u: '6.0' },
  { n: 'bus', c: 'travel', e: '🚌', d: 'bus', u: '6.0' },
  { n: 'oncoming_bus', c: 'travel', e: '🚍', d: 'oncoming bus', u: '6.0' },
  { n: 'trolleybus', c: 'travel', e: '🚎', d: 'trolleybus', u: '6.0' },
  { n: 'minibus', c: 'travel', e: '🚐', d: 'minibus', u: '6.0' },
  { n: 'ambulance', c: 'travel', e: '🚑', d: 'ambulance', u: '6.0' },
  { n: 'fire_engine', c: 'travel', e: '🚒', d: 'fire engine', u: '6.0' },
  { n: 'police_car', c: 'travel', e: '🚓', d: 'police car', u: '6.0' },
  { n: 'oncoming_police_car', c: 'travel', e: '🚔', d: 'oncoming police car', u: '6.0' },
  { n: 'taxi', c: 'travel', e: '🚕', d: 'taxi', u: '6.0' },
  { n: 'oncoming_taxi', c: 'travel', e: '🚖', d: 'oncoming taxi', u: '6.0' },
  { n: 'red_car', c: 'travel', e: '🚗', d: 'automobile', u: '6.0' },
  { n: 'oncoming_automobile', c: 'travel', e: '🚘', d: 'oncoming automobile', u: '6.0' },
  { n: 'blue_car', c: 'travel', e: '🚙', d: 'recreational vehicle', u: '6.0' },
  { n: 'truck', c: 'travel', e: '🚚', d: 'delivery truck', u: '6.0' },
  { n: 'articulated_lorry', c: 'travel', e: '🚛', d: 'articulated lorry', u: '6.0' },
  { n: 'tractor', c: 'travel', e: '🚜', d: 'tractor', u: '6.0' },
  { n: 'race_car', c: 'travel', e: '🏎', d: 'racing car', u: '7.0' },
  { n: 'motorcycle', c: 'travel', e: '🏍', d: 'racing motorcycle', u: '7.0' },
  { n: 'motor_scooter', c: 'travel', e: '🛵', d: 'motor scooter', u: '9.0' },
  { n: 'bike', c: 'travel', e: '🚲', d: 'bicycle', u: '6.0' },
  { n: 'scooter', c: 'travel', e: '🛴', d: 'scooter', u: '9.0' },
  { n: 'busstop', c: 'travel', e: '🚏', d: 'bus stop', u: '6.0' },
  { n: 'motorway', c: 'travel', e: '🛣', d: 'motorway', u: '7.0' },
  { n: 'railway_track', c: 'travel', e: '🛤', d: 'railway track', u: '7.0' },
  { n: 'oil', c: 'objects', e: '🛢', d: 'oil drum', u: '7.0' },
  { n: 'fuelpump', c: 'travel', e: '⛽', d: 'fuel pump', u: '5.2' },
  { n: 'rotating_light', c: 'travel', e: '🚨', d: 'police cars revolving light', u: '6.0' },
  { n: 'traffic_light', c: 'travel', e: '🚥', d: 'horizontal traffic light', u: '6.0' },
  { n: 'vertical_traffic_light', c: 'travel', e: '🚦', d: 'vertical traffic light', u: '6.0' },
  { n: 'octagonal_sign', c: 'symbols', e: '🛑', d: 'octagonal sign', u: '9.0' },
  { n: 'construction', c: 'travel', e: '🚧', d: 'construction sign', u: '6.0' },
  { n: 'anchor', c: 'travel', e: '⚓', d: 'anchor', u: '4.1' },
  { n: 'sailboat', c: 'travel', e: '⛵', d: 'sailboat', u: '5.2' },
  { n: 'canoe', c: 'travel', e: '🛶', d: 'canoe', u: '9.0' },
  { n: 'speedboat', c: 'travel', e: '🚤', d: 'speedboat', u: '6.0' },
  { n: 'cruise_ship', c: 'travel', e: '🛳', d: 'passenger ship', u: '7.0' },
  { n: 'ferry', c: 'travel', e: '⛴', d: 'ferry', u: '5.2' },
  { n: 'motorboat', c: 'travel', e: '🛥', d: 'motorboat', u: '7.0' },
  { n: 'ship', c: 'travel', e: '🚢', d: 'ship', u: '6.0' },
  { n: 'airplane', c: 'travel', e: '✈', d: 'airplane', u: '1.1' },
  { n: 'airplane_small', c: 'travel', e: '🛩', d: 'small airplane', u: '7.0' },
  { n: 'airplane_departure', c: 'travel', e: '🛫', d: 'airplane departure', u: '7.0' },
  { n: 'airplane_arriving', c: 'travel', e: '🛬', d: 'airplane arriving', u: '7.0' },
  { n: 'seat', c: 'travel', e: '💺', d: 'seat', u: '6.0' },
  { n: 'helicopter', c: 'travel', e: '🚁', d: 'helicopter', u: '6.0' },
  { n: 'suspension_railway', c: 'travel', e: '🚟', d: 'suspension railway', u: '6.0' },
  { n: 'mountain_cableway', c: 'travel', e: '🚠', d: 'mountain cableway', u: '6.0' },
  { n: 'aerial_tramway', c: 'travel', e: '🚡', d: 'aerial tramway', u: '6.0' },
  { n: 'satellite_orbital', c: 'travel', e: '🛰', d: 'satellite', u: '7.0' },
  { n: 'rocket', c: 'travel', e: '🚀', d: 'rocket', u: '6.0' },
  { n: 'bellhop', c: 'objects', e: '🛎', d: 'bellhop bell', u: '7.0' },
  { n: 'hourglass', c: 'objects', e: '⌛', d: 'hourglass', u: '1.1' },
  { n: 'hourglass_flowing_sand', c: 'objects', e: '⏳', d: 'hourglass with flowing sand', u: '6.0' },
  { n: 'watch', c: 'objects', e: '⌚', d: 'watch', u: '1.1' },
  { n: 'alarm_clock', c: 'objects', e: '⏰', d: 'alarm clock', u: '6.0' },
  { n: 'stopwatch', c: 'objects', e: '⏱', d: 'stopwatch', u: '6.0' },
  { n: 'timer', c: 'objects', e: '⏲', d: 'timer clock', u: '6.0' },
  { n: 'clock', c: 'objects', e: '🕰', d: 'mantlepiece clock', u: '7.0' },
  { n: 'clock12', c: 'symbols', e: '🕛', d: 'clock face twelve oclock', u: '6.0' },
  { n: 'clock1230', c: 'symbols', e: '🕧', d: 'clock face twelve-thirty', u: '6.0' },
  { n: 'clock1', c: 'symbols', e: '🕐', d: 'clock face one oclock', u: '6.0' },
  { n: 'clock130', c: 'symbols', e: '🕜', d: 'clock face one-thirty', u: '6.0' },
  { n: 'clock2', c: 'symbols', e: '🕑', d: 'clock face two oclock', u: '6.0' },
  { n: 'clock230', c: 'symbols', e: '🕝', d: 'clock face two-thirty', u: '6.0' },
  { n: 'clock3', c: 'symbols', e: '🕒', d: 'clock face three oclock', u: '6.0' },
  { n: 'clock330', c: 'symbols', e: '🕞', d: 'clock face three-thirty', u: '6.0' },
  { n: 'clock4', c: 'symbols', e: '🕓', d: 'clock face four oclock', u: '6.0' },
  { n: 'clock430', c: 'symbols', e: '🕟', d: 'clock face four-thirty', u: '6.0' },
  { n: 'clock5', c: 'symbols', e: '🕔', d: 'clock face five oclock', u: '6.0' },
  { n: 'clock530', c: 'symbols', e: '🕠', d: 'clock face five-thirty', u: '6.0' },
  { n: 'clock6', c: 'symbols', e: '🕕', d: 'clock face six oclock', u: '6.0' },
  { n: 'clock630', c: 'symbols', e: '🕡', d: 'clock face six-thirty', u: '6.0' },
  { n: 'clock7', c: 'symbols', e: '🕖', d: 'clock face seven oclock', u: '6.0' },
  { n: 'clock730', c: 'symbols', e: '🕢', d: 'clock face seven-thirty', u: '6.0' },
  { n: 'clock8', c: 'symbols', e: '🕗', d: 'clock face eight oclock', u: '6.0' },
  { n: 'clock830', c: 'symbols', e: '🕣', d: 'clock face eight-thirty', u: '6.0' },
  { n: 'clock9', c: 'symbols', e: '🕘', d: 'clock face nine oclock', u: '6.0' },
  { n: 'clock930', c: 'symbols', e: '🕤', d: 'clock face nine-thirty', u: '6.0' },
  { n: 'clock10', c: 'symbols', e: '🕙', d: 'clock face ten oclock', u: '6.0' },
  { n: 'clock1030', c: 'symbols', e: '🕥', d: 'clock face ten-thirty', u: '6.0' },
  { n: 'clock11', c: 'symbols', e: '🕚', d: 'clock face eleven oclock', u: '6.0' },
  { n: 'clock1130', c: 'symbols', e: '🕦', d: 'clock face eleven-thirty', u: '6.0' },
  { n: 'new_moon', c: 'nature', e: '🌑', d: 'new moon symbol', u: '6.0' },
  { n: 'waxing_crescent_moon', c: 'nature', e: '🌒', d: 'waxing crescent moon symbol', u: '6.0' },
  { n: 'first_quarter_moon', c: 'nature', e: '🌓', d: 'first quarter moon symbol', u: '6.0' },
  { n: 'waxing_gibbous_moon', c: 'nature', e: '🌔', d: 'waxing gibbous moon symbol', u: '6.0' },
  { n: 'full_moon', c: 'nature', e: '🌕', d: 'full moon symbol', u: '6.0' },
  { n: 'waning_gibbous_moon', c: 'nature', e: '🌖', d: 'waning gibbous moon symbol', u: '6.0' },
  { n: 'last_quarter_moon', c: 'nature', e: '🌗', d: 'last quarter moon symbol', u: '6.0' },
  { n: 'waning_crescent_moon', c: 'nature', e: '🌘', d: 'waning crescent moon symbol', u: '6.0' },
  { n: 'crescent_moon', c: 'nature', e: '🌙', d: 'crescent moon', u: '6.0' },
  { n: 'new_moon_with_face', c: 'nature', e: '🌚', d: 'new moon with face', u: '6.0' },
  { n: 'first_quarter_moon_with_face', c: 'nature', e: '🌛', d: 'first quarter moon with face', u: '6.0' },
  { n: 'last_quarter_moon_with_face', c: 'nature', e: '🌜', d: 'last quarter moon with face', u: '6.0' },
  { n: 'thermometer', c: 'objects', e: '🌡', d: 'thermometer', u: '7.0' },
  { n: 'sunny', c: 'nature', e: '☀', d: 'black sun with rays', u: '1.1' },
  { n: 'full_moon_with_face', c: 'nature', e: '🌝', d: 'full moon with face', u: '6.0' },
  { n: 'sun_with_face', c: 'nature', e: '🌞', d: 'sun with face', u: '6.0' },
  { n: 'star', c: 'nature', e: '⭐', d: 'white medium star', u: '5.1' },
  { n: 'star2', c: 'nature', e: '🌟', d: 'glowing star', u: '6.0' },
  { n: 'stars', c: 'travel', e: '🌠', d: 'shooting star', u: '6.0' },
  { n: 'milky_way', c: 'travel', e: '🌌', d: 'milky way', u: '6.0' },
  { n: 'cloud', c: 'nature', e: '☁', d: 'cloud', u: '1.1' },
  { n: 'partly_sunny', c: 'nature', e: '⛅', d: 'sun behind cloud', u: '5.2' },
  { n: 'thunder_cloud_rain', c: 'nature', e: '⛈', d: 'thunder cloud and rain', u: '5.2' },
  { n: 'white_sun_small_cloud', c: 'nature', e: '🌤', d: 'white sun with small cloud', u: '7.0' },
  { n: 'white_sun_cloud', c: 'nature', e: '🌥', d: 'white sun behind cloud', u: '7.0' },
  { n: 'white_sun_rain_cloud', c: 'nature', e: '🌦', d: 'white sun behind cloud with rain', u: '7.0' },
  { n: 'cloud_rain', c: 'nature', e: '🌧', d: 'cloud with rain', u: '7.0' },
  { n: 'cloud_snow', c: 'nature', e: '🌨', d: 'cloud with snow', u: '7.0' },
  { n: 'cloud_lightning', c: 'nature', e: '🌩', d: 'cloud with lightning', u: '7.0' },
  { n: 'cloud_tornado', c: 'nature', e: '🌪', d: 'cloud with tornado', u: '7.0' },
  { n: 'fog', c: 'nature', e: '🌫', d: 'fog', u: '7.0' },
  { n: 'wind_blowing_face', c: 'nature', e: '🌬', d: 'wind blowing face', u: '7.0' },
  { n: 'cyclone', c: 'symbols', e: '🌀', d: 'cyclone', u: '6.0' },
  { n: 'rainbow', c: 'travel', e: '🌈', d: 'rainbow', u: '6.0' },
  { n: 'closed_umbrella', c: 'people', e: '🌂', d: 'closed umbrella', u: '6.0' },
  { n: 'umbrella2', c: 'nature', e: '☂', d: 'umbrella', u: '1.1' },
  { n: 'umbrella', c: 'nature', e: '☔', d: 'umbrella with rain drops', u: '4.0' },
  { n: 'beach_umbrella', c: 'objects', e: '⛱', d: 'umbrella on ground', u: '5.2' },
  { n: 'zap', c: 'nature', e: '⚡', d: 'high voltage sign', u: '4.0' },
  { n: 'snowflake', c: 'nature', e: '❄', d: 'snowflake', u: '1.1' },
  { n: 'snowman2', c: 'nature', e: '☃', d: 'snowman', u: '1.1' },
  { n: 'snowman', c: 'nature', e: '⛄', d: 'snowman without snow', u: '5.2' },
  { n: 'comet', c: 'nature', e: '☄', d: 'comet', u: '1.1' },
  { n: 'fire', c: 'nature', e: '🔥', d: 'fire', u: '6.0' },
  { n: 'droplet', c: 'nature', e: '💧', d: 'droplet', u: '6.0' },
  { n: 'ocean', c: 'nature', e: '🌊', d: 'water wave', u: '6.0' },
  { n: 'jack_o_lantern', c: 'nature', e: '🎃', d: 'jack-o-lantern', u: '6.0' },
  { n: 'christmas_tree', c: 'nature', e: '🎄', d: 'christmas tree', u: '6.0' },
  { n: 'fireworks', c: 'travel', e: '🎆', d: 'fireworks', u: '6.0' },
  { n: 'sparkler', c: 'travel', e: '🎇', d: 'firework sparkler', u: '6.0' },
  { n: 'sparkles', c: 'nature', e: '✨', d: 'sparkles', u: '6.0' },
  { n: 'balloon', c: 'objects', e: '🎈', d: 'balloon', u: '6.0' },
  { n: 'tada', c: 'objects', e: '🎉', d: 'party popper', u: '6.0' },
  { n: 'confetti_ball', c: 'objects', e: '🎊', d: 'confetti ball', u: '6.0' },
  { n: 'tanabata_tree', c: 'nature', e: '🎋', d: 'tanabata tree', u: '6.0' },
  { n: 'bamboo', c: 'nature', e: '🎍', d: 'pine decoration', u: '6.0' },
  { n: 'dolls', c: 'objects', e: '🎎', d: 'japanese dolls', u: '6.0' },
  { n: 'flags', c: 'objects', e: '🎏', d: 'carp streamer', u: '6.0' },
  { n: 'wind_chime', c: 'objects', e: '🎐', d: 'wind chime', u: '6.0' },
  { n: 'rice_scene', c: 'travel', e: '🎑', d: 'moon viewing ceremony', u: '6.0' },
  { n: 'ribbon', c: 'objects', e: '🎀', d: 'ribbon', u: '6.0' },
  { n: 'gift', c: 'objects', e: '🎁', d: 'wrapped present', u: '6.0' },
  { n: 'reminder_ribbon', c: 'activity', e: '🎗', d: 'reminder ribbon', u: '7.0' },
  { n: 'tickets', c: 'activity', e: '🎟', d: 'admission tickets', u: '7.0' },
  { n: 'ticket', c: 'activity', e: '🎫', d: 'ticket', u: '6.0' },
  { n: 'military_medal', c: 'activity', e: '🎖', d: 'military medal', u: '7.0' },
  { n: 'trophy', c: 'activity', e: '🏆', d: 'trophy', u: '6.0' },
  { n: 'medal', c: 'activity', e: '🏅', d: 'sports medal', u: '7.0' },
  { n: 'first_place', c: 'activity', e: '🥇', d: 'first place medal', u: '9.0' },
  { n: 'second_place', c: 'activity', e: '🥈', d: 'second place medal', u: '9.0' },
  { n: 'third_place', c: 'activity', e: '🥉', d: 'third place medal', u: '9.0' },
  { n: 'soccer', c: 'activity', e: '⚽', d: 'soccer ball', u: '5.2' },
  { n: 'baseball', c: 'activity', e: '⚾', d: 'baseball', u: '5.2' },
  { n: 'basketball', c: 'activity', e: '🏀', d: 'basketball and hoop', u: '6.0' },
  { n: 'volleyball', c: 'activity', e: '🏐', d: 'volleyball', u: '8.0' },
  { n: 'football', c: 'activity', e: '🏈', d: 'american football', u: '6.0' },
  { n: 'rugby_football', c: 'activity', e: '🏉', d: 'rugby football', u: '6.0' },
  { n: 'tennis', c: 'activity', e: '🎾', d: 'tennis racquet and ball', u: '6.0' },
  { n: 'bowling', c: 'activity', e: '🎳', d: 'bowling', u: '6.0' },
  { n: 'cricket', c: 'activity', e: '🏏', d: 'cricket bat and ball', u: '10.0' },
  { n: 'field_hockey', c: 'activity', e: '🏑', d: 'field hockey stick and ball', u: '8.0' },
  { n: 'hockey', c: 'activity', e: '🏒', d: 'ice hockey stick and puck', u: '8.0' },
  { n: 'ping_pong', c: 'activity', e: '🏓', d: 'table tennis paddle and ball', u: '8.0' },
  { n: 'badminton', c: 'activity', e: '🏸', d: 'badminton racquet', u: '8.0' },
  { n: 'boxing_glove', c: 'activity', e: '🥊', d: 'boxing glove', u: '9.0' },
  { n: 'martial_arts_uniform', c: 'activity', e: '🥋', d: 'martial arts uniform', u: '9.0' },
  { n: 'goal', c: 'activity', e: '🥅', d: 'goal net', u: '9.0' },
  { n: 'golf', c: 'activity', e: '⛳', d: 'flag in hole', u: '5.2' },
  { n: 'ice_skate', c: 'activity', e: '⛸', d: 'ice skate', u: '5.2' },
  { n: 'fishing_pole_and_fish', c: 'activity', e: '🎣', d: 'fishing pole and fish', u: '6.0' },
  { n: 'running_shirt_with_sash', c: 'activity', e: '🎽', d: 'running shirt with sash', u: '6.0' },
  { n: 'ski', c: 'activity', e: '🎿', d: 'ski and ski boot', u: '6.0' },
  { n: 'dart', c: 'activity', e: '🎯', d: 'direct hit', u: '6.0' },
  { n: 'gun', c: 'objects', e: '🔫', d: 'pistol', u: '6.0' },
  { n: '8ball', c: 'activity', e: '🎱', d: 'billiards', u: '6.0' },
  { n: 'crystal_ball', c: 'objects', e: '🔮', d: 'crystal ball', u: '6.0' },
  { n: 'video_game', c: 'activity', e: '🎮', d: 'video game', u: '6.0' },
  { n: 'joystick', c: 'objects', e: '🕹', d: 'joystick', u: '7.0' },
  { n: 'slot_machine', c: 'activity', e: '🎰', d: 'slot machine', u: '6.0' },
  { n: 'game_die', c: 'activity', e: '🎲', d: 'game die', u: '6.0' },
  { n: 'spades', c: 'symbols', e: '♠', d: 'black spade suit', u: '1.1' },
  { n: 'hearts', c: 'symbols', e: '♥', d: 'black heart suit', u: '1.1' },
  { n: 'diamonds', c: 'symbols', e: '♦', d: 'black diamond suit', u: '1.1' },
  { n: 'clubs', c: 'symbols', e: '♣', d: 'black club suit', u: '1.1' },
  { n: 'black_joker', c: 'symbols', e: '🃏', d: 'playing card black joker', u: '6.0' },
  { n: 'mahjong', c: 'symbols', e: '🀄', d: 'mahjong tile red dragon', u: '5.1' },
  { n: 'flower_playing_cards', c: 'symbols', e: '🎴', d: 'flower playing cards', u: '6.0' },
  { n: 'performing_arts', c: 'activity', e: '🎭', d: 'performing arts', u: '6.0' },
  { n: 'frame_photo', c: 'objects', e: '🖼', d: 'frame with picture', u: '7.0' },
  { n: 'art', c: 'activity', e: '🎨', d: 'artist palette', u: '6.0' },
  { n: 'eyeglasses', c: 'people', e: '👓', d: 'eyeglasses', u: '6.0' },
  { n: 'dark_sunglasses', c: 'people', e: '🕶', d: 'dark sunglasses', u: '7.0' },
  { n: 'necktie', c: 'people', e: '👔', d: 'necktie', u: '6.0' },
  { n: 'shirt', c: 'people', e: '👕', d: 't-shirt', u: '6.0' },
  { n: 'jeans', c: 'people', e: '👖', d: 'jeans', u: '6.0' },
  { n: 'dress', c: 'people', e: '👗', d: 'dress', u: '6.0' },
  { n: 'kimono', c: 'people', e: '👘', d: 'kimono', u: '6.0' },
  { n: 'bikini', c: 'people', e: '👙', d: 'bikini', u: '6.0' },
  { n: 'womans_clothes', c: 'people', e: '👚', d: 'womans clothes', u: '6.0' },
  { n: 'purse', c: 'people', e: '👛', d: 'purse', u: '6.0' },
  { n: 'handbag', c: 'people', e: '👜', d: 'handbag', u: '6.0' },
  { n: 'pouch', c: 'people', e: '👝', d: 'pouch', u: '6.0' },
  { n: 'shopping_bags', c: 'objects', e: '🛍', d: 'shopping bags', u: '7.0' },
  { n: 'school_satchel', c: 'people', e: '🎒', d: 'school satchel', u: '6.0' },
  { n: 'mans_shoe', c: 'people', e: '👞', d: 'mans shoe', u: '6.0' },
  { n: 'athletic_shoe', c: 'people', e: '👟', d: 'athletic shoe', u: '6.0' },
  { n: 'high_heel', c: 'people', e: '👠', d: 'high-heeled shoe', u: '6.0' },
  { n: 'sandal', c: 'people', e: '👡', d: 'womans sandal', u: '6.0' },
  { n: 'boot', c: 'people', e: '👢', d: 'womans boots', u: '6.0' },
  { n: 'crown', c: 'people', e: '👑', d: 'crown', u: '6.0' },
  { n: 'womans_hat', c: 'people', e: '👒', d: 'womans hat', u: '6.0' },
  { n: 'tophat', c: 'people', e: '🎩', d: 'top hat', u: '6.0' },
  { n: 'mortar_board', c: 'people', e: '🎓', d: 'graduation cap', u: '6.0' },
  { n: 'helmet_with_cross', c: 'people', e: '⛑', d: 'helmet with white cross', u: '5.2' },
  { n: 'prayer_beads', c: 'objects', e: '📿', d: 'prayer beads', u: '8.0' },
  { n: 'lipstick', c: 'people', e: '💄', d: 'lipstick', u: '6.0' },
  { n: 'ring', c: 'people', e: '💍', d: 'ring', u: '6.0' },
  { n: 'gem', c: 'objects', e: '💎', d: 'gem stone', u: '6.0' },
  { n: 'mute', c: 'symbols', e: '🔇', d: 'speaker with cancellation stroke', u: '6.0' },
  { n: 'speaker', c: 'symbols', e: '🔈', d: 'speaker', u: '6.0' },
  { n: 'sound', c: 'symbols', e: '🔉', d: 'speaker with one sound wave', u: '6.0' },
  { n: 'loud_sound', c: 'symbols', e: '🔊', d: 'speaker with three sound waves', u: '6.0' },
  { n: 'loudspeaker', c: 'symbols', e: '📢', d: 'public address loudspeaker', u: '6.0' },
  { n: 'mega', c: 'symbols', e: '📣', d: 'cheering megaphone', u: '6.0' },
  { n: 'postal_horn', c: 'objects', e: '📯', d: 'postal horn', u: '6.0' },
  { n: 'bell', c: 'symbols', e: '🔔', d: 'bell', u: '6.0' },
  { n: 'no_bell', c: 'symbols', e: '🔕', d: 'bell with cancellation stroke', u: '6.0' },
  { n: 'musical_score', c: 'activity', e: '🎼', d: 'musical score', u: '6.0' },
  { n: 'musical_note', c: 'symbols', e: '🎵', d: 'musical note', u: '6.0' },
  { n: 'notes', c: 'symbols', e: '🎶', d: 'multiple musical notes', u: '6.0' },
  { n: 'microphone2', c: 'objects', e: '🎙', d: 'studio microphone', u: '7.0' },
  { n: 'level_slider', c: 'objects', e: '🎚', d: 'level slider', u: '7.0' },
  { n: 'control_knobs', c: 'objects', e: '🎛', d: 'control knobs', u: '7.0' },
  { n: 'microphone', c: 'activity', e: '🎤', d: 'microphone', u: '6.0' },
  { n: 'headphones', c: 'activity', e: '🎧', d: 'headphone', u: '6.0' },
  { n: 'radio', c: 'objects', e: '📻', d: 'radio', u: '6.0' },
  { n: 'saxophone', c: 'activity', e: '🎷', d: 'saxophone', u: '6.0' },
  { n: 'guitar', c: 'activity', e: '🎸', d: 'guitar', u: '6.0' },
  { n: 'musical_keyboard', c: 'activity', e: '🎹', d: 'musical keyboard', u: '6.0' },
  { n: 'trumpet', c: 'activity', e: '🎺', d: 'trumpet', u: '6.0' },
  { n: 'violin', c: 'activity', e: '🎻', d: 'violin', u: '6.0' },
  { n: 'drum', c: 'activity', e: '🥁', d: 'drum with drumsticks', u: '9.0' },
  { n: 'iphone', c: 'objects', e: '📱', d: 'mobile phone', u: '6.0' },
  { n: 'calling', c: 'objects', e: '📲', d: 'mobile phone with rightwards arrow at left', u: '6.0' },
  { n: 'telephone', c: 'objects', e: '☎', d: 'black telephone', u: '1.1' },
  { n: 'telephone_receiver', c: 'objects', e: '📞', d: 'telephone receiver', u: '6.0' },
  { n: 'pager', c: 'objects', e: '📟', d: 'pager', u: '6.0' },
  { n: 'fax', c: 'objects', e: '📠', d: 'fax machine', u: '6.0' },
  { n: 'battery', c: 'objects', e: '🔋', d: 'battery', u: '6.0' },
  { n: 'electric_plug', c: 'objects', e: '🔌', d: 'electric plug', u: '6.0' },
  { n: 'computer', c: 'objects', e: '💻', d: 'personal computer', u: '6.0' },
  { n: 'desktop', c: 'objects', e: '🖥', d: 'desktop computer', u: '7.0' },
  { n: 'printer', c: 'objects', e: '🖨', d: 'printer', u: '7.0' },
  { n: 'keyboard', c: 'objects', e: '⌨', d: 'keyboard', u: '1.1' },
  { n: 'mouse_three_button', c: 'objects', e: '🖱', d: 'three button mouse', u: '7.0' },
  { n: 'trackball', c: 'objects', e: '🖲', d: 'trackball', u: '7.0' },
  { n: 'minidisc', c: 'objects', e: '💽', d: 'minidisc', u: '6.0' },
  { n: 'floppy_disk', c: 'objects', e: '💾', d: 'floppy disk', u: '6.0' },
  { n: 'cd', c: 'objects', e: '💿', d: 'optical disc', u: '6.0' },
  { n: 'dvd', c: 'objects', e: '📀', d: 'dvd', u: '6.0' },
  { n: 'movie_camera', c: 'objects', e: '🎥', d: 'movie camera', u: '6.0' },
  { n: 'film_frames', c: 'objects', e: '🎞', d: 'film frames', u: '7.0' },
  { n: 'projector', c: 'objects', e: '📽', d: 'film projector', u: '7.0' },
  { n: 'clapper', c: 'activity', e: '🎬', d: 'clapper board', u: '6.0' },
  { n: 'tv', c: 'objects', e: '📺', d: 'television', u: '6.0' },
  { n: 'camera', c: 'objects', e: '📷', d: 'camera', u: '6.0' },
  { n: 'camera_with_flash', c: 'objects', e: '📸', d: 'camera with flash', u: '7.0' },
  { n: 'video_camera', c: 'objects', e: '📹', d: 'video camera', u: '6.0' },
  { n: 'vhs', c: 'objects', e: '📼', d: 'videocassette', u: '6.0' },
  { n: 'mag', c: 'objects', e: '🔍', d: 'left-pointing magnifying glass', u: '6.0' },
  { n: 'mag_right', c: 'objects', e: '🔎', d: 'right-pointing magnifying glass', u: '6.0' },
  { n: 'candle', c: 'objects', e: '🕯', d: 'candle', u: '7.0' },
  { n: 'bulb', c: 'objects', e: '💡', d: 'electric light bulb', u: '6.0' },
  { n: 'flashlight', c: 'objects', e: '🔦', d: 'electric torch', u: '6.0' },
  { n: 'izakaya_lantern', c: 'objects', e: '🏮', d: 'izakaya lantern', u: '6.0' },
  { n: 'notebook_with_decorative_cover', c: 'objects', e: '📔', d: 'notebook with decorative cover', u: '6.0' },
  { n: 'closed_book', c: 'objects', e: '📕', d: 'closed book', u: '6.0' },
  { n: 'book', c: 'objects', e: '📖', d: 'open book', u: '6.0' },
  { n: 'green_book', c: 'objects', e: '📗', d: 'green book', u: '6.0' },
  { n: 'blue_book', c: 'objects', e: '📘', d: 'blue book', u: '6.0' },
  { n: 'orange_book', c: 'objects', e: '📙', d: 'orange book', u: '6.0' },
  { n: 'books', c: 'objects', e: '📚', d: 'books', u: '6.0' },
  { n: 'notebook', c: 'objects', e: '📓', d: 'notebook', u: '6.0' },
  { n: 'ledger', c: 'objects', e: '📒', d: 'ledger', u: '6.0' },
  { n: 'page_with_curl', c: 'objects', e: '📃', d: 'page with curl', u: '6.0' },
  { n: 'scroll', c: 'objects', e: '📜', d: 'scroll', u: '6.0' },
  { n: 'page_facing_up', c: 'objects', e: '📄', d: 'page facing up', u: '6.0' },
  { n: 'newspaper', c: 'objects', e: '📰', d: 'newspaper', u: '6.0' },
  { n: 'newspaper2', c: 'objects', e: '🗞', d: 'rolled-up newspaper', u: '7.0' },
  { n: 'bookmark_tabs', c: 'objects', e: '📑', d: 'bookmark tabs', u: '6.0' },
  { n: 'bookmark', c: 'objects', e: '🔖', d: 'bookmark', u: '6.0' },
  { n: 'label', c: 'objects', e: '🏷', d: 'label', u: '7.0' },
  { n: 'moneybag', c: 'objects', e: '💰', d: 'money bag', u: '6.0' },
  { n: 'yen', c: 'objects', e: '💴', d: 'banknote with yen sign', u: '6.0' },
  { n: 'dollar', c: 'objects', e: '💵', d: 'banknote with dollar sign', u: '6.0' },
  { n: 'euro', c: 'objects', e: '💶', d: 'banknote with euro sign', u: '6.0' },
  { n: 'pound', c: 'objects', e: '💷', d: 'banknote with pound sign', u: '6.0' },
  { n: 'money_with_wings', c: 'objects', e: '💸', d: 'money with wings', u: '6.0' },
  { n: 'credit_card', c: 'objects', e: '💳', d: 'credit card', u: '6.0' },
  { n: 'chart', c: 'symbols', e: '💹', d: 'chart with upwards trend and yen sign', u: '6.0' },
  { n: 'envelope', c: 'objects', e: '✉', d: 'envelope', u: '1.1' },
  { n: 'e-mail', c: 'objects', e: '📧', d: 'e-mail symbol', u: '6.0' },
  { n: 'incoming_envelope', c: 'objects', e: '📨', d: 'incoming envelope', u: '6.0' },
  { n: 'envelope_with_arrow', c: 'objects', e: '📩', d: 'envelope with downwards arrow above', u: '6.0' },
  { n: 'outbox_tray', c: 'objects', e: '📤', d: 'outbox tray', u: '6.0' },
  { n: 'inbox_tray', c: 'objects', e: '📥', d: 'inbox tray', u: '6.0' },
  { n: 'package', c: 'objects', e: '📦', d: 'package', u: '6.0' },
  { n: 'mailbox', c: 'objects', e: '📫', d: 'closed mailbox with raised flag', u: '6.0' },
  { n: 'mailbox_closed', c: 'objects', e: '📪', d: 'closed mailbox with lowered flag', u: '6.0' },
  { n: 'mailbox_with_mail', c: 'objects', e: '📬', d: 'open mailbox with raised flag', u: '6.0' },
  { n: 'mailbox_with_no_mail', c: 'objects', e: '📭', d: 'open mailbox with lowered flag', u: '6.0' },
  { n: 'postbox', c: 'objects', e: '📮', d: 'postbox', u: '6.0' },
  { n: 'ballot_box', c: 'objects', e: '🗳', d: 'ballot box with ballot', u: '7.0' },
  { n: 'pencil2', c: 'objects', e: '✏', d: 'pencil', u: '1.1' },
  { n: 'black_nib', c: 'objects', e: '✒', d: 'black nib', u: '1.1' },
  { n: 'pen_fountain', c: 'objects', e: '🖋', d: 'lower left fountain pen', u: '7.0' },
  { n: 'pen_ballpoint', c: 'objects', e: '🖊', d: 'lower left ballpoint pen', u: '7.0' },
  { n: 'paintbrush', c: 'objects', e: '🖌', d: 'lower left paintbrush', u: '7.0' },
  { n: 'crayon', c: 'objects', e: '🖍', d: 'lower left crayon', u: '7.0' },
  { n: 'pencil', c: 'objects', e: '📝', d: 'memo', u: '6.0' },
  { n: 'briefcase', c: 'people', e: '💼', d: 'briefcase', u: '6.0' },
  { n: 'file_folder', c: 'objects', e: '📁', d: 'file folder', u: '6.0' },
  { n: 'open_file_folder', c: 'objects', e: '📂', d: 'open file folder', u: '6.0' },
  { n: 'dividers', c: 'objects', e: '🗂', d: 'card index dividers', u: '7.0' },
  { n: 'date', c: 'objects', e: '📅', d: 'calendar', u: '6.0' },
  { n: 'calendar', c: 'objects', e: '📆', d: 'tear-off calendar', u: '6.0' },
  { n: 'notepad_spiral', c: 'objects', e: '🗒', d: 'spiral note pad', u: '7.0' },
  { n: 'calendar_spiral', c: 'objects', e: '🗓', d: 'spiral calendar pad', u: '7.0' },
  { n: 'card_index', c: 'objects', e: '📇', d: 'card index', u: '6.0' },
  { n: 'chart_with_upwards_trend', c: 'objects', e: '📈', d: 'chart with upwards trend', u: '6.0' },
  { n: 'chart_with_downwards_trend', c: 'objects', e: '📉', d: 'chart with downwards trend', u: '6.0' },
  { n: 'bar_chart', c: 'objects', e: '📊', d: 'bar chart', u: '6.0' },
  { n: 'clipboard', c: 'objects', e: '📋', d: 'clipboard', u: '6.0' },
  { n: 'pushpin', c: 'objects', e: '📌', d: 'pushpin', u: '6.0' },
  { n: 'round_pushpin', c: 'objects', e: '📍', d: 'round pushpin', u: '6.0' },
  { n: 'paperclip', c: 'objects', e: '📎', d: 'paperclip', u: '6.0' },
  { n: 'paperclips', c: 'objects', e: '🖇', d: 'linked paperclips', u: '7.0' },
  { n: 'straight_ruler', c: 'objects', e: '📏', d: 'straight ruler', u: '6.0' },
  { n: 'triangular_ruler', c: 'objects', e: '📐', d: 'triangular ruler', u: '6.0' },
  { n: 'scissors', c: 'objects', e: '✂', d: 'black scissors', u: '1.1' },
  { n: 'card_box', c: 'objects', e: '🗃', d: 'card file box', u: '7.0' },
  { n: 'file_cabinet', c: 'objects', e: '🗄', d: 'file cabinet', u: '7.0' },
  { n: 'wastebasket', c: 'objects', e: '🗑', d: 'wastebasket', u: '7.0' },
  { n: 'lock', c: 'objects', e: '🔒', d: 'lock', u: '6.0' },
  { n: 'unlock', c: 'objects', e: '🔓', d: 'open lock', u: '6.0' },
  { n: 'lock_with_ink_pen', c: 'objects', e: '🔏', d: 'lock with ink pen', u: '6.0' },
  { n: 'closed_lock_with_key', c: 'objects', e: '🔐', d: 'closed lock with key', u: '6.0' },
  { n: 'key', c: 'objects', e: '🔑', d: 'key', u: '6.0' },
  { n: 'key2', c: 'objects', e: '🗝', d: 'old key', u: '7.0' },
  { n: 'hammer', c: 'objects', e: '🔨', d: 'hammer', u: '6.0' },
  { n: 'pick', c: 'objects', e: '⛏', d: 'pick', u: '5.2' },
  { n: 'hammer_pick', c: 'objects', e: '⚒', d: 'hammer and pick', u: '4.1' },
  { n: 'tools', c: 'objects', e: '🛠', d: 'hammer and wrench', u: '7.0' },
  { n: 'dagger', c: 'objects', e: '🗡', d: 'dagger knife', u: '7.0' },
  { n: 'crossed_swords', c: 'objects', e: '⚔', d: 'crossed swords', u: '4.1' },
  { n: 'bomb', c: 'objects', e: '💣', d: 'bomb', u: '6.0' },
  { n: 'bow_and_arrow', c: 'activity', e: '🏹', d: 'bow and arrow', u: '8.0' },
  { n: 'shield', c: 'objects', e: '🛡', d: 'shield', u: '7.0' },
  { n: 'wrench', c: 'objects', e: '🔧', d: 'wrench', u: '6.0' },
  { n: 'nut_and_bolt', c: 'objects', e: '🔩', d: 'nut and bolt', u: '6.0' },
  { n: 'gear', c: 'objects', e: '⚙', d: 'gear', u: '4.1' },
  { n: 'compression', c: 'objects', e: '🗜', d: 'compression', u: '7.0' },
  { n: 'scales', c: 'objects', e: '⚖', d: 'scales', u: '4.1' },
  { n: 'link', c: 'objects', e: '🔗', d: 'link symbol', u: '6.0' },
  { n: 'chains', c: 'objects', e: '⛓', d: 'chains', u: '5.2' },
  { n: 'alembic', c: 'objects', e: '⚗', d: 'alembic', u: '4.1' },
  { n: 'microscope', c: 'objects', e: '🔬', d: 'microscope', u: '6.0' },
  { n: 'telescope', c: 'objects', e: '🔭', d: 'telescope', u: '6.0' },
  { n: 'satellite', c: 'objects', e: '📡', d: 'satellite antenna', u: '6.0' },
  { n: 'syringe', c: 'objects', e: '💉', d: 'syringe', u: '6.0' },
  { n: 'pill', c: 'objects', e: '💊', d: 'pill', u: '6.0' },
  { n: 'door', c: 'objects', e: '🚪', d: 'door', u: '6.0' },
  { n: 'bed', c: 'objects', e: '🛏', d: 'bed', u: '7.0' },
  { n: 'couch', c: 'objects', e: '🛋', d: 'couch and lamp', u: '7.0' },
  { n: 'toilet', c: 'objects', e: '🚽', d: 'toilet', u: '6.0' },
  { n: 'shower', c: 'objects', e: '🚿', d: 'shower', u: '6.0' },
  { n: 'bathtub', c: 'objects', e: '🛁', d: 'bathtub', u: '6.0' },
  { n: 'shopping_cart', c: 'objects', e: '🛒', d: 'shopping trolley', u: '9.0' },
  { n: 'smoking', c: 'objects', e: '🚬', d: 'smoking symbol', u: '6.0' },
  { n: 'coffin', c: 'objects', e: '⚰', d: 'coffin', u: '4.1' },
  { n: 'urn', c: 'objects', e: '⚱', d: 'funeral urn', u: '4.1' },
  { n: 'moyai', c: 'objects', e: '🗿', d: 'moyai', u: '6.0' },
  { n: 'atm', c: 'symbols', e: '🏧', d: 'automated teller machine', u: '6.0' },
  { n: 'put_litter_in_its_place', c: 'symbols', e: '🚮', d: 'put litter in its place symbol', u: '6.0' },
  { n: 'potable_water', c: 'symbols', e: '🚰', d: 'potable water symbol', u: '6.0' },
  { n: 'wheelchair', c: 'symbols', e: '♿', d: 'wheelchair symbol', u: '4.1' },
  { n: 'mens', c: 'symbols', e: '🚹', d: 'mens symbol', u: '6.0' },
  { n: 'womens', c: 'symbols', e: '🚺', d: 'womens symbol', u: '6.0' },
  { n: 'restroom', c: 'symbols', e: '🚻', d: 'restroom', u: '6.0' },
  { n: 'baby_symbol', c: 'symbols', e: '🚼', d: 'baby symbol', u: '6.0' },
  { n: 'wc', c: 'symbols', e: '🚾', d: 'water closet', u: '6.0' },
  { n: 'passport_control', c: 'symbols', e: '🛂', d: 'passport control', u: '6.0' },
  { n: 'customs', c: 'symbols', e: '🛃', d: 'customs', u: '6.0' },
  { n: 'baggage_claim', c: 'symbols', e: '🛄', d: 'baggage claim', u: '6.0' },
  { n: 'left_luggage', c: 'symbols', e: '🛅', d: 'left luggage', u: '6.0' },
  { n: 'warning', c: 'symbols', e: '⚠', d: 'warning sign', u: '4.0' },
  { n: 'children_crossing', c: 'symbols', e: '🚸', d: 'children crossing', u: '6.0' },
  { n: 'no_entry', c: 'symbols', e: '⛔', d: 'no entry', u: '5.2' },
  { n: 'no_entry_sign', c: 'symbols', e: '🚫', d: 'no entry sign', u: '6.0' },
  { n: 'no_bicycles', c: 'symbols', e: '🚳', d: 'no bicycles', u: '6.0' },
  { n: 'no_smoking', c: 'symbols', e: '🚭', d: 'no smoking symbol', u: '6.0' },
  { n: 'do_not_litter', c: 'symbols', e: '🚯', d: 'do not litter symbol', u: '6.0' },
  { n: 'non-potable_water', c: 'symbols', e: '🚱', d: 'non-potable water symbol', u: '6.0' },
  { n: 'no_pedestrians', c: 'symbols', e: '🚷', d: 'no pedestrians', u: '6.0' },
  { n: 'no_mobile_phones', c: 'symbols', e: '📵', d: 'no mobile phones', u: '6.0' },
  { n: 'underage', c: 'symbols', e: '🔞', d: 'no one under eighteen symbol', u: '6.0' },
  { n: 'radioactive', c: 'symbols', e: '☢', d: 'radioactive sign', u: '1.1' },
  { n: 'biohazard', c: 'symbols', e: '☣', d: 'biohazard sign', u: '1.1' },
  { n: 'arrow_up', c: 'symbols', e: '⬆', d: 'upwards black arrow', u: '4.0' },
  { n: 'arrow_upper_right', c: 'symbols', e: '↗', d: 'north east arrow', u: '1.1' },
  { n: 'arrow_right', c: 'symbols', e: '➡', d: 'black rightwards arrow', u: '1.1' },
  { n: 'arrow_lower_right', c: 'symbols', e: '↘', d: 'south east arrow', u: '1.1' },
  { n: 'arrow_down', c: 'symbols', e: '⬇', d: 'downwards black arrow', u: '4.0' },
  { n: 'arrow_lower_left', c: 'symbols', e: '↙', d: 'south west arrow', u: '1.1' },
  { n: 'arrow_left', c: 'symbols', e: '⬅', d: 'leftwards black arrow', u: '4.0' },
  { n: 'arrow_upper_left', c: 'symbols', e: '↖', d: 'north west arrow', u: '1.1' },
  { n: 'arrow_up_down', c: 'symbols', e: '↕', d: 'up down arrow', u: '1.1' },
  { n: 'left_right_arrow', c: 'symbols', e: '↔', d: 'left right arrow', u: '1.1' },
  { n: 'leftwards_arrow_with_hook', c: 'symbols', e: '↩', d: 'leftwards arrow with hook', u: '1.1' },
  { n: 'arrow_right_hook', c: 'symbols', e: '↪', d: 'rightwards arrow with hook', u: '1.1' },
  { n: 'arrow_heading_up', c: 'symbols', e: '⤴', d: 'arrow pointing rightwards then curving upwards', u: '3.2' },
  { n: 'arrow_heading_down', c: 'symbols', e: '⤵', d: 'arrow pointing rightwards then curving downwards', u: '3.2' },
  { n: 'arrows_clockwise', c: 'symbols', e: '🔃', d: 'clockwise downwards and upwards open circle arrows', u: '6.0' },
  { n: 'arrows_counterclockwise', c: 'symbols', e: '🔄', d: 'anticlockwise downwards and upwards open circle ar', u: '6.0' },
  { n: 'back', c: 'symbols', e: '🔙', d: 'back with leftwards arrow above', u: '6.0' },
  { n: 'end', c: 'symbols', e: '🔚', d: 'end with leftwards arrow above', u: '6.0' },
  { n: 'on', c: 'symbols', e: '🔛', d: 'on with exclamation mark with left right arrow abo', u: '6.0' },
  { n: 'soon', c: 'symbols', e: '🔜', d: 'soon with rightwards arrow above', u: '6.0' },
  { n: 'top', c: 'symbols', e: '🔝', d: 'top with upwards arrow above', u: '6.0' },
  { n: 'place_of_worship', c: 'symbols', e: '🛐', d: 'place of worship', u: '8.0' },
  { n: 'atom', c: 'symbols', e: '⚛', d: 'atom symbol', u: '4.1' },
  { n: 'om_symbol', c: 'symbols', e: '🕉', d: 'om symbol', u: '7.0' },
  { n: 'star_of_david', c: 'symbols', e: '✡', d: 'star of david', u: '1.1' },
  { n: 'wheel_of_dharma', c: 'symbols', e: '☸', d: 'wheel of dharma', u: '1.1' },
  { n: 'yin_yang', c: 'symbols', e: '☯', d: 'yin yang', u: '1.1' },
  { n: 'cross', c: 'symbols', e: '✝', d: 'latin cross', u: '1.1' },
  { n: 'orthodox_cross', c: 'symbols', e: '☦', d: 'orthodox cross', u: '1.1' },
  { n: 'star_and_crescent', c: 'symbols', e: '☪', d: 'star and crescent', u: '1.1' },
  { n: 'peace', c: 'symbols', e: '☮', d: 'peace symbol', u: '1.1' },
  { n: 'menorah', c: 'symbols', e: '🕎', d: 'menorah with nine branches', u: '8.0' },
  { n: 'six_pointed_star', c: 'symbols', e: '🔯', d: 'six pointed star with middle dot', u: '6.0' },
  { n: 'aries', c: 'symbols', e: '♈', d: 'aries', u: '1.1' },
  { n: 'taurus', c: 'symbols', e: '♉', d: 'taurus', u: '1.1' },
  { n: 'gemini', c: 'symbols', e: '♊', d: 'gemini', u: '1.1' },
  { n: 'cancer', c: 'symbols', e: '♋', d: 'cancer', u: '1.1' },
  { n: 'leo', c: 'symbols', e: '♌', d: 'leo', u: '1.1' },
  { n: 'virgo', c: 'symbols', e: '♍', d: 'virgo', u: '1.1' },
  { n: 'libra', c: 'symbols', e: '♎', d: 'libra', u: '1.1' },
  { n: 'scorpius', c: 'symbols', e: '♏', d: 'scorpius', u: '1.1' },
  { n: 'sagittarius', c: 'symbols', e: '♐', d: 'sagittarius', u: '1.1' },
  { n: 'capricorn', c: 'symbols', e: '♑', d: 'capricorn', u: '1.1' },
  { n: 'aquarius', c: 'symbols', e: '♒', d: 'aquarius', u: '1.1' },
  { n: 'pisces', c: 'symbols', e: '♓', d: 'pisces', u: '1.1' },
  { n: 'ophiuchus', c: 'symbols', e: '⛎', d: 'ophiuchus', u: '6.0' },
  { n: 'twisted_rightwards_arrows', c: 'symbols', e: '🔀', d: 'twisted rightwards arrows', u: '6.0' },
  { n: 'repeat', c: 'symbols', e: '🔁', d: 'clockwise rightwards and leftwards open circle arr', u: '6.0' },
  { n: 'repeat_one', c: 'symbols', e: '🔂', d: 'clockwise rightwards and leftwards open circle arr', u: '6.0' },
  { n: 'arrow_forward', c: 'symbols', e: '▶', d: 'black right-pointing triangle', u: '1.1' },
  { n: 'fast_forward', c: 'symbols', e: '⏩', d: 'black right-pointing double triangle', u: '6.0' },
  { n: 'track_next', c: 'symbols', e: '⏭', d: 'black right-pointing double triangle with vertical bar', u: '6.0' },
  { n: 'play_pause', c: 'symbols', e: '⏯', d: 'black right-pointing double triangle with double vertical bar', u: '6.0' },
  { n: 'arrow_backward', c: 'symbols', e: '◀', d: 'black left-pointing triangle', u: '1.1' },
  { n: 'rewind', c: 'symbols', e: '⏪', d: 'black left-pointing double triangle', u: '6.0' },
  { n: 'track_previous', c: 'symbols', e: '⏮', d: 'black left-pointing double triangle with vertical bar', u: '6.0' },
  { n: 'arrow_up_small', c: 'symbols', e: '🔼', d: 'up-pointing small red triangle', u: '6.0' },
  { n: 'arrow_double_up', c: 'symbols', e: '⏫', d: 'black up-pointing double triangle', u: '6.0' },
  { n: 'arrow_down_small', c: 'symbols', e: '🔽', d: 'down-pointing small red triangle', u: '6.0' },
  { n: 'arrow_double_down', c: 'symbols', e: '⏬', d: 'black down-pointing double triangle', u: '6.0' },
  { n: 'pause_button', c: 'symbols', e: '⏸', d: 'double vertical bar', u: '7.0' },
  { n: 'stop_button', c: 'symbols', e: '⏹', d: 'black square for stop', u: '7.0' },
  { n: 'record_button', c: 'symbols', e: '⏺', d: 'black circle for record', u: '7.0' },
  { n: 'eject', c: 'symbols', e: '⏏', d: 'eject symbol', u: '4.0' },
  { n: 'cinema', c: 'symbols', e: '🎦', d: 'cinema', u: '6.0' },
  { n: 'low_brightness', c: 'symbols', e: '🔅', d: 'low brightness symbol', u: '6.0' },
  { n: 'high_brightness', c: 'symbols', e: '🔆', d: 'high brightness symbol', u: '6.0' },
  { n: 'signal_strength', c: 'symbols', e: '📶', d: 'antenna with bars', u: '6.0' },
  { n: 'vibration_mode', c: 'symbols', e: '📳', d: 'vibration mode', u: '6.0' },
  { n: 'mobile_phone_off', c: 'symbols', e: '📴', d: 'mobile phone off', u: '6.0' },
  { n: 'heavy_multiplication_x', c: 'symbols', e: '✖', d: 'heavy multiplication x', u: '1.1' },
  { n: 'heavy_plus_sign', c: 'symbols', e: '➕', d: 'heavy plus sign', u: '6.0' },
  { n: 'heavy_minus_sign', c: 'symbols', e: '➖', d: 'heavy minus sign', u: '6.0' },
  { n: 'heavy_division_sign', c: 'symbols', e: '➗', d: 'heavy division sign', u: '6.0' },
  { n: 'bangbang', c: 'symbols', e: '‼', d: 'double exclamation mark', u: '1.1' },
  { n: 'interrobang', c: 'symbols', e: '⁉', d: 'exclamation question mark', u: '3.0' },
  { n: 'question', c: 'symbols', e: '❓', d: 'black question mark ornament', u: '6.0' },
  { n: 'grey_question', c: 'symbols', e: '❔', d: 'white question mark ornament', u: '6.0' },
  { n: 'grey_exclamation', c: 'symbols', e: '❕', d: 'white exclamation mark ornament', u: '6.0' },
  { n: 'exclamation', c: 'symbols', e: '❗', d: 'heavy exclamation mark symbol', u: '5.2' },
  { n: 'wavy_dash', c: 'symbols', e: '〰', d: 'wavy dash', u: '1.1' },
  { n: 'currency_exchange', c: 'symbols', e: '💱', d: 'currency exchange', u: '6.0' },
  { n: 'heavy_dollar_sign', c: 'symbols', e: '💲', d: 'heavy dollar sign', u: '6.0' },
  { n: 'recycle', c: 'symbols', e: '♻', d: 'black universal recycling symbol', u: '3.2' },
  { n: 'fleur-de-lis', c: 'symbols', e: '⚜', d: 'fleur-de-lis', u: '4.1' },
  { n: 'trident', c: 'symbols', e: '🔱', d: 'trident emblem', u: '6.0' },
  { n: 'name_badge', c: 'symbols', e: '📛', d: 'name badge', u: '6.0' },
  { n: 'beginner', c: 'symbols', e: '🔰', d: 'japanese symbol for beginner', u: '6.0' },
  { n: 'o', c: 'symbols', e: '⭕', d: 'heavy large circle', u: '5.2' },
  { n: 'white_check_mark', c: 'symbols', e: '✅', d: 'white heavy check mark', u: '6.0' },
  { n: 'ballot_box_with_check', c: 'symbols', e: '☑', d: 'ballot box with check', u: '1.1' },
  { n: 'heavy_check_mark', c: 'symbols', e: '✔', d: 'heavy check mark', u: '1.1' },
  { n: 'x', c: 'symbols', e: '❌', d: 'cross mark', u: '6.0' },
  { n: 'negative_squared_cross_mark', c: 'symbols', e: '❎', d: 'negative squared cross mark', u: '6.0' },
  { n: 'curly_loop', c: 'symbols', e: '➰', d: 'curly loop', u: '6.0' },
  { n: 'loop', c: 'symbols', e: '➿', d: 'double curly loop', u: '6.0' },
  { n: 'part_alternation_mark', c: 'symbols', e: '〽', d: 'part alternation mark', u: '3.2' },
  { n: 'eight_spoked_asterisk', c: 'symbols', e: '✳', d: 'eight spoked asterisk', u: '1.1' },
  { n: 'eight_pointed_black_star', c: 'symbols', e: '✴', d: 'eight pointed black star', u: '1.1' },
  { n: 'sparkle', c: 'symbols', e: '❇', d: 'sparkle', u: '1.1' },
  { n: 'copyright', c: 'symbols', e: '©️', d: 'copyright sign', u: '1.1' },
  { n: 'registered', c: 'symbols', e: '®️', d: 'registered sign', u: '1.1' },
  { n: 'tm', c: 'symbols', e: '™️', d: 'trade mark sign', u: '1.1' },
  { n: 'hash', c: 'symbols', e: '#⃣', d: 'number sign', u: '3.0' },
  { n: 'asterisk', c: 'symbols', e: '*⃣', d: 'keycap asterisk', u: '3.0' },
  { n: 'zero', c: 'symbols', e: '0️⃣', d: 'keycap digit zero', u: '3.0' },
  { n: 'one', c: 'symbols', e: '1️⃣', d: 'keycap digit one', u: '3.0' },
  { n: 'two', c: 'symbols', e: '2️⃣', d: 'keycap digit two', u: '3.0' },
  { n: 'three', c: 'symbols', e: '3️⃣', d: 'keycap digit three', u: '3.0' },
  { n: 'four', c: 'symbols', e: '4️⃣', d: 'keycap digit four', u: '3.0' },
  { n: 'five', c: 'symbols', e: '5️⃣', d: 'keycap digit five', u: '3.0' },
  { n: 'six', c: 'symbols', e: '6️⃣', d: 'keycap digit six', u: '3.0' },
  { n: 'seven', c: 'symbols', e: '7️⃣', d: 'keycap digit seven', u: '3.0' },
  { n: 'eight', c: 'symbols', e: '8️⃣', d: 'keycap digit eight', u: '3.0' },
  { n: 'nine', c: 'symbols', e: '9️⃣', d: 'keycap digit nine', u: '3.0' },
  { n: 'ten', c: 'symbols', e: '🔟', d: 'keycap ten', u: '6.0' },
  { n: 'capital_abcd', c: 'symbols', e: '🔠', d: 'input symbol for latin capital letters', u: '6.0' },
  { n: 'abcd', c: 'symbols', e: '🔡', d: 'input symbol for latin small letters', u: '6.0' },
  { n: '1234', c: 'symbols', e: '🔢', d: 'input symbol for numbers', u: '6.0' },
  { n: 'symbols', c: 'symbols', e: '🔣', d: 'input symbol for symbols', u: '6.0' },
  { n: 'abc', c: 'symbols', e: '🔤', d: 'input symbol for latin letters', u: '6.0' },
  { n: 'a', c: 'symbols', e: '🅰', d: 'negative squared latin capital letter a', u: '6.0' },
  { n: 'ab', c: 'symbols', e: '🆎', d: 'negative squared ab', u: '6.0' },
  { n: 'b', c: 'symbols', e: '🅱', d: 'negative squared latin capital letter b', u: '6.0' },
  { n: 'cl', c: 'symbols', e: '🆑', d: 'squared cl', u: '6.0' },
  { n: 'cool', c: 'symbols', e: '🆒', d: 'squared cool', u: '6.0' },
  { n: 'free', c: 'symbols', e: '🆓', d: 'squared free', u: '6.0' },
  { n: 'information_source', c: 'symbols', e: 'ℹ', d: 'information source', u: '3.0' },
  { n: 'id', c: 'symbols', e: '🆔', d: 'squared id', u: '6.0' },
  { n: 'm', c: 'symbols', e: 'Ⓜ', d: 'circled latin capital letter m', u: '1.1' },
  { n: 'new', c: 'symbols', e: '🆕', d: 'squared new', u: '6.0' },
  { n: 'ng', c: 'symbols', e: '🆖', d: 'squared ng', u: '6.0' },
  { n: 'o2', c: 'symbols', e: '🅾', d: 'negative squared latin capital letter o', u: '6.0' },
  { n: 'ok', c: 'symbols', e: '🆗', d: 'squared ok', u: '6.0' },
  { n: 'parking', c: 'symbols', e: '🅿', d: 'negative squared latin capital letter p', u: '5.2' },
  { n: 'sos', c: 'symbols', e: '🆘', d: 'squared sos', u: '6.0' },
  { n: 'up', c: 'symbols', e: '🆙', d: 'squared up with exclamation mark', u: '6.0' },
  { n: 'vs', c: 'symbols', e: '🆚', d: 'squared vs', u: '6.0' },
  { n: 'koko', c: 'symbols', e: '🈁', d: 'squared katakana koko', u: '6.0' },
  { n: 'sa', c: 'symbols', e: '🈂', d: 'squared katakana sa', u: '6.0' },
  { n: 'u6708', c: 'symbols', e: '🈷', d: 'squared cjk unified ideograph-6708', u: '6.0' },
  { n: 'u6709', c: 'symbols', e: '🈶', d: 'squared cjk unified ideograph-6709', u: '6.0' },
  { n: 'u6307', c: 'symbols', e: '🈯', d: 'squared cjk unified ideograph-6307', u: '5.2' },
  { n: 'ideograph_advantage', c: 'symbols', e: '🉐', d: 'circled ideograph advantage', u: '6.0' },
  { n: 'u5272', c: 'symbols', e: '🈹', d: 'squared cjk unified ideograph-5272', u: '6.0' },
  { n: 'u7121', c: 'symbols', e: '🈚', d: 'squared cjk unified ideograph-7121', u: '5.2' },
  { n: 'u7981', c: 'symbols', e: '🈲', d: 'squared cjk unified ideograph-7981', u: '6.0' },
  { n: 'accept', c: 'symbols', e: '🉑', d: 'circled ideograph accept', u: '6.0' },
  { n: 'u7533', c: 'symbols', e: '🈸', d: 'squared cjk unified ideograph-7533', u: '6.0' },
  { n: 'u5408', c: 'symbols', e: '🈴', d: 'squared cjk unified ideograph-5408', u: '6.0' },
  { n: 'u7a7a', c: 'symbols', e: '🈳', d: 'squared cjk unified ideograph-7a7a', u: '6.0' },
  { n: 'congratulations', c: 'symbols', e: '㊗', d: 'circled ideograph congratulation', u: '1.1' },
  { n: 'secret', c: 'symbols', e: '㊙', d: 'circled ideograph secret', u: '1.1' },
  { n: 'u55b6', c: 'symbols', e: '🈺', d: 'squared cjk unified ideograph-55b6', u: '6.0' },
  { n: 'u6e80', c: 'symbols', e: '🈵', d: 'squared cjk unified ideograph-6e80', u: '6.0' },
  { n: 'red_circle', c: 'symbols', e: '🔴', d: 'large red circle', u: '6.0' },
  { n: 'large_blue_circle', c: 'symbols', e: '🔵', d: 'large blue circle', u: '6.0' },
  { n: 'black_circle', c: 'symbols', e: '⚫', d: 'medium black circle', u: '4.1' },
  { n: 'white_circle', c: 'symbols', e: '⚪', d: 'medium white circle', u: '4.1' },
  { n: 'black_large_square', c: 'symbols', e: '⬛', d: 'black large square', u: '5.1' },
  { n: 'white_large_square', c: 'symbols', e: '⬜', d: 'white large square', u: '5.1' },
  { n: 'black_medium_square', c: 'symbols', e: '◼', d: 'black medium square', u: '3.2' },
  { n: 'white_medium_square', c: 'symbols', e: '◻', d: 'white medium square', u: '3.2' },
  { n: 'black_medium_small_square', c: 'symbols', e: '◾', d: 'black medium small square', u: '3.2' },
  { n: 'white_medium_small_square', c: 'symbols', e: '◽', d: 'white medium small square', u: '3.2' },
  { n: 'black_small_square', c: 'symbols', e: '▪', d: 'black small square', u: '1.1' },
  { n: 'white_small_square', c: 'symbols', e: '▫', d: 'white small square', u: '1.1' },
  { n: 'large_orange_diamond', c: 'symbols', e: '🔶', d: 'large orange diamond', u: '6.0' },
  { n: 'large_blue_diamond', c: 'symbols', e: '🔷', d: 'large blue diamond', u: '6.0' },
  { n: 'small_orange_diamond', c: 'symbols', e: '🔸', d: 'small orange diamond', u: '6.0' },
  { n: 'small_blue_diamond', c: 'symbols', e: '🔹', d: 'small blue diamond', u: '6.0' },
  { n: 'small_red_triangle', c: 'symbols', e: '🔺', d: 'up-pointing red triangle', u: '6.0' },
  { n: 'small_red_triangle_down', c: 'symbols', e: '🔻', d: 'down-pointing red triangle', u: '6.0' },
  { n: 'diamond_shape_with_a_dot_inside', c: 'symbols', e: '💠', d: 'diamond shape with a dot inside', u: '6.0' },
  { n: 'radio_button', c: 'symbols', e: '🔘', d: 'radio button', u: '6.0' },
  { n: 'white_square_button', c: 'symbols', e: '🔳', d: 'white square button', u: '6.0' },
  { n: 'black_square_button', c: 'symbols', e: '🔲', d: 'black square button', u: '6.0' },
  { n: 'checkered_flag', c: 'travel', e: '🏁', d: 'chequered flag', u: '6.0' },
  { n: 'triangular_flag_on_post', c: 'objects', e: '🚩', d: 'triangular flag on post', u: '6.0' },
  { n: 'crossed_flags', c: 'objects', e: '🎌', d: 'crossed flags', u: '6.0' },
  { n: 'flag_black', c: 'objects', e: '🏴', d: 'waving black flag', u: '7.0' },
  { n: 'flag_white', c: 'objects', e: '🏳', d: 'waving white flag', u: '7.0' },
  { n: 'gay_pride_flag', c: 'flags', e: '🏳️‍🌈', d: 'gay_pride_flag', u: '7.0' },
  { n: 'flag_ac', c: 'flags', e: '🇦🇨', d: 'ascension', u: '6.0' },
  { n: 'flag_ad', c: 'flags', e: '🇦🇩', d: 'andorra', u: '6.0' },
  { n: 'flag_ae', c: 'flags', e: '🇦🇪', d: 'the united arab emirates', u: '6.0' },
  { n: 'flag_af', c: 'flags', e: '🇦🇫', d: 'afghanistan', u: '6.0' },
  { n: 'flag_ag', c: 'flags', e: '🇦🇬', d: 'antigua and barbuda', u: '6.0' },
  { n: 'flag_ai', c: 'flags', e: '🇦🇮', d: 'anguilla', u: '6.0' },
  { n: 'flag_al', c: 'flags', e: '🇦🇱', d: 'albania', u: '6.0' },
  { n: 'flag_am', c: 'flags', e: '🇦🇲', d: 'armenia', u: '6.0' },
  { n: 'flag_ao', c: 'flags', e: '🇦🇴', d: 'angola', u: '6.0' },
  { n: 'flag_aq', c: 'flags', e: '🇦🇶', d: 'antarctica', u: '6.0' },
  { n: 'flag_ar', c: 'flags', e: '🇦🇷', d: 'argentina', u: '6.0' },
  { n: 'flag_as', c: 'flags', e: '🇦🇸', d: 'american samoa', u: '6.0' },
  { n: 'flag_at', c: 'flags', e: '🇦🇹', d: 'austria', u: '6.0' },
  { n: 'flag_au', c: 'flags', e: '🇦🇺', d: 'australia', u: '6.0' },
  { n: 'flag_aw', c: 'flags', e: '🇦🇼', d: 'aruba', u: '6.0' },
  { n: 'flag_ax', c: 'flags', e: '🇦🇽', d: 'åland islands', u: '6.0' },
  { n: 'flag_az', c: 'flags', e: '🇦🇿', d: 'azerbaijan', u: '6.0' },
  { n: 'flag_ba', c: 'flags', e: '🇧🇦', d: 'bosnia and herzegovina', u: '6.0' },
  { n: 'flag_bb', c: 'flags', e: '🇧🇧', d: 'barbados', u: '6.0' },
  { n: 'flag_bd', c: 'flags', e: '🇧🇩', d: 'bangladesh', u: '6.0' },
  { n: 'flag_be', c: 'flags', e: '🇧🇪', d: 'belgium', u: '6.0' },
  { n: 'flag_bf', c: 'flags', e: '🇧🇫', d: 'burkina faso', u: '6.0' },
  { n: 'flag_bg', c: 'flags', e: '🇧🇬', d: 'bulgaria', u: '6.0' },
  { n: 'flag_bh', c: 'flags', e: '🇧🇭', d: 'bahrain', u: '6.0' },
  { n: 'flag_bi', c: 'flags', e: '🇧🇮', d: 'burundi', u: '6.0' },
  { n: 'flag_bj', c: 'flags', e: '🇧🇯', d: 'benin', u: '6.0' },
  { n: 'flag_bl', c: 'flags', e: '🇧🇱', d: 'saint barthélemy', u: '6.0' },
  { n: 'flag_bm', c: 'flags', e: '🇧🇲', d: 'bermuda', u: '6.0' },
  { n: 'flag_bn', c: 'flags', e: '🇧🇳', d: 'brunei', u: '6.0' },
  { n: 'flag_bo', c: 'flags', e: '🇧🇴', d: 'bolivia', u: '6.0' },
  { n: 'flag_bq', c: 'flags', e: '🇧🇶', d: 'caribbean netherlands', u: '6.0' },
  { n: 'flag_br', c: 'flags', e: '🇧🇷', d: 'brazil', u: '6.0' },
  { n: 'flag_bs', c: 'flags', e: '🇧🇸', d: 'the bahamas', u: '6.0' },
  { n: 'flag_bt', c: 'flags', e: '🇧🇹', d: 'bhutan', u: '6.0' },
  { n: 'flag_bv', c: 'flags', e: '🇧🇻', d: 'bouvet island', u: '6.0' },
  { n: 'flag_bw', c: 'flags', e: '🇧🇼', d: 'botswana', u: '6.0' },
  { n: 'flag_by', c: 'flags', e: '🇧🇾', d: 'belarus', u: '6.0' },
  { n: 'flag_bz', c: 'flags', e: '🇧🇿', d: 'belize', u: '6.0' },
  { n: 'flag_ca', c: 'flags', e: '🇨🇦', d: 'canada', u: '6.0' },
  { n: 'flag_cc', c: 'flags', e: '🇨🇨', d: 'cocos (keeling) islands', u: '6.0' },
  { n: 'flag_cd', c: 'flags', e: '🇨🇩', d: 'the democratic republic of the congo', u: '6.0' },
  { n: 'flag_cf', c: 'flags', e: '🇨🇫', d: 'central african republic', u: '6.0' },
  { n: 'flag_cg', c: 'flags', e: '🇨🇬', d: 'the republic of the congo', u: '6.0' },
  { n: 'flag_ch', c: 'flags', e: '🇨🇭', d: 'switzerland', u: '6.0' },
  { n: 'flag_ci', c: 'flags', e: '🇨🇮', d: "cote d'ivoire", u: '6.0' },
  { n: 'flag_ck', c: 'flags', e: '🇨🇰', d: 'cook islands', u: '6.0' },
  { n: 'flag_cl', c: 'flags', e: '🇨🇱', d: 'chile', u: '6.0' },
  { n: 'flag_cm', c: 'flags', e: '🇨🇲', d: 'cameroon', u: '6.0' },
  { n: 'flag_cn', c: 'flags', e: '🇨🇳', d: 'china', u: '6.0' },
  { n: 'flag_co', c: 'flags', e: '🇨🇴', d: 'colombia', u: '6.0' },
  { n: 'flag_cp', c: 'flags', e: '🇨🇵', d: 'clipperton island', u: '6.0' },
  { n: 'flag_cr', c: 'flags', e: '🇨🇷', d: 'costa rica', u: '6.0' },
  { n: 'flag_cu', c: 'flags', e: '🇨🇺', d: 'cuba', u: '6.0' },
  { n: 'flag_cv', c: 'flags', e: '🇨🇻', d: 'cape verde', u: '6.0' },
  { n: 'flag_cw', c: 'flags', e: '🇨🇼', d: 'curaçao', u: '6.0' },
  { n: 'flag_cx', c: 'flags', e: '🇨🇽', d: 'christmas island', u: '6.0' },
  { n: 'flag_cy', c: 'flags', e: '🇨🇾', d: 'cyprus', u: '6.0' },
  { n: 'flag_cz', c: 'flags', e: '🇨🇿', d: 'the czech republic', u: '6.0' },
  { n: 'flag_de', c: 'flags', e: '🇩🇪', d: 'germany', u: '6.0' },
  { n: 'flag_dg', c: 'flags', e: '🇩🇬', d: 'diego garcia', u: '6.0' },
  { n: 'flag_dj', c: 'flags', e: '🇩🇯', d: 'djibouti', u: '6.0' },
  { n: 'flag_dk', c: 'flags', e: '🇩🇰', d: 'denmark', u: '6.0' },
  { n: 'flag_dm', c: 'flags', e: '🇩🇲', d: 'dominica', u: '6.0' },
  { n: 'flag_do', c: 'flags', e: '🇩🇴', d: 'the dominican republic', u: '6.0' },
  { n: 'flag_dz', c: 'flags', e: '🇩🇿', d: 'algeria', u: '6.0' },
  { n: 'flag_ea', c: 'flags', e: '🇪🇦', d: 'ceuta, melilla', u: '6.0' },
  { n: 'flag_ec', c: 'flags', e: '🇪🇨', d: 'ecuador', u: '6.0' },
  { n: 'flag_ee', c: 'flags', e: '🇪🇪', d: 'estonia', u: '6.0' },
  { n: 'flag_eg', c: 'flags', e: '🇪🇬', d: 'egypt', u: '6.0' },
  { n: 'flag_eh', c: 'flags', e: '🇪🇭', d: 'western sahara', u: '6.0' },
  { n: 'flag_er', c: 'flags', e: '🇪🇷', d: 'eritrea', u: '6.0' },
  { n: 'flag_es', c: 'flags', e: '🇪🇸', d: 'spain', u: '6.0' },
  { n: 'flag_et', c: 'flags', e: '🇪🇹', d: 'ethiopia', u: '6.0' },
  { n: 'flag_eu', c: 'flags', e: '🇪🇺', d: 'european union', u: '6.0' },
  { n: 'flag_fi', c: 'flags', e: '🇫🇮', d: 'finland', u: '6.0' },
  { n: 'flag_fj', c: 'flags', e: '🇫🇯', d: 'fiji', u: '6.0' },
  { n: 'flag_fk', c: 'flags', e: '🇫🇰', d: 'falkland islands', u: '6.0' },
  { n: 'flag_fm', c: 'flags', e: '🇫🇲', d: 'micronesia', u: '6.0' },
  { n: 'flag_fo', c: 'flags', e: '🇫🇴', d: 'faroe islands', u: '6.0' },
  { n: 'flag_fr', c: 'flags', e: '🇫🇷', d: 'france', u: '6.0' },
  { n: 'flag_ga', c: 'flags', e: '🇬🇦', d: 'gabon', u: '6.0' },
  { n: 'flag_gb', c: 'flags', e: '🇬🇧', d: 'great britain', u: '6.0' },
  { n: 'flag_gd', c: 'flags', e: '🇬🇩', d: 'grenada', u: '6.0' },
  { n: 'flag_ge', c: 'flags', e: '🇬🇪', d: 'georgia', u: '6.0' },
  { n: 'flag_gf', c: 'flags', e: '🇬🇫', d: 'french guiana', u: '6.0' },
  { n: 'flag_gg', c: 'flags', e: '🇬🇬', d: 'guernsey', u: '6.0' },
  { n: 'flag_gh', c: 'flags', e: '🇬🇭', d: 'ghana', u: '6.0' },
  { n: 'flag_gi', c: 'flags', e: '🇬🇮', d: 'gibraltar', u: '6.0' },
  { n: 'flag_gl', c: 'flags', e: '🇬🇱', d: 'greenland', u: '6.0' },
  { n: 'flag_gm', c: 'flags', e: '🇬🇲', d: 'the gambia', u: '6.0' },
  { n: 'flag_gn', c: 'flags', e: '🇬🇳', d: 'guinea', u: '6.0' },
  { n: 'flag_gp', c: 'flags', e: '🇬🇵', d: 'guadeloupe', u: '6.0' },
  { n: 'flag_gq', c: 'flags', e: '🇬🇶', d: 'equatorial guinea', u: '6.0' },
  { n: 'flag_gr', c: 'flags', e: '🇬🇷', d: 'greece', u: '6.0' },
  { n: 'flag_gs', c: 'flags', e: '🇬🇸', d: 'south georgia', u: '6.0' },
  { n: 'flag_gt', c: 'flags', e: '🇬🇹', d: 'guatemala', u: '6.0' },
  { n: 'flag_gu', c: 'flags', e: '🇬🇺', d: 'guam', u: '6.0' },
  { n: 'flag_gw', c: 'flags', e: '🇬🇼', d: 'guinea-bissau', u: '6.0' },
  { n: 'flag_gy', c: 'flags', e: '🇬🇾', d: 'guyana', u: '6.0' },
  { n: 'flag_hk', c: 'flags', e: '🇭🇰', d: 'hong kong', u: '6.0' },
  { n: 'flag_hm', c: 'flags', e: '🇭🇲', d: 'heard island and mcdonald islands', u: '6.0' },
  { n: 'flag_hn', c: 'flags', e: '🇭🇳', d: 'honduras', u: '6.0' },
  { n: 'flag_hr', c: 'flags', e: '🇭🇷', d: 'croatia', u: '6.0' },
  { n: 'flag_ht', c: 'flags', e: '🇭🇹', d: 'haiti', u: '6.0' },
  { n: 'flag_hu', c: 'flags', e: '🇭🇺', d: 'hungary', u: '6.0' },
  { n: 'flag_ic', c: 'flags', e: '🇮🇨', d: 'canary islands', u: '6.0' },
  { n: 'flag_id', c: 'flags', e: '🇮🇩', d: 'indonesia', u: '6.0' },
  { n: 'flag_ie', c: 'flags', e: '🇮🇪', d: 'ireland', u: '6.0' },
  { n: 'flag_il', c: 'flags', e: '🇮🇱', d: 'israel', u: '6.0' },
  { n: 'flag_im', c: 'flags', e: '🇮🇲', d: 'isle of man', u: '6.0' },
  { n: 'flag_in', c: 'flags', e: '🇮🇳', d: 'india', u: '6.0' },
  { n: 'flag_io', c: 'flags', e: '🇮🇴', d: 'british indian ocean territory', u: '6.0' },
  { n: 'flag_iq', c: 'flags', e: '🇮🇶', d: 'iraq', u: '6.0' },
  { n: 'flag_ir', c: 'flags', e: '🇮🇷', d: 'iran', u: '6.0' },
  { n: 'flag_is', c: 'flags', e: '🇮🇸', d: 'iceland', u: '6.0' },
  { n: 'flag_it', c: 'flags', e: '🇮🇹', d: 'italy', u: '6.0' },
  { n: 'flag_je', c: 'flags', e: '🇯🇪', d: 'jersey', u: '6.0' },
  { n: 'flag_jm', c: 'flags', e: '🇯🇲', d: 'jamaica', u: '6.0' },
  { n: 'flag_jo', c: 'flags', e: '🇯🇴', d: 'jordan', u: '6.0' },
  { n: 'flag_jp', c: 'flags', e: '🇯🇵', d: 'japan', u: '6.0' },
  { n: 'flag_ke', c: 'flags', e: '🇰🇪', d: 'kenya', u: '6.0' },
  { n: 'flag_kg', c: 'flags', e: '🇰🇬', d: 'kyrgyzstan', u: '6.0' },
  { n: 'flag_kh', c: 'flags', e: '🇰🇭', d: 'cambodia', u: '6.0' },
  { n: 'flag_ki', c: 'flags', e: '🇰🇮', d: 'kiribati', u: '6.0' },
  { n: 'flag_km', c: 'flags', e: '🇰🇲', d: 'the comoros', u: '6.0' },
  { n: 'flag_kn', c: 'flags', e: '🇰🇳', d: 'saint kitts and nevis', u: '6.0' },
  { n: 'flag_kp', c: 'flags', e: '🇰🇵', d: 'north korea', u: '6.0' },
  { n: 'flag_kr', c: 'flags', e: '🇰🇷', d: 'korea', u: '6.0' },
  { n: 'flag_kw', c: 'flags', e: '🇰🇼', d: 'kuwait', u: '6.0' },
  { n: 'flag_ky', c: 'flags', e: '🇰🇾', d: 'cayman islands', u: '6.0' },
  { n: 'flag_kz', c: 'flags', e: '🇰🇿', d: 'kazakhstan', u: '6.0' },
  { n: 'flag_la', c: 'flags', e: '🇱🇦', d: 'laos', u: '6.0' },
  { n: 'flag_lb', c: 'flags', e: '🇱🇧', d: 'lebanon', u: '6.0' },
  { n: 'flag_lc', c: 'flags', e: '🇱🇨', d: 'saint lucia', u: '6.0' },
  { n: 'flag_li', c: 'flags', e: '🇱🇮', d: 'liechtenstein', u: '6.0' },
  { n: 'flag_lk', c: 'flags', e: '🇱🇰', d: 'sri lanka', u: '6.0' },
  { n: 'flag_lr', c: 'flags', e: '🇱🇷', d: 'liberia', u: '6.0' },
  { n: 'flag_ls', c: 'flags', e: '🇱🇸', d: 'lesotho', u: '6.0' },
  { n: 'flag_lt', c: 'flags', e: '🇱🇹', d: 'lithuania', u: '6.0' },
  { n: 'flag_lu', c: 'flags', e: '🇱🇺', d: 'luxembourg', u: '6.0' },
  { n: 'flag_lv', c: 'flags', e: '🇱🇻', d: 'latvia', u: '6.0' },
  { n: 'flag_ly', c: 'flags', e: '🇱🇾', d: 'libya', u: '6.0' },
  { n: 'flag_ma', c: 'flags', e: '🇲🇦', d: 'morocco', u: '6.0' },
  { n: 'flag_mc', c: 'flags', e: '🇲🇨', d: 'monaco', u: '6.0' },
  { n: 'flag_md', c: 'flags', e: '🇲🇩', d: 'moldova', u: '6.0' },
  { n: 'flag_me', c: 'flags', e: '🇲🇪', d: 'montenegro', u: '6.0' },
  { n: 'flag_mf', c: 'flags', e: '🇲🇫', d: 'saint martin', u: '6.0' },
  { n: 'flag_mg', c: 'flags', e: '🇲🇬', d: 'madagascar', u: '6.0' },
  { n: 'flag_mh', c: 'flags', e: '🇲🇭', d: 'the marshall islands', u: '6.0' },
  { n: 'flag_mk', c: 'flags', e: '🇲🇰', d: 'macedonia', u: '6.0' },
  { n: 'flag_ml', c: 'flags', e: '🇲🇱', d: 'mali', u: '6.0' },
  { n: 'flag_mm', c: 'flags', e: '🇲🇲', d: 'myanmar', u: '6.0' },
  { n: 'flag_mn', c: 'flags', e: '🇲🇳', d: 'mongolia', u: '6.0' },
  { n: 'flag_mo', c: 'flags', e: '🇲🇴', d: 'macau', u: '6.0' },
  { n: 'flag_mp', c: 'flags', e: '🇲🇵', d: 'northern mariana islands', u: '6.0' },
  { n: 'flag_mq', c: 'flags', e: '🇲🇶', d: 'martinique', u: '6.0' },
  { n: 'flag_mr', c: 'flags', e: '🇲🇷', d: 'mauritania', u: '6.0' },
  { n: 'flag_ms', c: 'flags', e: '🇲🇸', d: 'montserrat', u: '6.0' },
  { n: 'flag_mt', c: 'flags', e: '🇲🇹', d: 'malta', u: '6.0' },
  { n: 'flag_mu', c: 'flags', e: '🇲🇺', d: 'mauritius', u: '6.0' },
  { n: 'flag_mv', c: 'flags', e: '🇲🇻', d: 'maldives', u: '6.0' },
  { n: 'flag_mw', c: 'flags', e: '🇲🇼', d: 'malawi', u: '6.0' },
  { n: 'flag_mx', c: 'flags', e: '🇲🇽', d: 'mexico', u: '6.0' },
  { n: 'flag_my', c: 'flags', e: '🇲🇾', d: 'malaysia', u: '6.0' },
  { n: 'flag_mz', c: 'flags', e: '🇲🇿', d: 'mozambique', u: '6.0' },
  { n: 'flag_na', c: 'flags', e: '🇳🇦', d: 'namibia', u: '6.0' },
  { n: 'flag_nc', c: 'flags', e: '🇳🇨', d: 'new caledonia', u: '6.0' },
  { n: 'flag_ne', c: 'flags', e: '🇳🇪', d: 'niger', u: '6.0' },
  { n: 'flag_nf', c: 'flags', e: '🇳🇫', d: 'norfolk island', u: '6.0' },
  { n: 'flag_ng', c: 'flags', e: '🇳🇬', d: 'nigeria', u: '6.0' },
  { n: 'flag_ni', c: 'flags', e: '🇳🇮', d: 'nicaragua', u: '6.0' },
  { n: 'flag_nl', c: 'flags', e: '🇳🇱', d: 'the netherlands', u: '6.0' },
  { n: 'flag_no', c: 'flags', e: '🇳🇴', d: 'norway', u: '6.0' },
  { n: 'flag_np', c: 'flags', e: '🇳🇵', d: 'nepal', u: '6.0' },
  { n: 'flag_nr', c: 'flags', e: '🇳🇷', d: 'nauru', u: '6.0' },
  { n: 'flag_nu', c: 'flags', e: '🇳🇺', d: 'niue', u: '6.0' },
  { n: 'flag_nz', c: 'flags', e: '🇳🇿', d: 'new zealand', u: '6.0' },
  { n: 'flag_om', c: 'flags', e: '🇴🇲', d: 'oman', u: '6.0' },
  { n: 'flag_pa', c: 'flags', e: '🇵🇦', d: 'panama', u: '6.0' },
  { n: 'flag_pe', c: 'flags', e: '🇵🇪', d: 'peru', u: '6.0' },
  { n: 'flag_pf', c: 'flags', e: '🇵🇫', d: 'french polynesia', u: '6.0' },
  { n: 'flag_pg', c: 'flags', e: '🇵🇬', d: 'papua new guinea', u: '6.0' },
  { n: 'flag_ph', c: 'flags', e: '🇵🇭', d: 'the philippines', u: '6.0' },
  { n: 'flag_pk', c: 'flags', e: '🇵🇰', d: 'pakistan', u: '6.0' },
  { n: 'flag_pl', c: 'flags', e: '🇵🇱', d: 'poland', u: '6.0' },
  { n: 'flag_pm', c: 'flags', e: '🇵🇲', d: 'saint pierre and miquelon', u: '6.0' },
  { n: 'flag_pn', c: 'flags', e: '🇵🇳', d: 'pitcairn', u: '6.0' },
  { n: 'flag_pr', c: 'flags', e: '🇵🇷', d: 'puerto rico', u: '6.0' },
  { n: 'flag_ps', c: 'flags', e: '🇵🇸', d: 'palestinian authority', u: '6.0' },
  { n: 'flag_pt', c: 'flags', e: '🇵🇹', d: 'portugal', u: '6.0' },
  { n: 'flag_pw', c: 'flags', e: '🇵🇼', d: 'palau', u: '6.0' },
  { n: 'flag_py', c: 'flags', e: '🇵🇾', d: 'paraguay', u: '6.0' },
  { n: 'flag_qa', c: 'flags', e: '🇶🇦', d: 'qatar', u: '6.0' },
  { n: 'flag_re', c: 'flags', e: '🇷🇪', d: 'réunion', u: '6.0' },
  { n: 'flag_ro', c: 'flags', e: '🇷🇴', d: 'romania', u: '6.0' },
  { n: 'flag_rs', c: 'flags', e: '🇷🇸', d: 'serbia', u: '6.0' },
  { n: 'flag_ru', c: 'flags', e: '🇷🇺', d: 'russia', u: '6.0' },
  { n: 'flag_rw', c: 'flags', e: '🇷🇼', d: 'rwanda', u: '6.0' },
  { n: 'flag_sa', c: 'flags', e: '🇸🇦', d: 'saudi arabia', u: '6.0' },
  { n: 'flag_sb', c: 'flags', e: '🇸🇧', d: 'the solomon islands', u: '6.0' },
  { n: 'flag_sc', c: 'flags', e: '🇸🇨', d: 'the seychelles', u: '6.0' },
  { n: 'flag_sd', c: 'flags', e: '🇸🇩', d: 'sudan', u: '6.0' },
  { n: 'flag_se', c: 'flags', e: '🇸🇪', d: 'sweden', u: '6.0' },
  { n: 'flag_sg', c: 'flags', e: '🇸🇬', d: 'singapore', u: '6.0' },
  { n: 'flag_sh', c: 'flags', e: '🇸🇭', d: 'saint helena', u: '6.0' },
  { n: 'flag_si', c: 'flags', e: '🇸🇮', d: 'slovenia', u: '6.0' },
  { n: 'flag_sj', c: 'flags', e: '🇸🇯', d: 'svalbard and jan mayen', u: '6.0' },
  { n: 'flag_sk', c: 'flags', e: '🇸🇰', d: 'slovakia', u: '6.0' },
  { n: 'flag_sl', c: 'flags', e: '🇸🇱', d: 'sierra leone', u: '6.0' },
  { n: 'flag_sm', c: 'flags', e: '🇸🇲', d: 'san marino', u: '6.0' },
  { n: 'flag_sn', c: 'flags', e: '🇸🇳', d: 'senegal', u: '6.0' },
  { n: 'flag_so', c: 'flags', e: '🇸🇴', d: 'somalia', u: '6.0' },
  { n: 'flag_sr', c: 'flags', e: '🇸🇷', d: 'suriname', u: '6.0' },
  { n: 'flag_ss', c: 'flags', e: '🇸🇸', d: 'south sudan', u: '6.0' },
  { n: 'flag_st', c: 'flags', e: '🇸🇹', d: 'sao tome and principe', u: '6.0' },
  { n: 'flag_sv', c: 'flags', e: '🇸🇻', d: 'el salvador', u: '6.0' },
  { n: 'flag_sx', c: 'flags', e: '🇸🇽', d: 'sint maarten', u: '6.0' },
  { n: 'flag_sy', c: 'flags', e: '🇸🇾', d: 'syria', u: '6.0' },
  { n: 'flag_sz', c: 'flags', e: '🇸🇿', d: 'swaziland', u: '6.0' },
  { n: 'flag_ta', c: 'flags', e: '🇹🇦', d: 'tristan da cunha', u: '6.0' },
  { n: 'flag_tc', c: 'flags', e: '🇹🇨', d: 'turks and caicos islands', u: '6.0' },
  { n: 'flag_td', c: 'flags', e: '🇹🇩', d: 'chad', u: '6.0' },
  { n: 'flag_tf', c: 'flags', e: '🇹🇫', d: 'french southern territories', u: '6.0' },
  { n: 'flag_tg', c: 'flags', e: '🇹🇬', d: 'togo', u: '6.0' },
  { n: 'flag_th', c: 'flags', e: '🇹🇭', d: 'thailand', u: '6.0' },
  { n: 'flag_tj', c: 'flags', e: '🇹🇯', d: 'tajikistan', u: '6.0' },
  { n: 'flag_tk', c: 'flags', e: '🇹🇰', d: 'tokelau', u: '6.0' },
  { n: 'flag_tl', c: 'flags', e: '🇹🇱', d: 'east timor', u: '6.0' },
  { n: 'flag_tm', c: 'flags', e: '🇹🇲', d: 'turkmenistan', u: '6.0' },
  { n: 'flag_tn', c: 'flags', e: '🇹🇳', d: 'tunisia', u: '6.0' },
  { n: 'flag_to', c: 'flags', e: '🇹🇴', d: 'tonga', u: '6.0' },
  { n: 'flag_tr', c: 'flags', e: '🇹🇷', d: 'turkey', u: '6.0' },
  { n: 'flag_tt', c: 'flags', e: '🇹🇹', d: 'trinidad and tobago', u: '6.0' },
  { n: 'flag_tv', c: 'flags', e: '🇹🇻', d: 'tuvalu', u: '6.0' },
  { n: 'flag_tw', c: 'flags', e: '🇹🇼', d: 'the republic of china', u: '6.0' },
  { n: 'flag_tz', c: 'flags', e: '🇹🇿', d: 'tanzania', u: '6.0' },
  { n: 'flag_ua', c: 'flags', e: '🇺🇦', d: 'ukraine', u: '6.0' },
  { n: 'flag_ug', c: 'flags', e: '🇺🇬', d: 'uganda', u: '6.0' },
  { n: 'flag_um', c: 'flags', e: '🇺🇲', d: 'united states minor outlying islands', u: '6.0' },
  { n: 'flag_us', c: 'flags', e: '🇺🇸', d: 'united states', u: '6.0' },
  { n: 'flag_uy', c: 'flags', e: '🇺🇾', d: 'uruguay', u: '6.0' },
  { n: 'flag_uz', c: 'flags', e: '🇺🇿', d: 'uzbekistan', u: '6.0' },
  { n: 'flag_va', c: 'flags', e: '🇻🇦', d: 'the vatican city', u: '6.0' },
  { n: 'flag_vc', c: 'flags', e: '🇻🇨', d: 'saint vincent and the grenadines', u: '6.0' },
  { n: 'flag_ve', c: 'flags', e: '🇻🇪', d: 'venezuela', u: '6.0' },
  { n: 'flag_vg', c: 'flags', e: '🇻🇬', d: 'british virgin islands', u: '6.0' },
  { n: 'flag_vi', c: 'flags', e: '🇻🇮', d: 'u.s. virgin islands', u: '6.0' },
  { n: 'flag_vn', c: 'flags', e: '🇻🇳', d: 'vietnam', u: '6.0' },
  { n: 'flag_vu', c: 'flags', e: '🇻🇺', d: 'vanuatu', u: '6.0' },
  { n: 'flag_wf', c: 'flags', e: '🇼🇫', d: 'wallis and futuna', u: '6.0' },
  { n: 'flag_ws', c: 'flags', e: '🇼🇸', d: 'samoa', u: '6.0' },
  { n: 'flag_xk', c: 'flags', e: '🇽🇰', d: 'kosovo', u: '6.0' },
  { n: 'flag_ye', c: 'flags', e: '🇾🇪', d: 'yemen', u: '6.0' },
  { n: 'flag_yt', c: 'flags', e: '🇾🇹', d: 'mayotte', u: '6.0' },
  { n: 'flag_za', c: 'flags', e: '🇿🇦', d: 'south africa', u: '6.0' },
  { n: 'flag_zm', c: 'flags', e: '🇿🇲', d: 'zambia', u: '6.0' },
  { n: 'flag_zw', c: 'flags', e: '🇿🇼', d: 'zimbabwe', u: '6.0' },
];
