import React, { useMemo } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import Tippy from '@tippyjs/react';
import classNames from 'classnames';

import { DEFAULT_FONT_FAMILY_SELECT_OPTIONS } from '../../constants';
import { useEditorContext } from '../../context';
import Icon, { Icons } from 'uikit/icon';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import cx from '../../editor.module.scss';

export const MenuFontFamily = ({ mode, currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();

    const currentFontFamily = editor?.getAttributes('textStyle')?.fontFamily;
    const currentFontWeight = editor?.getAttributes('textStyle')?.fontWeight;

    const selectedFontFamily = useMemo(
        () => currentFontFamily || 'NotoSans, sans-serif',
        [currentFontFamily]
    );
    const selectedFontWeight = useMemo(
        () => currentFontWeight || 400,
        [currentFontWeight]
    );

    const fontFamily = React.useMemo(() => {
        let editorFontFamily = 'NotoSans, sans-serif';

        if (editor?.getAttributes('textStyle')?.fontFamily) {
            editorFontFamily = editor?.getAttributes('textStyle')?.fontFamily.split(',')[0].replace(' ', '').toLowerCase();
        }

        return (
            DEFAULT_FONT_FAMILY_SELECT_OPTIONS.find(f => {
                const value = f.value.split(',')[0].replace(' ', '').toLowerCase();
                return value === editorFontFamily;
            })?.title || 'NotoSans'
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, editor?.getAttributes('textStyle')?.fontFamily]);

    const setFontFamily = (font, weight) => {
        if (font === 'Noto Sans') {
            editor?.chain().focus().unsetFontFamily().run();
        }

        editor?.chain().focus().setFontFamily(font).run();

        if (weight) {
            editor?.chain().focus().setFontWeight(weight).run();
        } else if (!weight || weight === 400) {
            editor?.chain().focus().unsetFontWeight().unsetBold().run();
        }
    };

    return (
        <Menu
            mode={mode}
            name="font-family"
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            menuContentStyles={{ overflow: 'initial', padding: '7px 0 7px 7px', maxHeight: 'initial' }}
            label={
                <Tippy content="Шрифт" disabled={mode !== 'DESKTOP'}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 6px' }}>
                        <div className={classNames('editor-button', cx.fontFamily, cx.editorMenuButton)}>
                            {fontFamily ? fontFamily : 'NotoSans'}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    </div>
                </Tippy>
            }
        >
            <Scrollbar style={{ width: 262, height: 330, display: 'flex', justifyContent: 'center' }}>
                {DEFAULT_FONT_FAMILY_SELECT_OPTIONS.map(f => (
                    <div key={f.value}>
                        {f.types.length > 1 && (
                            <Menu
                                mode={mode}
                                name="font-family"
                                currentMenu={currentMenu}
                                setCurrentMenu={setCurrentMenu}
                                key={f.value}
                                selected={f.value === selectedFontFamily}
                                label={<span style={{ fontFamily: f.value }}>{f.title}</span>}
                                onClick={() => (mode === 'DESKTOP' ? setFontFamily(f.value) : null)}
                            >
                                {f.types.map((t, idx) => (
                                    <MenuItem
                                        selected={f.value === selectedFontFamily && t === selectedFontWeight}
                                        key={t.value + idx.toString()}
                                        label={
                                            <span style={{ fontFamily: f.value, fontWeight: t }}>
                                                {t === 300 ? 'Тонкий' : t === 400 ? 'Обычный' : t === 600 ? 'Полужирный' : 'Жирный'}
                                            </span>
                                        }
                                        styles={{ minWidth: 180 }}
                                        onClick={() => setFontFamily(f.value, t)}
                                    />
                                ))}
                            </Menu>
                        )}
                        {f.types.length <= 1 && (
                            <MenuItem
                                key={f.value}
                                selected={f.value === selectedFontFamily}
                                label={<span style={{ fontFamily: f.value }}>{f.title}</span>}
                                styles={{ minWidth: 236 }}
                                onClick={() => setFontFamily(f.value)}
                            />
                        )}
                    </div>
                ))}
            </Scrollbar>
        </Menu>
    );
};
