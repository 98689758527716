import { formatMessage } from "components/chat/utils";

const decodeHtmlEntities = (str) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = formatMessage(str).replaceAll('&amp;', '&');

    let decodedString = textArea.value;
    decodedString = decodedString.replace(/\u00A0/g, ' ');

    return decodedString;
};

export default decodeHtmlEntities;
