import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { idFromString } from 'lib/helpers';
import cx from './radio-button.module.scss';

const RadioButton = ({ id, className, label, forwardedRef, checked, onChange, value, desc, key, ...attrs }) => {
    const _id = id || idFromString(label);

    return (
        <div key={key} className={classNames(cx.wrapper, {[cx.checked]: checked} , className)}>
            <label onClick={e => {
                e.preventDefault();
                onChange(value);
            }} className={cx.container}>
                <input id={_id} type={'radio'} ref={forwardedRef} checked={checked} {...attrs}/>
                <span className={cx.label}>{label}</span>
                <div className={cx.checkmark}>
                    <span/>
                </div>
            </label>
            <div className={cx.desc}>{desc}</div>
        </div>
    );
};

RadioButton.defaultProps = {
    id: '',
    className: '',
    label: ''
};

RadioButton.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.any.isRequired,
    desc: PropTypes.string,
    key: PropTypes.string
};

export default React.forwardRef((props, ref) => <RadioButton {...props} forwardedRef={ref}/>);