import React, { useState, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import AutoSizer from 'react-virtualized-auto-sizer';
import Tree from 'components/trees/infinite-tree/tree';
import CommonDialog from 'components/trees/common-dialog';
import { NODE_TYPES } from 'components/trees';
import { useLocationTree } from 'components/trees/utils';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import Loading from 'uikit/loading';
import TreeUtils from 'lib/util/tree.util';
import { useDebounceValue } from 'lib/hooks';
import cx from './report-project-select.module.scss';
import api from 'api';

const ReportProjectSelect = ({ onChange, error, withSections, withArticles, isMulti = true, isClear, onRemove }) => {
    const treeRef = useRef(null);

    const [currentNode, setCurrentNode] = useState(null);
    const [visualPaths, setVisualPaths] = useState([]);
    const [removedNodesIds, setRemovedNodesIds] = useState([]);
    const [value, setValue] = useState({});

    const [dialog, setDialog] = useState(false);
    const [isReload, setIsReload] = useState(false);

    const [query, setQuery] = useState('');

    const [isAllChecked, setIsAllChecked] = useState(false);
    const [isTreeUpdate, setIsTreeUpdate] = useState(false);
    const [checkedNodes, setCheckedNodes] = useState([]);

    const { loading, tree } = useLocationTree({
        open: dialog,
        withArticles,
        withSections,
        fetchTreeFunc: api.project.getUsersDocumentTree,
        isReload,
    });
    const [debouncedValue] = useDebounceValue(query.trim().replace(/\s{2,}/g, " "), 300);

    const onLocationChange = useCallback(
        async (node, withSection, withArticles) => {
            const nodes = Array.isArray(node) ? node : [node];
            let project,
                path,
                sectionPaths = [],
                projectUuids = [],
                sectionUuids = [],
                articleUuids = [];

            const parseNode = (node) => {
                if (!withSection && !withArticles) {
                    projectUuids.push(node.id);
                } else if (withArticles) {
                    node.children.forEach((n) => {
                        if (n.state.checked) {
                            if (n.nodeType === NODE_TYPES.ARTICLE) {
                                articleUuids.push(n.id);
                            } else {
                                parseNode(n);
                            }
                        }
                    });
                } else if (node.children.length) {
                    node.children.forEach((n) => {
                        if (n.state.checked) {
                            if (n.nodeType === NODE_TYPES.ARTICLE) {
                                articleUuids.push(n.id);
                            }

                            if (!n.state.indeterminate) {
                                if (n.nodeType === NODE_TYPES.SECTION) {
                                    sectionPaths.push(TreeUtils.getPathIdsTo(n).join('/'));

                                    return;
                                }
                            } else {
                                sectionUuids.push(n.id);

                                return parseNode(n);
                            }
                        }
                    });
                } else {
                    return;
                }
            };

            nodes.filter((n) => n.nodeType === NODE_TYPES.PROJECT).forEach((n) => parseNode(n));

            const _visualPaths = nodes.reduce((acc, node) => {
                if (withArticles && node.nodeType === NODE_TYPES.ARTICLE) {
                    let currentNode = node.parent;
                    let project;
                    path = node.id;

                    while (currentNode.parent) {
                        path = currentNode.id + '/' + path;
                        currentNode = currentNode.parent;

                        if (currentNode.nodeType === NODE_TYPES.PROJECT) {
                            project = currentNode;
                        }
                    }

                    acc.push({
                        id: node.id,
                        logoUuid: project.logoUuid,
                        smallLogoUuid: project.smallLogoUuid,
                        path,
                        visualPath: TreeUtils.getVisualPathTo(node),
                    });
                } else if (withArticles && node.nodeType !== NODE_TYPES.ARTICLE) {
                    return acc;
                }

                if (node.nodeType === NODE_TYPES.PROJECT) {
                    path = '';
                    project = node;

                    if (!withSection) {
                        path += node.id;

                        acc.push({
                            id: node.id,
                            logoUuid: project.logoUuid,
                            smallLogoUuid: project.smallLogoUuid,
                            path,
                            visualPath: TreeUtils.getVisualPathTo(node),
                        });
                    }
                } else if (node.nodeType === NODE_TYPES.SECTION && node.isRoot && withSection) {
                    if (!node.state.open || !node.state.total) {
                        path = project.id + '/' + node.id;

                        acc.push({
                            id: node.id,
                            logoUuid: project.logoUuid,
                            smallLogoUuid: project.smallLogoUuid,
                            path,
                            visualPath: TreeUtils.getVisualPathTo(node),
                        });
                    }
                } else if (node.nodeType === NODE_TYPES.SECTION && !node.state.total && withSection) {
                    let currentNode = node.parent;
                    path = node.id;
                    while (currentNode.parent) {
                        path = currentNode.id + '/' + path;
                        currentNode = currentNode.parent;
                    }

                    acc.push({
                        id: node.id,
                        logoUuid: project.logoUuid,
                        smallLogoUuid: project.smallLogoUuid,
                        path,
                        visualPath: TreeUtils.getVisualPathTo(node),
                    });
                }

                return acc;
            }, []);

            setVisualPaths(_visualPaths);
            setValue({
                sectionPaths,
                projectUuids,
                sectionUuids,
                articleUuids,
            });
            onChange({
                sectionPaths,
                projectUuids,
                sectionUuids,
                articleUuids,
            });

            setDialog(false);
        },
        [setVisualPaths, onChange]
    );

    const removeProject = (e, id, withArticles, withSections) => {
        e.stopPropagation();

        const _visualPaths = visualPaths.filter((i) => i.id !== id);
        setRemovedNodesIds(() => {
            let _value = {};
            const nodes = visualPaths
                .filter((i) => i.id === id)
                .reduce((acc, item) => {
                    if (!withSections && !withArticles) {
                        setValue((prevState) => {
                            _value = {
                                ...prevState,
                                projectUuids: prevState.projectUuids.filter((i) => i !== item.id),
                            };

                            return _value;
                        });
                    }
                    if (withSections && !withArticles) {
                        setValue((prevState) => {
                            _value = {
                                ...prevState,
                                sectionPaths: prevState.sectionPaths.filter((i) => i !== item.path),
                                sectionUuids: prevState.sectionUuids.filter((i) => i !== item.id),
                            };

                            return _value;
                        });
                    }
                    if (withArticles) {
                        setValue((prevState) => {
                            _value = { ...prevState, articleUuids: prevState.articleUuids.filter((i) => i !== item.id) };

                            return _value;
                        });
                    }
                    const pathIds = item.path.split('/');
                    acc.push(pathIds[pathIds.length - 1]);
                    return acc;
                }, []);

            onChange(_value);

            return nodes;
        });

        setVisualPaths(_visualPaths);
        setValue((prevState) => {
            const _value = {
                ...prevState,
            };

            onChange(_value);

            return _value;
        });
    };

    const clearProjects = useCallback(
        (e) => {
            e && e.stopPropagation();

            setRemovedNodesIds('ALL');
            setCheckedNodes([]);
            setVisualPaths([]);
            setValue((prevState) => {
                const _value = {
                    ...prevState,
                    projectUuids: [],
                    sectionPaths: [],
                    sectionUuids: [],
                    articleUuids: [],
                    newsUuids: [],
                };

                onChange(_value);

                return _value;
            });
        },
        [onChange]
    );

    const toggleModal = () => {
        setDialog((prevState) => {
            setIsReload(!prevState);

            return !prevState;
        });
    };

    const _onSubmit = () => {
        if (isMulti) {
            const nodes = treeRef.current.tree.nodeTable.data;
            const selectedNodes = Object.values(nodes)
                .filter((node) => node.state?.checked && (node.state.filtered === undefined || node.state.filtered === true))
                .sort((nodeA, nodeB) => {
                    return nodeA.state.path - nodeB.state.path;
                });
            setCheckedNodes(selectedNodes);
            onLocationChange(selectedNodes, withSections, withArticles);
        } else {
            onLocationChange(currentNode);
        }
        setIsReload(false);
    };

    const _onUpdateNode = (node) => {
        const { tree } = treeRef.current;
        const isAllChecked = tree.nodes.every((node) => node.state?.checked);

        setIsAllChecked(isAllChecked);

        if (!isMulti) {
            setCurrentNode(node);
        }
    };

    const onAllButtonClick = () => {
        const { tree } = treeRef.current;
        const isAllChecked = tree.nodes.every((node) => node.state?.checked);

        if (isAllChecked) {
            tree.nodes.forEach((node) => {
                if (node.nodeType === NODE_TYPES.PROJECT) {
                    tree.checkNode(node);
                }
            });
        } else {
            tree.nodes.forEach((node) => {
                if ((node.nodeType === NODE_TYPES.PROJECT || node.nodeType === NODE_TYPES.SECTION) && !node.state.checked) {
                    tree.checkNode(node);
                }
            });
        }

        setIsAllChecked(!isAllChecked);
    };

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(debouncedValue.trim());
        }
    }, [debouncedValue, treeRef]);

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;

            if (dialog && !loading && checkedNodes.length) {
                setIsTreeUpdate(true);
                checkedNodes.forEach((node) => {
                    const _node = tree.getNodeById(node.id);

                    if (_node) {
                        _node.state.checked = node.state.checked || false;
                    }
                });

                checkedNodes.forEach((node) => {
                    const _node = tree.getNodeById(node.id);
                    if (_node.nodeType === NODE_TYPES.PROJECT && !_node.children.some(ch => ch.state.checked)) {
                        _node.state.checked = false;
                    }
                });

                if (!tree.nodes.some((n) => n.state.checked)) {
                    setIsAllChecked(false);
                }

                setIsTreeUpdate(false);
            }
        }
    }, [treeRef, checkedNodes, loading, dialog, setIsTreeUpdate]);

    useEffect(() => {
      if (treeRef?.current?.tree) {
        const { tree } = treeRef?.current;

            if (Array.isArray(removedNodesIds) && removedNodesIds.length) {
                setCheckedNodes((prevState) => {
                    return [
                        ...prevState.map((node) => {
                            if (!removedNodesIds.includes(node.id)) {
                                return node;
                            }

                            node.state.checked = false;

                            return node;
                        }),
                    ];
                });
            } else if (removedNodesIds === 'ALL') {
                setCheckedNodes([]);
                setIsAllChecked(false);
                Object.values(tree.nodeTable.data).forEach(node => {
                  if (node.state.checked) {
                    node.state.checked = false;
                  }
                })
            }
        }
    }, [removedNodesIds, setCheckedNodes, treeRef]);

    useEffect(() => {
        if (isClear) {
            clearProjects();
            onRemove();
        }
    }, [isClear, clearProjects, onRemove, onChange]);

    console.log(value);

    return (
        <>
            <div className={cx.locationChange}>
                <div
                    className={classNames(cx.blockLocation, {
                        [cx.fieldError]: error,
                    })}
                    onClick={toggleModal}
                >
                    <div id={'locationBlockDiv'} className={cx.path}>
                        {visualPaths?.map?.((item) => {
                            return (
                                <div key={item['id']} className={cx.projectWrapper}>
                                    <div className={cx.projectLogo}>
                                        <img
                                            src={
                                                item['smallLogoUuid']
                                                    ? api.upload.getImage(item['logoUuid'], false, 128)
                                                    : item['logoUuid']
                                                    ? api.upload.getImage(item['logoUuid'], false, 512)
                                                    : ''
                                            }
                                            alt=""
                                        />
                                    </div>
                                    <div>
                                        {item.visualPath.map((name, idx) => (
                                            <span key={idx}>/ {name + ' '}</span>
                                        ))}
                                    </div>
                                    <div
                                        className={cx.projectRemoveIcon}
                                        onClick={(e) => removeProject(e, item['id'], withArticles, withSections)}
                                    >
                                        <Icon type={Icons.CROSS} color="white" width={8} height={8} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={cx.removeAll} onClick={(e) => clearProjects(e)}>
                        <Icon id={'locationBlockClearIcon'} type={Icons.CROSS} />
                    </div>
                </div>
                {error ? <div className={cx.fieldErrorText}>Поле обязательно для заполнения</div> : null}
            </div>

            <CommonDialog
                open={dialog}
                loading={loading}
                hasData={tree.length > 0}
                title={withArticles ? 'Выбор статьи' : withSections ? 'Выбор раздела' : 'Выбор проекта'}
                onSubmit={_onSubmit}
                onDismiss={toggleModal}
            >
                <div id={'tree-container'} className={cx.tree}>
                    {loading || isTreeUpdate ? <Loading withOverlay={false} withRelativeOverlay={true} /> : null}
                    <div className={cx.searchInput}>
                        <Input
                            type={'text'}
                            placeholder={'Поиск по названию'}
                            value={query}
                            onChange={(value) => {
                                setQuery(value);
                            }}
                        />
                    </div>
                    {!loading && isMulti && (
                        <button className={classNames(cx.allBtn, isAllChecked ? cx.allBtnActive : '')} onClick={onAllButtonClick}>
                            <Icon type={Icons.LIST} />
                            {isAllChecked ? 'Снять выделение' : 'Выбрать все'}
                        </button>
                    )}
                    <div className={cx.sizerContainer}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return (
                                    <Tree
                                        width={width}
                                        height={height}
                                        data={tree}
                                        onUpdate={_onUpdateNode}
                                        multipleChoice={isMulti}
                                        autoOpen={false}
                                        isDeselecting={true}
                                        onCheckedChange={() => {}}
                                        ref={treeRef}
                                        getNodeLink={() => '#'}
                                    />
                                );
                            }}
                        </AutoSizer>
                    </div>
                </div>
            </CommonDialog>
        </>
    );
};

export default ReportProjectSelect;
