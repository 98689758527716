import React from 'react';
import PropTypes from 'prop-types';
import cx from './progress-bar.module.scss';

const ROUND_PRECISION = 1000;

function getPercentage(now, min, max) {
    const percentage = ((now - min) / (max - min)) * 100;
    return Math.round(percentage * ROUND_PRECISION) / ROUND_PRECISION;
}

const ProgressBar = ({ min = 0, now = 0, max = 100 }) => {
    return (
        <div className={cx.progressWrapper}>
            <div role="progressbar" className={cx.progressBar} style={{ width: `${getPercentage(now, min, max)}%` }}
                 aria-valuenow={String(now)} aria-valuemin={String(min)} aria-valuemax={String(max)}/>
        </div>
    );
};

ProgressBar.propTypes = {
    min: PropTypes.number,
    now: PropTypes.number,
    max: PropTypes.number,
    prefix: PropTypes.string,
    variant: PropTypes.string
};

ProgressBar.defaultProps = {
    prefix: 'progress',
    min: 0,
    max: 100
};

export default React.forwardRef((props, ref) => <ProgressBar {...props} forwardedRef={ref}/>);