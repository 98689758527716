function typeMime(type) {
    switch (type) {
        case 'pdf':
            return 'application/pdf';
        case 'doc':
            return 'application/msword';
        case 'png':
            return 'image/png';
        case 'rtf':
            return 'application/rtf';
        case 'txt':
            return 'text/plain';
        default:
            return type;
    }
}

export function download(data, filename, type) {
    const file = new Blob([data], { type: typeMime(type) });

    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, filename);
    } else {
        const a = document.createElement('a'), url = URL.createObjectURL(file);

        a.href = url;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}