import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Crumbs from 'components/crumbs';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import cx from './table-title-cell.module.scss';

const TableTitleCell = ({ state, link, title, crumbs, description, image }) => {
    return (
        <div className={cx.wrapper}>
            {image && (
                <img src={image} alt={title} />
            )}
            <div>
                {state !== 'DELETED' && (
                    <Link to={link} className={cx.title}>
                        <MultiClumpTooltip label={title} />
                    </Link>
                )}

                {state === 'DELETED' && (
                    <div style={{ opacity: 0.5 }}>
                        <MultiClumpTooltip label={title} />
                    </div>
                )}

                {description && (
                    <div className={cx.description}>
                        <MultiClumpTooltip label={description} />
                    </div>
                )}

                {crumbs.length > 0 && (
                    <div key={link} className={cx.crumbs}>
                        <Crumbs data={crumbs} />
                    </div>
                )}
            </div>
        </div>
    );
};

TableTitleCell.propTypes = {
    state: PropTypes.string,
    link: PropTypes.string,
    title: PropTypes.string,
    crumbs: PropTypes.array,
    description: PropTypes.string,
    image: PropTypes.string,
};

TableTitleCell.defaultProps = {
    state: '',
    link: '',
    title: '',
    crumbs: [],
    description: '',
    image: ''
};

export default TableTitleCell;
