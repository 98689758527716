import React from 'react';
import cx from './unread-label.module.scss';
import classNames from 'classnames';

export const UnreadLabel = ({ count, className }) => {
    if (!count) {
        return null;
    }

    return (
        <div className={classNames(cx.base, className)} style={{ position: 'absolute' }}>
            {count}
        </div>
    )
}
