import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonHorizontalRule = ({ setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const setHorizontalRule = () => {
        setCurrentMenu(undefined);
        editor?.chain().focus().setHorizontalRule().run();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconDevider}
            onClick={setHorizontalRule}
            tooltip={isTooltip === false ? null : 'Разделитель'}
            label="Разделитель"
        ></MenuButton>
    );
};
