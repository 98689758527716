import React, { useCallback, useState, useRef } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';
import IconButton from 'uikit/icon-button';
import Loading from 'uikit/loading';
import Icon, { Icons } from 'uikit/icon';
import cx from './ImagePreviewModal.module.scss';

export const useImagePreviewModal = () => {
    const imgRef = useRef({ src: undefined });
    const [selectedImageLoading, setSelectedImageLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [imgSizeModifier, setImgSizeModifier] = useState(1);
    const [imgSize, setImgSize] = useState({ width: '75%', height: 'auto', size: 100 });

    const openImageModal = useCallback(async (img) => {
        setSelectedImageLoading(true);
        setIsOpen(true);

        try {
            imgRef.current.src = img.src;

            const imageContainer = document.getElementById('imagePreviewModalContainer');
            const offsetWidth = imageContainer?.offsetWidth;
            const offsetHeight = imageContainer?.offsetHeight;
            const width = img.naturalWidth;
            const height = img.naturalHeight;

            if (width > height) {
                if (width < offsetWidth - (offsetWidth / 100) * 75) {
                    setImgSize({
                        width: width,
                        height: 'auto',
                        size: 100,
                    });
                } else {
                    setImgSize({
                        width: (offsetWidth / 100) * 75,
                        height: 'auto',
                        size: 100,
                    });
                }
            } else {
                if (height < (offsetHeight / 100) * 75) {
                    setImgSize({
                        width: 'auto',
                        height: height,
                        size: 100,
                    });
                } else {
                    setImgSize({
                        width: 'auto',
                        height: (offsetHeight / 100) * 75,
                        size: 100,
                    });
                }
            }

            setSelectedImageLoading(false);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const increaseImg = () => {
        if (imgSizeModifier >= 2) {
            return;
        }

        setImgSizeModifier((prevState) => prevState + 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size + 25 }));
    };
    const decreaseImg = () => {
        if (imgSizeModifier <= 0.5) {
            return;
        }

        setImgSizeModifier((prevState) => prevState - 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size - 25 }));
    };

    return {
        selectedImageLoading,
        isOpen,
        setIsOpen,
        imgSize,
        imgSizeModifier,
        increaseImg,
        decreaseImg,
        openImageModal,
        imgRef,
    }
}

export const ImagePreviewModal = ({
    selectedImageLoading,
    isOpen,
    setIsOpen,
    imgSize,
    imgSizeModifier,
    increaseImg,
    decreaseImg,
    imgRef,
}) => {

    return (
        <Modal className={cx.imagePreviewModal} isOpen={isOpen} overlayClassName={cx.termModalOverlay}
            onRequestClose={() => setIsOpen(false)} testId="imagePreviewModal">
            <div className={cx.head}>
                <div className={cx.text}></div>
                <IconButton icon={<Icon type={Icons.CROSS} color={'white'} width={16} height={16} />} onClick={() => setIsOpen(false)} id="imagePreviewModalCrestBtn" />
            </div>
            <div id="imagePreviewModalContainer" data-testid="imagePreviewModalContainer" className={cx.content}>
                <img
                    style={{
                        width: isNaN(imgSize.width) ? imgSize.width : imgSize.width * imgSizeModifier + 'px',
                        height: isNaN(imgSize.height) ? imgSize.height : imgSize.height * imgSizeModifier + 'px',
                    }}
                    ref={imgRef}
                    src=""
                    alt=""
                />
            </div>
            <div className={cx.modalFooter}>
                <button
                    className={classNames(cx.noStyleButton, {
                        [cx.disabled]: imgSize?.size <= 50,
                    })}
                    onClick={decreaseImg}
                    data-testid="imagePreviewModalDecreaseBtn"
                >
                    <Icon type={Icons.MINUS} width={20} height={20} color={imgSize?.size <= 50 ? 'gray' : 'white'} />
                </button>

                <div className={cx.sizeValue} data-testid="imagePreviewModalImgSize">{imgSize?.size}%</div>

                <button
                    className={classNames(cx.noStyleButton, {
                        [cx.disabled]: imgSize?.size >= 200,
                    })}
                    onClick={increaseImg}
                    data-testid="imagePreviewModalIncreaseBtn"
                >
                    <Icon type={Icons.PLUS_ADD} width={20} height={20} color={imgSize?.size >= 200 ? 'gray' : 'white'} />
                </button>
            </div>
            <Loading active={selectedImageLoading} withOverlay={false} withRelativeOverlay={true} />
        </Modal>
    )
};