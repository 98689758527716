import { useEffect, useState } from 'react';
import { useMessage } from 'lib/hooks/useMessage';
import * as validators from 'lib/util/validators.util';
import { ProjectFormModel } from 'model/project';
import api from 'api';

export function useProjectFormModel(projectUuid) {
    const { addError } = useMessage();
    const [loading, setLoading] = useState(false);

    const [permissionsOk, setPermissionsOk] = useState(true);
    const [project, setProject] = useState(new ProjectFormModel());

    useEffect(() => {
        if (projectUuid) {
            api.project.getProject(projectUuid).then(data => {
                setProject(new ProjectFormModel(data));
            }).catch(e => {
                if (/has no permissions to edit the project with id/.test(e.errorData.detail)) {
                    setPermissionsOk(false);
                } else {
                    throw e;
                }
            });
        }
    }, [projectUuid]);

    function commit() {
        const copy = this.copy();
        copy.commit = commit;

        setProject(copy);
    }

    project.commit = commit;

    function validateFields(fields) {
        if (!fields) {
            fields = ['title', 'description'];
        }

        let errors = {};

        if (fields.includes('title')) {
            errors['title'] = validators.textValidator(project.title);
        }

        if (fields.includes('description')) {
            errors['description'] = validators.textValidator(project.description);
        }

        project.errors = {
            ...project.errors,
            ...errors
        };

        project.commit(false);
        return Object.values(errors).every(v => v === null);
    }

    project.validateFields = validateFields;

    function scrollToError() {
        const errorField = document.querySelector('.field-invalid');

        if (!errorField) {
            return;
        }

        const wrapper = errorField.closest('.js-scroll-wrapper');
        const fieldPosition = errorField.getBoundingClientRect();

        if (fieldPosition.top <= 0) {
            wrapper.scrollTop = wrapper.scrollTop + fieldPosition.top - fieldPosition.height * 3;
        } else {
            wrapper.scrollTop = fieldPosition.top + fieldPosition.height * 4;
        }
    }

    project.scrollToError = scrollToError;

    async function validateTitleExists() {
        try {
            const titleErr = await api.project.validateTitle(project.title) ? null : 'Проект с таким названием уже существует';

            project.errors = {
                ...project.errors,
                titleExists: titleErr
            };

            project.commit();
            return !!titleErr;
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            return true;
        }
    }

    project.validateTitleExists = validateTitleExists;

    function clearProject() {
        const newProject = new ProjectFormModel();
        newProject.commit = commit;

        setProject(newProject);
    }

    return { project, setProject, clearProject, loading, setLoading, permissionsOk };
}
