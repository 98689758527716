import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cx from './text.module.scss';

const Text = ({ id, name, className, label, value, maxLength, isCounter, isError, error, onChange }) => {
    const [isLengthError, setIsLengthError] = useState(false);

    const onTextChange = (e) => {
        if (maxLength > 0 && e.target.value.length > maxLength) {
            setIsLengthError(true);
            return;
        }

        setIsLengthError(false);
        onChange(e.target.value);
    };

    return (
        <div id={id} className={classNames(cx.text, isError || isLengthError ? cx.textError : null, className)}>
            <label className={isError || isLengthError ? cx.error : null} htmlFor={name}>{label}</label>
            <textarea id={name} name={name} value={value} onChange={onTextChange}/>
            {isCounter && !isError &&
            <span className={isLengthError ? cx.error : ''}>Использовано символов: {value.length} / {maxLength}</span>}
            {isError &&
            <span className={cx.error}>{error}</span>}
        </div>
    );
};

Text.defaultProps = {
    id: null,
    name: null,
    className: null,
    label: null,
    value: null,
    maxLength: 128,
    isCounter: false,
    isError: false,
    error: '',
    onChange: () => {}
};

Text.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    isCounter: PropTypes.bool,
    isError: PropTypes.bool,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default Text;