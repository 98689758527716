import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import formatDateTime from 'lib/helpers/formatDateTime';
import MultiChart from 'components/statistic/resources-chart';
import { doughnutChartOptions, lineChartOptions } from 'components/statistic/charts-options';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import cx from './statistic-total-stats-page.module.scss';

const StatisticDashboardsSearch = () => {
    const [requestsPerDay, setRequestsPerDay] = useState(null);
    const [requestsPerOffice, setRequestsPerOffice] = useState(null);

    const [exactAnswer, setExactAnswer] = useState(null);
    const [topSearchedRequests, setTopSearchedRequests] = useState([]);

    const [topSearchedRequestsToday, setTopSearchedRequestsToday] = useState([]);

    const fetchRequestsPerHour = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getSearchesRequestsPerDay(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((search) => {
                data.labels.push(formatDateTime(new Date(search.date)));
                data.datasets.push(search.numberOfDaySearches);
            });

            setRequestsPerDay(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchRequestsPerOffice = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getSearchesRequestsPerOffice(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((office) => {
                data.labels.push(office.officeName || 'Без филиала');
                data.datasets.push(office.numberOfSearches);
            });

            setRequestsPerOffice(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchExactAnswer = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getSearchesRequestsResults(cancelToken.token);

            setExactAnswer({
                labels: ['Да', 'Нет'],
                datasets: [res?.numberOfFoundResults, res?.numberOfNotFoundResults],
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopSearchedRequests = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopSearchedRequests(cancelToken.token);
            setTopSearchedRequests(res);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopSearchedRequestsToday = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopSearchedRequestsToday(cancelToken.token);
            setTopSearchedRequestsToday(res);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        fetchRequestsPerHour(cancelToken);
        fetchRequestsPerOffice(cancelToken);
        fetchExactAnswer(cancelToken);
        fetchTopSearchedRequests(cancelToken);
        fetchTopSearchedRequestsToday(cancelToken);

        return () => {
            cancelToken.cancel();
        };
    }, [fetchRequestsPerHour, fetchRequestsPerOffice, fetchExactAnswer, fetchTopSearchedRequests, fetchTopSearchedRequestsToday]);

    return (
        <>
            <div className={cx.header}>
                <h1>Поиск - Мониторинг</h1>
            </div>
            <div className={cx.title}>Запросы</div>
            <div className={cx.chartRow}>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Количество запросов"
                        chartType={'line'}
                        chartTypes={['line']}
                        labels={requestsPerDay?.labels}
                        data={requestsPerDay?.datasets}
                        options={lineChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Запросы по офисам"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={requestsPerOffice?.labels}
                        data={requestsPerOffice?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Получен точный ответ"
                        chartType={'horizontalBar'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={exactAnswer?.labels}
                        data={exactAnswer?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
            </div>
            <div className={cx.title}>Популярное</div>
            <div className={cx.chartRow}>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Топ запросов</h3>
                        {topSearchedRequests.length === 0 && <div className={cx.listEmpty}>Нет данных для отображения</div>}
                        {topSearchedRequests.map((item) => {
                            return (
                                <div key={item.id} className={cx.requestsRow}>
                                    <div>
                                        <Icon type={Icons.SEARCH} /> {item.query}
                                    </div>
                                    <div>{item.numberOfSearches ? item.numberOfSearches : 0} запроса(-ов)</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Топ запросов за последние сутки</h3>
                        {topSearchedRequestsToday.length === 0 && <div className={cx.listEmpty}>Нет данных для отображения</div>}
                        {topSearchedRequestsToday.map((item) => {
                            return (
                                <div key={item.id} className={cx.requestsRow}>
                                    <div>
                                        <Icon type={Icons.SEARCH} /> {item.query}
                                    </div>
                                    <div>{item.numberOfSearches ? item.numberOfSearches : 0} запроса(-ов)</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticDashboardsSearch;
