import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonBold = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const toggleBold = (e) => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);

        if (editor?.isActive({ fontWeight: '600' }) || editor?.isActive('bold')) {
            editor?.chain().focus().unsetFontWeight().unsetBold().run();
        } else {
            editor?.chain().focus().setFontWeight('600').setBold().run();
        }
    };

    return (
        <MenuButton
            icon={Icons.EditorIconBold}
            selected={editor?.isActive('textStyle', { fontWeight: '600' }) || editor?.isActive('bold') || undefined}
            tooltip="Полужирный"
            onClick={toggleBold}
        ></MenuButton>
    );
};
