import { debounce } from 'lodash';
import { useRef, useEffect, useState, useCallback } from 'react';

export default function useCodeView(editor, textAreaRef) {
    const cmEditor = useRef(null);
    const cmTextAreaRef = useRef(textAreaRef);
    const [isCodeViewMode, setIsCodeViewMode] = useState(false);
    const toggleIsCodeViewMode = () => {
        setIsCodeViewMode(prev => !prev);
    };

    const formatCode = cm => {
        cm.execCommand('selectAll');
        const selectedRange = {
            from: cm.getCursor(true),
            to: cm.getCursor(false),
        };
        cm.autoFormatRange(selectedRange.from, selectedRange.to);
        cm.setCursor(0);
    };

    const selectAll = () => {
        cmEditor.current.execCommand('selectAll');
    };

    const updateTiptapValue = debounce(() => {
        editor.chain().clearContent().run();
        editor.commands.pasteContent(cmEditor.current.getValue());

        // const content = cmEditor.current.getValue();
        // const parser = new DOMParser();
        //
        // const startTag = '<body>';
        // const endTag = '</body>';
        //
        // const { body } = parser.parseFromString(startTag + content + endTag, 'text/html');
        // const schema = new Schema({ ...editor.schema.spec });
        //
        // const promise = Promise.resolve({ document: ProseMirrorDOMParser.fromSchema(schema).parse(body) });
        //
        // promise.then(({ document }) => {
        //     editor.chain().clearContent().insertContent(document.content.toJSON()).run();
        // });
    }, 100);

    const initCodemirror = useCallback(() => {
        const codeViewExtension = editor.extensionManager.extensions.find(e => e.name === 'codeView');

        if (codeViewExtension) {
            const { codemirror, codemirrorOptions } = codeViewExtension.options;
            if (codemirror) {
                const cmOptions = {
                    ...codemirrorOptions,
                    readOnly: false,
                    spellcheck: false,
                };

                const textarea = document.getElementById('code-view');
                cmEditor.current = codemirror.fromTextArea(textarea, cmOptions);

                cmEditor.current.setValue(editor.getHTML());
                cmEditor.current?.on('change', updateTiptapValue);
                formatCode(cmEditor.current);
            }
        }
    }, [editor, updateTiptapValue]);

    const destroyCodemirror = () => {
        const element = cmEditor.current.doc.cm.getWrapperElement();
        element && element.remove && element.remove();
        cmEditor.current = null;
    };

    useEffect(() => {
        if (isCodeViewMode) {
            if (!cmEditor.current) {
                initCodemirror();
            }
        } else {
            if (cmEditor.current) {
                destroyCodemirror();
            }
        }
    }, [isCodeViewMode, editor, initCodemirror]);

    return {
        cmSelectAll: selectAll,
        cmTextAreaRef,
        isCodeViewMode,
        toggleIsCodeViewMode,
    };
}
