import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import classNames from 'classnames';
import cx from '../textarea/textarea.module.scss';

export const TextareaAutoSizable = ({
    label,
    rows = 2,
    error,
    value = "",
    onChange,
    className,
    ...rest
}) => {

    const clsName = classNames(cx.base, className, {
        [cx.error]: error
    });

    return (
        <div className={clsName}>
            {label.length > 1 ? <label className={cx.label}>{label}</label> : ''}
            <div className={cx.inputWrapper}>
                <TextareaAutosize
                    rows={rows}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    className={classNames(cx.input)}
                    {...rest}
                />
            </div>
            {error && <span className={cx['error-message']}>{error}</span>}
        </div>
    );
};
