import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from '@reach/router';
import { useNewsFormModel } from 'lib/hooks';
import NewsForm from './news-form';
import cs from './news-form-page.module.scss';

export const newsFormPage = (Component, pageTitle, edit = false) => {
    const FormPage = (props) => {
        const navigate = useNavigate();
        const { uuid, type } = props;

        const model = useNewsFormModel(uuid, type);
        const { editor, news, loading, permissionsOk } = model;

        const [isEditorInstanceReady, setIsEditorInstanceReady] = useState(false);

        useEffect(() => {
            if (!permissionsOk) {
                navigate('/401', { replace: true });
            }
        }, [navigate, permissionsOk]);

        useEffect(() => {
          if (editor?.instanceReady || editor?.isEditable) {
            setIsEditorInstanceReady(true);
          }
        }, [editor])

        return (
            <div className={cs.content}>
                <div id={'newsFormPageTitle'} className={cs.pageTitle}>{pageTitle}</div>
                <NewsForm news={news} loading={loading || !isEditorInstanceReady} edit={edit}>
                    <Component {...props} {...model}/>
                </NewsForm>
            </div>
        );
    };

    FormPage.propTypes = {
        uuid: PropTypes.string,
        type: PropTypes.string
    };

    return FormPage;
};