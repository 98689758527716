import React from 'react';

const ArchiveIcon = (props) => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.94502 4.19999C3.94502 3.75999 4.30502 3.39999 4.74502 3.39999H15.245C15.685 3.39999 16.045 3.75999 16.045 4.19999C16.045 4.63999 15.685 4.99999 15.245 4.99999H4.74502C4.30502 4.99999 3.94502 4.63999 3.94502 4.19999ZM6.06504 2.19999H13.945C14.325 2.19999 14.645 1.87999 14.645 1.49999C14.645 1.11999 14.325 0.799988 13.945 0.799988H6.06504C5.68504 0.799988 5.36504 1.11999 5.36504 1.49999C5.36504 1.87999 5.68504 2.19999 6.06504 2.19999ZM19.205 5.12001V7.29999C19.205 7.37999 19.185 7.46001 19.165 7.52001L16.605 16.8C16.505 17.14 16.185 17.38 15.825 17.38H4.10503C3.74503 17.38 3.42503 17.14 3.32503 16.78L0.805034 7.49999C0.785034 7.45999 0.785039 7.41997 0.785039 7.37997C0.785039 7.33997 0.785039 7.3 0.785039 7.26V5.09999C0.785039 4.65999 1.14504 4.29999 1.58504 4.29999C2.02504 4.29999 2.38504 4.65999 2.38504 5.09999V6.57997H17.585V5.09999C17.585 4.65999 17.945 4.29999 18.385 4.29999C18.825 4.29999 19.205 4.68001 19.205 5.12001ZM17.345 8.19999H2.64502L4.72503 15.8H9.98504H15.205L17.345 8.19999ZM7.64502 12.2H12.365C12.805 12.2 13.165 11.84 13.165 11.4C13.165 10.96 12.805 10.6 12.365 10.6H7.64502C7.20502 10.6 6.84502 10.96 6.84502 11.4C6.84502 11.84 7.20502 12.2 7.64502 12.2Z"
                fill="#7B8F9C"/>
        </svg>
    );
};

export default ArchiveIcon;