import ApiClient from './api-axios';

export default class RoleApi extends ApiClient {
    getRoleList = async () => {
        return await this.get('/');
    };

    editRole = async data => {
        return await this.post('/edit', data);
    };

    addRole = async data => {
        return await this.post('/add', data);
    };

    deleteRole = async id => {
        return await this.deleteRequest(`/${id}`);
    };
}