import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from '@reach/router';
import formatDateTime from 'lib/helpers/formatDateTime';
import cs from './user.module.scss';

const User = ({ image, name, id, login, date, className, stackReversed }) => {
    const cls = classNames(cs.user, className);

    return (
        <Link id={id} className={cls} to={'/edit-user/' + login}>
            <div className={cs.image} style={{ backgroundImage: `url(${image})` }}/>
            <div className={classNames(cs.desc, { [cs.descReverse]: !date || stackReversed })}>
                <div className={cs.text}>{!date ? 'Автор' : formatDateTime(date)}</div>
                <div className={cs.link}>{name}</div>
            </div>
        </Link>
    );
};

User.defaultProps = {
    image: '',
    name: '',
    id: ''
};

User.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    _id: PropTypes.string,
    onNavigate: PropTypes.func,
    date: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(Date)
    ]),
    stackReversed: PropTypes.bool
};

export default User;