import ApiClient from './api-axios';

export default class DislikeApi extends ApiClient {
    getArticleDislikes = async (uuid) => {
        return await this.get(`/article/${uuid}`);
    };

    getNewsDislikes = async (uuid) => {
        return await this.get(`/news/${uuid}`);
    };

    addArticleDislike = async (articleId) => {
        return await this.post('/article/add', { articleId });
    };

    addNewsDislike = async (newsId) => {
        return await this.post('/news/add', { newsId });
    };

    addFileDislike = async (id) => {
        return await this.post('/file-comment/add', { commentId: id });
    };

    removeDislike = async (uuid) => {
        return await this.post('/delete', { uuid });
    };

    addArticleCommentDislike = async (commentId) => {
        return await this.post('/article-comment/add', { commentId });
    };

    addNewsCommentDislike = async (commentId) => {
        return await this.post('/news-comment/add', { commentId });
    };

    getArticleCommentDislikes = async (commentId) => {
        return await this.get(`/article-comment/${commentId}`);
    };

    getNewsCommentDislikes = async (commentId) => {
        return await this.get(`/news-comment/${commentId}`);
    };
}