import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonSuperscript = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const toggleSuperscript = e => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);
        editor?.chain().focus().toggleSuperscript().run();
    };

    return (
        <MenuButton
            tooltip="Надстрочный"
            icon={Icons.EditorIconSuperScript}
            onClick={toggleSuperscript}
            selected={editor?.isActive('superscript') ?? undefined}
        ></MenuButton>
    );
};
