import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonAlignRight = ({ onChangeAlign }) => {
    const editor = useEditorContext();

    return (
        <MenuButton
            tooltipLabel="Right align"
            icon={Icons.EditorIconAlignRight}
            onClick={(e) => onChangeAlign(e, 'right')}
            label="Выравнивание справа"
            style={{ whiteSpace: 'nowrap', display: 'flex' }}
            disabled={!editor?.isEditable || !editor?.can().setTextAlign('right')}
        ></MenuButton>
    );
};
