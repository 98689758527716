import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useEventListener, useMessage } from 'lib/hooks';
import { NotificationTypes, setNotifications } from 'slice/notificationsSlice';
import Button from 'uikit/button';
import api from 'api';
import cx from './notifications.module.scss';
import ScrollTopBtn from 'components/scroll-top-btn';
import { useCallback } from 'react';
import Loading from 'uikit/loading';

const NotificationsPage = () => {
    const dispatch = useDispatch();
    const { addError } = useMessage();

    const [isFetching, setIsFetching] = useState(true);

    const [unReadNotifications, setUnReadNotifications] = useState([]);
    const [readNotifications, setReadNotifications] = useState([]);

    const [scrollTop, setScrollTop] = useState(0);

    const onRead = async (notification) => {
        const newUnReadNotifications = JSON.parse(JSON.stringify(unReadNotifications));
        const notificationIndex = newUnReadNotifications.findIndex((item) => item['id'] === notification['id']);

        await api.notification.read(notification['id']);
        await getNotifications();

        newUnReadNotifications[notificationIndex]['readByUser'] = true;
        dispatch(setNotifications(newUnReadNotifications.filter((item) => !item['readByUser'])));
    };

    const onScroll = (e) => {
        const notifications = JSON.parse(JSON.stringify(unReadNotifications));
        let isChanged = false;

        for (let i = 0; i < notifications.length; i++) {
            if (
                (notifications[i]['objectType'] === 'ARTICLE' || notifications[i]['objectType'] === 'NEWS') &&
                notifications[i]['eventType'] === 'EDIT'
            ) {
                continue;
            }

            const el = document.getElementById('unread-' + i);

            if (notifications[i]['readByUser'] || el.getBoundingClientRect().top > document.body.offsetHeight) {
                continue;
            }

            isChanged = true;
            notifications[i]['readByUser'] = true;

            api.notification.read(notifications[i]['id']);
        }

        setUnReadNotifications(notifications);

        if (isChanged) {
            dispatch(setNotifications(notifications));
        }

        setScrollTop(e.target.scrollTop);
    };
    const getNotifications = useCallback(async () => {
        try {
            setIsFetching(true);
            const unReadNotificationsResponse = await api.notification.getUnread();
            setUnReadNotifications(unReadNotificationsResponse);

            const readNotificationsResponse = await api.notification.getAll();
            setReadNotifications(readNotificationsResponse.filter((notif) => notif['readByUser']));
            setIsFetching(false);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);

    useEventListener('scroll', onScroll, document.getElementById('content'));
    useEffect(() => {
        getNotifications();
    }, [getNotifications]);

    return (
        <div className={cx.content}>
            {isFetching && <Loading withOverlay={false} withRelativeOverlay={true} />}
            {unReadNotifications.length > 0 && (
                <div className={cx.title}>
                    <h1>Непрочитанные</h1>
                </div>
            )}
            {unReadNotifications.map((item, i) => {
                return (
                    <div
                        id={'unread-' + i}
                        key={i}
                        className={classNames(cx.notificationUnread, item['readByUser'] ? cx.notificationRead : null)}
                    >
                        <div className={cx.notificationContent}>
                            {item['author'] && (
                                <img
                                    src={
                                        item['author']['avatarUuid']
                                            ? api.upload.getImage(item['author']['avatarUuid'], false, 128)
                                            : '/img/avatar.png'
                                    }
                                    alt=""
                                />
                            )}
                            <p>
                                {NotificationTypes[item['objectType'] + '.' + item['eventType']]
                                    ? NotificationTypes[item['objectType'] + '.' + item['eventType']](null, item)
                                    : item['objectType'] + '.' + item['eventType']}
                            </p>
                        </div>
                        {(item['objectType'] === 'ARTICLE_COMMENT' || item['objectType'] === 'NEWS_COMMENT') && (
                            <p className={cx.notificationComment}>{item['data']['text']}</p>
                        )}
                        {(item['objectType'] === 'ARTICLE' || item['objectType'] === 'NEWS') && item['eventType'] === 'EDIT' && (
                            <Button color="green" disabled={item['readByUser']} onClick={() => onRead(item)}>
                                {item['readByUser'] ? 'Прочитано' : 'Прочитал (-а) уведомление'}
                            </Button>
                        )}
                        <p className={cx.notificationTime}>{new Date(item.timestamp).toLocaleString().slice(0, -3)}</p>
                    </div>
                );
            })}
            {readNotifications.length > 0 && (
                <div className={cx.title}>
                    <h1>Прочитанные</h1>
                </div>
            )}
            {readNotifications.map((item, i) => {
                return (
                    <div key={i} className={cx.notification}>
                        <div className={cx.notificationContent}>
                            {item['author'] && (
                                <img
                                    src={
                                        item['author']['avatarUuid']
                                            ? api.upload.getImage(item['author']['avatarUuid'], false, 128)
                                            : '/img/avatar.png'
                                    }
                                    alt=""
                                />
                            )}
                            <p>
                                {NotificationTypes[item['objectType'] + '.' + item['eventType']]
                                    ? NotificationTypes[item['objectType'] + '.' + item['eventType']](null, item)
                                    : item['objectType'] + '.' + item['eventType']}
                            </p>
                        </div>
                        {(item['objectType'] === 'ARTICLE_COMMENT' || item['objectType'] === 'NEWS_COMMENT') && (
                            <p className={cx.notificationComment}>{item['data']['text']}</p>
                        )}
                        {(item['objectType'] === 'ARTICLE' || item['objectType'] === 'NEWS') && item['eventType'] === 'EDIT' && (
                            <Button color="green" disabled>
                                Прочитано
                            </Button>
                        )}
                        <p className={cx.notificationTime}>{new Date(item.timestamp).toLocaleString().slice(0, -3)}</p>
                    </div>
                );
            })}
            <ScrollTopBtn isSeen={scrollTop > 20} scrollId={'content'} />
        </div>
    );
};

export default NotificationsPage;
