import React, { useState, useMemo, useEffect, Fragment } from 'react';
import { Link } from '@reach/router';
import { ReportsComponent } from 'components/data-components/reports-component';
import cx from './statistic-reports-page.module.scss';
import api from 'api';
import {
    articleSearchBy,
    documentStatusOptions,
    newsPriorityStatusOptions,
    newsSearchBy,
    newsTickerOptions,
    priority,
    objectTypes,
    sortTypesOptions,
} from 'app/statistic/vendors/filtersOptions';
import { ReportService } from 'app/statistic/services/ReportService';
import { useMessage } from 'lib/hooks';
import Crumbs from 'components/crumbs';
import crubmsCS from 'components/crumbs/crubms.module.scss';

const StatisticReportsContentReports = ({ setIsDownloadModal }) => {
    const { addError } = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [reports, setReports] = useState([]);
    const [reportsTotal, setReportsTotal] = useState(0);
    const [sortByOptions, setSortByOptions] = useState([]);
    const [filters, setFilters] = useState({});

    const getContentReports = async (page = 0, size = 50, filters = {}) => {
        setIsLoading(true);
        try {
            if (filters['document-type-select'].value === 'ARTICLE') {
                const res = await api.reports.getArticles({ page, size, filters, cancelToken: ReportService.cancelToken.token });

                setReports(
                    res.articles.content.map((i) => {
                        return {
                            ...i,
                            projectPath: i.projectPath,
                            createDate: i.createDate,
                            status: i.state === 'ACTIVE' ? 'Активные' : i.state === 'ARCHIVED' ? 'Архивные' : 'Удаленные',
                        };
                    })
                );
                setReportsTotal(res.articles.totalElements);

                if (!res.articles.totalElements) {
                    addError('По вашему запросу нет данных');
                }
            } else if (filters['document-type-select'].value === 'NEWS') {
                const res = await api.reports.getNews({
                    page,
                    size,
                    filters: { ...filters, global: filters['news-group']?.value === 'GLOBAL_NEWS' ? true : filters['news-group']?.value === 'PROJECT_NEWS' ? false : null },
                    cancelToken: ReportService.cancelToken.token,
                });

                setReportsTotal(res.news.totalElements);
                setReports(
                    res.news.content.map((i) => {
                        return {
                            ...i,
                            priority: priority[i.priority],
                            status: i.state === 'ACTIVE' ? 'Активные' : i.state === 'ARCHIVED' ? 'Архивные' : 'Удаленные',
                        };
                    })
                );

                if (!res.news.totalElements) {
                    addError('По вашему запросу нет данных');
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onDownload = async (filters) => {
        try {
            await api.reports.downloadReports(
                filters['document-type-select'].value === 'ARTICLE' ? 'articles' : 'news',
                filters,
                ReportService.cancelToken.token
            );
            setIsDownloadModal(true);
        } catch (error) {
            addError('Произошла ошибка при скачивании отчета');
        }
    };

    const onFiltersChange = (newFilters) => {
        setFilters((prevState) => {
            if (prevState['document-type-select'] && prevState['document-type-select'].value !== newFilters['document-type-select'].value) {
                setReports([]);
                setReportsTotal(0);
            }

            return newFilters;
        });
    };

    const columnsTmp = useMemo(() => {
        const data = [
            {
                Header: 'Документ',
                accessor: 'projectPath',
                isVisible: filters['document-type-select']?.value === 'ARTICLE',
                Cell: ({ row: { original: { state, title, projectPath, uuid, sectionPath } } }) => {
                    const projectPathLinks = projectPath.split('/');
                    const sectionPathIds = sectionPath.split('/');
                    const isArchive = state === 'ARCHIVED';
                    const crumbsData = [
                        {
                            linkTo: isArchive 
                                ? `/archive/${sectionPathIds[0]}/articles` 
                                : `/projects/${sectionPathIds[0]}/articles`,
                            name: projectPathLinks[0],
                        },
                        {
                            linkTo: isArchive 
                                ? `/archive/${sectionPathIds[0]}/${sectionPathIds[1]}/section/articles` 
                                : `/projects/${sectionPathIds[0]}/${sectionPathIds[1]}/section/articles`,
                            name: projectPathLinks[1],
                        },
                        {
                            linkTo: isArchive 
                                ? `/archive/article/` 
                                : `/projects/article/` +
                            uuid,
                            name: projectPathLinks[2],
                        },
                    ]

                    return (
                        <>
                            {state === 'DELETED' && <div>{title}</div>}
                            {state !== 'DELETED' && (
                                <Crumbs data={crumbsData} size='medium' className={crubmsCS.crumbsFree} noTooltip={true} />
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Проект',
                accessor: filters['news-group']?.value !== 'GLOBAL_NEWS' ? 'projectTitle' : '',
                isVisible: filters['document-type-select']?.value === 'NEWS',
                Cell: (data) => {
                    return data.row.original.projectTitle || '–';
                },
            },
            {
                Header: 'Название новости',
                accessor: 'title',
                isVisible: filters['document-type-select']?.value === 'NEWS',
                Cell: (data) => {
                    return (
                        <>
                            {data.row.original.state === 'DELETED' && <div>{data.row.original.title}</div>}
                            {data.row.original.state !== 'DELETED' && (
                                <Link
                                    className={cx.link}
                                    to={
                                        (data.row.original.state === 'ARCHIVED' ? '/archive/news/' : '/projects/news/') +
                                        data.row.original.uuid
                                    }
                                >
                                    {data.row.original.title}
                                </Link>
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Дата создания',
                accessor: 'createDate',
                Cell: (data) => ReportService.defaultDateColumn('createDate')(data),
            },
            {
                Header: 'Дата изменения',
                accessor: 'modifyDate',
                Cell: (data) => ReportService.defaultDateColumn('modifyDate')(data),
            },
            {
                Header: 'Автор',
                accessor: 'author',
                Cell: (data) => {
                    return data.row.original.activeAuthor ? (
                        <Link className={cx.link} to={'/edit-user/' + data.row.original.login}>
                            {data.row.original.author}
                        </Link>
                    ) : (
                        data.row.original.author
                    );
                },
            },
            {
                Header: 'Бегущая строка',
                accessor: 'ticker',
                isVisible: filters['document-type-select']?.value === 'NEWS',
                Cell: (data) => {
                    return data.row.original.ticker ? 'Да' : 'Нет';
                },
            },
            {
                Header: 'Приоритет',
                accessor: 'priority',
                isVisible: filters['document-type-select']?.value === 'NEWS',
            },
            {
                Header: 'Статус',
                accessor: 'status',
            },
        ];

        setSortByOptions((prevState) => {
            const newState = data
                .filter((col) => col.accessor && col.isVisible !== false)
                .map((column) => ({ label: column.Header, value: column.accessor }));

            if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
                setFilters((prevState) => ({ ...prevState, sortBy: newState.find(col => col.value === 'createDate') }));
            }

            return newState;
        });

        return data;
    }, [filters]);

    const filtersTmp = useMemo(() => {
        return [
            {
                fields: [
                    {
                        labels: ['Дата создания документа', 'От', 'До'],
                        name: 'createTime',
                        type: 'date-range',
                        default: filters['createTime'] || {
                            type: {
                                label: 'За сегодня',
                                value: 1,
                            },
                            from: new Date(new Date().setHours(0, 0, 0)).toISOString(),
                            to: new Date(new Date().setHours(23, 59, 59)).toISOString()
                        },
                    },
                ],
                width: '100%',
            },
            {
                label: 'Тип документа',
                fields: [
                    {
                        name: 'document-type-select',
                        type: 'document-type-select',
                        options: objectTypes,
                        default: filters['document-type-select'] || objectTypes[0],
                        required: true,
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'articles-group',
                        type: 'articles',
                        options: articleSearchBy,
                        default: filters['articles-group'] || articleSearchBy[0],
                    },
                ],
                width: '100%',
            },
            {
                label: '',
                fields: [
                    {
                        name: 'news-group',
                        type: 'news',
                        options: newsSearchBy,
                        default: filters['news-group'] || newsSearchBy[1],
                    },
                ],
                width: '100%',
            },
            {
                label: 'Автор',
                fields: [
                    {
                        name: 'authors',
                        type: 'user-select',
                        default: filters['authors'] || null,
                    },
                ],
                width: '100%',
            },
            {
                label: 'Статус',
                fields: [
                    {
                        name: 'status',
                        type: 'select',
                        options: documentStatusOptions,
                        default: filters['status'] || documentStatusOptions[0],
                    },
                ],
                width: '50%',
            },
            {
                label: 'Приоритет новости',
                fields: [
                    {
                        name: 'priority',
                        type: 'select',
                        options: newsPriorityStatusOptions,
                        default: filters['priority'] || newsPriorityStatusOptions[0],
                    },
                ],
                width: '50%',
                isHidden: filters['document-type-select']?.value !== 'NEWS',
            },
            {
                label: 'Бегущая строка',
                fields: [
                    {
                        name: 'ticker',
                        type: 'select',
                        options: newsTickerOptions,
                        default: filters['ticker'] || newsTickerOptions[0],
                    },
                ],
                width: '50%',
                isHidden: filters['document-type-select']?.value !== 'NEWS',
            },
            {
                label: 'Сортировать по',
                fields: [
                    {
                        name: 'sortBy',
                        type: 'select',
                        options: sortByOptions,
                        default: filters['sortBy'] || {label: 'Дата создания', value: 'createDate'},
                    },
                ],
                width: '25%',
            },
            {
                label: 'Порядок',
                fields: [
                    {
                        name: 'sort',
                        type: 'select',
                        options: sortTypesOptions,
                        default: filters['sort'] || sortTypesOptions[0],
                    },
                ],
                width: '25%',
            },
        ];
    }, [sortByOptions, filters]);

    useEffect(() => {
        ReportService.init('CONTENT_REPORT');

        return () => {
            ReportService.destroy();
        };
    }, []);

    return (
        <ReportsComponent
            isEmpty={!reportsTotal}
            isLoading={isLoading}
            columns={columnsTmp}
            data={reports}
            total={reportsTotal}
            filters={filtersTmp}
            onDownload={onDownload}
            onPaginate={getContentReports}
            onChangeFilters={onFiltersChange}
        />
    );
};

export default StatisticReportsContentReports;
