import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

export const useAutoNavigate = (_idsToOpen, treeRef) => {
    const [idsToOpen, setIdsToOpen] = useState(_idsToOpen);

    useEffect(() => {
        setIdsToOpen(prevState => {
            if (isEqual(prevState, _idsToOpen)) {
                return prevState;
            } else {
                return _idsToOpen;
            }
        });
    }, [_idsToOpen, setIdsToOpen]);

    useEffect(() => {
        let handler;
        new Promise(resolve => {
            function tryTree() {
                const tree = treeRef?.current?.tree;

                if (tree) {
                    resolve(tree);
                } else {
                    handler = setTimeout(tryTree, 300);
                }
            }

            tryTree();
        }).then(tree => {
            if (Array.isArray(idsToOpen) && idsToOpen.length > 0) {
                const interval = setInterval(() => {
                    idsToOpen.forEach((id, i) => {
                        const node = tree.getNodeById(id);

                        if (node) {
                            tree.openNode(node, { silent: true });
                            if (i + 1 === idsToOpen.length && (!tree.getSelectedNode() || tree.getSelectedNode().id !== node.id)) {
                                tree.selectNode(node, { silent: true });
                                // tree.scrollToNode(node);
                            }

                            clearInterval(interval);
                        }
                    });
                }, 500);
            }
        });

        return () => {
            if (handler) {
                clearTimeout(handler);
            }
        };
    }, [idsToOpen, treeRef]);
};
