import React, { useState } from 'react';
import BackButton from 'components/back-button';
import Button from 'uikit/button';
import Input from 'uikit/input';
import Checkbox from 'uikit/checkbox';
import cx from './roles-page-edit-role.module.scss';

const RolesPageEditRole = ({ role, isReadOnly, onCloseHandler, onSubmitHandler }) => {
    const [perm, setRules] = useState(role.permissions);

    const onCheckboxChange = (status, rule, idx) => {
        const _rules = [...perm];
        const editingRule = _rules.find(item => item.permName === rule.permName);

        editingRule.values[idx] = status;
        setRules(_rules);
    };

    const onClose = () => {
        onCloseHandler(false);
    };

    const onSubmit = () => {
        onSubmitHandler();
        onCloseHandler();
    };

    return (
        <>
            <div className={cx.header}>
                <BackButton className={cx.backBtn} onClick={onClose}/>
                <h1>{role.roleName !== '' ? role.roleName : 'Создание роли'}</h1>
            </div>
            <div className={cx.contentWrapper}>
                <div className={cx.formContainer}>
                    <div className={cx.formHeader}/>
                    <div className={cx.formBody}>
                        <h2>Информация</h2>
                        <Input className={cx.bottomDivider} label="Название роли" defaultValue={role.roleName}
                               disabled={isReadOnly}/>
                        <h2>Уровень доступа</h2>
                        <p>Настройте права доступа для каждого компонента системы, используя флажки</p>
                        <table className={cx.rulesTable}>
                            <thead>
                            <tr>
                                <th/>
                                <th>Просмотр</th>
                                <th>Создание</th>
                                <th>Изменение</th>
                                <th>Удаление</th>
                            </tr>
                            </thead>
                            <tbody>
                            {role.permissions.map((rule) => {
                                return (
                                    <tr>
                                        <td>{rule.permName}</td>
                                        {rule.values.map((value, idx) => {
                                            return (
                                                <td>
                                                    {value !== null &&
                                                    <Checkbox type="checkbox" disabled={isReadOnly} checked={value}
                                                              onChange={(e) => onCheckboxChange(e.target.checked, rule, idx)}/>}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                    {!isReadOnly && <div className={cx.formFooter}>
                        <Button label="Отмена" onClick={onClose}/>
                        <Button label="Подтвердить" color="green" onClick={onSubmit}/>
                    </div>}
                </div>
            </div>
        </>
    );
};

export default RolesPageEditRole;
