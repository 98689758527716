import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cs from './card.module.scss';

const Card = ({ children, className, id }) => {
    return (
        <div id={id} className={cn(cs.card, className)}>
            {children}
        </div>
    );
};

Card.defaultProps = {
    className: '',
    id: ''
};

Card.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    id: PropTypes.string
};

export default Card;