import React, { useRef, useState } from 'react';
import { useRequestAnimationFrame } from 'lib/hooks/useRequestAnimationFrame';
import cx from './video-player.module.scss';

export const Bar = ({ rewind = true, currentTime, watchedTime = 0, getDuration, getCurrentTime, setCurrentTime }) => {
  const progressRef = useRef(null);
  const [progress, setProgress] = useState(0);
  // const [blockedProgress, setBlockedProgress] = useState((watchedTime / (getDuration() ?? 0)) * 100 || 100);

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = progressRef.current;

    if (bar) {
      const barStart = bar.getBoundingClientRect().left + window.scrollX;
      const barWidth = bar.offsetWidth;

      const clickPositionInBar = clickPositionInPage - barStart;
      const timePerPixel = (getDuration() ?? 0) / barWidth;

      return timePerPixel * clickPositionInBar;
    }

    return 0;
  }

  const formatTime = s => {
    var seconds = parseInt(s, 10);
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds - hours * 3600) / 60);
    seconds = seconds - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    var time = hours + ':' + minutes + ':' + seconds;
    return time;
  };

  function handleTimeDrag(e) {
    const clickedTime = calcClickedTime(e);

    if (!rewind && clickedTime > watchedTime) {
      return;
    }

    setCurrentTime(clickedTime);

    const updateTimeOnMove = eMove => {
      const clickedTime = calcClickedTime(eMove);

      if (!rewind && clickedTime > watchedTime) {
        return;
      }
      setCurrentTime(clickedTime);
    };

    document.addEventListener('mousemove', updateTimeOnMove);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  }

  useRequestAnimationFrame(() => {
    // if (!rewind) {
    //   setBlockedProgress((watchedTime / (getDuration() ?? 0)) * 100);
    // }
    setProgress(((getCurrentTime() ?? 0) / (getDuration() ?? 0)) * 100);
  });

  return (
    <div className={cx.barContainer}>
      <span className={cx.timeStart}>{formatTime(currentTime)}</span>
      <div ref={progressRef} className={cx.progress} onMouseDown={e => handleTimeDrag(e)}>
        <div className={cx.progressPosition} style={{ width: `${progress}%` }}></div>
      </div>
      <span className={cx.timeEnd}>{formatTime(getDuration() ?? 0)}</span>
    </div>
  );
};
