import React from 'react';
import { MenuButton } from './MenuButton';
import Icon, { Icons } from 'uikit/icon';
import { useEditorContext } from '../../context';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import classNames from 'classnames';

import cx from '../../editor.module.scss';
import Tippy from '@tippyjs/react';

export const MenuButtonFormat = ({ mode, currentMenu, setCurrentMenu, isFullScreen, _isTooltip }) => {
    const editor = useEditorContext();

    const format = e => {
        e.stopPropagation();
        setCurrentMenu(undefined);

        editor?.chain().focus().copyFormat().run();
    };

    const cleanFormat = e => {
        e.stopPropagation();
        setCurrentMenu(undefined);

        editor?.chain().focus().unsetAllMarks().run();
    };

    return (
        <Menu
            nested={true}
            mode={mode}
            name="format"
            currentMenu={currentMenu}
            setCurrentMenu={name => {
                if (name !== 'format') {
                    setCurrentMenu('sub');
                } else {
                    setCurrentMenu(name);
                }
            }}
            label={
                <Tippy content="Форматирование" theme="editor-button" appendTo="parent" disabled={mode !== 'DESKTOP'}>
                    {mode === 'DESKTOP' && isFullScreen ? (
                        <div className={classNames('editor-button', cx.editorMenuButton)}>
                            <Icon type={Icons.EditorIconFormat} width={18} height={18} />
                            <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                                Форматирование
                            </span>
                            <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div className={classNames('editor-button', cx.editorMenuButton)}>
                                <Icon type={Icons.EditorIconFormat} width={18} height={18} />
                                <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                                    Форматирование
                                </span>
                            </div>
                            <span aria-hidden style={{ display: 'flex', marginRight: 6 }}>
                                <Icon type={Icons.EditorIconArrowRight} width={12} height={12} />
                            </span>
                        </div>
                    )}
                </Tippy>
            }
            menuButtonStyles={mode === 'DESKTOP' && isFullScreen ? null : { width: '100%' }}
            menuContentStyles={mode === 'DESKTOP' && isFullScreen ? null : { overflow: 'initial', minWidth: 254 }}
        >
            <MenuItem
                styles={{ padding: '0 6px' }}
                label={<MenuButton icon={Icons.EditorIconFormat} label="Копировать" onClick={format} />}
            />
            <MenuItem
                styles={{ padding: '0 6px' }}
                label={<MenuButton icon={Icons.EditorIconCleanFormat} label="Очистить" onClick={cleanFormat} />}
            />
        </Menu>
    );
};
