import ApiClient from './api-axios';

export default class SnapshotApi extends ApiClient {
    getArticlesSnapshots = async (uuid) => {
        return await this.get('article/snapshots/' + uuid);
    };

    getArticleSnapshot = async (uuid) => {
        return await this.get('article/snapshot/' + uuid);
    };

    applyArticleSnapshot = async (uuid) => {
        return await this.post('article/snapshot/apply/' + uuid);
    };

    removeArticleSnapshot = async (uuid) => {
        return await this.post('article/snapshot/delete/' + uuid);
    };

    getArticleSnapshotsDiff = async (previousSnapshotId, nextSnapshotId) => {
        return await this.get('article/snapshot/diff/?left=' + previousSnapshotId + '&right=' + nextSnapshotId);
    };

    getNewsSnapshots = async (uuid) => {
        return await this.get('news/snapshots/' + uuid);
    };

    getNewsSnapshot = async (uuid) => {
        return await this.get('news/snapshot/' + uuid);
    };

    applyNewsSnapshot = async (uuid) => {
        return await this.post('news/snapshot/apply/' + uuid);
    };

    removeNewsSnapshot = async (uuid) => {
        return await this.post('news/snapshot/delete/' + uuid);
    };

    getNewsSnapshotsDiff = async (previousSnapshotId, nextSnapshotId) => {
        return await this.get('news/snapshot/diff/?left=' + previousSnapshotId + '&right=' + nextSnapshotId);
    };
}