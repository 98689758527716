import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import formatDateTime from 'lib/helpers/formatDateTime';
import classNames from 'classnames';
import { fetchMenuButtons, selectUsers } from 'slice/authSlice';
import { Link, useNavigate } from '@reach/router';
import PropTypes from 'prop-types';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import { useAuthorized, useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { Empty } from 'components/projects/index';
import Confirmation from 'components/confirmation';
import { TableComponent, NewsComponent } from 'components/data-components';
import Icon, { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api/index';
import cx from './tab-news.module.scss';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import Loading from 'uikit/loading';

const TabNews = ({ projectId }) => {
    const tableRef = useRef(null);
    const selector = useSelector(state => state);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [/* project */, setProject] = useState();

    const { isAuthorizedAction } = useAuthorized();
    const { addSuccess, addError } = useMessage();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { platform } = useGlobalContext();

    const [users, setUsers] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [, setIsInit] = useState(false);

    const [isReLoading, setIsReLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const [type, setType] = useState(0);

    const [newsTotal, setNewsTotal] = useState(0);
    const [news, setNews] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const isCreateAuthorized = isAuthorizedAction([GLOBAL_ACTIONS.NEWS_CREATE]);

    const requestNews = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        setIsLoading(true);

        const newsResponse = await api.news.getProjectNews(projectId, offset / count, count,
            sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : 'modifyTime,desc', search, filters);

        const project = await api.project.getProject(projectId);

        setProject(project);

        setNewsTotal(newsResponse.totalElements);
        setNews(newsResponse.content);

        const filtersClone = structuredClone(filters);
        delete filtersClone['readied.in'];

        setIsEmpty(newsResponse.totalElements === 0 && _.isEmpty(filtersClone) && !search);

        setIsLoading(false);
        setIsReLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
          if (prevValue.some(c => c.id === node.id)) {
              return prevValue.filter(c => c.id !== node.id);
          } else {
              return [...prevValue, node]
          }
        })
    }, [])

    const archiveNews = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.archive.moveNewsToArchive(data.id);
            }  else if (checkedCheckboxRef.current.length) {
                await api.archive.moveToArchive(data.map(article => article.id));
            }

            dispatch(updateNewsTicker(true));
            dispatch(fetchMenuButtons());

            setNews([]);
            tableRef?.current?.reload();

            addSuccess(data?.id ? 'Новость перемещена в архив' : 'Выбранные новости перенесены в архив');
        } catch (error) {
            addError('При архивировании произошла ошибка')
        } finally {
            setIsLoading(false);
        }

      setIsLoading(true);
      closeDialog();
    }, [dispatch, closeDialog, addSuccess, addError])
    const onNewsArchive = useCallback(async (data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            {item.original.project ? item.original.project.title + ' / ' : 'Общая новость / '}
                            <span style={{ color: '#279BD9' }} onClick={() => navigate(`/projects/news/${item.original['id']}`)}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите перенести в архив новость&nbsp;
                    <span style={{ color: '#279BD9' }} onClick={() => navigate(`/projects/news/${data['id']}`)}>
                        {data?.title}
                    </span>
                    &nbsp;? Объекты в архиве могут быть восстановлены
                </span>
            );
        }

        openDialog({
            title: 'Архивирование',
            subTitle: data?.length ? 'Вы действительно хотите переместить в архив нижеперечисленные объекты? Объекты в архиве могут быть восстановлены' : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onChange: (news) => checkboxHandler(news),
            onSubmit: () => archiveNews(data?.length ? checkedCheckboxRef.current : data),
            onClose: closeDialog
        });
    }, [checkboxHandler, archiveNews, navigate, openDialog, closeDialog]);

    const newsColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title',
            width: 300,
            Cell: (data) => {
                if (data.isMobile) {
                    return (
                        <Link to={`/projects/news/${data.row.original['id']}`}
                              className={classNames(cx.titleBlock, cx.mobileTitleBlock)}>
                            {(data.row.original['priority'] === 'HIGH' || !data.row.original['project']) &&
                            <div className={cx.titleBlockTags}>
                                {data.row.original['priority'] === 'HIGH' &&
                                <span className={cx.yellow}>Срочная новость</span>}
                                {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                            </div>}
                            <p className={cx.title}>{data.row.original['title']}</p>
                            <div className={classNames(cx.titleBlockLogo, cx.logoMobile)}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                        </Link>
                    );
                } else {
                    return (
                        <Link to={`/projects/news/${data.row.original['id']}`} className={cx.titleBlock}>
                            <div className={cx.titleBlockLogo}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                            <div>
                                <div className={cx.titleBlockTags}>
                                    {data.row.original['priority'] === 'HIGH' &&
                                    <span className={cx.yellow}>Срочная новость</span>}
                                    {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                                </div>
                                <MultiClumpTooltip className={cx.title} label={data.row.original['title']} />
                            </div>
                        </Link>
                    );
                }
            }
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime',
            width: 140,
            Cell: (data) => {
                const modifyTime = data.row.original['modifyTime'];
                const createTime = data.row.original['createTime'];

                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        <div>
                            {formatDateTime(modifyTime ? modifyTime : createTime, true)}
                        </div>
                    </div>
                )
            }
        },
        {
            Header: 'Автор',
            accessor: 'multiName',
            width: 130,
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Автор</div>}
                        {data.row.original['author']['status'] === 'DELETED'
                            ? <span style={{opacity: 1}}>{data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}</span>
                            : <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </Link>}
                    </div>
                );
            }
        },
        {
            id: 4,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            width: 100,
            Cell: (data) => {
                let toolbar = [];

                toolbar.push({
                    icon: Icons.EDIT_PEN,
                    tooltip: 'Редактировать',
                    link: `/projects/news/${data.row.original.id}/edit`,
                    isHidden: original => !original?.permissions?.canEdit,
                });

                toolbar.push({
                    icon: data.row.original['favorite'] ? Icons.STAR_FILLED : Icons.STAR,
                    iconActive: data.row.original['favorite'],
                    tooltip: 'В избранное',
                    onClick: async (data) => {
                        if (data.favorite) {
                            await api.favorites.deleteNewsFromFavorites(data.id);
                            addSuccess('Новость удалена из избранного');
                        } else {
                            await api.favorites.addNewsToFavorites(data.id);
                            addSuccess('Новость добавлена в избранное');
                        }

                        const newsCopy = Object.assign([], news);
                        newsCopy.find((item) => item.id === data.id).favorite = !data.favorite;
                        setNews(newsCopy);
                    }
                });

                toolbar.push({
                    icon: Icons.ARCHIVE,
                    tooltip: 'В архив',
                    width: 15,
                    height: 15,
                    onClick: (data) => onNewsArchive(data),
                    isHidden: original => !original?.permissions.canArchive,
                });

                return EditRowToolbar(toolbar)(data);
            }
        }
    ], [addSuccess, news, onNewsArchive, platform]);
    const newsActions = useMemo(() => {
        const actions = [];
        const canArchive = (news ?? []).some(news => news.permissions?.canArchive);

        if (canArchive) {
            actions.push({
                icon: Icons.ARCHIVE,
                label: 'В архив',
                onClick: (rows) => onNewsArchive(rows)
            });
        }

        return actions;
    }, [onNewsArchive, news]);

    const loadUsers = async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
              return user.label.toString().toLowerCase().includes(search.toLowerCase());
            })
        }

        return { options }
    }

    const newsFilters = [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Автор',
            'fields': [
                {
                    'name': 'authorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadUsers
                }
            ]
        },
        {
            'label': 'Приоритет',
            'fields': [
                {
                    'name': 'priority.in',
                    'type': 'select',
                    'default': null,
                    'options': [
                      { label: 'Любой', value: '' },
                      { label: 'Срочные новости', value: 'HIGH' },
                      { label: 'Обычные новости', value: 'MEDIUM' },
                    ]
                }
            ]
        },
        {
            'label': 'Срок действия новости',
            'fields': [
                {
                    'name': 'expireTime.greaterOrEqualThan',
                    'type': 'date',
                    'default': null
                },
                {
                    'name': 'expireTime.lessOrEqualThan',
                    'type': 'date',
                    'default': null
                }
            ]
        },
        {
            'label': 'Статус наличия в бегущей строке',
            'fields': [
                {
                    'name': 'publishTicker.equals',
                    'type': 'select',
                    'default': null,
                    'options': [
                        {label: 'Есть', value: 'true'},
                        {label: 'Нет', value: 'false'},
                        {label: 'Все', value: null},
                    ]
                }
            ]
        },
        {
            'label': 'Наличие вложений',
            'fields': [
                {
                    'name': 'containsAttachment.equals',
                    'type': 'select',
                    'default': null,
                    'options': [
                        {label: 'Есть', value: 'true'},
                        {label: 'Нет', value: 'false'},
                        {label: 'Все', value: null},
                    ]
                }
            ]
        }
    ];

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        const fetchData = async () => {
            const usersResponse = await selectUsers(selector);
            setUsers(usersResponse.content?.map(user => ({
                label: user.firstName + ' ' + user.lastName,
                value: user.login,
            })));
        };
        fetchData();
    }, [selector]);

    useEffect(() => {
        setIsReLoading(true);
        setIsEmpty(false);

        setNewsTotal(0);
        setIsInit(prev => {
            if (!prev) {
                return !prev;
            }

            tableRef?.current?.reload();
            return prev;
        });
    }, [projectId]);

    return (
        <div className={cx.tabNews}>
            <Confirmation {...dialogState} />
            {isLoading && isReLoading && (
                <div className={cx.loader}>
                    <Loading withOverlay={false} />
                </div>
            )}
            {isEmpty && !isLoading && (
                <Empty
                    title="Новости не найдены"
                    description={isCreateAuthorized ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
                    buttons={isCreateAuthorized ? [{
                        icon: Icons.NEWS,
                        title: 'Добавить новость',
                        link: '/add/news'
                    }] : null}
                />
            )}
            {!isEmpty && (
                <div>
                    {type === 0 && (
                        <TableComponent
                            innerRef={tableRef}
                            id="tabNewsTableComponent"
                            isLoading={isLoading}
                            name="news"
                            title="Новости"
                            searchTitle="Поиск по автору и названию"
                            addTitle="Добавить новость"
                            addTitleMobile="Добавить"
                            columns={newsColumns}
                            actions={newsActions}
                            total={newsTotal}
                            data={news}
                            onPaginate={requestNews}
                            filters={newsFilters}
                            isMobile={platform === 'mobile'}
                            onAdd={isAuthorizedAction([GLOBAL_ACTIONS.NEWS_CREATE]) ? () => navigate('/add/news') : undefined}
                            defaultSort={{ by: 'modifyTime', desc: true }}
                            isShowReadiedFilter={true}
                            headContent={(
                                <ul>
                                    <li style={{ background: '#1280CE', borderRadius: '5px' }}>
                                        <Icon type={Icons.BURGER} width={12} height={12} color="white" />
                                    </li>
                                    <li onClick={() => setType(1)}>
                                        <Icon type={Icons.PANEL} width={12} height={12} />
                                    </li>
                                </ul>
                            )}
                            sortOptions={[
                                { Header: 'По дате создания', accessor: 'createTime' },
                                { Header: 'По дате изменения (по умолчанию)', accessor: 'modifyTime' },
                                { Header: 'По названию (по алфавиту)', accessor: 'title' },
                                { Header: 'По автору (по алфавиту по фамилии)', accessor: 'multiName' },
                                { Header: 'По количеству лайков', accessor: 'likesCount' },
                                { Header: 'По количеству дизлайков', accessor: 'dislikesCount' },
                                { Header: 'По популярности (кол-во просмотров)', accessor: 'readCount' },
                                { Header: 'По количеству комментариев', accessor: 'commentsCount' },
                                { Header: 'По рейтингу (оценка пользователя после прочтения)', accessor: '' },
                            ]}
                        />
                    )}
                    {type === 1 && (
                        <NewsComponent
                            isLoading={isLoading}
                            searchTitle="Поиск по автору и названию"
                            addTitle="Добавить новость"
                            total={newsTotal}
                            data={news}
                            onPaginate={requestNews}
                            filters={newsFilters}
                            onAdd={isAuthorizedAction([GLOBAL_ACTIONS.NEWS_CREATE]) ? () => navigate('/add/news') : undefined}
                            onArchive={(item) => onNewsArchive(item)} isMobile={platform === 'mobile'}
                            title="Новости"
                            isShowReadiedFilter={true}
                            headContent={(
                                <ul>
                                    <li onClick={() => setType(0)}>
                                        <Icon type={Icons.BURGER} width={12} height={12} />
                                    </li>
                                    <li style={{ background: '#1280CE', borderRadius: '5px' }}>
                                        <Icon type={Icons.PANEL} width={12} height={12} color="white" />
                                    </li>
                                </ul>
                            )} />
                    )}
                </div>
            )}
        </div>
    );
};

TabNews.defaultProps = {
    projectId: ''
};

TabNews.propTypes = {
    projectId: PropTypes.string
};

export default TabNews;
