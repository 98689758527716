import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Button from 'uikit/button';
import Icon, { Icons } from 'uikit/icon';
import { useDialog, useGlobalContext } from 'lib/hooks';
import { FILES_TYPE_ICONS } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';
import Confirmation from 'components/confirmation';
import { FileViewDialog } from 'components/file/file-view-dialog';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import { ImagePreviewModal, useImagePreviewModal } from 'components/ImagePreviewModal/ImagePreviewModal';
import cs from './script-playback.module.scss';
import api from 'api';

function ScriptPlayback({ elements, preview }) {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const { setGotScriptProgress } = useGlobalContext();

    const [steps, setSteps] = useState([]);
    const [isComplete, setIsComplete] = useState(false);

    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const {
        selectedImageLoading,
        isOpen,
        setIsOpen,
        imgSize,
        imgSizeModifier,
        increaseImg,
        decreaseImg,
        openImageModal,
        imgRef,
    } = useImagePreviewModal();

    const buttonType = {
        positive: 'green',
        negative: 'red',
    };

    const onStepRefreshHandler = (stepIdx) => {
        openDialog({
            color: 'green',
            title: 'Вернуться к шагу',
            text: 'Вы уверены что хотите вернуться к выбранному шагу? Ваш текущий прогресс будет сброшен.',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Да, вернуться',
            onSubmit: () => onStepRefresh(stepIdx),
            onClose: closeDialog,
            contentType: 'TEXT'
        });
    };

    const onStepRefresh = (stepIdx) => {
        setSteps((prevState) => {
            const state = JSON.parse(JSON.stringify(prevState));

            state[stepIdx]['isComplete'] = false;
            state[stepIdx]['conditionNodes'] = state[stepIdx]['conditionNodes'].map((n) => ({ ...n, selected: false }));
            return state.slice(0, stepIdx + 1);
        });

        closeDialog();
    };

    const setNextStep = (condition, stepId) => {
        setSteps((prevState) => {
            const state = JSON.parse(JSON.stringify(prevState));
            const currentStepIndex = state.findIndex((s) => s.id === stepId);

            if (currentStepIndex !== -1) {
                const conditionStepIndex = state[currentStepIndex]['conditionNodes'].findIndex((i) => i.id === condition.id);

                state[currentStepIndex]['isComplete'] = true;
                state[currentStepIndex]['conditionNodes'][conditionStepIndex]['selected'] = true;

                return [...state, ...elements.filter((el) => el.id === condition.targetId)];
            }
        });
    };

    const onFileClickHandler = (file) => {
        setSelectedFile(file);
        setIsViewFileModal(true);
    };

    const onComplete = () => {
        setIsComplete(true);
        setGotScriptProgress(false);
    };

    const onScriptRefreshHandler = () => {
        setSteps((prevState) => {
            const state = JSON.parse(JSON.stringify(prevState));

            state[0]['isComplete'] = false;
            state[0]['conditionNodes'] = state[0]['conditionNodes'].map((n) => ({ ...n, selected: false }));
            return state.slice(0, 1);
        });

        setIsComplete(false);
    };

    useEffect(() => {
        if (elements?.length) {
            setSteps([elements.find(p => p.root) || elements[0]]);
            setIsComplete(false);
        }
    }, [elements]);

    useEffect(() => {
        if (!isViewFileModal) {
            setSelectedFile(null);
        }
    }, [isViewFileModal]);

    // Отслеживание и установка флага прогресса скрипта
    useEffect(() => {
        if (preview) {
            return;
        }

        setGotScriptProgress(steps.length > 1);
    }, [steps, setGotScriptProgress, preview])

    useEffect(() => {
        if (isComplete) {
            const stepsComplete = document.getElementById("blockStepsComplete");

            if (stepsComplete) {
                stepsComplete.scrollIntoView({behavior: "smooth", block: "center"});
            }
        }
    }, [isComplete]);

    useEffect(() => {
        const wrapperBlock = document.querySelectorAll(".step-content-item");

        if (wrapperBlock.length === 0) {
            return;
        }

        wrapperBlock[wrapperBlock.length - 1].scrollIntoView({behavior: "smooth", block: "start"});
    }, [steps]);

    useEffect(() => {
        if (steps.length) {
            const images = document.querySelectorAll('.imagesWrapper img');

            images.forEach((image) => {
                image.addEventListener('click', (e) => openImageModal(e.target));
            });
        }
    }, [steps, openImageModal]);

    return (
        <>
            <Confirmation {...dialogState} />

            <div className={cs.stepWrapper} data-testid="scriptPlaybackStepsWrapper">
                {steps.map((step, idx, arr) => {
                    return (
                        <div className={classNames(cs.step, "step-content-item")} key={step.id} data-testid="scriptPlaybackStep">
                            <div>
                                <div className={cs.stepTitle} data-testid="scriptPlaybackStepTitle">{step.data.text}</div>
                                {idx < arr.length - 1 && !isComplete && (
                                    <div className={cs.stepRefresh} onClick={() => onStepRefreshHandler(idx)} data-testid="scriptPlaybackStepBackBtn">
                                        <Icon color={'blue'} className={cs.stepRefresh} type={Icons.REFRESH} />
                                        Вернуться к шагу
                                    </div>
                                )}
                            </div>

                            {!!step.articles.length && (
                                <div className={cs.articlesWrapper} data-testid="scriptPlaybackStepArticlesWrapper">
                                    {step.articles.map((article) => {
                                        return (
                                            <div key={article.articleId} className={cs.article}>
                                                <a target="_blank" href={`/projects/article/${article['articleId']}`} className={cs.titleBlock} rel="noreferrer">
                                                    <div className={cs.titleBlockInner}>
                                                        {article['logoUuid'] ? (
                                                            <img
                                                                src={api.upload.getImage(article['logoUuid'], false, 512)}
                                                                alt=""
                                                                className={cs.titleBlockCover}
                                                            />
                                                        ) : (
                                                            <img
                                                                alt=""
                                                                className={cs.titleBlockCover}
                                                                src={'/img/article-card-cover.jpg'}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className={cs.titleWrapper}>
                                                        <MultiClumpTooltip clamp={2} label={article.articleTitle} />
                                                        {/* <span>{article}</span> */}
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {!!step.links.length && (
                                <div className={cs.attachmentsWrapper} data-testid="scriptPlaybackStepLinksWrapper">
                                    {step.links.map((link) => {
                                        return (
                                            <div key={link.link} className={cs.linkWrapper}>
                                                <a href={link.link} target="_blank" rel="noreferrer">
                                                    <div className={cs.linkIcon}>
                                                        <Icon type={Icons.LINK} width={13} height={13} color="blue" />
                                                    </div>
                                                    <div>
                                                        <div className={cs.linkName}>{link.linkTitle}</div>
                                                        <div className={cs.linkUrl}>{link.link}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {!!step.files.length && (
                                <div className={cs.filesWrapper} data-testid="scriptPlaybackStepFilesWrapper">
                                    {step.files.map((file) => {
                                        return (
                                            <div key={file.fileId} className={cs.file} onClick={() => onFileClickHandler(file)}>
                                                <div className={cs.fileIconWrapper}>
                                                    <Icon
                                                        type={
                                                            FILES_TYPE_ICONS[file.serverData.fileType || file.serverData.type] ||
                                                            Icons.NONE_FILE
                                                        }
                                                        width={24}
                                                        height={24}
                                                    />
                                                </div>
                                                <div>
                                                    <div className={cs.fileName} onClick={() => {}}>
                                                        {file.serverData.fileName || file.serverData.name}
                                                    </div>
                                                    <div className={cs.fileDescription}>
                                                        {humanFileSize(file.serverData.fileSize || file.serverData.size, true)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            {isViewFileModal && (
                                <FileViewDialog file={selectedFile} isNoButtons={true} isNoActions={true} handleClose={() => setIsViewFileModal(false)} />
                            )}

                            {!!step.images.length && (
                                <div className="imagesWrapper" data-testid="scriptPlaybackStepImagesWrapper">
                                    {step.images.map((image) => {
                                        return (
                                            <div className={cs.imageWrapper}>
                                                <img src={image.imageLink} alt="" />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}

                            <div className={cs.stepButtons}>
                                {step.conditionNodes.map((condition) => {
                                    return (
                                        <Button
                                            color={buttonType[condition.type]}
                                            key={condition.id}
                                            disabled={isComplete || condition.selected ||  step.conditionNodes.some((condition) => condition.selected)}
                                            onClick={() => {
                                                setNextStep(condition, step.id);
                                            }}
                                            data-testid="scriptPlaybackStepNextStepBtn"
                                        >
                                            {condition.data.text}
                                        </Button>
                                    );
                                })}

                                {!step.conditionNodes.length && (
                                    <Button color={'blue'} disabled={isComplete} onClick={onComplete} data-testid="scriptPlaybackStepFinishBtn">
                                        Закончить разговор
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })}
                {isComplete && (
                    <div className={cs.blockStepsComplete} id="blockStepsComplete">
                        <div className={cs.step} data-testid="scriptPlaybackScriptComplete">Разговор завершен</div>
                        <div className={cs.step}>
                            <span className={cs.stepRefresh} onClick={() => onScriptRefreshHandler()} data-testid="scriptPlaybackScriptRefresh">
                                <Icon color={'blue'} className={cs.stepRefresh} type={Icons.REFRESH} />
                                Начать заново
                            </span>
                        </div>
                    </div>
                )}
                {/* Модалка предпросмотра приложенного изображения */}
                <ImagePreviewModal
                    selectedImageLoading={selectedImageLoading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    imgSize={imgSize}
                    imgSizeModifier={imgSizeModifier}
                    increaseImg={increaseImg}
                    decreaseImg={decreaseImg}
                    imgRef={imgRef}
                />
            </div>
        </>
    );
}

export default ScriptPlayback;
