import { useState } from 'react';

export const useManualFormDataChange = ( editFlag = false ) => {
  const [isFormDataTouched, setIsFormDataTouched] = useState(false);

  const handleManualFormDataChange = () => {
      if (editFlag) {
          setIsFormDataTouched(true);
      }
  };

  return { isFormDataTouched, handleManualFormDataChange }
}