import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cx from './loading.module.scss';

export default class Loading extends React.Component {
    static propTypes = {
        active: PropTypes.bool,
        className: PropTypes.string,
        withOverlay: PropTypes.bool,
        small: PropTypes.bool,
        description: PropTypes.string,
        withRelativeOverlay: PropTypes.bool
    };

    static defaultProps = {
        active: true,
        withOverlay: true,
        small: false,
        description: 'Active loading indicator',
        withRelativeOverlay: false
    };

    render() {
        const { active, className, withOverlay, small, description, withRelativeOverlay, size, style = {}, ...other } = this.props;

        const loadingClasses = classNames(cx.loading, className, {
            [cx.loadingSmall]: small,
            [cx.loadingStop]: !active
        });

        const loadingStyles = {
          width: size,
          height: size,
          ...style,
        }

        const overlayClasses = classNames(cx.loadingOverlay, {
            [cx.loadingOverlayStop]: !active
        });

        const relativeOverlayClasses = classNames(cx.loadingOverlay, cx.loadingRelativeOverlay, {
            [cx.loadingOverlayStop]: !active
        });

        const loading = (
            <div {...other} aria-label={description} aria-live={active ? 'assertive' : 'off'} className={loadingClasses} style={loadingStyles}>
                <svg className={cx.loading__svg} viewBox="-75 -75 150 150">
                    <title>Loading</title>
                    {small && <circle className={cx.loading__background} cx="0" cy="0" r="37.5"/>}
                    <circle className={cx.loading__stroke} cx="0" cy="0" r="37.5"/>
                </svg>
            </div>
        );

        if (withOverlay) {
            return <div className={overlayClasses}>{loading}</div>;
        } else if (withRelativeOverlay) {
            return <div className={relativeOverlayClasses}>{loading}</div>;
        } else {
            return loading;
        }
    }
}