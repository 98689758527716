import React from 'react';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button';
import classNames from 'classnames';

import cx from './modal-components.module.scss';

export const ModalHeader = ({ title, onClose, ...props }) => {
    return (
        <div className={cx.modalHeader} {...props}>
            <h2 data-testid="modalTitle">{title}</h2>
            <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14} />} onClick={onClose} id="modalCrestBtn"/>
        </div>
    );
};

export const ModalBody = ({ children, ...props }) => {
    return (
        <div className={cx.modalBody} {...props}>
            {children}
        </div>
    );
};

export const ModalFooter = ({ buttons, justify, ...props }) => {
    return (
        <div className={classNames(cx.modalFooter, cx[justify])} {...props}>
            {buttons?.map((btn, idx) => {
                return <Button key={idx} {...btn} data-testid="modalFooterBtn" />;
            })}
        </div>
    );
};
