import { useState, useEffect } from 'react';
import { isNumber } from 'luxon/src/impl/util';
import { NODE_TYPES } from 'components/trees/infinite-tree/node-type';
import api from 'api';

function parseTreeNodes(raw, withArticles, withSections, withScripts) {
    const parseCommonFields = (raw) => ({
        id: raw.uuid,
        name: raw.title,
        permittedActions: raw.permittedActions,
        logoUuid: raw.logoUuid,
        permissions: raw.permissions,
        unreadCount: raw.unreadCount,
        unread: raw.unread,
    });

    function parseArticles(rawArticles) {
        if (!rawArticles || rawArticles.length === 0) {
            return [];
        }

        return rawArticles.map(article => ({
            ...parseCommonFields(article),
            nodeType: NODE_TYPES.ARTICLE
        }));
    }

    function parseScripts(rawArticles) {
        if (!rawArticles || rawArticles.length === 0) {
            return [];
        }

        return rawArticles.map(article => ({
            ...parseCommonFields(article),
            nodeType: NODE_TYPES.SCRIPTS
        }));
    }

    const parseSection = (raw, isRoot) => {
        return {
            ...parseCommonFields(raw),
            nodeType: NODE_TYPES.SECTION,
            isRoot,
            children: [...(withArticles ? parseArticles(raw.articles) : []), ...(withScripts ? parseScripts(raw.scripts) : []), ...parseSections(raw.subSections, false)]
        };
    };

    function parseSections(rawRootSections, isRoot) {
        if (!rawRootSections || rawRootSections.length === 0) {
            return [];
        }

        return rawRootSections.map(rootSection => ({ ...parseSection(rootSection, isRoot) }));
    }

    function parseProject(rawProject) {
        return {
            ...parseCommonFields(rawProject),
            nodeType: NODE_TYPES.PROJECT,
            children: withSections ? parseSections(rawProject.rootSections, true) : []
        };
    }

    // const objects = raw.filter(o => o.uuid && (!withRights || withRights.length === 0 || withRights.some(test => o.permittedActions.includes(test))));
    // return objects.map(parseProject);

    return raw.map(parseProject);
}

function updateTreeNodes(nodes) {
    return nodes.map((item) => {
        item.id = item.resourceId;
        item.name = item.title;
        item.logoUuid = item.logo;
        item.nodeType = item.resourceType === 'PROJECT'
            ? NODE_TYPES.PROJECT
            : item.resourceType === 'SECTION'
                ? NODE_TYPES.SECTION
                : item.resourceType === 'THEME'
                    ? NODE_TYPES.THEME
                    : item.resourceType === 'QUESTION'
                        ? NODE_TYPES.QUESTION
                        : NODE_TYPES.ARTICLE;

        if (item.children.length > 0) {
            item.children = updateTreeNodes(item.children);
            return item;
        }

        return item;
    });
}

export function useLocationTree({ open, withArticles = false, withSections = true, withScripts = false,
                                    archive = false, fetchTreeFunc, treeInvalid = false, _withRights, isReload }) {
    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState([]);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => { setInitialized(false) }, [isReload]);
    useEffect(() => {
        async function fetchTree() {
            setLoading(true);
            try {
                const response = await fetchTreeFunc();
                setTree(parseTreeNodes(response, withArticles, withSections, withScripts));
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        if ((open && !initialized) || treeInvalid) {
            setInitialized(true);
            // dispatch(setTreeInvalid(undefined));
            fetchTree();
        }
    }, [open, archive, withArticles, withSections, treeInvalid, initialized, fetchTreeFunc, withScripts]);

    return { loading, tree };
}

export function useStatisticsTree(open, withArticles = false, withSections = true, archive = false) {
    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState([]);

    useEffect(() => {
        async function fetchTree() {
            setLoading(true);
            try {
                let response = null;

                response = await api.reports.getStatisticTree();

                setTree(response);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        if (open) {
            fetchTree();
        }
    }, [open, archive, withArticles, withSections]);

    return {
        loading, tree
    };
}

export function useUserAccessTree(open, project) {
    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState([]);

    useEffect(() => {
        async function fetchTree() {
            setLoading(true);
            try {
                const response = await api.project.getUsersDocumentTree();
                const nodes = response.filter(node => node.uuid === project.id);

                const parsedNodes = parseTreeNodes(nodes, false, true);
                setTree(parsedNodes);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        if (open && project) {
            fetchTree();
        }
    }, [open, project]);

    return {
        loading, tree
    };
}

export function useLockTree(objectId, resourceId, resourceType, objectRoleId) {
    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState([]);

    useEffect(() => {
        async function fetchTree() {
            setLoading(true);
            try {
                const response = isNumber(objectId)
                    ? await api.role.getGroupRoleTree(objectId, resourceId, resourceType, objectRoleId)
                    : await api.role.getUserRoleTree(objectId, resourceId, resourceType, objectRoleId);
                    console.log(response)
                setTree(updateTreeNodes([response]));
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }

        if (objectId && resourceId && resourceType) {
            fetchTree();
        }
    }, [objectId, resourceId, resourceType, objectRoleId]);

    return { loading, tree };
}

export function useTrainingTree() {
    const [isInitialized, setIsInitialized] = useState(false);

    const [loading, setLoading] = useState(false);
    const [tree, setTree] = useState([]);

    useEffect(() => {
        if (isInitialized) {
            return;
        }

        async function fetchTree() {
            setLoading(true);

            try {
                const response = await api.training.getTree();
                response.map(p => p.children.map(p1 => p1['children'] = []));

                setTree(updateTreeNodes(response));
                setIsInitialized(true);
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        }
        fetchTree();
    }, [isInitialized]);

    return { loading, tree, setIsInitialized };
}
