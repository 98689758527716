import React from 'react';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';

export const MenuButtonFullScreen = ({ isFullScreen, onChange }) => {
    return (
        <MenuButton
            tooltip="На весь экран"
            icon={isFullScreen ? Icons.EditorIconCollapse : Icons.EditorIconExpand}
            onClick={onChange}
            style={{ padding: 6, whiteSpace: 'nowrap', display: 'flex' }}
            selected={isFullScreen}
        />
    );
};
