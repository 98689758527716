import * as React from 'react';
import {
    autoUpdate,
    flip,
    FloatingList,
    FloatingNode,
    FloatingPortal,
    FloatingTree,
    offset,
    safePolygon,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useFloatingNodeId,
    useFloatingParentNodeId,
    useFloatingTree,
    useHover,
    useInteractions,
    useListItem,
    useListNavigation,
    useMergeRefs,
    useRole,
    useTypeahead,
} from '@floating-ui/react';
import cx from './DropdownMenu.module.scss';
import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';
import classNames from 'classnames';

const MenuContext = React.createContext({
    getItemProps: () => ({}),
    activeIndex: null,
    setActiveIndex: () => {},
    setHasFocusInside: () => {},
    isOpen: false,
});

export const MenuComponent = React.forwardRef(
    ({ children, label, mode, name, currentMenu, setCurrentMenu, selected, nested = false, ...props }, forwardedRef) => {
        const [isOpen, setIsOpen] = React.useState(false);
        const [hasFocusInside, setHasFocusInside] = React.useState(false);
        const [activeIndex, setActiveIndex] = React.useState(null);

        const elementsRef = React.useRef([]);
        const labelsRef = React.useRef([]);
        const parent = React.useContext(MenuContext);

        const tree = useFloatingTree();
        const nodeId = useFloatingNodeId();
        const parentId = useFloatingParentNodeId();
        const item = useListItem();

        const isNested = parentId != null;

        const { floatingStyles, refs, context } = useFloating({
            nodeId,
            open: isOpen,
            onOpenChange: (open, event, reason) => {
                if (mode !== 'DESKTOP' && (!reason || reason === 'outside-press')) {
                    return;
                }

                setIsOpen(open);
            },
            placement: isNested ? 'right-start' : props.placement ? props.placement : 'bottom-start',
            middleware: [offset({ mainAxis: isNested ? 24 : 10, alignmentAxis: isNested ? -4 : 0 }), flip(), shift()],
            whileElementsMounted: autoUpdate,
        });

        const hover = useHover(context, {
            enabled: document.body.offsetWidth > 1024 && isNested,
            delay: { open: 75 },
            handleClose: safePolygon({ blockPointerEvents: true }),
        });

        const click = useClick(context, {
            event: 'mousedown',
            toggle: !isNested,
            ignoreMouse: isNested,
        });

        const role = useRole(context, { role: 'menu' });
        const dismiss = useDismiss(context, { bubbles: true });

        const listNavigation = useListNavigation(context, {
            listRef: elementsRef,
            activeIndex,
            nested: isNested,
            onNavigate: setActiveIndex,
        });

        const typeahead = useTypeahead(context, {
            listRef: labelsRef,
            onMatch: isOpen ? setActiveIndex : undefined,
            activeIndex,
        });

        const { getFloatingProps, getItemProps } = useInteractions([click, hover, role, dismiss, listNavigation, typeahead]);

        const onMenuClick = () => {
            props?.onClick?.();

            if (mode !== 'DESKTOP' && !(typeof label === 'function')) {
                onClose();
            }
        };

        const onClose = isOpen => {
            if (mode !== 'DESKTOP') {
                setIsOpen(prev => {
                    if (!prev && setCurrentMenu) {
                        setCurrentMenu(name);
                    } else if (setCurrentMenu) {
                        setCurrentMenu(undefined);
                    }

                    return !prev;
                });
            } else {
                tree.events.emit('click', isOpen);
            }
        };

        React.useImperativeHandle(forwardedRef, () => ({
            hidemenu: onClose,
        }));

        // Event emitter allows you to communicate across tree components.
        // This effect closes all menus when an item gets clicked anywhere
        // in the tree.
        React.useEffect(() => {
            if (!tree) return;

            function onMenuClose(isOpen) {
                setIsOpen(prev => {
                    if (!prev && setCurrentMenu) {
                        setCurrentMenu(name);
                    } else if (setCurrentMenu) {
                        setCurrentMenu(undefined);
                    }

                    return typeof isOpen === 'boolean' ? isOpen : !prev;
                });
            }

            function onSubMenuOpen(event) {
                if (event.nodeId !== nodeId && event.parentId === parentId) {
                    setIsOpen(false);
                }
            }

            tree.events.on('click', onMenuClose);
            tree.events.on('menuopen', onSubMenuOpen);

            return () => {
                tree.events.off('click', onMenuClose);
                tree.events.off('menuopen', onSubMenuOpen);
            };
        }, [tree, nodeId, parentId, name, setCurrentMenu]);

        React.useEffect(() => {
            if (isOpen && tree) {
                tree.events.emit('menuopen', { parentId, nodeId });
            }
        }, [tree, isOpen, nodeId, parentId]);

        React.useEffect(() => {
            if (mode === 'DESKTOP') {
                return;
            }

            if (currentMenu !== name) {
                setIsOpen(false);
            }
        }, [mode, name, currentMenu, setCurrentMenu]);

        React.useEffect(() => {
            if (!isOpen || mode !== 'DESKTOP') {
                return;
            }

            const onCloseMenu = () => {
                setIsOpen(false);
            };

            document.addEventListener('click', onCloseMenu);

            return () => {
                document.removeEventListener('click', onCloseMenu);
            };
        }, [mode, isOpen]);

        return (
            <FloatingNode id={nodeId}>
                <button
                    ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
                    tabIndex={!isNested ? undefined : parent.activeIndex === item.index ? 0 : -1}
                    role={isNested ? 'menuitem' : undefined}
                    data-open={isOpen ? '' : undefined}
                    data-nested={isNested ? '' : undefined}
                    data-focus-inside={hasFocusInside ? '' : undefined}
                    className={isNested ? classNames(cx.MenuItem, { [cx.selected]: selected }) : cx.RootMenu}
                    style={props.menuButtonStyles}
                    onClick={onMenuClick}
                >
                    {typeof label === 'function' && label(onClose)}
                    {typeof label !== 'function' && <div onClick={mode === 'DESKTOP' ? onClose : undefined}>{label}</div>}
                    {isNested && (
                        <span aria-hidden style={{ display: 'flex', marginRight: 6 }}>
                            <Icon type={Icons.EditorIconArrowRight} width={12} height={12} />
                        </span>
                    )}
                </button>
                <MenuContext.Provider
                    value={{
                        activeIndex,
                        setActiveIndex,
                        getItemProps,
                        setHasFocusInside,
                        isOpen,
                        onClose,
                        mode,
                    }}
                >
                    <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
                        {isOpen && (
                            <FloatingPortal root={isNested ? document.body : props.root}>
                                <div
                                    ref={refs.setFloating}
                                    className={cx.Menu}
                                    style={{ ...floatingStyles, ...props.menuStyles }}
                                    {...getFloatingProps()}
                                    onKeyDown={() => {
                                        return true;
                                    }}
                                >
                                    <div
                                        className={classNames(cx.MenuContent, props.one ? cx.MenuContentOne : null)}
                                        style={props.menuContentStyles}
                                    >
                                        {children}
                                        <div
                                            className={cx.MenuContentClose}
                                            onClick={() => {
                                                if (isNested) {
                                                    setIsOpen(false);
                                                } else {
                                                    onClose();
                                                }
                                            }}
                                        >
                                            {isNested || nested ? 'Назад' : 'Отмена'}
                                        </div>
                                    </div>
                                </div>
                            </FloatingPortal>
                        )}
                    </FloatingList>
                </MenuContext.Provider>
            </FloatingNode>
        );
    }
);

export const MenuItem = React.forwardRef(
    ({ label, disabled, selected, disableHover = false, styles, className, ...props }, forwardedRef) => {
        const menu = React.useContext(MenuContext);
        const item = useListItem({ label: disabled ? null : label });
        const isActive = item.index === menu.activeIndex;

        return (
            <button
                {...props}
                ref={useMergeRefs([item.ref, forwardedRef])}
                type="button"
                role="menuitem"
                className={classNames(cx.MenuItem, className, {
                    [cx.disableHover]: disableHover,
                    [cx.selected]: selected,
                })}
                style={styles}
                tabIndex={isActive ? 0 : -1}
                disabled={disabled}
            >
                {typeof label === 'function' && label(menu.onClose)}
                {typeof label !== 'function' && (
                    <div style={{ width: '100%' }} onClick={menu.mode !== 'DESKTOP' ? menu.onClose : undefined}>
                        {label}
                    </div>
                )}
            </button>
        );
    }
);

export const Menu = React.forwardRef((props, ref) => {
    const parentId = useFloatingParentNodeId();

    if (parentId === null) {
        return (
            <FloatingTree>
                <MenuComponent {...props} ref={ref} />
            </FloatingTree>
        );
    }

    return <MenuComponent {...props} ref={ref} />;
});
