import Tippy from '@tippyjs/react';
import React from 'react';
import { components } from 'react-select';
import cx from './option.module.scss';

const Option = props => {
    return (
        <Tippy content={props.data.label}>
            <div className={cx.optionWrapper}>
                <components.Option {...props} />
            </div>
        </Tippy>
    );
};

export default Option;
