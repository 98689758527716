import React from 'react';
import cx from './about-page.module.scss';

const about = {
    version: 'KMS 1.105.5015.30'
};

const AboutPage = () => {
    return (
        <div className={cx.contentWrapper}>
            <div className={cx.header}>
                <h1>О программе</h1>
            </div>
            <div className={cx.aboutWrapper}>
                <div className={cx.versionWrapper}>
                    <div className={cx.versionTitle}>Версия программы</div>
                    <div>{about.version}</div>
                </div>
            </div>
        </div>
    );
};

export default AboutPage;