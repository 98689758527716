import React, { useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useCombinedRefs, useDebounceValue } from 'lib/hooks';
import Tree from '../infinite-tree/tree';
import { useAutoNavigate } from 'components/trees/utils';
import cs from './statistics-tree.module.scss';
import { tree } from './statistics-tree-data';
import { cloneDeep } from 'lodash'

const StatisticsTree = ({ forwardedRef, scrollToNode, onNavigate, getNodeLink, ...props }) => {
    const treeRef = useCombinedRefs(forwardedRef);
    // const { tree } = useStatisticsTree(true, true, true, props.archive);

    const [debouncedValue] = useDebounceValue('', 300);
    const clonedTreeRef = useRef(cloneDeep(tree));

    useAutoNavigate(scrollToNode, treeRef);

    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(debouncedValue.trim());
        }
    }, [debouncedValue, treeRef]);

    return (
        <div id={'tree-container'} className={cs.tree}>
            <div className={cs.sizerContainer}>
                <AutoSizer>
                    {({ height }) => {
                        return (
                            <Tree
                                height={height}
                                data={clonedTreeRef.current}
                                onUpdate={onNavigate}
                                autoOpen={false} ref={treeRef}
                                wordBreak
                                getNodeLink={getNodeLink}
                                onCheckedChange={() => {}}
                                statistics
                                hideIcons
                                {...props}
                            />
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};

export default React.forwardRef((props, ref) => <StatisticsTree {...props} forwardedRef={ref}/>);
