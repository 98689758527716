import React from 'react';
import axios from 'axios';
import { uniqWith } from 'lodash';
import { DateTime } from 'luxon';
import { months } from 'lib/helpers/formatDateTime';
import { FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import api from 'api';
import { requestActionTypes } from '../vendors/filtersOptions';

export const ReportService = {
    type: null,
    cancelToken: null,

    init: function (type) {
        this.type = type;
        this.cancelToken = axios.CancelToken.source();
    },

    getFileTypes: function () {
        const types = [
            { label: 'Любой', value: null },
            ...Object.keys(FILES_TYPE_SHORT_NAME)
                .filter((v, i, a) => a.indexOf(v) === i)
                .map((key) => {
                    return { label: FILES_TYPE_SHORT_NAME[key], value: key };
                }),
        ];

        return uniqWith(types, (a, b) => a.label === b.label);
    },

    getNews: async function () {
        try {
            const newsRes = await api.news.getNews(0, 9999);

            return newsRes.content.map((n) => {
                return { label: n.title, value: n.id };
            });
        } catch (e) {
            console.log(e);
        }
    },

    getActionsTypes: async function (documentType = 'ARTICLE') {
        try {
            let requestType = requestActionTypes[this.type];

            if (this.type === 'ACTIONS_REPORT') {
                if (documentType === 'ARTICLE') {
                    requestType = 'article';
                } else if (documentType === 'NEWS') {
                    requestType = 'news';
                } else {
                    return;
                }
            }

            const data = await api.reports.getActionsTypes(requestType);
            let types = [];

            types = Object.keys(data)
                .map((key) => ({ label: data[key], value: key }))
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1;
                    } else if (a.label > b.label) {
                        return 1;
                    } else {
                        return 0;
                    }
                });

            types.unshift({ label: 'Любой', value: null });

            return types;
        } catch (e) {
            console.log(e);
        }
    },

    defaultDateColumn: function (key) {
        return (data) => {
            const date = data.row.original[key];

            if (!date) {
                return null;
            }

            const dateTime = DateTime.fromISO(date);
            const dateTimeString = dateTime
                .toFormat('dd .LL., yyyy, HH:mm', { locale: 'ru' })
                .replace(/\.\d\d\./, months[dateTime.month - 1]);

            return <>{dateTimeString}</>;
        };
    },

    destroy: function () {
        this.type = null;
        this.cancelToken.cancel();
    },
};
