import React, { useCallback, useEffect, useState } from 'react';

import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';
import { ImageLinkModal } from '../Modals/ImageLinkModal';
import { ExpandImageModal } from '../Modals/ImagePreview';

import cx from '../../editor.module.scss';

const propsEqualCompare = (prevProps, nextProps) => {
    if (prevProps.selection?.node?.type.name !== 'resizableImage' && nextProps.selection?.node?.type.name !== 'resizableImage') return true;

    if (prevProps.isActive !== nextProps.isActive) return false;
    if (prevProps.selection.from !== nextProps.selection.from) return false;
    if (prevProps.selection.node?.type?.name !== nextProps.selection.node?.type?.name) return false;
    if (prevProps.selection.node.attrs !== nextProps.selection.node.attrs) return false;
    if (prevProps.setCurrentMenu !== nextProps.setCurrentMenu) return false;

    return true;
};

export const BubbleMenuImage = React.memo(({ isActive, selection, setCurrentMenu }) => {
    const editor = useEditorContext();

    const [menuPosition, setMenuPosition] = useState({});

    const [show, setShow] = useState(false);
    const [linkModalShow, setLinkModalShow] = useState(false);

    const toggleModal = () => {
        setCurrentMenu(undefined);
        setShow(show => !show);
    };

    const toggleLinkModal = () => {
        setCurrentMenu(undefined);
        setLinkModalShow(show => !show);
    };

    const onAlignChangeHandler = position => {
        editor?.chain().focus().updateAttributes('resizableImage', { 'data-align': position }).setNodeSelection(selection.$from.pos).run();
        setMenuPosition(getItemPosition(isActive, editor, selection));
    };

    const alignLeftHandler = () => {
        onAlignChangeHandler('left');
    };
    const alignRightHandler = () => {
        onAlignChangeHandler('right');
    };
    const alignCenterHandler = () => {
        onAlignChangeHandler('center');
    };

    const handleSubmit = ({ url }) => {
        editor?.commands.updateAttributes('resizableImage', { link: url });
        toggleLinkModal();
    };

    const removeImage = () => {
        setCurrentMenu(undefined);

        let { tr, selection, schema } = editor?.view.state;
        const from = Math.min(...selection.ranges.map(range => range.$from.pos));
        const to = Math.max(...selection.ranges.map(range => range.$to.pos));
        const type = schema.nodes['paragraph'];
        tr = tr.replaceWith(from, to, type.create());

        editor.view.dispatch(tr);
    };

    const getItemPosition = useCallback(() => {
        if (!isActive || !editor) {
            return {};
        }

        if (selection.node?.type?.name !== 'resizableImage') {
            return {};
        }

        const wrapperDomNode = editor?.view.nodeDOM(selection.$from.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const editorBoundingClientRect = document.getElementById('editor-content').getBoundingClientRect();
        const boundingClientRect = wrapperDomNode.firstChild.querySelector('img').getBoundingClientRect();
        const bubbleClientRect = document.getElementById('editorBubbleMenuImage').getBoundingClientRect();

        return {
            top: boundingClientRect.top - editorBoundingClientRect.top + boundingClientRect.height + 6 + 'px',
            left:
                boundingClientRect.left - editorBoundingClientRect.left + boundingClientRect.width / 2 - bubbleClientRect.width / 2 + 'px',
        };
    }, [selection, editor, isActive]);

    useEffect(() => {
        if (isActive) {
            setMenuPosition(getItemPosition());
        }
    }, [isActive, getItemPosition]);

    return (
        <div
            id="editorBubbleMenuImage"
            className={cx.bubbleMenu}
            style={{
                position: 'absolute',
                display: 'flex',
                opacity: menuPosition.top && isActive ? 1 : 0,
                pointerEvents: menuPosition.top && isActive ? 'initial' : 'none',
                alignItems: 'center',
                cursor: 'pointer',
                ...menuPosition,
            }}
        >
            <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconBubbleAlignLeft} onClick={alignLeftHandler} />
            <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconBubbleAlignCenter} onClick={alignCenterHandler} />
            <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconBubbleAlignRight} onClick={alignRightHandler} />
            <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconLinkAttach} onClick={toggleLinkModal} />
            <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconExpand} onClick={toggleModal} />
            <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
            <MenuButton className={cx.bubbleMenuButton} icon={Icons.EditorIconTrash} onClick={removeImage} />
            {show && <ExpandImageModal src={editor?.getAttributes('resizableImage').src} onClose={toggleModal} />}
            {linkModalShow && (
                <ImageLinkModal
                    show={linkModalShow}
                    data={{ linkUrl: editor?.getAttributes('resizableImage').link }}
                    onSubmit={handleSubmit}
                    onClose={toggleLinkModal}
                />
            )}
        </div>
    );
}, propsEqualCompare);
