import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from '@reach/router';
import { saveAs } from 'file-saver';
import classNames from 'classnames';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { navigate } from '@reach/router';
import axios from 'axios';
import { humanFileSize } from 'lib/helpers';
import { FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from 'lib/config/constant';
import { useAuthorized, useGlobalContext, useMessage } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import AudioPlayer from 'uikit/audio';
import Loading from 'uikit/loading/loading';
import IconButton from 'uikit/icon-button';
import { FileEditDialog } from 'components/file/file-edit-dialog';
import FileChangeDialog from 'components/file/file-change-dialog';
import FileCommentsList from 'components/file/file-comments-list';
import FileUsageList from 'components/file/file-usage-list';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import api from 'api/index';
import style from './file-view-dialog.module.scss';
import { iFrameDomContentLoaded } from 'lib/util/iframeDomContentLoaded.util';
import MultiClamp from 'react-multi-clamp';

export const FileViewDialog = ({ file: _file, handleClose, onChange, onRemove, isNoActions = false, isNoButtons = false }) => {
    const { platform } = useGlobalContext();

    const playerRef = useRef(null);
    const imgRef = useRef(null);
    const videoRef = useRef(null);

    const { addSuccess } = useMessage();
    const newsPicker = useSelector(selectNewsPicker);
    const { isAuthorizedAction } = useAuthorized();

    const hostname = window.location.hostname.includes('localhost')
        ? 'http://localhost:3000'
        : window.location.protocol + '//' + window.location.hostname;

    const [isReplaceFileModal, toggleReplaceFileModal] = useState(false);
    const [isEditFileModal, toggleEditFileModal] = useState(false);
    const [isFileInfoShow, setIsFileInfoShow] = useState(false);
    const [isCommentsShow, setIsCommentsShow] = useState(false);
    const [isUsageModalOpen, toggleIsUsageModalOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const [isTempIdLoaded, setIsTempIdLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const [file, setFile] = useState(null);

    const [filePreview, setFilePreview] = useState([]);
    const [imgSize, setImgSize] = useState({ width: '75%', height: 'auto', size: 100 });
    const [imgSizeModifier, setImgSizeModifier] = useState(1);
    const [fileViewType, setFileViewType] = useState(null);

    const getTableHTML = useCallback(async (file, cancelToken) => {
        const filePreviewRes = await api.filePreview.getFilePreview(file.fileId, false, true, cancelToken.token);

        if (filePreviewRes && filePreviewRes['status'] === 'IN_PROGRESS') {
            setTimeout(() => {
                getTableHTML(file, cancelToken);
            }, 1000);
        }

        if (filePreviewRes && filePreviewRes['status'] === 'SUCCESS') {
            const container = document.getElementById('documentContent');
            const frame = document.createElement('iframe');

            frame.id = 'documentTable';
            frame.style.width = '100%';
            frame.style.height = '100%';
            frame.style.backgroundColor = '#fff';
            frame.style.overflow = 'hidden';

            frame.setAttribute('srcDoc', filePreviewRes.html);
            frame.setAttribute('title', 'documentTable');

            container.appendChild(frame);

            iFrameDomContentLoaded(frame, onIframeDomContentLoaded);
            setIsTempIdLoaded(true);
        }

        if (filePreviewRes && filePreviewRes['status'] === 'FAILED') {
            setIsError(true);
        }
    }, []);

    const getDocumentPreview = useCallback(async (file, cancelToken) => {
        const filePreviewRes = await api.filePreview.getFilePreview(file.fileId, false, false, cancelToken.token);

        if (filePreviewRes && filePreviewRes['status'] === 'IN_PROGRESS') {
            setTimeout(() => {
                getDocumentPreview(file, cancelToken);
            }, 1000);
        }

        if (filePreviewRes && filePreviewRes['status'] === 'SUCCESS') {
            setFilePreview(filePreviewRes.fileList);
            setIsTempIdLoaded(true);
        }

        if (filePreviewRes && filePreviewRes['status'] === 'FAILED') {
            setIsError(true);
        }
    }, []);

    const download = useCallback(
        async (replaced, file, type, cancelToken) => {
            setIsTempIdLoaded(false);

            const tableFrame = document.getElementById('documentTable');

            if (tableFrame) {
                tableFrame.parentElement.removeChild(tableFrame);
            }

            if (type) {
                try {
                    switch (type) {
                        case 'TABLE':
                            if (replaced) {
                                await api.filePreview.getFilePreview(file.fileId, true);
                            }

                            getTableHTML(file, cancelToken);

                            break;
                        case 'DOCUMENT':
                            setImgSize({ width: '702px', height: 'auto' });

                            if (replaced) {
                                await api.filePreview.getFilePreview(file.fileId, true);
                            }

                            getDocumentPreview(file, cancelToken);
                            break;

                        case 'IMAGE':
                            setIsTempIdLoaded(true);
                            break;
                        default:
                            setIsTempIdLoaded(true);

                            if (playerRef.current) {
                                playerRef.current.load();
                            }
                            break;
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        [getDocumentPreview, getTableHTML]
    );

    const fetchFile = useCallback(
        async (fileId) => {
            const cancelToken = axios.CancelToken.source();

            try {
                const res = await api.file.getFileByUuid(fileId, cancelToken.token);

                setFile({ ...res, createTime: new Date(res.createTime).toLocaleString() });

                setFileViewType(() => {
                    const type = getFileViewType(res);

                    download(false, res, type, cancelToken);

                    return type;
                });
            } catch (e) {
                console.log(e);
            }
        },
        [download]
    );

    const onIframeDomContentLoaded = () => {
        const frame = document.getElementById('documentTable');
        const links = frame.contentWindow.document.querySelectorAll('a');

        links.forEach((link) => {
            if (link?.href.includes('#') && link?.href.includes(window.location.pathname)) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();

                    const anchorId = this.href.slice(this.href.lastIndexOf('#') + 1);

                    let anchorEl =
                        frame.contentWindow.document.getElementById(anchorId) ||
                        frame.contentWindow.document.getElementsByName(anchorId)[0] ||
                        null;

                    if (anchorEl) {
                        anchorEl = anchorEl.closest('tr') || anchorEl.closest('thead') || anchorEl;
                        const elOffsetTop = anchorEl.getBoundingClientRect().top;

                        if (frame) {
                            frame.contentWindow.scrollTo({ top: elOffsetTop, behavior: 'smooth' });
                        }
                    }
                });
            } else if (!link.target) {
                link.target = '_top';
            }
        });
    };

    const saveFile = async () => {
        const resp = await api.file.download(file.fileId);

        saveAs(resp, file.name);
    };
    const onImageLoaded = () => {
        const content = document.getElementById('documentContent');
        const width = imgRef.current.naturalWidth;
        const height = imgRef.current.naturalHeight;
        const offsetWidth = content?.offsetWidth;
        const offsetHeight = content?.offsetHeight;

        if (fileViewType === 'DOCUMENT') {
            setImgSize({
                width: (offsetWidth / 100) * 75,
                height: 'auto',
                size: 100,
            });
        } else if (width > height) {
            if (width < offsetWidth - (offsetWidth / 100) * 75) {
                setImgSize({
                    width: width,
                    height: 'auto',
                    size: 100,
                });
            } else {
                setImgSize({
                    width: (offsetWidth / 100) * 75,
                    height: 'auto',
                    size: 100,
                });
            }
        } else {
            if (height < (offsetHeight / 100) * 75) {
                setImgSize({
                    width: 'auto',
                    height: height,
                    size: 100,
                });
            } else {
                setImgSize({
                    width: 'auto',
                    height: (offsetHeight / 100) * 75,
                    size: 100,
                });
            }
        }

        // setIsImgLoaded(true);
    };

    const increaseImg = () => {
        if (imgSizeModifier >= 2) {
            return;
        }

        setImgSizeModifier((prevState) => prevState + 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size + 25 }));
    };
    const decreaseImg = () => {
        if (imgSizeModifier <= 0.5) {
            return;
        }

        setImgSizeModifier((prevState) => prevState - 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size - 25 }));
    };

    const onError = () => {
        setIsError(true);
    };
    const editFile = () => {
        toggleEditFileModal(!isEditFileModal);
    };

    const onNameChange = (name) => {
        setFile(() => {
            if (onChange) {
                onChange({ ...file, name });
            }

            return { ...file, name };
        });
    };

    const onFileChange = async (f) => {
        const cancelToken = axios.CancelToken.source();
        const type = getFileViewType(f);
        console.log(f);
        setFile({ ...file, ...f });
        setFileViewType(type);

        download(true, f, type, cancelToken);

        if (onChange) {
            onChange({ ...file, ...f });
        }
    };

    const removeFileHandler = async () => {
        try {
            await api.file.removeFile(file.fileId);
            onRemove(file.fileId);
            addSuccess('Файл удален');
        } catch (e) {
            console.log(e);
        }

        handleClose();
    };

    const toggleFileInfo = () => {
        setIsCommentsShow(false);
        setIsFileInfoShow(!isFileInfoShow);
    };
    const toggleComments = () => {
        setIsFileInfoShow(false);
        setIsCommentsShow(!isCommentsShow);
    };
    const toggleUsage = async () => {
        toggleIsUsageModalOpen(!isCommentsShow);
    };
    const toggleReplace = () => {
        toggleReplaceFileModal(!isReplaceFileModal);
    };

    const print = () => {
        const div = document.getElementById('documentContent').cloneNode(true);
        const iframe = window.frames['printIframe'];

        const images = div.querySelectorAll('img');

        for (let i = 0; i < images.length; i++) {
            images[i].style['width'] = isNaN(imgSize.width) ? imgSize.width : imgSize.width * 1 + 'px';
            images[i].style['height'] = isNaN(imgSize.height) ? imgSize.height : imgSize.height * 1 + 'px';
        }

        iframe.document.write('<style>@media print { @page { margin: 0; }}</style>' + div.innerHTML);
        iframe.document.close();

        window.frames['printIframe'].focus();
        window.frames['printIframe'].onload = () => window.frames['printIframe'].print();
    };

    const getFileViewType = (file) => {
        const isTable = ['ODS', 'XLS', 'XLSX'].some((type) => type === FILES_TYPE_SHORT_NAME[file.type]);
        const isDocument = !isTable && !file.type.includes('image/') && !file.type.includes('audio/') && !file.type.includes('video/');

        if (isTable) {
            return 'TABLE';
        } else if (isDocument) {
            return 'DOCUMENT';
        } else if (file.type.includes('image/')) {
            return 'IMAGE';
        } else if (file.type.includes('audio/')) {
            return 'AUDIO';
        } else if (file.type.includes('video/')) {
            return 'VIDEO';
        }

        return null;
    };

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        if (!file) {
            fetchFile(_file.fileId || _file.serverData.id, cancelToken);
        }

        return () => cancelToken.cancel();
    }, [file, _file, download, fetchFile]);
    useEffect(() => {
        if (fileViewType !== 'VIDEO' || videoRef.current === null) {
            return;
        }

        (async () => {
            let fileData = await api.file.download(file.fileId);
            fileData = fileData.slice(0, fileData.size, file.type);
            videoRef.current.src = URL.createObjectURL(fileData);
        })();
    }, [videoRef, fileViewType, file]);

    return (
        <div id="fileViewDialog" data-testid="fileViewDialogModal" className={classNames(style.modalWrapper, { [style.withNewsTicker]: newsPicker.count > 0 })}>
            <div className={style.modalHeaderTablet}>
                <div className={style.top}>
                    <div className={style.title} data-testid="fileViewDialogModalTitle">
                        <MultiClamp clamp={1} disableCssClamp>
                            {file?.name.replace(/\.[^/.]+$/, "")}
                        </MultiClamp>
                        <p><span>{FILES_TYPE_SHORT_NAME[file?.type]}</span> — {humanFileSize(file?.size, true)}</p>
                    </div>
                    <IconButton
                        icon={<Icon type={Icons.CROSS} color="white" width={14} height={14} />}
                        className={style.closeIcon}
                        onClick={handleClose}
                        id="fileViewDialogModalCrestBtn"
                    />
                </div>
                <div className={style.bottom}>
                    {file?.permissions?.delete && (
                        <button className={classNames(style.noStyleButton, [style.red])} onClick={removeFileHandler} data-testid="fileViewDialogModalDeleteBtn">
                            <Icon type={Icons.TRASH} width={16} height={16} color={'red'} />
                            <span>Удалить</span>
                        </button>
                    )}
                    {file?.permissions?.rename && (
                        <button className={style.noStyleButton} onClick={editFile} data-testid="fileViewDialogModalEditBtn">
                            <Icon type={Icons.EDIT_PEN} width={16} height={16} color={'white'} />
                            <span>Переименовать</span>
                        </button>
                    )}
                    {file?.permissions?.replace && (
                        <button className={style.noStyleButton} onClick={toggleReplace} data-testid="fileViewDialogModalReplaceBtn">
                            <Icon type={Icons.REFRESH} width={16} height={16} color={'white'} />
                            <span>Заменить файл</span>
                        </button>
                    )}
                    {file?.permissions?.usage && (
                        <button className={style.noStyleButton} onClick={toggleUsage} data-testid="fileViewDialogModalUsageBtn">
                            <Icon type={Icons.ATTACHMENT} width={16} height={16} color={'white'} />
                            <span>Где использован</span>
                        </button>
                    )}
                    {file?.permissions?.viewInfo && (
                        <button className={style.noStyleButton} onClick={toggleFileInfo} data-testid="fileViewDialogModalInfoBtn">
                            <Icon type={Icons.INFO} width={16} height={16} color={'white'} />
                            <span>О файле</span>
                        </button>
                    )}
                    {file?.permissions?.download && (
                        <button className={style.noStyleButton} onClick={saveFile} data-testid="fileViewDialogModalDownloadBtn">
                            <Icon type={Icons.DOWNLOAD} width={16} height={16} color={'white'} />
                            <span>Скачать</span>
                        </button>
                    )}
                </div>
            </div>
            <div className={style.modalHeader}>
                <IconButton
                    icon={<Icon type={Icons.CROSS} color={'white'} width={14} height={14} />}
                    className={style.closeIcon}
                    onClick={handleClose}
                />
                <div className={style.fileWrapper}>
                    <div className={style.iconWrapper}>
                        <Icon type={FILES_TYPE_ICONS[file?.type] || Icons.NONE_FILE} width={24} height={24} />
                    </div>
                    <div>
                        <div className={style.fileName}>{file?.name}</div>
                        <div className={style.fileDescription}>
                            <span className={style.fileType}>{FILES_TYPE_SHORT_NAME[file?.type]}</span> — {humanFileSize(file?.size, true)}
                        </div>
                    </div>
                </div>
                {!isNoButtons && (
                    <div className={style.modalHeaderDesktop}>
                        {file?.permissions?.print && (
                            <button className={style.noStyleButton} onClick={print}>
                                <Icon type={Icons.PRINTER} width={16} height={16} color={'white'} />
                                <div>Печать</div>
                            </button>
                        )}
                        {!isNoActions ? (
                            <>
                                {file?.permissions?.delete && (
                                    <button className={classNames(style.noStyleButton, [style.red])} onClick={removeFileHandler}>
                                        <Icon type={Icons.TRASH} width={16} height={16} color={'red'} />
                                        <div>Удалить</div>
                                    </button>
                                )}
                                {file?.permissions?.rename && (
                                    <button className={style.noStyleButton} onClick={editFile}>
                                        <Icon type={Icons.EDIT_PEN} width={16} height={16} color={'white'} />
                                        <div>Переименовать</div>
                                    </button>
                                )}
                                {file?.permissions?.replace && (
                                    <button className={style.noStyleButton} onClick={toggleReplace}>
                                        <Icon type={Icons.REFRESH} width={16} height={16} color={'white'} />
                                        <div>Заменить файл</div>
                                    </button>
                                )}
                            </>
                        ) : (
                            <button className={style.noStyleButton} onClick={() => navigate('/files/all')}>
                                <Icon type={Icons.SHARE} width={20} height={20} color={'white'} />
                                <div>Перейти к файлам</div>
                            </button>
                        )}
                        {file?.permissions?.usage && (
                            <button className={style.noStyleButton} onClick={toggleUsage}>
                                <Icon type={Icons.ATTACHMENT} width={16} height={16} color={'white'} />
                                <div>Где использован</div>
                            </button>
                        )}
                        {file?.permissions?.viewInfo && (
                            <button className={style.noStyleButton} onClick={toggleFileInfo}>
                                <Icon type={Icons.INFO} width={16} height={16} color={'white'} />
                                <div>О файле</div>
                            </button>
                        )}
                        {file?.permissions?.viewComments && (
                            <button className={style.noStyleButton} onClick={toggleComments}>
                                <Icon type={Icons.COMMENTS} width={16} height={16} color={'white'} />
                                <div>Комментарии</div>
                            </button>
                        )}
                        {file?.permissions?.download && (
                            <button className={classNames(style.iconButton, style.downloadButton)} onClick={saveFile}>
                                <Icon type={Icons.DOWNLOAD} width={16} height={16} /> Скачать
                            </button>
                        )}
                    </div>
                )}
                {!isNoButtons && isAuthorizedAction([GLOBAL_ACTIONS.FILE_READ]) && (
                    <div className={style.modalHeaderMobile}>
                        <IconButton
                            icon={<Icon color={isMobileMenuOpen ? 'blue' : 'white'} type={Icons.SANDWICH} width={22} height={22} />}
                            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                        />
                        {isMobileMenuOpen && (
                            <div className={style.mobileMenu}>
                                {file?.permissions?.download && (
                                    <button className={style.noStyleButton} onClick={saveFile}>
                                        <Icon type={Icons.DOWNLOAD} width={16} height={16} color={'white'} />
                                        <div>Скачать файл</div>
                                    </button>
                                )}
                                {file?.permissions?.viewInfo && (
                                    <button className={style.noStyleButton} onClick={toggleFileInfo}>
                                        <Icon type={Icons.INFO} width={16} height={16} color={'white'} />
                                        <div>О файле</div>
                                    </button>
                                )}
                                {file?.permissions?.usage && (
                                    <button className={style.noStyleButton} onClick={toggleUsage}>
                                        <Icon type={Icons.ATTACHMENT} width={16} height={16} color={'white'} />
                                        <div>Где использован</div>
                                    </button>
                                )}
                                {file?.permissions?.viewComments && (
                                    <button className={style.noStyleButton} onClick={toggleComments}>
                                        <Icon type={Icons.COMMENTS} width={16} height={16} color={'white'} />
                                        <div>Комментарии</div>
                                    </button>
                                )}
                                {!isNoActions ? (
                                    <>
                                        {file?.permissions?.rename && (
                                            <button className={style.noStyleButton} onClick={editFile}>
                                                <Icon type={Icons.EDIT_PEN} width={16} height={16} color={'white'} />
                                                <div>Переименовать</div>
                                            </button>
                                        )}
                                        {file?.permissions?.replace && (
                                            <button className={style.noStyleButton} onClick={toggleReplace}>
                                                <Icon type={Icons.REFRESH} width={16} height={16} color={'white'} />
                                                <div>Заменить файл</div>
                                            </button>
                                        )}
                                        {file?.permissions?.delete && (
                                            <button className={style.noStyleButton} onClick={removeFileHandler}>
                                                <Icon type={Icons.TRASH} width={16} height={16} color={'red'} />
                                                <div>Удалить файл</div>
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <button className={style.noStyleButton} onClick={() => navigate('/files/all')}>
                                        <Icon type={Icons.SHARE} width={20} height={20} color={'white'} />
                                        <div>Перейти к файлам</div>
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {isNoButtons && file?.permissions?.download && (
                    <>
                        {platform === 'desktop' && (
                            <button className={classNames(style.iconButton, style.downloadButton)} onClick={saveFile}>
                                <Icon type={Icons.DOWNLOAD} width={16} height={16} /> Скачать
                            </button>
                        )}
                        {platform !== 'desktop' && (
                            <div className={style.mobileMenu}>
                                <button className={style.noStyleButton} onClick={saveFile}>
                                    <Icon type={Icons.DOWNLOAD} width={16} height={16} color={'white'} />
                                    <div>Скачать</div>
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div
                className={classNames(style.modalBody, {
                    [style.show]: isFileInfoShow || isCommentsShow,
                    [style.table]: fileViewType === 'TABLE',
                })}
            >
                <iframe id="printIframe" name="printIframe" title="printIframe" style={{ zIndex: -1, position: 'absolute', opacity: 0 }} />
                <div id="documentContent" className={style.objWrapper}>
                    {isTempIdLoaded && !isError ? (
                        <>
                            {fileViewType === 'AUDIO' ? (
                                <div className={style.playerWrapper}>
                                    <AudioPlayer
                                        ref={playerRef}
                                        onError={onError}
                                        src={`/services/kms/api/v1/files/download/${file?.fileId}`}
                                    />
                                </div>
                            ) : fileViewType === 'VIDEO' ? (
                                <div className={style.videoWrapper}>
                                    <video ref={videoRef} controls autoPlay onError={onError} />
                                </div>
                            ) : fileViewType === 'IMAGE' ? (
                                <img
                                    ref={imgRef}
                                    alt=""
                                    onLoad={onImageLoaded}
                                    onError={onError}
                                    src={`/services/kms/api/v1/files/preview/${file?.fileId}`}
                                    style={{
                                        width: isNaN(imgSize.width) ? imgSize.width : imgSize.width * imgSizeModifier + 'px',
                                        height: isNaN(imgSize.height) ? imgSize.height : imgSize.height * imgSizeModifier + 'px',
                                    }}
                                />
                            ) : fileViewType === 'DOCUMENT' ? (
                                <div style={{ background: 'white' }}>
                                    {filePreview.map((img, idx) => {
                                        return (
                                            <img
                                                key={idx}
                                                ref={idx === 0 ? imgRef : null}
                                                alt=""
                                                onLoad={idx === 0 ? onImageLoaded : null}
                                                src={`${hostname}/services/parser/api/v1/file-preview/file/${img}`}
                                                style={{
                                                    width: isNaN(imgSize.width) ? imgSize.width : imgSize.width * imgSizeModifier + 'px',
                                                    height: isNaN(imgSize.height) ? imgSize.height : imgSize.height * imgSizeModifier + 'px',
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            ) : null}
                        </>
                    ) : isError ? (
                        <div className={style.error}>
                            <Icon type={Icons.BLOCK} width={42} height={42} color={'white'} />
                            <div className={style.errorTitle}>Предпросмотр файла недоступен</div>
                            <div className={style.errorDesc}>Данный формат не поддерживается</div>
                        </div>
                    ) : null}
                    {(file?.fileTypeGroup === 'application' || 'text') && !isTempIdLoaded && !isError && (
                        <div className={style.loader}>
                            <Loading withOverlay={false} />
                        </div>
                    )}
                </div>
            </div>
            {(fileViewType === 'DOCUMENT' || fileViewType === 'IMAGE') && (
                <div className={style.modalFooter}>
                    <button
                        className={classNames(style.noStyleButton, {
                            [style.disabled]: imgSize?.size <= 50,
                        })}
                        onClick={decreaseImg}
                    >
                        <Icon type={Icons.MINUS} width={20} height={20} color={imgSize?.size <= 50 ? 'gray' : 'white'} />
                    </button>

                    <div className={style.sizeValue}>{imgSize?.size}%</div>

                    <button
                        className={classNames(style.noStyleButton, {
                            [style.disabled]: imgSize?.size >= 200,
                        })}
                        onClick={increaseImg}
                    >
                        <Icon type={Icons.PLUS_ADD} width={20} height={20} color={imgSize?.size >= 200 ? 'gray' : 'white'} />
                    </button>
                </div>
            )}
            {isFileInfoShow && (
                <div className={classNames(style.sidebar, { [style.show]: isFileInfoShow })}>
                    <div className={style.sidebarHeader}>
                        <div className={style.sidebarTitle}>О файле</div>
                        <Icon type={Icons.CROSS} width={16} height={16} onClick={toggleFileInfo} />
                    </div>
                    <div className={style.sidebarBody}>
                        <div className={style.fileInfoRow}>
                            <div>Формат</div>
                            <div>{FILES_TYPE_SHORT_NAME[file?.type]}</div>
                        </div>
                        <div className={style.fileInfoRow}>
                            <div>Автор файла</div>
                            <div className={style.user}>
                                <Link to={`/edit-user/${file?.author.login}`} className={null}>
                                    <img src="/img/avatar.png" alt="" className={style.userAvatar} />
                                    <span className={style.userLink}>{file?.author['firstName'] + ' ' + file?.author['lastName']}</span>
                                </Link>
                            </div>
                        </div>
                        <div className={style.fileInfoRow}>
                            <div>Дата загрузки</div>
                            <div>{file?.createTime}</div>
                        </div>
                        <div className={style.fileInfoRow}>
                            <div>Вес файла</div>
                            <div>{humanFileSize(file?.size, true)}</div>
                        </div>
                        {file?.modifyTime && file?.createTime !== file?.modifyTime && (
                            <div className={style.fileInfoRow}>
                                <div>Изменен</div>
                                <div>{file?.modifyTime ? new Date(file?.modifyTime).toLocaleString() : '–'}</div>
                            </div>
                        )}
                        {file?.modifiedBy && (
                            <div className={style.fileInfoRow}>
                                <div>Автор изменения</div>
                                <div className={style.user}>
                                    {file?.modifiedBy ? (
                                        <Link to={`/edit-user/${file?.modifiedBy.login}`} className={null}>
                                            <img src="/img/avatar.png" alt="" className={style.userAvatar} />
                                            <span className={style.userLink}>
                                                {file?.modifiedBy['firstName'] + ' ' + file?.modifiedBy['lastName']}
                                            </span>
                                        </Link>
                                    ) : (
                                        '–'
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isCommentsShow && (
                <div className={classNames(style.sidebar, { [style.show]: isCommentsShow })}>
                    <div className={style.sidebarHeader}>
                        <div className={style.sidebarTitle}>Комментарии</div>
                        <Icon type={Icons.CROSS} width={16} height={16} onClick={toggleComments} />
                    </div>
                    <FileCommentsList isOpen={isCommentsShow} uuid={file?.fileId} addComments={file?.permissions?.addComments} />
                </div>
            )}
            <Modal
                isOpen={isUsageModalOpen}
                className={style.usage}
                overlayClassName={style.usageOverlay}
                onRequestClose={() => toggleIsUsageModalOpen(false)}
            >
                <FileUsageList
                    uuid={file?.fileId}
                    filePreviewClose={() => {
                        toggleIsUsageModalOpen(false);
                        handleClose();
                    }}
                    handleClose={() => toggleIsUsageModalOpen(false)}
                />
            </Modal>
            <Modal
                isOpen={isEditFileModal}
                className={style.usage}
                overlayClassName={style.usageOverlay}
                onRequestClose={() => toggleEditFileModal(false)}
            >
                <FileEditDialog file={file} handleClose={() => toggleEditFileModal(false)} onFileChange={onNameChange} />
            </Modal>
            <Modal
                isOpen={isReplaceFileModal}
                className={style.usage}
                overlayClassName={style.usageOverlay}
                onRequestClose={() => toggleReplaceFileModal(false)}
            >
                <FileChangeDialog uuid={file?.fileId} handleClose={() => toggleReplaceFileModal(false)} onFileChange={onFileChange} />
            </Modal>
        </div>
    );
};
