export const USER_ROLES = {
    ROLE_ADMIN: 'hasAdminRole',
    ROLE_MANAGER: 'hasManagerRole',
    ROLE_ROOT: 'hasRootRole',
    ROLE_SUPER_USER: 'hasSuperUserRole',
    ROLE_TEST: 'test'
};

export const ACCESS_MODES = {
    MODE_ADMIN: 'admin',
    MODE_MANAGER: 'manager',
    MODE_OPERATOR: 'operator',
    MODE_ROOT: 'root',
    MODE_SUPER_USER: 'super-user'
};

const WEIGHTS = {
    [USER_ROLES.ROLE_MANAGER]: 20,
    [USER_ROLES.ROLE_ADMIN]: 30,
    [USER_ROLES.ROLE_ROOT]: 40,
    [USER_ROLES.ROLE_SUPER_USER]: 40,
    [ACCESS_MODES.MODE_OPERATOR]: 10,
    [ACCESS_MODES.MODE_MANAGER]: 20,
    [ACCESS_MODES.MODE_ADMIN]: 30,
    [ACCESS_MODES.MODE_ROOT]: 40,
    [ACCESS_MODES.MODE_SUPER_USER]: 40
};

export function filterRulesToAccessGrant(rules, userRolesInfo) {
    const weight = Object.getOwnPropertyNames(userRolesInfo ?? {}).filter(n => userRolesInfo[n] === true)
        .reduce((acc, cur) => WEIGHTS[cur] > acc ? WEIGHTS[cur] : acc, 0);
    return rules.filter(r => WEIGHTS[r.name] <= weight);
}