import React, { useEffect, useRef } from 'react';
import { globalHistory, navigate, useLocation } from '@reach/router';
import { useGlobalContext, useDialog } from 'lib/hooks';
import Confirmation from 'components/confirmation';

const formUrls = ['/add/', '/edit', '/create'];
const scriptUrls = ['/scripting/script'];

const FormDataSaveWrapper = ({ children }) => {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const { visitedUrls,
        setVisitedUrls,
        formDataChanged,
        setFormDataChanged,
        gotScriptProgress,
        setGotScriptProgress,
        formDataChangedSubmitHandler,
    } = useGlobalContext();
    const location = useLocation();
    const formDataChangedRef = useRef(false);
    const gotScriptProgressRef = useRef(false);
    const formDataChangedSubmitHandlerRef = useRef(null);
    const isActualUrl = (urls) => urls.some((url) => location.pathname.includes(url))

    const onSubmitHandler = (navigateHandler) => {
        closeDialog();
        navigateHandler();
    }

    // Диалог при изменённом сост-ии формы
    const openFormDataChangeDialog = (navigateHandler) => {
        openDialog({
            title: 'Хотите покинуть страницу?',
            subTitle: 'Вы действительно хотите выйти? Все несохранённые данные будут потеряны.',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onSubmit: () => {
                onSubmitHandler(navigateHandler);
                setFormDataChanged(false);
                formDataChangedSubmitHandlerRef?.current?.();
                // setFormDataChangedSubmitHandler(null);
            },
            onClose: closeDialog
        });
    }

    // Диалог при прерывании прогресса скрипта
    const openScriptProgressDialog = (navigateHandler) => {
        openDialog({
            title: 'Конец разговора',
            subTitle: 'При выходе с этой страницы, текущий скрипт будет прерван. Прогресс выполнения скрипта нельзя вернуть.',
            color: 'red',
            closeBtnText: 'Нет, вернуться',
            submitBtnText: 'Да, завершить разговор',
            onSubmit: () => {
                onSubmitHandler(navigateHandler);
                setGotScriptProgress(false);
            },
            onClose: closeDialog
        });
    }

    if (isActualUrl([...formUrls, ...scriptUrls])) {
        window.history.pushState(null, null, null);
    }

    useEffect(() => {
        globalHistory.navigate(location.pathname + location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (visitedUrls[visitedUrls.length - 1] !== window.location.pathname + window.location.search){
            setVisitedUrls([
              ...visitedUrls,
              window.location.pathname + window.location.search
            ]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname, window.location.search]);

    useEffect(() => {
        const onLocationChange = () => {
            const url = visitedUrls[visitedUrls.length - 2] ?? '/';
            const navByUrl = () => {
              const visitedUrlsClone = [...visitedUrls];
              visitedUrlsClone.length = visitedUrlsClone.length ? visitedUrlsClone.length - 1 : 0;
              setVisitedUrls(visitedUrlsClone);
              window.history.pushState(null, '', url);
              navigate(url);
            }

            // Отслеживание состояния формы
            if (formDataChangedRef.current/*  && isActualUrl(formUrls) */) {
                openFormDataChangeDialog(navByUrl);
                return false;
            }

            // Отслеживание прогресса скрипта
            if (gotScriptProgressRef.current/*  && isActualUrl(scriptUrls) */) {
                openScriptProgressDialog(navByUrl);
                return false;
            }

            navByUrl();
        };

        window.addEventListener('popstate', onLocationChange);

        return() => {
          window.removeEventListener('popstate', onLocationChange);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitedUrls]);

    useEffect(() => {
      formDataChangedRef.current = formDataChanged;
    }, [formDataChanged]);

    useEffect(() => {
      gotScriptProgressRef.current = gotScriptProgress;
    }, [gotScriptProgress]);

    useEffect(() => {
      formDataChangedSubmitHandlerRef.current = formDataChangedSubmitHandler;
    }, [formDataChangedSubmitHandler]);

    globalHistory.navigate = (...props) => {
        const navByUrl = () => {
            navigate(props[0]);
        }
        // const noCheckFormDataChanged = props[1] && (props[1] === 'no-check-form-data-changed' || props[1]?.replace === false);
        const noCheckFormDataChanged = props[1] && props[1] === 'no-check-form-data-changed';

        // Отслеживание состояния формы
        if (formDataChangedRef.current && !noCheckFormDataChanged/*  && isActualUrl(formUrls) */) {
            openFormDataChangeDialog(navByUrl);
            return false;
        }

        // Отслеживание прогресса скрипта
        if (gotScriptProgressRef.current/*  && isActualUrl(scriptUrls) */) {
            openScriptProgressDialog(navByUrl);
            return false;
        }

        navByUrl();
    }

    return (
        <>
            {children}
            <Confirmation {...dialogState} />
        </>
    );
};

export default FormDataSaveWrapper;
