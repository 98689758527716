import ApiClient from './api-axios';

export default class ArticleCommentsApi extends ApiClient {
    postComment = async (newsId, parentCommentId, text) => {
        return this.post('/', { newsId, parentId: parentCommentId, text });
    };

    getNewsComments = async (uuid, page) => {
        return await this.get(`/news/${uuid}?page=${page ?? 0}`);
    };

    editComment = async (id, text) => {
        return await this.post('/edit', { id, text });
    };

    deleteComment = async uuid => {
        return await this.deleteRequest(`/${uuid}`);
    };

    getCommentById = async uuid => {
        return await this.get(`/${uuid}`);
    };
}
