import React from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import Button from 'uikit/button';
import TextArea from 'uikit/textarea';
import cs from './report-error.module.scss';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import { useGlobalContext } from 'lib/hooks';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';

export const ReportError = ({ isOpen, onDismiss, onRequestSubmit }) => {
    const { platform } = useGlobalContext();
    const [text, setText] = React.useState('');

    React.useEffect(() => {
        if (!isOpen) {
            setText('');
        }
    }, [isOpen]);

    const onSubmit = () => {
        onRequestSubmit(text);
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onDismiss} contentLabel={'Нашли ошибку?'} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
            <div className={classNames(cs.modal, {[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.head}>
                    <div className={cs.text}>Нашли ошибку?</div>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                                onClick={onDismiss}/>
                </div>
                <div className={cs.content}>
                    <div className={cs.hint}>
                        Опишите найденную ошибку и уточните где она находится для облегчения работы администратора
                    </div>
                    <TextArea onChange={setText}/>
                </div>
                <div className={cs.footer}>
                    <Button onClick={onDismiss} label='Отмена' fullWidth={platform === 'mobile'}/>
                    <Button onClick={onSubmit} label='Отправить' color={'green'} fullWidth={platform === 'mobile'}/>
                </div>
            </div>
        </Modal>
    );
};