import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import usePrevious from 'lib/hooks/usePrevious';
import Loading from 'uikit/loading';
import Filter from 'uikit/filter/filter';
import { News } from 'uikit/panel';
import Pagination from 'uikit/pagination/pagination';
import api from 'api';
import cx from './news-component.module.scss';
import Icon, { Icons } from 'uikit/icon';
import { Select } from 'uikit/select';

export const NewsComponent = ({ isLoading = false, title = '', searchTitle = '', addTitle = '', total = 0, data = [],
                                  filters = [], onPaginate = () => {}, onAdd = null, onRemove = null,
                                  onArchive = () => {}, isMobile, isShowReadiedFilter = false, headContent = '' }) => {
    const navigate = useNavigate();
    const previousData = usePrevious(data);

    const [newsCount, setNewsCount] = useState(10);
    const [newsOffset, setNewsOffset] = useState(0);
    const [newsSearch, setNewsSearch] = useState('');
    const [newsFilters, setNewsFilters] = useState({});

    const onNewsPaginate = useCallback((offset, count, search = newsSearch, filters = newsFilters) => {
        setNewsOffset(offset);
        setNewsCount(count);

        onPaginate(offset, count, { by: '', desc: false }, search, filters);
    }, [onPaginate, newsSearch, newsFilters]);
    const onNewsSearch = useCallback((text) => {
        setNewsSearch(text);
        onNewsPaginate(newsOffset, newsCount, text, newsFilters);
    }, [onNewsPaginate, newsCount, newsOffset, newsFilters]);
    const onTableFilters = useCallback((filtersData) => {
        setNewsFilters(filtersData);
        onNewsPaginate(newsOffset, newsCount, newsSearch, filtersData);
    }, [onNewsPaginate, newsCount, newsOffset, newsSearch]);
    const onChangeFilters = (field, value) => {
        const filtersDataCopy = Object.assign({}, newsFilters);

        filtersDataCopy[field] = value;
        onTableFilters(filtersDataCopy);
    };

    useEffect(() => {
        if (!previousData || data === previousData) {
            return;
        }

        if (previousData.length !== data.length) {
            if (data.length === 0 && newsOffset > 0) {
                onNewsPaginate(newsOffset - newsCount, newsCount, newsSearch);
                navigate(document.location.origin + document.location.pathname + '?offset=' + (newsOffset - newsCount) + '&count=' + newsCount);
            } else {
                onNewsPaginate(newsOffset, newsCount, newsSearch);
            }
        }
    }, [navigate, data, previousData, newsCount, newsOffset, newsSearch, onNewsPaginate]);

    if (isShowReadiedFilter && !('readied.in' in newsFilters)) {
        onChangeFilters('readied.in', { label: 'Все', value: null });
    }

    return (
        <div className={cx.panel}>
            {(title || isShowReadiedFilter) &&
            <div className={cx.head}>
                <h2>{title}</h2>
                {!isMobile &&
                    <div className={cx.headContent}>
                        {isShowReadiedFilter &&
                        <div className={cx.headReadied}>
                            <Icon type={Icons.EYE} width={16} height={16}/>
                            <span>Показать документы:</span>
                            <Select isMulti={false} value={newsFilters['readied.in']}
                                    onChange={(value) => onChangeFilters('readied.in', value)}
                                    options={[
                                        { label: 'Все', value: null },
                                        { label: 'Непрочитанные', value: 'false' },
                                        { label: 'Прочитанные', value: 'true' },
                                    ]}/>
                        </div>}
                        {headContent}
                    </div>
                }
            </div>}
            <div className={cx.body}>
                {isLoading &&
                <div className={cx.loader}>
                    <Loading withOverlay={false}/>
                </div>}
                <div className={cx.filter}>
                    <Filter search={searchTitle} searchText={newsSearch} onSearch={(text) => onNewsSearch(text)}
                            add={addTitle} onAdd={onAdd} filters={filters} filtersData={newsFilters}
                            onFilters={onTableFilters} isMobile={isMobile}/>
                </div>
                <div className={cx.items}>
                    {data.map((item) => {
                        return (
                            <News key={item['id']} className={cx.item} id={item['id']} title={item['title']}
                                  description={item['description']} priority={item['priority']}
                                  date={item['modifyTime']}
                                  image={item['logoUuid'] ? api.upload.getImage(item['logoUuid'], false, 512) : '/img/news-card-cover.jpg'}
                                  projectId={item['project'] ? item['project']['id'] : ''}
                                  projectTitle={item['project'] ? item['project']['title'] : ''}
                                  projectImage={item['project'] && item['project']['logoUuid']
                                      ? api.upload.getImage(item['project']['logoUuid'], false, 128) : ''}
                                  authorLogin={item['author']['login']}
                                  authorShortName={item['author']['firstName'] + ' ' + item['author']['lastName'].slice(0, 1) + '.'}
                                  authorName={item['author']['firstName'] + ' ' + item['author']['lastName']}
                                  authorImage={item['author']['avatarUuid']
                                      ? api.upload.getImage(item['author']['avatarUuid'], false, 128)
                                      : '/img/avatar.png'}
                                  onClick={() => navigate(`/projects/news/${item['id']}`)}
                                  onActionRemove={onRemove ? () => onRemove(item) : null}
                                  onActionArchive={() => onArchive(item)}
                                  permissions={item.permissions}
                            />
                        );
                    })}
                </div>
                <div className={cx.pagination}>
                    <Pagination total={total} count={newsCount} offset={newsOffset} onPaginate={onNewsPaginate} isMobile={isMobile}/>
                </div>
            </div>
        </div>
    );
};
