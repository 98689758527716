import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import { navigate } from '@reach/router';
import { useMessage, useDialog, useGlobalContext } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import Loading from 'uikit/loading';
import IconButton from 'uikit/icon-button';
import PrivatePage from 'containers/private-page-wrapper';
import Empty from 'components/projects/empty';
import Confirmation from 'components/confirmation';
import { TableComponent } from 'components/data-components';
import { EditRowToolbar } from 'uikit/table';
import api from 'api';
import cx from './companies-page.module.scss';

const CompaniesPage = ({ hasAnyAuthorities }) => {
    const tableRef = useRef(null);
    const { platform } = useGlobalContext();

    const { addError, addSuccess } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isLoading, setIsLoading] = useState(true);
    const [isSearch, setIsSearch] = useState(false);

    const [company, setCompany] = useState(null);

    const [offices, setOffices] = useState([]);
    const [officesTotal, setOfficesTotal] = useState(0);
    const [isOfficesEmpty, setIsOfficesEmpty] = useState(false);

    const [isCompanyExists, setIsCompanyExists] = useState(false);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some((c) => c.id === node.id)) {
                return prevValue.filter((c) => c.id !== node.id);
            } else {
                return [...prevValue, node];
            }
        });
    }, []);

    const fetchCompanies = useCallback(async () => {
        try {
            const res = await api.company.getCompanies({});

            if (res.content.length >= 1) {
                setCompany(res.content[0]);
                setIsCompanyExists(true);
            } else {
                setIsCompanyExists(false);
            }
        } catch (e) {
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);

    const fetchOffices = useCallback(async (offset, count, sort = { by: '', desc: false }, search) => {
        try {
            const res = await api.office.getOffices(
                offset / count,
                count,
                sort.by ? sort.by + ',' + (sort.desc ? 'desc' : 'asc') : 'name,desc',
                search
            );

            setOffices(res.content);
            setOfficesTotal(res.totalElements);

            setIsSearch(!!search);
            setIsOfficesEmpty(!res.content.length);

            setIsLoading(false);
        } catch (e) {
            addError('При запросе офисов произошла ошибка');
        }
    }, [addError]);

    const deleteOffice = useCallback(
        async (data) => {
            try {
                if (data.length) {
                    for (let i = 0; i < data.length; i++) {
                        await api.office.removeOffice(data[i].id);
                    }

                    tableRef?.current?.reload();
                    addSuccess('Выбранные офисы удалены');
                } else if (data?.id) {
                    await api.office.removeOffice(data.id);

                    tableRef?.current?.reload();
                    addSuccess('Офис удален');
                } else {
                    closeDialog();
                }
            } catch (error) {
                addError('При удалении произошла ошибка');
            }

            closeDialog();
        },
        [closeDialog, addSuccess, addError]
    );

    const removeOfficeHandler = useCallback(
        async (data) => {
            if (data?.length) {
                setCheckedCheckbox(data.map((d) => d.original));
            }

            let text = '';

            if (data?.length) {
                text = data.map((item) => {
                    return {
                        ...item.original,
                        text: <div>{item.original.name}</div>,
                    };
                });
            } else {
                text = (
                    <span>
                        Вы действительно хотите удалить офис "<span style={{ fontWeight: 500 }}>{data?.name}</span>"? Он не может быть
                        восстановлен.
                    </span>
                );
            }
            openDialog({
                title: 'Удаление',
                subTitle: data?.length
                    ? 'Вы действительно хотите удалить нижеперечисленные объекты? Они не могут быть восстановлены'
                    : null,
                text,
                contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
                color: 'green',
                closeBtnText: 'Нет, закрыть',
                submitBtnText: 'Подтвердить',
                onChange: (office) => checkboxHandler(office),
                onSubmit: () => deleteOffice(data?.length ? checkedCheckboxRef.current : data),
                onClose: () => closeDialog(),
            });
        },
        [openDialog, closeDialog, deleteOffice, checkboxHandler]
    );

    const officeColumns = useMemo(
        () => [
            {
                Header: 'Название',
                accessor: 'name',
                Cell: (data) => {
                    const { name, address, building, block, description } = data.row.original;
                    return (
                        <div>
                            <div className={cx.city}>{name}</div>
                            <div className={cx.address}>
                                {address}
                                {building ? `, Строение ${building}` : ''}
                                {block ? `, Блок ${block}` : ''}
                            </div>
                            <div className={cx.desc}>{description}</div>
                        </div>
                    );
                },
            },
            {
                id: 5,
                Header: '',
                width: 50,
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: function (data) {
                    const tools = [];

                    tools.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        link: `/global-settings/office/edit/${data.row.original.id}`,
                    });

                    tools.push({
                        icon: Icons.TRASH,
                        iconHoverColor: 'red',
                        tooltip: 'Удалить',
                        onClick: (data) => removeOfficeHandler(data),
                    });

                    return EditRowToolbar(tools, null, true, false, true)(data);
                },
            },
        ],
        [platform, removeOfficeHandler]
    );

    const officesActions = [
        {
            icon: Icons.TRASH,
            className: cx.blockActionButton,
            label: 'Удалить',
            onClick: (data) => removeOfficeHandler(data),
        },
    ];

    useEffect(() => {
        const initData = async () => {
            await fetchCompanies();
        };

        initData();
    }, [fetchCompanies]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />

            {isLoading && (
                <div className={cx.loading}>
                    <Loading withOverlay={true} active={isLoading} />
                </div>
            )}

            <div className={cx.headerWrapper}>
                <div className={cx.companyDescWrapper}>
                    <div className={cx.companyDesc}>
                        <div
                            className={cx.logo}
                            style={{
                                backgroundColor: '#EAEDF3',
                                backgroundImage: isCompanyExists ? `url(${api.upload.getImage(company.avatarUuid, false, 512)})` : '',
                                backgroundSize: 'cover',
                            }}
                        />
                        {isCompanyExists ? (
                            <div>
                                <div className={cx.title}>{company.name}</div>
                                <p className={cx.desc}>{company.description}</p>
                            </div>
                        ) : (
                            <div className={cx.addCompany}>
                                <div>Укажите данные о компании</div>
                                <Icon
                                    onClick={() => navigate('/global-settings/company/create')}
                                    type={Icons.EDIT_PEN}
                                    width={18}
                                    height={18}
                                />
                            </div>
                        )}
                    </div>
                    {isCompanyExists && (
                        <IconButton
                            onClick={() => navigate(`/global-settings/company/edit/${company.id}`)}
                            icon={<Icon type={Icons.EDIT_PEN} tooltip={'Редактировать'} width={15} height={15} />}
                        />
                    )}
                </div>
            </div>

            <div className={classNames(cx.contentWrapper, { [cx.mobileContentWrapper]: platform === 'mobile' })}>
                <div className={cx.header}>
                    <div className={cx.title}>Офисы</div>
                </div>
                {(isOfficesEmpty && !isSearch) && (
                    <div className={cx.Em}>
                        <Empty
                            icon={Icons.MAP_PIN}
                            title='Офисы отсутствуют'
                            buttons={[
                                {
                                    icon: Icons.MAP_PIN,
                                    title: 'Добавить офис',
                                    onClick: () => navigate('/global-settings/office/create')
                                }
                            ]}
                        />
                    </div>
                )}
                {(!isOfficesEmpty || isSearch) && (
                    <TableComponent
                        innerRef={tableRef}
                        searchTitle='Поиск'
                        addTitle='Добавить офис'
                        columns={officeColumns}
                        actions={officesActions}
                        isLoading={isLoading}
                        total={officesTotal}
                        data={offices}
                        onPaginate={fetchOffices}
                        addLink={'/global-settings/office/create'}
                        isMobile={platform === 'mobile'}
                        defaultSort={{ by: 'name', desc: true }}
                        sortOptions={[
                            {
                                Header: 'По названию (по умолчанию)',
                                accessor: 'name',
                            },
                            {
                                Header: 'По дате создания',
                                accessor: 'createTime',
                            },
                            {
                                Header: 'По адресу',
                                accessor: 'address',
                            },
                        ]}
                    />
                )}
            </div>
        </PrivatePage>
    );
};

export default CompaniesPage;
