import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonBulletList = ({ label, _hideMenu }) => {
    const editor = useEditorContext();

    // const toggleBulletList = () => {
    //     editor?.chain().focus().toggleBulletList().run();
    //     hideMenu?.();
    // };

    return (
        <MenuButton
            tooltipLabel={label}
            icon={Icons.EditorIconList}
            // onClick={toggleBulletList}
            label={label}
            style={{ whiteSpace: 'nowrap', display: 'flex', minWidth: 'initial' }}
            disabled={!editor?.isEditable || !editor?.can().toggleBulletList()}
            selected={editor?.isActive('bulletlist') ?? undefined}
        ></MenuButton>
    );
};
