import React from 'react';
import ReactDOMServer from 'react-dom/server';
import api from 'api/index';

const GlossarySymbols = [' ', ',', '.', '"', '<', '>', '/', '\\', '?', ';', ':', '', '[', ']', '{', '}', '|', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '-', '_', '=', '+', '`', '~'];
const GlossaryService = {
    isInit: false,
    data: [],

    init: async () => {
        if (GlossaryService.isInit) {
            return GlossaryService.data;
        }

        try {
            const response = await api.glossary.list(0, 2000);

            GlossaryService.isInit = true;
            GlossaryService.data = response['data']['content'];
        } catch {

        }
    },
    invalidate: () => {
        GlossaryService.isInit = false;
        GlossaryService.data = [];
    },

    getIndicesOf: (searchStr, str, caseSensitive) => {
        const searchStrLen = searchStr.length;

        if (searchStrLen === 0) {
            return [];
        }

        let startIndex = 0, index, indices = [];

        if (!caseSensitive) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }

        while ((index = str.indexOf(searchStr, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }

        return indices;
    },
    formatItem: (text, description) => {
        return ReactDOMServer.renderToStaticMarkup((
            <span className="tiarm" data-definition={description}>{text}</span>
        ));
    },
    format: (text, projectId = null) => {
        let response = text.replaceAll('&nbsp;', ' ').replaceAll('&amp;', '&').replaceAll('&quot;', '"');

        // Select terms which allow for resource;
        const terms = GlossaryService.data
            .filter((item) => item['project'] === null || (item['project'] && item['project']['id'] === projectId))
            .sort((a) => a['project'] !== null ? -1 : 1);

        // Select unique tags data;
        const tags = [];

        for (let i = 0; i < terms.length; i++) {
            const termTags = terms[i]['termTags'].map((item) => item['termTag']);

            for (let j = 0; j < termTags.length; j++) {
                if (tags.findIndex((item) => item['tag'] === termTags[j]) !== -1) {
                    continue;
                }

                tags.push({ 'tag': termTags[j], 'definition': terms[i]['termDefinition'] });

                if (termTags[j].indexOf('е') !== -1
                    && tags.findIndex((item) => item['tag'] === termTags[j].replace('е', 'ё')) === -1) {
                    tags.push({ 'tag': termTags[j].replace('е', 'ё'), 'definition': terms[i]['termDefinition'] });
                }

                if (termTags[j].indexOf('ё') !== -1
                    && tags.findIndex((item) => item['tag'] === termTags[j].replace('ё', 'е')) === -1) {
                    tags.push({ 'tag': termTags[j].replace('ё', 'е'), 'definition': terms[i]['termDefinition'] });
                }
            }
        }

        // Update text with tags;
        tags.forEach((tag) => {
            let responseText = '';
            // const indexes = [...response.toLowerCase().matchAll(new RegExp(tags[i]['tag'].toLowerCase(), 'gi'))]
            const indexes = [...GlossaryService.getIndicesOf(tag['tag'], response, false)].filter(p => {
                if (GlossarySymbols.indexOf(response[p - 1]) === -1 || GlossarySymbols.indexOf(response[p + tag['tag'].length]) === -1) {
                    return false;
                }

                let isStartTag = true;

                for (let i = p; i > 0; i--) {
                    if (response[i] === '<') {
                        break;
                    }

                    if (response[i] === '>') {
                        isStartTag = false;
                        break;
                    }
                }

                let isEndTag = true;

                for (let i = p; i < response.length; i++) {
                    if (response[i] === '>') {
                        break;
                    }

                    if (response[i] === '<') {
                        isEndTag = false;
                        break;
                    }
                }

                return !isStartTag && !isEndTag;
            });

            for (let j = 0; j < indexes.length; j++) {
                const indexText = response.substr(indexes[j], tag['tag'].length);
                const startIndex = j === 0 ? 0 : indexes[j - 1] + tag['tag'].length;

                responseText += response.substr(startIndex, indexes[j] - startIndex);
                responseText += GlossaryService.formatItem(indexText, tag['definition']);

                if (j === indexes.length - 1) {
                    responseText += response.substr(indexes[j] + tag['tag'].length);
                }
            }

            if (indexes.length !== 0) {
                response = responseText;
            }
        });

        const styles = '<style>' +
            '.tiarm { position: relative; display: inline-block; background: #EAEDF3; border: 1px solid #7B8F9C; border-radius: 3px; transition: all 0.3s ease; cursor: pointer; }' +
            '.tiarm:hover { background: rgba(18, 128, 206, 0.25); border: 1px solid #1280CE; }' +
        '</style>';
        return styles + response;
    }
}
export default GlossaryService;