export const lineChartOptions = {
    maintainAspectRatio: false,
    tooltips: {
        intersect: false
    },
    scales: {
        yAxes: [
            {
                stacked: false,
                ticks: {
                    stepSize: 1,
                    beginAtZero: true
                },
                gridLines: {
                    display: true,
                    color: 'hsla(235, 12%, 27%, 0.5)'
                }
            }
        ],
        xAxes: [
            {
                stacked: true,
                gridLines: {
                    display: false
                }
            }
        ]
    }
};

export const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
        padding: {
            top: 0,
            left: 5,
            right: 5,
            bottom: 5
        }
    }
};

export const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    segmentStrokeWidth: 1,
    layout: {
        padding: {
            top: 5,
            left: 15,
            right: 15,
            bottom: 5
        }
    }
};