import ListItem from '@tiptap/extension-list-item';

export const MultiListItem = ListItem.extend({
    name: 'multiListItem',

    priority: 1000,

    parseHTML() {
        return [
            {
                tag: 'li[data-type="multi-list-item"]'
            }
        ]
    },

    addKeyboardShortcuts() {
        return {
            ...this.parent?.(),
            'Tab': () => {
                const {selection} = this.editor.view.state;
                const node = selection.$from.node(-1);

                if (node.type === this.type) {
                    return true;
                }

                return false;
            },
            'Delete': ({ editor }) => {
                const { state } = editor;
                const { $from, $to } = state.selection;

                let isListItem = false;

                state.doc.nodesBetween($from.pos, $to.pos, (node) => {
                    if (node?.type?.name === 'listItem' || node?.type?.name === 'multiListItem') {
                        isListItem = true;
                    }
                });

                if (!isListItem) {
                    return false;
                }

                document.execCommand('forwardDelete');
                return true;
            },
            'Backspace': ({ editor }) => {
                const { $from } = editor.state.selection;
                const prevNode = $from.doc.resolve($from.before()).nodeBefore;

                if (prevNode && (prevNode.type.name === 'multi-list' || prevNode.type.name === 'bulletList' || prevNode.type.name === 'orderedList')) {
                    document.execCommand('delete');
                    return true;
                }

                return false;
            }
        }
    }
})
