import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { useGlobalContext, useManualFormDataChange } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { SectionFormModel } from 'model/section/section-form-model';
import Card from 'components/card';
import { NODE_TYPES } from 'components/trees';
import LocationChange from 'components/location-change';
import Input from 'uikit/input';
import Divider from 'uikit/divider';
import api from 'api/index';
import cs from './section-form.module.scss';

const SectionForm = ({ uuid, section, loading, children, edit }) => {
    const sectionRef = useRef(section);

    const { platform, setFormDataChanged, setFormDataChangedSubmitHandler } = useGlobalContext();
    const { isFormDataTouched, handleManualFormDataChange } = useManualFormDataChange(edit);

    const _onDestinationChange = node => {
        handleManualFormDataChange();
        let sectionId = null;
        let projectId;

        if (node.nodeType === NODE_TYPES.PROJECT) {
            projectId = node.id;
        } else {
            sectionId = node.id;

            function traverseNode(_node) {
                if (_node.nodeType === NODE_TYPES.PROJECT) {
                    projectId = _node.id;
                } else {
                    traverseNode(_node.parent);
                }
            }

            traverseNode(node);
        }
        section.parentProjectId = projectId;
        section.parentSectionId = sectionId;
        section.setParentsIds(node);
        section.setVisualPath(node);
        section.setTargetNodeId(node.id);
        section.commit();

        if (section.sectionTitle) {
            section.validateTitleExists();
        }

        section.validateFields(['location']);
    };
    const onClearPath = () => {
        section.clearPath();
        section.commit();
    };

    const onTitleFocus = () => {
        section.touched.title = true;
    };
    const onTitleBlur = () => {
        if (section.parentProjectId || section.parentSectionId) {
            section.validateTitleExists();
        }
    };
    const onTitleChange = (value) => {
        handleManualFormDataChange();
        section.sectionTitle = value.replace(/\u00a0/g, ' ');

        section.validateFields(['sectionTitle']);
        section.commit();
    };

    useEffect(() => {
        if (!loading) {
            sectionRef.current = cloneDeep(section);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);
    useEffect(() => {
        if (!loading) {
            const { sectionTitle, parentProjectId, parentSectionId } = section;
            const { sectionTitle: refSectionTitle, parentProjectId: refParentProjectId, parentSectionId: refParentSectionId } = sectionRef.current;

            setFormDataChanged(!isEqual([refSectionTitle, refParentProjectId, refParentSectionId], [sectionTitle, parentProjectId, parentSectionId]) || isFormDataTouched);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [section.sectionTitle, section.parentProjectId, section.parentSectionId]);

    // Сброс до инициализирующих данных при попытке уйти с формы добавления раздела, нажав на тот же пункт в меню - "Раздел"
    function resetToInitialValues () {
        return () => {
            const initialEntity = new SectionFormModel();
            const initialEntityKeys = Object.keys(initialEntity);
            initialEntityKeys.forEach((key) => {
                section[key] = initialEntity[key];
            })
            section.commit();
        }
    }
    useEffect(() => {
        if (!edit) {
            setFormDataChangedSubmitHandler(resetToInitialValues);
        }
        return () => {
            if (!edit) {
                setFormDataChangedSubmitHandler(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className={classNames(cs.content, {[cs.mobile]: platform === 'mobile'})}>
                <Card id="sectionFormCard">
                    <Input
                        id="sectionFormTitleField"
                        className={cs.field}
                        type="text"
                        label="Название раздела"
                        value={section.sectionTitle}
                        onChange={onTitleChange}
                        disabled={loading}
                        required
                        error={section.errors.sectionTitleExists || section.errors.sectionTitle}
                        onFocus={onTitleFocus}
                        onBlur={onTitleBlur}
                    />
                    <Divider/>
                    <LocationChange
                        required
                        excludeNodes={[uuid]}
                        onClearPath={onClearPath}
                        onChange={_onDestinationChange}
                        error={section.errors.location}
                        visualPath={section.getVisualPath()}
                        rights={[GLOBAL_ACTIONS.SECTION_CREATE]}
                        autoOpenPath={section.getParentsIds(true)}
                        fetchTreeFunc={api.project.getUsersDocumentTree}
                    />
                    <Divider className={cs.divider}/>
                    <div className={cs.tool}>{children}</div>
                </Card>
            </div>
        </>
    );
};

export default SectionForm;
