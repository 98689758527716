import React from 'react';
import classNames from 'classnames';
import cx from './mobile-tree-node.module.scss';
import MobileNodeToggler from 'components/trees/mobile-tree/mobile-node-toggler';

const MobileStatisticsTreeNode = ({ node, tree, toggleState, onClick }) => {
		return (
				<div className={classNames(cx.node, {[cx.selected]: node.state.selected})} onClick={() => onClick(node)}>
						<MobileNodeToggler state={toggleState} depth={node.state.depth}
															 onClick={(event) => {
																	 event.preventDefault();
																	 event.stopPropagation();

																	 if (toggleState === 'closed') {
																			 tree.openNode(node);
																	 } else if (toggleState === 'opened') {
																			 tree.closeNode(node, { silent: true });
																	 }
															 }}
						/>
						<div className={cx.name}>
								{node.name}
						</div>
				</div>
		)
}

export default MobileStatisticsTreeNode;