import { mergeAttributes, Node } from '@tiptap/core';
import {
    getEmbedUrlFromYoutubeUrl,
    getRutubeEmbedUrl,
    getVimeoEmbedUrl,
    getVKEmbedUrl,
    isValidRutubeUrl,
    isValidVimeoUrl,
    isValidVKUrl,
    isValidYoutubeUrl,
} from './utils';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { EmbedVideoView } from './EmbedVideoView';

export const CustomEmbed = Node.create({
    name: 'embed-video',

    addOptions() {
        return {
            addPasteHandler: true,
            allowFullscreen: true,
            autoplay: false,
            ccLanguage: undefined,
            ccLoadPolicy: undefined,
            controls: true,
            disableKBcontrols: false,
            enableIFrameApi: false,
            endTime: 0,
            height: 480,
            interfaceLanguage: undefined,
            ivLoadPolicy: 0,
            loop: false,
            modestBranding: false,
            HTMLAttributes: {},
            inline: false,
            nocookie: false,
            playlist: '',
            progressBarColor: undefined,
            width: 640,
        };
    },

    inline() {
        return this.options.inline;
    },

    group() {
        return this.options.inline ? 'inline' : 'block';
    },

    draggable: true,

    addAttributes() {
        return {
            src: {
                default: null,
            },
            start: {
                default: 0,
            },
            width: {
                default: this.options.width,
            },
            height: {
                default: this.options.height,
            },
            dataAlign: {
                default: 'center',
                rendered: false,
                parseHTML: el => el.getAttribute('data-align'),
                renderHTML: attrs => {
                    return {
                        'data-align': attrs.dataAlign || 'left',
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-embed-video] iframe',
            },
            {
                tag: 'iframe',
                getAttrs: el => {
                    if (!el.getAttribute('src')) {
                        return false;
                    }

                    return el.getAttribute('src')?.includes('youtube');
                },
            },
        ];
    },

    addCommands() {
        return {
            addEmbedVideo:
                options =>
                ({ commands }) => {
                    return commands.insertContent({
                        type: this.name,
                        attrs: options,
                    });
                },
        };
    },

    renderHTML({ node, HTMLAttributes }) {
        if (isValidRutubeUrl(HTMLAttributes.src)) {
            HTMLAttributes.src = getRutubeEmbedUrl(HTMLAttributes.src);
        } else if (isValidVKUrl(HTMLAttributes.src)) {
            HTMLAttributes.src = getVKEmbedUrl(HTMLAttributes.src);
        } else if (isValidVimeoUrl(HTMLAttributes.src)) {
            HTMLAttributes.src = getVimeoEmbedUrl(HTMLAttributes.src);
        } else if (isValidYoutubeUrl(HTMLAttributes.src)) {
            HTMLAttributes.src = getEmbedUrlFromYoutubeUrl({
                url: HTMLAttributes.src,
                allowFullscreen: this.options.allowFullscreen,
                autoplay: this.options.autoplay,
                ccLanguage: this.options.ccLanguage,
                ccLoadPolicy: this.options.ccLoadPolicy,
                controls: this.options.controls,
                disableKBcontrols: this.options.disableKBcontrols,
                enableIFrameApi: this.options.enableIFrameApi,
                endTime: this.options.endTime,
                interfaceLanguage: this.options.interfaceLanguage,
                ivLoadPolicy: this.options.ivLoadPolicy,
                loop: this.options.loop,
                modestBranding: this.options.modestBranding,
                nocookie: this.options.nocookie,
                origin: this.options.origin,
                playlist: this.options.playlist,
                progressBarColor: this.options.progressBarColor,
                startAt: HTMLAttributes.start || 0,
            });
        }

        return [
            'div',
            { 'data-embed-video': '', 'data-align': node.attrs.dataAlign, class: 'tiptap' },
            [
                'iframe',
                mergeAttributes(
                    this.options.HTMLAttributes,
                    {
                        width: this.options.width,
                        height: this.options.height,
                        allowfullscreen: this.options.allowFullscreen,
                        autoplay: this.options.autoplay,
                        ccLanguage: this.options.ccLanguage,
                        ccLoadPolicy: this.options.ccLoadPolicy,
                        disableKBcontrols: this.options.disableKBcontrols,
                        enableIFrameApi: this.options.enableIFrameApi,
                        endTime: this.options.endTime,
                        interfaceLanguage: this.options.interfaceLanguage,
                        ivLoadPolicy: this.options.ivLoadPolicy,
                        loop: this.options.loop,
                        modestBranding: this.options.modestBranding,
                        origin: this.options.origin,
                        playlist: this.options.playlist,
                        progressBarColor: this.options.progressBarColor,
                    },
                    HTMLAttributes
                ),
            ],
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(EmbedVideoView);
    },
});
