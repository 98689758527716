import ApiClient from './api-axios';

export default class GlobalMenuApi extends ApiClient {
    getMenuButtons = async () => {
        return this.get('menu/buttons');
    };

    hasAccess = async () => {
        return this.get('menu/access');
    };
}