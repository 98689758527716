import ApiClient from './api-axios';

export default class RoleApi extends ApiClient {
    getRoles = async () => {
        return await this.get('roles');
    };

    getUserRolesInfo = async () => {
        return await this.get('roles/info');
    };

    getRolesList = async () => {
        return await this.get('roles/list');
    };

    getPermittedRoles = async (resourceId, login) => {
        return await this.get(`roles/permitted-roles/${resourceId}/${login}`);
    };

    addUserPermissions = async (resourceType, resourceId, login, type, role) => {
        return await this.post('lock/permissions/user/add', {
            'resourceType': resourceType,
            'resourceId': resourceId,
            'roleId': role,
            'username': login,
            'updateType': type === 0 ? 'CURRENT' : type === 1 ? 'EXISTING_ONLY' : 'ALL'
        });
    };

    addGroupPermissions = async (resourceType, resourceId, groupId, type, role) => {
        return await this.post('lock/permissions/group/add', {
            'resourceType': resourceType,
            'resourceId': resourceId,
            'roleId': role,
            'groupId': groupId,
            'updateType': type === 0 ? 'CURRENT' : type === 1 ? 'EXISTING_ONLY' : 'ALL'
        });
    };

    getUserRoleTree = async (login, resourceId, resourceType/*, roleId*/) => {
        //if (roleId) {
        //    return await this.get(`lock/roles/user/tree/${login}/${resourceId}/${resourceType}/${roleId}`);
        //} else {
        //    return await this.get(`lock/roles/user/tree/${login}/${resourceId}/${resourceType}`);
        //}
        //FIXME
        return await this.get(`lock/roles/user/tree/${login}/${resourceId}/${resourceType}`);
    };

    getGroupRoleTree = async (id, resourceId, resourceType/*, roleId*/) => {
        //return await this.get(`lock/roles/group/tree/${id}/${resourceId}/${resourceType}/${roleId}`);
        //FIXME
        return await this.get(`lock/roles/group/tree/${id}/${resourceId}/${resourceType}`);
    };

    getResourceUsers = async (resourceId, resourceType) => {
        return await this.get(`lock/roles/user/list/${resourceId}/${resourceType}`);
    };

    getResourceGroups = async (resourceId, resourceType) => {
        return await this.get(`lock/roles/group/list/${resourceId}/${resourceType}`);
    };
}
