import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonSubscript = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const toggleSubScript = e => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);
        editor?.chain().focus().toggleSubscript().run();
    };

    return (
        <MenuButton
            tooltip="Подстрочный"
            icon={Icons.EditorIconSubScript}
            onClick={toggleSubScript}
            selected={editor?.isActive('subscript') ?? undefined}
        ></MenuButton>
    );
};
