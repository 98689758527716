function getSectionUrl(projectId, sectionId, tab = 'articles') {
    return `/projects/${projectId}/${sectionId}/section/${tab}`;
}

function getArchiveSectionUrl(projectId, sectionId, tab = 'articles') {
    return `/archive/${projectId}/${sectionId}/section/${tab}`;
}

const UrlUtils = { getSectionUrl, getArchiveSectionUrl };
export default UrlUtils;
