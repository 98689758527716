import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import cs from './tree-node-name.module.scss';
import { NODE_TYPES } from 'components/trees';
import MultiClamp from 'react-multi-clamp';

const NodeName = ({ state, children, className, node, highlight, wordBreak, hideIcons, archive }) => {

    const style = React.useMemo(() => {
        let _style = {
            opacity: '75%'
        }

        if (node.nodeType === NODE_TYPES.PROJECT || node.nodeType === NODE_TYPES.SECTION) {
           _style = {
               ..._style,
               fontSize: '13px',
               opacity: '100%'
           }
        }

        if (highlight && highlight.length > 0 && highlight.filter((item) => item).map((item) => item.toLowerCase()).some(( item ) => node.name.toLowerCase().includes(item))) {
            _style.backgroundColor = 'yellow'
        }

        if (node.nodeType === NODE_TYPES.ARTICLE) {
            _style.fontWeight = '400';

            if (node.state.selected) {
                _style.opacity = '100%';
            }
        }

        if (node.nodeType === NODE_TYPES.SECTION && hideIcons) {
            _style.fontWeight = 'normal';
        }

        return _style;
    }, [highlight, node, hideIcons]);

    return (
        <>
            {node.unread && !archive ? <div className={cs.unread} style={{ position: 'absolute', left: 0, bottom: 0 }} /> : ''}
            <div className={cn(cs.node, className, { [cs.opened]: state === 'opened', [cs.wordBreak]: wordBreak })} style={style}>
                <MultiClamp clamp={1} ellipsis={'...'}>{children}</MultiClamp>
            </div>
            {/*<span className={cn(cs.node, className, { [cs.opened]: state === 'opened', [cs.wordBreak]: wordBreak })} style={style}>*/}
            {/*    {children}*/}
            {/*</span>*/}
        </>
    );
};

NodeName.propTypes = {
    wordBreak: PropTypes.bool,
    hideIcons: PropTypes.bool,
}

export default NodeName;
