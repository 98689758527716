import React from 'react';
import PropTypes from 'prop-types';
import cs from '../infinite-tree/tree-toggler.module.scss';

const MobileNodeToggler = ({ state, depth, ...props }) => {
    const iconUrl = state === 'closed' ? '/icons/right.svg' : state === 'opened' ? '/icons/down.svg' : '';

    return (
        <div {...props} className={cs.toggler}
             style={{ marginLeft: `${depth * 24}px`, backgroundImage: `url(${iconUrl})` }}/>
    );
};

MobileNodeToggler.propTypes = {
    state: PropTypes.string,
    depth: PropTypes.number
};

export default MobileNodeToggler;