import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Select } from 'uikit/select';
import Button from 'uikit/button';
import cs from '../create-script.module.scss';

function ActiveLinkSettings({ activeNode, linkedNodeId, stepsOptions, onChange, onOptionHover, onOptionOut, onCancel, onSubmit }) {
    const { id: activeNodeId, data } = activeNode;

    return (
        <div className={cs.stepContainer} data-testid="activeLink">
          <div className={cs.stepHeading}>
              <div className={cs.stepHeadingLabel} data-testid="activeLinkLabel">Связать шаг с существующим шагом</div>
          </div>

          <Select
                label={'Выберите шаг'}
                placeholder="Выберите шаг из списка..."
                id="activeLinkSelectStepCombobox"
                value={stepsOptions.find((e) => e.value === linkedNodeId) ?? linkedNodeId}
                options={stepsOptions}
                onChange={(e) => {
                    onChange(activeNodeId, e.value)
                }}
                className={cs.conditionNodeSelect}
                components={{
                    Option: ({ innerProps, innerRef, isSelected, children, value }) => {
                        return (
                            <div
                                ref={innerRef}
                                {...innerProps}
                                className={classNames(
                                    cs.conditionOption,
                                    {
                                        [cs.conditionOptionActive]: isSelected,
                                    },
                                )}
                                onMouseEnter={() => {
                                    onOptionHover(activeNodeId, value);
                                }}
                                onMouseLeave={() => {
                                    onOptionOut();
                                }}
                            >
                                {children}
                            </div>
                        );
                    }
                }}
                isSearchable={ false }
            />

            <div className={cs.controlsContainer}>
                <Button className={cs.cancelButton} onClick={onCancel} label="Отмена" data-testid="activeLinkCancelBtn" />
                <Button label="Сохранить" color="green" onClick={() => {
                    onSubmit(activeNodeId, linkedNodeId, data)
                }} disabled={!linkedNodeId} data-testid="activeLinkOkBtn"/>
            </div>
        </div>
    )
}

ActiveLinkSettings.propTypes = {
    activeNode: PropTypes.object,
    linkedNodeId: PropTypes.string,
    stepsOptions: PropTypes.array,
    onChange: PropTypes.func,
    onOptionHover: PropTypes.func,
    onOptionOut: PropTypes.func,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default ActiveLinkSettings;