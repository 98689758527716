import React, { useState } from 'react';
import { humanFileSize } from 'lib/helpers';
import { FILES_TYPE_ICONS } from 'lib/config/constant';
import { useMessage } from 'lib/hooks';
import Icon from 'uikit/icon';
import { Icons } from 'uikit/icon';
import Button from 'uikit/button/button';
import Input from 'uikit/input';
import api from 'api/index';
import IconButton from 'uikit/icon-button';
import style from './file-edit-dialog.module.scss';

export const FileEditDialog = ({ file: _file, handleClose, onFileChange }) => {
    const { addSuccess, addError } = useMessage();

    const file = { ..._file };
    const [fileName, setFileName] = useState(_file.name);
    const [nameError, setNameError] = useState(null);

    const onFileNameChange = async (name) => {
        setFileName(name.replace(/\s+/g, ' ') + `.${fileName.split('.').at(-1)}`);

        try {
            if (name !== _file.name) {
                const isValid = await api.file.validateFileName(name, file.projectIds);
                setNameError(!isValid ? 'Данное название уже используется' : null);

                return;
            }

            setNameError(null);
        } catch (error) {}
    };

    const submitFileName = async () => {
        if (nameError) {
            return;
        }

        if (fileName.length > 254) {
            setNameError('Превышена максимальная длинна символов');

            return;
        }

        if (!fileName.toString().trim()) {
            setFileName('');
            setNameError('Поле обязательно для заполнения');

            return;
        }

        try {
            const name = fileName.trim().replace(/\s+/g, ' ');

            await api.file.changeFileName(JSON.stringify({ fileId: file.fileId, name }));
            addSuccess('Название файла изменено');
            handleClose();
            onFileChange(name);
        } catch (error) {
            addError('Произошла ошибка при изменении названия файла');
        }
    };

    return (
        <>
            <div className={style.modalHeader}>
                <h3>Изменение файла</h3>
                <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16} />} onClick={handleClose} />
            </div>
            <div className={style.modalBody}>
                <Input
                    className={style.inputWrapper}
                    error={nameError}
                    value={fileName.replace(`.${fileName.split('.').at(-1)}`, '')}
                    label="Название файла"
                    maxLength={254}
                    onChange={onFileNameChange}
                />
                <div className={style.nameCell}>
                    <div className={style.iconWrapper}>
                        <Icon type={FILES_TYPE_ICONS[file.type] || Icons.NONE_FILE} width={24} height={24} />
                    </div>
                    <div className={style.descWrapper}>
                        <div>
                            <div className={style.fileName}>{file.name}</div>
                            <div className={style.fileDescription}>{humanFileSize(file.size, true)}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.modalFooter}>
                <Button label="Отменить" onClick={handleClose} />
                <Button color="green" disabled={!!nameError} label="Подтвердить" onClick={submitFileName} />
            </div>
        </>
    );
};
