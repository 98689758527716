export const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

const format = (dt, alwaysShowYear = false, dateTimeSeparator = '', isTodayFormat = false) => {
    if (!(dt instanceof Date)) {
        try {
            dt = new Date(dt);
        } catch (e) {
            return dt;
        }
    }

    const year = dt.getFullYear();
    const month = dt.getMonth();
    const day = dt.getDate();

    let hours = dt.getHours();
    hours = hours.toString().length === 1 ? '0' + hours : hours;

    let minutes = dt.getMinutes();
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes;

    if (alwaysShowYear || year !== new Date().getFullYear()) {
        if (isTodayFormat && day === new Date().getDate()) {
            return hours + ':' + minutes;
        } else {
            return day + ' ' + months[month] + ', ' + year + ', ' + dateTimeSeparator + hours + ':' + minutes;
        }
    } else {
        if (isTodayFormat && day === new Date().getDate()) {
            return hours + ':' + minutes;
        } else {
            return day + ' ' + months[month] + ', ' + dateTimeSeparator + hours + ':' + minutes;
        }
    }
};

export default format;