import React, { useEffect } from 'react';
import Modal from 'react-modal';

import { useEditorContext } from '../../context';
import { MenuButton } from './MenuButton';
import { Icons } from 'uikit/icon';
import { ModalBody, ModalFooter, ModalHeader } from 'components/modal/modal-components';
import { desktopStyles } from 'lib/util/modalStyles';
import Input from 'uikit/input/input';
import { Select } from 'uikit/select';
import { NodeSelection } from '@tiptap/pm/state';

export const MenuButtonLink = ({ setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const [show, setShow] = React.useState(false);
    const [linkData, setLinkData] = React.useState({ linkUrl: '', linkName: '' });

    const toggleModal = () => {
        setCurrentMenu(undefined);
        setShow(prevState => {
            if (!prevState) {
                const isActive = editor.isActive('link');

                if (isActive) {
                    const link = editor?.getAttributes('link');
                    editor.commands.extendMarkRange('link');

                    const sel = NodeSelection.create(
                        editor.view.state.doc,
                        editor.state.selection.$from.pos - editor.state.selection.$from.textOffset
                    );
                    setLinkData({ linkUrl: link.href, linkName: sel.node.textContent });
                } else {
                    const sel = editor.state.selection;
                    const text = editor.state.doc.textBetween(sel.from, sel.to, ' ');

                    setLinkData({ linkUrl: '', linkName: text });
                }
            } else {
                setLinkData({ linkUrl: '', linkName: '' });
            }

            return !prevState;
        });
    };

    const handleSubmit = (name, url) => {
        editor.commands.setLink(name, { href: url });
        toggleModal();
    };

    return (
        <>
            <MenuButton
                icon={Icons.EditorIconLink}
                onClick={toggleModal}
                tooltip={isTooltip === false ? null : 'Ссылка'}
                label="Ссылка"
            ></MenuButton>

            <LinkModal data={linkData} show={show} onClose={toggleModal} onSubmit={handleSubmit} />
        </>
    );
};

export const LinkModal = ({ data, show, onSubmit, onClose }) => {
    const editor = useEditorContext();

    const [anchors, setAnchors] = React.useState([]);

    const [linkUrl, setLinkUrl] = React.useState('');
    const [linkName, setLinkName] = React.useState('');
    const [linkType, setLinkType] = React.useState({ label: 'Ссылка', value: 'LINK' });

    const handleClose = () => {
        setLinkUrl('');
        setLinkName('');
        setLinkType({ label: 'Ссылка', value: 'LINK' });
        onClose();
    };

    const handleSubmit = () => {
        onSubmit(String(linkName).replaceAll(' ', '') ? linkName : linkUrl, linkType.value === 'ANCHOR' ? '#' + linkUrl : linkUrl);

        setLinkUrl('');
        setLinkName('');
        setLinkType({ label: 'Ссылка', value: 'LINK' });
    };

    useEffect(() => {
        if (editor && linkType.value === 'ANCHOR') {
            const anchorList = [];

            editor.state.doc.descendants(node => {
                if (node.type.name === 'anchor') {
                    anchorList.push({ label: node.attrs.title || node.attrs.id || node.attrs.name, value: node.attrs.id });
                }
            });

            setAnchors(anchorList);
        }
    }, [editor, linkType]);

    useEffect(() => {
        if (data) {
            const isAnchor = data.linkUrl?.substring(0, 1) === '#';
            setLinkUrl(isAnchor ? data.linkUrl?.substring(1) : data.linkUrl || '');

            setLinkName(data.linkName || '');
            setLinkType(isAnchor ? { label: 'Ссылка на якорь в тексте', value: 'ANCHOR' } : { label: 'Ссылка', value: 'LINK' });
        }
    }, [data]);

    return (
        <Modal
            isOpen={show}
            preventScroll
            style={{ ...desktopStyles, content: { ...desktopStyles.content, maxWidth: 570, width: 'calc(100% - 20px)', border: 'none' } }}
        >
            <ModalHeader title="Добавление ссылки" onClose={handleClose} />
            <ModalBody>
                <Input
                    wrapperStyle={{ paddingBottom: 20 }}
                    label="Название ссылки"
                    value={linkName}
                    placeholder="Введите название ссылки"
                    onChange={setLinkName}
                />
                <div style={{ paddingBottom: 20 }}>
                    <Select
                        label="Тип ссылки"
                        isMulti={false}
                        isSearchable={false}
                        value={linkType}
                        onChange={value => setLinkType(value)}
                        options={[
                            { label: 'Ссылка', value: 'LINK' },
                            { label: 'Ссылка на якорь в тексте', value: 'ANCHOR' },
                        ]}
                        style={{ menuPortal: base => ({ ...base, zIndex: 19999 }) }}
                        menuPortalTarget={document.body}
                    />
                </div>
                {linkType.value === 'LINK' && (
                    <Input
                        wrapperStyle={{ paddingBottom: 20 }}
                        label="Ссылка"
                        value={linkUrl}
                        placeholder="Вставьте ссылку"
                        onChange={setLinkUrl}
                        required={true}
                    />
                )}
                {linkType.value === 'ANCHOR' && (
                    <div style={{ paddingBottom: 20 }}>
                        <Select
                            isMulti={false}
                            value={anchors.find(a => a.value === linkUrl)}
                            isSearchable={false}
                            label="Имя якоря"
                            onChange={e => setLinkUrl(e.value)}
                            placeholder="Найти имя ссылки"
                            options={anchors}
                            style={{ menuPortal: base => ({ ...base, zIndex: 19999 }) }}
                            menuPortalTarget={document.body}
                        />
                    </div>
                )}
            </ModalBody>
            <ModalFooter
                buttons={[
                    { label: 'Отмена', onClick: handleClose },
                    {
                        label: 'Сохранить',
                        color: 'blue',
                        onClick: handleSubmit,
                        disabled: !linkUrl.replaceAll(' ', ''),
                    },
                ]}
            />
        </Modal>
    );
};
