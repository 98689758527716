const ruLayout = [
  "а", // f
  "б", // ,
  "в", // d
  "г", // u
  "д", // l
  "е", // t
  "ё", // `
  "ж", // ;
  "з", // p
  "и", // b
  "й", // q
  "к", // r
  "л", // k
  "м", // v
  "н", // y
  "о", // j
  "п", // g
  "р", // h
  "с", // c
  "т", // n
  "у", // e
  "ф", // a
  "х", // [
  "ц", // w
  "ч", // x
  "ш", // i
  "ъ", // ]
  "ы", // s
  "ь", // m
  "э", // '
  "ю", // .
  "я", // z
];
const engLayout = [
  "f", // а
  ",", // б
  "d", // в
  "u", // г
  "l", // д
  "t", // е
  "`", // ё
  ";", // ж
  "p", // з
  "b", // и
  "q", // й
  "r", // к
  "k", // л
  "v", // м
  "y", // н
  "j", // о
  "g", // п
  "h", // р
  "c", // с
  "n", // т
  "e", // у
  "a", // ф
  "[", // х
  "w", // ц
  "x", // ч
  "i", // ш
  "]", // ъ
  "s", // ы
  "m", // ь
  "'", // э
  ".", // ю
  "z", // я
];

const switchLayout = {};
ruLayout.forEach((char, index) => {
  switchLayout[char] = engLayout[index];
})
engLayout.forEach((char, index) => {
  switchLayout[char] = ruLayout[index];
})

// word - string
export const getSwitchedLayout = (word) => {
  const wordChars = word.toLowerCase().split('');
  return wordChars.map((char) => {
    return switchLayout[char] ? switchLayout[char] : char
  }).join('');
}