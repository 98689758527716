import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { parse } from 'query-string';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import classNames from 'classnames';
import { TextareaAutoSizable } from 'uikit/textareaAutoSizable/TextareaAutoSizable';
import Button from 'uikit/button';
import LocationChange from 'components/location-change';
import { NODE_TYPES } from 'components/trees';
import TreeUtils from 'lib/util/tree.util';
import { useMessage, useGlobalContext, useManualFormDataChange } from 'lib/hooks';
import api from 'api';
import cs from '../create-script.module.scss';

const removeSpaces = (str) => str.trim().replace(/[\s]+/ig, ' ');

// Получение пути секций - слева-направо по вложенности
const getSectionsPath = (rootSections, sectionId) => {
    const sectionsPath = [];
    let found = false;

    const recurse = (sections) => {
        for (let i = 0; i < sections.length; i++) {
            sectionsPath.push(sections[i].uuid);

            if ((sections[i].uuid === sectionId)) {
                found = true;
                break;
            } else {
                const subSections = sections[i].subSections ?? [];
                if (subSections?.length > 0) {
                    recurse(subSections);
                    if(found){
                        break;
                    }
                }
            }
            sectionsPath.pop();
        }
    }

    recurse(rootSections);

    return sectionsPath
}

const getSectionsVisualPath = (sections, sectionsPath) => {
    const result = [];
    let level = 0;

    const getSectionPath = (sections, pathItem) => {
        const sectionNode = sections.find(({ uuid }) => uuid === pathItem);
        if (sectionNode) {
            result.push(sectionNode.title);

            if (sectionNode.subSections) {
                level++;
                getSectionPath(sectionNode.subSections, sectionsPath[level]);
            }
        }
    };

    getSectionPath(sections, sectionsPath[level]);

    return result;
}

function ScriptInfo({ data, errors, setErrors, onSubmit, onDismiss, edit = false }) {
    const { addError } = useMessage();
    const [scriptName, setScriptName] = useState('');
    const [locationLoading, setLocationLoading] = useState(false);
    const location = useLocation();
    const { setFormDataChanged } = useGlobalContext();
    const dataRef = useRef(data);
    const { isFormDataTouched, handleManualFormDataChange } = useManualFormDataChange(edit);

    const [scriptDescription, setScriptDescription] = useState('');
    const [scriptDestination, setScriptDestination] = useState({
        parentProjectId: '',
        parentSectionId: '',
        visualPath: [],
        parentsIds: [],
    });

    const textLenghtLimit = 254;

    const onScriptNameChangeHandler = (e) => {
        handleManualFormDataChange();

        if (e.length > textLenghtLimit) {
            setErrors((prevState) => ({ ...prevState, scriptName: 'Превышена максимальная длина текста' }));
        } else if (e.length === 0) {
            setErrors((prevState) => ({ ...prevState, scriptName: 'Поле обязательно для заполнения' }));
        } else {
            setErrors((prevState) => ({ ...prevState, scriptName: null }));
        }

        if (e && (errors?.title || errors?.titleInvalid)) {
            setErrors((prevState) => ({ ...prevState, title: null, titleInvalid: null }));
        }

        const re = new RegExp(String.fromCharCode(160), "g");
        setScriptName(e.replace(re, " "));
    };

    const onScriptDecriptionChangeHandler = (e) => {
        handleManualFormDataChange();

        if (e.length > textLenghtLimit) {
            setErrors((prevState) => ({ ...prevState, scriptDescription: 'Превышена максимальная длина текста' }));
        } else {
            setErrors((prevState) => ({ ...prevState, scriptDescription: null }));
        }

        const re = new RegExp(String.fromCharCode(160), "g");
        setScriptDescription(e.replace(re, " "))
    };

    const _onDestinationChange = (node) => {
        if (node.nodeType !== NODE_TYPES.SECTION) {
            addError('Для выбора доступны только разделы.');
        } else {
            let sectionId = node.id;
            let projectId;
            let parentsIds = [node.id];

            function traverseNode(_node) {
                if (_node.nodeType === NODE_TYPES.PROJECT) {
                    projectId = _node.id;
                    parentsIds.push(_node.id);
                } else {
                    parentsIds.push(_node.parent.id);
                    traverseNode(_node.parent);
                }
            }

            traverseNode(node);

            setErrors((prevState) => ({ ...prevState, path: null }));

            setScriptDestination({
                parentProjectId: projectId,
                parentSectionId: sectionId,
                visualPath: TreeUtils.getVisualPathTo(node),
                parentsIds: parentsIds.reverse()
            });
            handleManualFormDataChange();
            setFormDataChanged(false);

            // Закомментировано, т.к. ломает работу модалки с предупреждением при уходе с формы редактирования
            // const searchParams = new URLSearchParams(location.search);
            // searchParams.set('projectId', projectId);
            // searchParams.set('sectionId', sectionId);
            // window.history.pushState(null, '', `${location.pathname}?${searchParams.toString()}`);
            // navigate(`/scripting/create?projectId=${projectId}&sectionId=${sectionId}`, 'no-check-form-data-changed');
        }
    };
    const clearDestination = () => {
        handleManualFormDataChange();
        setScriptDestination({
            parentProjectId: null,
            parentSectionId: null,
            visualPath: '',
            parentsIds: [],
        });
    };

    const isSubmitHandler = () => {
        return !errors?.scriptName && !errors?.scriptDescription && scriptDestination.visualPath.length !== 0
            && !!scriptName.match(/[^\s]/ig)
            && (data.scriptName !== scriptName || data.scriptDescription !== scriptDescription
                || JSON.stringify(data.scriptDestination) !== JSON.stringify(scriptDestination));
    };

    const onSubmitHandler = () => {
        onSubmit({ scriptName: removeSpaces(scriptName), scriptDescription: removeSpaces(scriptDescription), scriptDestination });
    };

    const handleClickCancel = () => {
        onDismiss();
    };

    // На старте проверяем переданные query-параметры
    // И при наличии их, делаем запрос дерева проектов для установки пути в месторасположение
    useEffect(() => {
        const { projectId, sectionId } = parse(location.search);
        if (projectId && !scriptDestination.visualPath.length) {
            setLocationLoading(true);
            const treeNodes = api.project.getUsersDocumentTree();

            treeNodes.then((response) => {
                const projectNode = response.find(({ uuid }) => uuid === projectId);

                if (projectNode) {
                    setScriptDestination({
                        ...scriptDestination,
                        parentProjectId: projectId,
                        visualPath: [
                            projectNode.title
                        ]
                    });
                    if (sectionId) {
                        const sectionsPath = getSectionsPath(projectNode.rootSections, sectionId);
                        const parentsIds = [projectId];
                        let visualPath = [projectNode.title];
                        sectionsPath.forEach((sectionsPathItem) => {
                            parentsIds.push(sectionsPathItem);
                        });

                        visualPath = visualPath.concat(getSectionsVisualPath(projectNode.rootSections, sectionsPath, visualPath));

                        setScriptDestination({
                            parentProjectId: projectId,
                            parentSectionId: sectionId,
                            parentsIds,
                            visualPath
                        });
                    }
                }
                setLocationLoading(false);
            })
        }
        dataRef.current = cloneDeep(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setScriptName(data?.scriptName || '');
        setScriptDescription(data?.scriptDescription || '');
        if (data && data?.scriptDestination ) {
            const { scriptDestination: { parentProjectId, sectionId, visualPath } } = data;

            if (parentProjectId && sectionId && visualPath.length) {
                setScriptDestination(data?.scriptDestination);
                return;
            }
        }
        const { projectId, sectionId } = parse(location.search);
        if (!projectId && !sectionId){
            setScriptDestination(data?.scriptDestination || {
                parentProjectId: '',
                parentSectionId: '',
                visualPath: [],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        const { scriptName: refScriptName, scriptDescription: refScriptDescription, scriptDestination: refScriptDestination } = dataRef.current;
        setFormDataChanged(
            !isEqual({
                scriptName: refScriptName,
                scriptDescription: refScriptDescription,
            }, {
                scriptName,
                scriptDescription,
            }) || !isEqual(scriptDestination, refScriptDestination) || isFormDataTouched
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scriptName, scriptDescription, scriptDestination]);
    useEffect(() => {
        return () => {
            setErrors((prevState) => ({ ...prevState, scriptName: null, scriptDescription: null }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={cs.stepContainer} style={{ height: "100%" }} data-testid="scriptInfo">
            <p className={cs.stepHeadingLabel} data-testid="scriptInfoLable">О скрипте</p>
            <TextareaAutoSizable
                className={classNames(cs.desc, cs.scriptInfoName)}
                label="Название скрипта"
                placeholder="Название скрипта"
                rows="2"
                value={scriptName}
                onChange={onScriptNameChangeHandler}
                error={errors?.scriptName}
                data-testid="scriptInfoTitle"
            />
            <TextareaAutoSizable
                className={classNames(cs.desc, cs.scriptInfoDescription)}
                label="Описание скрипта"
                placeholder="Описание скрипта"
                rows="2"
                value={scriptDescription}
                onChange={onScriptDecriptionChangeHandler}
                error={errors?.scriptDescription}
                data-testid="scriptInfoDescription"
            />
            <LocationChange
                title="Выберите месторасположение"
                placeholder="Нажмите для выбора расположения..."
                visualPath={ (scriptDestination?.parentProjectId && scriptDestination?.parentSectionId) ? scriptDestination?.visualPath : []}
                onChange={_onDestinationChange}
                onClearPath={clearDestination}
                error={errors?.path}
                fetchTreeFunc={api.project.getUsersDocumentTree}
                loading={locationLoading}
                autoOpenPath={
                    scriptDestination.parentsIds ?? [scriptDestination.parentProjectId, scriptDestination.parentSectionId]
                        .filter(item => item)
                }
                locationPathClassName={cs.scriptLocationPath}
            />
            <div className={cs.controlsContainer}>
                <Button className={cs.cancelButton} label="Отмена" onClick={handleClickCancel} data-testid="scriptInfoCancelBtn" />
                <Button label="Подтвердить" color="green" onClick={onSubmitHandler} disabled={!isSubmitHandler()} data-testid="scriptInfoOkBtn" />
            </div>
        </div>
    );
}

ScriptInfo.propTypes = {
    data: PropTypes.shape({
        scriptName: PropTypes.string,
        scriptDescription: PropTypes.string,
        scriptDestination: PropTypes.shape({
            parentProjectId: PropTypes.string,
            parentSectionId: PropTypes.string,
            visualPath: PropTypes.arrayOf(PropTypes.string),
        }),
    }),
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};

export default ScriptInfo;
