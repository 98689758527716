import React from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import cx from '../../editor.module.scss';
import classNames from 'classnames';

export function DiagramView(props) {
  const { src, width, height, layout } = props.node.attrs;

  return (
    <NodeViewWrapper>
      <div
        className={classNames(cx.diagram, {
          [cx.left]: layout === 'left',
          [cx.right]: layout === 'right',
          [cx.center]: layout === 'center',
        })}
        data-node-type="remote-diagram"
      >
        <iframe title="draw" src={src} width={width} height={height} />
      </div>
    </NodeViewWrapper>
  );
}
