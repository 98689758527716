import React, { useCallback } from 'react';
import cx from './global-menu.module.scss';
import { Link, useLocation } from '@reach/router';
import { useGlobalContext } from 'lib/hooks';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import PropTypes from 'prop-types';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync/src';
import { UnreadLabel } from 'uikit/global-menu/unread-label';

export const MENU_CONTENT_TYPE = {
    MAIN_MENU: 0,
    CUSTOM: 1
}
const GlobalMenu = ({
    menuItems,
    itemActive,
    toggleMenu,
    isOpen,
    menuContentType,
    menuTitle,
    renderMenu,
    isTicker,
    unreadArticles,
    unreadNews,
    onClose
}) => {
    const location = useLocation();
    const { platform } = useGlobalContext();

    const isActive = useCallback(to => {
        if (to === '') {
            return location.pathname === '/';
        } else if (location.pathname.includes('/favorites')) {
            return false;
        } else {
            return location.pathname.includes(to);
        }
    }, [location.pathname]);

    const unreadCount = {
        'news': unreadNews,
        'projects': unreadArticles,
    }

    if (platform === 'mobile') {
        return (
            <div className={classNames(cx.mobileMenuWrapper, {[cx.withTicker]: isTicker})}>
                <ScrollSync>
                    <div className={classNames(cx.menu, {[cx.open]: isOpen})}>
                        <div className={cx.menuBackground} onClick={() => toggleMenu()}/>
                        <ScrollSyncPane>
                            <ul className={cx.menuItems}>
                                {menuItems.filter(p => p.isMobile).map((menu, i) => (
                                    <Link
                                        id={menu.id}
                                        key={i}
                                        to={menu.to}
                                        onClick={menu.onClick}
                                        className={classNames(
                                            cx.itemContainer,
                                            {[cx.add]: menu.to === 'add', [cx.active]: isActive(menu.to)})
                                        }
                                    >
                                        <div className={cx.item}>
                                            <Icon type={menu.mobileIcon} width={22} height={22}/>
                                        </div>
                                    </Link>
                                ))}
                                <span className={cx.shadowItem}/>
                            </ul>
                        </ScrollSyncPane>

                        <ScrollSyncPane>
                            <div className={cx.menuContent}>
                                {menuContentType === MENU_CONTENT_TYPE.MAIN_MENU &&
                                <div className={cx.mainMenu}>
                                    {menuItems.filter(p => p.isMobile).map((menu, i) => (
                                        <Link
                                            id={menu.id}
                                            key={i}
                                            className={classNames(cx.textItem, {[cx.active]: isActive(menu.to)})}
                                            to={menu.to}
                                            onClick={menu.onClick}
                                        >
                                            {menu.title}
                                            {menu.canExpand && (
                                                <div className={cx.textItemIcon}>
                                                    <Icon type={Icons.ARROW_RIGHT} width={12} height={12} />
                                                </div>
                                            )}
                                        </Link>
                                    ))}
                                </div>}

                                {menuContentType === MENU_CONTENT_TYPE.CUSTOM &&
                                <div className={cx.subMenuContainer}>
                                    <div className={cx.subMenuTitle}>
                                        {/*<IconButton*/}
                                        {/*    className={cx.backButton}*/}
                                        {/*    icon={<Icon type={Icons.ARROW} width={10} height={10}/>}*/}
                                        {/*    onClick={() => navigate(-1)}*/}
                                        {/*/>*/}
                                        <div className={cx.menuTitle}>
                                            {menuItems.find(v => (v.to === '' && location.pathname === '/'))?.title
                                                || (!location.pathname.includes('/favorites')
                                                    && menuItems.find(v => (v.to !== '' && location.pathname.includes(v.to)))?.title)
                                                || menuTitle}
                                        </div>
                                    </div>
                                    <div className={cx.subMenu}>
                                        {renderMenu(onClose)}
                                    </div>
                                </div>}
                            </div>
                        </ScrollSyncPane>
                    </div>
                </ScrollSync>
            </div>
        )
    }
    if (platform === 'desktop') {
        return  (
            <div className={classNames(cx.menuWrapper, {[cx.withTicker]: isTicker})}>
                <div className={cx.menu}>
                    <ul className={cx.menuItems}>
                        {menuItems.map((menu, i) => (
                            <Link id={menu.id} key={i} to={menu.to}>
                                <div className={`${cx.item} ${itemActive === menu.to.split('/')[0] ? cx.itemActive : ''}`}>
                                    <div className={cx.imgContainer}>
                                        <img src={`/icons/${menu.icon}.svg`} alt='' />
                                    </div>
                                    <p>{menu.title}</p>
                                    <UnreadLabel count={unreadCount[menu.icon]} />
                                </div>
                            </Link>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }

}

GlobalMenu.propTypes = {
    menuItems: PropTypes.array,
    itemActive: PropTypes.string,
    toggleMenu: PropTypes.func,
    isOpen: PropTypes.bool,
    menuContentType: PropTypes.number,
    menuTitle: PropTypes.string,
    renderMenu: PropTypes.func,
    isTicker: PropTypes.bool,
    unreadArticles: PropTypes.number,
    unreadNews: PropTypes.number,
};

export default GlobalMenu;

