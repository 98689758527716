import React from 'react';
import { components } from 'react-select';
import Icon, { Icons } from 'uikit/icon';
import { Select as CustomSelect } from './index';

const Menu = (props) => {
    if (props.selectProps.inputValue) {
        return <components.Menu {...props} />;
    } else {
        return <div/>;
    }
};

const Option = (props) => {
    const text = String(props.data.label);
    const search = String(props.selectProps.inputValue);

    let idx = text?.toLowerCase().indexOf(search?.toLowerCase());
    let label = idx >= 0 ? [text.substring(0, idx), <b key={idx} style={{ fontWeight: 'bolder' }}>
        {text.substring(idx, idx + search.length)}</b>, text.substring(idx + search.length)] : text;

    return (
        <components.Option {...props}>{label}</components.Option>
    );
};

const NoOptionsMessage = (props) => {
    if (props.selectProps.inputValue) {
        return <components.NoOptionsMessage {...props}>{props.selectProps.noDataMessage}</components.NoOptionsMessage>;
    } else {
        return <div/>;
    }
};

const Placeholder = ({ ...props }) => {
    return (
        <components.Placeholder {...props} className="flex items-center">
            <Icon type={Icons.SEARCH}/> {props.children}
        </components.Placeholder>
    );
};

const Input = (props) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {(props.selectProps.value || props.selectProps.inputValue) ? <Icon type={Icons.SEARCH}/> : null}
            <components.Input {...props} />
        </div>
    );
};

export const SelectSearch = ({ error, ...props }) => {
    const customStyle = {
        dropdownIndicator: () => ({
            display: 'none'
        }),
        singleValue: () => ({
            paddingLeft: 16
        })
    }

    return <CustomSelect {...props} error={error} style={customStyle}
                         components={{ ...components, Menu, NoOptionsMessage, Placeholder, Input, Option }}/>;
};