import ApiClient from './api-axios';

export default class LikeApi extends ApiClient {
    addArticleLike = async (id) => {
        return await this.post('/article/add', { articleId: id });
    };

    getArticleLikes = async (id) => {
        return await this.get(`/article/${id}`);
    };

    addNewsLike = async (id) => {
        return await this.post('/news/add', { newsId: id });
    };

    addFileCommentLike = async (id) => {
        return await this.post('/file-comment/add', { commentId: id });
    };

    getNewsLikes = async (id) => {
        return await this.get(`/news/${id}`);
    };

    removeLike = async (uuid) => {
        return await this.post('/delete', { uuid });
    };

    addArticleCommentLike = async (commentId) => {
        return await this.post('/article-comment/add', { commentId });
    };

    addNewsCommentLike = async (commentId) => {
        return await this.post('/news-comment/add', { commentId });
    };

    getArticleCommentLikes = async (commentId) => {
        return await this.get(`/article-comment/${commentId}`);
    };

    getNewsCommentLikes = async (commentId) => {
        return await this.get(`/news-comment/${commentId}`);
    };
}