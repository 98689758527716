import React, { useRef, useState } from 'react';
import cx from './ImagePreview.module.scss'
import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

export const ExpandImageModal = ({ src, onClose }) => {
    const imgRef = useRef(null)

    const [imgSize, setImgSize] = useState({ width: '75%', height: 'auto', size: 100 });
    const [imgSizeModifier, setImgSizeModifier] = useState(1);

    const onImageLoaded = () => {
        const content = document.getElementById('documentContent');
        const width = imgRef.current.naturalWidth;
        const height = imgRef.current.naturalHeight;
        const offsetWidth = content?.offsetWidth;
        const offsetHeight = content?.offsetHeight;

        if (width > height) {
            if (width < offsetWidth - (offsetWidth / 100) * 75) {
                setImgSize({
                    width: width,
                    height: 'auto',
                    size: 100,
                });
            } else {
                setImgSize({
                    width: (offsetWidth / 100) * 75,
                    height: 'auto',
                    size: 100,
                });
            }
        } else {
            if (height < (offsetHeight / 100) * 75) {
                setImgSize({
                    width: 'auto',
                    height: height,
                    size: 100,
                });
            } else {
                setImgSize({
                    width: 'auto',
                    height: (offsetHeight / 100) * 75,
                    size: 100,
                });
            }
        }

        // setIsImgLoaded(true);
    };

    const increaseImg = () => {
        if (imgSizeModifier >= 2) {
            return;
        }

        setImgSizeModifier((prevState) => prevState + 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size + 25 }));
    };
    const decreaseImg = () => {
        if (imgSizeModifier <= 0.5) {
            return;
        }

        setImgSizeModifier((prevState) => prevState - 0.25);
        setImgSize((prevState) => ({ ...prevState, size: prevState.size - 25 }));
    };

    return createPortal(
        <div style={{ position: 'fixed', inset: 0, display: 'flex', backgroundColor: 'rgba(38, 38, 38, 0.4)', overflow: 'auto', zIndex: 2 }}>
            <div
                style={{
                    position: 'fixed',
                    top: 40,
                    right: 16,
                    width: 30,
                    height: 30,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#fff',
                    padding: 6,
                    borderRadius: 4,
                    margin: '0 auto',
                    cursor: 'pointer'
                }}
                onClick={onClose}
            >
                <Icon type={Icons.EditorIconClose} width={18} height={18} />
            </div>
            <div id="documentContent" style={{ paddingTop: '135px', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                <div style={{maxWidth: '100%', maxHeight: '100%'}}>
                    <img
                        ref={imgRef}
                        src={src}
                        alt=""
                        onLoad={onImageLoaded}
                        style={{
                            width: isNaN(imgSize.width) ? imgSize.width : imgSize.width * imgSizeModifier + 'px',
                            height: isNaN(imgSize.height) ? imgSize.height : imgSize.height * imgSizeModifier + 'px',
                            backgroundColor: '#fff'
                        }} />
                </div>
            </div>
            <div className={cx.modalFooter}>
                <button
                    className={classNames(cx.noStyleButton, {
                        [cx.disabled]: imgSize?.size <= 50,
                    })}
                    onClick={decreaseImg}
                >
                    <Icon type={Icons.MINUS} width={20} height={20} color={imgSize?.size <= 50 ? 'gray' : 'white'} />
                </button>

                <div className={cx.sizeValue}>{imgSize?.size}%</div>

                <button
                    className={classNames(cx.noStyleButton, {
                        [cx.disabled]: imgSize?.size >= 200,
                    })}
                    onClick={increaseImg}
                >
                    <Icon type={Icons.PLUS_ADD} width={20} height={20} color={imgSize?.size >= 200 ? 'gray' : 'white'} />
                </button>
            </div>
        </div>,
        document.body
    );
};
