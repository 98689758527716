import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { iframeResizer as iframeResizerLib } from 'iframe-resizer';
import decodeHtmlEntities from 'lib/helpers/decodeHtmlEntities';
import { iFrameDomContentLoaded } from 'lib/util/iframeDomContentLoaded.util';
import { ImagePreviewModal, useImagePreviewModal } from 'components/ImagePreviewModal/ImagePreviewModal';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import { viewerFonts } from './viewer-fonts';
import { ckeditorStyles } from './ckeditor-styles';
import cx from './html-viewer.module.scss';

const HtmlViewer = ({ id, className, body, onInit = null, onAfterLoad, documentUuid }) => {
    const _id = id || 'htmlViewerFrame';
    const [isLoading, setIsLoading] = useState(false);

    const [termTags, setTermTags] = useState('');
    const [termDefinition, setTermDefinition] = useState('');

    const {
        selectedImageLoading,
        isOpen,
        setIsOpen,
        imgSize,
        imgSizeModifier,
        increaseImg,
        decreaseImg,
        openImageModal,
        imgRef
    } = useImagePreviewModal();

    const injectIframeResizerUrl = useCallback(() => {
        const frame = document.getElementById(_id);

        if (!frame) {
            return;
        }

        const doc = frame.contentDocument;

        if (!doc) {
            return;
        }

        let injectTarget = null;

        ['head', 'HEAD', 'body', 'BODY', 'div', 'DIV'].forEach(tagName => {
            if (injectTarget) {
                return;
            }

            const found = doc.getElementsByTagName(tagName);

            if (!(found && found.length)) {
                return;
            }

            injectTarget = found[0];
        });

        if (!injectTarget) {
            console.error('Unable to inject iframe resizer script');
            return;
        }

        const resizerScriptElement = document.createElement('script');

        resizerScriptElement.type = 'text/javascript';
        resizerScriptElement.src = '/iframeResizer.contentWindow.min.js';

        injectTarget.appendChild(resizerScriptElement);
    }, [_id]);

    const onTermModalClose = () => {
        setTermTags('');
        setTermDefinition('');
    };
    const initializeTerms = (terms) => {
        for (let i = 0; i < terms.length; i++) {
            terms[i].addEventListener('click', () => {
                setTermTags(terms[i].textContent);
                setTermDefinition(terms[i].getAttribute('data-definition'));
            });
        }
    };

    const initializeImageModal = useCallback((images) => {
        images.forEach(image => {
            image.addEventListener('click', e => openImageModal(e.target));
        });
    }, [openImageModal]);
    const getTables = (tables) => {
        const response = [];

        tables.forEach(table => {
            const row = table.querySelector('tbody')?.firstChild?.childNodes;

            if (!row) {
                return;
            }

            response.push({
                element: table,
                position: table.getBoundingClientRect(),
            });
        });

        return response;
    };

    const initializeTables = (tables) => {
        tables.forEach(table => {
            let totalWidth = 0;
            let tableLayoutFixed = true;

            const row = table.querySelector('tbody')?.firstChild?.childNodes;

            if (!row) {
                return;
            }

            for (const element of row) {
                if (!element.style.width) {
                    tableLayoutFixed = false;
                }

                totalWidth += +element.style.width.replace('px', '') || 0;
            }

            table.style.overflowX = 'auto';

            if (totalWidth && totalWidth >= 200) {
                table.style.width = table.width.includes('%') || table.width.includes('px') ? table.width : totalWidth + 'px';
                table.style.tableLayout = tableLayoutFixed ? 'fixed' : null;
            }
        });
    };
    const initializeTableActionWrappers = (tableActionWrappers) => {
        tableActionWrappers.forEach(i => {
            const rows = i.getElementsByTagName('tr');
            const firstColumnTHs = [];

            Array.from(rows).forEach(row => {
                if (!row.firstChild) {
                    return;
                }

                if (row.firstChild.tagName !== 'TH') {
                    return;
                }

                firstColumnTHs.push(row.firstChild);
            });

            if (firstColumnTHs.length <= 1) {
                return;
            }

            let offsetWidth = 0;

            for (const th of firstColumnTHs) {
                offsetWidth = th.offsetWidth < offsetWidth ? offsetWidth : th.offsetWidth;

                th.style.width = offsetWidth + 'px';
                th.style.position = 'sticky';

                th.style.left = 0 + 'px';
                th.style.zIndex = 2;
            }
        });
    };

    const onBeforePrintDetails = (allDetails) => {
        for (let i = 0; i < allDetails.length; i++) {
            if (allDetails[i].open) {
                allDetails[i].dataset.open = '1';
            } else {
                allDetails[i].setAttribute('open', '');
            }
        }
    };
    const onAfterPrintDetails = (allDetails) => {
        for (let i = 0; i < allDetails.length; i++) {
            if (allDetails[i].dataset.open) {
                allDetails[i].dataset.open = '';
            } else {
                allDetails[i].removeAttribute('open');
            }
        }
    };

    const initializeFullScreenRollback = (element, iframe) => {
        if (!element) {
            return;
        }

        element.addEventListener('scroll', () => {
            const scrollTop = element.scrollTop;

            if (scrollTop !== 0) {
                document.onfullscreenchange = () => {
                    if (!iframe.contentDocument.fullscreenElement) {
                        element.scrollTop = scrollTop;
                    }
                };
            }
        });
    };
    const initializeLinksScrolling = (links, iframe) => {
        links.forEach(link => {
            if (link?.href.includes('#') && link?.href.includes(window.location.pathname)) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();

                    const articlePostingCover = document.getElementById('articlePostingCover')?.offsetHeight || 0;
                    const articlePostingDescription = document.getElementById('articlePostingDescription')?.offsetHeight || 0;
                    const postingSearchContainer = document.getElementById('postingSearchContainer')?.offsetHeight || 0;

                    const files = document.getElementById('files');
                    const filesHeight = files?.offsetHeight ?? 0;
                    const anchorId = this.href.slice(this.href.lastIndexOf('#') + 1);

                    let anchorEl =
                        iframe.contentWindow.document.getElementById(anchorId) ||
                        iframe.contentWindow.document.getElementsByName(anchorId)[0] ||
                        null;

                    if (anchorEl) {
                        const scrollWrapper = iframe.closest('.js-scroll-wrapper');
                        anchorEl = anchorEl.closest('tr') || anchorEl.closest('thead') || anchorEl;

                        const elOffsetTop = anchorEl.getBoundingClientRect().top;
                        const postingSearchContainerHeight = document.getElementById('postingSearchContainer')?.offsetHeight || 0;

                        let scrollTop =
                            elOffsetTop +
                            postingSearchContainerHeight +
                            articlePostingCover +
                            articlePostingDescription +
                            filesHeight +
                            postingSearchContainer -
                            16;

                        if (scrollWrapper) {
                            scrollWrapper.scrollTo({
                                top: scrollTop,
                                behavior: 'smooth',
                            });
                        }
                    }
                });
            } else if (!link.target) {
                link.target = '_top';
            }
        });
    };

    const onDOMContentLoaded = useCallback(() => {
        if (!isLoading) {
            // return;
        }

        const iframe = document.getElementById(_id);

        if (iframe.contentWindow == null) {
            return;
        }

        iframe.style.minHeight = 'auto';
        iframe.style.height = `${iframe.contentWindow.document.body.scrollHeight}px`;

        injectIframeResizerUrl();
        iframeResizerLib({
            checkOrigin: false,
            autoResize: true,
            heightCalculationMethod: 'bodyScroll',
            waitForLoad: true,
        }, iframe);

        onInit();

        iframe.contentWindow.document.documentElement.style.overflowX = 'auto';
        iframe.contentWindow.document.body.style.overflowX = 'auto';

        initializeLinksScrolling(iframe.contentWindow.document.querySelectorAll('a'), iframe);
        setTimeout(() => setIsLoading(false), 200);

        // Call resize event for correct iframe height;
        setTimeout(() => window.dispatchEvent(new Event('resize')), 2000);
    }, [injectIframeResizerUrl, onInit, isLoading, _id]);

    useEffect(() => {
        if (!isLoading) {
            const frame = document.getElementById(_id);

            initializeTerms(frame.contentWindow.document.querySelectorAll('.tiarm'));
            initializeImageModal(frame.contentWindow.document.querySelectorAll('img'));

            const tableElements = frame.contentWindow.document.querySelectorAll('table');

            initializeTables(tableElements);
            initializeTableActionWrappers(frame.contentWindow.document.querySelectorAll('.tableActionsWrapper'));

            const tables = getTables(tableElements);

            if (tables.length) {
                const scrollWrapper = frame.closest('.js-scroll-wrapper');
                const previewMenu = document.getElementById('articlePreviewModal') || document.getElementById('newsPreviewModal');
                const articlePostingDescription = document.getElementById('articlePostingDescription');

                const files = document.getElementById('files');
                const filesHeight = files?.offsetHeight ?? 0;

                if (scrollWrapper) {
                    scrollWrapper.addEventListener('scroll', e => {
                        let scrollTop;

                        if (previewMenu) {
                            scrollTop = e.target.scrollTop;
                        } else {
                            scrollTop =
                                e.target.scrollTop -
                                filesHeight -
                                133 -
                                (articlePostingDescription ? articlePostingDescription.offsetHeight : 0);
                        }

                        tables.forEach(table => {
                            const thead = table.element.querySelector('thead') || table.element.querySelector('th')?.closest('tr');

                            if (thead) {
                                if (table.position.top <= scrollTop && table.position.bottom - thead.offsetHeight > scrollTop) {
                                    thead.style.top = `${scrollTop - table.position.top}px`;
                                } else if (table.position.top >= scrollTop && table.position.bottom > scrollTop) {
                                    thead.style.top = 0;
                                }
                            }
                        });
                    });
                }
            }

            // Open closed details elements for printing;
            frame.contentWindow.addEventListener('beforeprint', () =>
                onBeforePrintDetails(frame.contentWindow.document.body.querySelectorAll('details')));

            // After printing close details elements not opened before;
            frame.contentWindow.addEventListener('afterprint', () =>
                onAfterPrintDetails(frame.contentWindow.document.body.querySelectorAll('details')));
        }
    }, [isLoading, _id, initializeImageModal]);
    useEffect(() => {
        setIsLoading(true);

        const container = document.getElementById('htmlViewerContainer');
        container.removeChild(document.getElementById(_id));

        const iframe = document.createElement('iframe');

        iframe.id = id;
        iframe.class = className;

        const styles = `
            <link rel='stylesheet' href='/github.css'>
        `;
        const scripts = `
            <script src='/heading.js'></script>
            <script src='/highlight.min.js'></script>
            <script>hljs.highlightAll();</script>
        `;

        iframe.setAttribute('srcDoc', viewerFonts + ckeditorStyles + styles + scripts + decodeHtmlEntities(body));
        iframe.setAttribute('title', 'htmlViewer');

        iframe.style.width = '100%';
        iframe.style.minHeight = '800px';

        iframe.setAttribute('scrolling', 'yes');
        container.appendChild(iframe);

        iFrameDomContentLoaded(iframe, onDOMContentLoaded);
        iframe.contentWindow.addEventListener('load', onAfterLoad);

        const element = document.querySelector(
            '#articlePreviewText, #newsPreviewBodyIframe, #articleContainer, #newsContainer'
        );
        initializeFullScreenRollback(element, iframe);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentUuid, body, _id, className, id]);

    return (
        <>
            <div id="htmlViewerContainer" className="wrapper">
                <iframe id={id} className={className} title="htmlViewer" />
                <ImagePreviewModal
                    selectedImageLoading={selectedImageLoading}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    imgSize={imgSize}
                    imgSizeModifier={imgSizeModifier}
                    increaseImg={increaseImg}
                    decreaseImg={decreaseImg}
                    imgRef={imgRef}
                />
                <Modal
                    className={cx.termModal}
                    isOpen={!!(termTags && termDefinition)}
                    overlayClassName={cx.termModalOverlay}
                    onRequestClose={onTermModalClose}
                >
                    <div className={cx.termModalHeader}>
                        <h2>{termTags}</h2>
                        <IconButton
                            icon={<Icon type={Icons.CROSS} width={16} height={16} />}
                            onClick={onTermModalClose}
                        />
                    </div>
                    <div className={cx.termModalBody}>{termDefinition}</div>
                </Modal>
            </div>
        </>
    );
};

HtmlViewer.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    body: PropTypes.string,
    documentUuid: PropTypes.string,
    onInit: PropTypes.func,
    onAfterLoad: PropTypes.func,
};

HtmlViewer.defaultProps = {
    id: null,
    className: null,
    body: '',
    documentUuid: '',
    onInit: () => {},
    onAfterLoad: () => {},
};

export default HtmlViewer;
