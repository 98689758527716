import ApiClient from './api-axios';

export default class ErrorReportResource extends ApiClient {

    errorReport = async (resourceType, resourceId, body, title = 'error-report-' + resourceType) => {

        await this.post('/error-report', {
            resourceType,
            resourceId,
            body,
            title
        });
    }

    addErrorReportManagers = async (users, resourceId, resourceType) => {

        await this.post('/error-report/managers/add ', {
            users,
            resourceId,
            resourceType
        });
    }

    deleteErrorReportManagers = async (users, resourceId, resourceType) => {

        await this.post('/error-report/managers/delete ', {
            users,
            resourceId,
            resourceType
        });
    }

    findErrorReportManagers = async (uuid) => {

        return await this.get(`/error-report/managers/${uuid}`);

    }

}
