import { mergeAttributes } from '@tiptap/core';
import Highlight from '@tiptap/extension-highlight';

export const CustomHighlight = Highlight.extend({
    parseHTML() {
        return [
            {
                tag: 'mark',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },
});
