import React from 'react';
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';

import Icon from 'uikit/icon/icon';
import { Icons } from 'uikit/icon';

import cx from '../../editor.module.scss';

export const AnchorView = ({ node, getPos, editor }) => {
    const onClick = () => {
        if (window.innerWidth <= 920) {
            editor.view.dom.blur();
        }
    };
    const onClickAnchor = e => {
        e.stopPropagation();
        editor.commands.selectAnchor(getPos());

        if (window.innerWidth <= 920) {
            editor.view.dom.blur();
        }
    };

    return (
        <NodeViewWrapper as="span" style={{ position: 'relative' }}>
            <a
                id={node.attrs.id}
                href={node.attrs.href}
                name={node.attrs.name}
                data-type={node.attrs['data-type']}
                className={cx.anchorWrapper}
                onClick={onClick}
            >
                <span onClick={onClickAnchor} contentEditable={false}>
                    <Icon
                        className={cx.icon}
                        type={Icons.EditorIconAnchor}
                        data-tooltip={node.attrs['data-tooltip']}
                        style={{ cursor: 'pointer' }}
                        width={18}
                        height={18}
                        contentEditable={false}
                    />
                </span>
                <NodeViewContent as="span" />
            </a>
        </NodeViewWrapper>
    );
};
