import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UrlUtils from 'lib/util/url.util';
import { useCancelBehavior, useGlobalContext, useMessage, usePermittedActions } from 'lib/hooks';
import { perm, RESOURCE_TYPE } from 'model/resource';
import hot from 'containers/hot-container';
import PrivatePage from 'containers/private-page-wrapper';
import { sectionFormPage } from 'components/section-form/section-form-page.hoc';
import Loading from 'uikit/loading';
import Button from 'uikit/button';
import api from 'api';

const AddSectionPage = ({ section, loading, setLoading, navigate, hasAnyAuthorities }) => {
    const { platform, setFormDataChanged } = useGlobalContext();

    const { addError, addSuccess } = useMessage();
    const { cancel } = useCancelBehavior();

    const { checkPermission, ready } = usePermittedActions(RESOURCE_TYPE.SECTION, section.id);
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        if (ready && !checkPermission(perm.document.SECTION_CREATE)) {
            navigate('/401', { replace: true });
        }
    }, [ready, checkPermission, navigate]);

    const addSection = async () => {
        setIsSubmit(true);

        const titleExists = await section.validateTitleExists();

        if (!section.validateFields()) {
            return;
        }

        if (titleExists) {
            return;
        }

        setLoading(true);

        try {
            setFormDataChanged(false);
            const formData = section.getFormData();
            const newSection = await api.section.postSection(formData);

            addSuccess('Раздел создан');
            navigate(UrlUtils.getSectionUrl(formData.parentProjectId, newSection.uuid, 'articles'));
        } catch (error) {
            addError('Не удалось создать раздел');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isSubmit && Object.values(section.errors).some(v => v)) {
            section.scrollToError();
            setIsSubmit(false);
        }

    }, [isSubmit, section, section.errors]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            {loading && <Loading withOverlay={false} withRelativeOverlay={true}/>}
            <Button label={'Отмена'} onClick={cancel} fullWidth={platform === 'mobile'}/>
            <Button onClick={addSection} label='Сохранить' disabled={loading} color={'green'}
                    fullWidth={platform === 'mobile'}/>
        </PrivatePage>
    );
};

AddSectionPage.defaultProps = {
    hasAnyAuthorities: []
};

AddSectionPage.propTypes = {
    hasAnyAuthorities: PropTypes.arrayOf(PropTypes.string)
};

export default hot(sectionFormPage(AddSectionPage, 'Добавление раздела'));
