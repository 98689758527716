import React from 'react';
import classNames from 'classnames';
import Modal from 'react-modal';
import Button from 'uikit/button';
import Checkbox from 'uikit/checkbox/checkbox';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import cs from './subscribe-updates.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { desktopStyles, mobileStyles } from 'lib/util/modalStyles';

// TODO refactoring along with Confirmation and SendEmail
const SubscribeUpdates = ({ isOpen, onDismiss, onOk, color, okButtonText, dismissButtonText }) => {
    const { platform } = useGlobalContext();

    return (
        <Modal isOpen={isOpen} onRequestClose={onDismiss} contentLabel={'Обновления документа'} style={platform === 'mobile' ? mobileStyles : desktopStyles}>
            <div className={classNames(cs.modal, {[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.head}>
                    <div className={cs.text}>Обновления документа</div>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16}/>}
                                onClick={onDismiss}/>
                </div>
                <div className={cs.content}>
                    <div className={cs.infoTextBold}>Оповещать о действиях в документе:</div>
                    <div className={cs.checkboxContainer}><Checkbox label={'Изменение'}/></div>
                    <div className={cs.checkboxContainer}><Checkbox label={'Архивирование'}/></div>
                    <div className={cs.checkboxContainer}><Checkbox label={'Комментарий'}/></div>
                    <div className={cs.checkboxContainer}><Checkbox label={'Отметка "Нравится"'}/></div>
                    <div className={cs.checkboxContainer}><Checkbox label={'Удаление'}/></div>
                </div>
                <div className={cs.footer}>
                    <Button onClick={onDismiss} label={dismissButtonText} fullWidth={platform === 'mobile'}/>
                    <Button onClick={onOk} label={okButtonText} color={color} fullWidth={platform === 'mobile'}/>
                </div>
            </div>
        </Modal>
    );
};

SubscribeUpdates.defaultProps = {
    color: 'green',
    isOpen: false,
    onDismiss: () => {

    },
    onOk: () => {

    },
    okButtonText: 'Подтвердить',
    dismissButtonText: 'Отмена'
};

export default SubscribeUpdates;