import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Schema, DOMParser as ProseMirrorDOMParser, DOMSerializer } from '@tiptap/pm/model';
import { copyTableWhenAllCellsSelected } from '../Table/extension-table/utilities/copyTableWhenAllCellsSelected';
import pasteRtfImages from '../../extensions/CopyPaste/PasteRtfImages';
import pasteFromWord from '../../extensions/CopyPaste/PasteFromWord';

const TEXT_FORMAT = 'text/plain';
const HTML_FORMAT = 'text/html';

const replacements = [
    {
        tag: 'h1',
        replacement: 'p',
    },
    {
        tag: 'h2',
        replacement: 'p',
    },
    {
        tag: 'h3',
        replacement: 'p',
    },
    {
        tag: 'h4',
        replacement: 'p',
    },
    {
        tag: 'h5',
        replacement: 'p',
    },
    {
        tag: 'h6',
        replacement: 'p',
    },
    {
        tag: 'a',
        replacement: 'span',
    },
    {
        tag: 'i',
        replacement: 'span',
    },
    {
        tag: 'b',
        replacement: 'span',
    },
    {
        tag: 'u',
        replacement: 'span',
    },
    {
        tag: 'strong',
        replacement: 'span',
    },
    {
        tag: 'font',
        replacement: 'span',
    },
    {
        tag: 'ul',
        replacement: 'p',
    },
    {
        tag: 'ol',
        replacement: 'p',
    },
    {
        tag: 'dl',
        replacement: 'p',
    },
    {
        tag: 'li',
        replacement: 'p',
    },
    {
        tag: 'dd',
        replacement: 'p',
    },
    {
        tag: 'dt',
        replacement: 'p',
    },
    {
        tag: 'th',
        replacement: 'td',
    },
];

const applyInline = (element, recursive = true, sheets) => {
    let node = element;

    if (!node) {
        throw new Error('No element specified.');
    }

    if (sheets) {
        const matches = matchRules(node, sheets);
        const srcRules = document.createElement(node.tagName).style;

        srcRules.cssText = node.style?.cssText;
        matches.forEach(rule => {
            for (const prop of rule.style) {
                let val = srcRules.getPropertyValue(prop) || rule.style.getPropertyValue(prop);
                let priority = rule.style.getPropertyPriority(prop);

                node.style.setProperty(prop, val, priority);
            }
        });
    }

    if (recursive) {
        node.children.forEach(child => {
            applyInline(child, recursive, sheets);
        });
    }
};
function matchRules(el, sheets) {
    sheets = sheets || document.styleSheets;

    const response = [];

    for (let i in sheets) {
        if (sheets.hasOwnProperty(i)) {
            const rules = sheets[i].rules || sheets[i].cssRules;

            for (let r in rules) {
                try {
                    if (rules[r].selectorText && el?.matches(rules[r].selectorText)) {
                        response.push(rules[r]);
                    }
                } catch {
                    // This catch need if styles have not valid selector;
                }
            }
        }
    }

    return response;
}

const mergeDecorators = body => {
    const parentElement = [];

    body.querySelectorAll('span, strong').forEach(element => {
        if (element.parentElement.tagName === 'SPAN' || element.parentElement.tagName === 'STRONG') {
            return;
        }

        parentElement.push(element);
    });

    parentElement.forEach(element => {
        while (true) {
            if (
                element.children.length > 1 ||
                !element.children[0] ||
                element.textContent ||
                (element.children[0].tagName !== 'SPAN' && element.children[0].tagName !== 'STRONG')
            ) {
                break;
            }

            element.style = element.style.cssText + ';' + element.children[0].style.cssText;

            if (element.children[0].tagName === 'STRONG') {
                element.style.fontWeight = 600;
            }

            element.innerHTML = element.children[0].innerHTML;
        }
    });
};
const pixelsToPoints = body => {
    body.querySelectorAll('*').forEach(node => {
        if (node.style.fontSize && node.style.fontSize.indexOf('px') !== -1) {
            node.style.fontSize = +parseFloat(node.style.fontSize) * 0.75 + 'pt';
        }
    });
};

export const parseHTML = (schema, html) => {
    const parser = new DOMParser();

    const startTag = '<body>';
    const endTag = '</body>';

    const { body } = parser.parseFromString(startTag + html + endTag, 'text/html');

    const styles = body.getElementsByTagName('style');
    const sheets = [];

    if (styles) {
        styles.forEach(style => {
            document.head.appendChild(style);
            sheets.push(style.sheet);
        });
    }

    mergeDecorators(body);
    pixelsToPoints(body);

    applyInline(body, true, sheets);
    return { document: ProseMirrorDOMParser.fromSchema(schema).parse(body) };
};

const getContentGeneratorName = content => {
    const metaGeneratorTag = /<meta\s+name=["']?generator["']?\s+content=["']?(\w+)/gi;
    const result = metaGeneratorTag.exec(content);

    let generatorName;

    if (!result || !result.length) {
        return;
    }

    generatorName = result[1].toLowerCase();

    if (generatorName.indexOf('microsoft') === 0) {
        return 'microsoft';
    }

    if (generatorName.indexOf('libreoffice') === 0) {
        return 'libreoffice';
    }

    // eslint-disable-next-line no-useless-escape
    const wordRegexp = /(class="?Mso|style=["'][^"]*?\bmso\-|w:WordDocument|<o:\w+>|<\/font>)/;

    if (wordRegexp.test(content)) {
        return 'microsoft';
    }

    return 'unknown';
};
const parseElements = element => {
    const allowTags = [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'a',
        'p',
        'span',
        'strong',
        'b',
        'i',
        'u',
        'ul',
        'ol',
        'li',
        'dl',
        'table',
        'br',
        'blockquote',
        'hr',
        'img',
        'iframe',
        'video',
        'audio',
    ];
    const allowClasses = ['callout', 'details', 'columnBlock'];

    let response = [];

    element.childNodes.forEach(childNode => {
        if (childNode.className && allowClasses.filter(p => childNode.className.indexOf(p) !== -1).length !== 0) {
            response.push(childNode);
            return;
        }

        if (childNode.tagName && allowTags.includes(childNode.tagName.toLowerCase())) {
            // childNode.style.width = 'auto';

            if (childNode.style.position === 'absolute') {
                childNode.style.position = 'relative';
            }

            if (childNode.tagName === 'DIV') {
                childNode.style.border = 'none';

                if (childNode.childNodes.length === 0) {
                    childNode.style.display = 'none';
                }
            }

            // if (childNode.tagName === 'TABLE') {
            //     childNode.removeAttribute('width');
            // }

            // if (childNode.tagName === 'TD' || childNode.tagName === 'TH') {
            //     childNode.removeAttribute('colwidth');
            // }

            response.push(childNode);
            return;
        }

        if (!childNode.tagName && childNode.textContent !== 'StartFragment' && childNode.textContent !== 'EndFragment') {
            const textNode = document.createElement('span');
            textNode.textContent = childNode.textContent;

            response.push(textNode);
            return;
        }

        if (childNode.childNodes.length !== 0) {
            response = response.concat(parseElements(childNode));
        }
    });

    return response;
};

export const CopyPaste = Extension.create({
    name: 'copyPaste',
    addOptions() {
        return {
            initId: null,
            onUpload: null,
        };
    },
    addStorage() {
        return {
            initId: null,
        };
    },
    addCommands() {
        return {
            execPasteTest: (data, pasteRaw) => () => {
                const items = Array.from(data.items || []);

                for (const item of items) {
                    if (['image/gif', 'image/jpeg', 'image/png'].includes(item.type)) {
                        const position = this.editor.state.selection.$head;

                        this.options.onUpload(item.data, this.editor.storage.copyPaste.initId).then(attrs => {
                            this.editor.chain().focus().setResizableImage(attrs, position, { updateSelection: false }).run();
                        });

                        return true;
                    }
                }

                const textContent = data.text;
                const htmlContent = data.html;
                const rtfContent = data.rtf;

                const contentGeneratorName = getContentGeneratorName(htmlContent);
                let content = htmlContent.replace(/<br \/>/gm, '<p></p>');

                if (contentGeneratorName === 'microsoft') {
                    content = pasteFromWord(content);
                }

                content = pasteRtfImages(content, rtfContent);
                const hasHTML = data.types.some(type => type === HTML_FORMAT);

                if (hasHTML) {
                    const parser = new DOMParser();

                    const htmlDom = parser.parseFromString(content, 'text/html');
                    const elements = parseElements(htmlDom.body);

                    htmlDom.body.innerHTML = '';
                    elements.forEach(element => htmlDom.body.append(element));

                    if (pasteRaw) {
                        htmlDom.querySelectorAll('*').forEach(element => {
                            element.removeAttribute('style');
                            element.removeAttribute('class');

                            element.removeAttribute('color');
                            element.removeAttribute('bgcolor');

                            element.removeAttribute('lang');
                            element.removeAttribute('href');
                        });

                        replacements.forEach(p => {
                            htmlDom.querySelectorAll(p.tag).forEach(element => {
                                const replacement = document.createElement(p.replacement);

                                for (let i = 0, l = element.attributes.length; i < l; ++i) {
                                    const nodeName = element.attributes.item(i).nodeName;
                                    const nodeValue = element.attributes.item(i).nodeValue;

                                    replacement.setAttribute(nodeName, nodeValue);
                                }

                                replacement.innerHTML = element.innerHTML;
                                element.parentNode.replaceChild(replacement, element);
                            });
                        });
                    }

                    if (!pasteRaw) {
                        // Save styles from font to child elements. Because prose mirror remove font elements;
                        htmlDom.querySelectorAll('font').forEach(font => {
                            let child = font;

                            while (child.tagName === 'FONT') {
                                if (child.firstElementChild === null) {
                                    if (child.textContent) {
                                        const item = document.createElement('span');
                                        item.textContent = child.textContent;

                                        child.textContent = '';
                                        child.appendChild(item);

                                        child = item;
                                        continue;
                                    }

                                    return;
                                }

                                child = child.firstElementChild;
                            }

                            if (font.hasAttribute('color')) {
                                child.style.color = font.getAttribute('color');
                            }

                            if (font.hasAttribute('face')) {
                                child.style.fontFamily = font.getAttribute('face');
                            }

                            child.style.cssText += font.style.cssText;
                        });

                        // Remove go back element which exist in some word documents;
                        htmlDom.querySelectorAll('[name="_GoBack"]').forEach(element => {
                            element.remove();
                        });
                    }

                    // Updated table border to default;
                    htmlDom.querySelectorAll('th, td').forEach(td => {
                        td.style.borderColor = '#D8D8D8';
                    });

                    // Update ima sizes;
                    htmlDom.querySelectorAll('img').forEach(img => {
                        if (img.parentElement.nodeName === 'FONT') {
                            img.parentElement.replaceWith(img);
                        }
                        if (img.parentElement.nodeName === 'P') {
                            img.parentElement.replaceWith(img);
                        }
                        if (!img.getAttribute('width')) {
                            img.setAttribute('width', 'auto');
                        }

                        if (!img.getAttribute('height')) {
                            img.setAttribute('height', 'auto');
                        }
                    });

                    htmlDom.querySelectorAll('*').forEach(p => {
                        if (!p.hasAttribute('align')) {
                            return;
                        }

                        p.style.textAlign = p.getAttribute('align');
                    });

                    content = htmlDom.documentElement.innerHTML;
                }
                return this.editor.commands.pasteContent(hasHTML ? content.replace('/<br>/gm', '') : textContent, !hasHTML);
            },
            pasteContent:
                (content = '') =>
                () => {
                    const { editor } = this;

                    const schema = new Schema({ ...this.editor.schema.spec });
                    const promise = Promise.resolve(parseHTML(schema, content, this.options.initId));

                    promise.then(async ({ document }) => {
                        if (!document) {
                            return;
                        }

                        const { firstChild, childCount } = document.content;
                        const toPaste = childCount === 1 && firstChild.type.name === 'paragraph' ? firstChild.content : document.content;
                        editor.chain().focus().deleteSelection().insertContent(toPaste.toJSON(), { updateSelection: false }).run();
                    });

                    return true;
                },
        };
    },
    addProseMirrorPlugins() {
        const handleCutAndCopy = async (view, event) => {
            copyTableWhenAllCellsSelected({ editor: this.editor });

            const { from, to } = this.editor.state.selection;
            const slice = view.state.doc.slice(from, to);

            const documentFragment = DOMSerializer.fromSchema(view.state.schema).serializeFragment(slice.content);
            const div = document.createElement('div');

            div.appendChild(documentFragment);
            div.innerHTML = div.innerHTML.replace(/<p><\/p>/gm, '<br />');
            if (div.innerText === div.innerHTML) {
                div.innerHTML = `<p>${div.innerHTML}</p>`;
            }

            const images = div.querySelectorAll('img');

            if (images) {
                const set = new Set(Array.from(images).map(img => img.src));

                for (let value of set) {
                    if (!value.includes('localhost')) {
                        continue;
                    }

                    const res = await fetch(value);
                    const img = await res.blob();

                    let result_base64 = await new Promise(resolve => {
                        let fileReader = new FileReader();
                        fileReader.onload = _e => resolve(fileReader.result.replace('data:', '').replace(/^.+,/, ''));
                        fileReader.readAsDataURL(img);
                    });

                    images.forEach(img => {
                        if (img.src === value) {
                            img.src = 'data:image/jpeg;base64,' + result_base64;
                        }
                    });
                }
            }

            if (images) {
                for (let i = 0; i < images.length; i++) {
                    if (!images[i].src.includes('localhost')) {
                        continue;
                    }

                    await new Promise(resolve => {
                        const img = new Image();

                        img.onload = function () {
                            const canvas = document.createElement('canvas');

                            canvas.width = images[i].naturalWidth || images[i].width;
                            canvas.height = images[i].naturalHeight || images[i].height;

                            const ctx = canvas.getContext('2d');
                            ctx.drawImage(images[i], 0, 0, images[i].naturalWidth, images[i].naturalHeight);

                            images[i].src = canvas.toDataURL('image/png');
                            resolve();
                        };

                        img.src = images[i].src;
                    });
                }
            }

            event.clipboardData.setData(TEXT_FORMAT, div.innerText);
            event.clipboardData.setData(HTML_FORMAT, div.innerHTML);

            const blobHtml = new Blob([div.innerHTML], { type: HTML_FORMAT });
            const blobText = new Blob([div.innerText], { type: TEXT_FORMAT });

            const { ClipboardItem } = window;

            try {
                await navigator.clipboard.write([new ClipboardItem({ [HTML_FORMAT]: blobHtml, [TEXT_FORMAT]: blobText })]);
            } catch (ex) {
                console.log(ex);
            }

            event.preventDefault();
            event.stopPropagation();

            return true;
        };

        return [
            new Plugin({
                key: new PluginKey('copyPaste'),
                props: {
                    handleDOMEvents: {
                        copy: handleCutAndCopy,
                        cut: (view, event) => {
                            handleCutAndCopy(view, event);
                            this.editor.commands.deleteSelection();

                            return true;
                        },
                    },
                },
            }),
        ];
    },
});
