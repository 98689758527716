import React, { useState } from 'react';
import { TagGroupModal } from 'app/global-settings/tags-page/TagGroupModal';
import { TagCategory } from 'app/global-settings/tags-page/TagCategory';
import Loading from 'uikit/loading';
import Button from 'uikit/button';
import cs from './TagsPage.module.scss';
import Card from 'components/card';
import { useTags } from 'lib/hooks/useTags';

export const TagsPage = () => {

    const { tags, categories, loading, invalid, invalidate } = useTags();
    const [open, setOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [onlyAdd, setOnlyAdd] = useState(false);

    const handleCategoryEdit = (id, name, tags, onlyAdd = false) => {
        setOpen(true);
        setCurrentCategory({ id, name, tags });
        setOnlyAdd(onlyAdd);
    }

    const handleModalClose = () => {
        setOpen(false);
        setCurrentCategory(null);
        setOnlyAdd(false);
    }

    return (
        <div className={cs.tagsPage}>
            <div className={cs.title}>Теги</div>
            <Card className={cs.mainCard}>
            <Button className={cs.addButton} color="green" onClick={() => setOpen(true)}>Добавить категорию тегов</Button>

            {loading ? (
                <Loading/>
            ) : (
                <div className={cs.categories}>
                    {
                        categories.map((category) => {
                            return (
                                <TagCategory key={category.id} tags={tags} onEdit={handleCategoryEdit} onAfterDelete={invalidate} {...category} />
                            );
                        })
                    }
                    { categories.length === 0 && !invalid && !loading && (
                        <h3>Список категорий пуст.</h3>
                    )}
                </div>
            )}
            </Card>
            <TagGroupModal
                isOpen={open}
                onClose={handleModalClose}
                onAfterSubmit={invalidate}
                category={currentCategory}
                onlyAdd={onlyAdd}
            />
        </div>
    );
}
