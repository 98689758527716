import { useContext, useEffect, useState } from 'react';
import Mark from 'mark.js';
import { useDispatch, useSelector } from 'react-redux';
import { useMessage } from 'lib/hooks/index';
import { collectArticleParentsIds, collectArticleBreadCrumbs } from 'lib/helpers';
import { clearArticle, selectOpenArticle } from 'slice/globalSearchSlice';
import { RESOURCE_TYPE } from 'model/resource';
import { TreePropContext } from 'containers/side-tree-wrapper';
import api from 'api/index';

export const useArticle = (id, type = 'article') => {
    const dispatch = useDispatch();
    const store = useSelector(selectOpenArticle);

    const { addSuccess, addError } = useMessage();
    const { tree } = useContext(TreePropContext);

    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const [projectId, setProjectId] = useState(null);
    const [state, setState] = useState(null);

    const [articleData, setArticleData] = useState(null);
    const [article, setArticle] = useState(null);

    const [parents, setParents] = useState(null);
    const [breadCrumbs, setBreadCrumbs] = useState(null);

    const [viewersCount, setViewersCount] = useState(0);

    const onDelete = async () => {
        setModalLoading(true);
        const node = tree.getNodeById(article.id);

        try {
            tree.selectNode(node.parent);
        } catch (e) {
            console.log('Не удалось выделить ноду');
        }

        try {
            if (type === 'article') {
                await api.article.deleteArticle(article.id);
            } else {
                await api.article.deleteArticleDraft(article.id);
            }

            if (node) {
                tree.removeNode(node);
            }

            addSuccess('Статья удалена');
        } catch (e) {
            addError('Не удалось удалить статью');
        }

        setModalLoading(false);
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            let _article;

            if (type === 'article') {
                if (store.uuid) {
                    _article = await api.article.getArticle(id, store.query);
                    dispatch(clearArticle());
                } else {
                    _article = await api.article.getArticle(id);
                }
            } else if (type === 'article-draft') {
                const res = await api.article.getArticleDraft(id);
                _article = { article: res.draft, ...res };
            }

            setArticleData(_article);
            setArticle({ ..._article.article });

            setLoading(false);
        })();
    }, [dispatch, id, type, store.query, store.uuid]);
    useEffect(() => {
        (async () => {
            try {
                if (!articleData || articleData.article.id !== id) {
                    return;
                }

                if (id) {
                    articleData.article.permittedActions = await api.resource.getPermittedActions(RESOURCE_TYPE.ARTICLE, id);
                }

                articleData.article.read = articleData.read;
                articleData.article.wasRead = articleData.wasRead;
                articleData.article.version = articleData.version;

                if (articleData.read) {
                    const _articleTime = type === 'article' ? await api.article.getReadArticleTime(id) : 1000;
                    articleData.article.time = _articleTime / 1000;
                }

                setParents(collectArticleParentsIds(id, articleData.parents));
                setBreadCrumbs(collectArticleBreadCrumbs(id, articleData.article.title, articleData.parents));

                // Highlight article title, description and content;
                const params = new URLSearchParams(document.location.search);

                if (params.get('highlights') && params.get('highlights') !== 'null') {
                    const highlights = JSON.parse(params.get('highlights'));
                    const content = document.createElement('div');

                    content.innerHTML = articleData['article']['text'];
                    let instance = new Mark(content);

                    for (let i = 0; i < highlights.length; i++) {
                        instance.mark(highlights[i]);
                    }

                    articleData['article']['text'] = content.innerHTML;
                }

                setArticle({
                    permittedActions: [],
                    ...articleData.article,
                    favorite: articleData.favorite,
                    likes: {
                        isDisliked: articleData.isDisliked,
                        isLiked: articleData.isLiked,
                        lastLikeList: articleData.lastLikeList,
                        likeCount: articleData.likeCount
                    }
                });

                if (articleData) {
                    setViewersCount(await api.view.getViewersCount(id));
                }

                setProjectId(articleData?.parents?.projectId);
                setState(articleData.article.state)
            } catch (e) {
                if (e.errorData.message === 'error.resource.deleted') {
                    setState('DELETED')
                }

                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, [id, type, articleData]);

    return {
        loading,
        article,
        setArticle,
        parents,
        breadCrumbs,
        onDelete,
        modalLoading,
        projectId,
        viewersCount,
        state
    };
};
