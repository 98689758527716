import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';

export const MenuButtonLineHeightItem = ({ label, mode, setCurrentMenu, hideMenu }) => {
    const editor = useEditorContext();

    const setLineHeight = (e, height) => {
        if (mode !== 'MOBILE') {
            hideMenu?.();
        } else {
            e.stopPropagation();
            setCurrentMenu(undefined);
        }

        editor?.chain().focus().setLineHeight(height).run();
    };

    return (
        <MenuButton
            icon={() => null}
            label={label}
            onClick={e => setLineHeight(e, label)}
            style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}
            disabled={!editor?.isEditable || !editor?.can().setLineHeight(label)}
        ></MenuButton>
    );
};
