import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonItalic = ({ setCurrentMenu, isTooltip = true }) => {
    const editor = useEditorContext();

    const toggleItalic = e => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);
        editor?.chain().focus().toggleItalic().run();
    };

    return (
        <MenuButton
            tooltip={isTooltip ? 'Курсив' : null}
            icon={Icons.EditorIconItalic}
            onClick={toggleItalic}
            selected={editor?.isActive('italic') ?? undefined}
        ></MenuButton>
    );
};
