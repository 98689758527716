import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, useNavigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
// import MultiClamp from 'react-multi-clamp';
import { fetchMenuButtons, selectUsers } from 'slice/authSlice';
import { updateNewsTicker } from 'slice/newsPickerSlice';
import { setParent, defaultParentObject } from 'slice/treeSlice';
import { useDialog, useAuthorized, useMessage, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import { ContentWrapper } from 'containers/content-wrapper';
import { Empty } from 'components/projects';
import { TableComponent } from 'components/data-components';
import Confirmation from 'components/confirmation';
import { Icons } from 'uikit/icon';
import { EditRowToolbar } from 'uikit/table';
import api from 'api';
import cx from './news-page.module.scss';
import { MultiClumpTooltip } from 'components/MultiClumpTooltip/MultiClumpTooltip';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { MENU_CONTENT_TYPE, MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';

export default function NewsPage() {
    const tableRef = useRef(null);
    const selector = useSelector(state => state);

    const { platform } = useGlobalContext();
    const { width } = useWindowSize();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isAuthorizedAction } = useAuthorized();
    const isCreateAuthorized = isAuthorizedAction([GLOBAL_ACTIONS.NEWS_CREATE]);

    const { addSuccess, addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [newsTotal, setNewsTotal] = useState(0);
    const [news, setNews] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestNews = async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        try {
            setIsLoading(true);

            const newsResponse = await api.news.getNews(offset / count, count,
                sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : ['priority,asc', 'modifyTime,desc'], search, filters);

            newsResponse.content.map((item) => {
                item.favorites = false;
                return item;
            });

            setNewsTotal(newsResponse.totalElements);
            setNews(newsResponse.content);

            setIsSearch(!!search);
            setIsEmpty(newsResponse.totalElements === 0 && Object.keys(filters).length === 1 && filters["readied.in"]["value"] === null);

            setIsLoading(false);
        } catch (e) {
            console.log(e);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            setIsLoading(false);
        }
    };
    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
            if (prevValue.some(c => c.id === node.id)) {
                return prevValue.filter(c => c.id !== node.id);
            } else {
                return [...prevValue, node]
            }
        })
    }, [])

    const archiveNews = useCallback(async (data) => {
        try {
            setIsLoading(true);
            closeDialog();

            if (data?.id) {
                await api.archive.moveNewsToArchive(data.id);
            }  else if (checkedCheckboxRef.current.length) {
                await api.archive.moveToArchive(data.map(article => article.id));
            }

            dispatch(updateNewsTicker(true));
            dispatch(fetchMenuButtons());

            tableRef?.current?.reload();
            addSuccess(data?.id ? 'Новость перемещена в архив' : 'Выбранные новости перенесены в архив');
        } catch (error) {
            addError('При архивировании произошла ошибка')
        } finally {
            setIsLoading(false);
        }
    }, [dispatch, closeDialog, addSuccess, addError]);
    const onNewsArchive = useCallback(async (data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        let text = '';

        if (data?.length) {
            text = data.map((item) => {
                return {
                    ...item.original,
                    text: (
                        <>
                            {item.original.project ? item.original.project.title + ' / ' : 'Общая новость / '}
                            <span style={{ color: '#279BD9' }} onClick={() => navigate((item.original.project === null ? '' : '/projects') + `/news/${item.original['id']}`)}>
                                {item?.original?.title}
                            </span>
                        </>
                    )
                }
            });
        } else {
            text = (
                <span>
                    Вы действительно хотите перенести в архив новость&nbsp;
                    <span style={{ color: '#279BD9' }} onClick={() => navigate((data.project === null ? '' : '/projects') + `/news/${data['id']}`)}>
                        {data?.title}
                    </span>
                    &nbsp;? Объекты в архиве могут быть восстановлены
                </span>
            );
        }

        openDialog({
            title: 'Архивирование',
            subTitle: data?.length ? 'Вы действительно хотите переместить в архив нижеперечисленные объекты? Объекты в архиве могут быть восстановлены' : null,
            text,
            contentType: data?.length ? 'CHECKBOX_LIST' : 'TEXT',
            color: 'green',
            closeBtnText: 'Нет, закрыть',
            submitBtnText: 'Подтвердить',
            onChange: (news) => checkboxHandler(news),
            onSubmit: () => archiveNews(data?.length ? checkedCheckboxRef.current : data),
            onClose: closeDialog
        });
    }, [checkboxHandler, archiveNews, navigate, openDialog, closeDialog]);

    const newsColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title',
            width: 500,
            Cell: (data) => {
                if (data.isMobile) {
                    return (
                        <Link to={(data.row.original.project === null ? '' : '/projects') + `/news/${data.row.original['id']}`}
                              className={classNames(cx.titleBlock, cx.mobileTitleBlock)}>
                            {(data.row.original['priority'] === 'HIGH' || !data.row.original['project']) &&
                            <div className={cx.titleBlockTags}>
                                {data.row.original['priority'] === 'HIGH' &&
                                <span className={cx.yellow}>Срочная новость</span>}
                                {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                            </div>}
                            <p className={cx.title}>{data.row.original['title']}</p>
                            {data.row.original['project'] &&
                            <div className={cx.titleBlockProject}>
                                <div className={cx.titleBlockProjectAvatar}>
                                    <img src={data.row.original['project']['logoUuid']
                                        ? api.upload.getImage(data.row.original['project']['logoUuid'], false, 128)
                                        : '/img/default-icon-project.jpg'} alt={data.row.original['project']['title']}/>
                                </div>
                                <span>{data.row.original['project']['title']}</span>
                            </div>}
                            <div className={classNames(cx.titleBlockLogo, cx.logoMobile)}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                        </Link>
                    );
                } else {
                    return (
                        <Link to={(data.row.original.project === null ? '' : '/projects') + `/news/${data.row.original['id']}`}
                              className={cx.titleBlock}>
                            <div className={cx.titleBlockLogo}>
                                {data.row.original['logoUuid'] &&
                                <img src={api.upload.getImage(data.row.original['logoUuid'], false, 512)} alt="" />}
                                {!data.row.original['logoUuid'] && <img src="/img/news-card-cover.jpg" alt="" />}
                            </div>
                            <div>
                                <div className={cx.titleBlockTags}>
                                    {data.row.original['priority'] === 'HIGH' &&
                                    <span className={cx.yellow}>Срочная новость</span>}
                                    {!data.row.original['project'] && <span className={cx.blue}>Общая новость</span>}
                                </div>
                                <MultiClumpTooltip label={data.row.original['title']} />
                                {data.row.original['project'] &&
                                <div className={cx.titleBlockProject}>
                                    <div className={cx.titleBlockProjectAvatar}>
                                        <img src={data.row.original['project']['logoUuid']
                                            ? api.upload.getImage(data.row.original['project']['logoUuid'], false, 128)
                                            : '/img/default-icon-project.jpg'} alt={data.row.original['project']['title']}/>
                                    </div>
                                    <span className={cx.projectTitle}>
                                        <Tippy content={data.row.original['project']['title']}>
                                            <>{data.row.original['project']['title']}</>
                                        </Tippy>
                                    </span>
                                </div>}
                            </div>
                        </Link>
                    );
                }
            }
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime',
            width: 130,
            Cell: (data) => {
                const modifyTime = new Date(data.row.original['modifyTime']).toLocaleString();
                const createTime = new Date(data.row.original['createTime']).toLocaleString();

                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                        {modifyTime
                            ? modifyTime.substr(0, modifyTime.length - 3)
                            : createTime.substr(0, createTime.length - 3)}
                    </div>
                )
            }
        },
        {
            Header: 'Автор',
            accessor: 'multiName',
            width: 130,
            Cell: (data) => {
                return (
                    <div>
                        {data.isMobile && <div className={cx.subheader}>Автор</div>}
                        {data.row.original['author']['status'] === 'DELETED'
                            ? <span style={{opacity: 1}}>{data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}</span>
                            : <Link to={'/edit-user/' + data.row.original['author']['login']}>
                                {data.row.original['author']['firstName'] + ' ' + data.row.original['author']['lastName']}
                            </Link>}
                    </div>
                );
            }
        },
        {
            id: 4,
            settings: platform === 'mobile' ? ['no_td_wrap'] : [],
            width: 75,
            Cell: (data) => {
                let toolbar = [];

                toolbar.push({
                    icon: Icons.EDIT_PEN,
                    tooltip: 'Редактировать',
                    link: (data.row.original.project === null ? '' : '/projects') + `/news/${data.row.original.id}/edit`,
                    isHidden: original => !original?.permissions?.canEdit,
                });

                toolbar.push({
                    icon: data.row.original['favorite'] ? Icons.STAR_FILLED : Icons.STAR,
                    iconActive: data.row.original['favorite'],
                    tooltip: 'В избранное',
                    onClick: async (data) => {
                        if (data.favorite) {
                            await api.favorites.deleteNewsFromFavorites(data.id);
                            addSuccess('Новость удалена из избранного');
                        } else {
                            await api.favorites.addNewsToFavorites(data.id);
                            addSuccess('Новость добавлена в избранное');
                        }

                        const newsCopy = Object.assign([], news);
                        newsCopy.find((item) => item.id === data.id).favorite = !data.favorite;
                        setNews(newsCopy);
                    }
                });

                toolbar.push({
                    icon: Icons.ARCHIVE,
                    tooltip: 'В архив',
                    width: 15,
                    height: 15,
                    onClick: (data) => onNewsArchive(data),
                    isHidden: original => !original?.permissions?.canArchive
                });

                return EditRowToolbar(toolbar)(data);
            }
        }
    ], [addSuccess, news, onNewsArchive, platform]);
    const newsActions = useMemo(() => {
        const actions = [];
        const canArchive = (news ?? []).some(article => article.permissions?.canArchive);

        if (canArchive) {
            actions.push({
                icon: Icons.ARCHIVE,
                label: 'В архив',
                onClick: (rows) => onNewsArchive(rows),
                permittedAction: GLOBAL_ACTIONS.RESOURCE_ARCHIVE_MOVE
            });
        }

        return actions;
    }, [news, onNewsArchive]);

    const loadUsers = useCallback(async (search) => {
        let options = [];

        if (search) {
            options = users.filter(user => {
                return user.label.toString().toLowerCase().includes(search.toLowerCase());
            })
        }

        return { options }
    }, [users]);

    const newsFilters = useMemo(() => [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Автор',
            'fields': [
                {
                    'name': 'authorLogin.in',
                    'type': 'search',
                    'default': null,
                    'isMulti': true,
                    'loadOptions': loadUsers
                }
            ]
        },
        {
            'label': 'Искать по',
            'fields': [
                {
                    'name': 'global.in',
                    'type': 'select',
                    'default': null,
                    'options': [
                      { label: 'Все новости', value: '' },
                      { label: 'Проектные новости', value: 'false' },
                      { label: 'Общие новости', value: 'true' },
                    ]
                }
            ]
        },
        {
            'label': 'Приоритет',
            'fields': [
                {
                    'name': 'priority.in',
                    'type': 'select',
                    'default': null,
                    'options': [
                      { label: 'Любой', value: '' },
                      { label: 'Срочные новости', value: 'HIGH' },
                      { label: 'Обычные новости', value: 'MEDIUM' },
                    ]
                }
            ]
        },
        {
            'label': 'Срок действия новости',
            'fields': [
                {
                    'name': 'expireTime.greaterOrEqualThan',
                    'type': 'date',
                    'default': null
                },
                {
                    'name': 'expireTime.lessOrEqualThan',
                    'type': 'date',
                    'default': null
                }
            ]
        },
        {
            'label': 'Статус наличия в бегущей строке',
            'fields': [
                {
                    'name': 'publishTicker.equals',
                    'type': 'select',
                    'default': null,
                    'options': [
                        {label: 'Есть', value: 'true'},
                        {label: 'Нет', value: 'false'},
                        {label: 'Все', value: null},
                    ]
                }
            ]
        },
        {
            'label': 'Наличие вложений',
            'fields': [
                {
                    'name': 'containsAttachment.equals',
                    'type': 'select',
                    'default': null,
                    'options': [
                        {label: 'Есть', value: 'true'},
                        {label: 'Нет', value: 'false'},
                        {label: 'Все', value: null},
                    ]
                }
            ]
        }
    ], [loadUsers]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);
    useEffect(() => {
        const fetchData = async () => {
            const usersResponse = await selectUsers(selector);
            setUsers(usersResponse.content.map((user) => {
                return { label: user.firstName + ' ' + user.lastName, value: user.login };
            }));
        };
        fetchData();
    }, [selector]);
    useEffect(() => {
        dispatch(setParent(defaultParentObject));
    }, [dispatch]);

    const { setMenuContentType } = useContext(MobileMenuContext);
    useEffect(() => {
        if (!setMenuContentType) {
            return;
        }

        setMenuContentType(MENU_CONTENT_TYPE.MAIN_MENU);
    }, [setMenuContentType]);

    return (
        <>
            <Confirmation {...dialogState} />
            <ContentWrapper>
                <div className={cx.container}>
                    {isEmpty && !isSearch &&
                    <Empty title="Новости не найдены" description={isCreateAuthorized ? 'Воспользуйтесь кнопкой ниже для создания' : ''}
                           buttons={isCreateAuthorized ? [{
                               icon: Icons.NEWS,
                               title: 'Добавить новость',
                               onClick: () => navigate('/add/news')
                           }] : null}/>}
                    {(!isEmpty || isSearch) &&
                    <TableComponent
                        innerRef={tableRef}
                        isLoading={isLoading}
                        title="Новости"
                        searchTitle="Поиск по автору и названию"
                        addTitle="Добавить новость"
                        addTitleMobile="Добавить"
                        columns={newsColumns}
                        actions={newsActions}
                        total={newsTotal}
                        data={news}
                        onPaginate={requestNews}
                        filters={newsFilters}
                        addLink={isAuthorizedAction([GLOBAL_ACTIONS.NEWS_CREATE]) && '/add/news'}
                        isMobile={platform === 'mobile' || width <= 1024}
                        rowsToTilesOnMobile={true}
                        name="news"
                        isShowReadiedFilter={true}
                        defaultSort={{ by: 'modifyTime', desc: true }}
                        sortOptions={[
                            { Header: 'По дате создания', accessor: 'createTime' },
                            { Header: 'По дате изменения (по умолчанию)', accessor: 'modifyTime' },
                            { Header: 'По названию (по алфавиту)', accessor: 'title' },
                            { Header: 'По автору (по алфавиту по фамилии)', accessor: 'multiName' },
                            { Header: 'По количеству лайков', accessor: 'likesCount' },
                            { Header: 'По количеству дизлайков', accessor: 'dislikesCount' },
                            { Header: 'По популярности (кол-во просмотров)', accessor: 'readCount' },
                            { Header: 'По количеству комментариев', accessor: 'commentsCount' },
                            { Header: 'По рейтингу (оценка пользователя после прочтения)', accessor: '' },
                        ]}/>}
                </div>
            </ContentWrapper>
        </>
    );
};
