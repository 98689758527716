import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { idFromString } from 'lib/helpers';
import cx from './button.module.scss';

const Button = ({ id, className, type, btnType, color, size, label, children, fullWidth, disabled, onClick, ...attrs }) => {
    const key = id || idFromString(label);
    const styles = classNames(cx[type], cx[color], { [cx.small]: size === 'small', [cx.medium]: size === 'medium' });

    return (
        <div className={classNames(cx.buttonContainer, className, {[cx.fullWidth]: fullWidth, [cx.disabled]: disabled})}>
            <button id={key} className={styles} disabled={disabled} onClick={onClick} type={btnType} {...attrs}>
                {children ? children : label}
            </button>
        </div>
    );
};

Button.defaultProps = {
    id: null,
    className: null,
    type: 'normal',
    color: 'base',
    size: 'medium',
    label: '',
    children: null,
    fullWidth: false,
    disabled: false,
    onClick: () => {}
};

Button.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.oneOf(['normal', 'icon', 'iconRound', 'link']),
    color: PropTypes.oneOf(['base', 'green', 'blue', 'red', 'light', 'transparent']),
    size: PropTypes.oneOf(['small', 'medium']),
    label: PropTypes.string,
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

export default Button;