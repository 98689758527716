import ApiClient from './api-axios';

export default class FilePreviewApi extends ApiClient {
    getFilePreview = async (fileId, replaced, html = false, cancelToken) => {
      console.log(cancelToken);
      return await this.get(`/preview/${fileId}?replaced=${replaced}&html=${html}`, {}, false, '', {}, cancelToken);
    }

    getFilePreviewImg = async (path) => {
      return await this.get(`/file/${path}`, {}, false, 'arraybuffer', {'Accept': 'image/jpeg', 'Content-Type': 'image/jpeg'});
    }
}
