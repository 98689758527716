import { createSlice } from '@reduxjs/toolkit';
import api from 'api/index';

const initialState = {
    roles: [],
    loading: false
};

export const customRolesSlice = createSlice({
    name: 'customRoles',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setRoles: (state, action) => {
            state.roles = action.payload;
        },
        updateRole: (state, action) => {
            const _roles = state.roles.filter(r => r.id !== action.payload.id);
            state.roles = [..._roles, action.payload];
        },
        deleteRole: (state, action) => {
            state.roles = state.roles.filter(r => r.id !== action.payload);
        }
    }
});

export const {
    setLoading,
    setRoles,
    updateRole,
    deleteRole
} = customRolesSlice.actions;

export const selectLoading = state => state.customRoles.loading;
export const selectRoles = state => state.customRoles.roles;

export const fetchRoles = () => async dispatch => {
    try {
        dispatch(setLoading(true));
        const res = await api.customRoles.getRoleList();
        dispatch(setRoles(res));
    } catch (error) {

    } finally {
        dispatch(setLoading(false));
    }
};

export default customRolesSlice.reducer;
