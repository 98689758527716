import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { Empty } from 'components/projects';
import { TableComponent } from 'components/data-components';
import cxTable from 'uikit/table/table.module.scss';
import Confirmation from 'components/confirmation';
import { EditRowToolbar } from 'uikit/table';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import cx from './drafts-page-articles-tab.module.scss';
import { useDialog, useGlobalContext, useMessage } from 'lib/hooks';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import TableTitleCell from 'components/table-title-cell';

const DraftsPageArticlesTab = ({ onCountChange }) => {
    const tableRef = useRef(null);

    const { platform } = useGlobalContext();
    const { width } = useWindowSize();

    const { dialogState, openDialog, closeDialog } = useDialog();
    const { addSuccess, addError} = useMessage();

    const [isLoading, setIsLoading] = useState(false);

    const [isEmpty, setIsEmpty] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    const [articlesTotal, setArticlesTotal] = useState(0);
    const [articles, setArticles] = useState([]);

    const [checkedCheckbox, setCheckedCheckbox] = useState([]);
    const checkedCheckboxRef = useRef(checkedCheckbox);

    const requestArticles = useCallback(async (offset, count, sort = { by: '', desc: false }, search = '', filters = {}) => {
        try {
            const articlesResponse = await api.article.getArticlesDraft({
                page: offset / count,
                size: count,
                sort: sort.by ? (sort.by + ',' + (sort.desc ? 'desc' : 'asc')) : 'createTime,desc',
                filters,
                search
            });

            setArticlesTotal(articlesResponse.totalElements);
            setArticles(articlesResponse.content.map(article => ({ ...article, read: undefined })));

            setIsSearch(!!search);
            setIsEmpty(articlesResponse.totalElements === 0 && _.isEmpty(filters));

            setIsLoading(false);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const checkboxHandler = useCallback((node) => {
        setCheckedCheckbox((prevValue) => {
          if (prevValue.some(c => c.id === node.id)) {
              return prevValue.filter(c => c.id !== node.id);
          } else {
              return [...prevValue, node]
          }
        })
    }, [])

    const deleteArticles = useCallback(async (data) => {
        setIsLoading(true);
        closeDialog();

        try {
            const count = checkedCheckboxRef.current.length;

            if (data.id) {
                await api.article.deleteArticleDraft(data.id);
            } else if (checkedCheckboxRef.current.length) {
                for (let i = 0; i < checkedCheckboxRef.current.length; i++) {
                    await api.article.deleteArticleDraft(checkedCheckboxRef.current[i].id);
                }
            } else {
                setIsLoading(false);
                return
            }

            tableRef?.current?.reload();
            onCountChange();

            addSuccess(data.id ? 'Черновик статьи удален' : 'Выбранные (' + count + ') черновики удалены')
        } catch (error) {
            addError('При удалении произошла ошибка')
        } finally {
            setIsLoading(false);
        }
    }, [closeDialog, onCountChange, addSuccess, addError]);

    const onArticlesDelete = useCallback((data) => {
        if (data?.length) {
            setCheckedCheckbox(data.map(d => d.original))
        }

        openDialog({
            color: 'red',
            title: 'Удаление',
            text: 'Вы подтверждаете удаление?',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onChange: (articles) => checkboxHandler(articles),
            onSubmit: () => deleteArticles(data),
            onClose: closeDialog,
            contentType: 'TEXT',
        })
    }, [closeDialog, deleteArticles, openDialog, checkboxHandler])

    const articlesColumns = useMemo(() => {
        return [
            {
                Header: 'Содержание',
                accessor: 'title',
                Cell: (data) => (
                    <TableTitleCell
                        title={data.row.original.title}
                        link={`/user/article-draft/${data.row.original['id']}/article-draft/edit`}
                        description={data.row.original.description}
                        image={data.row.original['logoUuid']
                            ? api.upload.getImage(data.row.original['logoUuid'], false, 512)
                            : '/img/article-card-cover.jpg'}
                    />
                )
            },
            {
                Header: 'Дата изменения',
                accessor: 'modifyTime',
                width: 182,
                Cell: (data) => {
                    const createTime = new Date(data.row.original['createTime']).toLocaleString();
                    const modifyTime = new Date(data.row.original['modifyTime']).toLocaleString();
                    const isModifyTime = data.row.original['modifyTime'] !== null;

                    return (
                        <div>
                            {data.isMobile && <div className={cx.subheader}>Дата изменения</div>}
                            {isModifyTime
                                ? modifyTime.substr(0, modifyTime.length - 3)
                                : createTime.substr(0, createTime.length - 3)}
                        </div>
                    )
                },
                className: cxTable.td__grow_0
            },
            {
                id: 4,
                Header: '',
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                width: 82,
                Cell: (data) => {
                    const toolbar = [];

                    if (width > 1024) {
                        toolbar.push({
                            icon: Icons.EDIT_PEN,
                            tooltip: 'Редактировать',
                            link: `/user/article-draft/${data.row.original.id}/article-draft/edit`
                        });
                    }

                    toolbar.push({
                        icon: Icons.TRASH,
                        tooltip: 'Удалить',
                        onClick: (data) => onArticlesDelete(data)
                    });

                    return (
                        <>
                            {EditRowToolbar(toolbar)(data)}
                            <Icon
                                className={cx.mobileDelete}
                                type={Icons.TRASH}
                                width={15}
                                height={15}
                                onClick={() => onArticlesDelete(data.row.original)}
                            />
                        </>
                    );
                },
                className: cxTable.td__grow_0
            }
        ];
    }, [onArticlesDelete, platform, width]);

    const articlesFilters = [
        {
            'label': 'Дата создания',
            'fields': [
                {
                    'name': 'createTime',
                    'type': 'date-select',
                    'default': {
                        'type': {
                            label: 'Все время',
                            value: 0
                        },
                        'from': null,
                        'to': null
                    }
                }
            ]
        },
        {
            'label': 'Срок действия статьи',
            'fields': [
                {
                    'name': 'lifeTime.greaterOrEqualThan',
                    'type': 'date',
                    'default': null
                },
                {
                    'name': 'lifeTime.lessOrEqualThan',
                    'type': 'date',
                    'default': null
                }
            ]
        }
    ];

    const articlesActions = useMemo(() => {
        const actions = [];

        actions.push({
            icon: Icons.TRASH,
            label: 'Удалить',
            onClick: (data) => onArticlesDelete(data)
        });

        return actions;
    }, [onArticlesDelete]);

    useEffect(() => {
        checkedCheckboxRef.current = checkedCheckbox;
    }, [checkedCheckbox]);

    return (
        <>
            <Confirmation {...dialogState} />
            {isEmpty && !isSearch && <Empty title="Статьи не найдены"/>}
            {(!isEmpty || isSearch) &&
            <TableComponent
                innerRef={tableRef}
                isLoading={isLoading}
                searchTitle="Поиск по автору и названию"
                columns={articlesColumns}
                actions={articlesActions}
                total={articlesTotal}
                data={articles}
                onPaginate={requestArticles}
                filters={articlesFilters}
                isMobile={platform === 'mobile'}
            />}
        </>
    );
};

export default DraftsPageArticlesTab;
