import ApiClient from './api-axios';

export default class ArticleCommentsApi extends ApiClient {
    postComment = async (articleId, parentCommentId, text) => {
        return this.post('/', { articleId, parentId: parentCommentId, text });
    };

    getArticleComments = async (uuid, page, size = 20) => {
        return await this.get(`/article/${uuid}?page=${page ?? 0}&size=${size}`);
    };

    editComment = async (id, text) => {
        return await this.post('/edit', { id, text });
    };

    deleteComment = async uuid => {
        return await this.deleteRequest(`/${uuid}`);
    };

    getCommentById = async uuid => {
        return await this.get(`/${uuid}`);
    };
}
