import React, { useRef } from 'react';
import { Icons } from 'uikit/icon';
import { useEditorContext } from '../../context';
import { MenuButtonColorPicker } from './MenuButtonColorPicker';
import DropdownList from 'uikit/dropdown-list/dropdown-list';
import { MenuButton } from './MenuButton';

export const MenuButtonHighlightColor = ({ mode, currentMenu, setCurrentMenu }) => {
    const dropdownRef = useRef(null);
    const editor = useEditorContext();
    const [value, setValue] = React.useState('rgba(0, 0, 0, 1)');

    const setHighlightColor = color => {
        setValue(color);

        editor?.commands.setMark('textStyle', { backgroundColor: color });
        editor?.view.focus();
    };

    return (
        <DropdownList
            innerRef={dropdownRef}
            mode={mode}
            name="highlight-color"
            currentMenu={currentMenu}
            setCurrentMenu={setCurrentMenu}
            position={mode === 'DESKTOP' ? 'left' : 'right'}
            toggler={showMenu => (
                <MenuButton
                    icon={Icons.EditorIconSquareA}
                    tooltip="Цвет фона текста"
                    onClick={e => {
                        showMenu(e);
                        setValue(editor?.getAttributes('highlight').color || 'rgba(0, 0, 0, 1)');
                    }}
                />
            )}
        >
            <MenuButtonColorPicker onChange={setHighlightColor} onClose={() => dropdownRef.current?.hidemenu()} value={value} />
        </DropdownList>
    );
};
