import React, { useRef } from 'react';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';

import cx from '../../editor.module.scss';
import Tippy from '@tippyjs/react';
import { MenuButtonLineHeightItem } from './MenuButtonLineHeightItem';
import { useEditorContext } from '../../context';

export const MenuButtonLineHeight = ({ mode, currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();
    const lineHeightBtnRef = useRef(null);
    const lineHeightSizes = ['1', '1.5', '2', '3', '4', '5'];
    const lineHeight = editor?.getAttributes('paragraph')?.lineHeight;

    return (
        <Menu
            ref={lineHeightBtnRef}
            nested={mode === 'MOBILE'}
            mode={mode}
            name="line-height"
            currentMenu={currentMenu}
            setCurrentMenu={name => {
                if (mode === 'MOBILE' && name !== 'line-height') {
                    setCurrentMenu('sub');
                } else {
                    setCurrentMenu(name);
                }
            }}
            label={
                <Tippy content="Межстрочный интервал" disabled={mode !== 'DESKTOP'}>
                    {mode !== 'MOBILE' ? (
                        <div className={classNames('editor-button', cx.editorMenuButton)}>
                            <Icon type={Icons.EditorIconLineHeight} width={18} height={18} />
                            <Icon type={Icons.EditorIconArrowDown} width={12} height={12} />
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <div className={classNames('editor-button', cx.editorMenuButton)}>
                                <Icon type={Icons.EditorIconLineHeight} width={18} height={18} />
                                <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                                    Межстрочный интервал
                                </span>
                            </div>
                            <span aria-hidden style={{ display: 'flex', marginRight: 6 }}>
                                <Icon type={Icons.EditorIconArrowRight} width={12} height={12} />
                            </span>
                        </div>
                    )}
                </Tippy>
            }
            menuButtonStyles={mode === 'MOBILE' ? { width: '100%' } : null}
            menuContentStyles={mode === 'MOBILE' ? { overflow: 'initial', minWidth: 254 } : null}
        >
            {lineHeightSizes.map(lh => {
                return (
                    <MenuItem
                        key={lh}
                        selected={lineHeight === lh}
                        label={
                            <MenuButtonLineHeightItem
                                label={lh}
                                mode={mode}
                                hideMenu={lineHeightBtnRef.current?.hidemenu}
                                setCurrentMenu={setCurrentMenu}
                            />
                        }
                    />
                );
            })}
        </Menu>
    );
};
