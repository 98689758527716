import React from 'react';
import propTypes from 'prop-types';

export const RadioGroup = ({ id, className, value, onChange, children }) => {
		return (
				<div id={id} className={className}>
						{React.Children.map(children, radio => {
								if (React.isValidElement(radio)) {
										return React.cloneElement(radio, { checked: value === radio.props.value, onChange: () => onChange(radio.props.value) });
								}
								return radio;
						})}
				</div>
		)
}

RadioGroup.propTypes = {
		id: propTypes.string,
		className: propTypes.string,
		value: propTypes.any.isRequired,
		onChange: propTypes.func,
}

RadioGroup.defaultProps = {
		id: '',
		className: '',
		value: '',
		onChange: () => {}
}