import ApiClient from './api-axios';

export default class OfficeApi extends ApiClient {
    getOffices = async (page, size, sort = '', search) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('name.contains', search)
            params.append('address.contains', search);
            params.append('orSearch.specified', 'true');
        }

        return await this.get('?' + params.toString());
    };

    getOfficeById = async (officeId) => {
      return await this.get(`/${officeId}`);
    }

    createOffice = async (data) => {
        const params = new URLSearchParams({ ...data });

        return await this.post('?' + params.toString());
    };

    updateOffice = async ({ officeId, ...data }) => {
        const params = new URLSearchParams({ ...data, id: officeId });

        return await this.put(`/${String(officeId)}?` + params);
    };

    removeOffice = async (officeId) => {
        return await this.deleteRequest(`/${officeId}`);
    };

    getSupervisors = async (page = 0, size = 20, sort = '', search = '') => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
          params.append('orSearch.specified', true);
          params.append('userLogin.contains', search);
          params.append('userLastName.contains', search);
          params.append('userFirstName.contains', search);
          params.append('userMiddleName.contains', search);
      }

        return await this.get('/all/supervisors?' + params);
    };

    findSupervisorsByOfficeId = async (officeId) => {
        return await this.get(`/${officeId}/supervisors`);
    };

    addSupervisorsByOfficeId = async (login, officeId) => {
        return await this.post(`/${officeId}/supervisors?login=${login}`);
    };

    setSupervisorsByOfficeId = async (login, officeId) => {
        return await this.put(`/${officeId}/supervisors?login=${login}`);
    };

    updateSupervisorsByOfficeId = async (id, login, officeId) => {
        return await this.put(`/supervisors/${id}?id=${id}&login=${login}&officeId=${officeId}`);
    };

    deleteSupervisorsByOfficeId = async (login, officeId) => {
        return await this.deleteRequest(`/${officeId}/supervisors/${login}`);
    };
}
