import React from 'react';
import classNames from 'classnames';

import Icon from 'uikit/icon';

import cx from './chat-header.module.scss';

export const ChatHeader = ({ className, logoBgColor, logoSrc, chatTitle, logoIcon, renderLeftSlot, renderRightSlot, onTitleClick }) => {
    return (
        <div className={classNames(cx.chatWindowHeader, className)}>
            {renderLeftSlot?.()}

            <div className={cx.chatWindowHeaderTarget} onClick={onTitleClick}>
                <div className={classNames(cx.chatWindowHeaderTargetAvatar, logoBgColor)}>
                    {logoIcon ? (
                        <Icon type={logoIcon} color="gray" width={22} height={22} />
                    ) : logoSrc ? (
                        <img src={logoSrc} alt="" />
                    ) : null}
                </div>
                <div className={cx.chatWindowHeaderTargetContent}>
                    <p>{chatTitle}</p>
                </div>
            </div>

            {renderRightSlot?.()}
        </div>
    );
};
