import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cx from './icon-group.module.scss';

function IconGroup({ position, className, children }) {
    return <div className={classNames(className, cx.iconGroup, cx[position])}>{children}</div>;
}

IconGroup.defaultProps = {
  position: 'left',
  children: null
}

IconGroup.propTypes = {
  position: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node
};

export default IconGroup;
