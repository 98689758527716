import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonDetails = ({ setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const toggleDetails = () => {
        setCurrentMenu(undefined);
        editor?.chain().focus().setDetails().run();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconDetails}
            onClick={toggleDetails}
            tooltip={isTooltip === false ? null : 'Раскрывающиеся списки'}
            label="Раскрывающиеся списки"
            // disabled={!editor?.isEditable || !editor?.can().toggleItalic()}
            // selected={editor?.isActive('italic') ?? undefined}
        ></MenuButton>
    );
};
