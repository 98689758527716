export const GLOBAL_ACTIONS = {
    PROJECT_READ: 'PROJECT_READ',
    PROJECT_CREATE: 'PROJECT_CREATE',
    PROJECT_EDIT: 'PROJECT_EDIT',
    PROJECT_DELETE: 'PROJECT_DELETE',
    PROJECT_DELETE_REQUEST: 'PROJECT_DELETE_REQUEST',

    SECTION_READ: 'SECTION_READ',
    SECTION_CREATE: 'SECTION_CREATE',
    SECTION_EDIT: 'SECTION_EDIT',
    SECTION_DELETE: 'SECTION_DELETE',
    SECTION_DELETE_REQUEST: 'SECTION_DELETE_REQUEST',

    ARTICLE_READ: 'ARTICLE_READ ',
    ARTICLE_CREATE: 'ARTICLE_CREATE',
    ARTICLE_EDIT: 'ARTICLE_EDIT',
    ARTICLE_DELETE: 'ARTICLE_DELETE',
    ARTICLE_DELETE_REQUEST: 'ARTICLE_DELETE_REQUEST',

    NEWS_READ: 'NEWS_READ',
    NEWS_CREATE: 'NEWS_CREATE',
    NEWS_EDIT: 'NEWS_EDIT',
    NEWS_DELETE: 'NEWS_DELETE',
    NEWS_DELETE_REQUEST: 'NEWS_DELETE_REQUEST',
    GLOBAL_NEWS_CREATE: 'GLOBAL_NEWS_CREATE',

    COMMENTS_ADD: 'COMMENTS_ADD',

    DOCUMENT_SEND_TO_EMAIL: 'DOCUMENT_SEND_TO_EMAIL',
    DOCUMENT_DOWNLOAD: 'DOCUMENT_DOWNLOAD',
    DOCUMENT_PRINT: 'DOCUMENT_PRINT',

    ERROR_REPORT_ADD: 'ERROR_REPORT_ADD',
    ACTIVITY_READ: 'ACTIVITY_READ',
    NOTIFICATION_SUBSCRIBE_ADD: 'NOTIFICATION_SUBSCRIBE_ADD',
    LIKE_DISLIKE_ADD: 'LIKE_DISLIKE_ADD',

    LOCK: 'LOCK',

    USER_PAGE: 'USER_PAGE',
    USER_READ: 'USER_READ',
    VIEW_ALL_USERS: 'VIEW_ALL_USERS',
    VIEW_BLOCKED_USERS: 'VIEW_BLOCKED_USERS',
    USER_VIEW_PROFILE: 'USER_VIEW_PROFILE',
    USER_CREATE: 'USER_CREATE',
    USER_EDIT: 'USER_EDIT',
    USER_BLOCK: 'USER_BLOCK',
    USER_DELETE: 'USER_DELETE',

    GROUP_READ: 'GROUP_READ',
    GROUP_CREATE: 'GROUP_CREATE',
    GROUP_EDIT: 'GROUP_EDIT',
    GROUP_DELETE: 'GROUP_DELETE',

    CHAT_PAGE: 'CHAT_PAGE',
    CHAT_CONVERSATION_ADD: 'CHAT_CONVERSATION_ADD',
    CHAT_CONVERSATION_INFO: 'CHAT_CONVERSATION_INFO',
    CHAT_CONVERSATION_DELETE: 'CHAT_CONVERSATION_DELETE',
    CHAT_GROUP_ADD: 'CHAT_GROUP_ADD',
    CHAT_GROUP_EDIT: 'CHAT_GROUP_EDIT',
    CHAT_GROUP_INFO: 'CHAT_GROUP_INFO',
    CHAT_GROUP_DELETE: 'CHAT_GROUP_DELETE',

    ACTIVITY_PAGE: 'ACTIVITY_PAGE',
    STATS_PAGE: 'STATS_PAGE',
    STATS_EXPORT: 'STATS_EXPORT',
    STATS_SEND_EMAIL: 'STATS_SEND_EMAIL',

    RESOURCE_ARCHIVE_READ: 'RESOURCE_ARCHIVE_READ',
    RESOURCE_ARCHIVE_MOVE: 'RESOURCE_ARCHIVE_MOVE',
    RESOURCE_ARCHIVE_RESTORE: 'RESOURCE_ARCHIVE_RESTORE',

    FILE_LIST: 'FILE_LIST',
    FILE_EDIT: 'FILE_EDIT',
    FILE_UPLOAD: 'FILE_UPLOAD',
    FILE_READ: 'FILE_READ',
    FILE_REPLACE: 'FILE_REPLACE',
    FILE_DELETE: 'FILE_DELETE',

    FILE_UNATTACHED_LIST: 'FILE_UNATTACHED_LIST',
    FILE_UNATTACHED_EDIT: 'FILE_UNATTACHED_EDIT',
    FILE_UNATTACHED_UPLOAD: 'FILE_UNATTACHED_UPLOAD',
    FILE_UNATTACHED_READ: 'FILE_UNATTACHED_READ',
    FILE_UNATTACHED_REPLACE: 'FILE_UNATTACHED_REPLACE',
    FILE_UNATTACHED_DELETE: 'FILE_UNATTACHED_DELETE',

    USER_OWN_EDIT: 'USER_OWN_EDIT',
    NOTIFICATION_SUBSCRIBE_EDIT: 'NOTIFICATION_SUBSCRIBE_EDIT',
    SYSTEM_SETTINGS: 'SYSTEM_SETTINGS',

    COMPANY_PAGE: 'COMPANY_PAGE',
    COMPANY_EDIT: 'COMPANY_EDIT',
    COMPANY_OFFICE_CREATE: 'COMPANY_OFFICE_CREATE',
    COMPANY_OFFICE_EDIT: 'COMPANY_OFFICE_EDIT',
    COMPANY_OFFICE_DELETE: 'COMPANY_OFFICE_DELETE',

    SUPERVISOR_PAGE: 'SUPERVISOR_PAGE',
    SUPERVISOR_ADD: 'SUPERVISOR_ADD',
    SUPERVISOR_EDIT: 'SUPERVISOR_EDIT',
    SUPERVISOR_DELETE: 'SUPERVISOR_DELETE',

    SCRIPT_CREATE: 'SCRIPT_CREATE',
    SCRIPT_EDIT: 'SCRIPT_EDIT',
    SCRIPT_DELETE: 'SCRIPT_DELETE',

    POSITION_PAGE: 'POSITION_PAGE',
    POSITION_CREATE: 'POSITION_CREATE',
    POSITION_EDIT: 'POSITION_EDIT',
    POSITION_DELETE: 'POSITION_DELETE',

    CUSTOM_ROLE_READ: 'CUSTOM_ROLE_READ',
    CUSTOM_ROLE_CREATE: 'CUSTOM_ROLE_CREATE',
    CUSTOM_ROLE_EDIT: 'CUSTOM_ROLE_EDIT',
    CUSTOM_ROLE_DELETE: 'CUSTOM_ROLE_DELETE',

    INTEGRATION_PAGE: 'INTEGRATION_PAGE',
    APP_PAGE: 'APP_PAGE',
    TRAINING: 'APP_PAGE'
};