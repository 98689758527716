import { useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();

    const update = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    useLayoutEffect(() => {
        update();
        // window.addEventListener('resize', update);
        // return () => window.removeEventListener('resize', update);
    });

    return { width, height };
}
