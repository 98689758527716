import React from 'react';
import classNames from 'classnames';
import IconButton from 'uikit/icon-button/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Button from 'uikit/button/button';
import cs from './common-dialog.module.scss';
import { useGlobalContext } from 'lib/hooks';

const CommonDialog = ({ title = 'Добавление доступа', open, onDismiss, onSubmit, children, mode, id }) => {
    const { platform } = useGlobalContext();

    if (!open) {
        return null;
    }

    return (
        <div id={id} data-testid="commonDialogModal" className={cs.overlay}>
            <div className={classNames(cs.content, {[cs.mobile]: platform === 'mobile'})}>
                <div className={cs.title}>
                    <div data-testid="commonDialogModalTitle">{title}</div>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CROSS}/>} onClick={onDismiss} id="commonDialogModalCrestBtn"/>
                </div>
                {children}
                {platform !== 'mobile' && <div className={cs.line}/>}
                {mode !== 'edit' &&
                <div className={cs.buttons}>
                    <Button label={'Отмена'} onClick={onDismiss} data-tesid="commonDialogModalCancelBtn" />
                    <Button color={'green'} label={'Отправить'} onClick={onSubmit} data-tesid="commonDialogModalOkBtn" />
                </div>}
                {mode === 'edit' &&
                <div className={cs.buttons}>
                    <Button label={'Закрыть'} onClick={onDismiss} data-tesid="commonDialogModalCloseBtn" />
                </div>}
            </div>
        </div>
    );
};

export default CommonDialog;