import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import React, { forwardRef } from 'react';
import Icon from 'uikit/icon/icon';
import cx from '../../editor.module.scss';

export const MenuButton = forwardRef(({ icon, iconColor, onClick, className, style, selected, label, tooltip, disabled }, ref) => {
    const click = e => {
        onClick?.(e);
    };

    return (
        <Tippy content={tooltip} disabled={!tooltip} placement="top" theme="editor-button">
            <div
                ref={ref}
                className={classNames('editor-button', cx.editorMenuButton, className, {
                    [cx.selected]: selected,
                    [cx.grow]: label,
                    [cx.disabled]: disabled
                })}
                onMouseDown={
                    document.body.offsetWidth > 1024
                        ? e => {
                              e.preventDefault();
                              e.stopPropagation();

                              return false;
                          }
                        : null
                }
                style={style}
                onClick={click}
            >
                {icon instanceof Function ? (
                    icon()
                ) : (
                    <Icon type={icon} color={iconColor} width={18} height={18} />
                )}
                {label && (
                    <span className="editor-button-label" style={{ paddingLeft: 6 }}>
                        {label}
                    </span>
                )}
            </div>
        </Tippy>
    );
});
