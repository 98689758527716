import { findParentNodeClosestToPos } from '@tiptap/core';

import { isCellSelection } from './isCellSelection';
import { NodeSelection } from '@tiptap/pm/state';

export const copyTableWhenAllCellsSelected = ({ editor }) => {
    const { selection, tr } = editor.state;
    const { dispatch } = editor.view;

    if (!isCellSelection(selection)) {
        return false;
    }

    let cellCount = 0;
    const table = findParentNodeClosestToPos(selection.ranges[0].$from, node => {
        return node.type.name === 'table';
    });

    table?.node.descendants(node => {
        if (node.type.name === 'table') {
            return false;
        }

        if (['tableCell', 'tableHeader'].includes(node.type.name)) {
            cellCount += 1;
        }
    });

    const allCellsSelected = cellCount === selection.ranges.length;

    if (!allCellsSelected) {
        return false;
    }

    const resolvedPos = editor.view.state.doc.resolve(table.pos);
    const sel = new NodeSelection(resolvedPos);
    dispatch(tr.setSelection(sel));
    editor.state.apply(editor.state.tr);

    return true;
};
