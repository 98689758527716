import React from 'react';
import { Icons } from 'uikit/icon';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';

export const MenuTable = ({ mode, setCurrentMenu, isTooltip }) => {
    const editor = useEditorContext();

    const onSubmit = () => {
        setCurrentMenu(undefined);
        editor?.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: false });
    };

    return (
        <MenuButton
            icon={Icons.EditorIconTable}
            tooltip={isTooltip === false ? null : 'Таблица'}
            label={mode !== 'TABLET' ? 'Таблица' : null}
            onClick={onSubmit}
        />
    );
};
