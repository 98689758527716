import * as Stomp from 'stompjs';
import SockJS from 'sockjs-client';

const subscribers = [];

let stompClient = null;
let socket = null;

const NotificationConnect = (login) => {
    if (socket) {
        return;
    }

    socket = new SockJS('/websocket/notifications');

    stompClient = Stomp.over(socket);
    stompClient.debug = null;

    stompClient.connect({}, function() {
        stompClient.subscribe(`/user/${login}/notifications`, message => {
            subscribers[0](message);
        });
    });
};

export const NotificationDisconnect = () => {
    // stompClient.disconnect();
};

export const NotificationSubscribe = (onMessage) => {
    subscribers.push(onMessage);
};

export default NotificationConnect;