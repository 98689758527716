import React, { useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Link, useNavigate } from '@reach/router';
import { useDebounceValue } from 'lib/hooks';
import { useTrainingTree } from 'components/trees/utils/useLocationTree';
import { InfiniteTree as Tree } from 'components/trees';
import Icon, { Icons } from 'uikit/icon';
import cx from './training-tree.module.scss';

const toggleTreeNodes = ({ tree }, collapse) => {
    function toggleNode(node) {
        node.children.forEach(toggleNode);

        if ((!collapse && !node.state.open) || (collapse && node.state.open)) {
            tree.toggleNode(node, { silent: true });
        }
    }

    if (tree) {
        for (let node of tree.nodes) {
            toggleNode(node);
        }
    }
};

const TrainingTree = ({ canAdd = true, onCheck = () => {}, isReload = false }) => {
    const navigate = useNavigate();

    const { tree, setIsInitialized } = useTrainingTree();
    const treeRef = useRef();

    const [query, setQuery] = useState('');
    const [debouncedValue] = useDebounceValue(query.trim().replace(/\s{2,}/g, " "), 300);

    useEffect(() => { setIsInitialized(false); }, [isReload, setIsInitialized]);
    useEffect(() => {
        if (treeRef?.current?.tree) {
            const { tree } = treeRef.current;
            tree.filter(debouncedValue.trim());
        }
    }, [debouncedValue, treeRef]);

    return (
        <div className={cx.trainingTree}>
            <div className={cx.trainingTreeSearch}>
                <input
                    type="text"
                    placeholder="Поиск по дереву"
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                />
            </div>
            <div className={cx.trainingTreeExpander}>
                <div className={cx.trainingTreeExpanderButton} onClick={() => toggleTreeNodes(treeRef.current, true)}>
                    <Icon type={Icons.CHEVRONS_UP} width={6.77}/>
                    <span>Свернуть всё</span>
                </div>
                <div className={cx.trainingTreeExpanderButton} onClick={() => toggleTreeNodes(treeRef.current, false)}>
                    <Icon type={Icons.CHEVRON_DOWN} width={6.77}/>
                    <span>Развернуть всё</span>
                </div>
            </div>
            <div className={cx.trainingTreeCreate}>
                <div className={cx.trainingTreeCreateIcon}>
                    <Icon type={Icons.ADD} width={10} height={10}/>
                </div>
                <Link to={'/training/catalog/theme'}>
                    <span>Создать тему</span>
                </Link>
                &nbsp;или&nbsp;
                <Link to={'/training/catalog/question'}>
                    <span>вопрос</span>
                </Link>
            </div>
            <div className={cx.trainingTreeSizerContainer}>
                <AutoSizer key={tree}>
                    {({ width, height }) => {
                        return (
                            <Tree ref={treeRef} width={width} height={height} data={tree} multipleChoice={true}
                                  autoOpen={true} highlight={[debouncedValue]}
                                  onAddNode={canAdd ? () => navigate('/training/catalog/theme/') : null}
                                  onCheckedChange={(data) => onCheck(data)}
                                  getNodeLink={(node) => node.resourceType === 'THEME' ? `/training/catalog/theme/${node.resourceId}`
                                      : node.resourceType === 'QUESTION' ? `/training/catalog/question/${node.resourceId}` : ''}/>
                        );
                    }}
                </AutoSizer>
            </div>
        </div>
    );
};
export default TrainingTree;
