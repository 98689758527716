import React from 'react';

const DeleteIcon = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M15.1874 1.35L14.6541 0.81663C14.3374 0.499963 13.9207 0.333313 13.4874 0.333313C13.0374 0.333313 12.6374 0.499963 12.3207 0.81663L1.78739 11.3666C1.72073 11.4333 1.67073 11.5 1.63739 11.6L0.387393 14.75C0.287393 15 0.354059 15.2833 0.537393 15.4667C0.670726 15.6 0.837393 15.6666 1.00406 15.6666C1.08739 15.6666 1.17073 15.65 1.25406 15.6166L4.42073 14.3833C4.50406 14.35 4.58739 14.3 4.65406 14.2333L15.2041 3.68333C15.8374 3.05 15.8374 2 15.1874 1.35ZM2.80406 12.2833L3.73739 13.2167L2.18739 13.8166L2.80406 12.2833ZM14.2374 2.74998L5.03739 11.95L4.07073 10.9833L13.2707 1.76666C13.3541 1.68333 13.4541 1.66665 13.5041 1.66665C13.5541 1.66665 13.6541 1.68333 13.7374 1.76666L14.2707 2.29999C14.3707 2.41665 14.3707 2.61665 14.2374 2.74998Z" fill="#7B8F9C"/>
        </svg>

    );
};

export default DeleteIcon;