import { Mark } from '@tiptap/core';

export const FontWeightMark = Mark.create({
    name: 'font-weight',

    addCommands() {
        return {
            setFontWeight:
                fontWeight =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontWeight }).run();
                },
            unsetFontWeight:
                () =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontWeight: null }).removeEmptyTextStyle().run();
                },
        };
    },
});
