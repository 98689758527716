import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SelectAsync from 'react-select/async';
import { components as selectComponents } from 'react-select';
import { useCustomStyle } from './useSelectStyle';
import cx from './select.module.scss';
import { AsyncPaginate } from 'react-select-async-paginate';

export const SelectOption = (props) => {
    const {
        components,
        options,
        onChange,
        placeholder,
        error,
        label,
        disabled,
        isClearable,
        isSearchable,
        defaultValue,
        value,
        isMulti,
        style,
        className,
        required,
        id,
        showCaret,
        isError,
        paginate,
        debounceTimeout = 500,
        ...rest
    } = props;
    const _isError = isError === null ? !!error : isError;
    const customStyle = useCustomStyle(_isError, disabled, style);
    const _components = components;

    if (!showCaret) {
        _components.Input = (props) => (
            <div style={{ caretColor: 'transparent' }}>
                {' '}
                <selectComponents.Input {...props} />{' '}
            </div>
        );
    }

    return (
        <div id={id} data-testid={id} className={classNames(cx.content, className)}>
            {label.length > 1 && (
                <div
                    className={classNames(cx.label, {
                        [cx.required]: required,
                    })}
                >
                    {label}
                </div>
            )}
            {!paginate ? (
                <SelectAsync
                    styles={customStyle}
                    value={value}
                    options={options}
                    onChange={(selectedOption) => onChange(selectedOption)}
                    placeholder={placeholder}
                    isDisabled={disabled}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    defaultValue={defaultValue}
                    isMulti={isMulti}
                    components={_components}
                    menuPlacement="auto"
                    defaultOptions={options}
                    loadOptions={(inputValue, callback) => {
                        const search = inputValue.toLowerCase();

                        return callback(
                            options
                                .filter((tag) => tag.label.toString().toLowerCase().includes(search.toLowerCase()))
                                .sort((tag1, tag2) => {
                                    const tag1Tmp = tag1.label.toString().toLowerCase().split(' ');
                                    const tag2Tmp = tag2.label.toString().toLowerCase().split(' ');

                                    if (tag1Tmp[0] === search || tag1Tmp[1] === search) {
                                        return -1;
                                    } else if (tag2Tmp[0] === search || tag2Tmp[1] === search) {
                                        return 1;
                                    }

                                    return tag1.label.toString().toLowerCase().indexOf(search) - tag2.label.toString().toLowerCase().indexOf(search);
                                })
                        );
                    }}
                    {...rest}
                />
            ) : (
                <AsyncPaginate
                    {...props}
                    isDisabled={disabled}
                    styles={customStyle}
                    debounceTimeout={debounceTimeout}
                    components={_components}
                    menuPlacement="auto"
                    isSearchable={isSearchable}
                    noOptionsMessage={() => "Ничего не найдено"}
                />
            )}
            {error && <span className={cx['error-message']}>{error}</span>}
        </div>
    );
};

SelectOption.defaultProps = {
    options: [],
    onChange: () => {},
    placeholder: '',
    label: '',
    error: '',
    disabled: false,
    isClearable: false,
    isSearchable: true,
    isMulti: false,
    className: '',
    id: '',
    showCaret: true,
    components: {},
    isError: false,
};

SelectOption.propTypes = {
    option: PropTypes.array,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    isClearable: PropTypes.bool,
    isSearchable: PropTypes.bool,
    defaultValue: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isMulti: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    showCaret: PropTypes.bool,
    isError: PropTypes.bool,
    paginate: PropTypes.bool,
    debounceTimeout: PropTypes.number
};
