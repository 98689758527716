import React, { useMemo } from 'react';
import classNames from 'classnames';
import Checkbox from 'uikit/checkbox/checkbox';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';
import TreeIcon from 'components/trees/infinite-tree/tree-icon';
import MobileNodeToggler from 'components/trees/mobile-tree/mobile-node-toggler';
import cx from './mobile-tree-node.module.scss';

const MobileTreeNode = ({ node, tree, toggleState, onClick, getNodeLink, highlight, withCheckboxes = false }) => {
    const style = useMemo(() => {
        let _style = {}

        if (highlight && highlight.length > 0 && highlight.filter((item) => item).map((item) => item.toLowerCase()).some(( item ) => node.name.toLowerCase().includes(item))) {
            _style.backgroundColor = 'yellow'
        }

        return _style;
    }, [highlight, node.name]);

    return (
        <AdaptiveLink link={() => getNodeLink(node)} className={classNames(cx.node, {[cx.selected]: node.state.selected})}
                      onClick={() => onClick(node)}>
            {withCheckboxes && <Checkbox type={'checkbox'} checked={node.state.selected} className={cx.checkbox}/>}
            <MobileNodeToggler
                state={toggleState}
                depth={node.state.depth}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    if (toggleState === 'closed') {
                        tree.openNode(node);
                    } else if (toggleState === 'opened') {
                        tree.closeNode(node, { silent: true });
                    }
                }}
                onMouseUp={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
                onMouseDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            />
            <div className={cx.img}>
                <TreeIcon node={node} state={toggleState} nodeType={node.nodeType} size={node.state.depth === 0 ? 20 : 18}/>
            </div>
            <div className={cx.name} style={style}>{node.name}</div>
        </AdaptiveLink>
    )
};

export default MobileTreeNode;
