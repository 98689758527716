import React, { useEffect, useState } from 'react';
import { BubbleMenuPlugin } from './bubble-menu-plugin';

export const BubbleMenuView = props => {
    const [element, setElement] = useState(null);

    useEffect(() => {
        if (!element) {
            return;
        }

        if (props.editor?.isDestroyed) {
            return;
        }

        const { pluginKey = 'bubbleMenu', editor, tippyOptions = {}, updateDelay, shouldShow = null, popperOptions = {} } = props;

        const menuEditor = editor;

        if (!menuEditor) {
            console.warn('BubbleMenu component is not rendered inside of an editor component or does not have editor prop.');
            return;
        }

        const plugin = BubbleMenuPlugin({
            updateDelay,
            editor: menuEditor,
            element,
            pluginKey,
            shouldShow,
            tippyOptions,
            popperOptions,
        });

        menuEditor.registerPlugin(plugin);
        return () => menuEditor.unregisterPlugin(pluginKey);
        // eslint-disable-next-line
    }, [props.editor, element]);

    return (
        <div ref={setElement} className={props.className} style={{ visibility: 'hidden' }}>
            {props.children}
        </div>
    );
};
