import React from 'react';
import cx from './scrollTopBtn.module.scss';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';

const ScrollTopBtn = ({isSeen, scrollId}) => {

    return (
        <>
            {isSeen &&
            <div className={cx.scrollTopBtn} onClick={() => document.getElementById(scrollId).scrollTop = 0}>
                <IconButton icon={<Icon type={Icons.ARROW_FULL_UP} width={20} height={20}/>} onClick={() => {}}/>
                <div>Наверх</div>
            </div>}
        </>
    )
}

export default ScrollTopBtn;
