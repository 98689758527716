import React from 'react';
import { BubbleMenu } from '@tiptap/react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';

import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import { MenuButton } from '../Menu/MenuButton';

import cx from '../../editor.module.scss';

export const BubbleMenuTemplate = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    return (
        <BubbleMenu
            tippyOptions={{
                placement: 'bottom',
                arrow: false,
                getReferenceClientRect: () => {
                    const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                        return node.type.name === 'columnBlock';
                    });

                    if (nearestParent) {
                        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                        if (wrapperDomNode) {
                            return wrapperDomNode.getBoundingClientRect();
                        }
                    }
                    const { ranges } = editor?.state.selection;
                    const from = Math.min(...ranges.map(range => range.$from.pos));
                    const to = Math.max(...ranges.map(range => range.$to.pos));
                    return posToDOMRect(editor?.view, from, to);
                },
            }}
            editor={editor}
            shouldShow={({ editor }) => editor.isActive('columnBlock')}
        >
            <div className={cx.bubbleMenu}>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconColumnTwo}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeColumns({ cols: 2 }).run();
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconColumnThree}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeColumns({ cols: 3 }).run();
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconColumnTwoLeft}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeColumns({ cols: 2, template: 'left' }).run();
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconColumnTwoRight}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeColumns({ cols: 2, template: 'right' }).run();
                    }}
                />
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconColumnThreeCenter}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor?.chain().focus().changeColumns({ cols: 3, template: 'center' }).run();
                    }}
                />
                <div style={{ height: 17, width: 1, borderRight: '1px solid rgba(39, 155, 217, 0.15)' }}></div>
                <MenuButton
                    className={cx.bubbleMenuButton}
                    icon={Icons.EditorIconTrash}
                    onClick={() => {
                        setCurrentMenu(undefined);
                        editor.chain().focus().unsetColumns().run();
                    }}
                />
            </div>
        </BubbleMenu>
    );
};
