import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { v4 } from 'uuid';

export const CodeBlockHighlight = CodeBlockLowlight.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      id: {
        default: v4(),
        rendered: false,
      },
    };
  },
});
