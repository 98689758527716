import React from 'react';

const AddIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.99984 15.3334C12.0499 15.3334 15.3332 12.0502 15.3332 8.00008C15.3332 3.94999 12.0499 0.666748 7.99984 0.666748C3.94975 0.666748 0.666504 3.94999 0.666504 8.00008C0.666504 12.0502 3.94975 15.3334 7.99984 15.3334ZM7.2665 5.06675C7.2665 4.66174 7.59483 4.33341 7.99984 4.33341C8.40485 4.33341 8.73317 4.66174 8.73317 5.06675V7.26675H10.9332C11.3382 7.26675 11.6665 7.59507 11.6665 8.00008C11.6665 8.40509 11.3382 8.73341 10.9332 8.73341H8.73317V10.9334C8.73317 11.3384 8.40485 11.6667 7.99984 11.6667C7.59483 11.6667 7.2665 11.3384 7.2665 10.9334V8.73341H5.0665C4.66149 8.73341 4.33317 8.40509 4.33317 8.00008C4.33317 7.59507 4.66149 7.26675 5.0665 7.26675H7.2665V5.06675Z"
                  fill="#1280CE"/>
        </svg>
    );
};

export default AddIcon;