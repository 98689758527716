import React, { useState, useEffect, useCallback, useContext } from 'react';
import Icon, { Icons } from 'uikit/icon';
import { Link } from '@reach/router';
import IconGroup from 'uikit/icon-group';
import cs from './script-posting.module.scss';
import ScriptPlayback from 'components/script-playback/script-playback';
import api from 'api';
import ScriptEditor, {formatEdges} from 'components/script-editor/script-editor';
import {addEdge } from 'react-flow-renderer';
import { useDialog } from 'lib/hooks';
import { Confirmation } from '../../../../components/confirmation/confirmation';
import { ScriptsTreeContext } from 'containers/scripts-wrapper/scripts-wrapper';
import cx from "components/data-components/reports-component.module.scss";
import {Empty} from "components/projects";
import PostingHeader from "components/posting-header";
import {DOCUMENT_TYPE} from "components/posting-header/posting-header";
import {collectScriptBreadCrumbs} from "lib/helpers";

const EDGE_TYPE_STRAIGHT = 'straight';
const EDGE_TYPE_PREVIEW = 'preview';
const EDGE_TYPE_FROM = 'link_from';
const EDGE_TYPE_TO = 'link_to';

function ScriptPosting({ navigate, scriptUuid, type, archive }) {
    const { dialogState, openDialog, closeDialog } = useDialog();
    const context = useContext(ScriptsTreeContext);

    const [activeTab, setActiveTab] = useState('playback');
    const [isLoading, setIsLoading] = useState(true);

    const [breadCrumbs, setBreadCrumbs] = useState(null);
    const [script, setScript] = useState(null);
    const [state, setState] = useState(null);
    const [scriptPlayback, setScriptPlayback] = useState([]);

    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);

    const onConnect = useCallback((connection) => setEdges((eds) => addEdge(connection, eds)), []);

    const onArchiveScript = async () => {
        openDialog({
            title: 'Архивирование',
            subTitle: (
                <span>
                    Вы действительно хотите перенести в архив скрипт
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/scripting/script/${scriptUuid}`)}> {script.script.name}</span>
                    ? Скрипты в архиве могут быть восстановлены
                </span>
            ),
            text: (
                <span>
                    Вы действительно хотите переместить в архив скрипт?&nbsp;
                    Скрипты в архиве могут быть восстановлены.
                </span>
            ),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: archiveScript
        });
    };
    const archiveScript = async () => {
        await api.archive.moveScriptToArchive(scriptUuid);

        if (context && context.tree && context.tree.current) {
            const tree = context.tree.current.tree;
            const _node = tree.getNodeById(scriptUuid);
            if (_node) {
                tree.removeNode(_node);
            }
        }

        navigate(`/scripting/${script.parents.projectId}/${script.parents.sections[script.parents.sections.length - 1].uuid}`);
    };

    const onUnArchiveScript = async () => {
        openDialog({
            title: 'Восстановление',
            subTitle: (
                <span>
                    Вы действительно хотите восстановить из архива скрипт
                    <span style={{ color: '#279BD9', cursor: 'pointer' }} onClick={() => navigate(`/archive/script/${scriptUuid}`)}> {script.script.name}</span>
                    ?
                </span>
            ),
            text: (<span>Вы действительно хотите восстановить из архива скрипт?</span>),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: unArchiveScript
        });
    };
    const unArchiveScript = async () => {
        await api.archive.moveScriptFromArchive(scriptUuid);
        navigate('/scripting');
    };

    const onRemoveScript = async () => {
        openDialog({
            title: 'Удаление',
            text: (<span>Вы действительно хотите удалить скрипт? Этот процесс нельзя будет отменить</span>),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onClose: closeDialog,
            onSubmit: removeScript,
            contentType: 'TEXT',
            color: 'red',
        });
    };
    const removeScript = async () => {
        if (type === 'draft') {
            await api.scriptingDraft.removeScript(scriptUuid)
        } else {
            await api.scripting.removeScript(scriptUuid);
        }

        let url = '/scripting';
        if (archive) {
            url = '/archive/scripts';
        }

        navigate(url);
    };

    const onChangeTab = (tab) => {
        setActiveTab(tab);
    };
    const parseScriptToPayback = useCallback(() => {
        if (nodes && edges)
            return JSON.parse(
                JSON.stringify(
                    nodes
                        .filter((node) => node.type === 'special')
                        .map((node) => {
                            const nodeConditionIds = edges.filter((edge) => edge.source === node.id).map((n) => n.target);
                            const conditionNodes = [];

                            nodeConditionIds.forEach((id) => {
                                const _node = nodes.find((n) => n.id === id);

                                if (_node) {
                                    _node['targetId'] = edges.find((edge) => edge.source === _node.id)?.target;
                                    conditionNodes.push(_node);
                                }
                            });

                            return {
                                ...node,
                                conditionNodes,
                            };
                        })
                )
            );
    }, [nodes, edges]);

    useEffect(() => {
        if (!script) {
            return;
        }

        if (!archive && script['state'] === 'ARCHIVED') {
            navigate(`/archive/script/${script.script.id}`);
        } else if (archive && script['state'] === 'ACTIVE') {
            navigate(`/scripting/script/${script.script.id}`);
        }
    }, [navigate, archive, script]);
    useEffect(() => {
        const fetchScript = async () => {
            setIsLoading(true);

            try {
                const res = type === 'draft' ? await api.scriptingDraft.getScriptById(scriptUuid) : await api.scripting.getPublicScript(scriptUuid);
                const script = type === 'draft' ? res.scriptDraft : res.script;

                const _nodes = [];
                const _edges = [];

                script.elements.forEach((el) => {
                    if (el.files?.length) {
                        el.files = el.files.map((f) => {
                            return {
                                status: 'done',
                                fileId: f.fileId,
                                serverData: {
                                    name: f.fileName,
                                    size: f.fileSize,
                                    type: f.fileType,
                                },
                            };
                        });
                    }

                    el.type = el.type.toLowerCase();
                    el.type === EDGE_TYPE_STRAIGHT || el.type === EDGE_TYPE_FROM || el.type === EDGE_TYPE_TO ? _edges.push(el) : _nodes.push(el);
                });

                const breadCrumbs = collectScriptBreadCrumbs(script.id, script.name, res?.parents);
                setBreadCrumbs(breadCrumbs);

                setScript(res);
                setState(res.state);

                setNodes(_nodes);
                _nodes.forEach(node => formatEdges(_edges, node, setEdges));
                setEdges((prevState) => {
                    return [...prevState.filter(( edge ) => edge.state !== EDGE_TYPE_PREVIEW)];
                })
            } catch (error) {
                if (error.errorData?.message === 'error.resource.deleted') {
                    setState('DELETED');
                }
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchScript();
    }, [scriptUuid, type]);
    useEffect(() => {
        if (!isLoading) {
            setScriptPlayback(parseScriptToPayback());
        }
    }, [isLoading, parseScriptToPayback]);

    return (
        <>
            {state === 'DELETED' && (
                <div className={cx.empty}>
                    <Empty icon={Icons.SCRIPTING} title="Скрипт был удален" />
                </div>
            )}
            {script && (
                <div className={cs.postingWrapper}>
                    <Confirmation {...dialogState} />
                    <div className={cs.scriptHeader}>
                        <PostingHeader
                            className={cs.header}
                            title={script?.script.name}
                            authorId={script?.script.author.login}
                            authorName={script?.script.author.firstName + ' ' + script?.script.author.lastName}
                            authorStatus={script?.script.author.status}
                            authorImage={script?.script['author']['avatarUuid'] && api.upload.getImage(script?.script['author']['avatarUuid'], false, 128)}
                            documentType={DOCUMENT_TYPE.SCRIPT} date={script?.script.createTime}
                            isArchive={archive}
                            crumbs={breadCrumbs?.map((item) => {
                                return {
                                    name: item.title,
                                    linkTo: script?.script.id === item.id ? '' : archive
                                        ? item.type === 'PROJECT'
                                            ? `/archive/${item.id}/scripts`
                                            : `/archive/${breadCrumbs[0].id}/${item.id}/section/scripts`
                                        : item.type === 'PROJECT'
                                            ? `/projects/${item.id}/scripts`
                                            : `/projects/${breadCrumbs[0].id}/${item.id}/section/scripts`
                                };
                            })}
                            lastAction={script?.lastAction}
                        />
                        {script && (
                            <div className={cs.actions}>
                                <IconGroup position="right">
                                    {(!script['state'] || script['state'] === 'ACTIVE') && script.permissions?.canEdit && (
                                        <Link to={'/scripting/edit/' + scriptUuid} data-testid="scriptPostingEditBtn">
                                            <Icon type={Icons.EDIT_PEN} />
                                        </Link>
                                    )}
                                    {script['state'] === 'ACTIVE' && script.permissions?.canArchive && (
                                        <Icon onClick={onArchiveScript} type={Icons.ARCHIVE} color="gray" id="scriptPostingArchiveBtn" />
                                    )}
                                    {script['state'] === 'ARCHIVED' && script.permissions?.canArchive && (
                                        <Icon onClick={onUnArchiveScript} type={Icons.RELOAD} color="gray" id="scriptPostingUnarchiveBtn" />
                                    )}

                                    {(!script['state'] || script['state'] === 'ARCHIVED') && script.permissions?.canDelete && (
                                        <Icon onClick={onRemoveScript} type={Icons.TRASH} color="red" id="scriptPostingRemoveBtn" />
                                    )}
                                </IconGroup>
                            </div>
                        )}
                    </div>
                    {script?.script.description && (
                        <div className={cs.description} data-testid="scriptPostingDescription">
                            {script?.script.description}
                        </div>
                    )}
                    <div className={cs.tabsWrapper}>
                        <ul className={cs.tabs} data-testid="scriptPostingTabs">
                            <li className={activeTab === 'playback' ? cs.active : ''}
                                onClick={() => onChangeTab('playback')} data-testid="scriptPostingTabPlayback">
                                Воспроизведение
                            </li>
                            <li className={activeTab === 'script-schema' ? cs.active : ''}
                                onClick={() => onChangeTab('script-schema')} data-testid="scriptPostingTabSchema">
                                Схема скрипта
                            </li>
                        </ul>
                    </div>
                    <div
                        className={cs.playbackWrapper}
                        style={{ display: activeTab === 'playback' ? 'block' : 'none' }}
                        data-testid="scriptPostingPlaybackWrapper"
                    >
                        <ScriptPlayback elements={scriptPlayback} />
                    </div>
                    <div
                        className={cs.playbackWrapper}
                        style={{ display: activeTab === 'script-schema' ? 'block' : 'none' }}
                        data-testid="scriptPostingSchemaWrapper"
                    >
                        <ScriptEditor nodes={nodes} edges={edges} nodesDraggable={false} onConnect={onConnect} />
                    </div>
                </div>
            )}
        </>
    );
}

export default ScriptPosting;
