import ApiClient from './api-axios';

export default class NotificationApi extends ApiClient {
    getAll = async (page = 0, size = 500) => {
        return await this.get(`/?page=${page}&size=${size}&sort=id,desc`);
    };

    getUnread = async (page = 0, size = 2000, system = false) => {
        return await this.get(`/unread?page=${page}&size=${size}&sort=id,desc&system=${system}`);
    };

    readAll = async () => {
        return await this.put('/all/read');
    };

    read = async (notificationId) => {
        return await this.put(`/${notificationId}/read`);
    };
}