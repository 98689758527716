import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from '@reach/router';
import { usePermittedActions } from 'lib/hooks';
import { RESOURCE_TYPE, perm } from 'model/resource';
import { selectRoles } from 'slice/authSlice';
import { Empty } from 'components/projects/index';
import ModalDialog from 'components/modal-dialog';
import { Icons } from 'uikit/icon';
import Carousel from 'uikit/carousel/carousel';
import { News, NewsStatusColors } from 'uikit/panel';
import Filter from 'uikit/filter/filter';
import Table, { EditRowToolbar } from 'uikit/table';
import Pagination from 'uikit/pagination/pagination';
import Loading from 'uikit/loading';
import api from 'api/index';
import cx from './tab-all.module.scss';

const TabAll = ({ navigate, projectId }) => {
    const [isFetched, setIsFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [news, setNews] = useState([]);
    const { checkPermission } = usePermittedActions(RESOURCE_TYPE.PROJECT, projectId);

    const p = useMemo(() => {
        return {
            addPermission: checkPermission(perm.document.CREATE),
            edit: checkPermission(perm.document.EDIT),
            delete: checkPermission(perm.document.DELETE),
            archive: checkPermission(perm.document.RESOURCE_ARCHIVE_MOVE)
        };
    }, [checkPermission]);

    const isMultipleDelete = useSelector(selectRoles)['hasSuperUserRole'];

    const [isRemove, setIsRemove] = useState(false);
    const [removeData, setRemoveData] = useState(null);

    const [isArchive, setIsArchive] = useState(false);
    const [archiveData, setArchiveData] = useState(null);

    const [articlesTableTotal, setArticlesTableTotal] = useState(0);
    const [articlesTableCount, setArticlesTableCount] = useState(10);
    const [articlesTableOffset, setArticlesTableOffset] = useState(0);
    const [articlesTableData, setArticlesTableData] = useState([]);
    const [articlesTableSort, setArticlesTableSort] = useState({ by: '', desc: false });

    const articlesColumns = useMemo(() => [
        {
            Header: 'Содержание',
            accessor: 'title'
        },
        {
            Header: 'Дата создания',
            accessor: 'createTime'
        },
        {
            Header: 'Дата изменения',
            accessor: 'modifyTime'
        },
        {
            Header: 'Автор',
            accessor: 'author'
        },
        {
            id: 4,
            Cell: (function() {
                const toolbar = [];
                if (p.edit) {
                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        onClick: (data) => navigate(`/projects/article/${data.id}/edit`)
                    });
                }
                if (p.archive) {
                    toolbar.push({
                        icon: Icons.ARCHIVE,
                        tooltip: 'Архивировать',
                        onClick: (data) => {
                            setIsArchive(true);
                            setArchiveData(data);
                        }
                    });
                }
                if (p.delete) {
                    toolbar.push({
                        icon: Icons.TRASH,
                        tooltip: 'Удалить',
                        onClick: (data) => {
                            setIsRemove(true);
                            setRemoveData(data);
                        }
                    });
                }
                return EditRowToolbar(toolbar);
            })()
        }
    ], [navigate, p]);
    const tableActions = (function() {
        const actions = [];

        actions.push({
            icon: Icons.ARCHIVE,
            label: 'В архив',
            onClick: (rows) => {
                setIsArchive(true);
                setArchiveData(rows);
            },
            permittedAction: perm.document.RESOURCE_ARCHIVE_MOVE
        });

        if (isMultipleDelete) {
            actions.push({
                icon: Icons.TRASH,
                label: 'Удалить',
                onClick: (rows) => {
                    setIsRemove(true);
                    setRemoveData(rows);
                },
                permittedAction: perm.document.DELETE
            });
        }

        return actions;
    })();

    const onArticlesPaginate = useCallback(async (offset = articlesTableOffset, count = articlesTableCount) => {
        setIsLoading(true);

        const articlesResponse = await api.article.getProjectArticles(projectId, offset / count, count,
            articlesTableSort.by
                ? (articlesTableSort.by + ',' + (articlesTableSort.desc ? 'desc' : 'asc'))
                : 'modifyTime,desc');

        const articles = articlesResponse.content.map((article) => {
            return {
                'id': article['id'],
                'title': (
                    <div onClick={() => navigate(`/projects/article/${article['id']}`)}>
                        <p>{article['title']}</p>
                        <span>{article['description']}</span>
                    </div>
                ),
                'createTime': new Date(article['createTime']).toLocaleString().slice(0, -3),
                'modifyTime': article['lastAction']
                    ? new Date(article['lastAction']['timestamp']).toLocaleString().slice(0, -3)
                    : new Date(article['createTime']).toLocaleString().slice(0, -3),
                'author': (
                    <Link to={'/edit-user/' + article['author']['login']}>
                        {article['author']['firstName'] + ' ' + article['author']['lastName']}
                    </Link>
                ),
                permittedActions: article.permittedActions
            };
        });

        setArticlesTableTotal(articlesResponse['totalElements']);
        setArticlesTableCount(count);
        setArticlesTableOffset(offset);
        setArticlesTableData(articles);

        setIsLoading(false);
    }, [articlesTableCount, articlesTableOffset, articlesTableSort, navigate, projectId]);
    const onArticlesTableSort = async (column) => {
        const sort = Object.assign({}, articlesTableSort);

        if (sort.by === column.id) {
            sort.desc = !sort.desc;
        } else {
            sort.by = column.id;
            sort.desc = false;
        }

        setArticlesTableSort(sort);
    };

    const onRemove = async () => {
        setIsRemove(false);
        setIsLoading(true);

        if (removeData.id) {
            await api.article.deleteArticle(removeData.id);
        } else {
            for (let i = 0; i < removeData.length; i++) {
                await api.article.deleteArticle(removeData[i].original.id);
            }
        }

        if ((removeData.id && articlesTableData.length === 1) || (removeData.length === articlesTableData.length)) {
            await onArticlesPaginate(articlesTableOffset === 0 ? 0 : articlesTableOffset - articlesTableCount);
        } else {
            await onArticlesPaginate();
        }

        setIsLoading(false);
    };
    const onArchive = async () => {
        setIsArchive(false);
        setIsLoading(true);

        if (archiveData.id) {
            await api.archive.moveArticleToArchive(archiveData.id);
        } else {
            for (let i = 0; i < archiveData.length; i++) {
                await api.archive.moveArticleToArchive(archiveData[i].original.id);
            }
        }

        if ((archiveData.id && articlesTableData.length === 1) || (archiveData.length === articlesTableData.length)) {
            await onArticlesPaginate(articlesTableOffset === 0 ? 0 : articlesTableOffset - articlesTableCount);
        } else {
            await onArticlesPaginate();
        }

        setIsLoading(false);
    };

    const requestNews = useCallback(async () => {
        const newsResponse = await api.news.getProjectNews(projectId);
        setNews(newsResponse.content.map((item) => {
            return {
                id: item['id'],
                statusColor: NewsStatusColors.NONE,
                statusText: '',
                image: api.upload.getImage(item['logoUuid'], false, 512),
                projectId: item['project']?.['id'],
                projectImage: item['project'] && api.upload.getImage(item['project']?.['logoUuid'], false, 128),
                projectTitle: item['project']?.['title'],
                title: item['title'],
                description: item['text'].replace(/<\/?[^>]+>/g, '').replace(/&nbsp;/gi, ''),
                authorLogin: item['author']['login'],
                authorImage: api.upload.getImage(item['author']['avatarUuid'], false, 128),
                authorShortName: item['author']['firstName'] + ' ' + item['author']['lastName'].slice(0, 1) + '.',
                authorName: item['author']['firstName'] + ' ' + item['author']['lastName'],
                date: new Date(item['createTime']).toLocaleString().slice(0, -3),
                priority: item['priority']
            };
        }));

        setIsFetched(true);
    }, [projectId]);

    useEffect(() => {
        onArticlesPaginate();
    }, [onArticlesPaginate]);

    useEffect(() => {
        const fetchData = async () => {
            await requestNews();
            setIsFetched(true);
        };
        fetchData();
    }, [projectId, requestNews]);

    if (isFetched && news.length === 0 && articlesTableData.length === 0) {
        const description = (p.addPermission && 'Воспользуйтесь кнопками ниже для создания') || '';
        const buttons = (p.addPermission && [
            {
                icon: Icons.SECTION,
                title: 'Добавить раздел',
                onClick: () => {
                    navigate('/add/section');
                }
            },
            {
                icon: Icons.EDIT_PEN,
                title: 'Добавить статью',
                onClick: () => {
                    setTimeout(() => {
                        navigate('/add/article');
                    }, 1000);
                }
            },
            {
                icon: Icons.NEWS,
                title: 'Добавить новость',
                onClick: () => {
                    navigate('/add/news');
                }
            }
        ]) || [];
        return (
            <Empty title="Проект пуст" description={description} buttons={buttons}/>
        );
    }

    return (
        <div className={cx.tabAll}>
            {news.length !== 0 &&
            <div className={cx.news}>
                <div className={cx.head}>
                    <h2>Последние новости</h2>
                    <Link to="/news">Смотреть все новости</Link>
                </div>
                <Carousel>
                    {news.map((item) => {
                        return (
                            <div key={item.id}>
                                <News id={item.id} statusColor={item.statusColor} statusText={item.statusText}
                                      image={item.image} projectId={item.projectId} projectImage={item.projectImage}
                                      projectTitle={item.projectTitle} title={item.title} description={item.description}
                                      authorLogin={item.authorLogin} authorImage={item.authorImage}
                                      authorName={item.authorName} authorShortName={item.authorShortName} date={item.date}
                                      onClick={() => {
                                          navigate(`/projects/news/${item.id}`);
                                      }}
                                      onActionRemove={requestNews} onActionArchive={requestNews}
                                      priority={item.priority}
                                      permissions={news.permissions}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </div>}
            <ModalDialog isOpen={isRemove} text="Вы подтверждаете удаление?"
                         onRequestSubmit={() => onRemove()} onRequestClose={() => setIsRemove(false)}/>
            <ModalDialog isOpen={isArchive} text="Вы подтверждаете архивирование?"
                         onRequestSubmit={() => onArchive()} onRequestClose={() => setIsArchive(false)}/>
            {articlesTableData.length !== 0 &&
            <div className={cx.articles}>
                <div className={cx.head}>
                    <h2>Статьи</h2>
                </div>
                <div className={cx.table}>
                    {isLoading &&
                    <div className={cx.loader}>
                        <Loading withOverlay={false}/>
                    </div>}
                    <div className={cx.filter}>
                        <Filter search="Поиск по автору и названию" onSearch={() => {}} add="Добавить статью"
                                onAdd={() => navigate('/add/article')}/>
                    </div>
                    <Table columns={articlesColumns} data={articlesTableData} sort={articlesTableSort}
                           onSort={onArticlesTableSort} actions={tableActions}/>
                    <div className={cx.pagination}>
                        <Pagination total={articlesTableTotal} count={articlesTableCount} offset={articlesTableOffset}
                                    onCountChange={(count) => onArticlesPaginate(0, count.value)}
                                    onPaginate={onArticlesPaginate}/>
                    </div>
                </div>
            </div>}
        </div>
    );
};

TabAll.defaultProps = {
    projectId: ''
};

TabAll.propTypes = {
    projectId: PropTypes.string
};

export default TabAll;
