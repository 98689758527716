import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatDateTime from 'lib/helpers/formatDateTime';
import Textarea from 'uikit/textarea';
import Icon, { Icons } from 'uikit/icon';
import User from 'components/user';
import api from 'api';
import cs from './comment-item.module.scss';
import { useWindowSize } from 'lib/hooks/useWindowSize';

const CommentItem = (
    {
        replyComment,
        editComment,
        path,
        loggedIn,
        onReply,
        onCancel,
        onEdit,
        onReplySubmit,
        onEditSubmit,
        onDelete,
        onToggleLike,
        onToggleDislike,
        isPosting,
        isArchive,
        ...rest
    }) => {
    const { width } = useWindowSize();
    const inputRef = React.useRef();

    const [textRows, setTextRows] = useState(1);
    const [text, setText] = React.useState('');
    const [textError, setTextError] = React.useState(false);

    React.useEffect(() => {
        if (!replyComment && !editComment) {
            setTextRows(1);
            setText('');
        }
    }, [replyComment, editComment]);
    React.useEffect(() => {
        inputRef?.current && inputRef.current.focus();
    }, [inputRef]);

    const renderComment = ({ comment: { user, ...comment } }, path, index) => {
        const isReplying = replyComment?.id === comment.id;
        const isEditing = editComment?.id === comment.id;

        const _onReply = () => {
            onReply({ id: comment.id, path });
        };

        const _onEdit = () => {
            onEdit({ id: comment.id, path });
            setTextRows(comment.text.split('\n').length);
            setText(comment.text);
        };

        const _onSubmit = () => {
            if (!isPosting) {
                if (text.length === 0) {
                    setTextError(true);
                    return;
                }

                isEditing && onEditSubmit(comment.id, path, text);
                isReplying && onReplySubmit([...path, comment.id], text);
            }
        };

        const _onDelete = () => {
            onDelete({ id: comment.id, path });
        };

        const _toggleLike = () => {
            onToggleLike({ id: comment.id, path, liked: comment.liked, disliked: comment.disliked });
        };

        const _toggleDislike = () => {
            onToggleDislike({ id: comment.id, path, liked: comment.liked, disliked: comment.disliked });
        };

        const classes = classNames(cs.commentContainer, { [cs.child]: path.length > 0 });
        const styles = { marginLeft: 24 * (width < 768 ? (path.length === 0 ? 0 : 1) : path.length) };

        const onTextKeydown = event => {
            if (!isPosting && !event.shiftKey && event.keyCode === 13) {
                _onSubmit();
            }
        };

        const onTextChange = str => {
            setText(str);
            setTextRows(str.split('\n').length);
        };

        return (
            <>
                <div className={classes} style={styles} key={index}>
                    <User id={user.id} className={cs.commentator} name={`${user.firstName} ${user.lastName}`}
                          image={user.avatarUuid ? api.upload.getImage(user.avatarUuid, false, 128) : '/img/avatar.png'}
                          login={user.login} date={comment.createTime} stackReversed/>
                    {!isEditing && <div className={cs.text}>{comment.text}</div>}
                    {comment.updateTime &&
                    <div className={cs.updateTime}>Изменено {formatDateTime(comment.updateTime)}</div>}
                    {(isReplying || isEditing) &&
                        <Textarea
                            id="reply-input"
                            style={{ resize: 'none' }}
                            rows={textRows}
                            value={text}
                            onChange={onTextChange}
                            className={cs.replyInput}
                            disabled={isPosting}
                            error={textError}
                            ref={inputRef}
                            onFocus={() => setTextError(false)}
                            onKeyDown={onTextKeydown}
                        />
                    }
                    <div className={cs.replyBlock}>
                        {!isReplying && !isEditing &&
                        <>
                            {!isArchive &&
                            <div className={classNames(cs.reply, path.length > 0 ? cs.replyNext : null)} onClick={_onReply}>
                                <Icon width={16} height={16} type={Icons.JUMP_RIGHT}/>
                                <span>Ответить</span>
                            </div>}
                            {user.login === loggedIn &&
                            <>
                                {!isArchive &&
                                <div className={classNames(cs.reply, path.length > 0 ? cs.replyNext : null)} onClick={_onEdit}>
                                    <Icon width={12} height={12} type={Icons.EDIT_PEN}/>
                                    <span className={path.length > 0 ? cs.replyNext : null}>Редактировать</span>
                                </div>}
                                {!isArchive &&
                                <div className={classNames(cs.reply, cs.removeButton, path.length > 0 ? cs.replyNext : null)} onClick={_onDelete}>
                                    <Icon width={12} height={12} type={Icons.TRASH}/>
                                    <span className={path.length > 0 ? cs.replyNext : null}>Удалить</span>
                                </div>}
                                <div className={classNames(cs.reply, cs.like, cs.noAction, path.length > 0 ? cs.replyNext : null)}>
                                    <Icon width={12} height={12} type={Icons.LIKE}/>
                                    <span className={path.length > 0 ? cs.replyNext : null}>{'Нравится'}</span>
                                    <b>{comment.likes > 0 ? comment.likes : ''}</b>
                                </div>
                                <div className={classNames(cs.reply, cs.dislike, cs.noAction, path.length > 0 ? cs.replyNext : null)}>
                                    <Icon width={12} height={12} type={Icons.DISLIKE}/>
                                    <span className={path.length > 0 ? cs.replyNext : null}>{'Не нравится '}</span>
                                    <b>{comment.dislikes > 0 ? comment.dislikes : ''}</b>
                                </div>
                            </>}
                            {user.login !== loggedIn && !isArchive &&
                                <>
                                    <div
                                        className={classNames(cs.reply, cs.like, cs.noAction, path.length > 0 ? cs.replyNext : null)}
                                        onClick={_toggleLike}
                                    >
                                        <Icon width={12} height={12} type={Icons.LIKE} />
                                        <span className={classNames(path.length > 0 ? cs.replyNext : null, cs.desktop)}>
                                            {comment.liked ? 'Убрать нравится' : 'Нравится'}
                                        </span>
                                        <span className={classNames(path.length > 0 ? cs.replyNext : null, cs.mobile)}>
                                            Нравится
                                        </span>
                                        <b>{comment.likes > 0 ? comment.likes : ''}</b>
                                    </div>
                                    <div
                                        className={classNames(cs.reply, cs.dislike, cs.noAction, path.length > 0 ? cs.replyNext : null)}
                                        onClick={_toggleDislike}
                                    >
                                        <Icon width={12} height={12} type={Icons.DISLIKE} />
                                        <span className={classNames(path.length > 0 ? cs.replyNext : null, cs.desktop)}>
                                            {comment.disliked ? 'Убрать не нравится' : 'Не нравится'}
                                        </span>
                                        <span className={classNames(path.length > 0 ? cs.replyNext : null, cs.mobile)}>
                                            Не нравится
                                        </span>
                                        <b>{comment.dislikes > 0 ? comment.dislikes : ''}</b>
                                    </div>
                                </>}
                        </>}
                        {(isReplying || isEditing) && !isArchive &&
                            <div className={cs.editButtonsBlock}>
                            <div className={classNames(cs.reply, { [cs.disabled]: isPosting })} onClick={onCancel}>
                                    <Icon width={16} height={16} type={Icons.CLOSE_CIRCLE} />
                                    Отмена
                                </div>
                                <div className={classNames(cs.reply, { [cs.disabled]: isPosting })}
                                     onClick={_onSubmit}>
                                    <Icon width={16} height={16} type={Icons.SEND} />
                                    Отправить
                                </div>
                            </div>}
                    </div>
                </div>
                {comment.children.map((childComment, i) => {
                    return (
                        <>
                            {renderComment({ comment: childComment }, [...path, comment.id], i)}
                        </>
                    );
                })}
            </>
        );
    };

    return (
        <>
            {renderComment(rest, path, -1)}
        </>
    );
};

CommentItem.defaultProps = {
    liked: false,
    likes: 0
};

CommentItem.propTypes = {
    comment: PropTypes.shape({
        children: PropTypes.array,
        createTime: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        liked: PropTypes.bool,
        likes: PropTypes.number,
        text: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            middleName: PropTypes.string,
            login: PropTypes.string.isRequired,
            avatarUuid: PropTypes.string
        }).isRequired
    }).isRequired,
    onReply: PropTypes.func.isRequired,
    replyComment: PropTypes.shape({
        id: PropTypes.string,
        path: PropTypes.array
    }),
    onCancel: PropTypes.func.isRequired,
    onReplySubmit: PropTypes.func.isRequired,
    onEditSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onToggleLike: PropTypes.func.isRequired,
    onToggleDislike: PropTypes.func.isRequired,
    isPosting: PropTypes.bool,
    isArchive: PropTypes.bool
};

export default CommentItem;
