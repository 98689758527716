import HtmlDiff from 'htmldiff-js';

const getDiffElement = (element, type) => {
    return `<${type}>` + element.outerHTML + `</${type}>`;
};
const calculateSimilarity = (str1, str2) => {
    const set1 = new Set(str1.split(' '));
    const set2 = new Set(str2.split(' '));

    const intersection = new Set([...set1].filter(x => set2.has(x)));
    const union = new Set([...set1, ...set2]);

    return (intersection.size / union.size) * 100;
};
export const getStyles = () => {
    return `
        <style>
            ins {
                display: block;
                padding: 2px 8px;
                background: rgba(80, 182, 120, 0.35);
                border-radius: 3px;
                text-decoration: none;
            }
            del {
                display: block;
                padding: 2px 8px;
                background: rgba(255, 105, 115, 0.35);
                border-radius: 3px;
                text-decoration: line-through;
            }
            .diffmod, .diffins, .diffdel {
                display: inline-block;
            }
        </style>
    `;
};
const getDiffs = (original, revised) => {
    const parser = new DOMParser();

    const originDom = parser.parseFromString(original, 'text/html');
    const revisedDom = parser.parseFromString(revised, 'text/html');

    let originElements = Array.from(originDom.body.querySelectorAll('body > *'));
    let revisedElements = Array.from(revisedDom.body.querySelectorAll('body > *'));

    const adaptRevisedElements = (checkExist) => {
        for (let i = 0; i < originElements.length; i++) {
            if (originElements[i] === null) {
                continue;
            }

            if (!revisedElements[i]) {
                revisedElements = [...revisedElements.slice(0, i), null, ...revisedElements.slice(i)];
                continue;
            }

            const originText = originElements[i].textContent.trim();
            const revisedText = revisedElements[i].textContent.trim();

            const similarity = calculateSimilarity(originText, revisedText);
            const isTag = originElements[i].tagName === revisedElements[i].tagName;

            let elementExist = false;

            if (similarity !== 100) {
                for (let j = 0; j < revisedElements.length; j++) {
                    if (!revisedElements[j]) {
                        continue;
                    }

                    const prevOriginText = revisedElements[j].textContent;
                    const prevOriginTextSimilarity = calculateSimilarity(originElements[i].textContent, prevOriginText);

                    if (prevOriginTextSimilarity > 50) {
                        elementExist = true;
                    }
                }
            }

            if (!isTag || similarity < 10) {
                if (checkExist && elementExist) {
                    continue;
                }

                revisedElements = [...revisedElements.slice(0, i), null, ...revisedElements.slice(i)];
            }
        }
    };
    const adaptOriginElements = (checkExist) => {
        for (let i = 0; i < revisedElements.length; i++) {
            if (revisedElements[i] === null) {
                continue;
            }

            if (!originElements[i]) {
                originElements = [...originElements.slice(0, i), null, ...originElements.slice(i)];
                continue;
            }

            const isTag = originElements[i].tagName === revisedElements[i].tagName;
            const similarity = calculateSimilarity(revisedElements[i].textContent, originElements[i].textContent);

            let elementExist = false;

            if (similarity !== 100) {
                for (let j = 0; j < originElements.length; j++) {
                    if (!originElements[j]) {
                        continue;
                    }

                    const prevOriginText = originElements[j].textContent;
                    const prevOriginTextSimilarity = calculateSimilarity(revisedElements[i].textContent, prevOriginText);

                    if (prevOriginTextSimilarity > 50) {
                        elementExist = true;
                    }
                }
            }

            if (!isTag || similarity < 10) {
                if (checkExist && elementExist) {
                    continue;
                }

                originElements = [...originElements.slice(0, i), null, ...originElements.slice(i)];
            }
        }
    };

    if (originElements.length < revisedElements.length) {
        adaptOriginElements(true);
        adaptRevisedElements(false);
    } else {
        adaptRevisedElements(true);
        adaptOriginElements(true);
    }

    const length = originElements.length > revisedElements.length ? originElements.length : revisedElements.length;
    let content = '';

    for (let i = 0; i < length; i++) {
        if (!originElements[i]) {
            content += getDiffElement(revisedElements[i], 'ins');
            continue;
        }

        if (!revisedElements[i]) {
            content += getDiffElement(originElements[i], 'del');
            continue;
        }

        if (originElements[i].outerHTML !== revisedElements[i].outerHTML) {
            const diff = HtmlDiff.execute(originElements[i].outerHTML, revisedElements[i].outerHTML);

            const diffDom = parser.parseFromString(diff, 'text/html');
            const diffElements = diffDom.body.querySelectorAll('ins, del');

            if (diffElements.length > 4) {
                content += getDiffElement(originElements[i], 'del');
                content += getDiffElement(revisedElements[i], 'ins');

                continue;
            }

            content += diff;
            continue;
        }

        content += originElements[i].outerHTML
    }

    return getStyles() + '<body>' + content + '</body>';
};

export default getDiffs;
