import { Mark } from '@tiptap/core';

export const FontSizeMark = Mark.create({
    name: 'fontSize',
    priority: 900,
    addOptions() {
        return {
            types: ['textStyle'],
        };
    },

    addCommands() {
        return {
            setFontSize:
                fontSize =>
                ({ chain }) => {
                    return chain().setMark('textStyle', { fontSize }).run();
                },
            unsetFontSize:
                () =>
                ({ chain }) => {
                    return chain().focus().setMark('textStyle', { fontSize: null }).removeEmptyTextStyle().run();
                },
        };
    },
});
