import { FILES_TYPE_SHORT_NAME } from 'lib/config/constant';

const getFileTypesOptions = () => {
    const options = Object.keys(FILES_TYPE_SHORT_NAME)
        .filter((v, i, a) => a.indexOf(v) === i)
        .map((key) => {
            return { label: FILES_TYPE_SHORT_NAME[key], value: key };
        });

    let response = [];

    options.forEach(p => {
        if (response.find(p1 => p1.label === p.label)) {
            return;
        }

        const sameOptions = options.filter(p1 => p1.label === p.label);
        response.push({
            label: p.label,
            value: sameOptions.map(p1 => p1.value)
        });
    });

    return response;
};

export default getFileTypesOptions;
