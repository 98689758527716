import api from 'api/index';

export async function fileUpload(file, initId) {
    if (!file) {
        return;
    }

    const hostname = window.location.hostname.includes('localhost')
        ? 'http://localhost:3000'
        : window.location.protocol + '//' + window.location.hostname;

    const formData = new FormData();

    const re = new RegExp('XSRF-TOKEN=([^;]+)');
    const value = re.exec(document.cookie);

    formData.append('init', initId);
    formData.append('upload', file);
    formData.append('ckCsrfToken', value !== null ? decodeURI(value[1]) : null);

    const res = await api.file.uploadEditorFile(formData);

    return { src: res.url.replace('https://stag-kms.gran-soft.ru', hostname), alt: res.fileName };
}
