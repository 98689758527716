import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from 'uikit/button';
import cx from './modal-dialog.module.scss';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(38, 38, 38, 0.65)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const ModalDialog = ({ isOpen, onRequestClose, onRequestSubmit, contentLabel, text, modalLoading }) => {
    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel={contentLabel} style={customStyles}>
            <div className={cx.content}>
                {text}
            </div>
            <div className={cx.toolbar}>
                <Button onClick={onRequestSubmit} label='Да' disabled={modalLoading}/>
                <Button onClick={onRequestClose} label='Отмена' disabled={modalLoading}/>
            </div>
        </Modal>
    );
};

ModalDialog.defaultProps = {
    isOpen: false,
    onRequestClose: () => {

    },
    onRequestSubmit: () => {

    }
};

ModalDialog.propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    onRequestSubmit: PropTypes.func,
    contentLabel: PropTypes.string,
    text: PropTypes.string
};

export default ModalDialog;