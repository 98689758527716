import React, { useEffect, useMemo, useRef, useState } from 'react';
import { findParentNodeClosestToPos, posToDOMRect } from '@tiptap/core';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import Icon from 'uikit/icon/icon';
import { Menu, MenuItem } from '../DropdownMenu/DropdownMenu';
import { MenuButtonColorPicker } from '../Menu/MenuButtonColorPicker';
import cx from '../../editor.module.scss';
import { BubbleMenuView } from '../../extensions/BubbleMenu/BubbleMenuView';
import { CellSelection } from '../../extensions/Table/cellSelection';
import { selectedRect } from 'editors/tiptap-editor/extensions/Table/commands';
import { findParentNode } from 'editors/tiptap-editor/utils/findParentNode';

export const BubbleMenuTableCell = ({ currentMenu, setCurrentMenu }) => {
    const editor = useEditorContext();
    const colorPickerMenuRef = useRef(null);

    const [isMergeCellShow, setIsMergeCellShow] = useState(false);
    const [mode, setMode] = useState('DESKTOP');

    const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 1)');
    const [borderColor, setBorderColor] = useState('rgba(0, 0, 0, 1)');

    const isAddColumnBlock = useMemo(() => {
        if (!editor?.state) {
            return false;
        }
        const rect = selectedRect(editor.state);

        if (!rect) {
            return false;
        }
        const tableHeader = findParentNode(editor.state, 'tableHeader');

        return tableHeader && rect.left === 0 ? true : false;
    }, [editor?.state]);

    const isAddRowBlock = useMemo(() => {
        if (!editor?.state) {
            return false;
        }
        const rect = selectedRect(editor.state);

        if (!rect) {
            return false;
        }

        const tableHeader = findParentNode(editor.state, 'tableHeader');

        return tableHeader && rect.top === 0 ? true : false;
    }, [editor?.state]);

    const cellButtonsConfig = useMemo(
        () => [
            {
                name: 'Объединить ячейки',
                action: editor => editor.chain().focus().mergeCells().run(),
                icon: Icons.EditorIconTableJoinCell,
                type: 'joinCells',
            },
            {
                name: 'Добавить столбец справа',
                action: editor => editor.chain().addColumnAfter().run(),
                icon: Icons.EditorIconTableAddColumnLeft,
            },
            {
                name: 'Добавить столбец слева',
                action: editor => !isAddColumnBlock && editor.chain().addColumnBefore().run(),
                icon: Icons.EditorIconTableAddColumnRight,
                disabled: isAddColumnBlock,
            },
            {
                name: 'Добавить строку выше',
                action: editor => !isAddRowBlock && editor.chain().addRowBefore().run(),
                icon: Icons.EditorIconTableAddRowBottom,
                disabled: isAddRowBlock,
            },
            {
                name: 'Добавить строку ниже',
                action: editor => editor.chain().addRowAfter().run(),
                icon: Icons.EditorIconTableAddRowTop,
            },
            {
                name: 'Вертикальное выравнивание',
                action: (editor, align) => editor.chain().changeVerticalAlign(align).run(),
                icon: Icons.EditorIconJustify,
                type: 'verticalAlign',
            },
            {
                name: 'Очистить содержимое',
                action: editor => {
                    editor.chain().focus().clearCell().run();
                },
                icon: Icons.EditorIconCleanFormat,
                type: 'clearCell',
            },
            {
                name: 'Цвет границ',
                action: (editor, color) => editor.chain().setBorderColor(color).run(),
                icon: Icons.EditorIconTableTextColor,
                type: 'borderColor',
            },
            {
                name: 'Цвет ячейки',
                action: (editor, color) => editor.chain().setCellAttribute('bgcolor', color).run(),
                icon: Icons.EditorIconTableCellColor,
                type: 'bgcolor',
            },
            {
                name: 'Удалить столбец',
                action: editor => editor.chain().focus().deleteColumn().run(),
                icon: Icons.EditorIconTableRemove,
            },
            {
                name: 'Удалить строку',
                action: editor => editor.chain().focus().deleteRow().run(),
                icon: Icons.EditorIconTableRemove,
            },
        ],
        [isAddColumnBlock, isAddRowBlock]
    );

    const onClick = (btn, color) => {
        if (btn.type === 'clearCell') {
            const { pos, start, node } = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                return node.type.name === 'tableCell';
            });
            const startPosition = start;
            const endPosition = pos + node.nodeSize;

            let {
                state: { tr },
                dispatch,
            } = editor.view;
            tr.replaceWith(startPosition, endPosition, editor.state.schema.tableCell);
            dispatch(tr);
            editor.commands.goToPreviousCell();
            editor.commands.setCellAttribute('bgcolor', null);
        } else if (btn.type === 'borderColor') {
            setBorderColor(color);
            btn.action(editor, color);
        } else if (btn.type === 'bgcolor') {
            setBgColor(color);
            btn.action(editor, color);
        } else {
            btn.action(editor);
        }
    };

    const onVerticalAlignChange = align => {
        editor.commands.changeVerticalAlign(align);
    };

    const onMenuOpen = () => {
        const sel = editor.state.selection;

        setIsMergeCellShow(sel instanceof CellSelection && sel.$anchorCell.pos !== sel.$headCell.pos);
    };

    useEffect(() => {
        setMode(window.innerWidth > 920 ? 'DESKTOP' : window.innerWidth > 550 ? 'TABLET' : 'MOBILE');

        const onResize = () => {
            setMode(window.innerWidth > 920 ? 'DESKTOP' : window.innerWidth > 550 ? 'TABLET' : 'MOBILE');
        };

        window.addEventListener('resize', onResize, true);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <BubbleMenuView
            tippyOptions={{
                placement: mode === 'DESKTOP' ? 'right-start' : 'bottom',
                arrow: false,
                offset: mode === 'DESKTOP' ? [0, -13] : undefined,
                theme: 'tableCell',
                getReferenceClientRect: () => {
                    const nearestParent = findParentNodeClosestToPos(editor?.state.selection.$anchor, node => {
                        return node.type.spec.tableRole === 'cell' || node.type.spec.tableRole === 'header_cell';
                    });

                    if (nearestParent) {
                        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

                        if (wrapperDomNode) {
                            return wrapperDomNode.getBoundingClientRect();
                        }
                    }
                    const { ranges } = editor?.state.selection;
                    const from = Math.min(...ranges.map(range => range.$from.pos));
                    const to = Math.max(...ranges.map(range => range.$to.pos));
                    return posToDOMRect(editor?.view, from, to);
                },
            }}
            popperOptions={{
                modifiers:
                    mode === 'DESKTOP'
                        ? [
                              {
                                  name: 'flip',
                                  options: {
                                      fallbackPlacements: ['right-start'],
                                  },
                              },
                          ]
                        : null,
            }}
            editor={editor}
            shouldShow={({ editor }) => {
                return editor?.isActive('tableCell') || editor?.isActive('tableHeader');
            }}
        >
            <div
                style={{
                    display: editor?.isActive('tableCell') || editor?.isActive('tableHeader') ? 'flex' : 'none',
                    alignItems: 'center',
                    gap: 6,
                    // ...getItemPosition(),
                }}
            >
                <Menu
                    mode={mode}
                    name="table-cell"
                    currentMenu={currentMenu}
                    setCurrentMenu={setCurrentMenu}
                    menuContentStyles={{ overflow: 'initial' }}
                    label={
                        <div className="trigger-button" onClick={onMenuOpen}>
                            <Icon type={Icons.EditorIconMore} width={18} height={18} />
                        </div>
                    }
                >
                    {cellButtonsConfig.map(btn => {
                        if (btn.type === 'joinCells' && !isMergeCellShow) {
                            return null;
                        }

                        if (btn.type === 'verticalAlign') {
                            return (
                                <Menu
                                    mode={mode}
                                    name="table-cell"
                                    currentMenu={currentMenu}
                                    setCurrentMenu={setCurrentMenu}
                                    key={btn.name}
                                    menuStyles={{ overflow: 'initial' }}
                                    menuContentStyles={{ padding: 0, overflow: 'initial' }}
                                    one={true}
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                            <Icon type={btn.icon} width={18} height={18} /> <span>{btn.name}</span>
                                        </div>
                                    }
                                >
                                    <MenuItem
                                        styles={{ height: 'auto' }}
                                        label={onClose => <div onClick={onClose}>Сверху</div>}
                                        onClick={() => onVerticalAlignChange('top')}
                                    />
                                    <MenuItem
                                        styles={{ height: 'auto' }}
                                        label={onClose => <div onClick={onClose}>По центру</div>}
                                        onClick={() => onVerticalAlignChange('middle')}
                                    />
                                    <MenuItem
                                        styles={{ height: 'auto', marginBottom: mode !== 'DESKTOP' ? '41px' : 0 }}
                                        label={onClose => <div onClick={onClose}>Снизу</div>}
                                        onClick={() => onVerticalAlignChange('bottom')}
                                    />
                                </Menu>
                            );
                        }
                        if (btn.type === 'borderColor' || btn.type === 'bgcolor') {
                            return (
                                <Menu
                                    mode={mode}
                                    name="table-cell"
                                    currentMenu={currentMenu}
                                    setCurrentMenu={setCurrentMenu}
                                    ref={colorPickerMenuRef}
                                    key={btn.name}
                                    menuStyles={{ overflow: 'initial' }}
                                    menuContentStyles={{ padding: 0, overflow: 'initial' }}
                                    one={true}
                                    label={
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
                                            <Icon type={btn.icon} width={18} height={18} /> <span>{btn.name}</span>
                                        </div>
                                    }
                                >
                                    <MenuItem
                                        className={cx.menuItemColorPicker}
                                        label={onClose => {
                                            return (
                                                <MenuButtonColorPicker
                                                    onChange={color => onClick(btn, color)}
                                                    onClose={onClose}
                                                    value={btn.type === 'bgcolor' ? bgColor : borderColor}
                                                />
                                            );
                                        }}
                                    />
                                </Menu>
                            );
                        }

                        return (
                            <MenuItem
                                key={btn.name}
                                onClick={() => onClick(btn)}
                                disabled={btn.disabled}
                                label={onClose => (
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 6 }} onClick={onClose}>
                                        <Icon type={btn.icon} width={18} height={18} /> <span>{btn.name}</span>
                                    </div>
                                )}
                            />
                        );
                    })}
                </Menu>
            </div>
        </BubbleMenuView>
    );
};
