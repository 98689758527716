import React from 'react';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { useEditorContext } from '../../context';
import Icon, { Icons } from 'uikit/icon';

export const BubbleMenuTableBottomMenu = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const addRowAfter = e => {
        e.preventDefault();
        e.stopPropagation();

        setCurrentMenu(undefined);
        editor?.chain().addRowToEnd().run();
    };

    const getItemPosition = () => {
        if (!editor?.isActive('table')) {
            return {};
        }

        let nearestParent = editor?.state.selection.$anchor;

        if (editor?.state.selection.node?.type.name !== 'table') {
            nearestParent = findParentNodeClosestToPos(nearestParent, node => {
                return node.type.name === 'table';
            });
        }

        if (!nearestParent) {
            return {};
        }

        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const editorBoundingClientRect = document.getElementById('editor-content').getBoundingClientRect();
        const boundingClientRect = wrapperDomNode.querySelector('table').getBoundingClientRect();

        return {
            position: 'absolute',
            top: boundingClientRect.top - editorBoundingClientRect.top + boundingClientRect.height + 6 + 'px',
            left: boundingClientRect.left - editorBoundingClientRect.left + boundingClientRect.width / 2 - 8 + 'px',
        };
    };

    return (
        <div
            style={{
                display: editor?.isActive('table') ? 'flex' : 'none',
                alignItems: 'center',
                cursor: 'pointer',
                ...getItemPosition(),
            }}
        >
            <Icon type={Icons.EditorIconTableAdd} width={16} height={16} onClick={addRowAfter} />
        </div>
    );
};
