import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { selectGlobalPermActions, selectSessionHasBeenFetched } from 'slice/authSlice';

export const useAuthorized = () => {
    const actions = useSelector(selectGlobalPermActions);
    const sessionHasBeenFetched = useSelector(selectSessionHasBeenFetched);

    const isAuthorizedAction = useCallback((declaredAuthorities = []) => {
        if (declaredAuthorities.length === 0) {
            return true;
        }

        // console.log(declaredAuthorities);
        // console.log(actions);

        return declaredAuthorities.some(auth => actions.includes(auth));
    }, [actions]);

    return { isAuthorizedAction, sessionHasBeenFetched };
};
