import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useMessage } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import { useUpload } from 'components/background-upload/upload-provider';
import cx from './file-change-dialog.module.scss';
import IconButton from 'uikit/icon-button';
import Loading from 'uikit/loading';

const FileChangeDialog = ({ uuid, handleClose, onFileChange }) => {
    const { addSuccess } = useMessage();
    const { upload } = useUpload();
    const [isUpload, setIsUpload] = useState(false);

    const replaceFile = async (files) => {
        setIsUpload(true);

        for (const f of files) {
            upload({ file: f, type: 'replace', fileId: uuid })
                .start()
                .then((res) => {
                    addSuccess('Файл заменен');
                    onFileChange(res.serverData);
                    setIsUpload(false);
                    handleClose();
                });
        }
    };

    return (
        <div className={cx.dropZoneWrapper}>
            <div className={cx.dropZoneHeader}>
                <div>Замена файл</div>
                <div className={cx.closeIcon} onClick={handleClose}>
                    <IconButton icon={<Icon type={Icons.CROSS} width={16} height={16} />} onClick={handleClose} />
                </div>
            </div>
            <Dropzone onDrop={replaceFile}>
                {({ getRootProps, getInputProps }) => (
                    <>
                        {isUpload && (
                            <div className={cx.loader}>
                                <Loading withOverlay={false} />
                            </div>
                        )}
                        {!isUpload && (
                            <div {...getRootProps({ className: cx.dropZone })}>
                                <input {...getInputProps()} />
                                <div>
                                    <Icon type={Icons.DOWNLOAD} width={24} height={24} color={'blue'} />
                                </div>
                                <p>Перетащите файл в эту область или нажмите для выбора файла с компьютера</p>
                            </div>
                        )}
                    </>
                )}
            </Dropzone>
        </div>
    );
};

export default FileChangeDialog;
