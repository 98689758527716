import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import buttons from 'model/global-menu/buttons';
import { setParent, defaultParentObject } from 'slice/treeSlice';
import { submenuWrapper } from 'containers/sub-menu-wrapper';
import cx from './add-page.module.scss';
import { MobileMenuContext } from 'containers/menu-wrapper/menu-wrapper';
import { useGlobalContext } from 'lib/hooks';

const menuConfig = [
    {
        to: '/add/project',
        name: 'Проект',
        auth: buttons.ADD_PROJECT
    },
    {
        to: '/add/section',
        name: 'Раздел',
        auth: buttons.ADD_SECTION
    },
    // {
    //     to: '/add/article/new',
    //     name: 'Статью (новый редактор)',
    //     auth: buttons.ADD_ARTICLE
    // },
    {
        to: '/add/article',
        name: 'Статью',
        auth: buttons.ADD_ARTICLE
    },
    {
        to: '/add/news',
        name: 'Новость',
        auth: buttons.ADD_NEWS
    },
    {
        id: 'scriptingMenuItem',
        to: '/add/script',
        name: 'Скрипт',
        auth: buttons.ADD_GROUP
    },
    {
        to: '/add/user',
        name: 'Участника',
        auth: buttons.ADD_USER
    },
    {
        to: '/add/file',
        name: 'Файлы',
        auth: buttons.ADD_FILE
    },
    {
        to: '/add/group',
        name: 'Группу',
        auth: buttons.ADD_GROUP
    },
    {
        to: '/add/importUsers',
        name: 'Импорт участников',
        auth: buttons.ADD_USER
    }
];

const AddPage = ({ children, location }) => {
    const { platform } = useGlobalContext();
    const dispatch = useDispatch();
    const { setMenuAlwaysOpen } = useContext(MobileMenuContext);

    // const menuButtons = useSelector(selectMenuButtons);

    React.useEffect(() => {
        if (location.pathname === '/add') {
            dispatch(setParent(defaultParentObject));
        }
    }, [dispatch, location.pathname])

    useEffect(() => {
        if (platform !== 'desktop' && setMenuAlwaysOpen){
            let blockedMenu = false;
            if (location.pathname === '/add') {
                setMenuAlwaysOpen(true);
                blockedMenu = true;
            } else {
                setMenuAlwaysOpen(false);
            }
            if (blockedMenu) {
                return () => setMenuAlwaysOpen(false);
            }
        }
    }, [location.pathname, platform, setMenuAlwaysOpen])

    return (
        <div className={cx.addPageWrapper}>
            {children}
        </div>
    );
};

export default submenuWrapper(menuConfig, AddPage);
