import React from 'react';
import { findParentNodeClosestToPos } from '@tiptap/core';
import { MenuButton } from '../Menu/MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';
import cx from '../../editor.module.scss';

export const BubbleMenuTable = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const getItemPosition = () => {
        if (!editor?.isActive('table')) {
            return {};
        }

        let nearestParent = editor?.state.selection.$anchor;

        if (editor?.state.selection.node?.type.name !== 'table') {
            nearestParent = findParentNodeClosestToPos(nearestParent, node => {
                return node.type.name === 'table';
            });
        }

        if (!nearestParent) {
            return {};
        }

        const wrapperDomNode = editor?.view.nodeDOM(nearestParent.pos);

        if (!wrapperDomNode) {
            return {};
        }

        const editorBoundingClientRect = document.getElementById('editor-content').getBoundingClientRect();
        const boundingClientRect = wrapperDomNode.querySelector('table').getBoundingClientRect();

        return {
            position: 'absolute',
            top: boundingClientRect.top - editorBoundingClientRect.top + boundingClientRect.height + 30 + 'px',
            left: boundingClientRect.left - editorBoundingClientRect.left + boundingClientRect.width / 2 - 16 + 'px',
        };
    };

    return (
        <div
            className={cx.bubbleMenu}
            style={{
                display: editor?.isActive('table') ? 'flex' : 'none',
                boxShadow: '0 8px 20px rgba(0, 0, 0, 0.12)',
                ...getItemPosition(),
            }}
        >
            <MenuButton
                className={cx.bubbleMenuButton}
                icon={Icons.EditorIconTrash}
                onClick={() => {
                    setCurrentMenu(undefined);
                    editor.commands.deleteNode('table');
                }}
            />
        </div>
    );
};
