import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from '@reach/router';
import { useDialog, useMessage } from 'lib/hooks';
import { TRAINING_RATING_SCALES } from 'lib/config/constant';
import { TrainingNavigation } from 'components/training';
import { Confirmation } from 'components/confirmation/confirmation';
import Filter from 'uikit/filter/filter';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import api from 'api';
import cx from './training-tab-all.module.scss';

export default function TrainingTabAll() {
    const navigate = useNavigate();

    const { addError } = useMessage();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [tests, setTests] = useState([]);

    const onDeleteTest = async (id) => {
        openDialog({
            title: 'Удаление теста',
            text: (<span>Вы действительно хотите удалить тест?</span>),
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Подтвердить',
            onClose: closeDialog,
            onSubmit: async () => {
                try {
                    closeDialog();
                    await api.training.deleteTest(id);

                    const response = await api.training.getTests();
                    setTests(response);
                } catch (e) {
                    console.log(e);
                    addError('Сервис недоступен. Пожалуйста попробуйте позже.');
                }
            }
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.training.getTests();
                setTests(response);
            } catch (e) {
                console.log(e);
                addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            }
        };
        fetchData();
    }, [addError]);

    return (
        <div className={cx.trainingAll}>
            <Confirmation {...dialogState}/>
            <TrainingNavigation/>
            <div className={cx.trainingAllContainer}>
                <h1>Все тесты</h1>
                <div className={cx.trainingAllFilter}>
                    <Filter id="trainingAllFilter" search="Поиск..." searchText="" onSearch={(p) => console.log(p)}
                            add="Создать тест" onAdd={() => navigate('/training/test')}/>
                </div>
                <div className={cx.trainingAllItems}>
                    {tests.map((test, i) => {
                        return (
                            <div key={i} className={cx.trainingAllItem}>
                                <div className={cx.trainingAllItemTop}>
                                    <img src={test['image'] ? test['image'] : '/img/article-card-cover.jpg'} alt={test['title']}/>
                                    <div className={cx.trainingAllItemContent}>
                                        <Link to={'/training/test/' + test['id']}>
                                            <h3>{test['title']}</h3>
                                        </Link>
                                        <p>{test['description']}</p>
                                    </div>
                                </div>
                                <ul className={cx.trainingAllItemBottom}>
                                    <li>
                                        <img src={test['author']['image']
                                            ? api.upload.getImage(test['author']['image'], false, 128)
                                            : '/img/avatar.png'}
                                             alt={test['author']['firstName'] + ' ' + test['author']['lastName']}/>
                                        <div>
                                            <span>Автор</span>
                                            <p>{test['author']['firstName'] + ' ' + test['author']['lastName']}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Дата создания</span>
                                            <p>{new Date(test['createdDate']).toLocaleString()}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Оценивание</span>
                                            <p>{TRAINING_RATING_SCALES.find(p => p['value'] === test['gradingType'])['label']}</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Содержание</span>
                                            <p>{test['questionsCount']} вопросов</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>Назначен</span>
                                            <p>{test['usersCount']} участников</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className={cx.trainingAllItemMenu}>
                                    <IconButton icon={<Icon type={Icons.EDIT_PEN} width={15} height={15}/>}
                                                link={'/training/test/' + test['id'] + '/edit'}/>
                                    <IconButton icon={<Icon type={Icons.TRASH} width={15} height={15}/>}
                                                onClick={() => onDeleteTest(test['id'])}/>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
