import ApiClient from './api-axios';

export default class TrainingApi extends ApiClient {
    getTree = async () => {
        return this.get('/theme/tree');
    };
    getThemes = async () => {
        return this.get('/theme/');
    };
    getTheme = async (id) => {
        return this.get(`/theme/${id}`);
    };
    postTheme = async (title, description, questions) => {
        return this.post('/theme/', { title, description, questions });
    };
    putTheme = async (id, title, description, questions) => {
        return this.put('/theme/', { id, title, description, questions });
    };
    deleteTheme = async (id) => {
        return this.deleteRequest(`/theme/${id}`);
    };

    getQuestions = async () => {
        return this.get('/question/');
    };
    getQuestion = async (id) => {
        return this.get(`/question/${id}`);
    };
    postQuestion = async (image, title, mark, type, theme, answers) => {
        return this.post('/question/', { image, title, mark, type: type.value, themeId: theme.value, answers });
    };
    putQuestion = async (id, image, title, mark, type, theme, answers) => {
        return this.put('/question/', { id, image, title, mark, type: type.value, themeId: theme.value, answers });
    };
    deleteQuestion = async (id) => {
        return this.deleteRequest(`/question/${id}`);
    };

    getOpenTests = async () => {
        return this.get('/test/open');
    };
    getTests = async () => {
        return this.get('/test/');
    };

    getTest = async (id) => {
        return this.get(`/test/${id}`);
    };
    postTest = async (image, title, description, type, isSkip, testTime, questionTime, isShowCorrect,
                      isShowCorrectAfter, gradingType, isPassage, gradingRange, endTestMessage, endTestSuccessMessage,
                      endTestFailureMessage, isMixQuestions, isMixAnswers, questions) => {
        return this.post('/test/', { image, title, description, type, isSkip, testTime, questionTime,
            isShowCorrect, isShowCorrectAfter, gradingType, isPassage, gradingRange, endTestMessage,
            endTestSuccessMessage, endTestFailureMessage, isMixQuestions, isMixAnswers, questions });
    };
    putTest = async (id, image, title, description, type, isSkip, testTime, questionTime, isShowCorrect,
                     isShowCorrectAfter, gradingType, isPassage, gradingRange, endTestMessage, endTestSuccessMessage,
                     endTestFailureMessage, isMixQuestions, isMixAnswers, questions) => {
        return this.put('/test/', { id, image, title, description, type, isSkip, testTime, questionTime,
            isShowCorrect, isShowCorrectAfter, gradingType, isPassage, gradingRange, endTestMessage,
            endTestSuccessMessage, endTestFailureMessage, isMixQuestions, isMixAnswers, questions });
    };
    deleteTest = async (id) => {
        return this.deleteRequest(`/test/${id}`);
    };

    getTestUsers = async (id) => {
        return this.get(`/test/${id}/access`);
    };
    postTestUsers = async (testId, users, openDate, closeDate, tryCount, postponeCount, isForcibly, isNotification) => {
        return this.post('/test/access', { testId, users, openDate, closeDate, tryCount, postponeCount, isForcibly, isNotification});
    };

    postTestResult = async (id, start, finish, questions) => {
        return this.post('/test/result', { id, start, finish, questions });
    };

    getGlobalReport = async (id) => {
        return this.get(`report/global/${id}`);
    };
    getEfficiencyReport = async (id, from, to) => {
        return this.get(`report/efficiency/${id}/${from}/${to}`);
    };
    getUserReport = async (id, user, tryIndex) => {
        return this.get(`report/user/${id}/${user}/${tryIndex}`);
        // return [
        //     {
        //         question: 'Назовите основные причины отказа клиента в покупке',
        //         answers: 'Вариант 1 (Правильно)',
        //         time: '01:25'
        //     },
        //     {
        //         question: 'Основная причина отказа клиента',
        //         answers: 'Сомнение (Правильно)',
        //         time: '02:37'
        //     },
        //     {
        //         question: 'Сопоставьте правильнвый вариант для каждого пункта',
        //         answers: '1. Линейный - Произвольный (правильно)\n' +
        //             '2. Плюс - Плохо (неправильно)\n' +
        //             '3. Хорошо - минус (неправильно)\n' +
        //             '4. Гран - Софт (правильно)',
        //         time: '05:47'
        //     },
        //     {
        //         question: 'Дайте ответ на данный вопрос с открытым ответом',
        //         answers: 'Lorem Ipsum является текст-заполнитель обычно используется в графических, печать и издательской индустрии для предварительного просмотра макета и визуальных макетах.',
        //         time: '01:00'
        //     },
        //     {
        //         question: 'Расставьте версии в правильном порядке:',
        //         answers: '1. Windows 95 (правильно)\n' +
        //             '2. Windows XP (правильно)\n' +
        //             '3. Windows 7 (правильно)\n' +
        //             '4. Windows 10 (правильно)',
        //         time: '00:15'
        //     }
        // ];
    };
}