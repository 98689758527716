import ApiClient from './api-axios';

export default class ArticleApi extends ApiClient {
    initArticle = async () => {
        return await this.post('article/initialize');
    };

    getArticles = async (page = 0, size = 20, sort = 'modifyTime,desc', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append('authorLogin.in', filters['authorLogin.in'].map((filter) => filter.value));
        }

        if (filters['readied.in'] && filters['readied.in']['value']) {
            params.append('readied.in', filters['readied.in']['value']);
        }

        return await this.get('article?' + params.toString());
    };

    getArticlesDraft = async ({ page = 0, size = 20, sort = 'createTime,desc', filters = {}, search }) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        return await this.get('article-draft?' + params.toString());
    };

    getDelayedArticles = async ({ page = 0, size = 20, sort = 'publicationTime,desc', search = '', filters = {} }) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['publicationTime']) {
            if (filters['publicationTime']['from'] !== null) {
                params.append('publicationTime.greaterOrEqualThan', new Date(filters['publicationTime']['from']).toISOString());
            }
            if (filters['publicationTime']['to'] !== null) {
                params.append('publicationTime.lessOrEqualThan', new Date(filters['publicationTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        return await this.get('delayed-article/user?' + params.toString());
    };

    getLogo = (uuid, async = false, scale = 512) => {
        if (async) {
            return async () => await this.get(
                'article/logo',
                { uuid },
                false,
                'arraybuffer',
                {
                    'Accept': 'image/png',
                    'Content-Type': 'image/png'
                }
            );
        }

        return this.prefix + 'article/logo?uuid=' + uuid + '&scale=' + scale;
    };

    getDraftLogo = (uuid, async = false) => {
        if (async) {
            return async () => await this.get(
                'article-draft/logo',
                { uuid },
                false,
                'arraybuffer',
                {
                    'Accept': 'image/png',
                    'Content-Type': 'image/png'
                }
            );
        }

        return this.prefix + 'article-draft/logo?uuid=' + uuid;
    };

    postArticle = async (article) => {
        return await this.post('article', article);
    };

    postArticleDraft = async (article) => {
        return await this.post('article-draft', article);
    };

    postDelayedArticle = async (article) => {
        return await this.post('delayed-article', article);
    }

    editArticle = async (article) => {
        return await this.post('article/edit', article);
    };

    editArticleDraft = async (article) => {
        return await this.post('article-draft/edit', article);
    };

    editDelayedArticle = async (article) => {
        return await this.post('delayed-article/edit', article);
    };

    deleteArticle = async (id) => {
        return await this.post('article/delete/' + id, {});
    };

    deleteArticleDraft = async (id) => {
        return await this.post('article-draft/delete/' + id, {});
    };

    deleteDelayedArticle = async (id) => {
        return await this.deleteRequest('delayed-article/' + id, {});
    };

    getProjectArticles = async (project, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append('authorLogin.in', filters['authorLogin.in'].map((filter) => filter.value));
        }

        if (filters['readied.in'] && filters['readied.in']['value']) {
            params.append('readied.in', filters['readied.in']['value']);
        }

        if (filters['containsAttachment.equals'] && filters['containsAttachment.equals']['value']) {
            params.append('containsAttachment.equals', filters['containsAttachment.equals']['value']);
        }

        return await this.get(`article/project/${project}?` + params);
    };

    getProjectDelayedArticles = async (projectUuid, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['publicationTime']) {
            if (filters['publicationTime']['from'] !== null) {
                params.append('publicationTime.greaterOrEqualThan', new Date(filters['publicationTime']['from']).toISOString());
            }
            if (filters['publicationTime']['to'] !== null) {
                params.append('publicationTime.lessOrEqualThan', new Date(filters['publicationTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        return await this.get(`delayed-article/project/${projectUuid}?` + params);
    };

    getSectionArticles = async (section, page = 0, size = 20, sort = '', search = '', filters = {}) => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        if (filters['createTime']) {
            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', new Date(filters['createTime']['from']).toISOString());
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', new Date(filters['createTime']['to']).toISOString());
            }
        }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append('authorLogin.in', filters['authorLogin.in'].map((filter) => filter.value));
        }

        if (filters['readied.in'] && filters['readied.in']['value']) {
            params.append('readied.in', filters['readied.in']['value']);
        }

        if (filters['containsAttachment.equals'] && filters['containsAttachment.equals']['value']) {
            params.append('containsAttachment.equals', filters['containsAttachment.equals']['value']);
        }

        return await this.get(`article/section/${section}?` + params);
    };

    getArticle = async (uuid, searchQuery) => {

        if (searchQuery) {
            return await this.get(`article/${uuid}?searchQuery=${searchQuery.toString()}`);
        } else {
            return await this.get(`article/${uuid}`);
        }
    };

    getArticleLogo = async (uuid) => {
        return await this.get('article/logo?uuid=' + uuid);
    };

    getArticleDraft = async uuid => {
        return await this.get(`article-draft/${uuid}`);
    };

    getDelayedArticle = async uuid => {
        return await this.get(`delayed-article/${uuid}`);
    };

    publishArticleDraft = async data => {
        return await this.post('article-draft/save-and-publish', data);
    };

    publishDelayedArticle = async uuid => {
        return await this.post(`delayed-article/publish/${uuid}`);
    }

    readArticle = async (uuid, time) => {
        return await this.post(`article/${uuid}/read?readDurationMillis=${time * 1000}`);
    };

    readArticleDraft = async (_uuid, _time) => {
        // return await this.post(`article-draft/${uuid}/read?readDurationMillis=${time * 1000}`);
    };

    getReadArticleTime = async (uuid) => {
        return await this.get(`article/${uuid}/read`);
    };

    getReadArticleDraftTime = async (_uuid) => {
        // return await this.get(`article-draft/${uuid}/read`);
    };

    validateTitle = async (parentSectionId, title) => {
        return await this.get(`article/validate/title?parentSectionId=${parentSectionId}&title=${encodeURIComponent(title)}`);
    };

    getDelayedArticlesCount = async () => {
        return await this.get('delayed-article/count');
    }
}
