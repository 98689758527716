import React, { useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Icon, { Icons } from 'uikit/icon';
import cs from './dropdown-list-item.module.scss';

const DropdownListItem = ({ id, children, iconType, iconColor, text, ...rest }) => {
  const itemRef = useRef(null);
  const [timer1, setTimer1] = React.useState();
  const [timer2, setTimer2] = React.useState();

  const [hover, setHover] = React.useState(false);
  const [subHover, setSubHover] = React.useState(false);

  const position = itemRef.current?.getBoundingClientRect();

  const onSubmenuOver = () => {
    if (timer2) {
      clearTimeout(timer2);
    }

    setSubHover(true);
  };

  const onSubmenuOut = () => {
    let t = setTimeout(() => {
      setSubHover(false);
    }, 40);
    setTimer2(t);
  };

  const onMenuOver = () => {
    if (timer1) {
      clearTimeout(timer1);
    }

    setHover(true);
  };

  const onMenuOut = () => {
    let t = setTimeout(() => {
      setHover(false);
    }, 40);

    setTimer1(t);
  };
  console.log(children);
  return (
    <div ref={itemRef} id={id} className={cs.base}>
      <div className={cs.menuItem} onMouseOver={onMenuOver} onMouseOut={onMenuOut} {...rest}>
        <div className={cs.titleWrapper}>
          {iconType && <Icon width={18} height={18} type={iconType} color={iconColor} />}
          <div>{text}</div>
        </div>
        {children?.some(c => c) && <Icon width={14} height={14} type={Icons.ARROW_RIGHT} />}
      </div>

      {children?.some(c => c) &&
        (hover || subHover) &&
        createPortal(
          <div
            className={cs.submenu}
            style={{ top: position?.top, left: position.right }}
            onMouseOver={onSubmenuOver}
            onMouseOut={onSubmenuOut}
          >
            {children}
          </div>,
          document.body
        )}
    </div>
  );
};

DropdownListItem.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any,
  iconType: PropTypes.any,
  text: PropTypes.string.isRequired,
};

export default DropdownListItem;
