import React, { useState, useEffect, useCallback } from 'react';
import { useMessage } from 'lib/hooks';
import PrivatePage from 'containers/private-page-wrapper';
import Information from './Information';
import ActiveLicenses from './ActiveLicenses';
import AccessManagement from './AccessManagement';
import api from 'api';
import cs from './LicensePage.module.scss';

const keyCodes = {
    '`': 'е',
    'q': 'й',
    'w': 'ц',
    'e': 'у',
    'r': 'к',
    't': 'е',
    'y': 'н',
    'u': 'г',
    'i': 'ш',
    'o': 'щ',
    'p': 'з',
    '[': 'х',
    ']': 'ъ',
    'a': 'ф',
    's': 'ы',
    'd': 'в',
    'f': 'а',
    'g': 'п',
    'h': 'р',
    'j': 'о',
    'k': 'л',
    'l': 'д',
    ';': 'ж',
    '\'': 'э',
    'z': 'я',
    'x': 'ч',
    'c': 'с',
    'v': 'м',
    'b': 'и',
    'n': 'т',
    'm': 'ь',
    ',': 'б',
    '.': 'ю'
};

const changeKeyCode = (text, isReverse = false) => {
    let str = text.split('');
    let codes = {};

    if (isReverse) {
        Object.entries(keyCodes).forEach(([key, value]) => codes[value] = key);
    } else {
        codes = keyCodes;
    }

    for (let i = 0; i < str.length; i++) {
        str[i] = Object.hasOwn(codes, str[i]) ? codes[str[i]] : str[i];
    }

    return str.join('');
};
const searchByList = (text, list) => {
    return list.filter(
        p => p['user']['login'].toLowerCase().replace('ё', 'е').indexOf(text) !== -1
            || p['user']['firstName'].toLowerCase().replace('ё', 'е').indexOf(text) !== -1
            || p['user']['lastName'].toLowerCase().replace('ё', 'е').indexOf(text) !== -1
            || (p['user']['firstName'] + ' ' + p['user']['lastName']).toLowerCase()
                .replace('ё', 'е').indexOf(text) !== -1
            || (p['user']['lastName'] + ' ' + p['user']['firstName']).toLowerCase()
                .replace('ё', 'е').indexOf(text) !== -1
            || (p['user']['firstName'] + ' ' + p['user']['middleName'] + ' ' + p['user']['lastName']).toLowerCase()
                .replace('ё', 'е').indexOf(text) !== -1
            || (p['user']['lastName'] + ' ' + p['user']['firstName'] + ' ' + p['user']['middleName']).toLowerCase()
                .replace('ё', 'е').indexOf(text) !== -1);
};

const LicensePage = ({ hasAnyAuthorities }) => {
    const { addError } = useMessage();

    const [isLoaded, setIsLoaded] = useState(false);
    const [licenseInfo, setLicenseInfo] = useState({});

    const [activeLicenses, setActiveLicenses] = useState([]);
    const [activeLicensesTotal, setActiveLicensesTotal] = useState(0);

    const [filteredActiveLicenses, setFilteredActiveLicenses] = useState([]);
    const [filteredActiveLicensesTotal, setFilteredActiveLicensesTotal] = useState(0);

    const [allActiveLicenses, setAllActiveLicenses] = useState([]);
    const [allActiveLicensesTotal, setAllActiveLicensesTotal] = useState(0);

    const [filteredAllActiveLicenses, setFilteredAllActiveLicenses] = useState([]);
    const [filteredAllActiveLicensesTotal, setFilteredAllActiveLicensesTotal] = useState(0);

    const getLicenseInfo = useCallback(async () => {
        try {
            const data = await api.license.getLicenseInfo();

            if (data) {
                setLicenseInfo(Object.assign({}, data));
            }
        } catch (error) {
            console.log(error);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError]);
    const getActiveLicenses = useCallback(async (page, size, sort, search, filters) => {
        try {
            if (activeLicensesTotal !== 0 && page === 0 && size === 10) {
                let licenses = Object.assign([], activeLicenses);

                if (search) {
                    licenses = licenses.filter(p => p['licenseName'].toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || p['companyName'].toLowerCase().indexOf(search.toLowerCase()) !== -1);
                }

                if (sort['by']) {
                    licenses = licenses.sort((a, b) => a[sort['by']] > b[sort['by']]
                        ? (sort['desc'] ? -1 : 1) : (sort['desc'] ? 1 : -1));
                }

                setFilteredActiveLicensesTotal(licenses.length);
                setFilteredActiveLicenses(licenses);

                return;
            }

            const licenses = await api.license.getActiveLicenses(page, size, sort, search, filters);

            setActiveLicensesTotal(licenses.length);
            setActiveLicenses(licenses);

            setFilteredActiveLicensesTotal(licenses.length);
            setFilteredActiveLicenses(licenses);
        } catch (error) {
            console.log(error);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError, activeLicenses, activeLicensesTotal]);
    const getAllActiveLicenses = useCallback(async (page, size, sort, search, filters) => {
        try {
            if (allActiveLicensesTotal !== 0 && page === 0 && size === 10) {
                let subscriptions = Object.assign([], allActiveLicenses);

                if (search) {
                    const searchText = search.toLowerCase().replace('ё', 'е').replace('`', 't');

                    const firstSearch = changeKeyCode(searchText, false);
                    const secondSearch = changeKeyCode(searchText, true);

                    subscriptions = searchByList(firstSearch, subscriptions)
                        .concat(searchByList(secondSearch, subscriptions));
                }

                if (sort['by']) {
                    subscriptions = subscriptions.sort((a, b) => a[sort['by']] > b[sort['by']]
                        ? (sort['desc'] ? -1 : 1) : (sort['desc'] ? 1 : -1));
                }

                setFilteredAllActiveLicenses(subscriptions);
                setFilteredAllActiveLicensesTotal(subscriptions.length);

                return;
            }

            const subscriptions = await api.license.getAllActiveLicenseSubscriptions(page, size, sort, search, filters);

            setAllActiveLicenses(subscriptions);
            setAllActiveLicensesTotal(subscriptions.length);

            setFilteredAllActiveLicenses(subscriptions);
            setFilteredAllActiveLicensesTotal(subscriptions.length);
        } catch (error) {
            console.log(error);
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
        }
    }, [addError, allActiveLicenses, allActiveLicensesTotal]);

    useEffect(() => {
        if (!isLoaded) {
            getLicenseInfo();
            setIsLoaded(true);
        }
    }, [isLoaded, getLicenseInfo, getActiveLicenses]);

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <div className={cs.base}>
                <h1>Информация</h1>
                <Information licenseInfo={licenseInfo}/>
                <ActiveLicenses
                    activeLicenses={filteredActiveLicenses}
                    activeLicensesTotal={filteredActiveLicensesTotal}
                    getActiveLicenses={getActiveLicenses}
                    getLicenseInfo={getLicenseInfo}
                />
                <AccessManagement
                    activeLicenses={activeLicenses}
                    allActiveLicenses={filteredAllActiveLicenses}
                    allActiveLicensesTotal={filteredAllActiveLicensesTotal}
                    getAllActiveLicenses={getAllActiveLicenses}
                    getLicenseInfo={getLicenseInfo}
                />
            </div>
        </PrivatePage>
    );
};

export default LicensePage;
