import ApiClient from './api-axios';
import { dateToISOWithZone } from 'lib/helpers/dateToISOWithZone';
import { PAGE_SIZE } from 'lib/config/constant';

export default class FavoritesApi extends ApiClient {
    getFavoritesCounts = async () => {
        return await this.get('/count');
    };

    getFavoritesArticles = async ({ page = 0, size = PAGE_SIZE, search = '', sort = 'modifyTime,desc', filters = {} }) => {
        const params = new URLSearchParams({ page, size, sort: 'article.' + sort });

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
          let today = new Date();
          
          if (filters['createTime']['from'] !== null) {
              params.append('createTime.greaterOrEqualThan', dateToISOWithZone(new Date(filters['createTime']['from'])));
          }
          if (filters['createTime']['to'] !== null) {
              params.append('createTime.lessOrEqualThan', dateToISOWithZone(new Date(filters['createTime']['to'] || today)));
          }
      }

        if (filters['lifeTime.greaterOrEqualThan']) {
            params.append('lifeTime.greaterOrEqualThan', new Date(filters['lifeTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['lifeTime.lessOrEqualThan']) {
            params.append('lifeTime.lessOrEqualThan', new Date(filters['lifeTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append('authorLogin.in', filters['authorLogin.in'].map((filter) => filter.value));
        }

        return await this.get('/articles?' + params.toString());
    };

    getFavoritesNews = async ({ page = 0, size = PAGE_SIZE, search = '', sort = 'modifyTime,desc', filters = {} }) => {
        const params = new URLSearchParams(Object.assign({ page, size, sort: 'news.' + sort }));

        if (search) {
            params.append('title.contains', search);
            params.append('orSearch.specified', 'true');
        }

        if (filters['createTime']) {
            let today = new Date();

            if (filters['createTime']['from'] !== null) {
                params.append('createTime.greaterOrEqualThan', dateToISOWithZone(new Date(filters['createTime']['from'])));
            }
            if (filters['createTime']['to'] !== null) {
                params.append('createTime.lessOrEqualThan', dateToISOWithZone(new Date(filters['createTime']['to'] || today)));
            }
        }

        if (filters['expireTime.greaterOrEqualThan']) {
            params.append('expireTime.greaterOrEqualThan', new Date(filters['expireTime.greaterOrEqualThan']).toISOString());
        }

        if (filters['expireTime.lessOrEqualThan']) {
            params.append('expireTime.lessOrEqualThan', new Date(filters['expireTime.lessOrEqualThan']).toISOString());
        }

        if (filters['authorLogin.in']) {
            params.append('authorLogin.in', filters['authorLogin.in'].map((filter) => filter.value));
        }

        return await this.get('/news?' + params.toString());
    };

    getFavoritesProjects = async ({ page = 0, size = PAGE_SIZE, sort = 'createTime,desc' }) => {
        return await this.get(`/projects?page=${page}&size=${size}&sort=project.${sort}`);
    };

    addArticleToFavorites = async (uuid) => {
        return await this.post(`/article/${uuid}`);
    };

    addNewsToFavorites = async (uuid) => {
        return await this.post(`/news/${uuid}`);
    };

    addProjectToFavorites = async (uuid) => {
        return await this.post(`/project/${uuid}`);
    };

    deleteArticleFromFavorites = async (uuid) => {
        return await this.deleteRequest(`/article/${uuid}`);
    };

    deleteNewsFromFavorites = async (uuid) => {
        return await this.deleteRequest(`/news/${uuid}`);
    };

    deleteFromFavorites = async (uuid) => {
        return await this.deleteRequest(`/${uuid}`);
    };

    deleteProjectFromFavorites = async (uuid) => {
        return await this.deleteRequest(`/project/${uuid}`);
    };
}