import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Handle } from 'react-flow-renderer';
import classNames from 'classnames';
import Icon, { Icons } from 'uikit/icon';
import { textValidator } from "lib/util/validators.util";
import cs from './node-components.module.scss';

const NodeOverlay = ({ onClick }) => {
    return (
        <div className={cs.nodeContainerOverlay} onClick={onClick}></div>
    )
}

// Валидация текста
const watchTextCharacters = (text, limit, setErrorFn) => {
    if (textValidator(text, limit, true, false)) {
        setErrorFn(textValidator(text, limit, true, false));
        return;
    }
    setErrorFn(false);
}

// Компонент шага
export const CustomNodeComponent = ({ data, onAdd, onClick, onLinkClick, active, isBindable, isLinked, isHovered}) => {
    const { id, text, targetStep, position } = data;
    const onAddRef = useRef(onAdd);
    const [error, setError] = useState('');

    useEffect(() => {
        onAddRef.current = onAdd;
    }, [onAdd]);

    useEffect(() => {
        watchTextCharacters(text, 1500, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    return (
        <div className={classNames(cs.nodeContainer, {[cs.nodeContainerActive]: active}, {[cs.nodeContainerLinked]: isLinked || isHovered})} data-testid="ScriptEditorStep">
            <NodeOverlay onClick={() => onClick?.(id)} />
            <Handle type="source" isConnectable={false} id='handle-bottom-right' className={cs.nodeHandleLinkFrom} />
            <Handle type="source" position="bottom" id='handle-bottom' isConnectable={false} className={cs.nodeHandle} />
            <div className={cs.nodeText}>
                {targetStep && (
                    <div className={cs.nodeTargetStep}>
                        <Icon type={Icons.FLAG} color={'white'} width={12} height={12} />
                    </div>
                )}
                {text.trim().length > 0 && <span className={cs.nodeTextSpan}>{text.trim()}</span>}
                {error && <Icon type={Icons.ALERT} className={cs.iconAlert} tooltip={error} tooltipPlacement={'right'} color={'red'} width={19} height={19} />}
            </div>
            {isBindable &&
                <div className={cs.linkButtonContainer} onClick={() => onLinkClick(id)}>
                    <Icon type={Icons.LINK} tooltip={'Нажмите, чтобы связать шаг с существующим'} tooltipPlacement={'right'} color={'white'} width={9} height={9} />
                </div>}
            <div onClick={() => onAdd?.({ id, position })} className={cs.addButtonContainer}>
                <Icon type={Icons.PLUS_ADD} tooltip={'Создать условие ведущее на новый шаг'} tooltipPlacement={'right'} color={'white'} width={15} height={15} />
            </div>
            <Handle type="target" id='handle-center-top' position="top" isConnectable={false} className={cs.nodeHandle} />
            <Handle type="target" id='handle-right-center' isConnectable={false} className={cs.nodeHandleLinkTo} />
        </div>
    );
};

CustomNodeComponent.propTypes = {
  data: PropTypes.object,
  onAdd: PropTypes.func,
  onClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  active: PropTypes.bool,
  isBindable: PropTypes.bool,
};

// Компонент условия
export const ConditionNodeComponent = ({ type, nodeId, text, onClick, active }) => {
    const [error, setError] = useState('');

    let rootClassNames = classNames(cs.nodeNeutralContainer, {[cs.nodeNeutralContainerActive]: active});
    switch (type) {
        case 'positive':
            rootClassNames = classNames(cs.nodePositiveContainer, {[cs.nodePositiveContainerActive]: active});
            break;
        case 'negative':
            rootClassNames = classNames(cs.nodeNegativeContainer, {[cs.nodeNegativeContainerActive]: active});
            break;
        default:
            break;
    }

    useEffect(() => {
        watchTextCharacters(text, 254, setError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    return (
        <div className={rootClassNames} data-testid="ScriptEditorCondition">
            <NodeOverlay onClick={() => onClick?.(nodeId, true)} />
            <Handle type="target" position="top" isConnectable={false} className={cs.nodeHandle} />
            <div className={cs.nodeText}>
                {text.trim().length > 0 && <span className={cs.nodeTextSpan}>{text.trim()}</span>}
                {error && <Icon type={Icons.ALERT} className={cs.iconAlert} tooltip={error} tooltipPlacement={'right'} color={'red'} width={19} height={19} />}
            </div>
            <Handle type="source" position="bottom" id="a" isConnectable={false} className={cs.nodeHandle} />
        </div>
    );
}

ConditionNodeComponent.propTypes = {
  type: PropTypes.string,
  nodeId: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
};
