export const CATEGORY_NAMES = [
    'frequently_used',
    'custom',
    'people',
    'activity',
    'nature',
    'food',
    'travel',
    'objects',
    'symbols',
    'flags',
];

export const CATEGORY_TRANSLATE = {
    people: 'Смайлики и люди',
    activity: 'Действия',
    nature: 'Животные и природа',
    food: 'Еда и напитки',
    travel: 'Путешествия и места',
    objects: 'Объекты',
    symbols: 'Символы',
    flags: 'Флаги',
};
