import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Textarea from 'uikit/textarea';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import Checkbox from 'uikit/checkbox';
import Divider from 'uikit/divider';
import { FILES_TYPE_ICONS } from 'lib/config/constant';
import { humanFileSize } from 'lib/helpers';
import { useUpload } from 'components/background-upload/upload-provider';
import { NODE_TYPES } from 'components/trees';
import UploadModal from 'components/attachments/uploadModal';
import { useGlobalContext } from 'lib/hooks';
import AddLinkModal from './add-link-modal';
import AddDocumentModal from './add-document-modal';
import ImageUploadModal from './image-upload-modal';
import cs from '../create-script.module.scss';
import {FileViewDialog} from "components/file/file-view-dialog";
import classNames from 'classnames';

const getConditionMarker = (value) => {
    switch (value) {
        case 'neutral':
            return cs.conditionOptionMarkerNeutral;
        case 'positive':
            return cs.conditionOptionMarkerPositive;
        case 'negative':
            return cs.conditionOptionMarkerNegative;
        default:
            return cs.conditionOptionMarkerNeutral;
    }
  }

function ActiveNodeSettings({ activeNode, initId, onAddStep, onChange, onRemove, errors, watchTextCharacters, conditions, editConditionNode }) {
    const { upload } = useUpload();
    const { setFormDataChanged } = useGlobalContext();
    const activeNodeBackupRef = useRef(activeNode);
    const [isImageModal, setIsImageModal] = useState(false);
    const [isArticleModal, setIsArticleModal] = useState(false);
    const [isLinkModal, setIsLinkModal] = useState(false);
    const [isFileModal, setIsFileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isViewFileModal, setIsViewFileModal] = useState(false);

    const activeNodeRef = useRef(null);

    useEffect(() => {
        activeNodeRef.current = JSON.parse(JSON.stringify(activeNode));
        activeNodeRef.current.isStep = true;
    }, [activeNode]);

    useEffect(() => {
        const text = activeNodeRef.current.data.text.trim();

        watchTextCharacters(text);
    }, [activeNode, watchTextCharacters]);

    const onChangeStepText = (e) => {
        const text = e.trim();

        watchTextCharacters(text);
        activeNodeRef.current['data']['text'] = e;
        onChange(activeNodeRef.current);
    };

    const onTargetStepChange = (e) => {
        activeNodeRef.current['data']['targetStep'] = e.target.checked;
        onChange(activeNodeRef.current);
    };

    const onRemoveHandler = () => {
      onRemove(activeNodeRef.current);
    }

    const attachFiles = (files) => {
        activeNodeRef.current['files'] = files.map((f) => ({
            status: 'done',
            fileId: f.id || f.fileId,
            serverData: f,
        }));
        onChange(activeNodeRef.current);
    };

    const submitFiles = (e) => {
        setIsFileModal(false);

        const _updateActiveNode = async (file) => {
            const i = activeNodeRef.current.files.findIndex((f) => f.id === file.id);

            activeNodeRef.current['files'] = [
                ...activeNodeRef.current['files'].slice(0, i),
                file,
                ...activeNodeRef.current['files'].slice(i + 1),
            ];

            onChange(activeNodeRef.current);
        };

        const newFiles = [];
        for (const f of e.target.files) {
            const u = upload({ file: f, onUpdate: _updateActiveNode });
            newFiles.push(u);
            u.start();
        }

        activeNodeRef.current['files'] = activeNodeRef.current['files'].concat(newFiles);
        onChange(activeNodeRef.current);
    };

    const removeFile = (file) => {
        activeNodeRef.current['files'] = activeNodeRef.current['files'].filter((f) => f.fileId !== file.fileId);
        onChange(activeNodeRef.current);
    };

    const saveLink = (data) => {
        activeNodeRef.current['links'] = [...activeNodeRef.current['links'], data];
        onChange(activeNodeRef.current);
    };

    const removeLink = (data) => {
        activeNodeRef.current['links'] = activeNodeRef.current['links'].filter((link) => link.link !== data.link);
        onChange(activeNodeRef.current);
    };

    const saveDocuments = (data) => {
        const articles = data
            .filter((node) => node.nodeType === NODE_TYPES.ARTICLE)
            .map((article) => ({
                articleId: article.id,
                articleTitle: article.name,
                logoUuid: article.logoUuid,
            }));

        activeNodeRef.current['articles'] = articles;
        onChange(activeNodeRef.current);
        setIsArticleModal(false);
    };

    const removeArticle = (data) => {
        activeNodeRef.current['articles'] = activeNodeRef.current['articles'].filter((article) => article.articleId !== data.articleId);
        onChange(activeNodeRef.current);
    };

    const onImageUploadHandler = (data) => {
        const images = [];
        data.forEach((dataItem) => {
            // с доменом 'https://stag-kms.gran-soft.ru' в url изображения локально невозможно вывести его в разметке в <img .../>
            images.push({ imageLink: dataItem.url.replace('https://stag-kms.gran-soft.ru', ''), scriptUuid: `${dataItem.url}` })
        })

        activeNodeRef.current['images'] = [...activeNodeRef.current['images'], ...images];
        onChange(activeNodeRef.current);
        setIsImageModal(false);
    };

    const removeImage = (imageLink) => {
        activeNodeRef.current.images = activeNodeRef.current.images.filter((image) => image.imageLink !== imageLink);
        onChange(activeNodeRef.current);
    };

    const onFileClickHandler = (file) => {
        setSelectedFile(file);
        setIsViewFileModal(true);
    };

    const onRemoveCondition = (condition) => {
        const data = JSON.parse(JSON.stringify(condition));
        data.isStep = false;

        onRemove(data);
    };

    const onEditCondition = (conditionId) => {
        editConditionNode(conditionId);
    };

    useEffect(() => {
        if (!isViewFileModal) {
            setSelectedFile(null);
        }
    }, [isViewFileModal]);

    useEffect(() => {
        setFormDataChanged(
          !isEqual(activeNodeRef.current.data, activeNodeBackupRef.current.data) ||
          !isEqual(activeNodeRef.current.files, activeNodeBackupRef.current.files) ||
          !isEqual(activeNodeRef.current.images, activeNodeBackupRef.current.images) ||
          !isEqual(activeNodeRef.current.articles, activeNodeBackupRef.current.articles) ||
          !isEqual(activeNodeRef.current.links, activeNodeBackupRef.current.links)
        );
    }, [activeNode, setFormDataChanged]);

    return (
        <>
            <div className={cs.stepContainer} data-testid="activeNode">
                <div className={cs.stepHeading}>
                    <div className={cs.stepHeadingLabel} data-testid="activeNodeLabel">Шаг</div>
                    {!activeNode.root && (
                        <div className={cs.stepRemoveBtn} onClick={onRemoveHandler} data-testid="activeNodeDeleteBtn">
                            Удалить шаг
                        </div>
                    )}
                </div>
                <Textarea 
                    onChange={onChangeStepText} 
                    value={activeNode.data.text} 
                    className={cs.desc} 
                    label="Текст шага" 
                    rows="2" 
                    error={errors?.textCharacters}
                    data-testid="activeNodeName"
                />
                <div className={cs.attachContainer}>
                    <IconButton icon={<Icon width={14} height={14} type={Icons.PHOTO} onClick={() => setIsImageModal(true)} />} id="activeNodeImageModalBtn" />
                    <IconButton icon={<Icon width={14} height={14} type={Icons.NEWS} />} onClick={() => setIsArticleModal(true)} id="activeNodeArticleModalBtn" />
                    <IconButton icon={<Icon width={14} height={14} type={Icons.LINK} onClick={() => setIsLinkModal(true)} />} id="activeNodeLinkModalBtn" />
                    <IconButton icon={<Icon width={14} height={14} type={Icons.CLIP} />} onClick={() => setIsFileModal(true)} id="activeNodeFileModalBtn" />
                </div>
                <div data-testid="activeNodeFilesWrapper">
                    {activeNode.files?.map((file, index) => {
                        return (
                            <div key={file.fileId ?? `${file?.file?.path}__${index}`} className={cs.fileWrapper} >
                                <div className={cs.file} onClick={() => onFileClickHandler(file)}>
                                    <div className={cs.fileIconWrapper}>
                                        <Icon type={FILES_TYPE_ICONS[file.serverData?.type] || Icons.NONE_FILE} width={24} height={24} />
                                    </div>
                                    <div>
                                        <div className={cs.fileName} onClick={() => {}}>
                                            {file.status === 'loading' && <img src={'/icons/loading.svg'} width={16} height={16} alt="" />}
                                            {file.name || file.file?.path || (file?.serverData?.name ?? '')}
                                        </div>
                                        <div className={cs.fileDescription}>
                                            {file.status === 'done' && humanFileSize(file?.serverData?.size, true)}
                                            {file.status === 'loading' && <span>{file?.uploadedPercent} %</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className={cs.iconWrapper} onClick={() => removeFile(file)}>
                                    <Icon type={Icons.CROSS} width={9} height={9} />
                                </div>
                            </div>
                        );
                    })}
                </div>
                {isViewFileModal && (
                    <FileViewDialog file={selectedFile} isNoButtons={true} isNoActions={true} handleClose={() => setIsViewFileModal(false)} />
                )}
                <div data-testid="activeNodeLinksWrapper">
                    {activeNode.links?.map((link, idx) => {
                        return (
                            <div key={idx} className={cs.linkWrapper}>
                                <a href={link.link} target="_blank" rel="noreferrer">
                                    <div className={cs.linkIcon}>
                                        <Icon type={Icons.LINK} width={13} height={13} color="blue" />
                                    </div>
                                    <div className={cs.linkMain}>
                                        <div className={cs.linkName}>{link.linkTitle}</div>
                                        <div className={cs.linkUrl}>{link.link}</div>
                                    </div>
                                </a>
                                <div className={cs.iconWrapper} onClick={() => removeLink(link)}>
                                    <Icon type={Icons.CROSS} width={9} height={9} />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div data-testid="activeNodeArticlesWrapper">
                    {/* {activeNode.articles?.map((article) => { */}
                    {activeNodeRef.current?.articles?.map((article) => {
                        return (
                            <div key={article.articleId} className={cs.articleWrapper}>
                                <div className={cs.articleTitle}>{article.articleTitle}</div>
                                <div className={cs.iconWrapper} onClick={() => removeArticle(article)}>
                                    <Icon type={Icons.CROSS} width={9} height={9} />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={cs.imagesWrapper} data-testid="activeNodeImagesWrapper">
                    {activeNodeRef.current?.images?.map((image, index) => {
                        return (
                            <div key={`${image.url}_${index}`} className={cs.image}>
                                <div className={cs.imageRemove} onClick={() => removeImage(image?.imageLink)}>
                                    <Icon type={Icons.CROSS} width={10} height={10} color="white" />
                                </div>
                                <img src={image.imageLink} alt="" />
                            </div>
                        );
                    })}
                </div>
                <Checkbox label={'Целевой шаг'} onChange={onTargetStepChange} checked={activeNode?.data?.targetStep} data-testid="activeNodeTargetStep" />
                <Divider />
                <div className={cs.stepHeadingLabel}>Условия</div>
                <div className={cs.stepsConditionContainer} data-testid="activeNodeCondiitionsWrapper">
                    {conditions.map(condition => (
                        <div key={condition.condition.data.id} className={cs.conditionCard}>
                            <div className={classNames(cs.conditionType, getConditionMarker(condition.condition.type))}></div>
                            <div className={cs.conditionInfo}>
                                <div className={cs.conditionText}>
                                    {condition.condition.data.text}
                                </div>
                                <div className={cs.nextStepText}>
                                    Цель: {condition.nextStep.data.text}
                                </div>
                            </div>
                            <div className={cs.conditionBtn} onClick={() => onEditCondition(condition.condition.data.id)}>
                                <Icon type={Icons.EDIT_PEN} width={13} height={13} color="blue"/>
                            </div>
                            <div className={cs.conditionBtn} onClick={() => onRemoveCondition(condition.condition)}>
                                <Icon type={Icons.TRASH} width={13} height={13} color="red"/>
                            </div>
                        </div>
                    ))}
                </div>
                <div onClick={() => onAddStep(activeNode.data)} className={cs.addConditionContainer} data-testid="activeNodeAddConditionBtn">
                    <div className={cs.addButtonContainer}>+</div>
                    <span>Добавить условие</span>
                </div>
            </div>

            {isFileModal && (
                <UploadModal
                    selectedFiles={activeNode?.files || []}
                    onCloseHandler={() => setIsFileModal(false)}
                    onAttachHandler={attachFiles}
                    onSubmitHandler={submitFiles}
                />
            )}

            {isArticleModal && (<AddDocumentModal isOpen={isArticleModal} onClose={() => setIsArticleModal(false)} onSubmit={saveDocuments} articles={activeNode.articles} />)}

            <AddLinkModal isOpen={isLinkModal} onClose={() => setIsLinkModal(false)} onSubmit={saveLink} links={activeNode.links} />

            <ImageUploadModal
                open={isImageModal}
                onCloseModal={() => setIsImageModal(false)}
                initId={initId}
                maxSize={52428800}
                onUpload={onImageUploadHandler}
            />
        </>
    );
}

ActiveNodeSettings.propTypes = {
    activeNode: PropTypes.object,
    initId: PropTypes.string,
    onAddStep: PropTypes.func,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    errors: PropTypes.object,
    setErrors: PropTypes.func,
    watchTextLength: PropTypes.func,
    watchTextCharacters: PropTypes.func,
};

export default ActiveNodeSettings;
