import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from '@reach/router';
import { useAuthorized } from 'lib/hooks';
import Loading from 'uikit/loading';

const PrivatePage = ({ children, hasAnyAuthorities }) => {
    const { isAuthorizedAction, sessionHasBeenFetched } = useAuthorized();
    const navigate = useNavigate();

    const _isAuthorized = isAuthorizedAction(hasAnyAuthorities);

    if (!_isAuthorized && sessionHasBeenFetched) {
        navigate('/401', { replace: true });
    }

    return (
        <>
            {_isAuthorized ? <>{children}</> : <Loading/>}
        </>
    );
};

PrivatePage.propTypes = {
    hasAnyAuthorities: PropTypes.arrayOf(PropTypes.string)
};

export default PrivatePage;
