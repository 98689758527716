import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import formatDateTime from 'lib/helpers/formatDateTime';
import MultiChart from 'components/statistic/resources-chart';
import { barChartOptions, doughnutChartOptions, lineChartOptions } from 'components/statistic/charts-options';
import Icon, { Icons } from 'uikit/icon';
import api from 'api/index';
import cx from './statistic-total-stats-page.module.scss';

const today = {
    numberOfProjects: {
        id: 1,
        title: 'Создано проектов',
        count: 0,
    },
    numberOfArticles: {
        id: 2,
        title: 'Создано статей',
        count: 0,
    },
    numberOfNews: {
        id: 3,
        title: 'Создано новостей',
        count: 0,
    },
    numberOfUploads: {
        id: 4,
        title: 'Загружено файлов',
        count: 0,
    },
    numberOfComments: {
        id: 5,
        title: 'Оставлено комментариев',
        count: 0,
    },
    numberOfUsers: {
        id: 6,
        title: 'Новых участников',
        count: 0,
    },
    numberOfLikes: {
        id: 7,
        title: 'Лайков',
        count: 0,
    },
    numberOfDislikes: {
        id: 8,
        title: 'Дизлайков',
        count: 0,
    },
};

const StatisticDashboardsGeneral = () => {

    //const [usersPerDay, setUsersPerDay] = useState(null);
    const [usersOnlinePerHour, setUsersOnlinePerHour] = useState(null);

    //const [rolesCounts, setRolesCounts] = useState(null);
    const [rolesCountsOnline, setRolesCountsOnline] = useState(null);

    //const [officesUsersCount, setOfficesUsersCount] = useState(null);
    const [eventsCounts, setEventsCounts] = useState(null);

    const [eventsPerOffice, setEventsPerOffice] = useState(null);
    const [eventsToday, setEventsToday] = useState(today);

    const [topActiveUsers, setTopActiveUsers] = useState(null);
    const [topSearchedRequests, setTopSearchedRequests] = useState([]);

    const [topArticles, setTopArticles] = useState([]);

    // const fetchUsersPerDay = useCallback(async (cancelToken) => {
    //     try {
    //         const res = await api.stats.getUsersPerDay(cancelToken.token);
    //         const data = { labels: [], datasets: [] };

    //         res.forEach((user) => {
    //             data.labels.push(formatDateTime(new Date(user.date)));
    //             data.datasets.push(user.numberOfUsers);
    //         });

    //         setUsersPerDay(data);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, []);

    const fetchUsersOnline = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getUsersPerHourOnline(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((user) => {
                data.labels.push(formatDateTime(new Date(user.eventDateHour)));
                data.datasets.push(user.numberOfUsers);
            });
            setUsersOnlinePerHour(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    // const fetchRolesCount = useCallback(async (cancelToken) => {
    //     try {
    //         const res = await api.stats.getRolesCount(cancelToken.token);
    //         const roles = { labels: [], datasets: [] };

    //         res.roleItems.forEach((role) => {
    //             roles.labels.push(role.roleTitle);
    //             roles.datasets.push(role.count);
    //         });

    //         setRolesCounts(roles);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, []);

    const fetchRolesCountOnline = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getRolesCountOnline(cancelToken.token);
            const roles = { labels: [], datasets: [] };

            res.roleItems.forEach((role) => {
                roles.labels.push(role.roleTitle);
                roles.datasets.push(role.count);
            });

            setRolesCountsOnline(roles);
        } catch (e) {
            console.log(e);
        }
    }, []);

    // const fetchUsersCountOfEachOffice = useCallback(async (cancelToken) => {
    //     try {
    //         const res = await api.stats.getUsersCountOfEachOffice(cancelToken.token);
    //         const data = { labels: [], datasets: [] };

    //         res.forEach((office) => {
    //             data.labels.push(office.officeName || 'Без филиала');
    //             data.datasets.push(office.numberOfUsers);
    //         });

    //         setOfficesUsersCount(data);
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }, []);

    const fetchEventsCounts = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getEventsCounts(cancelToken.token);

            setEventsCounts({
                labels: ['Создание', 'Редактирование', 'Удаление', 'Коментирование', 'Прочтение'],
                datasets: [res.numberOfCreates, res.numberOfEdits, res.numberOfDeletes, res.numberOfComments, res.numberOfReads],
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchEventsPerOffice = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getEventsPerOffice(cancelToken.token);
            const data = { labels: [], datasets: [] };

            res.forEach((office) => {
                data.labels.push(office.officeName || 'Без филиала');
                data.datasets.push(office.numberOfEvents);
            });

            setEventsPerOffice(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchEventsToday = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getEventsToday(cancelToken.token);

            setEventsToday((prevState) => {
                return Object.keys(res).map((key) => {
                    return { ...prevState[key], count: res[key] ? res[key] : 0 };
                });
            });
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopActiveUsers = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopActiveUsers(cancelToken.token);

            const data = {
                labels: res.map((user) => `${user.user.firstName} ${user.user.lastName}`),
                datasets: [
                    { label: 'Создание', data: res.map((user) => user.numberOfCreates) },
                    { label: 'Редактирование', data: res.map((user) => user.numberOfEdites) },
                    { label: 'Удаление', data: res.map((user) => user.numberOfDeletes) },
                ],
            };

            setTopActiveUsers(data);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopSearchedRequests = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopSearchedRequests(cancelToken.token);

            setTopSearchedRequests(res);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const fetchTopArticles = useCallback(async (cancelToken) => {
        try {
            const res = await api.stats.getTopArticles('active', cancelToken.token);

            setTopArticles(res);
        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        fetchUsersOnline(cancelToken);
        fetchRolesCountOnline(cancelToken);
        fetchEventsCounts(cancelToken);
        fetchEventsPerOffice(cancelToken);
        fetchEventsToday(cancelToken);
        fetchTopActiveUsers(cancelToken);
        fetchTopSearchedRequests(cancelToken);
        fetchTopArticles(cancelToken);
        //fetchUsersPerDay(cancelToken);
        //fetchRolesCount(cancelToken);
        //fetchUsersCountOfEachOffice(cancelToken);

        return () => {
          cancelToken.cancel();
        }
    }, [
        fetchUsersOnline,
        fetchRolesCountOnline,
        fetchEventsCounts,
        fetchEventsPerOffice,
        fetchEventsToday,
        fetchTopActiveUsers,
        fetchTopSearchedRequests,
        fetchTopArticles,
        // fetchUsersPerDay,
        // fetchRolesCount,
        // fetchUsersCountOfEachOffice,
    ]);

    return (
        <>
            <div className={cx.header}>
                <h1>Общая статистика - Мониторинг</h1>
            </div>
            <div className={cx.title}>Участники</div>
            <div className={cx.chartRow}>
                {/*
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Участников в системе"
                        chartType={'line'}
                        chartTypes={['line']}
                        labels={usersPerDay?.labels}
                        data={usersPerDay?.datasets}
                        options={lineChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Роли участников"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={rolesCounts?.labels}
                        data={rolesCounts?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                */}
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Участники онлайн"
                        chartType={'line'}
                        chartTypes={['line']}
                        labels={usersOnlinePerHour?.labels}
                        data={usersOnlinePerHour?.datasets}
                        options={lineChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Роли (Онлайн)"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={rolesCountsOnline?.labels}
                        data={rolesCountsOnline?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                {/*
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Разделение по филиалам"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={officesUsersCount?.labels}
                        data={officesUsersCount?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                */}
                <div className={cx.chartWrapper}>
                    <div className={cx.sessionTimeWrapper}>
                        <div className={cx.sessionTimeTitle}>Среднее время сессии</div>
                        <div className={cx.sessionTime}>02:41:17</div>
                    </div>
                </div>
            </div>
            <div className={cx.title}>Действия</div>
            <div className={cx.chartRow}>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Действия в системе"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={eventsCounts?.labels}
                        data={eventsCounts?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Активность филиалов"
                        chartType={'doughnut'}
                        chartTypes={['doughnut', 'horizontalBar']}
                        labels={eventsPerOffice?.labels}
                        data={eventsPerOffice?.datasets}
                        isTypeSelect={true}
                        options={doughnutChartOptions}
                    />
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Активность за сутки</h3>
                        {Object.keys(eventsToday).map((key, idx) => {
                            return (
                                <div key={idx} className={cx.listRow}>
                                    <div> {eventsToday[key].title}</div>
                                    <div>{eventsToday[key].count ? eventsToday[key].count : 0}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={cx.chartWrapper}>
                    <MultiChart
                        title="Топ активности участников"
                        chartType="bar"
                        chartTypes={['bar']}
                        labels={topActiveUsers?.labels}
                        data={topActiveUsers?.datasets}
                        options={barChartOptions}
                    />
                </div>
            </div>
            <div className={cx.title}>Популярное</div>
            <div className={cx.chartRow}>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Топ-10 запросов</h3>
                        {topSearchedRequests.length === 0 && <div className={cx.listEmpty}>Нет данных для отображения</div>}
                        {topSearchedRequests.map((item, idx) => {
                            return (
                                <div key={idx} className={cx.requestsRow}>
                                    <div>
                                        <Icon type={Icons.SEARCH} /> {item.query}
                                    </div>
                                    <div>{item.numberOfSearches ? item.numberOfSearches : 0} запроса(-ов)</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={cx.listWrapper}>
                    <div className={cx.list}>
                        <h3>Топ-10 статей</h3>
                        {topArticles.length === 0 && <div className={cx.listEmpty}>Нет данных для отображения</div>}
                        {topArticles.map((item, idx) => {
                            return (
                                <div key={idx} className={cx.requestsRow}>
                                    <div>
                                        <Icon type={Icons.SEARCH} /> {item.article.title}
                                    </div>
                                    <div>{item.numberOfAppeals ? item.numberOfAppeals : 0} обращенией</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatisticDashboardsGeneral;
