import React, { useMemo, useState } from 'react';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import './splitter-layout.css';
import cs from './sidebar-layout.module.scss';
import { useWindowSize } from 'lib/hooks/useWindowSize';
import { useGlobalContext } from 'lib/hooks';
import Icon, { Icons } from 'uikit/icon';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectNewsPicker } from 'slice/newsPickerSlice';

export const sidebarLayout = (SideComponent) => {
    return React.forwardRef((props, ref) => {
        const { platform } = useGlobalContext();
        const { width } = useWindowSize();

        const newsPicker = useSelector(selectNewsPicker);

        const [isResizing, setIsResizing] = useState(false);
        const [showTree, setShowTree] = useState(false);

        const minPercentWidth = useMemo(() => 240 / width * 100, [width]);
        const showToggleTreeButton = () => {
            if (window.location.pathname.indexOf('/projects/article/') !== -1
                && (window.location.pathname.indexOf('/edit') !== -1
                    || window.location.pathname.indexOf('/history') !== -1)) {
                return false;
            } else if (window.location.pathname.indexOf('/news/') !== -1
                && (window.location.pathname.indexOf('/edit') !== -1
                    || window.location.pathname.indexOf('/history') !== -1)) {
                return false;
            } else if (window.location.pathname.indexOf('/article-draft/edit') !== -1
                || window.location.pathname.indexOf('/news-draft/edit') !== -1
                || window.location.pathname.indexOf('/delayed-article/edit') !== -1
                || window.location.pathname.indexOf('/delayed-news/edit') !== -1) {
                return false;
            }

            return true;
        };

        if (platform === 'mobile') {
            return (
                <>
                    <SideComponent {...props} ref={ref}/>
                    {props.children}
                </>
            );
        }

        if (width >= 768 && width <= 1024 && window.location.pathname.indexOf('/add') === -1) {
            return (
                <>
                    <div className={classNames(cs.treeContainer, {
                        [cs.treeContainerHidden]: width >= 768 && width <= 1024 && !showTree,
                        [cs.withNewsTicker]: newsPicker.count > 0,
                    })} onClick={() => setShowTree(!showTree)}>
                        <div className={cs.treeWrapper} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            <SideComponent {...props} ref={ref}/>
                        </div>
                    </div>
                    <div>
                        {showToggleTreeButton() && (
                            <div
                                className={classNames(cs.toggleTreeButton, {
                                    [cs.low]: window.location.pathname.indexOf('/projects/article/') !== -1
                                    || window.location.pathname.indexOf('/news/') !== -1
                                })}
                                onClick={() => setShowTree(!showTree)}
                            >
                                <Icon type={Icons.SANDWICH} width={20} height={14} />
                            </div>
                        )}
                        {props.children}
                    </div>
                </>
            );
        }

        if (width >= 768 && width <= 1024 && window.location.pathname.indexOf('add/') !== -1) {
            return props.children;
        }

        return (
            <SplitterLayout
                percentage
                secondaryMinSize={minPercentWidth}
                secondaryInitialSize={25}
                primaryIndex={1}
                onDragStart={() => setIsResizing(true)}
                onDragEnd={() => setIsResizing(false)}
            >
                <div style={{ height: '100%' }}>
                    <SideComponent {...props } ref={ref} />
                </div>
                <div
                    id="sidebarContent"
                    className={classNames('js-scroll-wrapper', cs.sidebarLayout, {
                        [cs.withNewsTicker]: newsPicker.count > 0
                    })}
                    style={{ height: '100%', overflow: 'auto' }}
                >
                    {isResizing && <div className={cs.resizingOverlay}/>}
                    {props.children}
                </div>
            </SplitterLayout>
        );
    })
}

export default sidebarLayout;
