import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useGlobalContext } from 'lib/hooks';
import { selectNewsPicker } from 'slice/newsPickerSlice';
import cx from './content-wrapper.module.scss';

export const ContentWrapper = ({ children, noScrollable, className = '' }) => {
    const { platform } = useGlobalContext();

    const newsPicker = useSelector(selectNewsPicker);
    
    if (platform === 'mobile') {
        return (
            <div id="content" className={classNames(cx.body, 'js-scroll-wrapper', cx.mobileBody, {
                [cx.scrollable]: !noScrollable,
                [cx.withNewsTicker]: newsPicker.count > 0,
            }, className)}>
                {children}
            </div>
        );
    }
    return (
        <div className={cx.contentWrapper}>
            <div id="content" className={classNames(cx.body, 'js-scroll-wrapper', {
                [cx.scrollable]: !noScrollable,
                [cx.withNewsTicker]: newsPicker.count > 0,
            }, className)}>
                {children}
            </div>
        </div>
    );
};

ContentWrapper.defaultProps = {
    noScrollable: false,
};

ContentWrapper.propTypes = {
    noScrollable: PropTypes.bool,
};
