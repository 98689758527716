import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Link, Router } from '@reach/router';
import { ContentWrapper } from 'containers/content-wrapper';
import { MobileTree, NavigationTree } from 'components/trees';
import Icon, { Icons } from 'uikit/icon';
import ActionLogPage from 'app/action-log/action-log-page/action-log';
import NotificationsPage from 'app/action-log/notifications-page/notifications';
import cs from './actions-log.module.scss';
import { useGlobalContext } from 'lib/hooks';
import { useCustomMobileMenu } from 'lib/hooks/useCustomMobileMenu';
import buttons from 'model/global-menu/buttons';
import { useSelector } from 'react-redux';
import { selectMenuButtons } from 'slice/authSlice';
import { useWindowSize } from 'lib/hooks/useWindowSize';

const ActionsLog = ({ navigate, ...props }) => {
    const { width } = useWindowSize();
    const [showTree, setShowTree] = useState(false);

    const { platform } = useGlobalContext();
    const [tab, setActiveTab] = useState(null);

    const treeRef = useRef(null);
    const menuButtons = useSelector(selectMenuButtons);

    useCustomMobileMenu(useCallback((onClose) => {
        return (
            <MobileTree onSelect={() => {
                onClose();
                setActiveTab('actions');
            }} getNodeLink={node => {
                return { link: `/action-log/actions/${node.id}`, replace: true};
            }} ref={treeRef}/>
        )
    }, []));

    useEffect(() => {
      if (!props['*']) {
          navigate('/action-log/actions', { replace: true });
          setActiveTab('actions');

          if (treeRef?.current?.tree) {
            treeRef.current.tree.selectNode(null);
          }
      } else if (!tab) {
          const index = props['*'].indexOf('/');

          setActiveTab(index !== -1 ? props['*'].slice(0, index) : props['*']);
      }
    }, [tab, navigate, props, treeRef]);

    return (
        <ContentWrapper>
            <div className={cs.toggleTreeButton} onClick={() => setShowTree(!showTree)}>
                <Icon type={Icons.SANDWICH} width={20} height={14} />
            </div>
            <div className={classNames(cs.base, { [cs.mobile]: platform === 'mobile' })}>
                {platform !== 'mobile' && (
                    <div className={classNames(cs.treeContainer, {
                        [cs.treeContainerHidden]: width >= 768 && width <= 1024 && !showTree
                    })} onClick={() => setShowTree(!showTree)}>
                        <div className={cs.tree} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}>
                            {tab === 'actions' && (
                                <NavigationTree
                                    multipleChoice={false}
                                    allButtonTitle='Все проекты'
                                    path={'action-log/actions'}
                                    ref={treeRef}
                                    getNodeLink={(node) => `/action-log/actions/${node.id}?page=0&scroll=0`}
                                    scrollToNode={[props['*'].replace('actions', '').replace('/', '')]}
                                />
                            )}
                            {tab === 'notifications' && (
                                <div className={cs.noTree}>
                                    <Icon type={Icons.TEXT_ALIGN_LEFT} width={24} height={24} />
                                    <div>Иерархия отсутствует</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <div className={cs.contentUsers}>
                    <ul className={cs.usersUl}>
                        {menuButtons[buttons.ACTIVITY] === true &&
                            <li className={tab === 'actions' ? cs.active : ''}>
                                <Link to={'/action-log/actions'} onClick={() => setActiveTab('actions')}>
                                    Действия
                                </Link>
                            </li>
                        }
                        <li className={tab === 'notifications' ? cs.active : ''}>
                            <Link to={'/action-log/notifications'} onClick={() => setActiveTab('notifications')}>
                                Уведомления
                            </Link>
                        </li>
                    </ul>
                    <div className={cs.tableWrapper}>
                        <Router className={cs.router}>
                            <ActionLogPage path='actions/' treeRef={treeRef} />
                            <ActionLogPage path='actions/:id' treeRef={treeRef} />
                            <NotificationsPage path='notifications/' />
                        </Router>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    );
};
export default ActionsLog;
