import { TableCol } from './extension-col';
import { TableColgroup } from './extension-colgroup';
import { Table } from './extension-table';
import { TableBody } from './extension-table-body';
import { TableCell } from './extension-table-cell';
import { TableHeader } from './extension-table-header';
import { TableRow } from './extension-table-row';
import { TableHead } from './extension-table-thead';
import { TableTitle } from './extension-table-title';

export const TableExtensions = [
    Table.configure({
        resizable: true,
    }),
    TableHead,
    TableBody,
    TableRow,
    TableHeader,
    TableCell,
    TableTitle,
    TableColgroup,
    TableCol,
];
