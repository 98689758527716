import React from 'react';
import { navigate } from '@reach/router';
import { Icons } from 'uikit/icon';
import { Empty } from 'components/projects';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        console.log(error, info);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Empty
                    icon={Icons.INFO}
                    title="Упс. Произошла ошибка."
                    description="Пожалуйста перезагрузите страницу или перейдите на главную."
                    buttons={[{
                        icon: Icons.RELOAD,
                        title: 'Перезагрузить',
                        onClick: () => window.location.reload()
                    }, {
                        icon: Icons.HOME,
                        title: 'На главную',
                        onClick: () => {
                            navigate('/');
                            window.location.reload();
                        }
                    }]}/>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;