import { useEffect, useState, useMemo, useCallback } from 'react';
import api from 'api/index';

export const useTags = () => {
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [invalid, setInvalid] = useState(true);

    useEffect(() => {
        const getCategories = async () => {
            setLoading(true);
            const response = await api.tags.getCategories();
            setCategories(response.content);

            const tagResponse = await api.tags.getTags();
            setTags(tagResponse.content);


            setInvalid(false);
            setLoading(false);
        }

        if (invalid) {
            getCategories();
        }
    }, [invalid]);

    const invalidate = useCallback(() => {
        setInvalid(true);
    }, []);

    return useMemo(() => ({
        tags,
        categories,
        loading,
        invalid,
        invalidate,
    }), [tags, categories, loading, invalid, invalidate])
}
