import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { useDebounceFunction } from 'lib/hooks';
import api from 'api';
import cs from './user-input.module.scss';
import './user-input.css';

export const UserInput = forwardRef(({ id, onChange, label, }, ref) => {
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const fetchSuggestions = useDebounceFunction(value => {
        api.user.getUsers(0, 999999, '', value).then(response => {
            setSuggestions(response.data.content);
        });
    }, 300);

    const _onChange = (event, { newValue }) => {
        setValue(newValue);
        onChange(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        fetchSuggestions(value);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const getSuggestionValue = suggestion => suggestion.login;
    const renderSuggestion = suggestion => (<div>{suggestion.login}</div>);

    const inputProps = {
        placeholder: 'Введите логин',
        value,
        onChange: _onChange
    };

    useImperativeHandle(ref, () => ({
        setValue(value) {
            setValue(value);
        },
    }));

    return (
        <div id={id}>
            {label && label.length > 1 ? <label className={cs.label}>{label}</label> : ''}
            <Autosuggest suggestions={suggestions} onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                         onSuggestionsClearRequested={onSuggestionsClearRequested}
                         getSuggestionValue={getSuggestionValue} renderSuggestion={renderSuggestion}
                         inputProps={inputProps} theme={cs}/>
        </div>
    );
});

UserInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    id: PropTypes.string
};