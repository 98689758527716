import ApiClient from './api-axios';

export default class SearchApi extends ApiClient {
    search = async (query) => {
        return await this.post('services/search/api/_search/resources/morphology', { ...query });
    };

    searchAutocomplete = async (query) => {
        return await this.get('services/search/api/_search/autocomplete-suggests', { query });
    };
}