import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconButton from 'uikit/icon-button';
import Icon, { Icons } from 'uikit/icon';
import DropdownMenuItem from './dropdown-menu-item';
import cs from './dropdown-menu.module.scss';

class DropdownMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {
        if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
            this.setState({ showMenu: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });
        }
    }

    hideMenu() {
        this.setState({ showMenu: false });
    }

    render() {
        return (
            <div className={classNames(cs.container, this.props.className, { [cs.show]: this.props.alwaysFilled || this.state.showMenu })}>
                {this.props.toggler?.(this.showMenu) || (
                    <IconButton
                        buttonId={this.props.buttonId}
                        onClick={this.showMenu}
                        icon={
                            <Icon
                                type={this.props.icon ? this.props.icon : Icons.MORE_CIRCLE}
                                tooltip={this.props.tooltip ? this.props.tooltip : null}
                                width={this.props.width ? this.props.width : 20}
                                height={this.props.height ? this.props.height : 20}
                            />
                        }
                    />
                )}

                {this.state.showMenu && (
                    <div
                        className={classNames(cs.menu, this.props.menuClassName, {
                            [cs.left]: this.props.position === 'left',
                            [cs.right]: this.props.position === 'right',
                        })}
                        style={this.props.menuStyle}
                        ref={(element) => {
                            this.dropdownMenu = element;
                        }}
                        onClick={() => this.closeMenu({})}
                    >
                        {this.props.children}
                    </div>
                )}
            </div>
        );
    }
}

DropdownMenu.propTypes = {
    position: PropTypes.string,
    className: PropTypes.string,
    menuClassName: PropTypes.string,
    alwaysFilled: PropTypes.bool,
    buttonId: PropTypes.string,
};

DropdownMenu.defaultProps = {
    position: 'left',
    className: null,
    menuClassName: null,
    alwaysFilled: false,
    buttonId: '',
};

DropdownMenu.Item = DropdownMenuItem;
export default DropdownMenu;
