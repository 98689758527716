import React from 'react';
import Icon, { Icons } from 'uikit/icon';
import IconButton from 'uikit/icon-button';
import Input from 'uikit/input/input';
import cx from './training-question-answer-next.module.scss';

export default function TrainingQuestionAnswerNext({ answers, onChange }) {
    const OnAnswerAdd = () => {
        const temp = Object.assign([], answers);
        temp.push({ value1: '', value2: '', isRight: false });
        onChange(temp);
    };
    const OnAnswerChange = (answer, i) => {
        const temp = Object.assign([], answers);
        temp[i]['value1'] = answer;
        onChange(temp);
    };
    const OnAnswerRemove = (i) => {
        const temp = Object.assign([], answers);
        temp.splice(i, 1);
        onChange(temp);
    };

    return (
        <div className={cx.trainingQuestionAnswerNext}>
            {answers.map((answer, i) => {
                return (
                    <div key={i} className={cx.trainingQuestionAnswerNextItem}>
                        <Input type="text" label="Вариант ответа" value={answer['value1']}
                               onChange={(str) => OnAnswerChange(str, i)}/>
                        <IconButton icon={<Icon type={Icons.TRASH} width={14} height={15}/>}
                                    onClick={() => OnAnswerRemove(i)}/>
                    </div>
                );
            })}
            <div className={cx.trainingQuestionAnswerNextAdd} onClick={() => OnAnswerAdd()}>
                <div className={cx.trainingQuestionAnswerNextAddIcon}>
                    <Icon type={Icons.ADD} width={10} height={10}/>
                </div>
                <span>Добавить вариант ответа</span>
            </div>
        </div>
    );
};