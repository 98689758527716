import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonStrikethrough = ({ setCurrentMenu }) => {
    const editor = useEditorContext();

    const toggleStrike = e => {
        e.preventDefault();
        editor.view.focus();

        setCurrentMenu(undefined);
        editor?.chain().focus().toggleStrike().run();
    };

    return (
        <MenuButton
            icon={Icons.EditorIconStrikethrough}
            tooltip="Зачеркнутый"
            onClick={toggleStrike}
            selected={editor?.isActive('strike') ?? undefined}
        ></MenuButton>
    );
};
