import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { idFromString } from 'lib/helpers';
import cx from './checkbox.module.scss';

const Checkbox = ({ id, className, label, onChange, disabled, indeterminate, forwardedRef, ...attrs }) => {
    const key = id || idFromString(label);

    return (
        <label className={classNames(className, cx.container, { [cx.disabled]: disabled, [cx.indeterminate]: indeterminate })}
               onClick={e => { e.stopPropagation(); }}>
            {label}
            <input id={key} type="checkbox" onChange={onChange} ref={forwardedRef} disabled={disabled} {...attrs}/>
            <div className={cx.checkmark}/>
        </label>
    );
};

Checkbox.defaultProps = {
    id: null,
    className: null,
    label: '',
    onChange: () => {

    },
    disabled: false,
    indeterminate: false
};

Checkbox.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    indeterminate: PropTypes.bool
};

export default React.forwardRef((props, ref) => <Checkbox {...props} forwardedRef={ref}/>);
