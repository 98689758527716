import { PluginKey } from '@tiptap/pm/state';
import { TableMap } from './tableMap';
import { getRow, removeColSpan } from './utils';
import { tableNodeTypes } from './extension-table/utilities/getTableNodeTypes';

export const fixTablesKey = new PluginKey('fix-tables');

function changedDescendants(old, cur, offset, f) {
    const oldSize = old.childCount,
        curSize = cur.childCount;
    outer: for (let i = 0, j = 0; i < curSize; i++) {
        const child = cur.child(i);
        for (let scan = j, e = Math.min(oldSize, i + 3); scan < e; scan++) {
            if (old.child(scan) === child) {
                j = scan + 1;
                offset += child.nodeSize;
                continue outer;
            }
        }
        f(child, offset);
        if (j < oldSize && old.child(j).sameMarkup(child)) changedDescendants(old.child(j), child, offset + 1, f);
        else child.nodesBetween(0, child.content.size, f, offset + 1);
        offset += child.nodeSize;
    }
}

export function fixTables(state, oldState) {
    let tr;
    const check = (node, pos) => {
        if (node.type.spec.tableRole === 'table') tr = fixTable(state, node, pos, tr);
    };
    if (!oldState) state.doc.descendants(check);
    else if (oldState.doc !== state.doc) changedDescendants(oldState.doc, state.doc, 0, check);
    return tr;
}

export function fixTable(state, table, tablePos, tr) {
    if (hasFreeRows(table)) tr = fixFreeRows(state, table, tablePos, tr);
    const map = TableMap.get(table);
    if (!map.problems) return tr;
    if (!tr) tr = state.tr;

    const mustAdd = [];
    for (let i = 0; i < map.height; i++) mustAdd.push(0);
    for (let i = 0; i < map.problems.length; i++) {
        const prob = map.problems[i];
        if (prob.type === 'collision') {
            const cell = table.nodeAt(prob.pos);
            if (!cell) continue;
            const attrs = cell.attrs;
            for (let j = 0; j < attrs.rowspan; j++) mustAdd[prob.row + j] += prob.n;
            tr.setNodeMarkup(tr.mapping.map(tablePos + 1 + prob.pos), null, removeColSpan(attrs, attrs.colspan - prob.n, prob.n));
        } else if (prob.type === 'missing') {
            mustAdd[prob.row] += prob.n;
        } else if (prob.type === 'overlong_rowspan') {
            const cell = table.nodeAt(prob.pos);
            if (!cell) continue;
            tr.setNodeMarkup(tr.mapping.map(tablePos + 1 + prob.pos), null, {
                ...cell.attrs,
                rowspan: cell.attrs.rowspan - prob.n,
            });
        } else if (prob.type === 'colwidth mismatch') {
            const cell = table.nodeAt(prob.pos);
            if (!cell) continue;
            tr.setNodeMarkup(tr.mapping.map(tablePos + 1 + prob.pos), null, {
                ...cell.attrs,
                colwidth: prob.colwidth,
            });
        }
    }
    let first, last;
    for (let i = 0; i < mustAdd.length; i++)
        if (mustAdd[i]) {
            if (first == null) first = i;
            last = i;
        }

    for (let i = 0; i < map.height; i++) {
        const { node: row, pos } = getRow(table, i);
        const end = pos + row.nodeSize;
        const add = mustAdd[i];
        if (add > 0) {
            let role = 'cell';
            if (row.firstChild) {
                role = row.firstChild.type.spec.tableRole;
            }
            const nodes = [];
            for (let j = 0; j < add; j++) {
                const node = tableNodeTypes(state.schema)[role].createAndFill();
                if (node) nodes.push(node);
            }
            const side = (i === 0 || first === i - 1) && last === i ? pos + 1 : end - 1;
            tr.insert(tr.mapping.map(tablePos + side + 1), nodes);
        }
    }
    return tr.setMeta(fixTablesKey, { fixTables: true });
}

export function hasFreeRows(table) {
    for (let i = 0; i < table.childCount; i++) if (table.child(i).type.spec.tableRole === 'row') return true;
    return false;
}

export function fixFreeRows(state, table, tablePos, tr) {
    let freeRows = [];
    let freeRowsFound = false;
    const sections = [];
    const types = tableNodeTypes(state.schema);
    for (let i = 0; i < table.childCount; i++) {
        const child = table.child(i);
        if (child.type.spec.tableRole === 'row') {
            freeRowsFound = true;
            freeRows.push(child);
        } else {
            if (freeRows.length > 0) {
                sections.push(types.body.createAndFill(null, freeRows));
                freeRows = [];
            }
            sections.push(child);
        }
    }
    if (freeRows.length > 0) {
        sections.push(types.body.createAndFill(null, freeRows));
        freeRows = [];
    }
    if (!freeRowsFound) return tr;
    return (tr || state.tr).replaceWith(tablePos, tablePos + table.nodeSize, types.table.createAndFill(table.attrs, sections));
}
