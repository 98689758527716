import React from 'react';
import { MenuButton } from './MenuButton';
import { useEditorContext } from '../../context';
import { Icons } from 'uikit/icon';

export const MenuButtonAlignCenter = ({ onChangeAlign }) => {
    const editor = useEditorContext();

    return (
        <MenuButton
            tooltipLabel="Center align"
            icon={Icons.EditorIconAlignCenter}
            onClick={(e) => onChangeAlign(e, 'center')}
            label="Выравнивание по центру"
            style={{ whiteSpace: 'nowrap', display: 'flex' }}
            disabled={!editor?.isEditable || !editor?.can().setTextAlign('center')}
        ></MenuButton>
    );
};
