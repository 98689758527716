import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon, { Icons } from 'uikit/icon';
import cs from './dropdown-menu-item.module.scss';
import { AdaptiveLink } from 'containers/adaptive-link/adaptive-link';

const DropdownMenuItem = ({ id, className, children, iconType, iconColor, text, iconWidth, iconHeight, link, submenuPosition, ...rest }) => {
    const [timer1, setTimer1] = React.useState();
    const [timer2, setTimer2] = React.useState();

    const [hover, setHover] = React.useState(false);
    const [subHover, setSubHover] = React.useState(false);

    const onSubmenuOver = () => {
        if (timer2) {
            clearTimeout(timer2);
        }

        setSubHover(true);
    };

    const onSubmenuOut = () => {
        let t = setTimeout(() => {
            setSubHover(false);
        }, 120);
        setTimer2(t);
    };

    const onMenuOver = () => {
        if (timer1) {
            clearTimeout(timer1);
        }

        setHover(true);
    };

    const onMenuOut = () => {
        let t = setTimeout(() => {
            setHover(false);
        }, 120);

        setTimer1(t);
    };

    return (
        <div id={id} className={classNames(cs.base, className)}>
            <AdaptiveLink link={link} className={cs.menuItem} onMouseOver={onMenuOver} onMouseOut={onMenuOut} {...rest}>
                <div className={cs.titleWrapper}>
                    {iconType && <Icon width={iconWidth} height={iconHeight} type={iconType} color={iconColor}/>}
                    <div>{text}</div>
                </div>
                {children && <Icon width={14} height={14} type={Icons.ARROW_RIGHT}/>}
            </AdaptiveLink>
            {children && (hover || subHover) &&
            <div className={classNames(cs.submenu, cs[submenuPosition])}
                 onMouseOver={onSubmenuOver}
                 onMouseOut={onSubmenuOut}>
                {children}
            </div>}
        </div>
    );
};

DropdownMenuItem.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    iconType: PropTypes.any,
    text: PropTypes.string.isRequired,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
    submenuPosition: PropTypes.oneOf(['left', 'right'])
};

DropdownMenuItem.defaultProps = {
    iconWidth: 18,
    iconHeight: 18,
    submenuPosition: 'right'
}

export default DropdownMenuItem;
