import React, { useMemo, useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal';
import { useMessage, useAuthorized, useDialog, useGlobalContext } from 'lib/hooks';
import { GLOBAL_ACTIONS } from 'model/auth/permissions';
import PrivatePage from 'containers/private-page-wrapper';
import Filter from 'uikit/filter/filter';
import Table, { EditRowToolbar } from 'uikit/table';
import { Icons } from 'uikit/icon';
import PositionsPageAddPosition from 'app/global-settings/positions-page/positions-page-add-position';
import api from 'api';
import cx from './positions-page.module.scss';
import Confirmation from 'components/confirmation';

const PositionsPage = ({ hasAnyAuthorities }) => {
    const { platform } = useGlobalContext();

    const { addSuccess, addError } = useMessage();
    const { isAuthorizedAction } = useAuthorized();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [searchValue, setSearchValue] = useState('');
    const [positions, setPositions] = useState([]);

    const [filteredPositions, setFilteredPositions] = useState([]);
    const [positionTableSort, setPositionsTableSort] = useState({ by: '', desc: false });

    const [selectedPosition, setSelectedPosition] = useState({ title: '' });
    const [isEditPositionModal, setEditPositionModal] = useState(false);

    const fetchManagers = useCallback(async () => {
        try {
            const res = await api.userPosition.getUserPosition(0, 2000, positionTableSort.by
                ? (positionTableSort.by + ',' + (positionTableSort.desc ? 'desc' : 'asc')) : '');

            setPositions(res.content);
            setFilteredPositions(res.content.map( (item) => {
              return {
                ...item,
                title: item.title.trim()
              }
            }));
        } catch (e) {
            console.log(e);
        }
    }, [positionTableSort]);

    const deletePosition =  useCallback(async (positionId) => {

        closeDialog();

        await api.userPosition.deleteUserPosition(positionId);
        await fetchManagers();

        addSuccess('Должность удалена');
        setPositions(positions.filter(position => position.id !== positionId));

    }, [addSuccess, closeDialog, fetchManagers, positions]);

    const removePosition = useCallback(async (data) => {

        openDialog({
            title: 'Удаление',
            text: 'Вы действительно хотите удалить должность ' + data.title + '? Этот процесс нельзя будет отменить',
            color: 'red',
            closeBtnText: 'Нет, отменить',
            submitBtnText: 'Да, удалить',
            onSubmit: () => deletePosition(data.id),
            onClose: () => closeDialog(),
            contentType: 'TEXT'
        })

    }, [closeDialog, deletePosition, openDialog]);

    const columns = useMemo(() => {
        return [
            {
                Header: 'Наименование должности',
                align: 'center',
                accessor: 'title',
                Cell: (data) => {
                    return <>
                        {data.isMobile && <div className={cx.subheader}>Должность</div>}
                        {data.row.original['title']}
                    </>
                }
            },
            {
                id: 4,
                Header: '',
                align: 'center',
                settings: platform === 'mobile' ? ['no_td_wrap'] : [],
                Cell: function(data) {
                    let toolbar = [];
                    toolbar.push({
                        icon: Icons.EDIT_PEN,
                        tooltip: 'Редактировать',
                        onClick: (data) => {
                            setSelectedPosition(data);
                            setEditPositionModal(true);
                        }
                    });
                    if (isAuthorizedAction([GLOBAL_ACTIONS.POSITION_DELETE])) {
                        toolbar.push({
                            icon: Icons.TRASH,
                            tooltip: 'Удалить',
                            onClick: (data) => {
                                removePosition(data);
                            }
                        });
                    }
                    return EditRowToolbar(toolbar, null, true, false, true)(data);
                }
            }
        ];
    }, [isAuthorizedAction, removePosition, platform]);

    const onPositionsTableSort = async (column) => {
        const sort = Object.assign({}, positionTableSort);

        if (sort.by === column.id) {
            sort.desc = !sort.desc;
        } else {
            sort.by = column.id;
            sort.desc = false;
        }

        setPositionsTableSort(sort);
    };

    const createPositionHandler = () => {
        setSelectedPosition({ title: '' });
        setEditPositionModal(isEdit => !isEdit);
    };

    const onSubmit = async (data) => {
        try {
            if (selectedPosition.title) {
                await api.userPosition.updateUserPosition(data);
                addSuccess('Должность изменена');
                setSelectedPosition({ title: '' });
            } else {
                await api.userPosition.addUserPosition(data);
                addSuccess('Должность создана');
            }

            await fetchManagers();
        } catch (e) {
            addError('Сервис недоступен. Пожалуйста попробуйте позже.');
            console.log(e);
        }
    };

    const toggleEditModal = () => {
        setEditPositionModal(isOpen => !isOpen);
    };

    const onMultiRemove = async (rows) => {
        for (let i = 0; i < rows.length; i++) {
            await api.userPosition.deleteUserPosition(rows[i].original.id);
        }

        addSuccess('Должности удалены');
        await fetchManagers();
    };

    useEffect(() => {
        setFilteredPositions(positions.filter(position => position.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1));
    }, [searchValue, positions]);

    useEffect(() => {
        fetchManagers();
    }, [fetchManagers]);

    const tableActions = (function() {
        if (!isAuthorizedAction([GLOBAL_ACTIONS.POSITION_DELETE])) {
            return [];
        } else {
            return [{
                icon: Icons.TRASH,
                label: 'Удалить',
                onClick: (rows) => onMultiRemove(rows)
            }];
        }
    })();

    return (
        <PrivatePage hasAnyAuthorities={hasAnyAuthorities}>
            <Confirmation {...dialogState} />

            <div className={cx.contentWrapper}>
                <div className={cx.title}>Должности</div>
                <div className={cx.table}>
                    <div className={cx.filter}>
                        <Filter
                            id={'positionsPageFilter'}
                            search="Поиск по названию"
                            searchText={searchValue}
                            onSearch={(text) => setSearchValue(text)} add="Создать должность"
                            onAdd={createPositionHandler} canAdd={true}
                        />
                    </div>
                    <Table
                        columns={columns}
                        data={filteredPositions}
                        sort={positionTableSort}
                        onSort={onPositionsTableSort}
                        actions={tableActions}
                        isMobile={platform === 'mobile'}
                        search={searchValue}
                    />
                </div>
            </div>
            <Modal isOpen={isEditPositionModal} className={cx.modal} onRequestClose={toggleEditModal}
                   overlayClassName={cx.modalOverlay}>
                <PositionsPageAddPosition position={selectedPosition} onCloseHandler={setEditPositionModal}
                                          onSubmitHandler={onSubmit} data={positions}/>
            </Modal>
        </PrivatePage>
    );
};

export default PositionsPage;
