import ApiClient from './api-axios';
import { dateToISOWithZone } from 'lib/helpers/dateToISOWithZone';

export default class ReportsApi extends ApiClient {
    collectFilters = (filters, page, size) => {
        const params = new URLSearchParams();
        const data = {};

        if (!isNaN(page) && !isNaN(size)) {
            params.append('page', page);
            params.append('size', size);
        }

        if (filters['sort'] || filters['sortBy']) {
            params.append(
                'sort',
                `${filters['sortBy'] && filters['sortBy']['value'] !== 'createDate' ? filters['sortBy']['value'] : 'createDate'},${
                    filters['sort'] ? filters['sort']['value'] : 'asc'
                }`
            );
        }

        if (filters['createTime']) {
            let today = new Date();

            data['from'] = dateToISOWithZone(new Date(filters['createTime']['from']));
            data['to'] = dateToISOWithZone(new Date(filters['createTime']['to'] || today));
        }

        if (filters['timestamp']) {
            data['timestamp'] = dateToISOWithZone(new Date(filters['timestamp']));
        }

        if (filters['queries'] && filters['queries']['length']) {
            data['queries'] = filters['queries'].map((v) => v.label);
        }

        if (filters['projectUuids'] && filters['projectUuids'].length) {
            data['projectUuids'] = filters['projectUuids'];
        }

        if (filters['sectionPaths'] && filters['sectionPaths'].length) {
            data['sectionPaths'] = filters['sectionPaths'];
        }

        if (filters['sectionUuids'] && filters['sectionUuids'].length) {
            data['sectionUuids'] = filters['sectionUuids'];
        }

        if (filters['articleUuids'] && filters['articleUuids'].length) {
            data['articleUuids'] = filters['articleUuids'];
        }

        if (filters['newsUuids'] && filters['newsUuids'].length) {
            const newsUuids = filters['newsUuids'].map((news) => news.value);
            data['newsUuids'] = newsUuids;
        }

        if (filters['news-group'] && filters['news-group']['value'] !== 'CUSTOM_NEWS' && filters['news-group']['value'] !== null) {
            data['newsType'] = filters['news-group']['value'];
        }

        if (filters['fileSize']) {
            const size =
                filters['fileSize']['type']['value'] === 0
                    ? 1000
                    : filters['fileSize']['type']['value'] === 1
                    ? 1000 * 1000
                    : 1000 * 1000 * 1000;

            data['fileSizeFrom'] = Math.floor(filters['fileSize']['from'] * size);
            data['fileSizeTo'] = Math.ceil(filters['fileSize']['to'] * size);
        }

        if (filters['archived.equals']) {
            data['archived.equals'] = filters['archived.equals']['value'];
        }

        if (
            (filters['fileTypes'] && filters['fileTypes']['value']) ||
            (Array.isArray(filters['fileTypes']) && !filters['fileTypes'].some((i) => i.value === null))
        ) {
            data['fileTypes'] = filters['fileTypes'].map((i) => i.value);
        }

        if (filters['status'] && filters['status']['value'] !== '') {
            data['status'] = filters['status']['value'];
        }

        if (filters['objectType'] && filters['objectType']['value']) {
            data['objectType'] = filters['objectType']['value'];
        }

        if (filters['document-type-select'] && filters['document-type-select']['value']) {
            data['objectType'] = filters['document-type-select']['value'];
        }

        if (filters['authors'] && filters['authors']['length']) {
            data['authors'] = filters['authors'].map((i) => i.login);
        }

        if (filters['users'] && filters['users']['length']) {
            data['users'] = filters['users'].map((i) => i.login);
        }

        if (filters['global'] !== null) {
            data['global'] = filters['global'];
        }

        if (filters['ticker'] && filters['ticker']['value'] !== null) {
            data['ticker'] = filters['ticker']['value'];
        }

        if (filters['priority'] && filters['priority']['value']) {
            data['priority'] = filters['priority']['value'];
        }

        if (filters['eventTypes'] && filters['eventTypes']['value']) {
            params.append('eventTypes', [filters['eventTypes']['value']]);
        }

        if (filters['reportType'] && ['CONTENT_REPORT', 'FILES_REPORTS', 'USERS_REPORT', 'ACTIONS_REPORT'].every((i) => i !== filters['reportType'])) {
            data['reportType'] = filters['reportType'];
        }

        return { data, params };
    };

    getStatisticTree = async () => {
        return await this.get('/kms/api/v1/stats/tree');
    };

    downloadReports = async (path, filters, cancelToken) => {
        const { data, params } = this.collectFilters(filters);
        params.append('page', 0);
        params.append('size', 999999);
        params.append('mode', 'BATCH_MODE');

        return await this.post(
            `/logging/api/report/download/${path}?` + params.toString(),
            data,
            true,
            'blob',
            {
                'Accept-Language': 'ru',
                Accept: 'application/octet-stream',
            },
            cancelToken
        );
    };

    getAuthors = async (path, type, global) => {
        return await this.post(`/logging/api/report/preview/${type}/authors`, {
            projectPaths: path,
            global: global || false,
        });
    };

    getActionsTypes = async (type = 'project') => {
        return await this.get(`logging/api/report/preview/${type}-event/types`);
    };

    getArticles = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('/logging/api/report/preview/articles?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getNews = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('/logging/api/report/preview/news?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getAttachments = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('/logging/api/report/preview/attachments?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getUserRoles = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('/logging/api/report/preview/user-roles?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getUserActions = async ({ type, page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post(`/logging/api/report/preview/${type}?` + params.toString(), data, false, '', {}, cancelToken);
    };

    getArticleReads = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('logging/api/report/preview/events/document/reads?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getUserActionsInNews = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        if (filters['globalNews']) {
            return await this.post('logging/api/report/preview/events/global-news?' + params.toString(), data, false, '', {}, cancelToken);
        } else {
            return await this.post('logging/api/report/preview/events/news?' + params.toString(), data, false, '', {}, cancelToken);
        }
    };

    getSearchRequests = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('logging/api/report/preview/search/queries?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getActionsReport = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('logging/api/report/preview/events?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getSearchDetails = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post('/logging/api/report/preview/search/details?' + params.toString(), data, false, '', {}, cancelToken);
    };

    getDocumentPopularity = async ({ page, size, filters = {}, cancelToken }) => {
        const { data, params } = this.collectFilters(filters, page, size);

        return await this.post(
            '/logging/api/report/preview/document-popularity/by-likes?' + params.toString(),
            data,
            false,
            '',
            {},
            cancelToken
        );
    };

    getDocumentEvaluation = async ({ _page, _size, _filters = {} }) => {
        // const { data, params } = this.collectFilters(filters, page, size);

        return {
            charts: [
                { date: '2021-10-05', like: 3, dislike: 5 },
                { date: '2021-10-06', like: 5, dislike: 15 },
                { date: '2021-10-07', like: 1, dislike: 6 },
                { date: '2021-10-08', like: 7, dislike: 3 },
                { date: '2021-10-09', like: 11, dislike: 7 },
                { date: '2021-10-10', like: 4, dislike: 9 },
            ],
            resources: {
                content: [
                    {
                        fullName: 'Егор Токарев',
                        login: 'tokarev',
                        rating: 'LIKE',
                        reactionDate: '2021-09-28T08:02:37.202Z',
                    },
                    {
                        fullName: 'Дима Бурнос',
                        login: 'slemdesign',
                        rating: 'DISLIKE',
                        reactionDate: '2021-09-27T08:02:37.202Z',
                    },
                    {
                        fullName: 'Егор Токарев',
                        login: 'tokarev',
                        rating: 'LIKE',
                        reactionDate: '2021-09-26T08:02:37.202Z',
                    },
                    {
                        fullName: 'Дима Бурнос',
                        login: 'slemdesign',
                        rating: 'DISLIKE',
                        reactionDate: '2021-09-25T08:02:37.202Z',
                    },
                    {
                        fullName: 'Егор Токарев',
                        login: 'tokarev',
                        rating: 'LIKE',
                        reactionDate: '2021-09-24T08:02:37.202Z',
                    },
                ],
                empty: false,
                first: true,
                last: false,
                number: 0,
                numberOfElements: 5,
                size: 10,
                totalElements: 5,
                totalPages: 1,
            },
        };

        // return await this.post('/logging/api/report/preview/document-popularity/by-likes?' + params.toString(), data);
    };
}
