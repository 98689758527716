export default function CollectScriptBreadCrumbs(id, title, parents) {
    if (parents) {
        let arr = [];

        arr.push({
            'id': parents.projectId,
            'title': parents.projectTitle,
            'state': parents.state,
            'type': 'PROJECT'
        });

        if (parents.sections) {
            arr = [...arr, ...parents.sections.map(sec => {
                return {
                    'id': sec.uuid,
                    'title': sec.title,
                    'state': sec.state,
                    'type': 'SECTION'
                };
            })];
        }

        if (id && title) {
            arr.push({ id, title });
        }

        return arr;
    } else {
        return [];
    }
}
