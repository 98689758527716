import ApiClient from './api-axios';

export default class ScriptingApi extends ApiClient {
    initScript = async () => {
        return await this.post('initialize');
    }

    postScript = async (script) => {
        return await this.post('', script);
    };

    patchScript = async (script) => {
        return await this.post('edit', script);
    };

    removeScript = async (uuid) => {
      return await this.post('delete/' + uuid)
    }

    getAllScripts = async (page = 0, size = 20, sort = '', search= '') => {
        const params = new URLSearchParams({ page, size, sort});

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        return await this.get('?' + params.toString(), '');
    };

    getPublicScript = async (uuid) => {
        return await this.get(`public/${uuid}`);
    };

    getProjectScripts = async (uuid, page = 0, size = 20, sort = '', search= '') => {
        const params = new URLSearchParams({ page, size, sort});

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        return await this.get(`project/${uuid}?` + params.toString(), '');
    };

    getPublicSectionScripts = async (uuid, page = 0, size = 20, sort = '', search= '') => {
        const params = new URLSearchParams({ page, size, sort });

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        return await this.get(`section/${uuid}?` + params.toString(), '');
    };

    getSectionScripts = async (sectionUuid, page = 0, size = 20, sort = '', search= '') => {
        const params = new URLSearchParams({ page, size, sort});

        if (search) {
            params.append('orSearch.specified', 'true');
            params.append('title.contains', search);
            params.append('authorLogin.contains', search);
            params.append('authorLastName.contains', search);
            params.append('authorFirstName.contains', search);
            params.append('authorMiddleName.contains', search);
        }

        return await this.get(`section/${sectionUuid}?` + params.toString());
    };

    validateTitle = async (title, parentSectionId, scriptId = '') => {
        const params = new URLSearchParams({ title, parentSectionId, scriptId });

        return await this.get('validate/title?' + params.toString());
    };
}
