import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import cx from './document-label.module.scss';

const DocumentLabel = ({ id, className, text, type, size }) => {
		const sizeClass = classNames({
				[cx.defaultSize]: size === 'default',
				[cx.small]: size === 'small'
		})

		return (
				<div id={id} className={classNames(cx.documentLabel, cx[type], sizeClass, className)}>
						<span className={cx.text}>
								{text}
						</span>
				</div>
		)
};

DocumentLabel.propTypes = {
		id: propTypes.string,
		className: propTypes.string,
		text: propTypes.string,
		type: propTypes.oneOf(['yellow', 'default', 'blue', 'transparent']),
		size: propTypes.oneOf(['default', 'small'])
};

DocumentLabel.defaultProps = {
		type: 'default',
		size: 'default'
}

export default DocumentLabel;