import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Icon, { Icons } from 'uikit/icon';
import Input from 'uikit/input';
import IconButton from 'uikit/icon-button';
import cx from './ChatResendModal.module.scss';

export default function ChatResendModal({ chats, users, onClose, onAccept }) {
    const [data, setData] = useState([]);
    const [showData, setShowData] = useState([]);

    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setShowData(data.filter((chat) => {
                return chat.title.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }));
        };
        fetchData();
    }, [data, search]);
    useEffect(() => {
        const data = Object.assign([], chats);

        for (let i = 0; i < users.length; i++) {
            if (data.findIndex((item) => item.id === users[i].id) !== -1) {
                continue;
            }

            data.push({
                id: users[i].id,
                title: users[i].firstName + ' ' + users[i].lastName,
                image: users[i].avatar
            });
        }

        setData(data);
    }, [chats, users]);

    return (
        <Modal isOpen={true} className={cx.chatResendModal} overlayClassName={cx.chatResendModalOverlay}>
            <div className={cx.chatResendModalHeader}>
                <div className={cx.chatResendModalHeaderTop}>
                    <h3>Переслать сообщения</h3>
                    <IconButton icon={<Icon type={Icons.CROSS} width={14} height={14}/>}
                                onClick={onClose}/>
                </div>
                <div className={cx.chatResendModalHeaderBottom}>
                    <div className={cx.chatResendModalHeaderBottomSearch}>
                        <Input placeholder="Поиск по имени, названию чата..." value={search}
                               onChange={(value) => setSearch(value)}/>
                        <Icon type={Icons.SEARCH} width={13} height={13}/>
                    </div>
                </div>
            </div>
            <div className={cx.chatResendModalBody}>
                {showData.length === 0 &&
                <div className={cx.chatResendModalBodyEmpty}>
                    <Icon type={Icons.SEARCH} width={50} height={50}/>
                    <p>Ничего не найдено</p>
                </div>}
                {showData.length !== 0 &&
                <div className={cx.chatResendModalBodyItems}>
                    {showData.map((item) => {
                        return (
                            <div key={item.id} className={cx.chatResendModalBodyItemsItem}
                                 onClick={() => onAccept(item)}>
                                <div className={cx.chatResendModalBodyItemsItemContent}>
                                    <div className={cx.chatResendModalBodyItemsItemContentAvatar}>
                                        {item.image && <img src={item.image} alt={item.title}/>}
                                    </div>
                                    <div className={cx.chatResendModalBodyItemsItemContentData}>
                                        <p>{item.title}</p>
                                        {/*<span>{user.isOnline ? 'Онлайн' : 'Оффлайн'}</span>*/}
                                    </div>
                                </div>
                                <Icon type={Icons.SHARE_ARROW} width={15} height={13}/>
                            </div>
                        );
                    })}
                </div>}
            </div>
        </Modal>
    );
};
