import React from 'react';

const DeleteIcon = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.4046 5.23326L12.8879 14.8666C12.8712 15.2166 12.5712 15.4999 12.2212 15.4999H3.77122C3.42122 15.4999 3.12122 15.2166 3.10455 14.8666L2.58789 5.23326C2.57122 4.86659 2.85457 4.54991 3.22123 4.53324C3.5879 4.51658 3.90456 4.79992 3.92122 5.16658L4.40456 14.1666H11.5879L12.0712 5.16658C12.0879 4.79992 12.4046 4.51658 12.7712 4.53324C13.1379 4.54991 13.4212 4.86659 13.4046 5.23326ZM15.1712 2.99992C15.1712 3.36659 14.8712 3.66658 14.5046 3.66658H1.50456C1.13789 3.66658 0.837891 3.36659 0.837891 2.99992C0.837891 2.63325 1.13789 2.33325 1.50456 2.33325H5.17122V0.833252C5.17122 0.516585 5.38788 0.333252 5.70455 0.333252H10.3046C10.6212 0.333252 10.8379 0.516585 10.8379 0.833252V2.33325H14.5046C14.8712 2.33325 15.1712 2.63325 15.1712 2.99992ZM6.33789 2.33325H9.67122V1.49992H6.33789V2.33325ZM6.62122 13.1666C6.95455 13.1666 7.20455 12.8499 7.20455 12.5332L7.03788 5.33325C7.03788 5.01658 6.77122 4.74992 6.43789 4.74992C6.12122 4.74992 5.85455 5.01658 5.87122 5.34991L6.03788 12.5666C6.03788 12.8833 6.30455 13.1666 6.62122 13.1666ZM9.37122 13.1666C9.68788 13.1666 9.95455 12.8999 9.95455 12.5833L10.1212 5.38326C10.1212 5.06659 9.87123 4.78324 9.55457 4.78324C9.22123 4.78324 8.97122 5.03325 8.95455 5.34991L8.78788 12.5499C8.77122 12.8833 9.03788 13.1666 9.37122 13.1666C9.35455 13.1666 9.35455 13.1666 9.37122 13.1666Z"
                fill="#7B8F9C"/>
        </svg>
    );
};

export default DeleteIcon;