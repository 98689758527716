import React from 'react';
import ReactDOM from 'react-dom';
import { Location } from '@reach/router';
import Modal from 'react-modal';
import 'lib/polyfills/promise-allSettled';
import App from 'app';
import Routes from 'app/routes';
import * as serviceWorker from './serviceWorker';
import '@trendmicro/react-checkbox/dist/react-checkbox.css';
import './index.scss';
import 'tippy.js/dist/tippy.css';

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

Modal.setAppElement('#root');

ReactDOM.render(
    <Location>
        {
            props => {
                return (
                    <App>
                        <Routes props={props}/>
                    </App>
                )
            }
        }
    </Location>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
