import React, { useState } from 'react';
import { default as ReactDateTimePicker } from 'react-datetime-picker';
import PropTypes from 'prop-types';
import Icon, { Icons } from 'uikit/icon';
import cs from './datetime-picker.module.scss';
import './datetime-picker.css';

export const DateTimePicker = ({ onChange, value, label, pickerProps, error, disabled }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div onClick={() => !disabled ? setIsOpen(true) : null}>
            {label && <div className={cs.label}>{label}</div>}
            <div>
                <ReactDateTimePicker
                    disabled={disabled}
                    isCalendarOpen={isOpen}
                    onCalendarClose={() => setIsOpen(false)}
                    className={error ? cs.error : {}}
                    disableClock={true}
                    onChange={onChange}
                    value={value}
                    {...pickerProps}
                    clearIcon={null}
                    calendarIcon={<Icon type={Icons.DATE_CALENDAR} width={14} height={15} />}
                />
            </div>
            {error && <div className={cs.errorText}>{error}</div>}
        </div>
    );
};

DateTimePicker.defaultProps = {
    pickerProps: {},
    disabled: false,
};

DateTimePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object,
    pickerProps: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};
